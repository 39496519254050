import type { TypedDialogBaseActions } from '@gv/state';
import { GeoDialogActions } from '@gv/ui/geo';
import { reportErrorDialog } from '@gv/ui/help';
import { DataSourceActions } from '@gv/datasource';

import { DialogActions } from '../../store/action/dialog.actions';
import { BillingActions, OrganizationDialogActions } from './lazy-billing';
import { DeviceActions } from './lazy-edge';

interface DialogConfig<T, OpenProps, ClosedProps, PrepareProps> {
  readonly actions: TypedDialogBaseActions<
    OpenProps,
    ClosedProps,
    PrepareProps
  >;
  readonly deps: () => Promise<T>;
}

const i0 = () =>
  import(
    '../../feature/copy-scene-description/copy-scene-description.module'
  ).then((m) => m.CopySceneDescriptionDialogModule);
const i1 = () =>
  import('../../module/feature/data-source/data-sources.module').then(
    (m) => m.DataSourcesModule,
  );
const i2 = () =>
  import('../../module/feature/workspace/location/location-state.module').then(
    (m) => m.LocationStateModule,
  );
const i9 = () =>
  import('../../module/feature/exports/exports-state.module').then(
    (m) => m.ExportsStateModule,
  );
const i10 = () =>
  import('../../module/store/util-dialogs-store-module').then(
    (m) => m.UtilDialogsStoreModule,
  );
const i11 = () =>
  import('../../module/feature/workspace/projects/project-dialogs.module').then(
    (m) => m.ProjectDialogsModule,
  );
const i12 = () =>
  import('../../module/feature/traffic-analytics/describe/filters.module').then(
    (m) => m.FiltersModule,
  );
const i14 = () =>
  import(
    '../../module/feature/workspace/dashboard/dashboard-state.module'
  ).then((m) => m.DashboardStateModule);
const i17 = () =>
  import('../../module/feature/user/user-dialogs.module').then(
    (m) => m.UserDialogsModule,
  );
const i18 = () =>
  import('./lazy-billing').then((m) => m.BillingDialogsEffectsModule);
const i19 = () =>
  import('./lazy-edge').then((m) => m.DeviceDialogsEffectsModule);
const i20 = () =>
  import('./lazy-billing').then((m) => m.OrganizationDialogsEffectsModule);
const i21 = () =>
  import('../../module/feature/data-source/data-source-dialogs.module').then(
    (m) => m.DataSourceDialogsModule,
  );
const i22 = () =>
  import('../../module/core/state.module').then((m) => m.StateModule);
const _mapping = {
  'copy-scene-description': {
    actions: DataSourceActions.copySceneDescriptionDialog,
    deps: i0,
  },
  'cardinal-angle-dialog-component': {
    actions: DataSourceActions.setCardinalAngleDialog,
    deps: i0,
  },
  'edit-data-source': {
    actions: DataSourceActions.editDataSourceDialog,
    deps: i1,
  },
  'delete-data-source': {
    actions: DataSourceActions.deleteDataSourceDialog,
    deps: i1,
  },
  'delete-video': {
    actions: DialogActions.deleteVideoDialog,
    deps: i1,
  },
  'edit-video': {
    actions: DialogActions.editVideoDialog,
    deps: i1,
  },
  'delete-location': {
    actions: DialogActions.deleteLocationDialog,
    deps: i2,
  },
  'delete-export': {
    actions: DialogActions.deleteExportDialog,
    deps: i9,
  },
  'share-export': {
    actions: DialogActions.shareExportDialog,
    deps: i9,
  },
  'update-app': {
    actions: DialogActions.updateAppDialog,
    deps: i10,
  },
  'language-mismatch': {
    actions: DialogActions.languageMismatchDialog,
    deps: i10,
  },
  'delete-project': {
    actions: DialogActions.deleteProjectDialog,
    deps: i11,
  },
  'delete-shared-project': {
    actions: DialogActions.deleteSharedProjectDialog,
    deps: i11,
  },
  'edit-project': {
    actions: DialogActions.editProjectDialog,
    deps: i11,
  },
  'importing-project': {
    actions: DialogActions.importingProjectDialog,
    deps: i11,
  },
  'share-project': {
    actions: DialogActions.shareProjectDialog,
    deps: i11,
  },
  'transfer-ownership': {
    actions: DialogActions.initTransferProjectDialog,
    deps: i11,
  },
  'accept-ownership': {
    actions: DialogActions.acceptProjectDialog,
    deps: i11,
  },
  'new-project': {
    actions: DialogActions.newProjectDialog,
    deps: i11,
  },
  'delete-filter': {
    actions: DialogActions.deleteFilterDialog,
    deps: i12,
  },
  'delete-metric': {
    actions: DialogActions.deleteMetricDialog,
    deps: i12,
  },
  'test-live-event-webhook': {
    actions: DialogActions.testWebhook,
    deps: i14,
  },
  'show-live-event-description': {
    actions: DialogActions.showEventDescription,
    deps: i14,
  },
  'reset-live-event': {
    actions: DialogActions.resetLiveEvent,
    deps: i14,
  },
  'enable-disable-event': {
    actions: DialogActions.enableDisableEvent,
    deps: i14,
  },
  'delete-auth-provider-dialog': {
    actions: DialogActions.deleteLoginProviderDialog,
    deps: i17,
  },
  'edit-password-component': {
    actions: DialogActions.editPasswordDialog,
    deps: i17,
  },
  payment: {
    actions: BillingActions.paymentDialog,
    deps: i18,
  },
  'payment-link': {
    actions: BillingActions.paymentDialogLink,
    deps: i18,
  },
  credit: {
    actions: BillingActions.creditDialog,
    deps: i18,
  },
  vault: {
    actions: BillingActions.vaultDialog,
    deps: i18,
  },
  seats: {
    actions: BillingActions.seatsDialog,
    deps: i18,
  },
  'platform-plan': {
    actions: BillingActions.platformPlanDialog,
    deps: i18,
  },
  'cancel-platform-plan': {
    actions: BillingActions.cancelPlatformPlanDialog,
    deps: i18,
  },
  'delete-payment-method': {
    actions: BillingActions.deletePaymentMethodDialog,
    deps: i18,
  },
  'new-stream': {
    actions: DeviceActions.newEdgeStreamDialog,
    deps: i19,
  },
  'edit-stream': {
    actions: DeviceActions.editEdgeStreamDialog,
    deps: i19,
  },
  'delete-stream': {
    actions: DeviceActions.deleteEdgeStreamDialog,
    deps: i19,
  },
  'verify-stream': {
    actions: DeviceActions.verifyEdgeStreamDialog,
    deps: i19,
  },
  'edit-stream-state': {
    actions: DeviceActions.updateEdgeStreamStateDialog,
    deps: i19,
  },
  'device-detail': {
    actions: DeviceActions.edgeDeviceDetailDialog,
    deps: i19,
  },
  'create-theme': {
    actions: OrganizationDialogActions.createThemeDialog,
    deps: i20,
  },
  'invite-via-referral': {
    actions: OrganizationDialogActions.inviteViaReferral,
    deps: i20,
  },
  'invite-organization-member': {
    actions: OrganizationDialogActions.inviteOrganizationMember,
    deps: i20,
  },
  'edit-organization-member': {
    actions: OrganizationDialogActions.editOrganizationMember,
    deps: i20,
  },
  'remove-organization-member': {
    actions: OrganizationDialogActions.removeOrganizationMember,
    deps: i20,
  },
  'new-organization': {
    actions: OrganizationDialogActions.createOrganization,
    deps: i20,
  },
  'organization-invitation': {
    actions: OrganizationDialogActions.acceptOrganizationDialog,
    deps: i20,
  },
  'set-data-source-geo-position': {
    actions: GeoDialogActions.setDataSourceGeoPositionDialog,
    deps: i21,
  },
  'report-error': {
    actions: reportErrorDialog,
    deps: i22,
  },
};
export const dialogMapping: Record<
  string,
  DialogConfig<unknown, unknown, unknown, unknown>
> = _mapping;
export type DialogMapping = typeof _mapping;
