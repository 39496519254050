import { isFunction } from 'lodash-es';
import type { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { filter, shareReplay, startWith, switchMap } from 'rxjs/operators';

const _RESET_KEY = Symbol('reset key');

export function resettableShareReplay<T, R>(
  resetObservable: Observable<R>,
  observableLike: ((arg: R) => Observable<T>) | Observable<T>,
  bufferSize = 1,
  refCount = true,
  untilDestroyed?: MonoTypeOperatorFunction<any>,
): Observable<T> {
  if (untilDestroyed) {
    return resetObservable.pipe(
      switchMap((r: R) => {
        const observable = isFunction(observableLike)
          ? observableLike(r)
          : observableLike;

        return observable.pipe(startWith(_RESET_KEY));
      }),
      untilDestroyed,
      shareReplay({ bufferSize, refCount }),
      filter<T>((v: T | typeof _RESET_KEY) => v !== _RESET_KEY),
    );
  }

  return resetObservable.pipe(
    switchMap((r: R) => {
      const observable: Observable<T> = isFunction(observableLike)
        ? observableLike(r)
        : observableLike;

      return observable.pipe(startWith(_RESET_KEY)) as Observable<T>;
    }),

    shareReplay({ bufferSize, refCount }),
    filter<T>((v: T | typeof _RESET_KEY) => v !== _RESET_KEY),
  );
}
