import type { Injector } from '@angular/core';

import { kebabize } from '@gv/utils';
import { createAction } from '@ngrx/store';
import type { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import type { Props } from '../flow/create-flow-base-actions';

export interface DialogDataProps<T> {
  data: T;
  injector?: () => Injector;
}

export type DialogActionType<T> = [T] extends [void]
  ? ActionCreator<string, () => TypedAction<string>>
  : ActionCreator<
      string,
      (props: DialogDataProps<T>) => TypedAction<string> & DialogDataProps<T>
    >;

export type OptionalType<T, V> = [T] extends [void] ? void : V;

export type OptionalDialogActionType<T> = OptionalType<
  T,
  ActionCreator<
    string,
    (props: DialogDataProps<T>) => TypedAction<string> & DialogDataProps<T>
  >
>;

export interface DialogOptions<
  T extends Props<DialogDataProps<any>> | void,
  R extends Props<DialogDataProps<any>> | void,
  P extends Props<DialogDataProps<any>> | void,
> {
  openProps: T;
  prepareProps: P;
  closedProps: R;
  allowSerialization?: boolean;
}

export interface TypedDialogBaseActions<OpenProps, ClosedProps, PrepareProps> {
  _type: string;
  _allowSerialization: boolean;
  prepare: OptionalDialogActionType<PrepareProps>;
  open: DialogActionType<OpenProps>;
  opened: ActionCreator<string, () => TypedAction<string>>;
  closed: DialogActionType<ClosedProps>;
}

export function createDialogBaseActions(
  basename: string,
  name: string,
  options?: Omit<
    DialogOptions<void, void, void>,
    'prepareProps' | 'openProps' | 'closedProps'
  >,
): TypedDialogBaseActions<void, void, void>;
export function createDialogBaseActions<
  TInfered extends Props<DialogDataProps<unknown>> | void,
>(
  basename: string,
  name: string,
  options: Omit<
    DialogOptions<void, TInfered, void>,
    'prepareProps' | 'openProps'
  >,
): TypedDialogBaseActions<
  void,
  TInfered extends Props<DialogDataProps<infer T>> ? T : void,
  void
>;
export function createDialogBaseActions<
  TInfered extends Props<DialogDataProps<unknown>> | void,
>(
  basename: string,
  name: string,
  options: Omit<
    DialogOptions<TInfered, void, void>,
    'prepareProps' | 'closedProps'
  >,
): TypedDialogBaseActions<
  TInfered extends Props<DialogDataProps<infer T>> ? T : void,
  void,
  void
>;
export function createDialogBaseActions<
  TInfered extends Props<DialogDataProps<unknown>> | void,
  RInfered extends Props<DialogDataProps<unknown>> | void,
>(
  basename: string,
  name: string,
  options: Omit<DialogOptions<TInfered, RInfered, void>, 'prepareProps'>,
): TypedDialogBaseActions<
  TInfered extends Props<DialogDataProps<infer T>> ? T : void,
  RInfered extends Props<DialogDataProps<infer R>> ? R : void,
  void
>;

export function createDialogBaseActions<
  TInfered extends Props<DialogDataProps<unknown>> | void,
  RInfered extends Props<DialogDataProps<unknown>> | void,
>(
  basename: string,
  name: string,
  options: Omit<DialogOptions<TInfered, void, RInfered>, 'closedProps'>,
): TypedDialogBaseActions<
  TInfered extends Props<DialogDataProps<infer T>> ? T : void,
  void,
  RInfered extends Props<DialogDataProps<infer R>> ? R : void
>;

export function createDialogBaseActions<
  TInfered extends Props<DialogDataProps<unknown>> | void,
  RInfered extends Props<DialogDataProps<unknown>> | void,
  PInfered extends Props<DialogDataProps<unknown>> | void,
>(
  basename: string,
  name: string,
  options: DialogOptions<TInfered, RInfered, PInfered>,
): TypedDialogBaseActions<
  TInfered extends Props<DialogDataProps<infer T>> ? T : void,
  RInfered extends Props<DialogDataProps<infer R>> ? R : void,
  PInfered extends Props<DialogDataProps<infer P>> ? P : void
>;
export function createDialogBaseActions<
  TInfered extends Props<DialogDataProps<unknown>> | void,
  RInfered extends Props<DialogDataProps<unknown>> | void,
  PInfered extends Props<DialogDataProps<unknown>> | void,
>(
  basename: string,
  name: string,
  options?: Partial<DialogOptions<TInfered, RInfered, PInfered>>,
): any {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return {
    _allowSerialization: !!(options && options.allowSerialization),
    _type: kebabize(name),
    prepare:
      options && options.prepareProps
        ? createAction(`${basename} prepare[${name}]`, options.prepareProps)
        : undefined,
    open:
      options && options.openProps
        ? createAction(`${basename} open[${name}]`, options.openProps)
        : createAction(`${basename} open[${name}]`),
    opened: createAction(`${basename} opened[${name}]`),
    closed:
      options && options.closedProps
        ? createAction(`${basename} closed[${name}]`, options.closedProps)
        : createAction(`${basename} closed[${name}]`),
  } as any;
}
