import type { Action } from '@ngrx/store';
import type { Observable } from 'rxjs';
import { EMPTY, of } from 'rxjs';

export function ofActions(
  ...actions: (Action | false | null | undefined)[]
): Observable<Action> {
  const validActions: Action[] = actions.filter((f): f is Action => !!f);

  if (validActions.length === 0) {
    return EMPTY;
  }

  return of(...validActions);
}
