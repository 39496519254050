<gv-dialog-component>
  <gv-dialog-header i18n="@@logout-everywhere-dialog.logout-header">
    Log out everywhere
  </gv-dialog-header>

  <gv-dialog-content>
    <p i18n="@@logout-everywhere-dialog.logout-note">
      You will be immediately logged out from all devices where you were logged
      in to Video Insights application, including the desktop application.
    </p>
    <p i18n="@@logout-everywhere-dialog.logout-upload-warning">
      Make sure you don't have any running video uploads. All uploads will be
      cancelled after the logout.
    </p>
  </gv-dialog-content>
  <gv-dialog-actions class="space-x-3">
    <button
      i18n="@@button.cancel"
      class="btn btn-dialog"
      [disabled]="loggingOut$ | async"
      (click)="cancel()"
      type="button"
    >
      Cancel
    </button>
    <app-button
      class="warn filled no-uppercase"
      [disabled]="loggingOut$ | async"
      [submitting]="submitting"
      (btnClick)="confirm()"
    >
      <ng-container i18n="@@logout-everywhere-dialog.log-out">
        Log out everywhere
      </ng-container>

      <ng-template
        appSubmittingText
        i18n="@@logout-everywhere-dialog.logging-out"
      >
        Logging out everywhere...
      </ng-template>
    </app-button>
  </gv-dialog-actions>
</gv-dialog-component>
