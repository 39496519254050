import type { ActionCreator, TypedAction } from '@ngrx/store/src/models';
import { isUndefinedOrNull } from '@gv/utils';

import type {
  VoidActions,
  AnalyticsEventsType,
  ExtractActionType,
  BaseAction,
} from './analytics-events-type';
import type { AnalyticsCategories } from './enum/analytics-categories.enum';

const basename = '[Angulartics]';

type EventType = {
  category: string;
  action: string;
  options: BaseAction;
};

export function eventAction<
  C extends AnalyticsCategories,
  A extends VoidActions<AnalyticsEventsType[C]>,
>({
  category,
  action,
}: {
  category: C;
  action: A;
}): TypedAction<string> & EventType;
export function eventAction<
  C extends AnalyticsCategories,
  A extends keyof AnalyticsEventsType[C],
>({
  category,
  action,
  options,
}: {
  category: C;
  action: A;
  options: ExtractActionType<AnalyticsEventsType[C][A]>;
}): TypedAction<string> & EventType;
export function eventAction<
  C extends AnalyticsCategories,
  A extends keyof AnalyticsEventsType[C],
>({
  category,
  action,
  options,
}: {
  category: C;
  action: A;
  options?: BaseAction;
}): EventType & TypedAction<string> {
  return {
    type: `${basename} event`,
    action: action as string,
    category,
    options: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      label:
        options && !isUndefinedOrNull(options.label)
          ? options.label
          : undefined,
      value:
        options && !isUndefinedOrNull(options.value)
          ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `${options.value}`
          : undefined,
      price: options ? options.price : undefined,
      credits: options ? options.credits : undefined,
      size: options ? options.size : undefined,
      platformPlan: options ? options.platformPlan : undefined,
      vaultPlan: options ? options.vaultPlan : undefined,
    },
  };
}
Object.assign(eventAction, { type: `${basename} event` });

export const AngularticsActions = {
  event: eventAction as ActionCreator<string, typeof eventAction>,
};
