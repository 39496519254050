export abstract class UploadFile {
  abstract path: string;
  abstract name: string;
  abstract size: number;
  abstract lastModified: Date;
  abstract isDirectory: boolean;
  abstract isFile: boolean;
  abstract type: string;
  abstract slice(start: number, end: number): Promise<Uint8Array | undefined>;
  abstract hash(chunkSize: number): Promise<string | undefined>;
  abstract init(autoClose?: boolean): Promise<void>;
  abstract close(): Promise<void>;
  abstract reopen(): Promise<void>;
}
