import './enable-logger';

import { ROARR } from 'roarr';

import { patchXhr } from './breadcrumbs';
import { createLogWriter } from './log-writer';
let initialized = false;

export function initLogger(storage: Storage, colors = false): void {
  if (initialized) {
    return;
  }

  patchXhr();

  if (!storage) {
    storage = globalThis.localStorage;
  }

  if (!storage) {
    initialized = true;
    return;
  }

  storage.setItem('ROARR_LOG', 'true');
  if (!storage.getItem('ROARR_FILTER')) {
    storage.setItem('ROARR_FILTER', 'context.logLevel:>20');
  }

  ROARR.write = createLogWriter({
    storage,
    colors,
    errorLogging: typeof window !== 'undefined',
  });
  initialized = true;
}
