import { GlobalPositionStrategy } from '@angular/cdk/overlay';

export class ScrollStrategy extends GlobalPositionStrategy {
  override attach(
    overlayRef: Parameters<typeof GlobalPositionStrategy.prototype.attach>[0],
  ): void {
    super.attach(overlayRef);
    overlayRef.hostElement.classList.add('z-[1000000000]');
  }

  enable(): void {
    //
  }

  disable(): void {
    //
  }
}
