import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';

import { OutageEffects } from './effect';

@NgModule({
  imports: [EffectsModule.forFeature([OutageEffects])],
})
export class OutageModule {}
