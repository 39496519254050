import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

import {
  BasicDialogButtonComponent,
  DialogComponent,
  SimpleDialogModule,
} from '@gv/ui/dialog';
import { ScrollDirective } from '@gv/ui/utils';
import { VideoFileValidation } from '@gv/upload/types';
import type { FilesCouldNotBeUploadedDialogDataModel } from '@gv/upload/state';

@Component({
  standalone: true,
  imports: [
    SimpleDialogModule,
    MatDialogModule,
    ScrollDirective,
    MatTooltipModule,
    BasicDialogButtonComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-files-could-not-be-uploaded-dialog',
  template: `
    <gv-simple-dialog
      text="Warning: Some files will be skipped"
      i18n-text="@@files-could-not-be-uploaded-dialog.title"
    >
      <ng-container *gvDialogContent>
        <p i18n="@@files-could-not-be-uploaded-dialog.msg">
          Please note that we only allow direct file uploads within the chosen
          folder. Following files will be skipped:
        </p>

        <div gvScroll class="flex max-h-[300px] flex-col items-start space-y-1">
          @for (file of files; track file) {
            <div [matTooltip]="file.reason" class="break-words">
              {{ file.name }}
            </div>
          }
          @if (filesCount !== 0) {
            <div
              class="break-words"
              i18n="@@files-could-not-be-uploaded-dialog.additional"
            >
              And additional {{ filesCount }} files
            </div>
          }
        </div>
      </ng-container>

      <gv-basic-dialog-button
        *gvDialogActions
        color="primary"
        i18n="@@button.ok"
        >Ok</gv-basic-dialog-button
      >
    </gv-simple-dialog>
  `,
})
export class FilesCouldNotBeUploadedDialogComponent extends DialogComponent<
  FilesCouldNotBeUploadedDialogDataModel,
  void
> {
  private static readonly validationReasons = {
    [VideoFileValidation.InvalidFile]: $localize`:@@files-could-not-be-uploaded-dialog.invalid-video-file:Invalid video file`,
    [VideoFileValidation.NestedDirectory]: $localize`:@@files-could-not-be-uploaded-dialog.nested:Nested folders are ignored`,
    [VideoFileValidation.NestedFile]: $localize`:@@files-could-not-be-uploaded-dialog.file-nested:File is nested in subfolder`,
    [VideoFileValidation.InvalidFileSize]: $localize`:@@files-could-not-be-uploaded-dialog.limit:Size of the video file is limited to 100 GB`,
  };

  filesCount =
    this.data.folders.length > 10 ? this.data.folders.length - 10 : 0;

  files: ReadonlyArray<{ name: string; reason: string }> = (
    this.data.folders || []
  )
    .reduce(
      (acc, folder) =>
        acc.concat(
          (folder.files || []).map((f): { name: string; reason: string } => ({
            name: f.file.relativePath,
            reason: this.getReason(f.validation),
          })),
        ),
      [],
    )
    .slice(0, 10);

  private getReason(validation: VideoFileValidation): string {
    return FilesCouldNotBeUploadedDialogComponent.validationReasons[validation];
  }
}
