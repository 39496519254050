import type { OnDestroy } from '@angular/core';
import { inject, Injectable } from '@angular/core';

import { WindowRefService } from '@gv/ui/core';
import { untilNgDestroyed } from '@gv/utils';
import type { Observable } from 'rxjs';
import { BehaviorSubject, of, Subject } from 'rxjs';
import {
  debounceTime,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConnectionStatusCheckerService implements OnDestroy {
  private windowRef = inject(WindowRefService);
  private statusSource: Subject<boolean> = new Subject<boolean>();
  statusChanged$: Observable<boolean> = this.statusSource.asObservable();

  private isConnected: boolean =
    !this.windowRef.nativeWindow ||
    !this.windowRef.nativeWindow.navigator ||
    this.windowRef.nativeWindow.navigator.onLine !== false;

  // this is only to monitor request network errors.
  // there might be false positives during network switching etc.
  private status = new BehaviorSubject<boolean>(this.isConnected);

  connectionStatus$ = this.statusChanged$.pipe(
    startWith(this.isConnected),
    shareReplay(1),
    switchMap((isConnected) => {
      if (!isConnected) {
        return of(false);
      }
      return this.status.pipe(debounceTime(1000));
    }),
    untilNgDestroyed(),
  );

  get connected(): boolean {
    return this.isConnected;
  }

  private onlineEvent = () => {
    this.isConnected = true;

    this.statusSource.next(this.connected);
  };

  private offlineEvent = () => {
    this.isConnected = false;

    this.statusSource.next(this.connected);
  };

  ngOnDestroy(): void {
    this.unbindEventHandlers();
  }

  enable(): void {
    if (!this.windowRef.nativeWindow) {
      return;
    }

    this.unbindEventHandlers();

    this.windowRef.nativeWindow.addEventListener('online', this.onlineEvent);
    this.windowRef.nativeWindow.addEventListener('offline', this.offlineEvent);
  }

  reportOnline(): void {
    if (!this.status.getValue()) {
      this.status.next(true);
    }
  }

  reportOffline(): void {
    if (this.status.getValue()) {
      this.status.next(false);
    }
  }

  private unbindEventHandlers(): void {
    if (this.windowRef.nativeWindow) {
      this.windowRef.nativeWindow.removeEventListener(
        'online',
        this.onlineEvent,
      );
      this.windowRef.nativeWindow.removeEventListener(
        'offline',
        this.offlineEvent,
      );
    }
  }
}
