import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  Output,
  TemplateRef,
  ElementRef,
  computed,
  input,
} from '@angular/core';
import { TooltipPosition, MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { NgTemplateOutlet, AsyncPipe } from '@angular/common';
import type { ActivatedRoute } from '@angular/router';

import type { Permission, PermissionScope } from '@gv/permission';
import { PermissionAwareContext, PERMISSION_SERVICE } from '@gv/permission';
import { BUTTON } from '@gv/ui/form';
import { Observable } from 'rxjs';
import {
  TEST_ID,
  TestGroupDirective,
  TestIdDirective,
  TestIdModule,
} from '@gv/debug';

import { ButtonSubmittingTextDirective } from './button-submitting-text.directive';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-button',
  templateUrl: './button.component.html',
  providers: [
    {
      provide: PermissionAwareContext,
      useExisting: forwardRef(() => ButtonComponent),
    },
    {
      provide: BUTTON,
      useExisting: forwardRef(() => ButtonComponent),
    },
    {
      provide: TEST_ID,
      useFactory: () => {
        const comp = inject(ButtonComponent);
        const classList = comp.element.nativeElement.classList;
        const btnType =
          classList.contains('submit') || classList.contains('green')
            ? 'btn-submit'
            : classList.contains('warn')
              ? 'btn-decline'
              : 'btn';
        return computed(() => btnType);
      },
    },
  ],
  standalone: true,
  imports: [
    RouterLink,
    MatTooltipModule,
    NgTemplateOutlet,
    MatIconModule,
    MatProgressSpinnerModule,
    AsyncPipe,
    TestIdModule,
  ],
  hostDirectives: [
    { directive: TestIdDirective, inputs: ['_gvId'] },
    TestGroupDirective,
  ],
})
export class ButtonComponent extends PermissionAwareContext {
  private _element = inject<ElementRef<HTMLElement>>(ElementRef<HTMLElement>);
  private permissionService = inject(PERMISSION_SERVICE, { optional: true });

  get element(): ElementRef<HTMLElement> {
    return this._element;
  }

  relativeTo = input<ActivatedRoute>();

  @Input()
  icon: string | undefined | null;

  @Input()
  iconInline = false;

  @Input()
  svgIcon: string | undefined | null;

  @Input()
  link: string | any[] | undefined | null;

  @Input()
  alignEnd = false;

  @ContentChild(ButtonSubmittingTextDirective, {
    read: TemplateRef,
    static: false,
  })
  protected submittingTemplate?: TemplateRef<ButtonSubmittingTextDirective>;

  disabledByPermissionsS = computed(() => {
    if (
      this.permissionDisabled() ||
      (!this.permission() && !this.permissionPairs())
    ) {
      return false;
    } else {
      const scope = this.scope();
      const scopes = scope
        ? ([].concat(scope) as readonly PermissionScope[])
        : undefined;
      const pairs =
        this.permissionPairs() || scopes!.map((s) => [this.permission(), s]);

      const areAble = pairs.map(([permissions, scope]) => {
        return this.permissionService.isAbleS(permissions, scope);
      });

      const isNotAble = areAble.find((f) => !f[0]);

      if (isNotAble) {
        this.reason = isNotAble![1]!.reason;
      }

      return !!isNotAble;
    }
  });
  private _disabled = false;

  @HostBinding('attr.disabled')
  get clickDisabled(): boolean {
    return this._disabled || this.disabledByPermissionsS();
  }

  @Input()
  set disabled(disabled: any) {
    this._disabled = coerceBooleanProperty(disabled);
  }

  get disabled(): any {
    return this._disabled;
  }

  @Input()
  tooltipPosition?: TooltipPosition = 'below';

  permission = input<undefined | Permission | readonly Permission[]>();

  permissionPairs =
    input<readonly [Permission | readonly Permission[], PermissionScope][]>();

  permissionDisabled = input(false);

  @Input()
  permissionTooltip: string | boolean | undefined;

  scope = input<undefined | PermissionScope | readonly PermissionScope[]>();

  @Input()
  submitting = false;

  @Input()
  progress$?: Observable<number>;

  @Output()
  btnClick = new EventEmitter<Event>();

  @HostListener('click', ['$event'])
  protected onClick(event: Event): void {
    if (this.clickDisabled) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }

    this.btnClick.emit(event);
  }
}
