import axios, { isAxiosError } from 'axios';

export async function getDomainRedirectForUrl(
  url: string,
): Promise<string | undefined> {
  try {
    const urlObj = new URL(url);
    await axios.get(urlObj.href, {
      headers: {
        'x-api': 'true',
      },
    });
  } catch (res) {
    if (isAxiosError(res)) {
      if (res.response?.status === 302) {
        const body = res.response.data as { location: string };
        if (body?.location) {
          return body.location;
        }
      }
    }
  }

  return undefined;
}
