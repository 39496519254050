import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';

import { Environment, WindowRefService } from '@gv/ui/core';

import { STRIPE_API_KEY } from '../token';

@NgModule({})
export class StripeConfigModule {
  static forRoot(config: {
    production: string;
    test: string;
  }): ModuleWithProviders<StripeConfigModule> {
    return {
      ngModule: StripeConfigModule,
      providers: [
        {
          provide: STRIPE_API_KEY,
          deps: [WindowRefService],
          useFactory: (windowRef: WindowRefService): string => {
            return !!windowRef &&
              !!windowRef.nativeWindow &&
              (windowRef.nativeWindow._environment === Environment.Production ||
                windowRef.nativeWindow._environment ===
                  Environment.ElectronProduction)
              ? config.production
              : config.test;
          },
        },
      ],
    };
  }
}
