export type ApiResponse<T, Headers = Record<any, unknown>> = {
  readonly data: T;
  readonly dtSent: Date;
  readonly apiMessage?: {
    readonly message: string;
    readonly code: number;
  };
  readonly status: number;
  readonly rid: string;
  readonly createdAt: Date;
  readonly bypassJwt: boolean;
  readonly bypassRedirect: boolean;
  readonly headers?: Record<string, string> & Headers;
};

export class ApiErrorResponse<T> extends Error implements ApiResponse<T> {
  readonly status!: number;
  readonly data!: T;
  readonly dtSent!: Date;
  readonly apiMessage?: { readonly message: string; readonly code: number };
  readonly rid!: string;
  readonly createdAt!: Date;
  readonly bypassJwt!: boolean;
  readonly bypassRedirect!: boolean;
  readonly headers?: Record<string, string>;

  constructor(
    public override readonly message: string,
    response: ApiResponse<T>,
  ) {
    super(message);
    Object.assign(this, response);

    Object.setPrototypeOf(this, ApiErrorResponse.prototype);
  }
}
