import { NgModule } from '@angular/core';

import { DisallowHtmlValidatorDirective } from '../directive/validator/disallow-html-validator.directive';
import { EmailValidatorDirective } from '../directive/validator/email-validator.directive';
import { EmptySpaceValidatorDirective } from '../directive/validator/empty-space-validator.directive';
import { EqualityValidatorDirective } from '../directive/validator/equality-validator.directive';
import { MaxValidatorDirective } from '../directive/validator/max-validator.directive';
import { MinValidatorDirective } from '../directive/validator/min-validator.directive';
import { ValidateMaxValidatorDirective } from '../directive/validator/validate-max-validator.directive';
import { ValidateMinValidatorDirective } from '../directive/validator/validate-min-validator.directive';

@NgModule({
  imports: [
    DisallowHtmlValidatorDirective,
    EmailValidatorDirective,
    EmptySpaceValidatorDirective,
    EqualityValidatorDirective,
    MaxValidatorDirective,
    MinValidatorDirective,
    ValidateMinValidatorDirective,
    ValidateMaxValidatorDirective,
  ],
  exports: [
    DisallowHtmlValidatorDirective,
    EmailValidatorDirective,
    EmptySpaceValidatorDirective,
    EqualityValidatorDirective,
    MaxValidatorDirective,
    MinValidatorDirective,
    ValidateMinValidatorDirective,
    ValidateMaxValidatorDirective,
  ],
})
export class ValidationModule {}
