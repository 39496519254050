import { inject, Injectable } from '@angular/core';

import {
  AnalyticsBillingActions,
  AnalyticsCategories,
  AngularticsActions,
} from '@gv/analytics';
import { API, ApiErrorResponse } from '@gv/api';
import { ErrorsActions } from '@gv/error';
import {
  createFlowEffect,
  createSubStateRefreshEffect,
  StoreInject,
} from '@gv/state';
import { USER_CONTEXT } from '@gv/user';
import {
  delayedConcatMap,
  getFilenameFromResponse,
  zonedToApiDate,
} from '@gv/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import type { Action } from '@ngrx/store';
import { saveAs } from 'file-saver';
import type { Observable } from 'rxjs';
import { concat, EMPTY, of, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  finalize,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import * as BillingActions from './actions';
import { credit } from './credit/actions';
import * as fromBillingState from './selectors';
import { BILLING_FEATURE_STATE } from './state';
import { DetailActions } from './detail';

@Injectable()
export class BillingEffects {
  private actions$ = inject<Actions>(Actions);
  private api = inject(API);
  private store = inject(StoreInject(BILLING_FEATURE_STATE));
  private userContext = inject(USER_CONTEXT);
  fetchPaymentInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.paymentInfo.fetch.init),
      debounceTime(0),
      delayedConcatMap(() => [
        this.store.select(fromBillingState.fromPaymentInfo.isPaymentInfoLoaded),
        this.store.select(fromBillingState.fromPaymentInfo.isPaymentInfoDirty),
        this.store.select(
          fromBillingState.fromPaymentInfo.isPaymentInfoLoading,
        ),
      ]),
      filter(
        ([action, loaded, dirty, loading]) =>
          !action.skipWhenLoaded || ((!loaded || dirty) && !loading),
      ),
      switchMap(() => {
        return concat(
          of(BillingActions.paymentInfo.fetch.started()),
          this.api.getInfo().pipe(
            mergeMap((response) => {
              return of(
                BillingActions.paymentInfo.fetch.completed({
                  dtSent: response.dtSent,
                  data: response.data!,
                  sortable: undefined,
                }),
              );
            }),
            catchError((error: unknown) => {
              return of(
                BillingActions.paymentInfo.fetch.error({
                  error,
                }),
              );
            }),
            takeUntil(
              this.actions$.pipe(
                ofType(BillingActions.paymentInfo.fetch.cancel),
              ),
            ),
          ),
        );
      }),
    ),
  );

  fetchPlatformPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.platformPlansInfo.fetch.init),
      debounceTime(0),
      delayedConcatMap(() => [
        this.store.select(
          fromBillingState.fromPlatformPlansInfo.isPlatformPlansInfoLoaded,
        ),
        this.store.select(
          fromBillingState.fromPlatformPlansInfo.isPlatformPlansInfoDirty,
        ),
        this.store.select(
          fromBillingState.fromPlatformPlansInfo.isPlatformPlansInfoLoading,
        ),
      ]),
      filter(
        ([action, loaded, dirty, loading]) =>
          !action.skipWhenLoaded || ((!loaded || dirty) && !loading),
      ),
      switchMap(() => {
        return concat(
          of(BillingActions.platformPlansInfo.fetch.started()),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          this.api.getAvailablePlatformPlans().pipe(
            mergeMap((response) => {
              return of(
                BillingActions.platformPlansInfo.fetch.completed({
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  dtSent: response.dtSent,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  data: response.data!,
                  sortable: undefined,
                }),
              );
            }),
            catchError((error: unknown) => {
              return of(
                BillingActions.platformPlansInfo.fetch.error({
                  error,
                }),
              );
            }),
            takeUntil(
              this.actions$.pipe(
                ofType(BillingActions.platformPlansInfo.fetch.cancel),
              ),
            ),
          ),
        );
      }),
    ),
  );

  fetchSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.subscription.fetch.init),
      debounceTime(0),
      delayedConcatMap(() => [
        this.store.select(
          fromBillingState.fromSubscription.isSubscriptionLoaded,
        ),
        this.store.select(
          fromBillingState.fromSubscription.isSubscriptionDirty,
        ),
        this.store.select(
          fromBillingState.fromSubscription.isSubscriptionLoading,
        ),
      ]),
      filter(
        ([action, loaded, dirty, loading]) =>
          !action.skipWhenLoaded || ((!loaded || dirty) && !loading),
      ),
      switchMap(() => {
        return concat(
          of(BillingActions.subscription.fetch.started()),
          this.api.getSubscription().pipe(
            mergeMap((response) => {
              return of(
                BillingActions.subscription.fetch.completed({
                  dtSent: new Date(),
                  data: response.data!,
                  sortable: undefined,
                }),
              );
            }),
            catchError((error: unknown) => {
              return of(
                BillingActions.subscription.fetch.error({
                  error,
                }),
              );
            }),
            takeUntil(
              this.actions$.pipe(
                ofType(BillingActions.subscription.fetch.cancel),
              ),
            ),
          ),
        );
      }),
    ),
  );

  fetchNextPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.nextPayment.fetch.init),
      debounceTime(0),
      delayedConcatMap(() => [
        this.store.select(fromBillingState.fromNextPayment.isNextPaymentLoaded),
        this.store.select(fromBillingState.fromNextPayment.isNextPaymentDirty),
        this.store.select(
          fromBillingState.fromNextPayment.isNextPaymentLoading,
        ),
      ]),
      filter(
        ([action, loaded, dirty, loading]) =>
          !action.skipWhenLoaded || ((!loaded || dirty) && !loading),
      ),
      switchMap(() => {
        return concat(
          of(BillingActions.nextPayment.fetch.started()),
          this.api.getNextSubscriptionPayment().pipe(
            mergeMap((response) => {
              return of(
                BillingActions.nextPayment.fetch.completed({
                  dtSent: response.dtSent,
                  data: response.data!,
                  sortable: undefined,
                }),
              );
            }),
            catchError((error: unknown) => {
              return of(
                BillingActions.nextPayment.fetch.error({
                  error,
                }),
              );
            }),
            takeUntil(
              this.actions$.pipe(
                ofType(BillingActions.nextPayment.fetch.cancel),
              ),
            ),
          ),
        );
      }),
    ),
  );

  fetchBillingInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.billingInfo.fetch.init),
      debounceTime(0),
      delayedConcatMap(() => [
        this.store.select(fromBillingState.fromBillingInfo.isBillingInfoLoaded),
        this.store.select(fromBillingState.fromBillingInfo.isBillingInfoDirty),
        this.store.select(
          fromBillingState.fromBillingInfo.isBillingInfoLoading,
        ),
      ]),
      filter(
        ([action, loaded, dirty, loading]) =>
          !action.skipWhenLoaded || ((!loaded || dirty) && !loading),
      ),
      switchMap(() => {
        return concat(
          of(BillingActions.billingInfo.fetch.started()),
          this.api
            .getBillingAccount({
              headers: { 'x-date_from': '', 'x-date_to': '' },
            })
            .pipe(
              mergeMap((response) => {
                return of(
                  BillingActions.billingInfo.fetch.completed({
                    dtSent: response.dtSent,
                    data: response.data!,
                    sortable: undefined,
                  }),
                );
              }),
              catchError((error: unknown) => {
                return of(
                  BillingActions.billingInfo.fetch.error({
                    error,
                  }),
                );
              }),
              takeUntil(
                this.actions$.pipe(
                  ofType(BillingActions.billingInfo.fetch.cancel),
                ),
              ),
            ),
        );
      }),
    ),
  );

  updateBillingAddress$ = createFlowEffect(({ action }) => {
    return this.api.updateBillingAddress(action.data).pipe(
      mergeMap((response) => of({ model: response.data })),
      catchError((error: unknown) =>
        of(
          ErrorsActions.generic({
            action,
            error,
            logToSentry: true,
            log: true,
            snackbarMessage: {
              message:
                error instanceof ApiErrorResponse && error?.apiMessage?.message
                  ? error?.apiMessage?.message
                  : $localize`:@@effects.failed-to-update-billing-address:Failed to update billing address`,
              config: {
                duration: 5000,
              },
            },
          }),
        ),
      ),
    );
  }, BillingActions.billingInfo.updateBillingAddress);

  cancelSubscription$ = createFlowEffect(({ action }) => {
    return this.api.cancelSubscription().pipe(
      mergeMap(() =>
        concat(
          of(
            BillingActions.subscription.refresh({}),
            BillingActions.nextPayment.refresh({}),
            DetailActions.detail.refresh({}),
          ),
        ),
      ),
      catchError((error: unknown) =>
        of(
          ErrorsActions.generic({
            action,
            error,
            logToSentry: true,
            log: true,
            snackbarMessage: {
              message:
                error instanceof ApiErrorResponse && error?.apiMessage?.message
                  ? error?.apiMessage?.message
                  : $localize`:@@effects.failed-to-cancel-subscription:Failed to cancel subscription`,
              config: {
                duration: 5000,
              },
            },
          }),
        ),
      ),
    );
  }, BillingActions.subscription.cancelSubscriptionFlow);

  fetchInvoicesHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.invoicesHistory.fetch.init),
      debounceTime(0),
      delayedConcatMap(() => [
        this.store.select(
          fromBillingState.fromInvoicesHistory.isInvoicesHistoryLoaded,
        ),
        this.store.select(
          fromBillingState.fromInvoicesHistory.isInvoicesHistoryDirty,
        ),
        this.store.select(
          fromBillingState.fromInvoicesHistory.isInvoicesHistoryLoading,
        ),
      ]),
      filter(
        ([action, loaded, dirty, loading]) =>
          !action.skipWhenLoaded || ((!loaded || dirty) && !loading),
      ),
      delayedConcatMap(() => [
        this.store.select(
          fromBillingState.fromInvoicesHistory.getPaginationInfo,
        ),
      ]),
      switchMap(([[action], info]) => {
        return concat(
          of(BillingActions.invoicesHistory.fetch.started()),
          this.api
            .getInvoices({
              headers: {
                'x-date_from': info.range
                  ? zonedToApiDate(info.range.start)!
                  : '',
                'x-date_to': info.range
                  ? zonedToApiDate(info.range.end)!
                  : info.end
                    ? zonedToApiDate(info.end)!
                    : '',
                'x-limit': action.data?.limit
                  ? String(action.data?.limit)
                  : '40',
                'x-offset': action.data?.offset
                  ? String(action.data?.offset)
                  : '',
              },
            })
            .pipe(
              mergeMap((response) => {
                return of(
                  BillingActions.invoicesHistory.fetch.completed({
                    dtSent: response.dtSent,
                    data: response.data!,
                    sortable: undefined,
                    context: action.data || undefined,
                  }),
                );
              }),
              catchError((error: unknown) => {
                return of(
                  BillingActions.invoicesHistory.fetch.error({
                    error,
                  }),
                );
              }),
              takeUntil(
                this.actions$.pipe(
                  ofType(BillingActions.invoicesHistory.fetch.cancel),
                ),
              ),
            ),
        );
      }),
    ),
  );

  loadInvoicesPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.invoicesHistory.loadMore),
      delayedConcatMap(() => [
        this.store.select(
          fromBillingState.fromInvoicesHistory.getPaginationInfo,
        ),
      ]),
      map(([, info]) =>
        BillingActions.invoicesHistory.fetch.init({
          skipWhenLoaded: false,
          data: {
            limit: info.limit,
            offset: info.offset + info.limit,
          },
        }),
      ),
    ),
  );

  fetchProFormaInvoicesHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.proFormaInvoicesHistory.fetch.init),
      debounceTime(0),
      delayedConcatMap(() => [
        this.store.select(
          fromBillingState.fromProFormaInvoicesHistory
            .isProFormaInvoicesHistoryLoaded,
        ),
        this.store.select(
          fromBillingState.fromProFormaInvoicesHistory
            .isProFormaInvoicesHistoryDirty,
        ),
        this.store.select(
          fromBillingState.fromProFormaInvoicesHistory
            .isProFormaInvoicesHistoryLoading,
        ),
      ]),
      filter(
        ([action, loaded, dirty, loading]) =>
          !action.skipWhenLoaded || ((!loaded || dirty) && !loading),
      ),
      delayedConcatMap(() => [
        this.store.select(
          fromBillingState.fromProFormaInvoicesHistory.getPaginationInfo,
        ),
      ]),
      switchMap(([[action], info]) => {
        return concat(
          of(BillingActions.proFormaInvoicesHistory.fetch.started()),
          this.api
            .getProFormas({
              headers: {
                'x-date_from': info.range
                  ? zonedToApiDate(info.range.start)!
                  : '',
                'x-date_to': info.range
                  ? zonedToApiDate(info.range.end)!
                  : info.end
                    ? zonedToApiDate(info.end)!
                    : '',
                'x-limit': action.data?.limit
                  ? String(action.data?.limit)
                  : '40',
                'x-offset': action.data?.offset
                  ? String(action.data?.offset)
                  : '',
              },
            })
            .pipe(
              mergeMap((response) => {
                return of(
                  BillingActions.proFormaInvoicesHistory.fetch.completed({
                    dtSent: response.dtSent,
                    data: response.data!,
                    sortable: undefined,
                    context: action.data || undefined,
                  }),
                );
              }),
              catchError((error: unknown) => {
                return of(
                  BillingActions.proFormaInvoicesHistory.fetch.error({
                    error,
                  }),
                );
              }),
              takeUntil(
                this.actions$.pipe(
                  ofType(BillingActions.proFormaInvoicesHistory.fetch.cancel),
                ),
              ),
            ),
        );
      }),
    ),
  );

  loadProFormaInvoicesPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.proFormaInvoicesHistory.loadMore),
      delayedConcatMap(() => [
        this.store.select(
          fromBillingState.fromProFormaInvoicesHistory.getPaginationInfo,
        ),
      ]),
      map(([, info]) =>
        BillingActions.proFormaInvoicesHistory.fetch.init({
          skipWhenLoaded: false,
          data: {
            limit: info.limit,
            offset: info.offset + info.limit,
          },
        }),
      ),
    ),
  );

  fetchPriceOffersHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.priceOffersHistory.fetch.init),
      debounceTime(0),
      delayedConcatMap(() => [
        this.store.select(
          fromBillingState.fromPriceOffersHistory.isPriceOffersHistoryLoaded,
        ),
        this.store.select(
          fromBillingState.fromPriceOffersHistory.isPriceOffersHistoryDirty,
        ),
        this.store.select(
          fromBillingState.fromPriceOffersHistory.isPriceOffersHistoryLoading,
        ),
      ]),
      filter(
        ([action, loaded, dirty, loading]) =>
          !action.skipWhenLoaded || ((!loaded || dirty) && !loading),
      ),
      delayedConcatMap(() => [
        this.store.select(
          fromBillingState.fromPriceOffersHistory.getPaginationInfo,
        ),
      ]),
      switchMap(([[action], info]) => {
        return concat(
          of(BillingActions.priceOffersHistory.fetch.started()),
          this.api
            .getPriceOffers({
              headers: {
                'x-date_from': info.range
                  ? zonedToApiDate(info.range.start)!
                  : '',
                'x-date_to': info.range
                  ? zonedToApiDate(info.range.end)!
                  : info.end
                    ? zonedToApiDate(info.end)!
                    : '',
                'x-limit': action.data?.limit
                  ? String(action.data?.limit)
                  : '40',
                'x-offset': action.data?.offset
                  ? String(action.data?.offset)
                  : '',
              },
            })
            .pipe(
              mergeMap((response) => {
                return of(
                  BillingActions.priceOffersHistory.fetch.completed({
                    dtSent: response.dtSent,
                    data: response.data!,
                    sortable: undefined,
                    context: action.data || undefined,
                  }),
                );
              }),
              catchError((error: unknown) => {
                return of(
                  BillingActions.priceOffersHistory.fetch.error({
                    error,
                  }),
                );
              }),
              takeUntil(
                this.actions$.pipe(
                  ofType(BillingActions.priceOffersHistory.fetch.cancel),
                ),
              ),
            ),
        );
      }),
    ),
  );

  loadPriceOffersPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.priceOffersHistory.loadMore),
      delayedConcatMap(() => [
        this.store.select(
          fromBillingState.fromPriceOffersHistory.getPaginationInfo,
        ),
      ]),
      map(([, info]) =>
        BillingActions.priceOffersHistory.fetch.init({
          skipWhenLoaded: false,
          data: {
            limit: info.limit,
            offset: info.offset + info.limit,
          },
        }),
      ),
    ),
  );

  fetchCreditHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.creditHistory.fetch.init),
      debounceTime(0),
      delayedConcatMap(() => [
        this.store.select(
          fromBillingState.fromCreditHistory.isCreditHistoryLoaded,
        ),
        this.store.select(
          fromBillingState.fromCreditHistory.isCreditHistoryDirty,
        ),
        this.store.select(
          fromBillingState.fromCreditHistory.isCreditHistoryLoading,
        ),
      ]),
      filter(
        ([action, loaded, dirty, loading]) =>
          !action.skipWhenLoaded || ((!loaded || dirty) && !loading),
      ),
      delayedConcatMap(() => [
        this.store.select(fromBillingState.fromCreditHistory.getPaginationInfo),
      ]),
      switchMap(([[action], info]) => {
        return concat(
          of(BillingActions.creditHistory.fetch.started()),
          this.api
            .getCreditHistory({
              headers: {
                'x-date_from': info.range
                  ? zonedToApiDate(info.range.start)!
                  : '',
                'x-date_to': info.range
                  ? zonedToApiDate(info.range.end)!
                  : info.end
                    ? zonedToApiDate(info.end)!
                    : '',
                'x-limit': action.data?.limit
                  ? String(action.data?.limit)
                  : '40',
                'x-offset': action.data?.offset
                  ? String(action.data?.offset)
                  : '',
              },
            })
            .pipe(
              mergeMap((response) => {
                return of(
                  BillingActions.creditHistory.fetch.completed({
                    dtSent: response.dtSent,
                    data: response.data!,
                    sortable: undefined,
                    context: action.data || undefined,
                  }),
                );
              }),
              catchError((error: unknown) => {
                return of(
                  BillingActions.creditHistory.fetch.error({
                    error,
                  }),
                );
              }),
              takeUntil(
                this.actions$.pipe(
                  ofType(BillingActions.creditHistory.fetch.cancel),
                ),
              ),
            ),
        );
      }),
    ),
  );

  loadCreditsPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.creditHistory.loadMore),
      delayedConcatMap(() => [
        this.store.select(fromBillingState.fromCreditHistory.getPaginationInfo),
      ]),
      map(([, info]) =>
        BillingActions.creditHistory.fetch.init({
          skipWhenLoaded: false,
          data: {
            limit: info.limit,
            offset: info.offset + info.limit,
          },
        }),
      ),
    ),
  );

  downloadInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.downloadInvoice),
      mergeMap(
        (action): Observable<Action> =>
          concat(
            this.api.getInvoice(action.invoiceUuid).pipe(
              tap((response) => {
                const blob = new Blob([response.data!], {
                  type: 'application/pdf',
                });
                const filename = getFilenameFromResponse(response);
                saveAs(blob, filename ? filename : 'invoice.pdf');
              }),
              mergeMap(() => EMPTY),
              catchError((error: unknown) => {
                return of(
                  ErrorsActions.generic({
                    action,
                    error,
                    log: true,
                    logToSentry: true,
                    snackbarMessage: {
                      message: $localize`:@@effects.failed-to-download-invoice:Failed to download invoice.`,
                      config: {
                        duration: 5000,
                      },
                    },
                  }),
                );
              }),
            ),
          ),
      ),
    ),
  );

  applyPromoCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.promoCode.apply),
      delayedConcatMap(() => [this.userContext.userUuid$]),
      switchMap(([action, userUuid]) => {
        this.store.dispatch(
          BillingActions.promoCode.submitting({ value: true }),
        );
        return this.api.applyPromocode(userUuid, action.promo).pipe(
          mergeMap((response) =>
            of(
              BillingActions.billingInfo.refresh({ dtSent: response.dtSent }),
              credit.refresh({ dtSent: response.dtSent }),
            ),
          ),
          tap(() => {
            this.store.dispatch(
              AngularticsActions.event({
                category: AnalyticsCategories.Billing,
                action: AnalyticsBillingActions.PromoCodeAdd,
              }),
            );
            this.store.dispatch(
              BillingActions.promoCode.error({ value: false }),
            );
          }),
          catchError((error: unknown) =>
            concat(
              of(
                ErrorsActions.generic({
                  action,
                  error,
                  logToSentry: true,
                  log: true,
                  snackbarMessage: {
                    message:
                      error &&
                      error instanceof ApiErrorResponse &&
                      error.apiMessage?.message
                        ? error.apiMessage.message
                        : $localize`:@@effects.failed-to-apply:Cannot apply promo code.`,
                    config: { duration: 3000 },
                  },
                }),
              ),
              of(BillingActions.promoCode.error({ value: true })),
              throwError(() => error),
            ),
          ),
          finalize(() => {
            this.store.dispatch(
              BillingActions.promoCode.submitting({ value: false }),
            );
          }),
        );
      }),
    ),
  );

  deletePaymentMethod$ = createFlowEffect(({ action }) => {
    return this.api.deletePaymentMethod().pipe(
      mergeMap(() => of(BillingActions.billingInfo.refresh({}))),
      catchError((error: unknown) =>
        of(
          ErrorsActions.generic({
            action,
            error,
            logToSentry: true,
            log: true,
            snackbarMessage: {
              message:
                error instanceof ApiErrorResponse && error?.apiMessage?.message
                  ? error?.apiMessage?.message
                  : $localize`:@@effects.failed-to-delete-payment-method:Failed to delete payment method`,
              config: {
                duration: 5000,
              },
            },
          }),
        ),
      ),
    );
  }, BillingActions.billingInfo.deletePaymentMethodFlow);

  refreshInvoicesHistory$ = createSubStateRefreshEffect(
    BillingActions.invoicesHistory._,
    fromBillingState.fromInvoicesHistory._,
    {
      actions$: this.actions$,
      store: this.store,
    },
    'invoicesHistory',
  );

  refreshPriceOffersHistory$ = createSubStateRefreshEffect(
    BillingActions.priceOffersHistory._,
    fromBillingState.fromPriceOffersHistory._,
    {
      actions$: this.actions$,
      store: this.store,
    },
    'priceOffersHistory',
  );

  refreshProFormaInvoicesHistory$ = createSubStateRefreshEffect(
    BillingActions.proFormaInvoicesHistory._,
    fromBillingState.fromProFormaInvoicesHistory._,
    {
      actions$: this.actions$,
      store: this.store,
    },
    'proFormaInvoicesHistory',
  );

  refreshCreditsHistory$ = createSubStateRefreshEffect(
    BillingActions.creditHistory._,
    fromBillingState.fromCreditHistory._,
    {
      actions$: this.actions$,
      store: this.store,
    },
    'creditHistory',
  );

  refreshBillingInfo$ = createSubStateRefreshEffect(
    BillingActions.billingInfo,
    fromBillingState.fromBillingInfo,
    {
      actions$: this.actions$,
      store: this.store,
    },
    'billingInfo',
  );

  refreshNextPayment$ = createSubStateRefreshEffect(
    BillingActions.nextPayment,
    fromBillingState.fromNextPayment,
    {
      actions$: this.actions$,
      store: this.store,
    },
    'nextPayment',
  );

  refreshPaymentInfo$ = createSubStateRefreshEffect(
    BillingActions.paymentInfo,
    fromBillingState.fromPaymentInfo,
    {
      actions$: this.actions$,
      store: this.store,
    },
    'paymentInfo',
  );

  refreshSubscription$ = createSubStateRefreshEffect(
    BillingActions.subscription,
    fromBillingState.fromSubscription,
    {
      actions$: this.actions$,
      store: this.store,
    },
    'subscription',
  );
}
