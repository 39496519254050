import { createFeatureSelector, createSubStateSelectors } from '@gv/state';
import { createSelector } from '@ngrx/store';

import { billingFeatureKey } from './state';
import type { BillingFeatureState } from './state';

const getState = createFeatureSelector<BillingFeatureState>(billingFeatureKey);

const getPaymentInfoState = createSelector(
  getState,
  (state) => state.paymentInfo,
);

const getPlatformPlansState = createSelector(
  getState,
  (state) => state.platformPlansInfo,
);

const getBillingInfoState = createSelector(
  getState,
  (state) => state.billingInfo,
);

const getSubscriptionState = createSelector(
  getState,
  (state) => state.subscription,
);

const getNextPaymentState = createSelector(
  getState,
  (state) => state.nextPayment,
);

const getOrganizationState = createSelector(
  getState,
  (state) => state.organization,
);

const getInvoicesHistoryState = createSelector(
  getState,
  (state) => state.invoicesHistory,
);
const getProFormaInvoicesHistoryState = createSelector(
  getState,
  (state) => state.proFormaInvoicesHistory,
);
const getPriceOffersHistoryState = createSelector(
  getState,
  (state) => state.priceOffersHistory,
);

const getCreditHistoryState = createSelector(
  getState,
  (state) => state.creditHistory,
);

const getAppThemesState = createSelector(getState, (state) => state.appThemes);

export const temporaryTheme = createSelector(
  getState,
  (state) => state.temporaryTheme,
);

export const fromPaymentInfo = createSubStateSelectors(
  getPaymentInfoState,
  'paymentInfo',
);

export const fromPlatformPlansInfo = createSubStateSelectors(
  getPlatformPlansState,
  'platformPlansInfo',
);

export const fromSubscription = createSubStateSelectors(
  getSubscriptionState,
  'subscription',
);

export const fromNextPayment = createSubStateSelectors(
  getNextPaymentState,
  'nextPayment',
);

const _fromOrganization = createSubStateSelectors(
  getOrganizationState,
  'organization',
);

export const fromOrganization = {
  ..._fromOrganization,
  getMembers: createSelector(
    _fromOrganization.getOrganization,
    (org) => org?.members || [],
  ),
};

export const fromAppThemes = createSubStateSelectors(
  getAppThemesState,
  'appThemes',
);

export const isApplyingPromoCode = createSelector(
  getState,
  (state) => state.applyingPromoCode,
);

export const promoCodeError = createSelector(
  getState,
  (state) => state.promoCodeError,
);

export const billingError = createSelector(
  getState,
  (state) =>
    state.paymentInfo.error ||
    state.platformPlansInfo.error ||
    state.subscription.error ||
    state.billingInfo.error,
);

const baseInvoicesSelectors = createSubStateSelectors(
  getInvoicesHistoryState,
  'invoicesHistory',
);
const baseProFormaInvoicesSelectors = createSubStateSelectors(
  getProFormaInvoicesHistoryState,
  'proFormaInvoicesHistory',
);
const basePriceOffersSelectors = createSubStateSelectors(
  getPriceOffersHistoryState,
  'priceOffersHistory',
);

const baseCreditSelectors = createSubStateSelectors(
  getCreditHistoryState,
  'creditHistory',
);

const baseBillingInfo = createSubStateSelectors(
  getBillingInfoState,
  'billingInfo',
);

const getActiveBenefits = createSelector(
  baseBillingInfo.getBillingInfo,
  (info) => {
    const now = Date.now();
    return !info?.benefits
      ? undefined
      : info.benefits.filter(
          (benefit) =>
            !benefit.expiration || now < new Date(benefit.expiration).getTime(),
        );
  },
);

export const fromBillingInfo = {
  ...baseBillingInfo,
  getActiveBenefits,
};

export interface DocumentListDataModel {
  uuid: string;
  dtCreated: string;
  items: readonly { name: string }[];
  totalPrice: number;
  currency: string;
  expired?: string;
  moduleName?: string;
  pastDue?: boolean;
  totalCurrencyPrice?: number;
  paymentProcessed?: boolean;
  vat?: number;
}

export const fromInvoicesHistory = {
  _: baseInvoicesSelectors,
  ...baseInvoicesSelectors,
  getInvoicesHistory: createSelector(
    baseInvoicesSelectors.getInvoicesHistory,
    (state) => state?.invoices ?? [],
  ),

  getTotalCount: createSelector(
    baseInvoicesSelectors.getInvoicesHistory,
    (state) => state?.count,
  ),

  getPaginationInfo: createSelector(
    baseInvoicesSelectors.getInvoicesHistory,
    getInvoicesHistoryState,
    (state, mainState) => ({
      count: state?.count,
      offset: state?.offset,
      limit: state?.limit,
      range: mainState.range,
      end: mainState.noFilterEnd,
    }),
  ),
};

export const fromProFormaInvoicesHistory = {
  _: baseProFormaInvoicesSelectors,
  ...baseProFormaInvoicesSelectors,
  getProFormaInvoicesHistory: createSelector(
    baseProFormaInvoicesSelectors.getProFormaInvoicesHistory,
    (state) => state?.proFormaInvoices ?? [],
  ),

  getTotalCount: createSelector(
    baseProFormaInvoicesSelectors.getProFormaInvoicesHistory,
    (state) => state?.count,
  ),

  getPaginationInfo: createSelector(
    baseProFormaInvoicesSelectors.getProFormaInvoicesHistory,
    getProFormaInvoicesHistoryState,
    (state, mainState) => ({
      count: state?.count,
      offset: state?.offset,
      limit: state?.limit,
      range: mainState.range,
      end: mainState.noFilterEnd,
    }),
  ),
};

export const fromPriceOffersHistory = {
  _: basePriceOffersSelectors,
  ...basePriceOffersSelectors,
  getPriceOffersHistory: createSelector(
    basePriceOffersSelectors.getPriceOffersHistory,
    (state) => state?.priceOffers ?? [],
  ),

  getTotalCount: createSelector(
    basePriceOffersSelectors.getPriceOffersHistory,
    (state) => state?.count,
  ),

  getPaginationInfo: createSelector(
    basePriceOffersSelectors.getPriceOffersHistory,
    getPriceOffersHistoryState,
    (state, mainState) => ({
      count: state?.count,
      offset: state?.offset,
      limit: state?.limit,
      range: mainState.range,
      end: mainState.noFilterEnd,
    }),
  ),
};

export const fromCreditHistory = {
  _: baseCreditSelectors,
  ...baseCreditSelectors,
  getCreditHistory: createSelector(
    baseCreditSelectors.getCreditHistory,
    (state) => state?.history ?? [],
  ),

  getTotalCount: createSelector(
    baseCreditSelectors.getCreditHistory,
    (state) => state?.count,
  ),

  getPaginationInfo: createSelector(
    baseCreditSelectors.getCreditHistory,
    getCreditHistoryState,
    (state, mainState) => ({
      count: state?.count,
      offset: state?.offset,
      limit: state?.limit,
      range: mainState.range,
      end: mainState.noFilterEnd,
    }),
  ),
};
