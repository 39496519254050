import { NgClass } from '@angular/common';
import {
  Directive,
  Input,
  ElementRef,
  ViewChild,
  Injector,
  signal,
  inject,
  Component,
  InjectionToken,
  effect,
  TemplateRef,
  ViewContainerRef,
  booleanAttribute,
} from '@angular/core';
import type { EmbeddedViewRef, AfterViewInit } from '@angular/core';
import { MatFormField, MAT_ERROR } from '@angular/material/form-field';

import { TestIdModule } from '@gv/debug';
import { lifecycleAwareTimeout, untilNgDestroyed } from '@gv/utils';
import type { Observable } from 'rxjs';
import { defer, startWith, map, debounceTime, of } from 'rxjs';

export const SHOW_ERROR = new InjectionToken<Observable<boolean>>('showError');

@Directive({
  standalone: true,
  providers: [
    {
      provide: SHOW_ERROR,
      useValue: of(true),
    },
  ],
  selector: '[gvForceError]',
})
export class StandaloneErrorDirective {}

@Component({
  standalone: true,
  selector: 'gv-validation-error',
  imports: [NgClass, TestIdModule],
  template: `
    <ng-template #tpl>
      <div
        class="mat-mdc-form-field-subscript-wrapper mat-mdc-form-field-bottom-align mat-mdc-form-field-subscript-dynamic-size text-red-500"
      >
        <div
          class="mat-mdc-form-field-error-wrapper"
          gvId="error"
          [ngClass]="{
            'overflow-visible whitespace-nowrap': enlarge,
            'w-[150%] overflow-visible whitespace-pre-line': oversize
          }"
        >
          <ng-content></ng-content>
        </div>
      </div>
    </ng-template>
  `,
  providers: [
    {
      provide: MAT_ERROR,
      useExisting: ValidationErrorComponent,
    },
  ],
})
export class ValidationErrorComponent implements AfterViewInit {
  protected injector = inject(Injector);
  private el = inject<ElementRef<HTMLElement>>(ElementRef);
  private showError$ = inject(SHOW_ERROR, { optional: true });
  private formField = inject(MatFormField, { optional: true });
  protected vcr = inject(ViewContainerRef);

  @ViewChild('tpl') protected tplTooltip!: TemplateRef<any>;

  private ref: EmbeddedViewRef<any> | undefined;

  @Input({ transform: booleanAttribute })
  enlarge = false;

  @Input({ transform: booleanAttribute })
  oversize = false;

  forceS = signal<boolean>(false);

  @Input({ transform: booleanAttribute })
  set force(value: boolean) {
    this.forceS.set(value);
  }

  get force(): boolean {
    return this.forceS();
  }

  protected showS = signal(false);

  private show$ =
    (
      this.showError$?.pipe(debounceTime(0)) ||
      (this.formField &&
        defer(() => this.formField!._control.stateChanges)
          .pipe(startWith(undefined))
          .pipe(map(() => this.formField!._control.errorState)))
    )?.pipe(untilNgDestroyed()) ??
    (() => {
      throw new Error('MatFormField or SHOW_ERROR must be defined');
    })();

  private createShowHideEffect = () =>
    effect(
      (cleanup) => {
        if (!this.showS() && !this.forceS()) {
          return;
        }

        this.show();
        cleanup(() => {
          this.hide();
        });
      },
      { injector: this.injector, allowSignalWrites: true },
    );

  ngAfterViewInit(): void {
    lifecycleAwareTimeout(
      () => {
        this.show$.subscribe((show) => this.showS.set(show));
        this.createShowHideEffect();
      },
      0,
      this.injector,
    );
  }

  private show(): void {
    this.hide();
    const el =
      (this.formField?._elementRef?.nativeElement as HTMLElement | undefined) ??
      this.el.nativeElement;
    if (!el) {
      return;
    }
    lifecycleAwareTimeout(
      () => {
        this.ref = this.tplTooltip.createEmbeddedView({});
        this.vcr.insert(this.ref);
        const fragment = new DocumentFragment();
        this.ref.rootNodes.forEach((f) => fragment.appendChild(f as Node));
        this.ref.detectChanges();
        el.insertBefore(
          fragment,
          el.querySelector('.mat-mdc-form-field-subscript-wrapper'),
        );
      },
      0,
      this.injector,
    );
  }

  private hide(): void {
    this.ref?.destroy();
    this.ref = undefined;
  }
}
