import { inject, Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TimeoutService {
  private ngZone = inject(NgZone);

  clearTimeout(id: ReturnType<typeof setTimeout>): void {
    clearInterval(id);
  }

  setTimeout(
    callback: () => void,
    duration: number,
  ): ReturnType<typeof setTimeout> {
    return this.ngZone.runOutsideAngular(() => {
      return setTimeout(callback, duration);
    });
  }
}
