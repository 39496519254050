export function removeFromArray<T>(array: T[], value: T): boolean {
  const index = array.indexOf(value);

  if (index >= 0) {
    array.splice(index, 1);
    return true;
  }

  return false;
}

export function popFromArray<T>(array: readonly T[]): readonly T[] {
  if (array.length === 0) {
    return array;
  }

  const newArr = [...array];
  newArr.pop();
  return newArr;
}

export function findIndexOfMin(array: number[]): number | undefined {
  let minIndex: number | undefined = undefined;
  let minValue: number | undefined = undefined;

  for (let index = 0; index < array.length; index += 1) {
    const value = array[index];

    if (minIndex === undefined || value <= minValue!) {
      minIndex = index;
      minValue = value;
    }
  }

  return minIndex;
}

export function findIndexOfMax(array: number[]): number | undefined {
  let maxIndex: number | undefined = undefined;
  let maxValue: number | undefined = undefined;

  for (let index = 0; index < array.length; index += 1) {
    const value = array[index];

    if (maxIndex === undefined || value > maxValue!) {
      maxIndex = index;
      maxValue = value;
    }
  }

  return maxIndex;
}
