import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  inject,
  InjectionToken,
} from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

interface DialogRoot {
  elementRef: ElementRef<HTMLElement>;
}

export const DIALOG_ROOT = new InjectionToken<DialogRoot>('dialogRoot');
export const BASE_DIALOG_STYLES = `
  :host {
    --mdc-dialog-supporting-text-color: var(--text-color);
    max-height: inherit;
    min-height: inherit;
    min-width: inherit;
    max-width: inherit;
  }

  :host-context(.fixed-height-dialog) {
    height: 100%;
  }
`;

@Component({
  selector: 'gv-dialog-component',
  standalone: true,
  imports: [DragDropModule, MatIconModule, MatDialogModule, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DIALOG_ROOT,
      useExisting: forwardRef(() => DialogBaseComponent),
    },
  ],
  host: {
    class: 'flex flex-col overflow-hidden',
  },
  template: `<ng-content></ng-content>`,
  styles: [BASE_DIALOG_STYLES],
})
export class DialogBaseComponent {
  elementRef = inject<ElementRef<HTMLElement>>(ElementRef<HTMLElement>);
}
