import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgStore } from '@gv/state';

import { detailFeatureKey, DETAIL_FEATURE_STATE } from '../store/detail/state';
import { detailReducer } from '../store/detail/reducers';
import { DetailEffects } from '../store/detail/effects';

export * from '../store/detail/actions';
export * from '../store/detail/selectors';
export { DETAIL_FEATURE_STATE };

@NgModule({
  imports: [
    StoreModule.forFeature(detailFeatureKey, detailReducer),
    EffectsModule.forFeature([DetailEffects]),
  ],
  providers: [
    {
      provide: DETAIL_FEATURE_STATE,
      useExisting: NgStore,
    },
  ],
})
export class DetailStateModule {}
