import type { ApiResponse } from '@gv/api';
import { ApiErrorResponse } from '@gv/api';

import { BehaviorField } from '../rxjs';

export class RedirectError extends Error {
  constructor(
    public readonly token: string,
    public readonly location: string,
  ) {
    super('Redirected');

    Object.setPrototypeOf(this, RedirectError.prototype);
  }
}

export const reloadingLocks = new BehaviorField<number>(0);

export function redirectToLocation(
  windowRef: Window,
  location: string,
  pathname?: string,
) {
  if (!windowRef) {
    return;
  }

  let redirect = pathname ?? windowRef.location.pathname;

  if (!pathname && windowRef.location.search) {
    redirect += windowRef.location.search;
  }

  // we probably do not want this?
  // if (windowRef.location.hash) {
  //   redirect += windowRef.location.hash;
  // }

  windowRef.location.href = `${location}${redirect}`;
}

export const createThrowIfRedirect = (allow: boolean) =>
  function throwIfRedirect(response: ApiResponse<any>): void {
    if (!allow) {
      return;
    }
    let domainHeader = response.headers?.['x-domain'];
    if (domainHeader && document.location.hostname !== domainHeader) {
      const isDev = /^fe\.|.+\.fe\./.test(document.location.host);
      const hostMatch = /.*((?:dev|staging)-[^.]*)\.(.*)/.exec(
        document.location.host,
      );
      domainHeader =
        !isDev || !hostMatch
          ? domainHeader
          : `${domainHeader.split('.')[0]}-${hostMatch[1]}.${hostMatch[2]}`;
      if (document.location.hostname !== domainHeader) {
        throw new ApiErrorResponse('Invalid domain', {
          ...response,
          status: 302,
          data: {
            location: `https://${domainHeader}`,
          },
        });
      }
    }
  };

export function copyTokenToDomain(
  window: Window,
  location: string,
  token: string,
  afterTokenUpdated: () => Promise<void>,
  pathName?: string,
): Promise<void> {
  reloadingLocks.value += 1;
  return new Promise<void>((resolve) => {
    const frame = document.createElement('iframe');
    const handler = (e: MessageEvent<string>) => {
      try {
        const data = <{ channel: 'FROM_REDIRECT' }>JSON.parse(e.data);
        if (data.channel === 'FROM_REDIRECT') {
          const redirect = () => {
            redirectToLocation(window, location, pathName);
          };

          void afterTokenUpdated()
            .then(redirect)
            .then(resolve)
            .then(() => {
              // do not care about releasing lock; we are navigating away anyway
              // reloadingLocks.value -= 1;
            })
            .catch(() => {
              reloadingLocks.value -= 1;
            });
        }
        window.removeEventListener('message', handler);
      } catch (_) {
        //
      }
    };
    window.addEventListener('message', handler);
    frame.style.display = 'none';
    frame.src = `${location}/static/redirect?auth=${token}`;
    document.body.appendChild(frame);
  });
}
