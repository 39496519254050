import { HttpErrorResponse } from '@angular/common/http';

import type { MonoTypeOperatorFunction } from 'rxjs';
import { ApiErrorResponse } from '@gv/api';

import { RetryMode, retryOnError } from './retry-on-error';

export function retryOnNetworkError<T>(
  waitTime: number,
  maxTime: number,
  limit = Infinity,
): MonoTypeOperatorFunction<T> {
  return retryOnError({
    predicate: (error) =>
      (error instanceof ApiErrorResponse ||
        error instanceof HttpErrorResponse) &&
      error.status === 0,
    waitTime,
    maxWaitTime: maxTime,
    limit,
    retryMode: RetryMode.NumberOfRetries,
  });
}
