<gv-simple-dialog>
  <ng-container *gvDialogContent [ngSwitch]="state">
    <div class="relative flex w-full flex-col items-center justify-center">
      <ng-container *ngIf="SlugHashUtils.hasSlug(); else gvLogo">
        <img
          class="mx-auto mb-8 mt-4 h-20 w-20"
          [src]="SlugHashUtils.getSquareLogoUrl()"
        />
      </ng-container>
      <ng-template #gvLogo>
        <img
          class="mx-auto mb-8 mt-4 h-20 w-20"
          src="./assets/images/logo.png"
        />
      </ng-template>

      <div *ngSwitchCase="UpdateState.Available" class="text-center">
        <div
          class="mb-6 text-lg font-semibold"
          i18n="@@update-dialog.new-version"
        >
          New version available
        </div>

        <span i18n="@@update-dialog.new-version-msg">
          Please update the app to a new version to activate the last changes.
        </span>
      </div>

      <div *ngSwitchCase="UpdateState.Downloading" class="mb-6 w-full">
        <div i18n="@@update-dialog.downloading" class="w-full text-center"
          >Downloading...</div
        >

        <mat-progress-bar
          mode="indeterminate"
          class="mt-6"
          *ngIf="!electron || progressS() === undefined; else determinate"
        ></mat-progress-bar>
        <ng-template #determinate>
          <mat-progress-bar
            mode="determinate"
            [value]="progressS()"
            class="mt-6"
            *ngIf="electron"
          ></mat-progress-bar>
        </ng-template>
      </div>

      <div *ngSwitchCase="UpdateState.Done" class="text-center">
        <ng-container *ngIf="electron; else doneNotElectron">
          <span i18n="@@update-dialog.install-msg">
            Please click the button below to install the update
          </span>
        </ng-container>

        <ng-template #doneNotElectron>
          <span i18n="@@update-dialog.updated">
            Your app is now up to date. Click the button below to get the latest
            updates
          </span>
        </ng-template>
      </div>

      <div *ngSwitchCase="UpdateState.DoneInitially" class="text-center">
        <div
          class="mb-6 text-lg font-semibold"
          i18n="@@update-dialog.new-version-initially"
        >
          New version available
        </div>

        <span i18n="@@update-dialog.reload-page">
          Please reload the page to get the latest updates.
        </span>
      </div>
    </div>
  </ng-container>

  <ng-container *gvDialogActions [ngSwitch]="state">
    <ng-container *ngSwitchCase="UpdateState.Available">
      <button
        type="submit"
        class="btn btn-dialog btn-green w-full"
        (click)="download()"
        i18n="@@update-dialog.update-now"
      >
        Update now
      </button>

      <button
        [mat-dialog-close]="false"
        class="btn btn-dialog w-full"
        i18n="@@button.cancel"
        type="button"
      >
        Cancel
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="UpdateState.Done">
      <button
        *ngIf="electron; else doneNotElectron"
        type="submit"
        class="btn btn-dialog btn-green w-full"
        (click)="reload()"
        i18n="@@update-dialog.install"
      >
        Install
      </button>

      <ng-template #doneNotElectron>
        <button
          type="submit"
          class="btn btn-dialog btn-green w-full"
          (click)="reload()"
          i18n="@@update-dialog.reload"
        >
          Reload
        </button>
      </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="UpdateState.DoneInitially">
      <button
        type="submit"
        class="btn btn-dialog btn-green w-full"
        (click)="reload()"
        i18n="@@update-dialog.reload"
      >
        Reload
      </button>

      <button
        [mat-dialog-close]="false"
        class="btn btn-dialog w-full"
        i18n="@@button.cancel"
        type="button"
      >
        Cancel
      </button>
    </ng-container>
  </ng-container>
</gv-simple-dialog>
