import { Injectable } from '@angular/core';

import { removeFromArray } from '@gv/utils';
import { isFunction } from 'lodash-es';
import type { Observable } from 'rxjs';
import { Subject, filter, of } from 'rxjs';

export type HighlighterReceiver<
  T extends Record<string, any> = Record<string, any>,
> =
  | {
      highlight(data: T): void;
    }
  | ((data: T) => void);

@Injectable({
  providedIn: 'root',
})
export class HighlighterProxy {
  private listeners: Record<string, HighlighterReceiver<any>[]> = {};

  private notifier = new Subject<void>();

  register<T extends Record<string, any> = Record<string, any>>(
    key: string,
    listener: HighlighterReceiver<T>,
  ): void {
    this.listeners[key] ??= [];
    this.listeners[key].push(listener);
    this.notifier.next();
  }

  unregister<T extends Record<string, any> = Record<string, any>>(
    key: string,
    listener: HighlighterReceiver<T>,
  ): void {
    if (!this.listeners[key]) {
      return;
    }
    removeFromArray(this.listeners[key] ?? [], listener);
  }

  emit(key: string, data: Record<string, any>): void {
    for (const listener of this.listeners[key] ?? []) {
      if (isFunction(listener)) {
        listener(data);
      } else {
        listener.highlight(data);
      }
    }
  }

  waitFor(key: string): Observable<void> {
    if (this.listeners[key]?.length) {
      return of(undefined);
    }

    return this.notifier.pipe(
      filter(() => Boolean(this.listeners[key]?.length)),
    );
  }
}
