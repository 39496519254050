/**
 * This is a partially generated file. Some edits may be overwritten!
 * @apiRef {@model FileState}
 */
export enum FileState {
  New = 'NEW',
  Uploading = 'UPLOADING',
  Lost = 'LOST',
  Uploaded = 'UPLOADED',
  Verifying = 'VERIFYING',
  Ready = 'READY',
  Invalid = 'INVALID',
  Error = 'ERROR',
  NeedsChecking = 'NEEDS_CHECKING',
}
