import type { OnDestroy } from '@angular/core';
import { Injectable, inject } from '@angular/core';

import { ClientLogLevel } from '@gv/api';
import { sendLog } from '@gv/client-logger';
import { EMPTY_STATE, StoreInject } from '@gv/state';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';

import { UserService } from '../../application/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class UserLoggerService implements OnDestroy {
  private userService = inject(UserService);
  private store = inject(StoreInject(EMPTY_STATE));
  private sub = new Subscription();

  ngOnDestroy(): void {
    this.destroySubscriptions();
  }

  enable(): void {
    this.destroySubscriptions();

    this.createSubscriptions();
  }

  private createSubscriptions(): void {
    this.sub = new Subscription();

    this.sub.add(
      this.userService.loggedIn$.subscribe(() => {
        const userUuid = this.userService.user?.uuid;
        if (!userUuid) {
          return;
        }

        this.store.dispatch(
          sendLog({
            datetime: DateTime.now().toISO(),
            level: ClientLogLevel.Info,
            message: `User logged in (user:${userUuid}).`,
          }),
        );
      }),
    );

    this.sub.add(
      this.userService.loggedOut$.subscribe((userUuid) => {
        if (!userUuid) {
          return;
        }

        this.store.dispatch(
          sendLog({
            datetime: DateTime.now().toISO(),
            level: ClientLogLevel.Info,
            message: `User logged in (user:${userUuid}).`,
          }),
        );
      }),
    );
  }

  private destroySubscriptions(): void {
    this.sub?.unsubscribe();
    this.sub = undefined;
  }
}
