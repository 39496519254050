import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import type { OnDestroy } from '@angular/core';
import { Injectable, NgZone, inject } from '@angular/core';

import { API_LAMBDA, ClientLogLevel } from '@gv/api';
import { ClientLoggerService } from '@gv/client-logger';
import { ElectronAppInfoService } from '@gv/desktop/core';
import { logStore } from '@gv/logger';
import { EMPTY_STATE, getSync, StoreInject } from '@gv/state';
import {
  errorReportUpload,
  REPORT_ERROR_META_FACTORY,
  safeJsonStringify,
} from '@gv/ui/help';
import { ConnectionStatusCheckerService } from '@gv/ui/utils';
import {
  calculateChunkCountFromFileSize,
  calculateChunkBoundaries,
  HashUtils,
} from '@gv/upload/core';
import {
  FileUploadStarted,
  MultipartFileUploadHttpResponse,
  uploadConfig,
} from '@gv/upload/types';
import type {
  FilePartUploadModel,
  FileUploadEvent,
  FileUploadModel,
  S3FileUploadOptionsModel,
  UploadFile,
} from '@gv/upload/types';
import { USER_CONTEXT } from '@gv/user';
import {
  PromiseUtils,
  removeFromArray,
  simpleSwitchMap,
  toSentryError,
  untilNgDestroyed,
} from '@gv/utils';
import { addBreadcrumb, captureException, withScope } from '@sentry/angular';
import axios from 'axios';
import { throttle } from 'lodash-es';
import { DateTime } from 'luxon';
import type { Subscriber, TeardownLogic } from 'rxjs';
import {
  from,
  lastValueFrom,
  merge,
  BehaviorSubject,
  concat,
  defer,
  firstValueFrom,
  Observable,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  timeout,
} from 'rxjs/operators';

import { VideoUploadSpeedEstimatorService } from './effects/video-upload-speed-estimator.service';
import { logger } from './logger';

function waitForRetry(): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, 8000);
  });
}

function hasNextChunk(fileUpload: FileUploadModel): boolean {
  const { nextChunk, failedChunks, chunks } = fileUpload;
  return !!failedChunks.length || nextChunk < chunks;
}

function getNextChunk(fileUpload: FileUploadModel): number {
  const { nextChunk, failedChunks, chunks } = fileUpload;

  if (failedChunks.length) {
    const failedChunk = failedChunks.pop();
    return failedChunk;
  }

  if (nextChunk === chunks) {
    return -1;
  }

  fileUpload.nextChunk += 1;
  return nextChunk;
}

function findItemWithNextChunk(
  queue: readonly FileUploadModel[],
): FileUploadModel | undefined {
  for (let i = 0; i < queue.length; i += 1) {
    if (hasNextChunk(queue[i])) {
      return queue[i];
    }
  }
  return undefined;
}

@Injectable({
  providedIn: 'root',
})
export class S3FileUploadService implements OnDestroy {
  private store = inject(StoreInject(EMPTY_STATE));
  private lapi = inject(API_LAMBDA);
  private httpClient = inject(HttpClient);
  private connectionStatus = inject(ConnectionStatusCheckerService);
  private clientLogger = inject(ClientLoggerService);
  private userContext = inject(USER_CONTEXT);
  private ngZone = inject(NgZone);
  private estimator = inject(VideoUploadSpeedEstimatorService);
  private reporterErrors = new Set<string>();
  private electronAppInfoService = inject(ElectronAppInfoService, {
    optional: true,
  });
  private metaFactory = inject(REPORT_ERROR_META_FACTORY);
  static readonly className: string = 'S3FileUploadService';

  static readonly fileReadError = 'failed-to-read-file';

  private progressEventReceivedSubject = new Subject<void>();

  private progressEventReceived$ =
    this.progressEventReceivedSubject.asObservable();

  private queueSizeSubject = new BehaviorSubject<number>(0);

  queueSize$: Observable<number> = this.queueSizeSubject.asObservable();

  private queue: FileUploadModel[] = [];

  private inFlightRequests: Map<Subscription, string> = new Map();

  private inFlightPromises: ReadonlyArray<Promise<void>> = [];

  private paused = true;

  private _running = new BehaviorSubject<boolean>(false);

  private running$ = this._running.asObservable();

  private get running(): boolean {
    return this._running.getValue();
  }

  private set running(running: boolean) {
    if (this.running !== running) {
      this._running.next(running);
    }
  }

  readonly requestsStuck$: Observable<boolean> = this.running$.pipe(
    distinctUntilChanged(),
    startWith(false),
    simpleSwitchMap(() => {
      const observable: Observable<boolean> = this.progressEventReceived$.pipe(
        map(() => false),
        timeout({
          each: uploadConfig.uploadRestart.networkStuckDetectionThreshold,
          with: () =>
            concat(
              of(true),
              defer(() => observable),
            ),
        }),
      );

      return observable;
    }, of(false)),
    distinctUntilChanged(),
    untilNgDestroyed(),
    shareReplay(1),
  );

  private notifyProgressReceived = throttle(() => {
    this.progressEventReceivedSubject.next();
  }, uploadConfig.multipartUploadThrottleTimePerChunk);

  private freeSlots: number = uploadConfig.maxChunksAtOnce;
  private maxRetries: number = uploadConfig.retry.limit;

  private subscription: Subscription | undefined;

  ngOnDestroy(): void {
    this.disable();
  }

  getQueue(): readonly FileUploadModel[] {
    return this.queue;
  }

  enable(): void {
    this.subscription?.unsubscribe();
    this.subscription = new Subscription();

    this.subscription.add(
      this.connectionStatus.statusChanged$
        .pipe(startWith(this.connectionStatus.connected))
        .subscribe((connected) => {
          if (connected) {
            this.resume();
          } else {
            void this.pause();
          }
        }),
    );

    this.subscription.add(
      this.connectionStatus.connectionStatus$
        .pipe(
          filter((isConnected) => !isConnected),
          switchMap(() => {
            return of({}).pipe(
              delay(uploadConfig.uploadRestart.offlineDelay),
              takeUntil(this.progressEventReceived$),
            );
          }),
        )
        .subscribe(() => {
          // Cancel pending requests and start regular retry loop.
          // This is here to fix issue when some browsers (Firefox) do not cancel
          // requests when network is lost
          void this.restart();
        }),
    );

    this.subscription.add(
      this.requestsStuck$.subscribe((stuck) => {
        if (stuck) {
          withScope((scope) => {
            scope.setLevel('info');
            scope.setFingerprint([
              `${S3FileUploadService.className}::uploadStuckDetection`,
            ]);
            captureException(
              toSentryError(
                `${S3FileUploadService.className}::uploadStuckDetection`,
                new Error('UploadStuck'),
              ),
            );
          });
        }
      }),
    );
  }

  disable(): void {
    this.subscription?.unsubscribe();
    this.subscription = undefined;

    void this.pause();
  }

  removeUnfinishedUploads(): void {
    for (const requestSubscription of this.inFlightRequests.keys()) {
      requestSubscription.unsubscribe();
    }

    this.inFlightRequests.clear();
    this.queue.length = 0;
    this.queueChanged();
  }

  multipartUpload(
    s3FileUploadOptions: S3FileUploadOptionsModel,
  ): Observable<FileUploadEvent> {
    return new Observable<FileUploadEvent>(
      (observer: Subscriber<FileUploadEvent>): TeardownLogic => {
        const {
          file: { size },
        } = s3FileUploadOptions;

        const chunks = calculateChunkCountFromFileSize(size);
        if (chunks <= 0) {
          observer.error('Nothing to upload!');
          observer.complete();
          return;
        }

        const offset = chunks - s3FileUploadOptions.presignedUrls.length;

        const filePartUploads: FilePartUploadModel[] = [];

        if (s3FileUploadOptions.presignedUrls.length !== chunks) {
          // some chunks were already uploaded, skip them
          for (let chunk = 0; chunk < offset; chunk += 1) {
            const chunkBoundaries = calculateChunkBoundaries(size, chunk + 1);

            filePartUploads.push({
              isCompleted: true,
              loadedBytes: chunkBoundaries.to - chunkBoundaries.from,
              partNumber: chunk + 1,
              eTag: s3FileUploadOptions.existingParts[chunk],
            });
          }
        }

        const fileUpload: FileUploadModel = {
          ...s3FileUploadOptions,
          chunks,
          failedChunks: [],
          offset,
          started: false,
          nextChunk: offset,
          completedChunks: offset,
          filePartUploads,
          failedRetries: 0,
          observer,
          fileReadErrorDetected: false,
        };

        this.queue.push(fileUpload);
        this.queueChanged();

        this.tryToUploadNextChunk();

        // teardown logic
        return () => {
          const index = this.queue.findIndex(
            (upload) => upload.uploadId === fileUpload.uploadId,
          );
          if (index < 0) {
            return;
          }

          this.queue.splice(index, 1);
          this.queueChanged();

          for (const [sub, id] of this.inFlightRequests.entries()) {
            if (id === fileUpload.uploadId) {
              sub.unsubscribe();
              this.inFlightRequests.delete(sub);
            }
          }
        };
      },
    );
  }

  async restart(): Promise<void> {
    await this.pause();
    this.resume();

    if (this.queue.length > 0) {
      withScope((scope) => {
        scope.setLevel('info');
        scope.setFingerprint([
          `${S3FileUploadService.className}::${this.restart.name}()`,
        ]);
        captureException(
          toSentryError(
            `${S3FileUploadService.className}::${this.restart.name}()`,
            new Error('UploadRestarted'),
          ),
        );
      });
    }
  }

  private async pause(): Promise<void> {
    this.paused = true;

    if (this.queue.length > 0) {
      addBreadcrumb({
        category: 'upload',
        level: 'info',
        message: 'Upload paused',
      });
      const userUuid: string = this.userContext.userS()?.uuid;
      void this.sendClientLog(
        `Upload paused (user:${userUuid})`,
        ClientLogLevel.Info,
      );
    }

    for (const requestSubscription of this.inFlightRequests.keys()) {
      requestSubscription.unsubscribe();
    }
    this.inFlightRequests.clear();

    await PromiseUtils.softAll(this.inFlightPromises);
  }

  private resume(): void {
    this.paused = false;

    if (this.queue.length > 0) {
      addBreadcrumb({
        category: 'upload',
        level: 'info',
        message: 'Upload resumed',
      });

      const userUuid: string = this.userContext.userS()?.uuid;

      void this.sendClientLog(
        `Upload resumed (user:${userUuid})`,
        ClientLogLevel.Info,
      );
    }

    for (const fileUpload of this.queue) {
      fileUpload.failedRetries = 0;
    }

    this.tryToUploadNextChunk();
  }

  private tryToUploadNextChunk(): void {
    if (this.paused) {
      this.running = false;
      return;
    }

    if (this.queue.length === 0) {
      this.running = false;
      return;
    }

    if (this.running && this.freeSlots === 0) {
      return;
    }

    this.running = true;
    let _fileUpload: FileUploadModel | undefined;
    while (
      this.freeSlots > 0 &&
      (_fileUpload = findItemWithNextChunk(this.queue))
    ) {
      const fileUpload = _fileUpload;
      this.freeSlots -= 1;

      const p = this.uploadNextChunk(fileUpload)
        .then(() => {
          setTimeout(() => {
            this.freeSlots += 1;
            this.tryToUploadNextChunk();
          });
        })
        .catch((e) => {
          withScope((scope) => {
            scope.setLevel('warning');
            scope.setTag('videoUuid', fileUpload.fileUuid);
            scope.setTag('subMessage', 'Failed to upload file part to s3');
            captureException(
              toSentryError(
                `${S3FileUploadService.className}::${this.tryToUploadNextChunk.name}()`,
                e,
              ),
            );
          });

          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          logger.error({ error: e }, 'Error during upload');

          setTimeout(() => {
            this.freeSlots += 1;
            this.tryToUploadNextChunk();
          });
        });

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.inFlightPromises = [...this.inFlightPromises, p];

      const cleanupFn = () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.inFlightPromises = this.inFlightPromises.filter((p2) => p !== p2);
      };

      p.then(cleanupFn, cleanupFn);
    }
  }

  private async uploadNextChunk(fileUpload: FileUploadModel): Promise<void> {
    if (fileUpload.nextChunk === 0) {
      fileUpload.observer.next(new FileUploadStarted());
    }

    const nextChunk = getNextChunk(fileUpload);
    if (nextChunk < 0) {
      return;
    }

    try {
      await this.uploadFilePart(fileUpload, nextChunk);

      if (fileUpload.completedChunks === fileUpload.chunks) {
        this.removeFromQueue(fileUpload);
      }
    } catch (e) {
      if (!this.queue.includes(fileUpload)) {
        // canceled on purpose
        return;
      }

      if (this.paused) {
        fileUpload.failedChunks.push(nextChunk);
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      logger.error({ nextChunk, error: e }, 'failed to upload chunk');

      // presigned url has expired
      if (
        e instanceof HttpErrorResponse &&
        (e.status === 400 || e.status === 403)
      ) {
        withScope((scope) => {
          scope.setLevel('error');
          scope.setTag('videoUuid', fileUpload.fileUuid);
          scope.setTag('subMessage', 'Presigned url expired');
          captureException(
            toSentryError(
              `${S3FileUploadService.className}::uploadNextChunk()`,
              e,
            ),
          );
        });

        this.removeFromQueue(fileUpload);

        fileUpload.observer.error(e);
        fileUpload.observer.complete();
        return;
      }

      const isNetworkError = e instanceof HttpErrorResponse && e.status === 0;

      if (!isNetworkError) {
        fileUpload.failedRetries += 1;
      }

      if (e === S3FileUploadService.fileReadError) {
        fileUpload.fileReadErrorDetected = true;
      }

      const data = {
        failedChunk: nextChunk,
        failedChunks: fileUpload.failedChunks,
        failedRetries: fileUpload.failedRetries,
        fileName: fileUpload.file.name,
        chunks: fileUpload.chunks,
        completedChunks: fileUpload.completedChunks,
        videoUuid: fileUpload.fileUuid,
      };

      if (fileUpload.failedRetries < this.maxRetries) {
        await waitForRetry();

        fileUpload.failedChunks.push(nextChunk);

        if (!isNetworkError) {
          addBreadcrumb({
            category: 'upload',
            data,
            level: 'warning',
            message: 'Failed to upload file part to s3, trying again',
          });
        }
      } else {
        withScope((scope) => {
          scope.setLevel('error');
          scope.setTag('videoUuid', fileUpload.fileUuid);
          scope.setExtra('uploadInfo', data);
          scope.setTag('subMessage', 'Failed to upload');
          captureException(
            toSentryError(
              `${S3FileUploadService.className}::uploadNextChunk()`,
              e,
            ),
          );
        });

        this.removeFromQueue(fileUpload);

        fileUpload.observer.error(e);
        fileUpload.observer.complete();
      }
    }

    return;
  }

  private removeFromQueue(fileUpload: FileUploadModel): void {
    const removed = removeFromArray(this.queue, fileUpload);
    if (removed) {
      this.queueChanged();
    }
  }

  private async uploadFilePart(
    fileUpload: FileUploadModel,
    chunk: number,
  ): Promise<FileUploadEvent> {
    const url = fileUpload.presignedUrls[chunk - fileUpload.offset];
    if (url === undefined) {
      return Promise.reject(
        `Missing presigned URL for part number '${chunk}'.`,
      );
    }

    const { file, chunks, filePartUploads, observer } = fileUpload;

    const part = {
      loadedBytes: 0,
      isCompleted: false,
      eTag: undefined,
      partNumber: chunk + 1,
    };

    filePartUploads[chunk] = part;

    const chunkBoundaries = calculateChunkBoundaries(file.size, chunk + 1);

    let blob: Blob | ArrayBuffer;
    let dataLength: number;

    if (file instanceof File) {
      blob = file.slice(chunkBoundaries.from, chunkBoundaries.to);
      dataLength = blob.size;

      try {
        await firstValueFrom(
          HashUtils.loadChunk(
            file.slice(chunkBoundaries.from, chunkBoundaries.from + 1),
          ),
        );
      } catch (error) {
        // file not available
        this.notifyProgressReceived();
        return Promise.reject(S3FileUploadService.fileReadError);
      }
    } else {
      try {
        const bytes = await file.slice(
          chunkBoundaries.from,
          chunkBoundaries.to,
        );
        blob = bytes.buffer;
        dataLength = blob.byteLength;
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        logger.error({ error: e }, 'Failed to read file part snapshot');
        // we know that this is likely caused by io issue, so report that we received progress.
        // This will prevent 'you're offline' to be not detected as this type of error can be retried by user anyway
        this.notifyProgressReceived();

        return Promise.reject(S3FileUploadService.fileReadError);
      }
    }

    if (
      file.size === 0 ||
      dataLength !== chunkBoundaries.to - chunkBoundaries.from
    ) {
      this.notifyProgressReceived();

      return Promise.reject(S3FileUploadService.fileReadError);
    }

    if (fileUpload.fileReadErrorDetected) {
      // file is available again, reset counter
      fileUpload.failedRetries = 0;
      fileUpload.fileReadErrorDetected = false;
    }

    this.notifyProgressReceived();
    const request = new HttpRequest<Blob | ArrayBuffer>('PUT', url, blob, {
      reportProgress: true,
    });

    // store file size to prevent wrong calculation of upload progress
    // when network is disconnected and file resides on network storage
    const fileSize = file.size;
    const updateProgress = throttle(() => {
      const totalLoaded = filePartUploads.reduce((previous, fpu) => {
        return previous + fpu.loadedBytes;
      }, 0);

      observer.next({
        loaded: totalLoaded,
        total: fileSize,
        type: HttpEventType.UploadProgress,
      });
    }, uploadConfig.multipartUploadThrottleTimePerChunk);

    return new Promise<FileUploadEvent>((resolve, reject) => {
      this.ngZone.runOutsideAngular(() => {
        let requestSubscription: Subscription = undefined;

        let lastTime = new Date().getTime();
        let lastLoaded = 0;

        requestSubscription = this.httpClient.request<void>(request).subscribe({
          next: (httpEvent) => {
            if (requestSubscription && requestSubscription.closed) {
              // subscription is already closed so doing nothing
              // in ideal case this should not be here but it seems
              // that request is not cancelled properly after unsubscribe
              this.inFlightRequests.delete(requestSubscription);
              return;
            }

            const currentTime = new Date().getTime();

            // not working when msw (mock-service-worker) is imported (local development)
            if (httpEvent.type === HttpEventType.UploadProgress) {
              if (httpEvent.total > 0) {
                part.loadedBytes = httpEvent.loaded;

                this.estimator.reportUploadedBytes({
                  endTime: currentTime,
                  startTime: lastTime,
                  uploaded: httpEvent.loaded - lastLoaded,
                });

                lastLoaded = httpEvent.loaded;
                lastTime = currentTime;

                updateProgress();

                this.notifyProgressReceived();
              }
            } else if (httpEvent instanceof HttpResponse) {
              this.notifyProgressReceived();

              this.inFlightRequests.delete(requestSubscription);

              const eTag = httpEvent.headers.get('ETag').replace(/["]/g, '');

              if (!this.validateETag(eTag)) {
                part.loadedBytes = 0;

                updateProgress();
                reject('invalid-etag');
                return;
              }

              if (
                file.size === 0 ||
                (blob instanceof Blob &&
                  blob.size !== chunkBoundaries.to - chunkBoundaries.from)
              ) {
                reject(S3FileUploadService.fileReadError);
                return;
              }

              this.estimator.reportUploadedBytes({
                endTime: currentTime,
                startTime: lastTime,
                uploaded: dataLength - lastLoaded,
              });

              part.isCompleted = true;
              part.eTag = eTag;
              fileUpload.completedChunks += 1;

              resolve(httpEvent);

              observer.next({
                chunks: fileUpload.completedChunks,
                type: 'completed-chunks',
              });

              // check if all parts already completed
              if (fileUpload.completedChunks === chunks) {
                const httpResponse = new MultipartFileUploadHttpResponse({
                  body: httpEvent.body,
                  headers: httpEvent.headers,
                  status: httpEvent.status,
                  statusText: httpEvent.statusText,
                  url: httpEvent.url,
                  filePartUploads,
                });

                observer.next(httpResponse);
                observer.complete();
              }
            }
          },
          error: (error) => {
            if (error instanceof HttpErrorResponse) {
              if (error.status !== 0 && error.status !== 500) {
                void this.reportError({
                  error,
                  file,
                  chunk,
                  url,
                  blob,
                  fileUpload,
                });
              }
            }

            this.notifyProgressReceived();
            part.loadedBytes = 0;
            updateProgress();
            reject(error);
          },
        });

        requestSubscription.add(() => {
          if (!part.isCompleted) {
            part.loadedBytes = 0;

            updateProgress();
            this.notifyProgressReceived();

            reject(this.paused ? 'connection-lost' : 'cancelled');
          }
          this.inFlightRequests.delete(requestSubscription);
        });

        this.inFlightRequests.set(requestSubscription, fileUpload.uploadId);
      });
    });
  }

  private validateETag(eTag: string): boolean {
    return typeof eTag !== 'undefined' && eTag !== null && eTag.trim() !== '';
  }

  private sendClientLog(
    message: string,
    clientLogLevel: ClientLogLevel,
  ): Promise<boolean> {
    return this.clientLogger
      .sendClientLog({
        datetime: DateTime.now().toISO(),
        level: clientLogLevel,
        message,
      })
      .then(
        () => true,
        () => false,
      );
  }

  private queueChanged(): void {
    if (this.queue.length !== this.queueSizeSubject.getValue()) {
      this.queueSizeSubject.next(this.queue.length);
    }
  }

  private async reportError(data: {
    error: HttpErrorResponse;
    url: string;
    file: File | UploadFile;
    blob: Blob | ArrayBuffer;
    chunk: number;
    fileUpload: FileUploadModel;
  }): Promise<void> {
    const key = `${data.fileUpload.fileUuid}_${data.error.status}`;
    try {
      if (this.reporterErrors.has(key)) {
        return;
      }
      this.reporterErrors.add(key);

      const meta = {
        ...this.metaFactory(),
        reason: 'upload problem',
        location: window.location.href,
        sessionId: this.electronAppInfoService?.appInfo?.sessionId,
        desktopVersion: this.electronAppInfoService?.appInfo?.version,

        s3Response: {
          url: data.url,
          status: data.error.status,
          message: data.error.message,
          headers: data.error.headers.keys().reduce(
            (acc, key) => {
              acc[key] = data.error.headers.get(key);
              return acc;
            },
            <Record<string, string>>{},
          ),
          body: JSON.stringify(data.error.error, null, 2),
          chunkFileUuid: null,
        },
      };

      const state = getSync(this.store, (state) => state);
      const logs = Array.from(logStore());
      const response = await firstValueFrom(
        this.lapi.reportError({
          headers: {
            'x-system': 'true',
          },
        }),
      );

      const file = await firstValueFrom(
        this.lapi.reportErrorImage(response.data.uuid),
      );

      meta.s3Response.chunkFileUuid = file.data.uuid;

      await lastValueFrom(
        merge(
          from(axios.put(file.data.url, data.blob)),
          errorReportUpload(response.data.urls[0], JSON.stringify(meta), false),
          errorReportUpload(response.data.urls[1], JSON.stringify({}), false),
          errorReportUpload(
            response.data.urls[2],
            safeJsonStringify(state),
            false,
          ),
          errorReportUpload(response.data.urls[4], JSON.stringify(logs), false),
        ),
      );

      await firstValueFrom(this.lapi.reportErrorComplete(response.data.uuid));
    } catch (e) {
      this.reporterErrors.delete(key);

      captureException(
        toSentryError(`${S3FileUploadService.className}::reportError`, e),
      );
    }
  }
}
