import type { MonoTypeOperatorFunction } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { isUndefinedOrNull, isObject } from '../utils/type-utils';

export function distinctUntilKeysChanged<T, K extends keyof T>(
  keys: K[],
  compare?: (x: T, y: T, keys: K[]) => boolean,
): MonoTypeOperatorFunction<T> {
  return distinctUntilChanged((x: T, y: T) => {
    if (x === y) {
      return true;
    }

    if (
      isUndefinedOrNull(x) ||
      isUndefinedOrNull(y) ||
      !isObject(x) ||
      !isObject(y)
    ) {
      return x === y;
    }

    if (compare) {
      return compare(x, y, keys);
    }

    for (const key of keys) {
      if (x[key] !== y[key]) {
        return false;
      }
    }

    return true;
  });
}
