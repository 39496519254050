import type { LiqeQuery } from 'liqe';
import { parse, test } from 'liqe';
import { getLogLevelName } from 'roarr';
import yallist from 'yallist';
import type { LogLevelName, LogWriter, Message } from 'roarr';
import type { JsonObject } from 'roarr/dist/types';

import { DETACHED_FIELD, getDetachedData } from './breadcrumbs';
import { deserializeFromJSON } from './logger';

const MAX_LENGTH = 1000;
let length = 0;
export const _logStore = new yallist<{ payload: Message }>();

export type LogMethods = {
  [key in LogLevelName]: (...data: unknown[]) => void;
};

export type Storage = {
  getItem: (name: string) => string | null;
  setItem: (name: string, value: string) => void;
};

type Configuration = {
  logMethods?: LogMethods;
  storage?: Storage;
  colors?: boolean;
  errorLogging?: boolean;
};

const logLevelColors: {
  [key in LogLevelName]: { backgroundColor: string; color: string };
} = {
  debug: { backgroundColor: '#712bde', color: '#fff' },
  error: { backgroundColor: '#f05033', color: '#fff' },
  fatal: { backgroundColor: '#f05033', color: '#fff' },
  info: { backgroundColor: '#3174f1', color: '#fff' },
  trace: { backgroundColor: '#666', color: '#fff' },
  warn: { backgroundColor: '#f5a623', color: '#000' },
};

const namespaceColors: {
  [key in LogLevelName]: { color: string };
} = {
  debug: { color: '#8367d3' },
  error: { color: '#ff1a1a' },
  fatal: { color: '#ff1a1a' },
  info: { color: '#3291ff' },
  trace: { color: '#999' },
  warn: { color: '#f7b955' },
};

export function expandContextIfNeeded(context: JsonObject) {
  if (!context[DETACHED_FIELD]) {
    return context;
  }
  context = {
    ...context,
    ...(getDetachedData(context[DETACHED_FIELD] as string) || {}),
  };
  delete context[DETACHED_FIELD];
  return context;
}

export const createLogMethods = (): LogMethods => {
  return {
    // eslint-disable-next-line no-console
    debug: (...args: any[]) => console.debug(...args),
    // eslint-disable-next-line no-console
    error: (...args: any[]) => console.error(...args),
    // eslint-disable-next-line no-console
    fatal: (...args: any[]) => console.error(...args),
    // eslint-disable-next-line no-console
    info: (...args: any[]) => console.info(...args),
    // eslint-disable-next-line no-console
    trace: (...args: any[]) => console.debug(...args),
    // eslint-disable-next-line no-console
    warn: (...args: any[]) => console.warn(...args),
  };
};

const findLiqeQuery = (storage: Storage): LiqeQuery | null => {
  const query = storage.getItem('ROARR_FILTER');

  return query ? parse(query) : null;
};

export function logToHtml(item: Message): string {
  const { logLevel: numericLogLevel, namespace, package: pkg } = item.context;

  const logLevelName = getLogLevelName(Number(numericLogLevel));
  const logColor = logLevelColors[logLevelName];
  const styles = `
      background-color: ${logColor.backgroundColor};
      color: ${logColor.color};
      font-weight: bold;
    `;
  const namespaceStyles = `
      color: ${namespaceColors[logLevelName].color};
    `;
  return `
    <span style="${styles}">${logLevelName}</span>
    <span style="${namespaceStyles}">[${String(pkg)}]</span>
    <span style="${namespaceStyles}">${
      namespace ? ` [${String(namespace)}]:` : ''
    }</span>
    <span>${item.message}</span>
  `;
}

function isPayloadDetached(message: Message): boolean {
  return !!message?.context?.[DETACHED_FIELD];
}

export const createLogWriter = (
  configuration: Configuration = {},
): LogWriter => {
  const storage = configuration?.storage ?? globalThis.localStorage;
  const logMethods = createLogMethods();

  if (!storage) {
    return () => {
      // Do nothing.
    };
  }

  if (storage.getItem('ROARR_LOG') !== 'true') {
    return () => {
      // Do nothing.
    };
  }

  const liqeQuery = findLiqeQuery(storage);
  return (message) => {
    const { value: payload, errors } = deserializeFromJSON(message) as {
      value: Message;
      errors: Error[];
    };

    const {
      logLevel: numericLogLevel,
      namespace,
      package: pkg,
      ..._context
    } = payload.context;
    let context = _context;

    _logStore.unshift({
      payload,
    });
    if (!isPayloadDetached(payload)) {
      length++;
    }

    if (length >= MAX_LENGTH) {
      // remove until we get attached
      let _a: undefined | ReturnType<typeof _logStore.pop>;
      while ((_a = _logStore.pop()) && isPayloadDetached(_a?.payload)) {
        //
      }
      if (_a) {
        length--;
      }
    }

    if (liqeQuery && !test(liqeQuery, payload)) {
      return;
    }

    const logLevelName = getLogLevelName(Number(numericLogLevel));
    const logMethod = logMethods[logLevelName];
    context = expandContextIfNeeded(context);

    if (!configuration.colors) {
      if (Object.keys(context).length > 0) {
        logMethod(
          `${logLevelName} ${pkg ? ` [${String(pkg)}]` : ''}${
            namespace ? ` [${String(namespace)}]:` : ''
          } ${payload.message} %O`,
          context,
          ...errors,
        );
      } else {
        logMethod(
          `${logLevelName} ${pkg ? ` [${String(pkg)}]` : ''}${
            namespace ? ` [${String(namespace)}]:` : ''
          } ${payload.message}`,
        );
      }
      return;
    }

    const logColor = logLevelColors[logLevelName];
    const styles = `
      background-color: ${logColor.backgroundColor};
      color: ${logColor.color};
      font-weight: bold;
    `;
    const namespaceStyles = `
      color: ${namespaceColors[logLevelName].color};
    `;
    const resetStyles = `
      color: inherit;
    `;

    if (Object.keys(context).length > 0) {
      logMethod(
        `%c ${logLevelName} %c${pkg ? ` [${String(pkg)}]` : ''}%c${
          namespace ? ` [${String(namespace)}]:` : ''
        }%c ${payload.message} %O`,
        styles,
        namespaceStyles,
        namespaceStyles,
        resetStyles,
        context,
        ...errors,
      );
    } else {
      logMethod(
        `%c ${logLevelName} %c${pkg ? ` [${String(pkg)}]` : ''}%c${
          namespace ? ` [${String(namespace)}]:` : ''
        }%c ${payload.message}`,
        styles,
        namespaceStyles,
        namespaceStyles,
        resetStyles,
      );
    }
  };
};
