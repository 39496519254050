import { ObjectClass, SupportedLanguage } from '@gv/api';

function makeAppConfig() {
  const date = new Date();
  const year = date.getFullYear();

  return {
    /**
     * Client version identifier.
     * May be unknown if app is running locally.
     */
    brand: 'GoodVision',
    footerBrand: 'GoodVision',
    defaultTitle: 'GoodVision Video Insights',
    year,
    productLink: 'https://www.goodvisionlive.com',
    numberOfDaysBeforeExpiration: 30,
    security: {
      expiredTokenThreshold: 30 * 1000, // in ms
      authenticationGuard: {
        toleranceAfterInvalidToken: 10 * 1000, // in ms
      },
    },
    project: {
      maxBudget: 100000,
    },
    trafficAnalytics: {
      reports: {
        vissim: {
          supportedClasses: [
            ObjectClass.Bus,
            ObjectClass.Car,
            ObjectClass.Motorcycle,
            ObjectClass.OGV1,
            ObjectClass.OGV2,
            ObjectClass.Van,
          ],
        },
        saturationFlowReport: {
          endOfSaturatedFlow: {
            defaultValue: 4, // in seconds
            maxValue: 9, // in seconds
            minValue: 1, // in seconds
          },
          minSaturatedFlowIntervalDuration: {
            defaultValue: 13, // in seconds
            maxValue: 99, // in seconds
            minValue: 2, // in seconds
          },
          ignoreVehiclesNumber: {
            defaultValue: 2,
            maxValue: 5,
            minValue: 0,
          },
          minSaturationIntervalsCount: {
            defaultValue: 10,
            maxValue: 30,
            minValue: 5,
          },
          shortenFreeflowInterval: {
            defaultValue: false,
          },
          objectClassWeights: {
            defaultValues: {
              [ObjectClass.Car]: 1,
              [ObjectClass.Van]: 1,
              [ObjectClass.Bus]: 2,
              [ObjectClass.OGV1]: 1.5,
              [ObjectClass.OGV2]: 2.3,
              [ObjectClass.Motorcycle]: 0.4,
              [ObjectClass.Bicycle]: 0.2,
              [ObjectClass.Person]: undefined,
            },
            maxValue: 3,
            minValue: 0,
          },
        },
      },
      scenarios: {
        maxDepth: 4,
      },
    },
    userSettings: {
      userProfile: {
        errorMessage: {
          duration: 3000,
        },
        successMessage: {
          duration: 3000,
        },
      },
    },
    // objectColorFilter: {
    //   objectColors: getSupportedObjectColors(),
    // },
    // objectClassColors: getObjectClassColors(),
    deleteDataSource: {
      successMessage: {
        duration: 3000,
      },
    },
    editDataSource: {
      successMessage: {
        duration: 3000,
      },
    },
    widget: {
      validation: {
        name: {
          maxLength: 3300,
        },
      },
    },
    cookies: {
      lawDisclaimer: '_gv_cl',
      language: '_gv_lang2',
      schemeNotification: '_gv_scheme',
    },
    api: {
      errorMessage: {
        defaultTimeout: 3000, // in ms
      },
      successMessage: {
        defaultTimeout: 3000, // in ms
      },
      retry: {
        maxWaitTime: 10 * 1000, // in ms
        waitTime: 1000, // in ms
        limit: 5,
      },
    },
    authentication: {
      tokenChangeHandlerTimeout: 1000, // in ms
      tokenRefreshInterval: {
        longLived: 60 * 60 * 24 * 7 * 1000, // in ms
        shortLived: 1 * 60 * 60 * 1000, // in ms
      },
    },
    filter: {
      name: {
        maxLength: 200, // max characters allowed
        notPrefix: 'Not',
      },
    },
    timeLimitations: {
      intrusionReport: 60 * 60 * 24 * 7 * 1000, // in ms
      originDestinationMatrixReport: 60 * 60 * 24 * 30 * 3 * 1000, // in ms
      saturationFlowReport: 60 * 60 * 24 * 7 * 1000, // in ms
      tmcReport: 60 * 60 * 24 * 30 * 3 * 1000, // in ms
      widget: 60 * 60 * 24 * 30 * 3 * 1000, // in ms
      vissim: 60 * 60 * 24 * 30 * 3 * 1000, // in ms
    },
    originDestinationConfigurator: {
      minGraphicalObjects: 1,
      maxGraphicalObjects: 10,
    },
    movements: {
      maxDistance: {
        meters: 5000,
        feet: Math.ceil(5000 * 3.2808399),
      },
    },
    validation: {
      errorMessage: {
        defaultTimeout: 3000, // in ms
      },
      userPassword: {
        pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\\d$@$!%*?&].{11,}/,
      },
    },
    appUpdate: {
      checkDelay: 10000, // in ms
      checkInterval: 1000 * 60 * 60, // in ms
    },
    sentry: {
      dsn: 'https://fd9945c9322d4096bfd3a02eeb0c0975@sentry.io/1528188',
      environment: {
        dev: 'dev',
        production: 'production',
        staging: 'staging',
      },
      apiRetry: {
        maxTime: 10 * 1000, // in ms
        waitTime: 1000, // in ms
      },
    },
    vault: {
      fetchEntriesDebounceTimePerDirectory: 300, // in ms
      fetchEntriesLimit: 100,
      maxFetchEntriesLimitGoogle: 1000,
      maxFetchEntriesLimitDropbox: 2000,
      directoryHeight: 47, // needs to be same as $vault-browser-directory-browser-directory-height
    },
    requestsRetry: {
      timeToShowMaintenancePage: 2 * 60 * 1000, // in ms
    },
    clocks: {
      skewedClockOffsetThreshold: 5 * 60 * 1000, // in ms
    },
    intercom: {
      messages: {
        interestedInCustomVaultPlanMessage: $localize`:@@config.interested-custom-vault:I'm interested in custom vault plan`,
        interestedInVissimModelMessage: $localize`:@@config.interested-vissim-model:I'm interested in generating PTV Vissim model`,
      },
    },
    player: {
      videoUuidDataKey: 'uuid',
    },
    languages: [
      {
        pathPrefix: 'en',
        shortcutApi: SupportedLanguage.En,
        title: 'English',
      },
      {
        pathPrefix: 'es',
        shortcutApi: SupportedLanguage.Es,
        title: 'Español',
      },
      {
        pathPrefix: 'pt',
        shortcutApi: SupportedLanguage.Pt,
        title: 'Português',
      },
      // {
      //   pathPrefix: 'de',
      //   shortcutApi: SupportedLanguage.De,
      //   title: $localize`:@@config.lang-de:German`,
      // },
      // {
      //   pathPrefix: 'cs',
      //   shortcutApi: SupportedLanguage.CsCz,
      //   title: $localize`:@@config.lang-cz:Czech`,
      // },
    ],
  };
}

export const appConfig = makeAppConfig();
