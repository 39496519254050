/* eslint-disable @typescript-eslint/no-explicit-any */
import type { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { EMPTY, of, throwError } from 'rxjs';
import {
  defaultIfEmpty,
  delay,
  retry as retryOp,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

export enum RetryMode {
  Custom,
  NumberOfRetries,
}

const noOp = (_err: any) => undefined;

export function retryOnError<T>(options: {
  predicate: (error: any) => boolean;
  waitTime: number;
  maxWaitTime: number;
  limit: number;
  retryMode: RetryMode.NumberOfRetries;
  onRetry?: (err: any) => void;
  preRetry?: (err: any) => void;
  untilObservable?: Observable<any>;
}): MonoTypeOperatorFunction<T>;
export function retryOnError<T>(options: {
  predicate: (error: any) => boolean;
  waitTime: number;
  maxWaitTime: number;
  limit: number;
  retryMode: RetryMode.Custom;
  onRetry?: (err: any) => void;
  preRetry?: (err: any) => void;
  untilObservable?: Observable<any>;
  retry: (error: any) => Observable<any>;
}): MonoTypeOperatorFunction<T>;
export function retryOnError<T>(options: {
  predicate: (error: any) => boolean;
  waitTime: number;
  maxWaitTime: number;
  limit: number;
  retryMode: RetryMode;
  onRetry?: (err: any) => void;
  preRetry?: (err: any) => void;
  untilObservable?: Observable<any>;
  retry?: (error: any) => Observable<any>;
}): MonoTypeOperatorFunction<T> {
  const {
    retryMode,
    limit,
    predicate,
    waitTime,
    maxWaitTime,
    preRetry,
    onRetry,
    untilObservable,
    retry,
  } = options;

  if (
    retryMode === RetryMode.NumberOfRetries &&
    limit > 300 &&
    limit !== Infinity
  ) {
    throw new Error('Invalid number of retries');
  }

  return retryOp({
    delay(error: unknown, retries) {
      if (predicate(error)) {
        retries += 1;

        const runAgainAfter = Math.min(waitTime * retries, maxWaitTime);

        let delayOp: MonoTypeOperatorFunction<any> | undefined;

        switch (retryMode) {
          case RetryMode.NumberOfRetries:
            if (retries <= limit) {
              delayOp = delay(runAgainAfter);
            }
            break;
          case RetryMode.Custom: {
            if (retries < limit) {
              (preRetry || noOp)(error);

              return retry!(error).pipe(
                defaultIfEmpty(error),
                switchMap((e: unknown) =>
                  e === error ? throwError(() => e) : of(e),
                ),
                tap(() => (onRetry || noOp)(error)),
                takeUntil(untilObservable || EMPTY),
              );
            }
            break;
          }
        }

        if (delayOp) {
          return of(undefined).pipe(
            tap(() => (preRetry || noOp)(error)),
            delayOp,
            defaultIfEmpty(error),
            switchMap((e: unknown) =>
              e === error ? throwError(() => e) : of(e),
            ),
            tap(() => (onRetry || noOp)(error)),
            takeUntil(untilObservable || EMPTY),
          );
        }
      }

      return throwError(() => error);
    },
  });
}
