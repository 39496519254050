import WordArray from 'crypto-js/lib-typedarrays';
import SHA256 from 'crypto-js/sha256';
import { defer, from as obsFrom, Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { forkJoinConcurrent } from '@gv/utils';
import type { ChunkBoundariesModel } from '@gv/upload/types';

/**
 * This class should include only essential things for calculating file hash
 it is imported inside webworker and therefore all libraries will be bundled with that web worker
 */
export class HashUtils {
  private static numberOfChunksPerSection = 1;

  private static encode(str: string): Uint8Array {
    if (typeof TextEncoder !== 'undefined') {
      return new TextEncoder().encode(str);
    }

    // eslint-disable-next-line deprecation/deprecation
    const utf8 = unescape(encodeURIComponent(str));
    const result = new Uint8Array(utf8.length);

    for (let i = 0; i < utf8.length; i += 1) {
      result[i] = utf8.charCodeAt(i);
    }

    return result;
  }

  private static sha256(data: ArrayBuffer): Observable<string> {
    if (typeof self.crypto !== 'undefined') {
      return defer(() =>
        obsFrom(self.crypto.subtle.digest('SHA-256', data)),
      ).pipe(
        map((buffer) => Array.from(new Uint8Array(buffer))),
        map((hashArray) =>
          hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''),
        ),
      );
    }

    return defer(() =>
      obsFrom(
        // run in next tick, so we can start loading data for next chunk in the meantime
        Promise.resolve().then((): string => {
          const buffer = WordArray.create(Array.from(new Uint8Array(data)));

          return SHA256(buffer).toString();
        }),
      ),
    );
  }

  static loadChunk(blob: Blob): Observable<ArrayBuffer> {
    const reader = new FileReader();
    return new Observable((obs) => {
      let stopped = false;

      reader.onerror = () => {
        obs.error(reader.error);
        reader.abort();
      };

      reader.onloadend = () => {
        if (stopped) {
          return;
        }

        if (reader.error) {
          return obs.error(reader.error);
        }
        obs.next(reader.result as ArrayBuffer);
        obs.complete();
      };

      reader.readAsArrayBuffer(blob);

      return () => {
        stopped = true;
        reader.abort();
      };
    });
  }

  static calculateChunksBoundaries(
    fileSize: number,
    chunkSize: number,
  ): readonly ChunkBoundariesModel[] {
    if (fileSize <= chunkSize) {
      return [{ from: 0, to: fileSize }];
    }

    const bounds: ChunkBoundariesModel[] = [];

    // hash whole file
    if (fileSize < 3 * HashUtils.numberOfChunksPerSection * chunkSize) {
      for (let from = 0; from < fileSize; from = bounds[bounds.length - 1].to) {
        bounds.push({ from, to: Math.min(fileSize, from + chunkSize) });
      }

      return bounds;
    }

    // hash beggining
    for (let i = 0; i < HashUtils.numberOfChunksPerSection; i += 1) {
      const from = bounds.length > 0 ? bounds[bounds.length - 1].to : 0;
      const to = Math.min(from + chunkSize, fileSize);
      bounds.push({ from, to });
    }

    const endSectionStart =
      fileSize - HashUtils.numberOfChunksPerSection * chunkSize;
    const maxStartOfMiddleSection =
      endSectionStart - HashUtils.numberOfChunksPerSection * chunkSize;

    const middleSectionStart = Math.min(
      maxStartOfMiddleSection,
      Math.ceil(fileSize / 2),
    );

    // hash middle
    for (let i = 0; i < HashUtils.numberOfChunksPerSection; i += 1) {
      const from = middleSectionStart + i * chunkSize;
      const to = from + chunkSize;
      bounds.push({ from, to });
    }

    // hash end
    for (let i = 1; i <= HashUtils.numberOfChunksPerSection; i += 1) {
      const from =
        fileSize - (HashUtils.numberOfChunksPerSection - i + 1) * chunkSize;
      const to =
        fileSize - (HashUtils.numberOfChunksPerSection - i) * chunkSize;
      bounds.push({ from, to });
    }

    return bounds;
  }

  static calculateHash(file: File, chunkSize: number): Observable<string> {
    const fileSize = file.size;
    const boundaries = HashUtils.calculateChunksBoundaries(
      file.size,
      chunkSize,
    );

    const observables = boundaries.map((b) =>
      of(b).pipe(
        mergeMap((bounds) => {
          const blob = file.slice(bounds.from, bounds.to);

          return HashUtils.loadChunk(blob).pipe(
            mergeMap((chunk) => HashUtils.sha256(chunk)),
          );
        }),
      ),
    );

    return forkJoinConcurrent(observables, 2).pipe(
      mergeMap((hashes): Observable<string> => {
        if (hashes.length === 0) {
          return of(undefined);
        }

        const str = `${file.name}-${fileSize}-${hashes.join('')}`;
        const d = HashUtils.encode(str);

        return HashUtils.sha256(d).pipe(
          map((hash) => `${hash}-C${hashes.length}`),
        );
      }),
      take(1),
    );
  }
}
