import { InjectionToken } from '@angular/core';

import type { Observable } from 'rxjs';

import type { UserProperties } from './mixpanel';

export interface MixPanelContext {
  userInfo$: Observable<UserProperties | undefined>;
}

export const MIXPANEL_CONTEXT = new InjectionToken<MixPanelContext>(
  'mixPanelContext',
);
