import { CommonModule } from '@angular/common';
import { computed, CSP_NONCE, inject, NgModule } from '@angular/core';
import type { MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';

import { EFFECTS_ERROR_HANDLER } from '@ngrx/effects';
import { effectsErrorHandler, HIGHLIGHT_MAPPING } from '@gv/state';
import { API, API_LAMBDA, API_URL, CHECK_OUTAGE, HTTP_SERVICE } from '@gv/api';
import {
  BASE_PERMISSION_SERVICE,
  STATE_PROJECT_PERMISSION_SERVICE,
  ProjectPermissionService,
  PermissionService,
} from '@gv/permission';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { StripeConfigModule } from '@gv/ui/billing';
import type { UserContext } from '@gv/user';
import { USER_CONTEXT } from '@gv/user';
import {
  BehaviorSubject,
  filter,
  from,
  map,
  of,
  shareReplay,
  Subject,
  switchMap,
} from 'rxjs';
import { DIALOG_MAPPING } from '@gv/ui/dialog';
import { MixPanelModule, MIXPANEL_CONTEXT } from '@gv/analytics';
import { OutageModule } from '@gv/outage';
import {
  distinctUntilKeysChanged,
  TRANSLATION_LOADER,
  USER_LOCALE,
} from '@gv/utils';
import { USER_I18N_RESOURCES } from '@gv/context';
import { provideDatetimeFormat } from '@gv/time';
import { TranslationLoaderService } from '@gv/ui/utils';
import { ToasterContainerComponent, ToasterService } from '@gv/ui/toaster';
import { ElectronModule } from '@gv/desktop/main';
import { ElectronUploadsPersistanceService } from '@gv/upload/state';
import { DS_PAGE_COMPONENT, VAULT_PAGE_COMPONENT } from '@gv/ui/core';
import { ENVIRONMENT } from '@gv/constant';

import { AppErrorHandlingModule } from '../setup/app-error-handling.module';
import { AppInitializerModule } from '../setup/app-initializer.module';
import { AppRoutingModule } from '../routing/app-routing.module';
import { AppComponent } from '../../component/root/app.component';
import { AuthenticationModule } from '../feature/user-management/authentication/authentication.module';
import { BrowserUtilsModule } from '../feature/util/browser-utils.module';
import { CommonBaseModule } from '../base/common-base.module';
import { ConfigModule } from '../setup/config.module';
import { CookieLawModule } from '../feature/privacy/cookie-law.module';
import { CookieModule } from '../feature/cookie/cookie.module';
import { HttpInterceptorModule } from '../setup/http-interceptor.module';
import { LanguageMismatchDialogModule } from '../feature/language/language-mismatch-dialog.module';
import { LAZY_COMPONENTS } from '../../entity/token/lazy-component';
import { lazyComponentFactory } from '../routing/lazy-components.module';
import { LogoutEverywhereDialogModule } from '../feature/auth/logout-everywhere-dialog.module';
import { MaintenanceModule } from '../feature/maintenance/maintenance.module';
import { StateModule } from './state.module';
import { ApiService } from '../../service/api/api.service';
import { HttpService } from '../../service/api/http.service';
import { stripeApiKey } from '../../entity/constant/3rd-party-service/stripe-api-keys';
import { UserService } from '../../service/application/user/user.service';
import { TokenStoreService } from '../../service/application/user/token-store.service';
import { dialogMapping } from '../../entity/constant/dialog-mapping';
import { higlightMapping } from '../../entity/constant/highlight-mapping';
import { mixPanelFactory } from './mixpanel.factory';
import { LANG_REDIRECT_IN_PROGRESS } from '../../service/helper/localization.service';
import { environment } from '../../../environments/environment';

export const tooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 1500,
  disableTooltipInteractivity: true,
};

@NgModule({
  imports: [
    StateModule,
    // 3rd parties
    CommonModule,
    CommonBaseModule,

    // app
    AppErrorHandlingModule,
    AppInitializerModule,
    AppRoutingModule,
    AuthenticationModule,
    BrowserUtilsModule,
    ConfigModule,
    CookieLawModule,
    CookieModule,
    HttpInterceptorModule,
    LanguageMismatchDialogModule,
    LogoutEverywhereDialogModule,
    MaintenanceModule,
    StripeConfigModule.forRoot(stripeApiKey),
    MixPanelModule,
    OutageModule,
    ...(ELECTRON ? [ElectronModule] : []),
    ToasterContainerComponent,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    ...(environment.production
      ? []
      : [
          {
            provide: CSP_NONCE,
            useValue: '12345',
          },
        ]),
    ...(ELECTRON ? [ElectronUploadsPersistanceService] : []),
    { provide: LAZY_COMPONENTS, useFactory: lazyComponentFactory },
    {
      provide: EFFECTS_ERROR_HANDLER,
      useValue: effectsErrorHandler,
    },
    {
      provide: STATE_PROJECT_PERMISSION_SERVICE,
      useClass: ProjectPermissionService,
    },
    {
      provide: BASE_PERMISSION_SERVICE,
      useClass: PermissionService,
    },
    {
      provide: API_URL,
      deps: [ApiService],
      useFactory: (apiService: ApiService) => apiService.baseUrl,
    },
    {
      provide: API,
      deps: [ApiService],
      useFactory: (apiService: ApiService) => apiService.api,
    },
    {
      provide: API_LAMBDA,
      deps: [ApiService],
      useFactory: (apiService: ApiService) => apiService.apiLambda,
    },
    {
      provide: HTTP_SERVICE,
      useExisting: HttpService,
    },
    {
      provide: DIALOG_MAPPING,
      useValue: dialogMapping,
    },
    {
      provide: HIGHLIGHT_MAPPING,
      useValue: higlightMapping,
    },
    {
      provide: VAULT_PAGE_COMPONENT,
      useValue: () =>
        import('../feature/workspace/vault/video-storage').then(
          (f) => f.VaultPageComponent,
        ),
    },
    {
      provide: DS_PAGE_COMPONENT,
      useValue: () =>
        import('../feature/workspace/projects/data-sources').then(
          (f) => f.DataSourcesPageComponent,
        ),
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'auto',
        subscriptSizing: 'dynamic',
      },
    },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: tooltipDefaults },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: {
        disabled: true,
        animation: {
          enterDuration: 0,
          exitDuration: 0,
        },
      },
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          json: () => import('highlight.js/lib/languages/json'),
          bash: () => import('highlight.js/lib/languages/bash'),
        },
      },
    },
    {
      provide: CHECK_OUTAGE,
      useValue: new Subject<void>(),
    },
    {
      provide: LANG_REDIRECT_IN_PROGRESS,
      useValue: new BehaviorSubject<boolean>(false),
    },
    {
      provide: USER_CONTEXT,
      deps: [UserService, TokenStoreService],
      useFactory: (
        userService: UserService,
        tokenStore: TokenStoreService,
      ): UserContext => {
        return {
          user$: userService.user$,
          userS: userService.userS,
          userUuid$: userService.userUuid$,
          token$: tokenStore.token$.pipe(map((m) => m?.data)),
          decodedToken$: tokenStore.decodedToken$,
          timeOffset$: tokenStore.timeOffset$,
          setCookie: userService.setCookie?.bind(userService),
          getCookie: userService.getCookie?.bind(userService),
        };
      },
    },
    {
      provide: MIXPANEL_CONTEXT,
      useFactory: mixPanelFactory,
    },
    {
      provide: TRANSLATION_LOADER,
      useClass: TranslationLoaderService,
    },
    {
      provide: USER_I18N_RESOURCES,
      useFactory: () => {
        const userService = inject(UserService);
        const service = inject(TRANSLATION_LOADER);
        return userService.user$.pipe(
          filter((f) => !!f),
          distinctUntilKeysChanged(['language']),
          switchMap((user) => {
            const loadedPromise = service.register(of(user.language));
            return from(loadedPromise).pipe(
              map(() => service.getData(user.language)),
            );
          }),
          shareReplay({ bufferSize: 1, refCount: true }),
        );
      },
    },
    {
      provide: USER_LOCALE,
      useFactory: () => {
        const service = inject(UserService);
        return computed(() => service.userS()?.language);
      },
    },
    ToasterService,
    provideDatetimeFormat(),
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
  ],
})
export class AppModule {}
