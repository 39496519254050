import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { StoreInject } from '@gv/state';
import { select } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { UserService } from '../../../../service/application/user/user.service';
import * as CookieLawActions from '../../../../store/action/cookie-law.actions';
import { fromCookieLawState } from '../../../../store/selector/cookie-law.selector';
import { COOKIE_LAW_FEATURE_STATE } from '../../../../store/state/feature-state/cookie-law-feature.state';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-cookie-law-snack-bar',
  host: {
    class: 'fixed left-0 top-0 w-full bg-gray-overlay-snackbar z-[99999]',
  },
  template: `
    @if (isVisible$ | async) {
      <div
        class="flex w-full items-center justify-between px-6 py-4 text-white"
      >
        <div class="pr-4" i18n="@@cookie-law-snack-bar.cookies">
          This website uses cookies to ensure you get the best experience on our
          website.
        </div>
        <a
          class="btn btn-green btn-no-uppercase text-white"
          (click)="confirmCookieLaw()"
          i18n="@@cookie-law-snack-bar.accept"
        >
          Got it!
        </a>
      </div>
    }
  `,
  standalone: true,
  imports: [AsyncPipe],
})
export class CookieLawSnackBarComponent {
  private userService = inject(UserService);
  private store = inject(StoreInject(COOKIE_LAW_FEATURE_STATE));
  isVisible$ = combineLatest([
    this.store.pipe(select(fromCookieLawState.getWasConfirmed)),
    this.userService.isLoggedIn$,
  ]).pipe(
    map(([wasConfirmed, isLoggedIn]) => !wasConfirmed && !isLoggedIn),
    distinctUntilChanged(),
  );

  confirmCookieLaw(): void {
    this.store.dispatch(CookieLawActions.confirm());
  }
}
