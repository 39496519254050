import { Directive } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Directive()
export class PermissionAwareContext {
  private reasonSubject = new BehaviorSubject<string | undefined>(undefined);

  readonly reason$ = this.reasonSubject.asObservable();

  set reason(reason: string | undefined) {
    if (this.reason !== reason) {
      this.reasonSubject.next(reason);
    }
  }

  get reason(): string | undefined {
    return this.reasonSubject.getValue();
  }
}
