export interface PromiseErrorModel {
  i: number;
  error: Error;
}

export class PromiseUtils {
  static async softAll<T>(
    promiseArray: Promise<T>[] | ReadonlyArray<Promise<T>>,
    rejectCallback?: (errors: PromiseErrorModel[]) => void,
  ): Promise<T[]> {
    const resArray: T[] = [];
    const errors: PromiseErrorModel[] = [];
    for (let index = 0; index < promiseArray.length; index++) {
      const p = promiseArray[index];
      try {
        const res = await p;
        resArray.push(res);
      } catch (e) {
        resArray.push(undefined);
        const error: PromiseErrorModel = {
          i: index,
          error: e,
        };
        errors.push(error);
      }
    }
    if (errors.length && rejectCallback) {
      rejectCallback(errors);
    }
    return resArray.length ? resArray : undefined;
  }
}
