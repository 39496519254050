const defaultVersion = 'v1';

export function generateBaseUrl(location: Location, addSuffix = true): string {
  let baseUrl = '';
  const { protocol, hostname, port } = location;

  const params = {
    protocol: protocol,
    hostname: hostname,
    port: port,
    version: defaultVersion,
  };

  if (params.protocol !== '') {
    baseUrl += params.protocol + '//';
  }

  baseUrl += hostname;

  if (params.port !== '' && params.port !== '80') {
    baseUrl += ':' + params.port;
  }

  if (addSuffix) {
    baseUrl += '/api/' + params.version;
  }
  return baseUrl;
}
