import { STREAM_HIGHLIGHT_KEY } from '@gv/ui/edge';

import { ExportActions } from '../../store/action/exports.actions';
import { LocationActions } from '../../store/action/location.actions';
import { ProjectActions } from '../../store/action/project.actions';
import { exportsFeatureKey } from '../../store/reducer/exports.reducer';
import { locationFeatureKey } from '../../store/reducer/location.reducer';
import { projectFeatureKey } from '../../store/reducer/project.reducer';
import { ProjectsActions } from '../../component/page/projects/state/projects.state';
import { projectsFeatureKey } from '../../component/page/projects/state/projects.reducer';

export const higlightMapping = {
  project: {
    waitFor: projectsFeatureKey,
    action: (data: { uuid: string; name: string }) => [
      ProjectsActions.highlight({ highlight: data.uuid, dtSent: new Date() }),
      ...(data.name
        ? [
            ProjectsActions.setFilter({
              filter: {
                uuids: [data.uuid],
                entitiesNames: [data.name],
                nameSearch: undefined,
                assignees: [],
                dtModifiedEnd: undefined,
                dtModifiedStart: undefined,
              },
            }),
          ]
        : []),
    ],
  },
  location: {
    waitFor: projectFeatureKey,
    action: (data: { uuid: string }) =>
      ProjectActions.locations.highlight({
        highlight: data.uuid,
        dtSent: new Date(),
      }),
  },
  export: {
    waitFor: exportsFeatureKey,
    action: (data: { uuid: string }) =>
      ExportActions.highlight({
        highlight: data.uuid,
        dtSent: new Date(),
      }),
  },
  'project-export': {
    proxy: true,
    wait: true,
  },
  'data-source': {
    waitFor: locationFeatureKey,
    action: (data: { uuid: string }) =>
      LocationActions.dataSources.highlight({
        highlight: data.uuid,
        dtSent: new Date(),
      }),
  },
  'gvvi-data-source': {
    proxy: true,
    wait: true,
  },
  [STREAM_HIGHLIGHT_KEY]: {
    local: true,
  },
} as const;
