import type { Observable } from 'rxjs';

import type { ApiDefinition } from './api-def';
import type { ApiResponse } from './type/api-response';
import type { IllegalParkingDataMetric } from './entity/illegal-parking-data-metric';

type Headers<H extends object | void> = H extends void
  ? { headers?: Record<string, string> }
  : { headers: Record<string, string> & H };
type Params<P extends object | void> = P extends void
  ? { params?: Record<string, string> }
  : { params: Record<string, string> & P };
type ROptions<H extends object | void, P extends object | void> = Headers<H> &
  Params<P> & { responseType?: 'json' | 'blob' };

export const lambdaApiDefinition: readonly ApiDefinition[] = [
  {
    e: '/report-error',
    id: 'reportError',
    m: 'post',
    d: undefined,
    b: false,
  },
  {
    e: '/report-error/{}/image',
    id: 'reportErrorImage',
    m: 'post',
    p: 1,
    d: undefined,
    b: false,
  },
  {
    e: '/report-error/{}',
    id: 'reportErrorComplete',
    m: 'post',
    p: 1,
    d: undefined,
    b: false,
  },

  // case 'GET /report-error/release':
  //   return ErrorReporter.getReleases(event);
  // case 'GET /report-error/release/{release}':
  //   return ErrorReporter.getRelease(event);
  // case 'GET /report-error/release/{release}/{uuid}':
  //   return ErrorReporter.getReport(event);
  {
    e: '/report-error/release',
    id: 'reportErrorGetReleases',
    m: 'get',
    p: 0,
    d: undefined,
    b: false,
  },
  {
    e: '/report-error/release/{}',
    id: 'reportErrorGetRelease',
    m: 'get',
    p: 1,
    d: undefined,
    b: false,
  },
  {
    e: '/report-error/release/{}/{}',
    id: 'reportErrorGetReport',
    m: 'get',
    p: 2,
    d: undefined,
    b: false,
  },
  {
    e: '/report-error/file/{}',
    id: 'reportErrorGetFile',
    m: 'get',
    p: 1,
    d: undefined,
    b: false,
  },
  {
    e: '/live-event',
    id: 'liveEventsListGroups',
    m: 'get',
    p: 0,
    d: undefined,
    b: false,
  },
  {
    e: '/live-event/{}',
    id: 'liveEventsGetGroupEvents',
    m: 'get',
    p: 1,
    d: undefined,
    b: false,
  },
  {
    e: '/live-event/{}',
    id: 'liveEventsGetEventImage',
    m: 'get',
    p: 1,
    d: undefined,
    b: false,
  },
  {
    e: '/event/ips/{}',
    id: 'liveEventsGeScenarioImage',
    m: 'get',
    p: 1,
    d: undefined,
    b: false,
  },
  {
    e: '/event/ips/{}',
    id: 'liveEventGetScenarioData',
    m: 'get',
    p: 1,
    d: undefined,
    b: false,
  },
  {
    e: '/event/ips/{}',
    id: 'liveEventsGetScenarioImage',
    m: 'get',
    p: 1,
    d: undefined,
    b: false,
  },
  {
    e: '/storybook/auth',
    id: 'authStorybook',
    m: 'post',
    p: 0,
    d: undefined,
    b: false,
  },
];

export interface LambdaApi {
  reportError(
    options?: ROptions<
      {
        'x-system'?: string;
      },
      void
    >,
  ): Observable<ApiResponse<{ uuid: string; urls: string[] }>>;
  reportErrorImage(
    id: string,
    options?: ROptions<void, void>,
  ): Observable<ApiResponse<{ uuid: string; url: string; getUrl: string }>>;
  reportErrorComplete(
    id: string,
    options?: ROptions<void, void>,
  ): Observable<ApiResponse<void>>;
  reportErrorGetReleases(options?: ROptions<void, void>): Observable<
    ApiResponse<{
      releases: string[];
    }>
  >;
  reportErrorGetRelease(
    release: string,
    options?: ROptions<void, void>,
  ): Observable<
    ApiResponse<{
      reports: readonly { uuid: string; createdAt: string }[];
    }>
  >;
  reportErrorGetReport(
    release: string,
    uuid: string,
    options?: ROptions<void, void>,
  ): Observable<
    ApiResponse<{
      files: readonly { prefix: string }[];
    }>
  >;
  reportErrorGetFile(
    path: string,
    options?: ROptions<void, void>,
  ): Observable<ApiResponse<{ url: string }>>;

  liveEventsListGroups(
    options?: ROptions<void, void>,
  ): Observable<ApiResponse<{ groups: string[] }>>;
  liveEventsGetGroupEvents(
    group: string,
    options?: ROptions<
      void,
      {
        limit?: string;
        from?: string;
        to?: string;
        lastKey?: string;
      }
    >,
  ): Observable<
    ApiResponse<{
      events: {
        createdAt: string;
        hasImage: boolean;
        data: any;
        uuid: string;
      }[];
      from: string;
      lastKey: string;
      count: number;
    }>
  >;
  liveEventsGetEventImage(
    id: string,
    options?: ROptions<void, void>,
  ): Observable<ApiResponse<{ url: string }>>;
  liveEventGetScenarioData(
    scenarioUuid: string,
    options?: ROptions<
      void,
      {
        limit: string;
        from: string;
        to: string;
        lastKey: string;
      }
    >,
  ): Observable<ApiResponse<IllegalParkingDataMetric>>;
  liveEventsGetScenarioImage(
    id: string,
    options?: ROptions<void, void>,
  ): Observable<ApiResponse<{ url: string }>>;
  authStorybook(options?: ROptions<void, void>): Observable<ApiResponse<void>>;
}
