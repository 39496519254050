import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PasswordValidatorService {
  containsUpperCase(password: string): boolean {
    if (!password) {
      return false;
    }

    return /([A-Z])\w*/.test(password);
  }

  containsLowerCase(password: string): boolean {
    if (!password) {
      return false;
    }

    return /([a-z])\w*/.test(password);
  }

  containsNumber(password: string): boolean {
    if (!password) {
      return false;
    }

    return /([0-9])\w*/.test(password);
  }

  isLongerThanSixChars(password: string): boolean {
    if (!password) {
      return false;
    }

    return password.length >= 12;
  }
}
