import { Injectable } from '@angular/core';

import { createComponentLogger } from '@gv/logger';
import { captureMessage, withScope } from '@sentry/angular';
import type { DBSchema, IDBPDatabase } from 'idb';
import { openDB } from 'idb';

import { logger as _logger } from '../../logger';

interface GVDB extends DBSchema {
  user: {
    key: string;
    value: string;
  };
  clientLog: {
    key: string;
    value: any;
  };
  angulartics: {
    key: string;
    value: Date;
  };
}

const neededStores = ['user', 'clientLog', 'angulartics'] as const;
const dbName = 'GoodVision';

@Injectable({ providedIn: 'root' })
export class IndexedDBService {
  private logger = createComponentLogger(_logger, 'IndexedDBService');

  private version: number | undefined;

  private _connection: IDBPDatabase<GVDB> | undefined;
  private _connecting: Promise<void> | undefined;
  private _waiting = 0;

  async getConnection(): Promise<IDBPDatabase<GVDB>> {
    if (this._connection) {
      return Promise.resolve(this._connection);
    }

    if (this._connecting !== undefined) {
      this._waiting += 1;
      await this._connecting;
      this._waiting -= 1;

      // everything is resolved;
      if (this._waiting === 0) {
        this._connecting = undefined;
      }

      if (this._connection) {
        return this._connection;
      }

      return Promise.reject('Connection to DB failed');
    }

    this._connecting = (async () => {
      let connection: IDBPDatabase<GVDB> | undefined;
      let i = 0;
      do {
        try {
          connection = await this.open();

          for (let index = 0; index < neededStores.length; index++) {
            const s = neededStores[index];
            if (!connection.objectStoreNames.contains(s)) {
              this.version = (connection.version || 0) + 1;
              connection.close();

              connection = await this.open();
              break;
            }
          }
        } catch (error) {
          this.version = undefined;

          this.logger.debug({ error }, 'Filed to update db');
        }
      } while (i++ < 5 && !connection);

      this._connection = connection;
    })();

    return this.getConnection();
  }

  private async open(): Promise<IDBPDatabase<GVDB>> {
    // eslint-disable-next-line prefer-const
    let connection: IDBPDatabase<GVDB>;

    const cleanUp = () => {
      connection?.close();

      if (this._connection === connection) {
        this._connection = undefined;
      }
    };

    // eslint-disable-next-line prefer-const
    connection = await openDB<GVDB>(dbName, this.version, {
      upgrade: (db) => {
        for (let index = 0; index < neededStores.length; index++) {
          const s = neededStores[index];
          if (!db.objectStoreNames.contains(s)) {
            db.createObjectStore(s);
          }
        }
      },
      blocked: () => {
        withScope((scope) => {
          scope.setLevel('error');
          scope.setTag('subMessage', 'DB connection blocked');
          captureMessage('DB connection blocked');
        });
      },
      blocking: cleanUp,
      terminated: cleanUp,
    });

    connection.onversionchange = (event) => {
      cleanUp();
      this.version = event.newVersion ?? undefined;
    };

    this.version = undefined;

    return connection;
  }

  close(): void {
    this._connection?.close();
    this._connection = undefined;
  }
}
