import { inject } from '@angular/core';

import { LOCAL_STORAGE } from '../tokens/local-storage-token';
import { LocalStorageService } from './local-storage.service';

export class LocalStorageSynchronizer {
  private localStorage = inject(LocalStorageService);
  private synchronizer = inject(LOCAL_STORAGE);

  getHash(key: string): string {
    const synch = this.synchronizer();
    return [key, synch.user, synch.project].filter((f) => !!f).join('_');
  }

  setItem(key: string, value: any): void {
    this.localStorage.setItem(this.getHash(key), value);
  }

  getItem(key: string): any {
    return this.localStorage.getItem(this.getHash(key));
  }
}
