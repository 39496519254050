import { ofType } from '@ngrx/effects';
import type { Action } from '@ngrx/store';
import type { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import type {
  FlowProps,
  PropagatedInitProps,
  TypedFlowBaseActions,
} from './create-flow-base-actions';
import { ofFlowAction } from './of-flow-action';

export const onFlowFinished = <
  InitProps,
  CompletedData,
  PropagateInitProps extends boolean,
>(
  action:
    | (Action & FlowProps<PropagatedInitProps<InitProps, PropagateInitProps>>)
    | (Action & FlowProps<void>),
  actions: TypedFlowBaseActions<InitProps, CompletedData, PropagateInitProps>,
) => {
  return (actions$: Observable<Action>) => {
    return actions$.pipe(
      ofFlowAction(action),
      ofType(actions.completed, actions.error, actions.cancel),
      take(1),
    );
  };
};
