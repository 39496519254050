export interface SubStateObjectModel<T> {
  readonly data: T;

  readonly loaded: boolean;

  readonly loading: boolean;

  readonly freshness: Date | undefined;

  readonly watching: number;

  readonly dirty: boolean;

  readonly highlighted: {
    readonly uuid: string | undefined;
    readonly date: Date | undefined;
  };

  readonly error:
    | {
        readonly message: string | undefined;
        readonly code: number | undefined;
      }
    | undefined;
}

export interface SubStateArrayModel<T extends { readonly uuid: string }> {
  readonly ids: string[];

  readonly data: Record<string, T>;

  readonly loaded: boolean;

  readonly loading: boolean;

  readonly freshness: Date | undefined;

  readonly watching: number;

  readonly dirty: boolean | unknown;

  readonly highlighted: {
    readonly uuid: string | undefined;
    readonly date: Date | undefined;
  };

  readonly error:
    | {
        readonly message: string | undefined;
        readonly code: number | undefined;
      }
    | undefined;
}

export const initialArraySubState: SubStateArrayModel<any> = {
  data: {},

  ids: [],

  loaded: false,

  loading: false,

  dirty: false,

  freshness: undefined,

  watching: 0,

  highlighted: {
    uuid: undefined,
    date: undefined,
  },

  error: undefined,
};

export const initialSubState: SubStateObjectModel<any> = {
  freshness: undefined,

  watching: 0,

  data: undefined,

  loaded: false,

  loading: false,

  dirty: false,

  highlighted: {
    uuid: undefined,
    date: undefined,
  },

  error: undefined,
};
