import { Component, input, output, inject, computed } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

import { TestIdModule } from '@gv/debug';

import type { ToastModel } from '../../model/toast-model';
import { toastTypeMapping } from '../../model/toast-model';

@Component({
  selector: 'gv-toaster',
  standalone: true,
  imports: [MatIconModule, NgClass, TestIdModule],
  template: `
    <div
      class="mb-4 flex w-[500px] items-start justify-between rounded bg-white px-4 py-3 text-text"
    >
      <div class="flex w-full min-w-0 items-center space-x-6">
        @if (toast().svgIcon) {
          <mat-icon
            class="flex-none"
            [svgIcon]="toast().svgIcon!"
            [ngClass]="toastTypeMapping[toast().type]"
          />
        } @else if (toast().icon) {
          <mat-icon
            class="flex-none"
            [ngClass]="toastTypeMapping[toast().type]"
            >{{ toast().icon }}</mat-icon
          >
        }
        <div
          class="flex w-full min-w-0 flex-1 flex-col items-start overflow-hidden"
        >
          <span class="min-w-0 break-words font-semibold">{{
            toast().title
          }}</span>
          <span
            class="min-w-0 max-w-full break-words [&>*]:max-w-full [&>*]:break-words"
            [innerHTML]="contentS()"
          ></span>
        </div>
      </div>
      @if (toast().action && toast().actionButton) {
        <button
          type="button"
          class="mx-4 flex-none self-center font-semibold uppercase text-ta-primary hover:underline"
          [gvId]="'btn-toaster'"
          (click)="toast().action!()"
        >
          {{ toast().actionButton }}
        </button>
      }
      @if (toast().closable) {
        <mat-icon
          class="h-6 w-6 flex-none cursor-pointer text-base"
          (click)="close.emit()"
          >close</mat-icon
        >
      }
    </div>
  `,
})
export class ToasterComponent {
  private sanitizer = inject(DomSanitizer);
  protected toastTypeMapping = toastTypeMapping;

  toast = input.required<ToastModel>();
  index = input.required<number>();
  close = output<void>();

  contentS = computed(() => {
    return (
      this.toast().content &&
      this.sanitizer.bypassSecurityTrustHtml(this.toast().content!)
    );
  });
}
