import { Injectable, inject } from '@angular/core';

import {
  AnalyticsCategories,
  AnalyticsProcessActions,
  AngularticsActions,
} from '@gv/analytics';
import {
  createDialogEffect,
  createLazyDialogEffect,
  onFlowFinished,
} from '@gv/state';
import { UploadsActions, VaultDialogActions } from '@gv/upload/state';
import { simpleSwitchMap } from '@gv/utils';
import { Actions } from '@ngrx/effects';
import type { Action } from '@ngrx/store';
import type { Observable } from 'rxjs';
import { EMPTY, merge, of } from 'rxjs';
import {
  exhaustMap,
  finalize,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import { FilesCouldNotBeUploadedDialogComponent } from '../../../component/dialog/vault/files-could-not-be-uploaded-dialog/files-could-not-be-uploaded-dialog.component';
import { VaultSizeExceededDialogComponent } from '../../../component/dialog/vault/vault-size-exceeded/vault-size-exceeded-dialog.component';

@Injectable()
export class UploadDialogEffect {
  private actions$ = inject<Actions>(Actions);
  stopAllUploadsDialog$ = createLazyDialogEffect(
    ({ actions$, openDialog, dialogActionsEmitter }) =>
      actions$.pipe(
        mergeMap((action): Observable<Action> => {
          const dialogRef$ = openDialog(action, {
            autoFocus: false,
            disableClose: true,
            width: '600px',
          });

          return dialogActionsEmitter(
            { action, dialogRef: dialogRef$, cleanup: false },
            dialogRef$.pipe(
              simpleSwitchMap((dialogRef) => dialogRef.afterClosed()),
              switchMap(
                (stopUploads): Observable<Action> =>
                  !stopUploads
                    ? EMPTY
                    : of(
                        UploadsActions.stopAllUploadsFlow.init({}),
                        AngularticsActions.event({
                          category: AnalyticsCategories.Process,
                          action: AnalyticsProcessActions.Cancel,
                        }),
                      ),
              ),
            ),
          );
        }),
      ),
    VaultDialogActions.stopAllUploadsDialog,
    {
      module: {
        component: () =>
          import(
            '../../../component/dialog/video/video-upload/stop-all-uploads/stop-all-uploads-dialog.component'
          ).then((m) => m.StopAllUploadsDialogComponent),
      },
    },
  );

  stopVaultUploadDialog$ = createLazyDialogEffect(
    ({ actions$, openDialog, dialogActionsEmitter }) =>
      actions$.pipe(
        mergeMap((action): Observable<Action> => {
          const dialogRef$ = openDialog(action, {
            autoFocus: false,
            disableClose: true,
            width: '600px',
            data: action.data,
          });

          const onSubmit$ = dialogRef$.pipe(
            simpleSwitchMap((dialogRef) =>
              dialogRef.componentInstance.onSubmit$.pipe(
                exhaustMap((): Observable<Action> => {
                  const flowActions = action.data.uuid
                    ? UploadsActions.vault.uploadFile.cancel
                    : UploadsActions.vault.cancel;

                  const flowAction = flowActions.init({
                    data: {
                      ...action.data,
                      localOnly: false,
                    },
                  });

                  const onFinish$ = this.actions$.pipe(
                    onFlowFinished(flowAction, flowActions as any), // force ts to accept this; type is correct, it just returns different data, but we ignore them anyway
                    take(1),
                    mergeMap(() => {
                      dialogRef.close();
                      return EMPTY;
                    }),
                  );

                  dialogRef.componentInstance.setUpdateInProgress(true);
                  return merge(onFinish$, of(flowAction)).pipe(
                    finalize(() => {
                      dialogRef.componentInstance.setUpdateInProgress(false);
                    }),
                  );
                }),
              ),
            ),
          );

          return dialogActionsEmitter(
            { action, dialogRef: dialogRef$ },
            onSubmit$,
          );
        }),
      ),
    VaultDialogActions.stopVaultUploadDialog,
    {
      module: {
        component: () =>
          import(
            '../../../component/dialog/video/video-upload/stop-vault-upload/stop-vault-upload-dialog.component'
          ).then((m) => m.StopVaultUploadDialogComponent),
      },
    },
  );

  stopAllUploadsCrossTabDialog$ = createLazyDialogEffect(
    ({ actions$, openDialog, dialogActionsEmitter }) =>
      actions$.pipe(
        mergeMap((dialogAction): Observable<Action> => {
          const dialogRef$ = openDialog(dialogAction, {
            autoFocus: false,
            disableClose: true,
            width: '600px',
            data: dialogAction.data,
          });

          const onSubmit$ = dialogRef$.pipe(
            simpleSwitchMap((dialogRef) =>
              dialogRef.componentInstance.onSubmit$.pipe(
                exhaustMap(() => {
                  if (dialogAction.data.desktopQuit) {
                    dialogRef.close(true);
                    return EMPTY;
                  }

                  const action = UploadsActions.stopAllUploadsCrossTabFlow.init(
                    {},
                  );

                  const onFlowFinished$ = this.actions$.pipe(
                    onFlowFinished(
                      action,
                      UploadsActions.stopAllUploadsCrossTabFlow,
                    ),
                    tap(() => {
                      dialogRef.close(true);
                    }),
                    map(() =>
                      AngularticsActions.event({
                        category: AnalyticsCategories.Process,
                        action: AnalyticsProcessActions.Cancel,
                      }),
                    ),
                  );

                  dialogRef.componentInstance.setUpdateInProgress(true);
                  return merge(onFlowFinished$, of(action)).pipe(
                    finalize(() =>
                      dialogRef.componentInstance.setUpdateInProgress(false),
                    ),
                  );
                }),
              ),
            ),
          );

          return dialogActionsEmitter(
            { action: dialogAction, dialogRef: dialogRef$ },
            onSubmit$,
          );
        }),
      ),
    VaultDialogActions.stopAllUploadsCrossTabDialog,
    {
      module: {
        component: () =>
          import(
            '../../../component/dialog/cancel-video-uploading/cancel-video-uploading-dialog.component'
          ).then((m) => m.CancelVideoUploadingDialogComponent),
      },
    },
  );

  filesCouldNotBeUploadedDialog$ = createDialogEffect(
    ({ actions$, openDialog, dialogActionsEmitter }) =>
      actions$.pipe(
        mergeMap((action): Observable<Action> => {
          const dialogRef = openDialog(action, {
            autoFocus: false,
            disableClose: true,
            width: '600px',
            data: action.data,
          });

          return dialogActionsEmitter({ action, dialogRef });
        }),
      ),
    VaultDialogActions.filesCouldNotBeUploadedDialog,
    FilesCouldNotBeUploadedDialogComponent,
  );

  vaultFileExceededDialog$ = createDialogEffect(
    ({ actions$, openDialog, dialogActionsEmitter }) =>
      actions$.pipe(
        mergeMap((action): Observable<Action> => {
          const dialogRef = openDialog(action, {
            autoFocus: false,
            disableClose: true,
            width: '600px',
            data: action.data,
          });

          return dialogActionsEmitter({ action, dialogRef });
        }),
      ),
    VaultDialogActions.vaultSizeExceededDialog,
    VaultSizeExceededDialogComponent,
  );
}
