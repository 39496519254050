import type { Observable } from 'rxjs';
import type { IsAble } from '@gv/utils';

import type { Permission, PermissionScope } from '../entity/enum';
import type { Permissions } from '../def';

export enum PermissionMode {
  And = 'AND',
  Or = 'OR',
}

export interface BasePermissionService {
  readonly permissions$: Observable<Permissions>;

  ngOnDestroy(): void;

  isAllowed(
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    mode?: PermissionMode,
  ): boolean;

  isAllowedS(
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    mode?: PermissionMode,
  ): boolean;

  isAllowed$(
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    mode?: PermissionMode,
  ): Observable<boolean>;

  isAble$(
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    mode?: PermissionMode,
  ): Observable<IsAble>;

  isAble(
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    mode?: PermissionMode,
  ): IsAble;

  isAbleS(
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    mode?: PermissionMode,
  ): IsAble;

  getReason(
    mode: 'short' | 'full',
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    permissionMode?: PermissionMode,
  ): string;

  isSet(
    permissions: Permissions,
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    mode?: PermissionMode,
  ): boolean;
}
