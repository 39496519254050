import { Injectable, inject } from '@angular/core';

import { WindowRefService } from './window-ref.service';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  private windowRef = inject(WindowRefService);

  getItem(key: string): string {
    return this.useSessionStorage((storage) => {
      return storage.getItem(key);
    })!;
  }

  setItem(key: string, value: string): void {
    this.useSessionStorage((storage) => {
      storage.setItem(key, value);
    });
  }

  private useSessionStorage<T>(callback: (sessionStorage: Storage) => T): T {
    if (!this.windowRef?.nativeWindow?.sessionStorage) {
      return undefined!;
    }
    return callback(this.windowRef.nativeWindow.sessionStorage);
  }
}
