import { Injectable, inject } from '@angular/core';

import { toSentryError } from '@gv/utils';
import { captureException, withScope } from '@sentry/angular';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';

import { WindowRefService } from './window-ref.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private windowRef = inject(WindowRefService);
  private errorReported = false;

  private storageChangedSubject: Subject<void> = new Subject<void>();

  readonly storageChanged$: Observable<void> =
    this.storageChangedSubject.asObservable();

  private storageChangedHandler = () => {
    this.storageChangedSubject.next();
  };

  enable(): void {
    if (this.windowRef.nativeWindow) {
      this.windowRef.nativeWindow.addEventListener(
        'storage',
        this.storageChangedHandler,
      );
    }
  }

  setItem<T>(key: string, value: T): void {
    if (!this.isLocalStorageDefined()) {
      return;
    }

    // only undefined values would fail JSON.stringify -> localStorage -> JSON.parse flow
    if (value === undefined) {
      return;
    }

    this.windowRef.nativeWindow.localStorage.setItem(
      key,
      JSON.stringify(value),
    );
  }

  getItem<T>(key: string): T {
    if (!this.isLocalStorageDefined()) {
      return undefined;
    }

    const data = this.windowRef.nativeWindow.localStorage.getItem(key);

    if (!data) {
      return undefined;
    }

    return JSON.parse(data) as T;
  }

  removeItem(key: string): void {
    if (!this.isLocalStorageDefined()) {
      return;
    }

    this.windowRef.nativeWindow.localStorage.removeItem(key);
  }

  private isLocalStorageDefined(): boolean {
    const localStorageDefined =
      this.windowRef.nativeWindow && !!this.windowRef.nativeWindow.localStorage;

    if (!localStorageDefined) {
      this.reportLocalStorageIsNotDefined();
    }

    return localStorageDefined;
  }

  private reportLocalStorageIsNotDefined(): void {
    if (this.errorReported) {
      return;
    }

    withScope((scope) => {
      scope.setLevel('info');
      captureException(
        toSentryError(
          'LocalStorageService',
          new Error('localStorage is not defined'),
        ),
      );
      this.errorReported = true;
    });
  }
}
