/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { Action } from '@ngrx/store';
import { createAction, props } from '@ngrx/store';
import type {
  ActionCreator,
  ActionCreatorProps,
  Creator,
  TypedAction,
} from '@ngrx/store/src/models';
import type { Actions } from '@ngrx/effects';
import { ofType } from '@ngrx/effects';
import { take } from 'rxjs/operators';
import type { Observable } from 'rxjs';
import { generateUuid } from '@gv/utils';

import { isActionOfType } from '../is-type';
import { ofFlowAction } from './of-flow-action';

export interface ExtFlowProps {
  readonly silent?: boolean;
  readonly noUpdate?: boolean;
}

export type Props<T> = ActionCreatorProps<T>;

export type FlowProps<InitProps> = [InitProps] extends [void]
  ? { readonly id: string } & ExtFlowProps
  : {
      readonly id: string;
      readonly initProps: InitProps;
    } & ExtFlowProps;

export type ValidateFields<T, Error> = T extends { type: string } ? Error : T;

export type DataProps<T> = [T] extends [void]
  ? {} // eslint-disable-line @typescript-eslint/ban-types
  : {
      readonly data: T;
    };

export type CompleteProps<T> = DataProps<
  ValidateFields<T, 'type field cannot be present'>
>;

export type FlowActionType<P> = ActionCreator<
  string,
  (props: P) => TypedAction<string> & P
>;

export type FlowInitActionType<P> = [P] extends [void]
  ? ActionCreator<string, () => TypedAction<string> & P & FlowProps<void>>
  : ActionCreator<
      string,
      (props: P) => TypedAction<string> & P & FlowProps<void>
    >;

export type PropagatedInitProps<InitProps, Propagate> = [Propagate] extends [
  true,
]
  ? InitProps
  : void;

export interface TypedFlowBaseActions<
  InitProps,
  CompletedProps,
  PropagateInitProps extends boolean,
> {
  propagateInitProps: PropagateInitProps;
  init: [InitProps] extends [void]
    ? FlowInitActionType<ExtFlowProps>
    : FlowInitActionType<DataProps<InitProps> & ExtFlowProps>;
  cancel: FlowActionType<
    FlowProps<PropagatedInitProps<InitProps, PropagateInitProps>>
  >;
  started: FlowActionType<
    FlowProps<PropagatedInitProps<InitProps, PropagateInitProps>>
  >;
  error: FlowActionType<
    CompleteProps<Error> &
      FlowProps<PropagatedInitProps<InitProps, PropagateInitProps>>
  >;
  completed: FlowActionType<
    CompleteProps<CompletedProps> &
      FlowProps<PropagatedInitProps<InitProps, PropagateInitProps>>
  >;
  loader?: {
    acquire: (props: DataProps<InitProps>) => Action;
    release: (props: DataProps<InitProps>) => Action;
  };
  onFinished$: (
    action:
      | (Action & FlowProps<PropagatedInitProps<InitProps, PropagateInitProps>>)
      | (Action & FlowProps<void>),
    actions$: Actions,
  ) => Observable<
    | ReturnType<
        FlowActionType<
          CompleteProps<CompletedProps> &
            FlowProps<PropagatedInitProps<InitProps, PropagateInitProps>>
        >
      >
    | ReturnType<
        FlowActionType<
          CompleteProps<Error> &
            FlowProps<PropagatedInitProps<InitProps, PropagateInitProps>>
        >
      >
    | ReturnType<
        FlowActionType<
          FlowProps<PropagatedInitProps<InitProps, PropagateInitProps>>
        >
      >
  >;
}

export function createPropsFromAction<
  InitProps,
  CompletedProps,
  PropagateInitProps extends boolean,
  A extends TypedFlowBaseActions<InitProps, CompletedProps, PropagateInitProps>,
>(
  baseAction: ReturnType<A['init']>,
  actions: TypedFlowBaseActions<InitProps, CompletedProps, PropagateInitProps>,
): FlowProps<PropagatedInitProps<InitProps, PropagateInitProps>> {
  const p = {
    id: baseAction.id,
    silent: baseAction.silent,
    noUpdate: baseAction.noUpdate,
    initProps: actions.propagateInitProps
      ? isActionOfType(baseAction, actions.init)
        ? (baseAction as any).data
        : (baseAction as any).initProps
      : undefined,
  };

  return p as FlowProps<PropagatedInitProps<InitProps, PropagateInitProps>>;
}

function createInitAction<InitProps>(
  init:
    | FlowActionType<DataProps<InitProps> & FlowProps<void>>
    | FlowActionType<FlowProps<void>>,
  initProps?: Props<DataProps<InitProps>>,
): Creator {
  const fn = !initProps
    ? (p?: ExtFlowProps) =>
        (init as FlowActionType<FlowProps<void>>)({
          id: generateUuid(),
          ...(p || {}),
        })
    : (
        p: DataProps<InitProps> & ExtFlowProps,
      ): ReturnType<FlowActionType<DataProps<InitProps>>> =>
        (init as FlowActionType<DataProps<InitProps> & FlowProps<void>>)({
          id: generateUuid(),
          ...p,
        });

  Object.assign(fn, { type: init.type });

  return fn;
}

export function createFlowBaseActions<T, R, TInfered extends T>(
  basename: string,
  name: string,
  options: {
    initProps: Props<DataProps<T>>;
    completedProps: Props<CompleteProps<R>>;
    loader?: {
      acquire: (initProps: DataProps<TInfered>) => Action;
      release: (initProps: DataProps<TInfered>) => Action;
    };
  },
  propagateInitProps: true,
): TypedFlowBaseActions<T, R, true>;
export function createFlowBaseActions<T, R, TInfered extends T>(
  basename: string,
  name: string,
  options: {
    initProps: Props<DataProps<T>>;
    completedProps: Props<CompleteProps<R>>;
    loader?: {
      acquire: (initProps: DataProps<TInfered>) => Action;
      release: (initProps: DataProps<TInfered>) => Action;
    };
  },
  propagateInitProps?: false,
): TypedFlowBaseActions<T, R, false>;
export function createFlowBaseActions<R>(
  basename: string,
  name: string,
  options: {
    completedProps: Props<CompleteProps<R>>;
    loader?: {
      acquire: () => Action;
      release: () => Action;
    };
  },
  propagateInitProps?: false,
): TypedFlowBaseActions<void, R, false>;
export function createFlowBaseActions<T, TInfered extends T>(
  basename: string,
  name: string,
  options: {
    initProps: Props<DataProps<T>>;
    loader?: {
      acquire: (initProps: DataProps<TInfered>) => Action;
      release: (initProps: DataProps<TInfered>) => Action;
    };
  },
  propagateInitProps: true,
): TypedFlowBaseActions<T, void, true>;
export function createFlowBaseActions<T, TInfered extends T>(
  basename: string,
  name: string,
  options: {
    initProps: Props<DataProps<T>>;
    loader?: {
      acquire: (initProps: DataProps<TInfered>) => Action;
      release: (initProps: DataProps<TInfered>) => Action;
    };
  },
  propagateInitProps?: false,
): TypedFlowBaseActions<T, void, false>;
export function createFlowBaseActions(
  basename: string,
  name: string,
  options: void | {
    loader?: {
      acquire: () => Action;
      release: () => Action;
    };
  },
  propagateInitProps?: false,
): TypedFlowBaseActions<void, void, false>;
export function createFlowBaseActions<T, R, TInfered extends T>(
  basename: string,
  name: string,
  options: {
    initProps?: Props<DataProps<T>>;
    completedProps?: Props<DataProps<R>>;
    loader?: {
      acquire: (initProps: any) => Action;
      release: (initProps: any) => Action;
    };
  } | void = {},
  propagateInitProps?: boolean,
): any {
  const baseInit = createAction(
    `${basename} init[${name}]`,
    options && options.initProps
      ? props<DataProps<T> & FlowProps<void>, DataProps<T> & FlowProps<void>>()
      : props<FlowProps<void>>(),
  );

  const completedProps =
    options && options.completedProps
      ? propagateInitProps && options && options.initProps
        ? props<
            FlowProps<TInfered> & CompleteProps<R>,
            FlowProps<TInfered> & CompleteProps<R>
          >()
        : props<
            FlowProps<void> & CompleteProps<R>,
            FlowProps<void> & CompleteProps<R>
          >()
      : propagateInitProps && options && options.initProps
        ? props<FlowProps<TInfered>, FlowProps<TInfered>>()
        : props<FlowProps<void>>();

  const init = createInitAction(
    baseInit,
    options ? options.initProps : undefined,
  );
  const cancel = createAction(
    `${basename} cancel[${name}]`,
    propagateInitProps && options && options.initProps
      ? props<FlowProps<T>, FlowProps<T>>()
      : props<FlowProps<void>>(),
  );
  const started = createAction(
    `${basename} started[${name}]`,
    propagateInitProps && options && options.initProps
      ? props<FlowProps<T>, FlowProps<T>>()
      : props<FlowProps<void>>(),
  );
  const error = createAction(
    `${basename} error[${name}]`,
    propagateInitProps && options && options.initProps
      ? props<
          FlowProps<T> & DataProps<Error>,
          FlowProps<T> & DataProps<Error>
        >()
      : props<FlowProps<void> & DataProps<Error>>(),
  );
  const completed = createAction(
    `${basename} completed[${name}]`,
    completedProps,
  );

  const onFinished$ = (
    action: TypedAction<string> & FlowProps<void>,
    actions$: Actions,
  ) =>
    actions$.pipe(
      ofFlowAction(action),
      ofType(completed, error, cancel),
      take(1),
    );

  return {
    init,
    cancel,
    started,
    error,
    completed,
    propagateInitProps,
    loader: options && options.loader,
    onFinished$,
  };
}
