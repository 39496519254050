/**
 * This is a partially generated file. Some edits may be overwritten!
 * @apiRef {@model ProjectPermissions}
 */
export enum ProjectPermissions {
  ReadOnly = 'READ_ONLY',
  Write = 'WRITE',
  Owner = 'OWNER',
  Support = 'SUPPORT',
}
