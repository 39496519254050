import type { OnDestroy, Signal } from '@angular/core';
import {
  DestroyRef,
  Injectable,
  Injector,
  NgZone,
  computed,
  inject,
  signal,
} from '@angular/core';
import { Router } from '@angular/router';
import { toObservable } from '@angular/core/rxjs-interop';

import type { Feature, UserProperty } from '@gv/api';
import type { Store } from '@gv/state';
import { StoreInject } from '@gv/state';
import type { CreditFeatureState } from '@gv/ui/billing';
import { credit, CREDIT_FEATURE_STATE, getCredits } from '@gv/ui/billing';
import type { UserModel } from '@gv/user';
import { getTokenUserUuid, UserActions } from '@gv/user';
import {
  filterTruthy,
  shallowDistinctUntilChanged,
  untilNgDestroyed,
} from '@gv/utils';
import { Actions, ofType } from '@ngrx/effects';
import type { Observable } from 'rxjs';
import { connectable, defer, from, merge, Subject } from 'rxjs';
import {
  concatMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  startWith,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { COOKIE_SERVICE, type CookieService } from '@gv/ui/utils';

import { logger } from '../../../../logger';
import { APP_CONFIG } from '../../../entity/token/app.config';
import type { AppState } from '../../../store/state/app-state';
import { APP_STATE } from '../../../store/state/app-state';
import { PreventActivationDuringUploadGuard } from '../routing/guard/uploading/prevent-activation-during-upload.guard';
import { RouterHelperService } from '../routing/router-helper.service';
import { TokenStoreService } from './token-store.service';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  private destroyRef = inject(DestroyRef);
  private appConfig = inject(APP_CONFIG);
  private tokenStore = inject(TokenStoreService);
  private injector = inject(Injector);
  private actions$ = inject<Actions>(Actions);
  private router = inject(Router);
  private ngZone = inject(NgZone);
  private routerHelper = inject(RouterHelperService);
  static readonly className: string = 'UserService';

  private readonly beforeLogoutHooks: (() => Promise<void>)[] = [];

  private get store(): Store<AppState & CreditFeatureState> {
    return this.injector.get(StoreInject(APP_STATE, CREDIT_FEATURE_STATE));
  }

  private _cookieService: CookieService;
  private get cookieService() {
    this._cookieService ??= this.injector.get(COOKIE_SERVICE);
    return this._cookieService;
  }

  private requestLogout$ = this.actions$.pipe(
    ofType(UserActions.requestLogout),
  );

  private _userS = signal<UserModel | undefined>(undefined);
  readonly userS = this._userS.asReadonly();
  readonly userUuidS = computed(() => this.userS()?.uuid);

  /**
   * @deprecated
   */
  readonly user$: Observable<UserModel> = toObservable(this.userS);

  /**
   * @deprecated
   */
  readonly userUuid$ = this.user$.pipe(
    map((user) => user?.uuid),
    distinctUntilChanged(),
  );

  /**
   * @deprecated
   */
  readonly isLoggedIn$: Observable<boolean> = this.user$.pipe(
    map((user) => !!user),
    distinctUntilChanged(),
    untilNgDestroyed(),
  );

  readonly loggedIn$ = connectable(
    this.tokenStore.token$.pipe(
      startWith({ data: undefined, dt: undefined }),
      pairwise(),
      filter(([prevToken, newToken]) => !prevToken?.data && !!newToken?.data),
      switchMap(
        (): Observable<void> =>
          this.user$.pipe(
            filter((user) => !!user),
            map(() => undefined),
            take(1),
          ),
      ),
      untilNgDestroyed(this.destroyRef),
    ),
    {
      connector: () => new Subject<void>(),
    },
  );

  readonly loggedOut$ = connectable(
    this.tokenStore.token$.pipe(
      pairwise(),
      filter(([prevToken, newToken]) => prevToken?.data && !newToken?.data),
      switchMap(() =>
        this.user$.pipe(
          filter((user) => !user),
          map(() => undefined),
          take(1),
        ),
      ),
      withLatestFrom(this.userUuid$.pipe(filterTruthy())),
      map(([, uuid]) => uuid),
      untilNgDestroyed(this.destroyRef),
    ),
    {
      connector: () => new Subject<string>(),
    },
  );

  get user(): UserModel {
    return this.userS();
  }

  readonly disabledFeatures$: Observable<readonly Feature[] | undefined> =
    this.user$.pipe(
      map((user) => (user ? user.disabledFeatures : undefined)),
      shallowDistinctUntilChanged(),
    );

  readonly properties$: Observable<readonly UserProperty[]> = this.user$.pipe(
    map((user) => user && user.properties),
    shallowDistinctUntilChanged(),
  );

  /**
   * @deprecated
   */
  readonly credits$: Observable<number> = defer(() =>
    this.store.select(getCredits),
  );

  private _credits: Signal<number>;
  get credits(): Signal<number> {
    if (!this._credits) {
      this._credits = this.store.selectSignal(getCredits);
    }
    return this._credits;
  }

  /**
   * should be same as uuid$ but is available before we get user data from api
   */
  readonly tokenUserUuid$ = this.tokenStore.token$.pipe(
    map((token) => (!token?.data ? undefined : getTokenUserUuid(token.data))),
    distinctUntilChanged(),
  );

  private logout$ = merge(
    this.tokenStore.tokenInvalidated$,
    this.tokenStore.token$.pipe(
      pairwise(),
      filter(([prevToken, newToken]) => prevToken?.data && !newToken?.data),
    ),
  ).pipe(
    debounceTime(0),
    concatMap(() => {
      if (!this.isOnLoginOrLogoutPage()) {
        return from(this.redirectToLogout());
      }
      return from(this.logOut());
    }),
    untilNgDestroyed(),
  );

  constructor() {
    this.loggedOut$.connect();
    this.loggedIn$.connect();
    this.logout$.subscribe();
    this.requestLogout$
      .pipe(untilNgDestroyed(this.destroyRef))
      .subscribe(() => void this.logOut());

    this.userUuid$
      .pipe(distinctUntilChanged(), untilNgDestroyed(this.destroyRef))
      .subscribe((uuid) => {
        if (uuid) {
          this.store.dispatch(credit.fetch.init({ skipWhenLoaded: true }));
        }
      });
  }

  ngOnDestroy(): void {
    //
  }

  /**
   * Sets the current authenticated user.
   * Pass 'undefined' value to unset the user.
   *
   * @param user User to set.
   */
  setUser(user: UserModel, updateTimeOffset = false): UserModel {
    if (this._userS() !== user) {
      if (!user) {
        this._userS.set(undefined);
      } else {
        this._userS.set(user);
      }

      if (updateTimeOffset && user) {
        this.tokenStore.refreshTimeOffset(user.dtSent);
      }
    }

    return this._userS();
  }

  logIn(token: string, dtSent: Date, user: UserModel): void {
    if (!token) {
      throw new Error('Token must be set.');
    }

    this.tokenStore.setToken({ data: token, dt: Date.now() }, dtSent);

    if (user) {
      this.setUser(user, !!dtSent);
    }
  }

  async logOut(): Promise<void> {
    if (!this.user) {
      return;
    }

    for (const hook of this.beforeLogoutHooks) {
      try {
        await hook();
      } catch (error) {
        logger.error({ error }, 'Failed to run before logout hook');
      }
    }

    this.setUser(undefined);
    this.tokenStore.setToken({ data: undefined, dt: Date.now() }, undefined);
    await this.tokenStore.flush();

    this.store.dispatch(UserActions.logout());
  }

  registerBeforeLogoutHook(hook: () => Promise<void>): void {
    this.beforeLogoutHooks.push(hook);
  }

  setLanguageCookie(language: string): void {
    const date = new Date();
    date.setTime(date.getTime() + 10 * 365 * 60 * 60 * 1000);

    this.cookieService.set(
      this.appConfig.cookies.language,
      language,
      date,
      '/',
      `${window.location.hostname.split('.').slice(-2).join('.')}`,
    );
  }

  setCookie(cookie: string, value: any): void {
    this.cookieService.set(cookie, value, undefined, '/');
  }

  getCookie(cookie: string): Promise<string> {
    return this.cookieService.get(cookie);
  }

  private isOnLoginOrLogoutPage(): boolean {
    return (
      this.router.isActive('/login', {
        paths: 'subset',
        queryParams: 'subset',
        fragment: 'ignored',
        matrixParams: 'ignored',
      }) ||
      this.router.isActive('/logout', {
        paths: 'subset',
        queryParams: 'subset',
        fragment: 'ignored',
        matrixParams: 'ignored',
      })
    );
  }

  private redirectToLogout(): Promise<boolean> {
    return this.ngZone.run(() => {
      return this.router.navigate(['/logout'], {
        queryParams: {
          r: this.routerHelper.getCurrentUrlForRedirectionAfterLogin(),
          [PreventActivationDuringUploadGuard.forceActivationQueryParameterName]: 1,
        },
        queryParamsHandling: 'merge',
        skipLocationChange: true,
      });
    });
  }
}
