import type { LiveProjectDataSourceDTO } from '@gv/api';
import { SortDirection, SortBy, LayoutView, MapStyle } from '@gv/api';
import type { SubStateArrayModel, SubStateObjectModel } from '@gv/state';
import { initialArraySubState, initialSubState } from '@gv/state';
import type { PageSettings } from '@gv/page-settings';

import type { ProjectDetailStateModel } from '../../entity/model/projects/project-state-model';
import type { LocationStateModel } from '../../entity/model/location/location-state-model';

export type LocationsSubState = SubStateArrayModel<LocationStateModel>;

export type ProjectSubState = SubStateObjectModel<ProjectDetailStateModel>;

export type ProjectDataSourcesSubState =
  SubStateArrayModel<LiveProjectDataSourceDTO>;

export interface ProjectState {
  readonly uuid: string;

  readonly project: ProjectSubState;

  readonly locations: LocationsSubState;

  readonly locationsPageSettings: PageSettings;

  readonly projectDataSources: ProjectDataSourcesSubState;
}

export const initialLocationsSubState: LocationsSubState = initialArraySubState;

export const initialProjectSubState: ProjectSubState = initialSubState;

export const initialProjectDataSourcesSubState: ProjectDataSourcesSubState =
  initialArraySubState;

export const initialProjectState: ProjectState = {
  uuid: undefined,

  project: initialProjectSubState,

  locations: initialLocationsSubState,

  projectDataSources: initialProjectDataSourcesSubState,

  locationsPageSettings: {
    pageView: LayoutView.Map,
    searchTerm: '',
    sortDirection: SortDirection.Asc,
    sortingBy: SortBy.Name,
    mapSettings: {
      mapStyle: MapStyle.GVMode,
      mapZoom: 14,
      preferredZoom: 14,
    },
    globalSettingsChanged: false,
  },
};
