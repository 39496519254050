/**
 * This is a partially generated file. Some edits may be overwritten!
 * @apiRef {@model DeviceStatus}
 */
export enum DeviceStatus {
  Initializing = 'INITIALIZING',
  Offline = 'OFFLINE',
  Online = 'ONLINE',
  New = 'NEW',
  InitializingOffline = 'INITIALIZING_OFFLINE',
  InitializationFailed = 'INITIALIZATION_FAILED',
  InitializationFailedOffline = 'INITIALIZATION_FAILED_OFFLINE',
  DeviceImpaired = 'DEVICE_IMPAIRED',
  DeviceImpairedOffline = 'DEVICE_IMPAIRED_OFFLINE',
}
