import type { CreditBalanceDTO } from '@gv/api';
import type { Store, SubStateObjectModel } from '@gv/state';
import { initialSubState, StoreInjectionToken } from '@gv/state';

export const creditFeatureKey = 'credits';

export interface CreditFeatureState {
  [creditFeatureKey]: CreditState;
}

export const CREDIT_FEATURE_STATE = new StoreInjectionToken<
  Store<CreditFeatureState>
>('creditFeatureState');

export interface CreditState {
  readonly balance: SubStateObjectModel<CreditBalanceDTO & { dtSent: Date }>;
}
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const initialBalance: SubStateObjectModel<
  CreditBalanceDTO & { dtSent: Date }
> = initialSubState;

export const initialCreditState: CreditState = {
  balance: initialBalance,
};
