import { isFunction } from 'lodash-es';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/ban-types
export class BaseStateService<T extends object> {
  protected stateSubject: BehaviorSubject<T>;

  get state(): T {
    return this.stateSubject.getValue();
  }

  state$: Observable<T>;

  constructor(initialValue: T) {
    this.stateSubject = new BehaviorSubject<T>(initialValue);
    this.state$ = this.stateSubject.asObservable();
  }

  setState(valueOrUpdater: T | ((state: T) => T)) {
    if (!valueOrUpdater) {
      return;
    }

    const newState: T = isFunction(valueOrUpdater)
      ? valueOrUpdater(this.state)
      : valueOrUpdater;

    if (newState !== this.state) {
      this.stateSubject.next(newState);
    }
  }

  patchState(valueOrUpdater: Partial<T> | ((state: T) => Partial<T>)) {
    if (!valueOrUpdater) {
      return;
    }

    const value: Partial<T> = isFunction(valueOrUpdater)
      ? valueOrUpdater(this.state)
      : valueOrUpdater;

    this.setState({
      ...this.state,
      ...value,
    });
  }
}
