/* This is a generated file. All edits will be overwritten! */
import { Observable } from 'rxjs';
import { ApiResponse } from './type/api-response';
import { Decodable, decodableMapping } from './decodable-fields';
import type { ActionLogsDTO } from './entity/action-logs-dto';
import type { AppThemesDTO } from './entity/app-themes-dto';
import type { ApplyPromocode } from './entity/apply-promocode';
import type { AvailableScreenshotsDTO } from './entity/available-screenshots-dto';
import type { Bbox } from './entity/bbox';
import type { BillingAccountDTO } from './entity/billing-account-dto';
import type { BillingAddressModel } from './entity/billing-address-model';
import type { BillingAddressVATDTO } from './entity/billing-address-vat-dto';
import type { BillingAddress } from './entity/billing-address';
import type { BundledFilesDTO } from './entity/bundled-files-dto';
import type { CartCheckoutResponseModel } from './entity/cart-checkout-response-model';
import type { CartCheckout } from './entity/cart-checkout';
import type { CartPaymentRequestModel } from './entity/cart-payment-request-model';
import type { CartValidationDTO } from './entity/cart-validation-dto';
import type { ChangePwRequestWEmail } from './entity/change-pw-request-w-email';
import type { CheckCountsDTO } from './entity/check-counts-dto';
import type { CheckCounts } from './entity/check-counts';
import type { CheckStream } from './entity/check-stream';
import type { ClassSchemeDetailDTO } from './entity/class-scheme-detail-dto';
import type { ClassificationSchemesModel } from './entity/classification-schemes-model';
import type { ClientLog } from './entity/client-log';
import type { ClientLogs } from './entity/client-logs';
import type { CopyDescription } from './entity/copy-description';
import type { CreateCartPaymentRequest } from './entity/create-cart-payment-request';
import type { CreateClassificationSchemeModel } from './entity/create-classification-scheme-model';
import type { CreateCreditExport } from './entity/create-credit-export';
import type { CreateDashboardModel } from './entity/create-dashboard-model';
import type { CreateDataMetric } from './entity/create-data-metric';
import type { CreateDataSourceModel } from './entity/create-data-source-data-model';
import type { CreateDeviceStreamFromDiscovery } from './entity/create-device-stream-from-discovery';
import type { CreateDeviceStream } from './entity/create-device-stream';
import type { CreateEvent } from './entity/create-event';
import type { CreateExport } from './entity/create-export';
import type { CreateFilter } from './entity/create-filter';
import type { CreateGraphicalObjectDataModel } from './entity/create-graphical-object-data-model';
import type { CreateGVVIDataSource } from './entity/create-gvvi-data-source';
import type { CreateGVVIExport } from './entity/create-gvvi-export';
import type { CreateGVVIProcessing } from './entity/create-gvvi-processing';
import type { CreateLiveWidgetExport } from './entity/create-live-widget-export';
import type { CreateLiveWidget } from './entity/create-live-widget';
import type { CreateLocationModel } from './entity/create-location-model';
import type { CreateMultiExport } from './entity/create-multi-export';
import type { CreateODMatrix } from './entity/create-od-matrix';
import type { CreateOrganizationUser } from './entity/create-organization-user';
import type { CreateOrganization } from './entity/create-organization';
import type { CreatePaymentMethodDataModel } from './entity/create-payment-method-data-model';
import type { CreateProjectCollaborationModel } from './entity/create-project-collaboration-model';
import type { CreateProjectDirectory } from './entity/create-project-directory';
import type { CreateProjectExport } from './entity/create-project-export';
import type { ExportSizeCheckRequest } from './entity/create-project-export';
import type { CreateProjectModel } from './entity/create-project-model';
import type { CreateTrafficScenario } from './entity/create-traffic-scenario';
import type { CreateUser } from './entity/create-user';
import type { CreateVaultFolderModel } from './entity/create-vault-folder-model';
import type { CreateVaultUpload } from './entity/create-vault-upload';
import type { CreateVideoUploadFromVaultDataModel } from './entity/create-video-upload-from-vault-data-model';
import type { CreateWebPushSubscription } from './entity/create-web-push-subscription';
import type { CreateWidget } from './entity/create-widget';
import type { CreditBalanceDTO } from './entity/credit-balance-dto';
import type { CreditHistoryDTO } from './entity/credit-history-dto';
import type { DashboardApiModel } from './entity/dashboard-api-model';
import type { DashboardsApiModel } from './entity/dashboards-api-model';
import type { DataAvailabilityDTO } from './entity/data-availability-dto';
import type { DataEditDTO } from './entity/data-edit-dto';
import type { DataMetricDTO } from './entity/data-metric-dto';
import type { DataMetric } from './entity/data-metric';
import type { DataMetricsDTO } from './entity/data-metrics-dto';
import type { DataSourceActionDTO } from './entity/data-source-action-dto';
import type { DataSourceDetailApiModel } from './entity/data-source-detail-api-model';
import type { DataSourceFilter } from './entity/data-source-filter';
import type { DataSourceProcessingValidationModel } from './entity/data-source-processing-validation-model';
import type { DataSourceScreenshotDataDTO } from './entity/data-source-screenshot-data-dto';
import type { DataSourceScreenshotDTO } from './entity/data-source-screenshot-dto';
import type { DataSourceValidationDTO } from './entity/data-source-validation-dto';
import type { DataSourceValidation } from './entity/data-source-validation';
import type { DataSourcesApiModel } from './entity/data-sources-api-model';
import type { DataSourcesMapDataDTO } from './entity/data-sources-map-data-dto';
import type { DataSourcesWithDirectoriesDTO } from './entity/data-sources-with-directories-dto';
import type { DemoProjectValidationModel } from './entity/demo-project-validation-model';
import type { DescriptionDTO } from './entity/description-dto';
import type { DeviceDetailDTO } from './entity/device-detail-dto';
import type { DeviceInstallDTO } from './entity/device-install-dto';
import type { DeviceStreamDTO } from './entity/device-stream-dto';
import type { DeviceStreamValidationDTO } from './entity/device-stream-validation-dto';
import type { DevicesDTO } from './entity/devices-dto';
import type { DirectoryValidationDTO } from './entity/directory-validation-dto';
import type { EditAuthProviders } from './entity/edit-auth-provider';
import type { EditClassScheme } from './entity/edit-class-scheme';
import type { EditDashboardModel } from './entity/edit-dashboard-model';
import type { EditDataMetricOrder } from './entity/edit-data-metric-order';
import type { EditDataMetric } from './entity/edit-data-metric';
import type { EditDataSource } from './entity/edit-data-source';
import type { EditDescription } from './entity/edit-description';
import type { EditDeviceStream } from './entity/edit-device-stream';
import type { EditDevice } from './entity/edit-device';
import type { EditDirectory } from './entity/edit-directory';
import type { EditEvent } from './entity/edit-event';
import type { EditFile } from './entity/edit-file';
import type { EditFilterOrder } from './entity/edit-filter-order';
import type { EditFilter } from './entity/edit-filter';
import type { EditGVVIDataSource } from './entity/edit-gvvi-data-source';
import type { EditLiveWidget } from './entity/edit-live-widget';
import type { EditLocationModel } from './entity/edit-location-model';
import type { EditNotificationSettings } from './entity/edit-notification-settings';
import type { EditOrganizationUser } from './entity/edit-organization-user';
import type { EditOrganization } from './entity/edit-organization';
import type { EditPageSettings } from './entity/edit-page-settings';
import type { EditProjectCollaborationModel } from './entity/edit-project-collaboration-model';
import type { EditProjectDirectory } from './entity/edit-project-directory';
import type { EditProjectModel } from './entity/edit-project-model';
import type { EditProjectOwner } from './entity/edit-project-owner';
import type { EditScenarioEvent } from './entity/edit-scenario-event';
import type { EditScenarioOrder } from './entity/edit-scenario-order';
import type { EditTrafficScenario } from './entity/edit-traffic-scenario';
import type { EditUser } from './entity/edit-user';
import type { EditVideoDTO } from './entity/edit-video-dto';
import type { EditWidget } from './entity/edit-widget';
import type { EditWidgetsOrder } from './entity/edit-widgets-order';
import type { Email } from './entity/email';
import type { EventInfosDTO } from './entity/event-infos-dto';
import type { ShareExportModel } from './entity/export-email-model';
import type { ExportsApiModel } from './entity/exports-api-model';
import type { FileDownloadDTO } from './entity/file-download-dto';
import type { FileFilter } from './entity/file-filter';
import type { FilterDescriptionDTO } from './entity/filter-description-dto';
import type { FilterDetailDTO } from './entity/filter-detail-dto';
import type { FilterModel } from './entity/filter-model';
import type { FilterValidationModel } from './entity/filter-validation-model';
import type { FiltersCounts } from './entity/filters-counts';
import type { FiltersModel } from './entity/filters-model';
import type { FinishVideoUpload } from './entity/finish-video-upload';
import type { GeoAnnotationPoints } from './entity/geo-annotation-points';
import type { GeoCalibrationWrapper } from './entity/geo-calibration-wrapper';
import type { GraphicalObjectAction } from './entity/graphical-object-action';
import type { GraphicalObjectModel } from './entity/graphical-object-model';
import type { GraphicalObjectValidationModel } from './entity/graphical-object-validation-model';
import type { GraphicalObjectsDataModel } from './entity/graphical-objects-data-model';
import type { GVVIDataSourceDetailDTO } from './entity/gvvi-data-source-detail-dto';
import type { GVVIDataSourceTargetsDTO } from './entity/gvvi-data-source-targets-dto';
import type { GVVIDataSourceValidationDTO } from './entity/gvvi-data-source-validation-dto';
import type { GVVIDataSourcesDTO } from './entity/gvvi-data-sources-dto';
import type { GVVIDataSourcesValidationDTO } from './entity/gvvi-data-sources-validation-dto';
import type { HeadProjectsDTO } from './entity/head-projects-dto';
import type { IProjectDataSources } from './entity/i-project-data-sources';
import type { InviteUserUsingReferral } from './entity/invite-user-using-referral';
import type { InvoiceHistoryDTO } from './entity/invoice-history-dto';
import type { LayoutView } from './entity/layout-view';
import type { LiveProjectDataSourcesDTO } from './entity/live-data-sources-dto';
import type { LiveExportSizeCheckResponseDTO } from './entity/live-export-size-check-response-dto';
import type { LiveWidgetData } from './entity/live-widget-data';
import type { LiveWidgetDetailDTO } from './entity/live-widget-detail-dto';
import type { LiveWidgetDTO } from './entity/live-widget-dto';
import type { LiveWidgetExportAcceptedDTO } from './entity/live-widget-export-accepted-dto';
import type { LiveWidgetsDTO } from './entity/live-widgets-dto';
import type { LocationApiModel } from './entity/location-api-model';
import type { LocationValidationModel } from './entity/location-validation-model';
import type { LocationsApiModel } from './entity/locations-api-model';
import type { LoginUser } from './entity/login-user';
import type { MapSettings } from './entity/map-settings-model';
import type { NextPaymentDTO } from './entity/next-payment-dto';
import type { NotificationAuthDTO } from './entity/notification-auth-dto';
import type { NotificationCenterStatusDTO } from './entity/notification-center-status-dto';
import type { NotificationInfoDTO } from './entity/notification-info-dto';
import type { NotificationMessagesDTO } from './entity/notification-messages-dto';
import type { NotificationResourceDTO } from './entity/notification-resource-dto';
import type { NotificationResource } from './entity/notification-resource';
import type { NotificationSettingsDTO } from './entity/notification-settings-dto';
import type { OneTimePaymentValidationDTO } from './entity/one-time-payment-validation-dto';
import type { OrganizationDTO } from './entity/organization-dto';
import type { OrganizationInvitationAction } from './entity/organization-invitation-action';
import type { OrganizationInvitationResultDTO } from './entity/organization-invitation-result-dto';
import type { OrganizationLogoPresignDTO } from './entity/organization-logo-presign-dto';
import type { OrganizationSettingsDTO } from './entity/organization-settings-dto';
import type { OrganizationUsersDTO } from './entity/organization-users-dto';
import type { PageSettingsDTO } from './entity/page-settings-dto';
import type { PairOAuth } from './entity/pair-o-auth';
import type { PaymentInfoModel } from './entity/payment-info-model';
import type { PaymentMethodModel } from './entity/payment-method-model';
import type { PaymentRequestDTO } from './entity/payment-request-dto';
import type { PaymentRequestsDTO } from './entity/payment-requests-dto';
import type { PerformBulkFileAction } from './entity/perform-bulk-file-action';
import type { PerformBulkNotificationAction } from './entity/perform-bulk-notification-action';
import type { PerformDeviceStreamAction } from './entity/perform-device-stream-action';
import type { PlannedMaintenanceDTO } from './entity/planned-maintenance-dto';
import type { PlatformModulePlansResponseModel } from './entity/platform-module-plans-response-model';
import type { PrepareDataEdit } from './entity/prepare-data-edit';
import type { PresignVaultFiles } from './entity/presign-vault-files';
import type { PresignVaultUploadFilesDTO } from './entity/presign-vault-upload-files-dto';
import type { PresignVaultUploadFiles } from './entity/presign-vault-upload-files';
import type { PriceOfferHistoryDTO } from './entity/price-offer-history-dto';
import type { ProFormaHistoryDTO } from './entity/pro-forma-history-dto';
import type { ProcessingActionDTO } from './entity/processing-action-dto';
import type { ProcessingPlottingData } from './entity/processing-plotting-data';
import type { ProcessingPlottingDTO } from './entity/processing-plotting-dto';
import type { ProcessingPlottingValidationData } from './entity/processing-plotting-validation-data';
import type { ProcessingPlottingValidationResult } from './entity/processing-plotting-validation-result';
import type { ProcessingPlottingsDTO } from './entity/processing-plottings-dto';
import type { ProcessingValidation } from './entity/processing-validation';
import type { ProcessingsDTO } from './entity/processings-dto';
import type { ProjectApiModel } from './entity/project-api-model';
import type { ProjectDetailDTO } from './entity/project-api-model';
import type { ProjectDirectoriesDTO } from './entity/project-directories-dto';
import type { ProjectDirectoryDTO } from './entity/project-directory-dto';
import type { ProjectDirectoryValidationDTO } from './entity/project-directory-validation-dto';
import type { ProjectFilter } from './entity/project-filter';
import type { ProjectsApiModel } from './entity/projects-api-model';
import type { ProjectsSearchDTO } from './entity/projects-search-dto';
import type { PublicDashboardDTO } from './entity/public-dashboard-dto';
import type { ReadNotifications } from './entity/read-notifications';
import type { ReferralValidationDTO } from './entity/referral-validation-dto';
import type { ReferralValidation } from './entity/referral-validation';
import type { ProjectExportDetailDTO } from './entity/report-detail-dto';
import type { ProjectExportDto } from './entity/report-dto';
import type { ReportsDTO } from './entity/reports-dto';
import type { SearchLogs } from './entity/search-logs';
import type { SortBy } from './entity/sort-by';
import type { SortDirection } from './entity/sort-direction';
import type { SubscriptionDTO } from './entity/subscription-dto';
import type { TimelineFilesDTO } from './entity/timeline-files-dto';
import type { TimelineWithFilesDTO } from './entity/timeline-with-files-dto';
import type { Token } from './entity/token';
import type { TrafficScenarioDetailDTO } from './entity/traffic-scenario-detail-dto';
import type { TrafficScenarioDTO } from './entity/traffic-scenario-dto';
import type { TrafficScenarioExportsDTO } from './entity/traffic-scenario-exports-dto';
import type { TrafficScenarioRecipeWithWidgets } from './entity/traffic-scenario-recipe-with-widgets';
import type { TrafficScenarioTypesDTO } from './entity/traffic-scenario-types-dto';
import type { TrafficScenariosDTO } from './entity/traffic-scenarios-dto';
import type { TranslationKeysDTO } from './entity/translation-keys-dto';
import type { UpdateDataSourceScreenshot } from './entity/update-data-source-screenshot';
import type { UpdateGraphicalObjectDataModel } from './entity/update-graphical-object-data-model';
import type { UserApiModel } from './entity/user-api-model';
import type { UserCardDTO } from './entity/user-card-dto';
import type { UserNotificationAction } from './entity/user-notification-action';
import type { UserProjectShareModel } from './entity/user-project-share-model';
import type { VaultDirectoriesDTO } from './entity/vault-directories-dto';
import type { VaultDirectoryModel } from './entity/vault-directory-model';
import type { VaultEntriesModel } from './entity/vault-entries-model';
import type { VaultFileModel } from './entity/vault-file-model';
import type { VaultUploadDetailModel } from './entity/vault-upload-detail-model';
import type { VaultUploadModel } from './entity/vault-upload-model';
import type { VaultUploadsModel } from './entity/vault-uploads-model';
import type { VerifyOAuthUser } from './entity/verify-o-auth-user';
import type { VerifyRequestWEmail } from './entity/verify-request-w-email';
import type { VerifyUser } from './entity/verify-user';
import type { VideoModel } from './entity/video-model';
import type { VideoValidationDTO } from './entity/video-validation-dto';
import type { WelcomeModel } from './entity/welcome-model';
import type { WidgetActionModel } from './entity/widget-action-model';
import type { WidgetCreatedModel } from './entity/widget-created-model';
import type { WidgetDataModel } from './entity/widget-data-model';
import type { WidgetDetailDTO } from './entity/widget-detail-dto';
import type { WidgetValidationDTO } from './entity/widget-validation-dto';
import type { WidgetsModel } from './entity/widgets-model';

type Headers<H extends object | void> = H extends void ? { headers?: Record<string, string | undefined> } : { headers: Record<string, string | undefined> & H };
type Params<P extends object | void> = P extends void ? { params?: Record<string, string | undefined> } : { params: Record<string, string | undefined> & P };
type ROptions<H extends object | void, P extends object | void> = Headers<H> & Params<P> & { responseType?: 'json' | 'blob' };

export interface ApiDefinition {
  /**
   * Endpoint
   */
  readonly e: string;

  /**
   * Operation id
   */
  readonly id: string;

  /**
   * Method
   */
  readonly m: 'post' | 'get' | 'put' | 'delete' | 'head';

  /**
   * Decodable mapping
   */
  readonly d?: Decodable<unknown>;

  /**
   * Number of parameters
   */
  readonly p?: number;

  /**
   * true if body is needed
   */
  readonly b?: boolean;

  /**
   * true if binary response is returned
   */
  readonly bin?: boolean;
}

export const apiDefinition: readonly ApiDefinition[] = [
  {
    e: '/oauth/pair',
    id: 'pairOAuth',
    m: 'post',
    d: decodableMapping.Token,
    b: true,
  },
  {
    e: '/user',
    id: 'createUser',
    m: 'post',
    b: true,
  },
  {
    e: '/user',
    id: 'getOwnUserDetail',
    m: 'get',
    d: decodableMapping.UserApiModel,
  },
  {
    e: '/user/{}',
    id: 'updateUserDetail',
    m: 'put',
    d: decodableMapping.UserApiModel,
    p: 1,
    b: true,
  },
  {
    e: '/user/{}',
    id: 'getUserDetail',
    m: 'get',
    d: decodableMapping.UserApiModel,
    p: 1,
  },
  {
    e: '/user/{}',
    id: 'deleteUserProfile',
    m: 'delete',
    p: 1,
  },
  {
    e: '/user/{}/detail',
    id: 'getUserDetailCard',
    m: 'get',
    d: decodableMapping.UserCardDTO,
    p: 1,
  },
  {
    e: '/user/{}/auth-provider',
    id: 'updateUserAuthProvider',
    m: 'put',
    p: 1,
    b: true,
  },
  {
    e: '/user/{}/benefit',
    id: 'applyPromocode',
    m: 'post',
    p: 1,
    b: true,
  },
  {
    e: '/user/verification',
    id: 'verifyUser',
    m: 'post',
    d: decodableMapping.Token,
    b: true,
  },
  {
    e: '/user/verification/oauth',
    id: 'verifyOAuthUser',
    m: 'post',
    d: decodableMapping.Token,
    b: true,
  },
  {
    e: '/user/auth',
    id: 'authenticateUser',
    m: 'post',
    d: decodableMapping.Token,
    b: true,
  },
  {
    e: '/user/auth/logout',
    id: 'logoutUser',
    m: 'post',
  },
  {
    e: '/user/auth/token',
    id: 'refreshToken',
    m: 'put',
    d: decodableMapping.Token,
  },
  {
    e: '/user/auth/token',
    id: 'invalidateUserTokens',
    m: 'delete',
  },
  {
    e: '/user/request/password',
    id: 'createNewPasswordRequest',
    m: 'post',
    b: true,
  },
  {
    e: '/user/request/verify',
    id: 'verifyRequest',
    m: 'post',
    b: true,
  },
  {
    e: '/user/request/password/change',
    id: 'changePassword',
    m: 'post',
    b: true,
  },
  {
    e: '/user/request/organization/accept',
    id: 'organizationInvitationAction',
    m: 'post',
    d: decodableMapping.OrganizationInvitationResultDTO,
    b: true,
  },
  {
    e: '/user/request/{}/notification',
    id: 'denyRequestNotification',
    m: 'delete',
    p: 1,
  },
  {
    e: '/user/web-push',
    id: 'createWebPushSubscription',
    m: 'post',
    b: true,
  },
  {
    e: '/user/web-push',
    id: 'removeWebPushSubscription',
    m: 'delete',
  },
  {
    e: '/user/file-access-report',
    id: 'getFileAccessReport',
    m: 'get',
    bin: true,
  },
  {
    e: '/user/welcome',
    id: 'getWelcomePage',
    m: 'get',
    d: decodableMapping.WelcomeModel,
  },
  {
    e: '/user/page-settings',
    id: 'editPageSettings',
    m: 'put',
    d: decodableMapping.PageSettingsDTO,
    b: true,
  },
  {
    e: '/user/notification',
    id: 'readNotifications',
    m: 'post',
    d: decodableMapping.NotificationMessagesDTO,
    b: true,
  },
  {
    e: '/user/notification',
    id: 'getNotificationCenterStatus',
    m: 'get',
    d: decodableMapping.NotificationCenterStatusDTO,
  },
  {
    e: '/user/notification/{}',
    id: 'performUserNotificationAction',
    m: 'post',
    p: 1,
    b: true,
  },
  {
    e: '/user/notification/action',
    id: 'performBulkNotificationAction',
    m: 'post',
    b: true,
  },
  {
    e: '/user/notification/settings',
    id: 'editNotificationSettings',
    m: 'put',
    d: decodableMapping.NotificationSettingsDTO,
    b: true,
  },
  {
    e: '/user/notification/settings',
    id: 'getNotificationSettings',
    m: 'get',
    d: decodableMapping.NotificationSettingsDTO,
  },
  {
    e: '/user/notification/settings',
    id: 'resetNotificationSettingsToDefault',
    m: 'delete',
    d: decodableMapping.NotificationSettingsDTO,
  },
  {
    e: '/user/notification/resource',
    id: 'getNotificationResource',
    m: 'post',
    d: decodableMapping.NotificationResourceDTO,
    b: true,
  },
  {
    e: '/project',
    id: 'createProject',
    m: 'post',
    d: decodableMapping.ProjectApiModel,
    b: true,
  },
  {
    e: '/project',
    id: 'getProjects',
    m: 'get',
    d: decodableMapping.ProjectsApiModel,
  },
  {
    e: '/project',
    id: 'headProjects',
    m: 'head',
  },
  {
    e: '/project/search',
    id: 'searchProjects',
    m: 'get',
    d: decodableMapping.ProjectsSearchDTO,
  },
  {
    e: '/project/{}',
    id: 'editProject',
    m: 'put',
    d: decodableMapping.ProjectApiModel,
    p: 1,
    b: true,
  },
  {
    e: '/project/{}',
    id: 'getProject',
    m: 'get',
    d: decodableMapping.ProjectDetailDTO,
    p: 1,
  },
  {
    e: '/project/{}',
    id: 'deleteProject',
    m: 'delete',
    p: 1,
  },
  {
    e: '/project/{}/collaboration',
    id: 'createProjectCollaboration',
    m: 'post',
    p: 1,
    b: true,
  },
  {
    e: '/project/{}/collaboration/{}',
    id: 'editCollaboration',
    m: 'put',
    d: decodableMapping.UserProjectShareModel,
    p: 2,
    b: true,
  },
  {
    e: '/project/{}/collaboration/{}',
    id: 'getCollaboration',
    m: 'get',
    d: decodableMapping.UserProjectShareModel,
    p: 2,
  },
  {
    e: '/project/{}/collaboration/{}',
    id: 'deleteCollaboration',
    m: 'delete',
    p: 2,
  },
  {
    e: '/project/{}/owner',
    id: 'initProjectOwnershipTransfer',
    m: 'post',
    p: 1,
    b: true,
  },
  {
    e: '/project/{}/owner/{}',
    id: 'finishProjectOwnershipTransfer',
    m: 'post',
    p: 2,
  },
  {
    e: '/project/{}/owner/{}',
    id: 'getProjectOwnershipTransfer',
    m: 'get',
    d: decodableMapping.ProjectApiModel,
    p: 2,
  },
  {
    e: '/project/{}/data-source',
    id: 'getProjectDataSources',
    m: 'get',
    p: 1,
  },
  {
    e: '/project/{}/data-source/{}/screenshot',
    id: 'getProjectDataSourceScreenshot',
    m: 'get',
    d: decodableMapping.DataSourceScreenshotDataDTO,
    p: 2,
  },
  {
    e: '/project/demo',
    id: 'shareDemoProject',
    m: 'post',
    d: decodableMapping.ProjectApiModel,
  },
  {
    e: '/project/demo/validation',
    id: 'shareDemoProjectValidation',
    m: 'get',
    d: decodableMapping.DemoProjectValidationModel,
  },
  {
    e: '/project/class-scheme',
    id: 'createClassificationScheme',
    m: 'post',
    d: decodableMapping.ClassSchemeDetailDTO,
    b: true,
  },
  {
    e: '/project/class-scheme',
    id: 'getClassificationSchemes',
    m: 'get',
    d: decodableMapping.ClassificationSchemesModel,
  },
  {
    e: '/project/class-scheme/{}',
    id: 'editClassificationScheme',
    m: 'put',
    d: decodableMapping.ClassSchemeDetailDTO,
    p: 1,
    b: true,
  },
  {
    e: '/project/class-scheme/{}',
    id: 'getClassificationSchemeDetail',
    m: 'get',
    d: decodableMapping.ClassSchemeDetailDTO,
    p: 1,
  },
  {
    e: '/project/class-scheme/{}',
    id: 'deleteClassificationScheme',
    m: 'delete',
    p: 1,
  },
  {
    e: '/project/{}/location',
    id: 'createLocation',
    m: 'post',
    d: decodableMapping.LocationApiModel,
    p: 1,
    b: true,
  },
  {
    e: '/project/{}/location',
    id: 'getProjectLocations',
    m: 'get',
    d: decodableMapping.LocationsApiModel,
    p: 1,
  },
  {
    e: '/project/{}/location/{}',
    id: 'editLocation',
    m: 'put',
    d: decodableMapping.LocationApiModel,
    p: 2,
    b: true,
  },
  {
    e: '/project/{}/location/{}',
    id: 'getLocation',
    m: 'get',
    d: decodableMapping.LocationApiModel,
    p: 2,
  },
  {
    e: '/project/{}/location/{}',
    id: 'deleteLocation',
    m: 'delete',
    p: 2,
  },
  {
    e: '/project/{}/location/{}/validation',
    id: 'validateLocation',
    m: 'get',
    d: decodableMapping.LocationValidationModel,
    p: 2,
  },
  {
    e: '/project/{}/location/{}/data-source',
    id: 'createDataSource',
    m: 'post',
    d: decodableMapping.DataSourceDetailApiModel,
    p: 2,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source',
    id: 'getDataSources',
    m: 'get',
    d: decodableMapping.DataSourcesApiModel,
    p: 2,
  },
  {
    e: '/project/{}/location/{}/data-source/action',
    id: 'dataSourceAction',
    m: 'post',
    d: decodableMapping.DataSourcesApiModel,
    p: 2,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}',
    id: 'editDataSource',
    m: 'put',
    d: decodableMapping.DataSourceDetailApiModel,
    p: 3,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}',
    id: 'getDataSource',
    m: 'get',
    d: decodableMapping.DataSourceDetailApiModel,
    p: 3,
  },
  {
    e: '/project/{}/location/{}/data-source/{}',
    id: 'deleteDataSource',
    m: 'delete',
    p: 3,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/validation',
    id: 'validateDataSource',
    m: 'get',
    d: decodableMapping.DataSourceValidationDTO,
    p: 3,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/screenshot',
    id: 'editDataSourceScreenshot',
    m: 'put',
    p: 3,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/screenshot',
    id: 'getDataSourceScreenshot',
    m: 'get',
    d: decodableMapping.DataSourceScreenshotDTO,
    p: 3,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/timeline',
    id: 'getDataSourceTimeline',
    m: 'get',
    d: decodableMapping.DataAvailabilityDTO,
    p: 3,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/timeline-file',
    id: 'getDataSourceVideoTimeline',
    m: 'get',
    d: decodableMapping.TimelineWithFilesDTO,
    p: 3,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/screenshot-data',
    id: 'getTimelineScreenshots',
    m: 'get',
    p: 3,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/timeline-data',
    id: 'createTimelineMetric',
    m: 'post',
    d: decodableMapping.DataMetricsDTO,
    p: 3,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/timeline-data',
    id: 'editTimelineMetricOrder',
    m: 'put',
    d: decodableMapping.DataMetricsDTO,
    p: 3,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/timeline-data',
    id: 'getTimelineMetrics',
    m: 'get',
    d: decodableMapping.DataMetricsDTO,
    p: 3,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/timeline-data/data',
    id: 'refreshTimelineMetrics',
    m: 'put',
    d: decodableMapping.DataMetricsDTO,
    p: 3,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/timeline-data/{}',
    id: 'editTimelineMetric',
    m: 'put',
    d: decodableMapping.DataMetricsDTO,
    p: 4,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/timeline-data/{}',
    id: 'getTimelineMetric',
    m: 'get',
    d: decodableMapping.DataMetricDTO,
    p: 4,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/timeline-data/{}',
    id: 'deleteTimelineMetric',
    m: 'delete',
    d: decodableMapping.DataMetricsDTO,
    p: 4,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/timeline-data/{}/data',
    id: 'refreshSingleTimelineMetric',
    m: 'put',
    p: 4,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/timeline-data/{}/data',
    id: 'getTimelineMetricData',
    m: 'get',
    p: 4,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/sample',
    id: 'getDataSourceSample',
    m: 'get',
    p: 3,
    bin: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/calibration',
    id: 'updateDataSourceCalibration',
    m: 'put',
    d: decodableMapping.GeoCalibrationWrapper,
    p: 3,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/calibration',
    id: 'getDataSourceCalibration',
    m: 'get',
    d: decodableMapping.GeoCalibrationWrapper,
    p: 3,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/calibration',
    id: 'deleteDataSourceCalibration',
    m: 'delete',
    p: 3,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/calibration/validation',
    id: 'validateDataSourceCalibration',
    m: 'post',
    d: decodableMapping.GeoCalibrationWrapper,
    p: 3,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/processing/action',
    id: 'processingAction',
    m: 'post',
    d: decodableMapping.ProcessingsDTO,
    p: 3,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/processing/validation',
    id: 'validateProcessing',
    m: 'post',
    d: decodableMapping.DataSourceProcessingValidationModel,
    p: 3,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/editor',
    id: 'prepareEdit',
    m: 'post',
    d: decodableMapping.DataEditDTO,
    p: 3,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/editor',
    id: 'resetEdit',
    m: 'delete',
    p: 3,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/editor/{}',
    id: 'getEdit',
    m: 'get',
    d: decodableMapping.DataEditDTO,
    p: 4,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/description',
    id: 'copyDescription',
    m: 'post',
    p: 3,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/description',
    id: 'editDescription',
    m: 'put',
    p: 3,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/description',
    id: 'getDescription',
    m: 'get',
    d: decodableMapping.DescriptionDTO,
    p: 3,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/processing',
    id: 'createProcessing',
    m: 'post',
    d: decodableMapping.VideoModel,
    p: 3,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/processing',
    id: 'getProcessingData',
    m: 'get',
    d: decodableMapping.ProcessingsDTO,
    p: 3,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/processing/{}',
    id: 'editProcessing',
    m: 'put',
    d: decodableMapping.VideoModel,
    p: 4,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/processing/{}',
    id: 'getProcessing',
    m: 'get',
    d: decodableMapping.VideoModel,
    p: 4,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/processing/{}',
    id: 'deleteProcessing',
    m: 'delete',
    p: 4,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/processing/{}/validation',
    id: 'validateVideoProcessing',
    m: 'get',
    d: decodableMapping.VideoValidationDTO,
    p: 4,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/graphical-object',
    id: 'createGraphicalObject',
    m: 'post',
    d: decodableMapping.GraphicalObjectModel,
    p: 3,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/graphical-object',
    id: 'getGraphicalObjects',
    m: 'get',
    d: decodableMapping.GraphicalObjectsDataModel,
    p: 3,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/graphical-object',
    id: 'deleteGraphicalObjects',
    m: 'delete',
    p: 3,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/graphical-object/{}',
    id: 'editGraphicalObject',
    m: 'put',
    d: decodableMapping.GraphicalObjectModel,
    p: 4,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/graphical-object/{}',
    id: 'getGraphicalObject',
    m: 'get',
    d: decodableMapping.GraphicalObjectModel,
    p: 4,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/graphical-object/{}',
    id: 'deleteGraphicalObject',
    m: 'delete',
    p: 4,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/graphical-object/{}/validation',
    id: 'getGraphicalObjectValidation',
    m: 'get',
    d: decodableMapping.GraphicalObjectValidationModel,
    p: 4,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/od-matrix',
    id: 'createODFilters',
    m: 'post',
    d: decodableMapping.FiltersModel,
    p: 3,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/filter',
    id: 'createFilter',
    m: 'post',
    d: decodableMapping.FilterModel,
    p: 3,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/filter',
    id: 'editFilterOrder',
    m: 'put',
    d: decodableMapping.FiltersModel,
    p: 3,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/filter',
    id: 'getFilters',
    m: 'get',
    d: decodableMapping.FiltersModel,
    p: 3,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/filter/{}',
    id: 'editFilter',
    m: 'put',
    d: decodableMapping.FilterModel,
    p: 4,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/filter/{}',
    id: 'getFilter',
    m: 'get',
    d: decodableMapping.FilterDetailDTO,
    p: 4,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/filter/{}',
    id: 'deleteFilter',
    m: 'delete',
    p: 4,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/filter/{}/validation',
    id: 'getFilterValidation',
    m: 'get',
    d: decodableMapping.FilterValidationModel,
    p: 4,
  },
  {
    e: '/project/{}/export',
    id: 'createMultiExport',
    m: 'post',
    p: 1,
    b: true,
  },
  {
    e: '/project/{}/live-export',
    id: 'createLiveExport',
    m: 'post',
    d: decodableMapping.ProjectExportDto,
    p: 1,
    b: true,
  },
  {
    e: '/project/{}/live-export/size-check',
    id: 'checkLiveExportDataSize',
    m: 'post',
    d: decodableMapping.LiveExportSizeCheckResponseDTO,
    p: 1,
    b: true,
  },
  {
    e: '/project/{}/export',
    id: 'getProjectExports',
    m: 'get',
    d: decodableMapping.ReportsDTO,
    p: 1,
  },
  {
    e: '/project/directory',
    id: 'getProjectVaultDirectories',
    m: 'get',
    d: decodableMapping.VaultDirectoriesDTO,
  },
  {
    e: '/project/{}/file/action',
    id: 'performBulkFileAction',
    m: 'post',
    p: 1,
    b: true,
  },
  {
    e: '/project/{}/data-source/live',
    id: 'getLiveDataSources',
    m: 'get',
    d: decodableMapping.LiveProjectDataSourcesDTO,
    p: 1,
  },
  {
    e: '/project/{}/data-source/{}/scenario',
    id: 'getTrafficScenarios',
    m: 'get',
    d: decodableMapping.TrafficScenarioExportsDTO,
    p: 2,
  },
  {
    e: '/project/{}/live-export/{}',
    id: 'shareProjectExport',
    m: 'post',
    p: 2,
    b: true,
  },
  {
    e: '/project/{}/live-export/{}',
    id: 'getProjectExportDetail',
    m: 'get',
    d: decodableMapping.ProjectExportDetailDTO,
    p: 2,
  },
  {
    e: '/project/{}/live-export/{}',
    id: 'deleteProjectExport',
    m: 'delete',
    p: 2,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/export',
    id: 'createExport',
    m: 'post',
    p: 3,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/export',
    id: 'getExports',
    m: 'get',
    d: decodableMapping.ExportsApiModel,
    p: 3,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/export/{}',
    id: 'exportAction',
    m: 'post',
    p: 4,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/export/{}',
    id: 'deleteExport',
    m: 'delete',
    p: 4,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/counts',
    id: 'checkCounts',
    m: 'post',
    d: decodableMapping.CheckCountsDTO,
    p: 3,
    b: true,
  },
  {
    e: '/project/{}/location/{}/data-source/{}/counts/{}',
    id: 'checkCountsResult',
    m: 'get',
    d: decodableMapping.FiltersCounts,
    p: 4,
  },
  {
    e: '/gvvi/project/{}/export',
    id: 'createGVVIMultiExport',
    m: 'post',
    p: 1,
    b: true,
  },
  {
    e: '/gvvi/project/{}/directory',
    id: 'createProjectDirectory',
    m: 'post',
    d: decodableMapping.ProjectDirectoryDTO,
    p: 1,
    b: true,
  },
  {
    e: '/gvvi/project/{}/directory',
    id: 'editProjectDirectory',
    m: 'put',
    d: decodableMapping.ProjectDirectoryDTO,
    p: 1,
    b: true,
  },
  {
    e: '/gvvi/project/{}/directory',
    id: 'getProjectDirectories',
    m: 'get',
    d: decodableMapping.ProjectDirectoriesDTO,
    p: 1,
  },
  {
    e: '/gvvi/project/{}/directory',
    id: 'deleteProjectDirectory',
    m: 'delete',
    p: 1,
  },
  {
    e: '/gvvi/project/{}/directory/validation',
    id: 'validateProjectDirectory',
    m: 'get',
    d: decodableMapping.ProjectDirectoryValidationDTO,
    p: 1,
  },
  {
    e: '/gvvi/project/{}/data-source',
    id: 'createGVVIDataSource',
    m: 'post',
    d: decodableMapping.GVVIDataSourceDetailDTO,
    p: 1,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source',
    id: 'getGVVIProjectDataSources',
    m: 'get',
    d: decodableMapping.DataSourcesWithDirectoriesDTO,
    p: 1,
  },
  {
    e: '/gvvi/project/{}/data-source/map',
    id: 'getGVVIProjectDataSourcesMapData',
    m: 'get',
    d: decodableMapping.DataSourcesMapDataDTO,
    p: 1,
  },
  {
    e: '/gvvi/project/{}/data-source/similar',
    id: 'getGVVIProjectSimilarDataSources',
    m: 'get',
    d: decodableMapping.GVVIDataSourceTargetsDTO,
    p: 1,
  },
  {
    e: '/gvvi/project/{}/data-source/action',
    id: 'gvviDataSourceAction',
    m: 'post',
    d: decodableMapping.GVVIDataSourcesDTO,
    p: 1,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/validation',
    id: 'validateGVVIDataSources',
    m: 'post',
    d: decodableMapping.GVVIDataSourcesValidationDTO,
    p: 1,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}',
    id: 'editGVVIDataSource',
    m: 'put',
    d: decodableMapping.GVVIDataSourceDetailDTO,
    p: 2,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}',
    id: 'getGVVIDataSource',
    m: 'get',
    d: decodableMapping.GVVIDataSourceDetailDTO,
    p: 2,
  },
  {
    e: '/gvvi/project/{}/data-source/{}',
    id: 'deleteGVVIDataSource',
    m: 'delete',
    p: 2,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/validation',
    id: 'validateGVVIDataSource',
    m: 'get',
    d: decodableMapping.GVVIDataSourceValidationDTO,
    p: 2,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/screenshot',
    id: 'editGVVIDataSourceScreenshot',
    m: 'put',
    p: 2,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/screenshot',
    id: 'getGVVIDataSourceScreenshot',
    m: 'get',
    d: decodableMapping.DataSourceScreenshotDTO,
    p: 2,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/timeline',
    id: 'getGVVIDataSourceTimeline',
    m: 'get',
    d: decodableMapping.DataAvailabilityDTO,
    p: 2,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/timeline-file',
    id: 'getGVVIDataSourceVideoTimeline',
    m: 'get',
    d: decodableMapping.TimelineWithFilesDTO,
    p: 2,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/screenshot-data',
    id: 'getGVVITimelineScreenshots',
    m: 'get',
    p: 2,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/timeline-data',
    id: 'createGVVITimelineMetric',
    m: 'post',
    d: decodableMapping.DataMetricsDTO,
    p: 2,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/timeline-data',
    id: 'editGVVITimelineMetricOrder',
    m: 'put',
    d: decodableMapping.DataMetricsDTO,
    p: 2,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/timeline-data',
    id: 'getGVVITimelineMetrics',
    m: 'get',
    d: decodableMapping.DataMetricsDTO,
    p: 2,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/timeline-data/data',
    id: 'refreshGVVITimelineMetrics',
    m: 'put',
    d: decodableMapping.DataMetricsDTO,
    p: 2,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/timeline-data/{}',
    id: 'editGVVITimelineMetric',
    m: 'put',
    d: decodableMapping.DataMetricsDTO,
    p: 3,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/timeline-data/{}',
    id: 'getGVVITimelineMetric',
    m: 'get',
    d: decodableMapping.DataMetricDTO,
    p: 3,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/timeline-data/{}',
    id: 'deleteGVVITimelineMetric',
    m: 'delete',
    d: decodableMapping.DataMetricsDTO,
    p: 3,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/timeline-data/{}/data',
    id: 'refreshGVVISingleTimelineMetric',
    m: 'put',
    p: 3,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/timeline-data/{}/data',
    id: 'getGVVITimelineMetricData',
    m: 'get',
    p: 3,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/sample',
    id: 'getGVVIDataSourceSample',
    m: 'get',
    p: 2,
    bin: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/processing/action',
    id: 'gvviProcessingAction',
    m: 'post',
    d: decodableMapping.ProcessingsDTO,
    p: 2,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/processing/validation',
    id: 'validateGVVIProcessing',
    m: 'post',
    d: decodableMapping.DataSourceProcessingValidationModel,
    p: 2,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/processing/{}/plotting/validation',
    id: 'validateProcessingPlotting',
    m: 'post',
    d: decodableMapping.ProcessingPlottingValidationResult,
    p: 3,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/processing/{}/plotting',
    id: 'runPlottingForProcessing',
    m: 'post',
    d: decodableMapping.ProcessingPlottingDTO,
    p: 3,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/plotting',
    id: 'getPlottings',
    m: 'get',
    d: decodableMapping.ProcessingPlottingsDTO,
    p: 2,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/editor',
    id: 'prepareGVVIEdit',
    m: 'post',
    d: decodableMapping.DataEditDTO,
    p: 2,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/editor',
    id: 'resetGVVIEdit',
    m: 'delete',
    p: 2,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/editor/{}',
    id: 'getGVVIDataEdit',
    m: 'get',
    d: decodableMapping.DataEditDTO,
    p: 3,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/description',
    id: 'copyGVVIDescription',
    m: 'post',
    p: 2,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/description',
    id: 'editGVVIDescription',
    m: 'put',
    p: 2,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/description',
    id: 'getGVVIDescription',
    m: 'get',
    d: decodableMapping.DescriptionDTO,
    p: 2,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/processing',
    id: 'createGVVIProcessing',
    m: 'post',
    d: decodableMapping.VideoModel,
    p: 2,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/processing',
    id: 'getGVVIProcessings',
    m: 'get',
    d: decodableMapping.ProcessingsDTO,
    p: 2,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/processing/{}',
    id: 'editGVVIProcessing',
    m: 'put',
    d: decodableMapping.VideoModel,
    p: 3,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/processing/{}',
    id: 'getGVVIProcessingDetail',
    m: 'get',
    d: decodableMapping.VideoModel,
    p: 3,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/processing/{}',
    id: 'deleteGVVIProcessing',
    m: 'delete',
    p: 3,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/processing/{}/validation',
    id: 'validateGVVIVideoProcessing',
    m: 'get',
    d: decodableMapping.VideoValidationDTO,
    p: 3,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/graphical-object',
    id: 'createGVVIGraphicalObject',
    m: 'post',
    d: decodableMapping.GraphicalObjectModel,
    p: 2,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/graphical-object',
    id: 'gvviGraphicalObjectAction',
    m: 'put',
    d: decodableMapping.GraphicalObjectsDataModel,
    p: 2,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/graphical-object',
    id: 'getGVVIGraphicalObjects',
    m: 'get',
    d: decodableMapping.GraphicalObjectsDataModel,
    p: 2,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/graphical-object',
    id: 'deleteGVVIGraphicalObjects',
    m: 'delete',
    p: 2,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/graphical-object/{}',
    id: 'editGVVIGraphicalObject',
    m: 'put',
    d: decodableMapping.GraphicalObjectModel,
    p: 3,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/graphical-object/{}',
    id: 'getGVVIGraphicalObject',
    m: 'get',
    d: decodableMapping.GraphicalObjectModel,
    p: 3,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/graphical-object/{}',
    id: 'deleteGVVIGraphicalObject',
    m: 'delete',
    p: 3,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/graphical-object/{}/validation',
    id: 'getGVVIGraphicalObjectValidation',
    m: 'get',
    d: decodableMapping.GraphicalObjectValidationModel,
    p: 3,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/od-matrix',
    id: 'createGVVIODFilters',
    m: 'post',
    d: decodableMapping.FiltersModel,
    p: 2,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/filter',
    id: 'createGVVIFilter',
    m: 'post',
    d: decodableMapping.FilterModel,
    p: 2,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/filter',
    id: 'editGVVIFilterOrder',
    m: 'put',
    d: decodableMapping.FiltersModel,
    p: 2,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/filter',
    id: 'getGVVIFilters',
    m: 'get',
    d: decodableMapping.FiltersModel,
    p: 2,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/filter/{}',
    id: 'editGVVIFilter',
    m: 'put',
    d: decodableMapping.FilterModel,
    p: 3,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/filter/{}',
    id: 'getGVVIFilter',
    m: 'get',
    d: decodableMapping.FilterDetailDTO,
    p: 3,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/filter/{}',
    id: 'deleteGVVIFilter',
    m: 'delete',
    p: 3,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/filter/{}/validation',
    id: 'getGVVIFilterValidation',
    m: 'get',
    d: decodableMapping.FilterValidationModel,
    p: 3,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/export',
    id: 'createGVVIExport',
    m: 'post',
    p: 2,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/export',
    id: 'getGVVIExports',
    m: 'get',
    d: decodableMapping.ExportsApiModel,
    p: 2,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/export/{}',
    id: 'gvviExportAction',
    m: 'post',
    p: 3,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/export/{}',
    id: 'deleteGVVIExport',
    m: 'delete',
    p: 3,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/counts',
    id: 'checkGVVICounts',
    m: 'post',
    d: decodableMapping.CheckCountsDTO,
    p: 2,
    b: true,
  },
  {
    e: '/gvvi/project/{}/data-source/{}/counts/{}',
    id: 'checkGVVICountsResult',
    m: 'get',
    d: decodableMapping.FiltersCounts,
    p: 3,
  },
  {
    e: '/dashboard/{}/widget',
    id: 'createWidget',
    m: 'post',
    d: decodableMapping.WidgetCreatedModel,
    p: 1,
    b: true,
  },
  {
    e: '/dashboard/{}/widget',
    id: 'editWidgetOrder',
    m: 'put',
    d: decodableMapping.WidgetsModel,
    p: 1,
    b: true,
  },
  {
    e: '/dashboard/{}/widget',
    id: 'getWidgets',
    m: 'get',
    d: decodableMapping.WidgetsModel,
    p: 1,
  },
  {
    e: '/dashboard/{}/widget/{}',
    id: 'widgetAction',
    m: 'post',
    p: 2,
    b: true,
  },
  {
    e: '/dashboard/{}/widget/{}',
    id: 'editWidget',
    m: 'put',
    d: decodableMapping.WidgetCreatedModel,
    p: 2,
    b: true,
  },
  {
    e: '/dashboard/{}/widget/{}',
    id: 'getWidget',
    m: 'get',
    d: decodableMapping.WidgetDetailDTO,
    p: 2,
  },
  {
    e: '/dashboard/{}/widget/{}',
    id: 'deleteWidget',
    m: 'delete',
    d: decodableMapping.WidgetsModel,
    p: 2,
  },
  {
    e: '/dashboard/{}/widget/{}/description',
    id: 'getWidgetFilterDescription',
    m: 'get',
    d: decodableMapping.FilterDescriptionDTO,
    p: 2,
  },
  {
    e: '/dashboard/{}/widget/{}/data',
    id: 'getWidgetData',
    m: 'get',
    p: 2,
  },
  {
    e: '/dashboard/{}/widget/{}/event-data',
    id: 'getWidgetEventData',
    m: 'get',
    p: 2,
  },
  {
    e: '/dashboard/{}/widget/{}/widget-image/{}/{}',
    id: 'getWidgetImageData',
    m: 'get',
    p: 4,
    bin: true,
  },
  {
    e: '/dashboard/{}/widget/{}/widget-export/{}/{}',
    id: 'getWidgetExportData',
    m: 'get',
    p: 4,
    bin: true,
  },
  {
    e: '/dashboard/{}/widget/{}/widget-image/{}/validation',
    id: 'validateWidgetImageData',
    m: 'get',
    p: 3,
  },
  {
    e: '/dashboard/{}/widget/{}/screenshot',
    id: 'getWidgetScreenshot',
    m: 'get',
    d: decodableMapping.DataSourceScreenshotDTO,
    p: 2,
  },
  {
    e: '/dashboard/{}/widget/{}/validation',
    id: 'validateWidget',
    m: 'get',
    d: decodableMapping.WidgetValidationDTO,
    p: 2,
  },
  {
    e: '/dashboard/{}/live-widget',
    id: 'createLiveWidget',
    m: 'post',
    d: decodableMapping.LiveWidgetDTO,
    p: 1,
    b: true,
  },
  {
    e: '/dashboard/{}/live-widget',
    id: 'editLiveWidgetOrder',
    m: 'put',
    d: decodableMapping.LiveWidgetsDTO,
    p: 1,
    b: true,
  },
  {
    e: '/dashboard/{}/live-widget',
    id: 'getLiveWidgets',
    m: 'get',
    d: decodableMapping.LiveWidgetsDTO,
    p: 1,
  },
  {
    e: '/dashboard/{}/live-widget/{}',
    id: 'editLiveWidget',
    m: 'put',
    d: decodableMapping.LiveWidgetDTO,
    p: 2,
    b: true,
  },
  {
    e: '/dashboard/{}/live-widget/{}',
    id: 'getLiveWidget',
    m: 'get',
    d: decodableMapping.LiveWidgetDetailDTO,
    p: 2,
  },
  {
    e: '/dashboard/{}/live-widget/{}',
    id: 'deleteLiveWidget',
    m: 'delete',
    p: 2,
  },
  {
    e: '/dashboard/{}/live-widget/{}/data',
    id: 'getLiveWidgetData',
    m: 'get',
    p: 2,
  },
  {
    e: '/dashboard/{}/live-widget/{}/export',
    id: 'createLiveWidgetExport',
    m: 'post',
    d: decodableMapping.LiveWidgetExportAcceptedDTO,
    p: 2,
    b: true,
  },
  {
    e: '/dashboard/{}/scenario',
    id: 'createTrafficScenario',
    m: 'post',
    d: decodableMapping.TrafficScenarioDTO,
    p: 1,
    b: true,
  },
  {
    e: '/dashboard/{}/scenario',
    id: 'editTrafficScenarioOrder',
    m: 'put',
    d: decodableMapping.TrafficScenariosDTO,
    p: 1,
    b: true,
  },
  {
    e: '/dashboard/{}/scenario',
    id: 'getDashboardTrafficScenarios',
    m: 'get',
    d: decodableMapping.TrafficScenariosDTO,
    p: 1,
  },
  {
    e: '/dashboard/{}/scenario/{}',
    id: 'editTrafficScenario',
    m: 'put',
    d: decodableMapping.TrafficScenarioDTO,
    p: 2,
    b: true,
  },
  {
    e: '/dashboard/{}/scenario/{}',
    id: 'getTrafficScenario',
    m: 'get',
    d: decodableMapping.TrafficScenarioDetailDTO,
    p: 2,
  },
  {
    e: '/dashboard/{}/scenario/{}',
    id: 'deleteTrafficScenario',
    m: 'delete',
    p: 2,
  },
  {
    e: '/dashboard/{}/scenario/{}/event/{}',
    id: 'editTrafficScenarioEvent',
    m: 'put',
    p: 3,
    b: true,
  },
  {
    e: '/dashboard/{}/scenario/recipe',
    id: 'getTrafficScenarioRecipes',
    m: 'get',
    d: decodableMapping.TrafficScenarioTypesDTO,
    p: 1,
  },
  {
    e: '/dashboard/{}/scenario/recipe/detail',
    id: 'getTrafficScenarioRecipe',
    m: 'get',
    d: decodableMapping.TrafficScenarioRecipeWithWidgets,
    p: 1,
  },
  {
    e: '/dashboard/{}/event',
    id: 'createEvent',
    m: 'post',
    d: decodableMapping.WidgetCreatedModel,
    p: 1,
    b: true,
  },
  {
    e: '/dashboard/{}/event/{}',
    id: 'editEvent',
    m: 'put',
    d: decodableMapping.WidgetCreatedModel,
    p: 2,
    b: true,
  },
  {
    e: '/dashboard/{}/event/{}/test',
    id: 'testEventWebhook',
    m: 'post',
    p: 2,
  },
  {
    e: '/dashboard/{}/event/status',
    id: 'getDashboardEventStatus',
    m: 'get',
    d: decodableMapping.EventInfosDTO,
    p: 1,
  },
  {
    e: '/project/{}/dashboard',
    id: 'createProjectDashboard',
    m: 'post',
    d: decodableMapping.DashboardApiModel,
    p: 1,
    b: true,
  },
  {
    e: '/project/{}/dashboard',
    id: 'getProjectDashboards',
    m: 'get',
    d: decodableMapping.DashboardsApiModel,
    p: 1,
  },
  {
    e: '/project/{}/dashboard/{}',
    id: 'editProjectDashboard',
    m: 'put',
    d: decodableMapping.DashboardApiModel,
    p: 2,
    b: true,
  },
  {
    e: '/project/{}/dashboard/{}',
    id: 'getProjectDashboard',
    m: 'get',
    d: decodableMapping.DashboardApiModel,
    p: 2,
  },
  {
    e: '/project/{}/dashboard/{}',
    id: 'deleteProjectDashboard',
    m: 'delete',
    p: 2,
  },
  {
    e: '/user/notification/auth',
    id: 'notificationAuth',
    m: 'post',
    d: decodableMapping.NotificationAuthDTO,
  },
  {
    e: '/user/notification/info',
    id: 'notificationInfo',
    m: 'get',
    d: decodableMapping.NotificationInfoDTO,
  },
  {
    e: '/user/verify-stream',
    id: 'verifyStream',
    m: 'post',
    b: true,
  },
  {
    e: '/user/vault/upload',
    id: 'createVaultUpload',
    m: 'post',
    d: decodableMapping.VaultUploadDetailModel,
    b: true,
  },
  {
    e: '/user/vault/upload',
    id: 'getActiveVaultUploads',
    m: 'get',
    d: decodableMapping.VaultUploadsModel,
  },
  {
    e: '/user/vault/upload/{}',
    id: 'presignUploadingFiles',
    m: 'put',
    d: decodableMapping.PresignVaultUploadFilesDTO,
    p: 1,
    b: true,
  },
  {
    e: '/user/vault/upload/{}',
    id: 'getVaultUpload',
    m: 'get',
    d: decodableMapping.VaultUploadModel,
    p: 1,
  },
  {
    e: '/user/vault/upload/{}',
    id: 'cancelActiveUpload',
    m: 'delete',
    p: 1,
  },
  {
    e: '/user/vault/directory/validation',
    id: 'validateDirectory',
    m: 'get',
    d: decodableMapping.DirectoryValidationDTO,
  },
  {
    e: '/user/vault/directory',
    id: 'createDirectory',
    m: 'post',
    d: decodableMapping.VaultDirectoryModel,
    b: true,
  },
  {
    e: '/user/vault/directory',
    id: 'editDirectory',
    m: 'put',
    d: decodableMapping.VaultDirectoryModel,
    b: true,
  },
  {
    e: '/user/vault/directory',
    id: 'deleteDirectory',
    m: 'delete',
  },
  {
    e: '/user/vault/file',
    id: 'getVaultFiles',
    m: 'get',
    d: decodableMapping.VaultEntriesModel,
  },
  {
    e: '/user/vault/file/{}',
    id: 'editFile',
    m: 'put',
    d: decodableMapping.VaultFileModel,
    p: 1,
    b: true,
  },
  {
    e: '/user/vault/file/{}',
    id: 'getFileDetail',
    m: 'get',
    d: decodableMapping.FileDownloadDTO,
    p: 1,
  },
  {
    e: '/user/vault/file/{}',
    id: 'deleteFile',
    m: 'delete',
    p: 1,
  },
  {
    e: '/user/vault/file/{}/bundle',
    id: 'getFileBundledFiles',
    m: 'get',
    d: decodableMapping.BundledFilesDTO,
    p: 1,
  },
  {
    e: '/user/vault/file/{}/upload',
    id: 'finishFileUpload',
    m: 'post',
    p: 1,
    b: true,
  },
  {
    e: '/user/vault/file/{}/upload',
    id: 'cancelFileUpload',
    m: 'delete',
    p: 1,
  },
  {
    e: '/user/vault/file/timeline',
    id: 'presignVaultFiles',
    m: 'put',
    d: decodableMapping.TimelineFilesDTO,
    b: true,
  },
  {
    e: '/app/client-log',
    id: 'sendClientLog',
    m: 'post',
    b: true,
  },
  {
    e: '/app/beacon',
    id: 'sendBeacon',
    m: 'post',
    b: true,
  },
  {
    e: '/payment/info',
    id: 'getInfo',
    m: 'get',
    d: decodableMapping.PaymentInfoModel,
  },
  {
    e: '/payment/account',
    id: 'getBillingAccount',
    m: 'get',
    d: decodableMapping.BillingAccountDTO,
  },
  {
    e: '/payment/subscription',
    id: 'getSubscription',
    m: 'get',
    d: decodableMapping.SubscriptionDTO,
  },
  {
    e: '/payment/subscription',
    id: 'cancelSubscription',
    m: 'delete',
    d: decodableMapping.SubscriptionDTO,
  },
  {
    e: '/payment/next',
    id: 'getNextSubscriptionPayment',
    m: 'get',
    d: decodableMapping.NextPaymentDTO,
  },
  {
    e: '/payment/balance',
    id: 'getCreditBalance',
    m: 'get',
    d: decodableMapping.CreditBalanceDTO,
  },
  {
    e: '/payment/validation',
    id: 'validatePayment',
    m: 'get',
    d: decodableMapping.OneTimePaymentValidationDTO,
  },
  {
    e: '/payment/cart/validation',
    id: 'validateCartPayment',
    m: 'post',
    d: decodableMapping.CartValidationDTO,
    b: true,
  },
  {
    e: '/payment/cart',
    id: 'createNewCart',
    m: 'post',
    d: decodableMapping.PaymentRequestDTO,
    b: true,
  },
  {
    e: '/payment/cart',
    id: 'getCarts',
    m: 'get',
    d: decodableMapping.PaymentRequestsDTO,
  },
  {
    e: '/payment/cart/{}',
    id: 'getCartDetail',
    m: 'get',
    d: decodableMapping.CartPaymentRequestModel,
    p: 1,
  },
  {
    e: '/payment/cart/{}',
    id: 'cancelCart',
    m: 'delete',
    p: 1,
  },
  {
    e: '/payment/cart/{}/checkout',
    id: 'cartCheckout',
    m: 'post',
    d: decodableMapping.CartCheckoutResponseModel,
    p: 1,
    b: true,
  },
  {
    e: '/payment/payment-method',
    id: 'authorizePaymentMethod',
    m: 'post',
    d: decodableMapping.PaymentMethodModel,
    b: true,
  },
  {
    e: '/payment/payment-method',
    id: 'getAuthorizePaymentMethods',
    m: 'get',
    d: decodableMapping.PaymentMethodModel,
  },
  {
    e: '/payment/payment-method',
    id: 'deletePaymentMethod',
    m: 'delete',
  },
  {
    e: '/payment/credit',
    id: 'getCreditHistory',
    m: 'get',
    d: decodableMapping.CreditHistoryDTO,
  },
  {
    e: '/payment/billing-address',
    id: 'updateBillingAddress',
    m: 'put',
    d: decodableMapping.BillingAddressModel,
    b: true,
  },
  {
    e: '/payment/billing-address/check',
    id: 'getBillingAddressInfo',
    m: 'post',
    d: decodableMapping.BillingAddressVATDTO,
    b: true,
  },
  {
    e: '/payment/invoice',
    id: 'getInvoices',
    m: 'get',
    d: decodableMapping.InvoiceHistoryDTO,
  },
  {
    e: '/payment/invoice/{}',
    id: 'getInvoice',
    m: 'get',
    p: 1,
    bin: true,
  },
  {
    e: '/payment/proforma',
    id: 'getProFormas',
    m: 'get',
    d: decodableMapping.ProFormaHistoryDTO,
  },
  {
    e: '/payment/price-offer',
    id: 'getPriceOffers',
    m: 'get',
    d: decodableMapping.PriceOfferHistoryDTO,
  },
  {
    e: '/payment/credit/summary',
    id: 'createCreditSummary',
    m: 'post',
    b: true,
    bin: true,
  },
  {
    e: '/image/{}',
    id: 'getImageForVideo',
    m: 'get',
    p: 1,
    bin: true,
  },
  {
    e: '/image/data-source/{}',
    id: 'getDataSourceScreenshotImage',
    m: 'get',
    p: 1,
    bin: true,
  },
  {
    e: '/image/event/{}',
    id: 'getEventImage',
    m: 'get',
    p: 1,
    bin: true,
  },
  {
    e: '/export/{}',
    id: 'getExportFile',
    m: 'get',
    p: 1,
    bin: true,
  },
  {
    e: '/organization',
    id: 'createOrganization',
    m: 'post',
    d: decodableMapping.OrganizationDTO,
    b: true,
  },
  {
    e: '/organization',
    id: 'editOrganization',
    m: 'put',
    d: decodableMapping.OrganizationDTO,
    b: true,
  },
  {
    e: '/organization',
    id: 'getOrganization',
    m: 'get',
    d: decodableMapping.OrganizationDTO,
  },
  {
    e: '/organization/logo',
    id: 'presignOrganizationLogos',
    m: 'post',
    d: decodableMapping.OrganizationLogoPresignDTO,
  },
  {
    e: '/organization/settings',
    id: 'getOrganizationSettings',
    m: 'get',
    d: decodableMapping.OrganizationSettingsDTO,
  },
  {
    e: '/organization/referral',
    id: 'inviteUserUsingReferral',
    m: 'post',
    b: true,
  },
  {
    e: '/organization/user',
    id: 'createOrganizationUserInvitation',
    m: 'post',
    d: decodableMapping.OrganizationUsersDTO,
    b: true,
  },
  {
    e: '/organization/user/{}',
    id: 'editOrganizationUser',
    m: 'put',
    d: decodableMapping.OrganizationUsersDTO,
    p: 1,
    b: true,
  },
  {
    e: '/organization/user/{}',
    id: 'deleteOrganizationUser',
    m: 'delete',
    d: decodableMapping.OrganizationUsersDTO,
    p: 1,
  },
  {
    e: '/app/action-log',
    id: 'searchActionLog',
    m: 'post',
    d: decodableMapping.ActionLogsDTO,
    b: true,
  },
  {
    e: '/app/theme',
    id: 'getAppThemes',
    m: 'get',
    d: decodableMapping.AppThemesDTO,
  },
  {
    e: '/app/referral/validation',
    id: 'validateReferral',
    m: 'post',
    d: decodableMapping.ReferralValidationDTO,
    b: true,
  },
  {
    e: '/app/translation',
    id: 'getTranslationKeys',
    m: 'get',
    d: decodableMapping.TranslationKeysDTO,
  },
  {
    e: '/device',
    id: 'getDevices',
    m: 'get',
    d: decodableMapping.DevicesDTO,
  },
  {
    e: '/device/{}',
    id: 'editDevice',
    m: 'put',
    d: decodableMapping.DeviceDetailDTO,
    p: 1,
    b: true,
  },
  {
    e: '/device/{}',
    id: 'getDeviceDetail',
    m: 'get',
    d: decodableMapping.DeviceDetailDTO,
    p: 1,
  },
  {
    e: '/device/{}/install',
    id: 'getDeviceInstallUrl',
    m: 'get',
    d: decodableMapping.DeviceInstallDTO,
    p: 1,
  },
  {
    e: '/device/{}/discovery',
    id: 'createStreamFromDiscovery',
    m: 'post',
    d: decodableMapping.DeviceStreamDTO,
    p: 1,
    b: true,
  },
  {
    e: '/device/{}/discovery',
    id: 'discoverDeviceCameras',
    m: 'put',
    p: 1,
  },
  {
    e: '/device/{}/stream',
    id: 'createDeviceStream',
    m: 'post',
    d: decodableMapping.DeviceStreamDTO,
    p: 1,
    b: true,
  },
  {
    e: '/device/{}/stream/{}',
    id: 'editDeviceStream',
    m: 'put',
    d: decodableMapping.DeviceStreamDTO,
    p: 2,
    b: true,
  },
  {
    e: '/device/{}/stream/{}',
    id: 'getDeviceStreamDetail',
    m: 'get',
    d: decodableMapping.DeviceStreamDTO,
    p: 2,
  },
  {
    e: '/device/{}/stream/{}',
    id: 'deleteDeviceStream',
    m: 'delete',
    p: 2,
  },
  {
    e: '/device/{}/stream/{}/validation',
    id: 'getDeviceStreamValidation',
    m: 'get',
    d: decodableMapping.DeviceStreamValidationDTO,
    p: 2,
  },
  {
    e: '/device/{}/stream/{}/action',
    id: 'performDeviceStreamAction',
    m: 'post',
    d: decodableMapping.DeviceStreamDTO,
    p: 2,
    b: true,
  },
  {
    e: '/payment/platform-plan',
    id: 'getAvailablePlatformPlans',
    m: 'get',
    d: decodableMapping.PlatformModulePlansResponseModel,
  },
  {
    e: '/app/maintenance',
    id: 'getScheduledMaintenance',
    m: 'get',
    d: decodableMapping.PlannedMaintenanceDTO,
  },
  {
    e: '/gw/dashboard/{}',
    id: 'getPublicDashboardDetail',
    m: 'get',
    d: decodableMapping.PublicDashboardDTO,
    p: 1,
  },
  {
    e: '/gw/dashboard/{}/live-widget',
    id: 'getPublicDashboardLiveWidgets',
    m: 'get',
    d: decodableMapping.LiveWidgetsDTO,
    p: 1,
  },
  {
    e: '/gw/dashboard/{}/live-widget/{}',
    id: 'getPublicDashboardLiveWidget',
    m: 'get',
    d: decodableMapping.LiveWidgetDTO,
    p: 2,
  },
  {
    e: '/gw/dashboard/{}/live-widget/{}/data',
    id: 'getPublicDashboardLiveWidgetData',
    m: 'get',
    p: 2,
  },
  {
    e: '/gw/dashboard/{}/live-widget/{}/export',
    id: 'createPublicDashboardLiveWidgetExport',
    m: 'post',
    d: decodableMapping.LiveWidgetExportAcceptedDTO,
    p: 2,
    b: true,
  },
  {
    e: '/gw/dashboard/{}/image/event/{}',
    id: 'getPublicDashboardEventImage',
    m: 'get',
    p: 2,
    bin: true,
  },
];

export interface Api {
  applyPromocode(uuid: string, data: ApplyPromocode, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  authenticateUser(data: LoginUser, options?: ROptions<void, void>): Observable<ApiResponse<Token>>;
  authorizePaymentMethod(data: CreatePaymentMethodDataModel, options?: ROptions<void, void>): Observable<ApiResponse<PaymentMethodModel>>;
  cancelActiveUpload(uploadId: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  cancelCart(cartUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  cancelFileUpload(fileUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  cancelSubscription(options?: ROptions<void, void>): Observable<ApiResponse<SubscriptionDTO>>;
  cartCheckout(cartUuid: string, data: CartCheckout, options?: ROptions<void, void>): Observable<ApiResponse<CartCheckoutResponseModel>>;
  changePassword(data: ChangePwRequestWEmail, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  checkCounts(uuid: string, locationUuid: string, dataSourceUuid: string, data: CheckCounts, options?: ROptions<void, void>): Observable<ApiResponse<CheckCountsDTO>>;
  checkCountsResult(uuid: string, locationUuid: string, dataSourceUuid: string, taskHash: string, options?: ROptions<void, void>): Observable<ApiResponse<FiltersCounts>>;
  checkGVVICounts(uuid: string, dataSourceUuid: string, data: CheckCounts, options?: ROptions<void, void>): Observable<ApiResponse<CheckCountsDTO>>;
  checkGVVICountsResult(uuid: string, dataSourceUuid: string, taskHash: string, options?: ROptions<void, void>): Observable<ApiResponse<FiltersCounts>>;
  checkLiveExportDataSize(projectUuid: string, data: ExportSizeCheckRequest, options?: ROptions<void, void>): Observable<ApiResponse<LiveExportSizeCheckResponseDTO>>;
  copyDescription(uuid: string, locationUuid: string, dataSourceUuid: string, data: CopyDescription, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  copyGVVIDescription(uuid: string, dataSourceUuid: string, data: CopyDescription, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  createClassificationScheme(data: CreateClassificationSchemeModel, options?: ROptions<void, void>): Observable<ApiResponse<ClassSchemeDetailDTO>>;
  createCreditSummary(data: CreateCreditExport, options?: ROptions<void, void>): Observable<ApiResponse<Blob>>;
  createDataSource(uuid: string, locationUuid: string, data: CreateDataSourceModel, options?: ROptions<void, void>): Observable<ApiResponse<DataSourceDetailApiModel>>;
  createDeviceStream(uuid: string, data: CreateDeviceStream, options?: ROptions<void, void>): Observable<ApiResponse<DeviceStreamDTO>>;
  createDirectory(data: CreateVaultFolderModel, options: ROptions<{
    'x-directory': string | undefined;
  }, void>): Observable<ApiResponse<VaultDirectoryModel>>;
  createEvent(dashboardUuid: string, data: CreateEvent, options?: ROptions<void, void>): Observable<ApiResponse<WidgetCreatedModel>>;
  createExport(uuid: string, locationUuid: string, dataSourceUuid: string, data: CreateExport, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  createFilter(uuid: string, locationUuid: string, dataSourceUuid: string, data: CreateFilter, options: ROptions<{
    'x-create_widget': string | undefined;
  }, void>): Observable<ApiResponse<FilterModel>>;
  createGraphicalObject(uuid: string, locationUuid: string, dataSourceUuid: string, data: CreateGraphicalObjectDataModel, options: ROptions<{
    'x-create_filter': string | undefined;
  }, void>): Observable<ApiResponse<GraphicalObjectModel>>;
  createGVVIDataSource(uuid: string, data: CreateGVVIDataSource, options: ROptions<{
    'x-directory': string | undefined;
  }, void>): Observable<ApiResponse<GVVIDataSourceDetailDTO>>;
  createGVVIExport(uuid: string, dataSourceUuid: string, data: CreateGVVIExport, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  createGVVIFilter(uuid: string, dataSourceUuid: string, data: CreateFilter, options: ROptions<{
    'x-create_widget': string | undefined;
  }, void>): Observable<ApiResponse<FilterModel>>;
  createGVVIGraphicalObject(uuid: string, dataSourceUuid: string, data: CreateGraphicalObjectDataModel, options: ROptions<{
    'x-create_filter': string | undefined;
  }, void>): Observable<ApiResponse<GraphicalObjectModel>>;
  createGVVIMultiExport(uuid: string, data: CreateMultiExport, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  createGVVIODFilters(uuid: string, dataSourceUuid: string, data: CreateODMatrix, options?: ROptions<void, void>): Observable<ApiResponse<FiltersModel>>;
  createGVVIProcessing(uuid: string, dataSourceUuid: string, data: CreateGVVIProcessing, options?: ROptions<void, void>): Observable<ApiResponse<VideoModel>>;
  createGVVITimelineMetric(uuid: string, dataSourceUuid: string, data: CreateDataMetric, options?: ROptions<void, void>): Observable<ApiResponse<DataMetricsDTO>>;
  createLiveExport(projectUuid: string, data: CreateProjectExport, options?: ROptions<void, void>): Observable<ApiResponse<ProjectExportDto>>;
  createLiveWidget(dashboardUuid: string, data: CreateLiveWidget, options?: ROptions<void, void>): Observable<ApiResponse<LiveWidgetDTO>>;
  createLiveWidgetExport(dashboardUuid: string, widgetUuid: string, data: CreateLiveWidgetExport, options?: ROptions<void, void>): Observable<ApiResponse<LiveWidgetExportAcceptedDTO>>;
  createLocation(uuid: string, data: CreateLocationModel, options?: ROptions<void, void>): Observable<ApiResponse<LocationApiModel>>;
  createMultiExport(uuid: string, data: CreateMultiExport, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  createNewCart(data: CreateCartPaymentRequest, options?: ROptions<void, void>): Observable<ApiResponse<PaymentRequestDTO>>;
  createNewPasswordRequest(data: Email, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  createODFilters(uuid: string, locationUuid: string, dataSourceUuid: string, data: CreateODMatrix, options?: ROptions<void, void>): Observable<ApiResponse<FiltersModel>>;
  createOrganization(data: CreateOrganization, options?: ROptions<void, void>): Observable<ApiResponse<OrganizationDTO>>;
  createOrganizationUserInvitation(data: CreateOrganizationUser, options?: ROptions<void, void>): Observable<ApiResponse<OrganizationUsersDTO>>;
  createProcessing(uuid: string, locationUuid: string, dataSourceUuid: string, data: CreateVideoUploadFromVaultDataModel, options?: ROptions<void, void>): Observable<ApiResponse<VideoModel>>;
  createProject(data: CreateProjectModel, options?: ROptions<void, void>): Observable<ApiResponse<ProjectApiModel>>;
  createProjectCollaboration(uuid: string, data: CreateProjectCollaborationModel, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  createProjectDashboard(uuid: string, data: CreateDashboardModel, options?: ROptions<void, void>): Observable<ApiResponse<DashboardApiModel>>;
  createProjectDirectory(uuid: string, data: CreateProjectDirectory, options: ROptions<{
    'x-directory': string | undefined;
  }, void>): Observable<ApiResponse<ProjectDirectoryDTO>>;
  createPublicDashboardLiveWidgetExport(dashboardUuid: string, widgetUuid: string, data: CreateLiveWidgetExport, options?: ROptions<void, void>): Observable<ApiResponse<LiveWidgetExportAcceptedDTO>>;
  createStreamFromDiscovery(uuid: string, data: CreateDeviceStreamFromDiscovery, options?: ROptions<void, void>): Observable<ApiResponse<DeviceStreamDTO>>;
  createTimelineMetric(uuid: string, locationUuid: string, dataSourceUuid: string, data: CreateDataMetric, options?: ROptions<void, void>): Observable<ApiResponse<DataMetricsDTO>>;
  createTrafficScenario(dashboardUuid: string, data: CreateTrafficScenario, options?: ROptions<void, void>): Observable<ApiResponse<TrafficScenarioDTO>>;
  createUser(data: CreateUser, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  createVaultUpload(data: CreateVaultUpload, options?: ROptions<void, void>): Observable<ApiResponse<VaultUploadDetailModel>>;
  createWebPushSubscription(data: CreateWebPushSubscription, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  createWidget(dashboardUuid: string, data: CreateWidget, options?: ROptions<void, void>): Observable<ApiResponse<WidgetCreatedModel>>;
  dataSourceAction(uuid: string, locationUuid: string, data: DataSourceActionDTO, options?: ROptions<void, void>): Observable<ApiResponse<DataSourcesApiModel>>;
  deleteClassificationScheme(classSchemeUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteCollaboration(uuid: string, collaboratorUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteDataSource(uuid: string, locationUuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteDataSourceCalibration(uuid: string, locationUuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteDeviceStream(uuid: string, streamUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteDirectory(options: ROptions<{
    'x-directory': string | undefined;
  }, void>): Observable<ApiResponse<void>>;
  deleteExport(uuid: string, locationUuid: string, dataSourceUuid: string, exportUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteFile(fileUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteFilter(uuid: string, locationUuid: string, dataSourceUuid: string, filterUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteGraphicalObject(uuid: string, locationUuid: string, dataSourceUuid: string, graphicalObjectUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteGraphicalObjects(uuid: string, locationUuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteGVVIDataSource(uuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteGVVIExport(uuid: string, dataSourceUuid: string, exportUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteGVVIFilter(uuid: string, dataSourceUuid: string, filterUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteGVVIGraphicalObject(uuid: string, dataSourceUuid: string, graphicalObjectUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteGVVIGraphicalObjects(uuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteGVVIProcessing(uuid: string, dataSourceUuid: string, processingUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteGVVITimelineMetric(uuid: string, dataSourceUuid: string, metricUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DataMetricsDTO>>;
  deleteLiveWidget(dashboardUuid: string, widgetUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteLocation(uuid: string, locationUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteOrganizationUser(uuid: string, options?: ROptions<void, void>): Observable<ApiResponse<OrganizationUsersDTO>>;
  deletePaymentMethod(options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteProcessing(uuid: string, locationUuid: string, dataSourceUuid: string, processingUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteProject(uuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteProjectDashboard(uuid: string, dashboardUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteProjectDirectory(uuid: string, options: ROptions<{
    'x-directory': string | undefined;
  }, void>): Observable<ApiResponse<void>>;
  deleteProjectExport(projectUuid: string, exportUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteTimelineMetric(uuid: string, locationUuid: string, dataSourceUuid: string, metricUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DataMetricsDTO>>;
  deleteTrafficScenario(dashboardUuid: string, scenarioUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteUserProfile(uuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  deleteWidget(dashboardUuid: string, widgetUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<WidgetsModel>>;
  denyRequestNotification(uuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  discoverDeviceCameras(uuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  editClassificationScheme(classSchemeUuid: string, data: EditClassScheme, options?: ROptions<void, void>): Observable<ApiResponse<ClassSchemeDetailDTO>>;
  editCollaboration(uuid: string, collaboratorUuid: string, data: EditProjectCollaborationModel, options?: ROptions<void, void>): Observable<ApiResponse<UserProjectShareModel>>;
  editDataSource(uuid: string, locationUuid: string, dataSourceUuid: string, data: EditDataSource, options?: ROptions<void, void>): Observable<ApiResponse<DataSourceDetailApiModel>>;
  editDataSourceScreenshot(uuid: string, locationUuid: string, dataSourceUuid: string, data: UpdateDataSourceScreenshot, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  editDescription(uuid: string, locationUuid: string, dataSourceUuid: string, data: EditDescription, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  editDevice(uuid: string, data: EditDevice, options?: ROptions<void, void>): Observable<ApiResponse<DeviceDetailDTO>>;
  editDeviceStream(uuid: string, streamUuid: string, data: EditDeviceStream, options?: ROptions<void, void>): Observable<ApiResponse<DeviceStreamDTO>>;
  editDirectory(data: EditDirectory, options: ROptions<{
    'x-directory': string | undefined;
  }, void>): Observable<ApiResponse<VaultDirectoryModel>>;
  editEvent(dashboardUuid: string, eventUuid: string, data: EditEvent, options?: ROptions<void, void>): Observable<ApiResponse<WidgetCreatedModel>>;
  editFile(fileUuid: string, data: EditFile, options?: ROptions<void, void>): Observable<ApiResponse<VaultFileModel>>;
  editFilter(uuid: string, locationUuid: string, dataSourceUuid: string, filterUuid: string, data: EditFilter, options?: ROptions<void, void>): Observable<ApiResponse<FilterModel>>;
  editFilterOrder(uuid: string, locationUuid: string, dataSourceUuid: string, data: EditFilterOrder, options: ROptions<{
    'x-filter_group': string | undefined;
  }, void>): Observable<ApiResponse<FiltersModel>>;
  editGraphicalObject(uuid: string, locationUuid: string, dataSourceUuid: string, graphicalObjectUuid: string, data: UpdateGraphicalObjectDataModel, options?: ROptions<void, void>): Observable<ApiResponse<GraphicalObjectModel>>;
  editGVVIDataSource(uuid: string, dataSourceUuid: string, data: EditGVVIDataSource, options?: ROptions<void, void>): Observable<ApiResponse<GVVIDataSourceDetailDTO>>;
  editGVVIDataSourceScreenshot(uuid: string, dataSourceUuid: string, data: UpdateDataSourceScreenshot, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  editGVVIDescription(uuid: string, dataSourceUuid: string, data: EditDescription, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  editGVVIFilter(uuid: string, dataSourceUuid: string, filterUuid: string, data: EditFilter, options?: ROptions<void, void>): Observable<ApiResponse<FilterModel>>;
  editGVVIFilterOrder(uuid: string, dataSourceUuid: string, data: EditFilterOrder, options: ROptions<{
    'x-filter_group': string | undefined;
  }, void>): Observable<ApiResponse<FiltersModel>>;
  editGVVIGraphicalObject(uuid: string, dataSourceUuid: string, graphicalObjectUuid: string, data: UpdateGraphicalObjectDataModel, options?: ROptions<void, void>): Observable<ApiResponse<GraphicalObjectModel>>;
  editGVVIProcessing(uuid: string, dataSourceUuid: string, processingUuid: string, data: EditVideoDTO, options?: ROptions<void, void>): Observable<ApiResponse<VideoModel>>;
  editGVVITimelineMetric(uuid: string, dataSourceUuid: string, metricUuid: string, data: EditDataMetric, options?: ROptions<void, void>): Observable<ApiResponse<DataMetricsDTO>>;
  editGVVITimelineMetricOrder(uuid: string, dataSourceUuid: string, data: EditDataMetricOrder, options?: ROptions<void, void>): Observable<ApiResponse<DataMetricsDTO>>;
  editLiveWidget(dashboardUuid: string, widgetUuid: string, data: EditLiveWidget, options?: ROptions<void, void>): Observable<ApiResponse<LiveWidgetDTO>>;
  editLiveWidgetOrder(dashboardUuid: string, data: EditWidgetsOrder, options?: ROptions<void, void>): Observable<ApiResponse<LiveWidgetsDTO>>;
  editLocation(uuid: string, locationUuid: string, data: EditLocationModel, options?: ROptions<void, void>): Observable<ApiResponse<LocationApiModel>>;
  editNotificationSettings(data: EditNotificationSettings, options?: ROptions<void, void>): Observable<ApiResponse<NotificationSettingsDTO>>;
  editOrganization(data: EditOrganization, options?: ROptions<void, void>): Observable<ApiResponse<OrganizationDTO>>;
  editOrganizationUser(uuid: string, data: EditOrganizationUser, options?: ROptions<void, void>): Observable<ApiResponse<OrganizationUsersDTO>>;
  editPageSettings(data: EditPageSettings, options?: ROptions<void, void>): Observable<ApiResponse<PageSettingsDTO>>;
  editProcessing(uuid: string, locationUuid: string, dataSourceUuid: string, processingUuid: string, data: EditVideoDTO, options?: ROptions<void, void>): Observable<ApiResponse<VideoModel>>;
  editProject(uuid: string, data: EditProjectModel, options?: ROptions<void, void>): Observable<ApiResponse<ProjectApiModel>>;
  editProjectDashboard(uuid: string, dashboardUuid: string, data: EditDashboardModel, options?: ROptions<void, void>): Observable<ApiResponse<DashboardApiModel>>;
  editProjectDirectory(uuid: string, data: EditProjectDirectory, options: ROptions<{
    'x-directory': string | undefined;
  }, void>): Observable<ApiResponse<ProjectDirectoryDTO>>;
  editTimelineMetric(uuid: string, locationUuid: string, dataSourceUuid: string, metricUuid: string, data: EditDataMetric, options?: ROptions<void, void>): Observable<ApiResponse<DataMetricsDTO>>;
  editTimelineMetricOrder(uuid: string, locationUuid: string, dataSourceUuid: string, data: EditDataMetricOrder, options?: ROptions<void, void>): Observable<ApiResponse<DataMetricsDTO>>;
  editTrafficScenario(dashboardUuid: string, scenarioUuid: string, data: EditTrafficScenario, options?: ROptions<void, void>): Observable<ApiResponse<TrafficScenarioDTO>>;
  editTrafficScenarioEvent(dashboardUuid: string, scenarioUuid: string, eventUuid: string, data: EditScenarioEvent, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  editTrafficScenarioOrder(dashboardUuid: string, data: EditScenarioOrder, options?: ROptions<void, void>): Observable<ApiResponse<TrafficScenariosDTO>>;
  editWidget(dashboardUuid: string, widgetUuid: string, data: EditWidget, options?: ROptions<void, void>): Observable<ApiResponse<WidgetCreatedModel>>;
  editWidgetOrder(dashboardUuid: string, data: EditWidgetsOrder, options?: ROptions<void, void>): Observable<ApiResponse<WidgetsModel>>;
  exportAction(uuid: string, locationUuid: string, dataSourceUuid: string, exportUuid: string, data: ShareExportModel, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  finishFileUpload(fileUuid: string, data: FinishVideoUpload, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  finishProjectOwnershipTransfer(uuid: string, request: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  getActiveVaultUploads(options?: ROptions<void, void>): Observable<ApiResponse<VaultUploadsModel>>;
  getAppThemes(options?: ROptions<void, void>): Observable<ApiResponse<AppThemesDTO>>;
  getAuthorizePaymentMethods(options?: ROptions<void, void>): Observable<ApiResponse<PaymentMethodModel>>;
  getAvailablePlatformPlans(options?: ROptions<void, void>): Observable<ApiResponse<PlatformModulePlansResponseModel>>;
  getBillingAccount(options?: ROptions<void, void>): Observable<ApiResponse<BillingAccountDTO>>;
  getBillingAddressInfo(data: BillingAddress, options?: ROptions<void, void>): Observable<ApiResponse<BillingAddressVATDTO>>;
  getCartDetail(cartUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<CartPaymentRequestModel>>;
  getCarts(options?: ROptions<void, void>): Observable<ApiResponse<PaymentRequestsDTO>>;
  getClassificationSchemeDetail(classSchemeUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<ClassSchemeDetailDTO>>;
  getClassificationSchemes(options: ROptions<{
    'x-language': string | undefined; 'x-live_traffic': string | undefined; 'x-device_type': string | undefined;
  }, void>): Observable<ApiResponse<ClassificationSchemesModel>>;
  getCollaboration(uuid: string, collaboratorUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<UserProjectShareModel>>;
  getCreditBalance(options?: ROptions<void, void>): Observable<ApiResponse<CreditBalanceDTO>>;
  getCreditHistory(options: ROptions<{
    'x-date_from': string | undefined; 'x-date_to': string | undefined; 'x-offset': string | undefined; 'x-limit': string | undefined;
  }, void>): Observable<ApiResponse<CreditHistoryDTO>>;
  getDashboardEventStatus(dashboardUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<EventInfosDTO>>;
  getDashboardTrafficScenarios(dashboardUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<TrafficScenariosDTO>>;
  getDataSource(uuid: string, locationUuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DataSourceDetailApiModel>>;
  getDataSourceCalibration(uuid: string, locationUuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<GeoCalibrationWrapper>>;
  getDataSources(uuid: string, locationUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DataSourcesApiModel, {
    'x-sort_by': SortBy, 'x-sort_direction': SortDirection, 'x-view': LayoutView, 'x-map_settings': MapSettings,
  }>>;
  getDataSourceSample(uuid: string, locationUuid: string, dataSourceUuid: string, options: ROptions<void, {
    'filter': string | undefined; 'ignore-oc': string | undefined;
  }>): Observable<ApiResponse<Blob, {
    'x-task': string,
  }>>;
  getDataSourceScreenshot(uuid: string, locationUuid: string, dataSourceUuid: string, options: ROptions<{
    'x-date': string | undefined;
  }, void>): Observable<ApiResponse<DataSourceScreenshotDTO>>;
  getDataSourceScreenshotImage(image: string, options?: ROptions<void, void>): Observable<ApiResponse<Blob>>;
  getDataSourceTimeline(uuid: string, locationUuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DataAvailabilityDTO>>;
  getDataSourceVideoTimeline(uuid: string, locationUuid: string, dataSourceUuid: string, options: ROptions<{
    'x-presign': string | undefined;
  }, void>): Observable<ApiResponse<TimelineWithFilesDTO>>;
  getDescription(uuid: string, locationUuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DescriptionDTO>>;
  getDeviceDetail(uuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DeviceDetailDTO>>;
  getDeviceInstallUrl(uuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DeviceInstallDTO>>;
  getDevices(options?: ROptions<void, void>): Observable<ApiResponse<DevicesDTO>>;
  getDeviceStreamDetail(uuid: string, streamUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DeviceStreamDTO>>;
  getDeviceStreamValidation(uuid: string, streamUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DeviceStreamValidationDTO>>;
  getEdit(uuid: string, locationUuid: string, dataSourceUuid: string, editUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DataEditDTO>>;
  getEventImage(image: string, options?: ROptions<void, void>): Observable<ApiResponse<Blob, {
    'x-bbox': Bbox,
  }>>;
  getExportFile(exportUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<Blob>>;
  getExports(uuid: string, locationUuid: string, dataSourceUuid: string, options: ROptions<{
    'x-offset': string | undefined; 'x-limit': string | undefined;
  }, void>): Observable<ApiResponse<ExportsApiModel, {
    'x-sort_by': SortBy, 'x-sort_direction': SortDirection,
  }>>;
  getFileAccessReport(options?: ROptions<void, void>): Observable<ApiResponse<Blob>>;
  getFileBundledFiles(fileUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<BundledFilesDTO>>;
  getFileDetail(fileUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<FileDownloadDTO>>;
  getFilter(uuid: string, locationUuid: string, dataSourceUuid: string, filterUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<FilterDetailDTO>>;
  getFilters(uuid: string, locationUuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<FiltersModel>>;
  getFilterValidation(uuid: string, locationUuid: string, dataSourceUuid: string, filterUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<FilterValidationModel>>;
  getGraphicalObject(uuid: string, locationUuid: string, dataSourceUuid: string, graphicalObjectUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<GraphicalObjectModel>>;
  getGraphicalObjects(uuid: string, locationUuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<GraphicalObjectsDataModel>>;
  getGraphicalObjectValidation(uuid: string, locationUuid: string, dataSourceUuid: string, graphicalObjectUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<GraphicalObjectValidationModel>>;
  getGVVIDataEdit(uuid: string, dataSourceUuid: string, editUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DataEditDTO>>;
  getGVVIDataSource(uuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<GVVIDataSourceDetailDTO>>;
  getGVVIDataSourceSample(uuid: string, dataSourceUuid: string, options: ROptions<void, {
    'filter': string | undefined; 'ignore-oc': string | undefined;
  }>): Observable<ApiResponse<Blob, {
    'x-task': string,
  }>>;
  getGVVIDataSourceScreenshot(uuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DataSourceScreenshotDTO>>;
  getGVVIDataSourceTimeline(uuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DataAvailabilityDTO>>;
  getGVVIDataSourceVideoTimeline(uuid: string, dataSourceUuid: string, options: ROptions<{
    'x-presign': string | undefined;
  }, void>): Observable<ApiResponse<TimelineWithFilesDTO>>;
  getGVVIDescription(uuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DescriptionDTO>>;
  getGVVIExports(uuid: string, dataSourceUuid: string, options: ROptions<{
    'x-offset': string | undefined; 'x-limit': string | undefined;
  }, void>): Observable<ApiResponse<ExportsApiModel, {
    'x-sort_by': SortBy, 'x-sort_direction': SortDirection,
  }>>;
  getGVVIFilter(uuid: string, dataSourceUuid: string, filterUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<FilterDetailDTO>>;
  getGVVIFilters(uuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<FiltersModel>>;
  getGVVIFilterValidation(uuid: string, dataSourceUuid: string, filterUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<FilterValidationModel>>;
  getGVVIGraphicalObject(uuid: string, dataSourceUuid: string, graphicalObjectUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<GraphicalObjectModel>>;
  getGVVIGraphicalObjects(uuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<GraphicalObjectsDataModel>>;
  getGVVIGraphicalObjectValidation(uuid: string, dataSourceUuid: string, graphicalObjectUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<GraphicalObjectValidationModel>>;
  getGVVIProcessingDetail(uuid: string, dataSourceUuid: string, processingUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<VideoModel>>;
  getGVVIProcessings(uuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<ProcessingsDTO>>;
  getGVVIProjectDataSources(uuid: string, options: ROptions<{
    'x-data_source_filter': string | undefined; 'x-directory': string | undefined; 'x-offset': string | undefined; 'x-limit': string | undefined;
  }, void>): Observable<ApiResponse<DataSourcesWithDirectoriesDTO, {
    'x-data_source_filter': DataSourceFilter,
  }>>;
  getGVVIProjectDataSourcesMapData(uuid: string, options: ROptions<{
    'x-data_source_filter': string | undefined;
  }, void>): Observable<ApiResponse<DataSourcesMapDataDTO, {
    'x-data_source_filter': DataSourceFilter,
  }>>;
  getGVVIProjectSimilarDataSources(uuid: string, options: ROptions<{
    'x-similar_to': string | undefined;
  }, void>): Observable<ApiResponse<GVVIDataSourceTargetsDTO, {
    'x-data_source_filter': DataSourceFilter,
  }>>;
  getGVVITimelineMetric(uuid: string, dataSourceUuid: string, metricUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DataMetricDTO>>;
  getGVVITimelineMetricData(uuid: string, dataSourceUuid: string, metricUuid: string, options: ROptions<{
    'x-offset': string | undefined; 'x-limit': string | undefined;
  }, {
    'start': string | undefined; 'end': string | undefined; 'granularity': string | undefined;
  }>): Observable<ApiResponse<DataMetric>>;
  getGVVITimelineMetrics(uuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DataMetricsDTO>>;
  getGVVITimelineScreenshots(uuid: string, dataSourceUuid: string, options: ROptions<void, {
    'start': string | undefined; 'end': string | undefined;
  }>): Observable<ApiResponse<AvailableScreenshotsDTO>>;
  getImageForVideo(uuid: string, options?: ROptions<void, void>): Observable<ApiResponse<Blob>>;
  getInfo(options?: ROptions<void, void>): Observable<ApiResponse<PaymentInfoModel>>;
  getInvoice(uuid: string, options?: ROptions<void, void>): Observable<ApiResponse<Blob>>;
  getInvoices(options: ROptions<{
    'x-date_from': string | undefined; 'x-date_to': string | undefined; 'x-offset': string | undefined; 'x-limit': string | undefined;
  }, void>): Observable<ApiResponse<InvoiceHistoryDTO>>;
  getLiveDataSources(projectUuid: string, options: ROptions<{
    'x-include_deleted': string | undefined;
  }, void>): Observable<ApiResponse<LiveProjectDataSourcesDTO>>;
  getLiveWidget(dashboardUuid: string, widgetUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<LiveWidgetDetailDTO>>;
  getLiveWidgetData(dashboardUuid: string, widgetUuid: string, options: ROptions<{
    'x-offset': string | undefined; 'x-limit': string | undefined; 'x-catalog_version': string | undefined;
  }, {
    'start': string | undefined; 'end': string | undefined;
  }>): Observable<ApiResponse<LiveWidgetData>>;
  getLiveWidgets(dashboardUuid: string, options: ROptions<{
    'x-offset': string | undefined; 'x-limit': string | undefined;
  }, void>): Observable<ApiResponse<LiveWidgetsDTO>>;
  getLocation(uuid: string, locationUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<LocationApiModel>>;
  getNextSubscriptionPayment(options?: ROptions<void, void>): Observable<ApiResponse<NextPaymentDTO>>;
  getNotificationCenterStatus(options?: ROptions<void, void>): Observable<ApiResponse<NotificationCenterStatusDTO>>;
  getNotificationResource(data: NotificationResource, options?: ROptions<void, void>): Observable<ApiResponse<NotificationResourceDTO>>;
  getNotificationSettings(options?: ROptions<void, void>): Observable<ApiResponse<NotificationSettingsDTO>>;
  getOrganization(options?: ROptions<void, void>): Observable<ApiResponse<OrganizationDTO>>;
  getOrganizationSettings(options?: ROptions<void, void>): Observable<ApiResponse<OrganizationSettingsDTO>>;
  getOwnUserDetail(options?: ROptions<void, void>): Observable<ApiResponse<UserApiModel>>;
  getPlottings(uuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<ProcessingPlottingsDTO>>;
  getPriceOffers(options: ROptions<{
    'x-date_from': string | undefined; 'x-date_to': string | undefined; 'x-offset': string | undefined; 'x-limit': string | undefined;
  }, void>): Observable<ApiResponse<PriceOfferHistoryDTO>>;
  getProcessing(uuid: string, locationUuid: string, dataSourceUuid: string, processingUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<VideoModel>>;
  getProcessingData(uuid: string, locationUuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<ProcessingsDTO>>;
  getProFormas(options: ROptions<{
    'x-date_from': string | undefined; 'x-date_to': string | undefined; 'x-offset': string | undefined; 'x-limit': string | undefined;
  }, void>): Observable<ApiResponse<ProFormaHistoryDTO>>;
  getProject(uuid: string, options?: ROptions<void, void>): Observable<ApiResponse<ProjectDetailDTO>>;
  getProjectDashboard(uuid: string, dashboardUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DashboardApiModel>>;
  getProjectDashboards(uuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DashboardsApiModel, {
    'x-sort_by': SortBy, 'x-sort_direction': SortDirection,
  }>>;
  getProjectDataSources(uuid: string, options: ROptions<{
    'x-similar_to': string | undefined; 'x-directory': string | undefined; 'x-service': string | undefined; 'x-export_type': string | undefined; 'x-data_source_type': string | undefined;
  }, void>): Observable<ApiResponse<IProjectDataSources>>;
  getProjectDataSourceScreenshot(uuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DataSourceScreenshotDataDTO>>;
  getProjectDirectories(uuid: string, options: ROptions<{
    'x-data_source_filter': string | undefined; 'x-directory': string | undefined;
  }, void>): Observable<ApiResponse<ProjectDirectoriesDTO, {
    'x-data_source_filter': DataSourceFilter,
  }>>;
  getProjectExportDetail(projectUuid: string, exportUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<ProjectExportDetailDTO>>;
  getProjectExports(projectUuid: string, options: ROptions<{
    'x-offset': string | undefined; 'x-limit': string | undefined; 'x-export_filter': string | undefined;
  }, void>): Observable<ApiResponse<ReportsDTO>>;
  getProjectLocations(uuid: string, options?: ROptions<void, void>): Observable<ApiResponse<LocationsApiModel, {
    'x-sort_by': SortBy, 'x-sort_direction': SortDirection, 'x-view': LayoutView, 'x-map_settings': MapSettings,
  }>>;
  getProjectOwnershipTransfer(uuid: string, request: string, options?: ROptions<void, void>): Observable<ApiResponse<ProjectApiModel>>;
  getProjects(options: ROptions<{
    'x-project_filter': string | undefined; 'x-offset': string | undefined; 'x-limit': string | undefined; 'x-map_data': string | undefined;
  }, void>): Observable<ApiResponse<ProjectsApiModel, {
    'x-project_filter': ProjectFilter, 'x-view': LayoutView, 'x-map_settings': MapSettings,
  }>>;
  getProjectVaultDirectories(options: ROptions<{
    'x-directory': string | undefined; 'x-service': string | undefined; 'x-service_id': string | undefined;
  }, void>): Observable<ApiResponse<VaultDirectoriesDTO>>;
  getPublicDashboardDetail(dashboardUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<PublicDashboardDTO>>;
  getPublicDashboardEventImage(dashboardUuid: string, image: string, options?: ROptions<void, void>): Observable<ApiResponse<Blob, {
    'x-bbox': Bbox,
  }>>;
  getPublicDashboardLiveWidget(dashboardUuid: string, widgetUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<LiveWidgetDTO>>;
  getPublicDashboardLiveWidgetData(dashboardUuid: string, widgetUuid: string, options: ROptions<{
    'x-offset': string | undefined; 'x-limit': string | undefined; 'x-catalog_version': string | undefined;
  }, {
    'start': string | undefined; 'end': string | undefined;
  }>): Observable<ApiResponse<LiveWidgetData>>;
  getPublicDashboardLiveWidgets(dashboardUuid: string, options: ROptions<{
    'x-offset': string | undefined; 'x-limit': string | undefined;
  }, void>): Observable<ApiResponse<LiveWidgetsDTO>>;
  getScheduledMaintenance(options?: ROptions<void, void>): Observable<ApiResponse<PlannedMaintenanceDTO>>;
  getSubscription(options?: ROptions<void, void>): Observable<ApiResponse<SubscriptionDTO>>;
  getTimelineMetric(uuid: string, locationUuid: string, dataSourceUuid: string, metricUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DataMetricDTO>>;
  getTimelineMetricData(uuid: string, locationUuid: string, dataSourceUuid: string, metricUuid: string, options: ROptions<{
    'x-offset': string | undefined; 'x-limit': string | undefined;
  }, {
    'start': string | undefined; 'end': string | undefined; 'granularity': string | undefined;
  }>): Observable<ApiResponse<DataMetric>>;
  getTimelineMetrics(uuid: string, locationUuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DataMetricsDTO>>;
  getTimelineScreenshots(uuid: string, locationUuid: string, dataSourceUuid: string, options: ROptions<void, {
    'start': string | undefined; 'end': string | undefined;
  }>): Observable<ApiResponse<AvailableScreenshotsDTO>>;
  getTrafficScenario(dashboardUuid: string, scenarioUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<TrafficScenarioDetailDTO>>;
  getTrafficScenarioRecipe(dashboardUuid: string, options: ROptions<{
    'x-scenario_type': string | undefined;
  }, void>): Observable<ApiResponse<TrafficScenarioRecipeWithWidgets>>;
  getTrafficScenarioRecipes(dashboardUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<TrafficScenarioTypesDTO>>;
  getTrafficScenarios(projectUuid: string, dataSourceUuid: string, options: ROptions<{
    'x-export_type': string | undefined;
  }, void>): Observable<ApiResponse<TrafficScenarioExportsDTO>>;
  getTranslationKeys(options: ROptions<{
    'x-language': string | undefined;
  }, void>): Observable<ApiResponse<TranslationKeysDTO>>;
  getUserDetail(uuid: string, options?: ROptions<void, void>): Observable<ApiResponse<UserApiModel>>;
  getUserDetailCard(uuid: string, options?: ROptions<void, void>): Observable<ApiResponse<UserCardDTO>>;
  getVaultFiles(options: ROptions<{
    'x-file_filter': string | undefined; 'x-offset': string | undefined; 'x-limit': string | undefined; 'x-directory': string | undefined; 'x-service': string | undefined; 'x-service_id': string | undefined; 'x-page_token': string | undefined;
  }, void>): Observable<ApiResponse<VaultEntriesModel, {
    'x-file_filter': FileFilter,
  }>>;
  getVaultUpload(uploadId: string, options?: ROptions<void, void>): Observable<ApiResponse<VaultUploadModel>>;
  getWelcomePage(options?: ROptions<void, void>): Observable<ApiResponse<WelcomeModel>>;
  getWidget(dashboardUuid: string, widgetUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<WidgetDetailDTO>>;
  getWidgetData(dashboardUuid: string, widgetUuid: string, options: ROptions<void, {
    'v': string | undefined;
  }>): Observable<ApiResponse<WidgetDataModel | Blob, {
    'x-image_empty': string,
  }>>;
  getWidgetEventData(dashboardUuid: string, widgetUuid: string, options: ROptions<void, {
    'start': string | undefined; 'end': string | undefined;
  }>): Observable<ApiResponse<WidgetDataModel | Blob>>;
  getWidgetExportData(dashboardUuid: string, widgetUuid: string, hash: string, image: string, options?: ROptions<void, void>): Observable<ApiResponse<Blob>>;
  getWidgetFilterDescription(dashboardUuid: string, widgetUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<FilterDescriptionDTO>>;
  getWidgetImageData(dashboardUuid: string, widgetUuid: string, hash: string, image: string, options?: ROptions<void, void>): Observable<ApiResponse<Blob>>;
  getWidgets(dashboardUuid: string, options: ROptions<{
    'x-offset': string | undefined; 'x-limit': string | undefined;
  }, void>): Observable<ApiResponse<WidgetsModel>>;
  getWidgetScreenshot(dashboardUuid: string, widgetUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DataSourceScreenshotDTO>>;
  gvviDataSourceAction(uuid: string, data: DataSourceActionDTO, options: ROptions<{
    'x-directory': string | undefined;
  }, void>): Observable<ApiResponse<GVVIDataSourcesDTO>>;
  gvviExportAction(uuid: string, dataSourceUuid: string, exportUuid: string, data: ShareExportModel, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  gvviGraphicalObjectAction(uuid: string, dataSourceUuid: string, data: GraphicalObjectAction, options?: ROptions<void, void>): Observable<ApiResponse<GraphicalObjectsDataModel>>;
  gvviProcessingAction(uuid: string, dataSourceUuid: string, data: ProcessingActionDTO, options?: ROptions<void, void>): Observable<ApiResponse<ProcessingsDTO>>;
  headProjects(options: ROptions<{
    'x-project_filter': string | undefined;
  }, void>): Observable<ApiResponse<void, {
    'x-project_filter': ProjectFilter, 'x-project_metadata': HeadProjectsDTO,
  }>>;
  initProjectOwnershipTransfer(uuid: string, data: EditProjectOwner, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  invalidateUserTokens(options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  inviteUserUsingReferral(data: InviteUserUsingReferral, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  logoutUser(options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  notificationAuth(options?: ROptions<void, void>): Observable<ApiResponse<NotificationAuthDTO>>;
  notificationInfo(options?: ROptions<void, void>): Observable<ApiResponse<NotificationInfoDTO>>;
  organizationInvitationAction(data: OrganizationInvitationAction, options?: ROptions<void, void>): Observable<ApiResponse<OrganizationInvitationResultDTO>>;
  pairOAuth(data: PairOAuth, options?: ROptions<void, void>): Observable<ApiResponse<Token>>;
  performBulkFileAction(projectUuid: string, data: PerformBulkFileAction, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  performBulkNotificationAction(data: PerformBulkNotificationAction, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  performDeviceStreamAction(uuid: string, streamUuid: string, data: PerformDeviceStreamAction, options?: ROptions<void, void>): Observable<ApiResponse<DeviceStreamDTO>>;
  performUserNotificationAction(uuid: string, data: UserNotificationAction, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  prepareEdit(uuid: string, locationUuid: string, dataSourceUuid: string, data: PrepareDataEdit, options?: ROptions<void, void>): Observable<ApiResponse<DataEditDTO>>;
  prepareGVVIEdit(uuid: string, dataSourceUuid: string, data: PrepareDataEdit, options?: ROptions<void, void>): Observable<ApiResponse<DataEditDTO>>;
  presignOrganizationLogos(options?: ROptions<void, void>): Observable<ApiResponse<OrganizationLogoPresignDTO>>;
  presignUploadingFiles(uploadId: string, data: PresignVaultUploadFiles, options?: ROptions<void, void>): Observable<ApiResponse<PresignVaultUploadFilesDTO>>;
  presignVaultFiles(data: PresignVaultFiles, options?: ROptions<void, void>): Observable<ApiResponse<TimelineFilesDTO>>;
  processingAction(uuid: string, locationUuid: string, dataSourceUuid: string, data: ProcessingActionDTO, options?: ROptions<void, void>): Observable<ApiResponse<ProcessingsDTO>>;
  readNotifications(data: ReadNotifications, options?: ROptions<void, void>): Observable<ApiResponse<NotificationMessagesDTO>>;
  refreshGVVISingleTimelineMetric(uuid: string, dataSourceUuid: string, metricUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  refreshGVVITimelineMetrics(uuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DataMetricsDTO>>;
  refreshSingleTimelineMetric(uuid: string, locationUuid: string, dataSourceUuid: string, metricUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  refreshTimelineMetrics(uuid: string, locationUuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DataMetricsDTO>>;
  refreshToken(options?: ROptions<void, void>): Observable<ApiResponse<Token>>;
  removeWebPushSubscription(options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  resetEdit(uuid: string, locationUuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  resetGVVIEdit(uuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  resetNotificationSettingsToDefault(options?: ROptions<void, void>): Observable<ApiResponse<NotificationSettingsDTO>>;
  runPlottingForProcessing(uuid: string, dataSourceUuid: string, processingUuid: string, data: ProcessingPlottingData, options?: ROptions<void, void>): Observable<ApiResponse<ProcessingPlottingDTO>>;
  searchActionLog(data: SearchLogs, options: ROptions<{
    'x-offset': string | undefined; 'x-limit': string | undefined;
  }, void>): Observable<ApiResponse<ActionLogsDTO>>;
  searchProjects(options: ROptions<{
    'x-project_filter': string | undefined;
  }, void>): Observable<ApiResponse<ProjectsSearchDTO>>;
  sendBeacon(data: ClientLog, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  sendClientLog(data: ClientLogs, options: ROptions<{
    'x-version': string | undefined; 'x-instance': string | undefined;
  }, void>): Observable<ApiResponse<void>>;
  shareDemoProject(options?: ROptions<void, void>): Observable<ApiResponse<ProjectApiModel>>;
  shareDemoProjectValidation(options?: ROptions<void, void>): Observable<ApiResponse<DemoProjectValidationModel>>;
  shareProjectExport(projectUuid: string, exportUuid: string, data: ShareExportModel, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  testEventWebhook(dashboardUuid: string, eventUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  updateBillingAddress(data: BillingAddress, options?: ROptions<void, void>): Observable<ApiResponse<BillingAddressModel>>;
  updateDataSourceCalibration(uuid: string, locationUuid: string, dataSourceUuid: string, data: GeoAnnotationPoints, options?: ROptions<void, void>): Observable<ApiResponse<GeoCalibrationWrapper>>;
  updateUserAuthProvider(uuid: string, data: EditAuthProviders, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  updateUserDetail(uuid: string, data: EditUser, options?: ROptions<void, void>): Observable<ApiResponse<UserApiModel>>;
  validateCartPayment(data: CreateCartPaymentRequest, options?: ROptions<void, void>): Observable<ApiResponse<CartValidationDTO>>;
  validateDataSource(uuid: string, locationUuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<DataSourceValidationDTO>>;
  validateDataSourceCalibration(uuid: string, locationUuid: string, dataSourceUuid: string, data: GeoAnnotationPoints, options?: ROptions<void, void>): Observable<ApiResponse<GeoCalibrationWrapper>>;
  validateDirectory(options: ROptions<{
    'x-directory': string | undefined;
  }, void>): Observable<ApiResponse<DirectoryValidationDTO>>;
  validateGVVIDataSource(uuid: string, dataSourceUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<GVVIDataSourceValidationDTO>>;
  validateGVVIDataSources(uuid: string, data: DataSourceValidation, options: ROptions<{
    'x-directory': string | undefined;
  }, void>): Observable<ApiResponse<GVVIDataSourcesValidationDTO>>;
  validateGVVIProcessing(uuid: string, dataSourceUuid: string, data: ProcessingValidation, options?: ROptions<void, void>): Observable<ApiResponse<DataSourceProcessingValidationModel>>;
  validateGVVIVideoProcessing(uuid: string, dataSourceUuid: string, processingUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<VideoValidationDTO>>;
  validateLocation(uuid: string, locationUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<LocationValidationModel>>;
  validatePayment(options: ROptions<{
    'x-amount': string | undefined;
  }, void>): Observable<ApiResponse<OneTimePaymentValidationDTO>>;
  validateProcessing(uuid: string, locationUuid: string, dataSourceUuid: string, data: ProcessingValidation, options?: ROptions<void, void>): Observable<ApiResponse<DataSourceProcessingValidationModel>>;
  validateProcessingPlotting(uuid: string, dataSourceUuid: string, processingUuid: string, data: ProcessingPlottingValidationData, options?: ROptions<void, void>): Observable<ApiResponse<ProcessingPlottingValidationResult>>;
  validateProjectDirectory(uuid: string, options: ROptions<{
    'x-directory': string | undefined; 'x-deletion': string | undefined;
  }, void>): Observable<ApiResponse<ProjectDirectoryValidationDTO>>;
  validateReferral(data: ReferralValidation, options?: ROptions<void, void>): Observable<ApiResponse<ReferralValidationDTO>>;
  validateVideoProcessing(uuid: string, locationUuid: string, dataSourceUuid: string, processingUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<VideoValidationDTO>>;
  validateWidget(dashboardUuid: string, widgetUuid: string, options?: ROptions<void, void>): Observable<ApiResponse<WidgetValidationDTO>>;
  validateWidgetImageData(dashboardUuid: string, widgetUuid: string, hash: string, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  verifyOAuthUser(data: VerifyOAuthUser, options?: ROptions<void, void>): Observable<ApiResponse<Token>>;
  verifyRequest(data: VerifyRequestWEmail, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  verifyStream(data: CheckStream, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
  verifyUser(data: VerifyUser, options?: ROptions<void, void>): Observable<ApiResponse<Token>>;
  widgetAction(dashboardUuid: string, widgetUuid: string, data: WidgetActionModel, options?: ROptions<void, void>): Observable<ApiResponse<void>>;
}
