import type { ProjectType } from '@gv/api';
import { LayoutView, MapStyle, SortBy, SortDirection } from '@gv/api';
import type { SubStateArrayModel, SubStateObjectModel } from '@gv/state';
import { initialArraySubState, initialSubState } from '@gv/state';
import type { DataSourceDetailStateModel } from '@gv/datasource';
import type { PageSettings } from '@gv/page-settings';

import type { LocationStateModel } from '../../entity/model/location/location-state-model';

export type DataSourcesSubState =
  SubStateArrayModel<DataSourceDetailStateModel>;

export type LocationSubState = SubStateObjectModel<LocationStateModel>;

export const initialDataSourcesSubState: DataSourcesSubState =
  initialArraySubState;

export const initialLocationSubState: LocationSubState = initialSubState;

export interface LocationState {
  readonly uuid: string;

  readonly projectUuid: string;
  readonly projectType: ProjectType;

  readonly location: LocationSubState;

  readonly dataSources: DataSourcesSubState;

  readonly locationPageSettings: PageSettings;
}

export const initialLocationState: LocationState = {
  uuid: undefined,

  projectUuid: undefined,
  projectType: undefined,

  location: initialLocationSubState,

  dataSources: initialDataSourcesSubState,

  locationPageSettings: {
    pageView: LayoutView.Map,
    searchTerm: '',
    sortDirection: SortDirection.Asc,
    sortingBy: SortBy.Name,
    mapSettings: {
      mapStyle: MapStyle.GVMode,
      mapZoom: 14,
      preferredZoom: 14,
    },
    globalSettingsChanged: false,
  },
};
