import type { ErrorHandler } from '@angular/core';

import { catchError } from 'rxjs/operators';
import type { Action } from '@ngrx/store';
import type { Observable } from 'rxjs';
import { withScope, captureException } from '@sentry/angular';
import { toSentryError } from '@gv/utils';

const MAX_NUMBER_OF_RETRY_ATTEMPTS = 2000;

export function effectsErrorHandler<T extends Action>(
  observable$: Observable<T>,
  errorHandler: ErrorHandler,
  retryAttemptLeft: number = MAX_NUMBER_OF_RETRY_ATTEMPTS,
): Observable<T> {
  return observable$.pipe(
    catchError((error) => {
      if (errorHandler) {
        errorHandler.handleError(error);
      }
      if (retryAttemptLeft <= 1) {
        return observable$; // last attempt
      }

      withScope((scope) => {
        scope.setLevel('warning');
        scope.setExtra('retryAttemptLeft', retryAttemptLeft);
        captureException(toSentryError('EffectsErrorHandler', error));
      });

      // Return observable that produces this particular effect
      return effectsErrorHandler(
        observable$,
        errorHandler,
        retryAttemptLeft - 1,
      );
    }),
  );
}
