import { Input, Component } from '@angular/core';

import type { ImageTextItem } from '@gv/constant';
import { TestIdModule } from '@gv/debug';

import { ImageTextItemComponent } from './image-text-item.component';

@Component({
  standalone: true,
  imports: [ImageTextItemComponent, TestIdModule],
  selector: 'gv-annotated-image',
  host: {
    class: 'relative w-fit h-fit block',
  },
  template: `
    <ng-content> </ng-content>
    @for (item of annotations; track item.uuid) {
      <gv-image-text-item [data]="item" [gvId]="item.uuid">
      </gv-image-text-item>
    }
  `,
})
export class AnnotatedImage {
  @Input()
  annotations!: ImageTextItem[];
}
