import type { OnDestroy } from '@angular/core';
import { Injectable, inject } from '@angular/core';

import type { VaultUploadModel } from '@gv/api';
import { API, ApiErrorResponse, FileState } from '@gv/api';
import {
  ElectronFile,
  ElectronRefService,
  type ElectronUploadGroupModel,
  type ElectronVaultStateFileModel,
} from '@gv/desktop/core';
import { asIndexed, StoreInject } from '@gv/state';
import { TimeoutService } from '@gv/ui/core';
import type {
  UploadGroupModel,
  VaultStateFileModel,
  VaultUploadGroupModel,
} from '@gv/upload/types';
import {
  UploadType,
  VaultStateFileState,
  VaultUploadGroupState,
  uploadConfig,
} from '@gv/upload/types';
import { USER_CONTEXT } from '@gv/user';
import {
  exhaustMapWithTrailing,
  FilterMode,
  isShallowEqualArray,
  isShallowEqualIndexedObject,
  isShallowEqualObj,
  removeFromArray,
  RequestRetryManagerService,
  resettableShareReplay,
  RetryMode,
  retryOnNetworkError,
  shallowDistinctUntilChanged,
  simpleSwitchMap,
  untilNgDestroyed,
} from '@gv/utils';
import { select } from '@ngrx/store';
import { captureException, captureMessage, withScope } from '@sentry/angular';
import { produce } from 'immer';
import {
  BehaviorSubject,
  combineLatest,
  defer,
  EMPTY,
  forkJoin,
  from,
  Observable,
  of,
} from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { calculateChunkSizeFromFileSize } from '@gv/upload/core';

import { fromUploadsState } from '../selectors';
import { UPLOADS_FEATURE_STATE } from '../uploads-feature.state';
import { UploadStateUtils } from '../upload-state-utils';
import { logger } from '../logger';

@Injectable()
export class ElectronUploadsPersistanceService implements OnDestroy {
  private userContext = inject(USER_CONTEXT);
  private electronRef = inject(ElectronRefService);
  private api = inject(API);
  private store = inject(StoreInject(UPLOADS_FEATURE_STATE));
  private timeoutService = inject(TimeoutService);
  private requestsRetryManager = inject(RequestRetryManagerService);
  private enabledSubject = new BehaviorSubject<boolean>(false);

  private enabled$ = this.enabledSubject.asObservable();

  private uploads$: Observable<readonly UploadGroupModel[]> =
    resettableShareReplay(this.enabled$, (enabled) =>
      !enabled
        ? of(undefined)
        : this.store.pipe(
            select(fromUploadsState.vault.getGroupsToPersist),
            shallowDistinctUntilChanged(),
            (source) =>
              new Observable<readonly UploadGroupModel[]>((obs) => {
                let timer: ReturnType<typeof setTimeout>;

                let pendingGroups: readonly UploadGroupModel[];
                let lastGroups: readonly UploadGroupModel[];

                const notifyPending = () => {
                  obs.next(pendingGroups);
                  lastGroups = pendingGroups;
                  pendingGroups = undefined;
                };

                const resetTimer = (flush = false) => {
                  if (flush) {
                    notifyPending();
                  }

                  this.timeoutService.clearTimeout(timer);
                };

                const setTimer = () => {
                  resetTimer();

                  timer = this.timeoutService.setTimeout(notifyPending, 60000);
                };

                const obsSubscription = source.subscribe({
                  complete: (): void => {
                    resetTimer(true);

                    if (pendingGroups) {
                      obs.complete();
                    }
                  },

                  error: (e): void => {
                    resetTimer(true);

                    obs.error(e);
                  },

                  next: (groups): void => {
                    resetTimer();

                    const isSame = isShallowEqualArray(groups, lastGroups);

                    if (isSame) {
                      return;
                    }

                    const almostSame = isShallowEqualArray(
                      groups,
                      lastGroups,
                      // eslint-disable-next-line @typescript-eslint/unbound-method
                      this.isAlmostSame,
                    );

                    if (almostSame) {
                      pendingGroups = groups;
                      setTimer();
                    } else {
                      obs.next(groups);
                      lastGroups = groups;
                    }
                  },
                });

                obs.add(obsSubscription);

                obs.add(() => resetTimer());
              }),
          ),
    );

  private uploadsSaver$: Observable<boolean> = this.enabled$.pipe(
    simpleSwitchMap(
      (): Observable<boolean> =>
        combineLatest([this.userContext.userUuid$, this.uploads$]).pipe(
          exhaustMapWithTrailing(([userUuid, uploads]) => {
            if (!uploads || !userUuid) {
              return EMPTY;
            }

            return defer(() =>
              from(
                this.electronRef.api.uploads.set({
                  uuid: userUuid,
                  uploads: uploads
                    .filter((u) => !!u.serverData)
                    .map((u) => this.mapToElectronGroup(u)),
                }),
              ),
            ).pipe(
              catchError((error) => {
                withScope((scope) => {
                  scope.setTag('subMessage', 'failed to store uploads');
                  scope.setLevel('warning');
                  captureException(error);
                });

                return EMPTY;
              }),
            );
          }),
        ),
      EMPTY,
    ),
    untilNgDestroyed(),
  );

  constructor() {
    this.uploadsSaver$.subscribe();
  }

  enable(): void {
    logger.debug('ElectronPersistanceService::enable');
    this.enabledSubject.next(true);
  }

  disable(): void {
    logger.debug('ElectronPersistanceService::disabled');
    this.enabledSubject.next(false);
  }

  ngOnDestroy(): void {
    // needed for debugService
  }

  restoreUploads(userUuid: string): Observable<readonly UploadGroupModel[]> {
    logger.debug({ userUuid }, 'ElectronPersistanceService::restore');
    const uploads$ = defer(() =>
      from(this.electronRef.api.uploads.get(userUuid)),
    );

    const { maxWaitTime, waitTime, limit } = uploadConfig.apiRetry;

    return uploads$.pipe(
      tap((uploads) => {
        withScope((scope) => {
          scope.setExtra(
            'uploads',
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            (uploads || []).map((s) => this.mapElectronGroupToSentryInfoObj(s)),
          );
          scope.setExtra('userUuid', userUuid);
          scope.setLevel('info');
          captureMessage('restoring uploads');
        });
      }),
      mergeMap((storedUploads): Observable<readonly UploadGroupModel[]> => {
        if (!storedUploads || storedUploads.length === 0) {
          return of([]);
        }

        return this.api.getActiveVaultUploads().pipe(
          retryOnNetworkError(waitTime, maxWaitTime),
          this.requestsRetryManager.createRetryOnError(
            (error) =>
              error instanceof ApiErrorResponse && error.status === 500,
            waitTime,
            maxWaitTime,
            limit,
            RetryMode.NumberOfRetries,
            this.userContext.userUuid$,
          ),
          this.requestsRetryManager.createRetryOnError(
            (error) => error instanceof ApiErrorResponse && error.status > 500,
            waitTime,
            maxWaitTime,
            5,
            RetryMode.NumberOfRetries,
            this.userContext.userUuid$,
          ),
          map(({ data }): readonly UploadGroupModel[] => {
            if (!data || data.uploads.length === 0) {
              return [];
            }

            const validUploads: UploadGroupModel[] = [];

            for (const upload of data.uploads) {
              const uploadGroup = storedUploads.find(
                (f) =>
                  f.serverData && f.serverData.uploadId === upload.uploadId,
              );

              if (uploadGroup) {
                validUploads.push(
                  this.mapElectronGroupToStateGroup(uploadGroup, upload),
                );
              }
            }

            return validUploads;
          }),
          catchError((error) => {
            withScope((scope) => {
              scope.setExtra(
                'uploads',
                storedUploads.map((s) =>
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                  this.mapElectronGroupToSentryInfoObj(s),
                ),
              );
              scope.setExtra('userUuid', userUuid);
              scope.setTag('subMessage', 'failed to restore uploads');
              captureException(error);
            });

            return of([]);
          }),
        );
      }),
      mergeMap((uploads): Observable<readonly UploadGroupModel[]> => {
        if (uploads.length === 0) {
          return of(uploads);
        }

        const observables: Observable<UploadGroupModel>[] = uploads.map((v) =>
          this.verifyEncodedFilesExists(v),
        );

        return forkJoin(observables);
      }),
      catchError((error) => {
        withScope((scope) => {
          scope.setExtra('userUuid', userUuid);
          scope.setTag('subMessage', 'failed to obtain uploads from store');
          captureException(error);
        });

        return of([]);
      }),
    );
  }

  private verifyEncodedFilesExists(
    model: UploadGroupModel,
  ): Observable<UploadGroupModel> {
    if (model.encoded.length === 0) {
      return of(model);
    }

    return of(model).pipe(
      concatMap(() =>
        combineLatest([
          ...model.encoded.map((uuid) =>
            from(
              model.files[uuid].encoded.file
                .init()
                .catch(() => model.files[uuid])
                .then(() => model.files[uuid]),
            ),
          ),
        ]),
      ),
      map((files: VaultStateFileModel[]) => {
        return produce(model, (draft) => {
          for (const file of files.reverse()) {
            if (!file.encoded.file.size) {
              removeFromArray(draft.encoded, file.uuid);
              draft.initialized.unshift(file.uuid);
              delete draft.files[file.uuid].encoded;
              draft.files[file.uuid].willEncode = undefined;
              draft.files[file.uuid].state = UploadStateUtils.set(
                VaultStateFileState.Empty,
                VaultStateFileState.Inserted,
                VaultStateFileState.Created,
              );
            }
          }

          draft.totalSize = Object.values(draft.files).reduce(
            (size, f) => size + (f.encoded?.size || f.data.size),
            0,
          );
          draft.remainingSize = draft.remainingFiles.reduce(
            (size, f) =>
              size + (draft.files[f].encoded?.size || draft.files[f].data.size),
            0,
          );
        });
      }),
    );
  }

  private mapElectronGroupToSentryInfoObj(s: ElectronUploadGroupModel): any {
    return {
      serverData: s.serverData,
      uuid: s.uuid,
      state: s.state,
      type: s.type,
      directory: s.type === UploadType.VAULT ? s.directory : undefined,
      files:
        s.filesIds &&
        s.filesIds
          .map((uuid) => s.files[uuid])
          .map((f) => ({
            a: f.serverData,
            state: UploadStateUtils.format(f.state),
          })),
    };
  }

  private mapElectronGroupToStateGroup(
    electronGroup: ElectronUploadGroupModel,
    serverUpload: VaultUploadModel,
  ): UploadGroupModel {
    const filesIds = electronGroup.filesIds;

    const files: readonly VaultStateFileModel[] = filesIds.map((f) =>
      this.mapFileForUpload(electronGroup.files[f], serverUpload),
    );

    const indexedFiles = asIndexed(files, (f) => f.uuid);

    const uploaded = filesIds.filter((uuid) =>
      UploadStateUtils.isSet(
        indexedFiles[uuid].state,
        VaultStateFileState.Uploaded,
      ),
    );

    const canceled = filesIds.filter((uuid) =>
      UploadStateUtils.isSet(
        indexedFiles[uuid].state,
        VaultStateFileState.Canceled,
      ),
    );

    const initialized = filesIds.filter((uuid) =>
      UploadStateUtils.allNotSet(
        indexedFiles[uuid].state,
        VaultStateFileState.Uploaded,
        VaultStateFileState.Canceled,
        VaultStateFileState.Encoded,
      ),
    );

    const encoded = filesIds.filter(
      (uuid) =>
        UploadStateUtils.isSet(
          indexedFiles[uuid].state,
          VaultStateFileState.Encoded,
        ) &&
        UploadStateUtils.allNotSet(
          indexedFiles[uuid].state,
          VaultStateFileState.Uploaded,
          VaultStateFileState.Canceled,
        ),
    );

    const totalSize: number = files.reduce(
      (total, f) => total + (f.encoded?.size || f.data.size),
      0,
    );

    const remainingFiles = [...encoded, ...initialized];

    const remainingSize: number = remainingFiles.reduce(
      (total, f) =>
        total + (indexedFiles[f].encoded?.size || indexedFiles[f].data.size),
      0,
    );

    const group: UploadGroupModel = {
      canceled,
      failed: [],
      files: indexedFiles,
      filesIds,
      fullMetadata: [],
      initialized,
      metadata: [],
      remainingFiles,
      remainingSize,
      state: VaultUploadGroupState.Created,
      totalSize,
      uploaded,
      uploading: [],
      encoding: [],
      encoded,
      uuid: electronGroup.uuid,
      name: electronGroup.name,
      type: UploadType.VAULT,
      isDirectory: electronGroup.isDirectory,
      directory: electronGroup.directory,
      rootPrefix: electronGroup.rootPrefix,
      projectName: electronGroup.projectName,
      projectUuid: electronGroup.projectUuid,
      serverData: {
        directory: serverUpload.directory,
        uploadId: serverUpload.uploadId,
      },
    };

    return group;
  }

  private mapFileForUpload(
    electronFile: ElectronVaultStateFileModel,
    upload: VaultUploadModel,
  ): VaultStateFileModel {
    const serverFile =
      electronFile.serverData &&
      upload.files.find((u) => u.fileUuid === electronFile.serverData.fileUuid);

    let state: VaultStateFileState = UploadStateUtils.set(
      VaultStateFileState.Empty,
      VaultStateFileState.Inserted,
      VaultStateFileState.Created,
    );

    if (electronFile.encoded) {
      state = UploadStateUtils.set(state, VaultStateFileState.Encoded);
    }

    if (
      UploadStateUtils.isSet(
        electronFile.state,
        VaultStateFileState.Uploaded,
        VaultStateFileState.Canceled,
      )
    ) {
      state = electronFile.state;
    } else if (!serverFile) {
      state = UploadStateUtils.set(
        UploadStateUtils.unset(
          electronFile.state,
          VaultStateFileState.Uploading,
          VaultStateFileState.Failed,
        ),
        VaultStateFileState.Canceled,
      );
    } else if (
      ![FileState.New, FileState.Uploading, FileState.Lost].includes(
        serverFile.state,
      )
    ) {
      state = UploadStateUtils.set(
        UploadStateUtils.unset(
          electronFile.state,
          VaultStateFileState.Uploading,
          VaultStateFileState.Failed,
        ),
        VaultStateFileState.Uploaded,
      );
    }

    const parts = calculateChunkSizeFromFileSize(electronFile.data.size);
    const file: VaultStateFileModel = {
      data: {
        file: new ElectronFile(electronFile.data.file, this.electronRef),
        relativePath: electronFile.data.relativePath,
        name: electronFile.data.name,
        size: electronFile.data.size,
        uuid: electronFile.data.uuid,
      },
      directory: electronFile.directory,
      encoded: electronFile.encoded
        ? {
            file: new ElectronFile(electronFile.encoded.file, this.electronRef),
            size: electronFile.encoded.size,
          }
        : undefined,
      errorReason: undefined,
      fullMetadata: undefined,
      metadata: undefined,
      finishUrl: undefined,
      serverData: electronFile.serverData && {
        alreadyUploaded: electronFile.serverData.alreadyUploaded,
        fileUuid: electronFile.serverData.fileUuid,
        uploadId: electronFile.serverData.uploadId,
        filename: serverFile ? serverFile.filename : electronFile.data.name,
        hash: serverFile && serverFile.hash,
        presignedUrls: undefined,
        existingParts: undefined,
        dtModified: undefined,
      },
      state,
      uploadGroupUuid: electronFile.uploadGroupUuid,
      uuid: electronFile.uuid,
      willEncode: electronFile.encoded ? true : undefined,
      uploadStarted: electronFile.uploadStarted
        ? new Date(electronFile.uploadStarted)
        : undefined,
      uploadEnded: electronFile.uploadEnded
        ? new Date(electronFile.uploadEnded)
        : undefined,
      encodingStarted: electronFile.encodingStarted
        ? new Date(electronFile.encodingStarted)
        : undefined,
      encodingEnded: electronFile.encodingEnded
        ? new Date(electronFile.encodingEnded)
        : undefined,
      presetPath: electronFile.presetPath,
      remainingParts: parts,
      parts,
    };

    return file;
  }

  /**
   * Do not use spread operator here, as it's important to include only needed parameters;
   */
  private mapToElectronGroup(
    group: UploadGroupModel,
  ): ElectronUploadGroupModel {
    const files: { [uuid: string]: ElectronVaultStateFileModel } = {};

    for (const uuid of Object.keys(group.files)) {
      const file = group.files[uuid];

      files[uuid] = {
        data: {
          file: file.data.file.path,
          name: file.data.name,
          size: file.data.size,
          uuid: file.data.uuid,
          relativePath: file.data.relativePath,
        },
        encoded: file.encoded
          ? { file: file.encoded.file.path, size: file.encoded.file.size }
          : undefined,
        directory: file.directory,
        errorReason: file.errorReason,
        fullMetadata: file.fullMetadata,
        metadata: file.metadata,
        serverData: file.serverData && {
          alreadyUploaded: file.serverData.alreadyUploaded,
          fileUuid: file.serverData.fileUuid,
          uploadId: file.serverData.uploadId,
        },
        state: file.state,
        uploadGroupUuid: file.uploadGroupUuid,
        uuid: file.uuid,
        uploadStarted: file.uploadStarted,
        uploadEnded: file.uploadEnded,
        encodingStarted: file.encodingStarted,
        presetPath: file.presetPath,
        encodingEnded: file.encodingEnded,
      };
    }

    const electronGroup: ElectronUploadGroupModel = {
      name: group.name,
      files,
      filesIds: group.filesIds,

      state: group.state,
      uuid: group.uuid,
      type: UploadType.VAULT,
      isDirectory: group.isDirectory,
      directory: group.directory,
      projectName: group.projectName,
      projectUuid: group.projectUuid,
      rootPrefix: group.rootPrefix,
      serverData: group.serverData && {
        ...group.serverData,
      },
    };

    return electronGroup;
  }

  private isAlmostSame(
    a: VaultUploadGroupModel,
    b: VaultUploadGroupModel,
  ): boolean {
    return isShallowEqualObj(
      a,
      b,
      ['filesIds', 'failed', 'serverData', 'uuid', 'files'],
      {
        files: (
          a1: { [uuid: string]: VaultStateFileModel },
          b1: { [uuid: string]: VaultStateFileModel },
        ) =>
          isShallowEqualIndexedObject(
            a1,
            b1,
            (a2: VaultStateFileModel, b2: VaultStateFileModel) =>
              isShallowEqualObj(
                a2,
                b2,
                ['serverData'],
                undefined,
                FilterMode.ONLY,
              ),
          ),
      },
      FilterMode.ONLY,
    );
  }
}
