import type { DateDTO } from '@gv/api';
import type { DateObjectUnits, DateTimeUnit, DurationLike } from 'luxon';
import { DateTime, Duration, Interval } from 'luxon';

import { ObjectUtils } from './object-utils';
export interface ZonedDate {
  readonly date: DateTime;
  readonly timezone: string;
}

export interface ZonedInterval {
  readonly start?: ZonedDate;
  readonly end?: ZonedDate;
}

export type DateFields = DateObjectUnits;

type RecursiveNonNullable1<T> = {
  [K in keyof T]-?: RecursiveNonNullable<T[K]>;
};
export type RecursiveNonNullable<T> = T extends DateTime
  ? NonNullable<T>
  : RecursiveNonNullable1<NonNullable<T>>;

export function isZonedDate(date: unknown): date is ZonedDate {
  return (
    !!date &&
    ObjectUtils.isTypeBasedOnProperty<ZonedDate>(date, 'timezone') &&
    ObjectUtils.isTypeBasedOnProperty<ZonedDate>(date, 'date')
  );
}

export function addMillisecondsToZoned(
  date: ZonedDate,
  duration: number,
): ZonedDate {
  return {
    ...date,
    date: date.date.plus({ milliseconds: duration }),
  };
}

export function setZonedMilliseconds(
  date: ZonedDate,
  duration: number,
): ZonedDate {
  return {
    ...date,
    date: date.date.set({ millisecond: duration }),
  };
}

export function getZonedMilliseconds(date: ZonedDate): number {
  return date.date.millisecond;
}

export function subMillisecondsFromZoned(
  date: ZonedDate,
  duration: number,
): ZonedDate {
  return addMillisecondsToZoned(date, -duration);
}

export function zonedDifferenceInMilliseconds(
  date1: ZonedDate,
  date2: ZonedDate,
): number;
export function zonedDifferenceInMilliseconds(
  date1: ZonedDate | undefined,
  date2: ZonedDate | undefined,
): number | undefined {
  if (!date1 || !date2) {
    return undefined;
  }

  return date1.date.diff(date2.date, 'milliseconds').toMillis();
}

export function zonedStartOfDay(date: ZonedDate): ZonedDate;
export function zonedStartOfDay(
  date: ZonedDate | undefined,
): ZonedDate | undefined;
export function zonedStartOfDay(
  date: ZonedDate | undefined,
): ZonedDate | undefined {
  if (!date) {
    return date;
  }

  return {
    ...date,
    date: date.date.startOf('day'),
  };
}

export function zonedStartOf(date: ZonedDate, unit: DateTimeUnit): ZonedDate;
export function zonedStartOf(
  date: ZonedDate | undefined,
  unit: DateTimeUnit,
): ZonedDate | undefined;
export function zonedStartOf(
  date: ZonedDate | undefined,
  unit: DateTimeUnit,
): ZonedDate | undefined {
  if (!date) {
    return date;
  }

  return {
    ...date,
    date: date.date.startOf(unit),
  };
}
export function zonedEndOf(date: ZonedDate, unit: DateTimeUnit): ZonedDate;
export function zonedEndOf(
  date: ZonedDate | undefined,
  unit: DateTimeUnit,
): ZonedDate | undefined;
export function zonedEndOf(
  date: ZonedDate | undefined,
  unit: DateTimeUnit,
): ZonedDate | undefined {
  if (!date) {
    return date;
  }

  return {
    ...date,
    date: date.date.endOf(unit),
  };
}

export function zonedEndOfDay(date: ZonedDate): ZonedDate;
export function zonedEndOfDay(
  date: ZonedDate | undefined,
): ZonedDate | undefined;
export function zonedEndOfDay(
  date: ZonedDate | undefined,
): ZonedDate | undefined {
  if (!date) {
    return date;
  }

  return {
    ...date,
    date: date.date.endOf('day'),
  };
}

export function setZonedDate(
  date: ZonedDate,
  duration: DateObjectUnits,
): ZonedDate {
  return {
    date: date.date.set(duration),
    timezone: date.timezone,
  };
}

export function addToZonedDate(
  date: ZonedDate,
  duration: DurationLike,
): ZonedDate {
  return {
    date: date.date.plus(duration),
    timezone: date.timezone,
  };
}

export function subtractFromZonedDate(
  date: ZonedDate,
  duration: DurationLike,
): ZonedDate {
  return {
    date: date.date.minus(duration),
    timezone: date.timezone,
  };
}

export function isZonedBefore(
  date1: ZonedDate,
  date2: ZonedDate | undefined,
): boolean {
  return !!date2 && date1?.date < date2.date;
}

export function isZonedBeforeOrEqual(
  date1: ZonedDate,
  date2: ZonedDate | undefined,
): boolean {
  return !isZonedAfter(date1, date2);
}

export function isZonedAfter(
  date1: ZonedDate,
  date2: ZonedDate | undefined,
): boolean {
  return !!date2 && date1.date > date2.date;
}

export function isZonedAfterOrEqual(
  date1: ZonedDate,
  date2: ZonedDate | undefined,
): boolean {
  return !isZonedBefore(date1, date2);
}

export function isZonedEqual(
  date1: ZonedDate | undefined,
  date2: ZonedDate | undefined,
): boolean {
  if (date1 === date2) {
    return true;
  }

  return !!date1 && !!date2 && date1.date.equals(date2.date);
}

export function isZonedEqualWeakly(
  date1: ZonedDate | undefined,
  date2: ZonedDate | undefined,
): boolean {
  if (date1 === date2) {
    return true;
  }

  return !!date1 && !!date2 && +date1.date === +date2.date;
}

export function mapTimezone(timezone: string): string {
  return timezone === 'Z' ? 'UTC' : timezone;
}

export function apiDateToZoned(date: DateDTO): ZonedDate;
export function apiDateToZoned(
  date: DateDTO | undefined,
): ZonedDate | undefined;
export function apiDateToZoned(
  date: DateDTO | undefined,
): ZonedDate | undefined {
  if (!date) {
    return undefined;
  }

  const timezone = mapTimezone(date.timezone);
  return {
    timezone,
    date: DateTime.fromISO(
      timezone === undefined ? date.utc.replace('Z', '') : date.utc,
      { zone: timezone },
    ),
  };
}

export function ceilZoned(date: ZonedDate): ZonedDate {
  const ms = date.date.millisecond;
  if (ms === Math.ceil(ms)) {
    return date;
  }

  return addMillisecondsToZoned(floorZoned(date), 1);
}

export function ceilZonedToSeconds(date: ZonedDate): ZonedDate {
  const ms = date?.date?.millisecond;
  if (!ms || ms === 0) {
    return date;
  }

  return addToZonedDate(floorZonedToSeconds(date), { seconds: 1 });
}

export function floorZonedToSeconds(date: ZonedDate): ZonedDate {
  const ms = date?.date?.millisecond;
  if (!ms || ms === 0) {
    return date;
  }

  return {
    timezone: date.timezone,
    date: date.date.set({ millisecond: 0 }),
  };
}

export function floorZoned(date: ZonedDate): ZonedDate {
  const ms = date.date.millisecond;
  if (ms === Math.ceil(ms)) {
    return date;
  }

  return {
    ...date,
    date: date.date.set({ millisecond: 0 }),
  };
}

export function formatZoned(
  date: ZonedDate | DateDTO | undefined,
  dateFormat: string,
): string {
  if (!date) {
    return '';
  }

  if (ObjectUtils.isTypeBasedOnProperty<DateDTO, 'utc'>(date, 'utc')) {
    return formatZoned(apiDateToZoned(date), dateFormat);
  }

  return date.date.toFormat(dateFormat);
}

export function zonedToApiDate(
  date: ZonedDate | undefined,
): string | undefined {
  if (!date) {
    return undefined;
  }
  return date.date.toUTC().toISO();
}

export function zonedToApiTimestamp(date: ZonedDate): number;
export function zonedToApiTimestamp(
  date: ZonedDate | undefined,
): number | undefined {
  if (!date) {
    return undefined;
  }
  return date.date.toUTC().toMillis();
}

export function msToDuration(ms: number): DurationLike {
  return Duration.fromMillis(ms);
}

export function isWithinZonedInterval(
  needle: ZonedDate,
  interval: ZonedInterval,
): boolean {
  if (!isZonedIntervalDefined(interval)) {
    return false;
  }

  const {
    start: { date: start },
    end: { date: end },
  } = interval;

  return (
    Interval.fromDateTimes(start, end).contains(needle.date) ||
    needle.date.toMillis() === end.toMillis() // interval end is not inclusive
  );
}

export function isZonedIntervalWithinZonedInterval(
  needle: ZonedInterval,
  haystack: ZonedInterval,
): boolean {
  if (!isZonedIntervalDefined(needle)) {
    return false;
  }

  return (
    isWithinZonedInterval(needle.start, haystack) &&
    isWithinZonedInterval(needle.end, haystack)
  );
}

export function isZonedIntervalWithinZonedIntervalLoose(
  needle: ZonedInterval,
  haystack: ZonedInterval,
): boolean {
  if (!isZonedIntervalDefined(needle)) {
    return false;
  }

  return (
    isZonedBeforeOrEqual(needle.start, haystack.end) &&
    isZonedAfterOrEqual(needle.end, haystack.start)
  );
}

export function areZonedIntervalsEqual(
  interval1: ZonedInterval | undefined,
  interval2: ZonedInterval | undefined,
): boolean {
  if (
    !isZonedIntervalDefined(interval1) ||
    !isZonedIntervalDefined(interval2)
  ) {
    return false;
  }

  return (
    isZonedEqual(interval1.start, interval2.start) &&
    isZonedEqual(interval1.end, interval2.end)
  );
}
export function areZonedIntervalsEqualWeakly(
  interval1: ZonedInterval | undefined,
  interval2: ZonedInterval | undefined,
): boolean {
  if (
    !isZonedIntervalDefined(interval1) ||
    !isZonedIntervalDefined(interval2)
  ) {
    return false;
  }

  return (
    isZonedEqualWeakly(interval1.start, interval2.start) &&
    isZonedEqualWeakly(interval1.end, interval2.end)
  );
}

export function isValidZonedDate(
  date: ZonedDate | null | undefined,
): date is ZonedDate {
  if (!date) {
    return false;
  }

  const d = date.date;
  return DateTime.isDateTime(d) && d.isValid;
}

export function isZonedIntervalDefined(
  range: ZonedInterval | undefined,
): range is RecursiveNonNullable<ZonedInterval> {
  return (
    !!range && isValidZonedDate(range.start) && isValidZonedDate(range.end)
  );
}

export function zonedIntervalDuration(date: ZonedInterval): number;
export function zonedIntervalDuration(
  date: ZonedInterval | undefined,
): number | undefined;
export function zonedIntervalDuration(
  range: ZonedInterval | undefined,
): number | undefined {
  if (!isZonedIntervalValid(range)) {
    return undefined;
  }
  return zonedDifferenceInMilliseconds(range.end, range.start);
}

export function isZonedIntervalValid(
  range: ZonedInterval | undefined,
  boundaries?: ZonedInterval | undefined,
  maxDuration?: number,
): range is RecursiveNonNullable<ZonedInterval> {
  if (!isZonedIntervalDefined(range) || isZonedBefore(range.end, range.start)) {
    return false;
  }

  if (maxDuration !== undefined) {
    const diff = zonedDifferenceInMilliseconds(range.end, range.start);
    if (diff > maxDuration) {
      return false;
    }
  }

  if (!isZonedIntervalDefined(boundaries)) {
    return true;
  }

  return (
    isZonedAfterOrEqual(
      setZonedMilliseconds(range.start, 0),
      setZonedMilliseconds(boundaries.start, 0),
    ) &&
    isZonedBeforeOrEqual(
      setZonedMilliseconds(range.end, 0),
      setZonedMilliseconds(boundaries.end, 0),
    )
  );
}

export function zonedNow(timezone: string): ZonedDate {
  return zonedFrom(Date.now(), timezone);
}

export function zonedFrom(date: Date | number, timezone: string): ZonedDate {
  const milliseconds = date instanceof Date ? date.getTime() : date;

  const t = mapTimezone(timezone);
  return {
    timezone: t,
    date: DateTime.fromMillis(milliseconds, { zone: t }),
  };
}

export function parseZoned(
  dateString: string,
  formatString: string,
  timezone: string,
): ZonedDate {
  const t = mapTimezone(timezone);
  return {
    date: DateTime.fromFormat(dateString, formatString, {
      zone: t,
    }),
    timezone: t,
  };
}

export function isZonedSameDay(date: ZonedDate, date2?: ZonedDate): boolean {
  return !!date2 && date.date.hasSame(date2.date, 'day');
}

export function isZonedSameHour(date: ZonedDate, date2?: ZonedDate): boolean {
  return !!date2 && date.date.hasSame(date2.date, 'hour');
}

export function isZonedSameMinute(date: ZonedDate, date2?: ZonedDate): boolean {
  return !!date2 && date.date.hasSame(date2.date, 'minute');
}

export function isZonedSameSecond(date: ZonedDate, date2?: ZonedDate): boolean {
  return !!date2 && date.date.hasSame(date2.date, 'second');
}

export function isZonedSameMonth(date: ZonedDate, date2?: ZonedDate): boolean {
  return !!date2 && date.date.hasSame(date2.date, 'month');
}

export function getZonedTime(
  date: ZonedDate,
): Pick<DateFields, 'hour' | 'minute' | 'second'> {
  return {
    hour: date.date.hour,
    second: date.date.second,
    minute: date.date.minute,
  };
}

export function getZonedToISONumbers(date: ZonedDate): DateFields {
  return {
    hour: date.date.hour,
    second: date.date.second,
    minute: date.date.minute,
    day: date.date.day,
    month: date.date.month,
    year: date.date.year,
  };
}

export function getZonedDate(
  date: ZonedDate,
): Pick<DateFields, 'year' | 'day' | 'month'> {
  return {
    day: date.date.day,
    month: date.date.month,
    year: date.date.year,
  };
}

export function maxFromZoned(...dates: ZonedDate[]): ZonedDate {
  let max = dates[0];
  for (let i = 1; i < dates.length; i += 1) {
    const date = dates[i];

    if (date && max && isZonedAfter(date, max)) {
      max = date;
    }
  }

  return max;
}

export function minFromZoned(...dates: ZonedDate[]): ZonedDate {
  let min = dates[0];
  for (let i = 1; i < dates.length; i += 1) {
    const date = dates[i];

    if (isZonedBefore(date, min)) {
      min = date;
    }
  }

  return min;
}

export function zonedValueOf(date: ZonedDate): number;
export function zonedValueOf(date: ZonedDate | undefined): number | undefined;
export function zonedValueOf(date: ZonedDate | undefined): number | undefined {
  return date?.date.valueOf();
}

export function clampZoned(date: ZonedDate, range: ZonedInterval): ZonedDate {
  if (!isZonedIntervalValid(range)) {
    return date;
  }

  if (date.date < range.start.date) {
    return range.start;
  }

  if (date.date > range.end.date) {
    return range.end;
  }

  return date;
}
