import { Injectable, inject } from '@angular/core';

import type { ElectronFolderModel } from '@gv/desktop/core';
import { ElectronFile, ElectronRefService } from '@gv/desktop/core';
import type {
  ChunkBoundariesModel,
  InitVaultUploadsPropsModel,
  InvalidFileModel,
  InvalidFolderModel,
  LocalFileUploadItemModel,
  UploadFile,
  VaultFileUploadItemModel,
} from '@gv/upload/types';
import {
  UploadType,
  uploadConfig,
  VideoFileValidation,
} from '@gv/upload/types';
import { generateUuid, ObjectUtils, PromiseUtils } from '@gv/utils';
import mime from 'mime';

import { FileUtils } from './file-utils';

const minChunkCount = 1;
const maxChunkCount = 10000;

const collator = new Intl.Collator('en', {
  numeric: true,
  sensitivity: 'base',
});

/**
 * Min chunk size in bytes. Must be at least 5 MB and not more than 5 GB.
 * @see https://docs.aws.amazon.com/AmazonS3/latest/dev/qfacts.html
 */
const minChunkSize: number = 5 * 1024 * 1024;

export function calculateChunkCountFromFileSize(size: number): number {
  let chunkCount = Math.ceil(size / uploadConfig.preferredChunkSize);

  if (chunkCount > 1) {
    const remainder = size % uploadConfig.preferredChunkSize;
    if (remainder > 0 && remainder < minChunkSize) {
      chunkCount--;
    }
  }

  return Math.max(minChunkCount, Math.min(maxChunkCount, chunkCount));
}

export function calculateChunkSizeFromFileSize(size: number): number {
  const numberOfChunks = calculateChunkCountFromFileSize(size);
  return Math.ceil(size / numberOfChunks);
}

export function calculateChunkBoundaries(
  size: number,
  partNumber: number,
): ChunkBoundariesModel {
  const chunkSize = calculateChunkSizeFromFileSize(size);
  const fromPosition = (partNumber - 1) * chunkSize;
  let to = partNumber * chunkSize;

  if (size - to < minChunkSize) {
    to = size;
  }

  return {
    from: fromPosition,
    to,
  };
}

@Injectable({
  providedIn: 'root',
})
export class VideoUploadHelperService {
  private electronRef = inject(ElectronRefService, { optional: true });

  /**
   * Creates video upload items list from file list
   * with default sorting by video file name.
   *
   * @param files Files to create video upload items list from.
   * @returns Video upload items list sorted by video file name.
   */
  createFileVideoUploadItems(
    files: ReadonlyArray<File | UploadFile>,
    relativeBasePath: string | undefined,
  ): readonly LocalFileUploadItemModel[] {
    return (
      files
        .map<LocalFileUploadItemModel>((file) => ({
          uuid: generateUuid(),
          file,
          lastModified: file.lastModified,
          name: file.name,
          size: file.size,
          relativePath: relativeBasePath
            ? FileUtils.pathJoin(relativeBasePath, file.name)
            : file instanceof File &&
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                ((file as any).webkitRelativePath as string)
              ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                ((file as any).webkitRelativePath as string)
              : file.name,
        }))
        // eslint-disable-next-line @typescript-eslint/unbound-method
        .sort(this.sortItemsByName)
    );
  }

  createVaultUploadItems(
    files: ReadonlyArray<File | UploadFile>,
    relativeBasePath: string | undefined,
  ): LocalFileUploadItemModel[] {
    if (!files) {
      return [];
    }
    const vaultUploadItems: LocalFileUploadItemModel[] = [];
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      vaultUploadItems.push({
        file,
        name: file.name,
        size: file.size,
        relativePath: relativeBasePath
          ? FileUtils.pathJoin(relativeBasePath, file.name)
          : file instanceof File &&
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              ((file as any).webkitRelativePath as string)
            ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              ((file as any).webkitRelativePath as string)
            : file.name,
        uuid: crypto.randomUUID(),
      });
    }

    // eslint-disable-next-line @typescript-eslint/unbound-method
    return vaultUploadItems.sort(this.sortItemsByName);
  }

  async createVaultUploadInitPropsFromFolder(
    folder: ElectronFolderModel,
    rootPrefix: string,
    destinationDirectory: string,
    createNewOnConflict: boolean,
  ): Promise<InitVaultUploadsPropsModel> {
    const files: UploadFile[] = folder.files.map(
      (f) =>
        new ElectronFile(FileUtils.pathJoin(folder.path, f), this.electronRef),
    );

    await PromiseUtils.softAll(files.map((f) => f.init()));

    return {
      type: UploadType.VAULT,
      files: this.createVaultUploadItems(files, folder.name),
      prefix: destinationDirectory,
      rootPrefix,
      createNewOnConflict,
      invalidFolders:
        folder.directories.length === 0
          ? []
          : [
              {
                name: folder.name,
                path: folder.path,
                files: folder.directories.map(
                  (d): InvalidFileModel => ({
                    file: {
                      relativePath: FileUtils.pathJoin(folder.name, d),
                      name: d,
                      size: 0,
                    },
                    validation: VideoFileValidation.NestedDirectory,
                  }),
                ),
              },
            ],
    };
  }

  /**
   * Note that method mutates the original array.
   *
   * @param videoUploadItems Array of video upload items to sort.
   * @param asc true for ascending sorting, otherwise false.
   * @returns Sorted (mutated) array of video upload items.
   */
  sortVideoUploadItemsByFilename<T extends { name: string }>(
    videoUploadItems: readonly T[],
    asc: boolean,
  ): readonly T[] {
    return [...videoUploadItems].sort((a, b) =>
      asc ? this.sortItemsByName(a, b) : this.sortItemsByName(b, a),
    );
  }

  private sortItemsByName<T extends { name: string }>(a: T, b: T): number {
    return collator.compare(a.name || '', b.name || '');
  }

  async loadDirectoryFiles(
    directory: DirectoryEntry,
    path: string[],
  ): Promise<{
    files: ReadonlyArray<File | UploadFile>;
    invalid: InvalidFolderModel;
  }> {
    const reader = directory.createReader();
    let fileEntriesPromises: Promise<File | UploadFile>[] = [];

    const currentPath = [...path, directory.name];
    const currentPathStr = currentPath.join('/');

    let invalidFiles: InvalidFileModel[] = [];

    const loadNext = (): Promise<boolean> => {
      return new Promise<boolean>((loadFinished, loadFailed) => {
        reader.readEntries((entries) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          fileEntriesPromises = fileEntriesPromises.concat(
            entries
              .filter((f): f is FileEntry => f.isFile)
              .map((f) => this.fileEntryToFile(f, currentPathStr)),
          );

          invalidFiles = invalidFiles.concat(
            entries
              .filter((f) => f.isDirectory)
              .map(
                (d): InvalidFileModel => ({
                  validation: VideoFileValidation.NestedDirectory,
                  file: {
                    name: d.name,
                    size: 0,
                    relativePath: FileUtils.pathJoin(currentPathStr, d.name),
                  },
                }),
              ),
          );

          loadFinished(entries.length > 0);
        }, loadFailed);
      });
    };

    // eslint-disable-next-line no-empty
    while (await loadNext()) {}

    const files = await PromiseUtils.softAll(fileEntriesPromises);

    const invalidFolder: InvalidFolderModel = {
      files: invalidFiles,
      name: directory.name,
      path: currentPath.join('/'),
    };

    return {
      files,
      invalid: invalidFolder,
    };
  }

  async getUploadsFromDroppedItems(
    itemsArray: DataTransferItem[],
    rootPrefix: string,
    prefix: string,
  ): Promise<ReadonlyArray<InitVaultUploadsPropsModel>> {
    const files = itemsArray
      .map((item): FileSystemEntry | File => {
        if (item.webkitGetAsEntry) {
          const file: FileSystemEntry = item.webkitGetAsEntry();

          if (file?.isFile) {
            return item.getAsFile();
          }

          return file;
        }

        return item.getAsFile();
      })
      .filter((f) => !!f);

    const rawFilesPromises: Promise<File | UploadFile>[] = files
      .filter((f): f is File => f instanceof File)
      .map((file) => this.mapFile(file));

    const webkitFilesPromises: Promise<File | UploadFile>[] = files
      .filter((f): f is FileEntry => !(f instanceof File) && f.isFile)
      .map((f) => this.fileEntryToFile(f));

    const webkitDirectories: Promise<InitVaultUploadsPropsModel>[] = files
      .filter((f): f is DirectoryEntry => !(f instanceof File) && f.isDirectory)
      .map((f) =>
        this.loadDirectoryFiles(f, []).then(
          (directoryFiles): InitVaultUploadsPropsModel => ({
            files: this.createVaultUploadItems(directoryFiles.files, f.name),
            ...FileUtils.getUploadOptions(
              rootPrefix,
              prefix,
              f.name,
              'dropped',
            ),
            invalidFolders: [directoryFiles.invalid],
            type: UploadType.VAULT,
          }),
        ),
      );

    const directoriesUploads = await PromiseUtils.softAll(
      webkitDirectories,
    ).then((directories) => directories && directories.filter((d) => !!d));

    const webkitFiles: (File | UploadFile)[] =
      webkitFilesPromises.length === 0
        ? []
        : await PromiseUtils.softAll(webkitFilesPromises);

    const rawFiles: (File | UploadFile)[] =
      rawFilesPromises.length === 0
        ? []
        : await PromiseUtils.softAll(rawFilesPromises);

    if (
      (!webkitFiles || webkitFiles.length === 0) &&
      (!rawFiles || rawFiles.length === 0)
    ) {
      return directoriesUploads;
    }

    return [
      ...(directoriesUploads || []),
      {
        ...FileUtils.getUploadOptions(rootPrefix, prefix, undefined, 'dropped'),
        files: this.createVaultUploadItems(
          [...webkitFiles, ...rawFiles],
          undefined,
        ),
        invalidFolders: [],
        type: UploadType.VAULT,
      },
    ];
  }

  getFileValidationForUpload(
    file: File | UploadFile,
    relativePath?: string,
  ): VideoFileValidation {
    if (!this.isFileTypeSupported(file.type)) {
      const mimeFromName = mime.getType(file.name);
      if (!mimeFromName || !this.isFileTypeSupported(mimeFromName)) {
        return VideoFileValidation.InvalidFile;
      }
    }

    if (file.size <= uploadConfig.minFileSize) {
      return VideoFileValidation.InvalidFile;
    } else if (file.size > uploadConfig.maxFileSize) {
      return VideoFileValidation.InvalidFileSize;
    } else if (!FileUtils.isNotNested(relativePath ? relativePath : file)) {
      return VideoFileValidation.NestedFile;
    }

    return VideoFileValidation.Valid;
  }

  getVaultFileValidationForUpload(
    uploadItem: VaultFileUploadItemModel,
  ): VideoFileValidation {
    if (uploadItem.size <= uploadConfig.minFileSize) {
      return VideoFileValidation.InvalidFile;
    } else if (uploadItem.size > uploadConfig.maxFileSize) {
      return VideoFileValidation.InvalidFileSize;
    }

    return VideoFileValidation.Valid;
  }

  private fileEntryToFile(
    fileEntry: FileEntry,
    path?: string,
  ): Promise<File | UploadFile> {
    return new Promise<File | UploadFile>((resolve, reject) => {
      fileEntry.file((domFile: File) => {
        this.mapFile(domFile, path ? { path, name: fileEntry.name } : undefined)
          .then(resolve)
          .catch(reject);
      }, reject);
    });
  }

  private mapFile(
    domFile: File,
    options?: { path: string; name: string },
  ): Promise<File | UploadFile> {
    return new Promise<File | UploadFile>((resolve, reject) => {
      if (this.electronRef) {
        // we have electron domFile with full filesystem path
        // convert it to electron file
        const file = new ElectronFile(domFile.path, this.electronRef);

        file.init().then(() => resolve(file), reject);
        return;
      }

      if (!domFile.path) {
        ObjectUtils.assign(domFile, {
          path: options
            ? [options.path, options.name].join('/')
            : options?.name || domFile.name,
        });
      }

      resolve(domFile);
    });
  }

  private isFileTypeSupported(fileType: string): boolean {
    if (
      uploadConfig.supportedFileTypes.findIndex((type) => type === fileType) !==
      -1
    ) {
      return true;
    }
    return fileType.startsWith('video/');
  }
}
