import type { ExtendedWindow } from '@gv/ui/core';
import { Environment } from '@gv/ui/core';
import mixpanel from 'mixpanel-browser';

import { mixpanelId } from './config';
import type { MixPanelEvents } from './mixpanel-events';

const MIXPANEL_DEBUG_KEY = 'gv-mixpanel-debug';

export interface UserProperties {
  name: string;
  uuid: string;
  organization: string | undefined;
  orgUuid: string | undefined;
  plan: 'trial' | 'modeller' | 'surveyor' | 'enterprise' | undefined;
  vaultSize: number;
  loginType: 'user' | 'google';
  language: 'en' | 'es' | 'pt';
}

function normalizeString(text: string): string {
  return text.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
}

function normalizeObj(data: Record<string, any>): Record<string, any> {
  return Object.keys(data).reduce(
    (acc, v: string) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      acc[normalizeString(v)] = data[v];
      return acc;
    },
    <Record<string, any>>{},
  );
}

function getEnv(): keyof typeof mixpanelId {
  switch ((window as unknown as ExtendedWindow)._environment) {
    case Environment.ElectronProduction:
    case Environment.Production:
      return 'production';

    case Environment.ElectronStaging:
    case Environment.Staging:
    case Environment.ElectronDev:
      return 'staging';
  }
  return 'dev';
}

function getToken(): string {
  return mixpanelId[getEnv()];
}

export function init(): void {
  mixpanel.init(getToken(), { debug: getEnv() === 'dev' });
}

const eventQueue: Parameters<typeof trackEvent>[] = [];

export function trackEvent<K extends keyof MixPanelEvents>(
  name: K,
  data: MixPanelEvents[K],
  force = false,
): void {
  if (!userDataInitialized && !force) {
    eventQueue.push([name, data]);
    return;
  }

  if (!localStorage.getItem(MIXPANEL_DEBUG_KEY)) {
    return;
  }

  if (!data) {
    mixpanel.track(normalizeString(name));
  } else {
    mixpanel.track(normalizeString(name), normalizeObj(data));
  }
}

let userDataInitialized = false;

export function trackUser(user: UserProperties): void {
  userDataInitialized = true;
  mixpanel.register(normalizeObj(user));
  if (eventQueue.length) {
    for (const event of eventQueue) {
      trackEvent(...event); // we can call this safely as userDataInitialized is true hence queue processing is disabled
    }
    eventQueue.length = 0;
  }
}

export function reset(): void {
  userDataInitialized = false;
  eventQueue.length = 0;
  mixpanel.reset();
}

export function identify(userUuid: string): void {
  mixpanel.identify(userUuid);
}
