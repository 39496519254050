import { HttpErrorResponse } from '@angular/common/http';

import type { ActionCreator, ReducerTypes } from '@ngrx/store';
import { on } from '@ngrx/store';
import type { Draft } from 'immer';
import { produce } from 'immer';
import { ApiErrorResponse } from '@gv/api';

import { asUuidIndexed } from '../as-indexed';
import type { BaseSubStateActions } from './create-sub-state-action';
import type {
  SubStateArrayModel,
  SubStateObjectModel,
} from './sub-state-model';

function uuidExtractor({ uuid }: { uuid: string }): string {
  return uuid;
}

export function createSubStateReducerFns<
  State,
  ApiModel,
  StateEntityModel extends { readonly uuid: string },
  L extends SubStateArrayModel<StateEntityModel>,
  FetchType = undefined,
  CompletedType = undefined,
>(
  actions: BaseSubStateActions<
    readonly ApiModel[],
    readonly StateEntityModel[],
    FetchType,
    CompletedType
  >,
  subStateSelector: (state: Draft<State>) => Draft<L>,
  modelMapper: (
    state: State,
    apiModel: readonly ApiModel[],
    dtSent: Date,
  ) => readonly StateEntityModel[],
  defaultErrorMessage: string,
  isArray: true,
): ReducerTypes<State, ActionCreator[]>[];
export function createSubStateReducerFns<
  DraftState extends Draft<unknown>,
  ApiModel,
  StateEntityModel,
  L extends SubStateObjectModel<StateEntityModel>,
  State = DraftState extends Draft<infer S> ? S : never,
>(
  actions: BaseSubStateActions<ApiModel, StateEntityModel>,
  subStateSelector: (state: DraftState) => Draft<L>,
  modelMapper: (
    state: State,
    apiModel: ApiModel,
    dtSent: Date,
  ) => StateEntityModel,
  defaultErrorMessage: string,
): ReducerTypes<State, ActionCreator[]>[];
export function createSubStateReducerFns<
  State,
  ApiModel,
  StateEntityModel extends { uuid: string },
  L extends SubStateObjectModel<unknown> | SubStateArrayModel<StateEntityModel>,
>(
  actions:
    | BaseSubStateActions<ApiModel, StateEntityModel>
    | BaseSubStateActions<readonly ApiModel[], readonly StateEntityModel[]>,
  subStateSelector: (state: Draft<State>) => Draft<L>,
  modelMapper: (
    state: State extends infer S ? S : never,
    apiModel: any,
    dtSent: Date,
  ) => any,
  defaultErrorMessage: string,
  isArray?: boolean,
): ReducerTypes<State, ActionCreator[]>[] {
  const setDataArray = (
    subState: Draft<L>,
    _data: readonly StateEntityModel[],
  ) => {
    const data = _data || [];
    (subState as Draft<SubStateArrayModel<StateEntityModel>>).ids =
      data.map(uuidExtractor);
    (subState as Draft<SubStateArrayModel<StateEntityModel>>).data =
      asUuidIndexed(data) as Draft<Record<string, StateEntityModel>>;
  };

  const setObjectData = (subState: Draft<L>, _data: StateEntityModel) => {
    subState.data = _data;
  };

  const setData = isArray ? setDataArray : setObjectData;

  return [
    on<State, [typeof actions.fetch.started]>(actions.fetch.started, (state) =>
      produce(state, (draft: Draft<State>): void => {
        const subState = subStateSelector(draft);
        subState.loading = true;
      }),
    ),

    on(actions.fetch.completed, (state, action) =>
      produce(state, (draft: Draft<State>) => {
        const subState = subStateSelector(draft);
        setData(
          subState,
          modelMapper(state as any, action.data, action.dtSent),
        );
        subState.loading = false;
        subState.freshness = action.dtSent;
        subState.dirty = false;
        subState.loaded = true;
        subState.error = undefined;
      }),
    ),

    on(actions.fetch.cancel, (state) =>
      produce(state, (draft: Draft<State>) => {
        const subState = subStateSelector(draft);
        subState.loading = false;
      }),
    ),

    on(actions.fetch.error, (state, action) =>
      produce(state, (draft: Draft<State>) => {
        const subState = subStateSelector(draft);
        if (isArray) {
          (subState as Draft<SubStateArrayModel<any>>).ids = [];
          (subState as Draft<SubStateArrayModel<any>>).data = {};
        } else {
          subState.data = undefined;
        }
        subState.loading = false;
        subState.freshness = undefined;
        subState.dirty = false;
        subState.loaded = false;
        subState.error = {
          code:
            action.error instanceof ApiErrorResponse
              ? action.error.status
              : action.error instanceof HttpErrorResponse
                ? action.error.status
                : undefined,
          message:
            action.error instanceof ApiErrorResponse
              ? action.error.apiMessage?.message
              : defaultErrorMessage,
        };
      }),
    ),

    on(actions.watching.acquire, (state) =>
      produce(state, (draft: Draft<State>) => {
        const subState = subStateSelector(draft);
        subState.watching += 1;
      }),
    ),

    on(actions.watching.release, (state) =>
      produce(state, (draft: Draft<State>) => {
        const subState = subStateSelector(draft);
        subState.watching = Math.max(0, subState.watching - 1);
      }),
    ),

    on(actions.markAsDirty, (state) =>
      produce(state, (draft: Draft<State>) => {
        const subState = subStateSelector(draft);
        subState.dirty = true;
      }),
    ),

    on(actions.hydrate, (state, action) =>
      produce(state, (draft: Draft<State>) => {
        const subState = subStateSelector(draft);

        setData(subState, action.data as any);
        subState.freshness = action.dtSent;
        subState.loaded = true;
        subState.error = undefined;
      }),
    ),

    on(actions.highlight, (state, action) =>
      produce(state, (draft: Draft<State>) => {
        const subState = subStateSelector(draft);

        subState.highlighted = {
          uuid: action.highlight,
          date: action.dtSent,
        };
      }),
    ),
  ];
}
