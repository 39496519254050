import type { MonoTypeOperatorFunction } from 'rxjs';

import { isShallowEqual } from '../utils/memo-utils';
import { distinctUntilKeysChanged } from './distinct-until-keys-changed';

export function shallowDistinctUntilKeysChanged<T, K extends keyof T>(
  keys: K[],
): MonoTypeOperatorFunction<T> {
  return distinctUntilKeysChanged(keys, (x, y, compareKeys) => {
    for (const key of compareKeys) {
      if (!isShallowEqual(x[key], y[key])) {
        return false;
      }
    }

    return true;
  });
}
