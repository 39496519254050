import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { APP_INSTANCE_ID } from '@gv/ui/core';
import { SnackBarEffects } from '@gv/ui/toaster';
import {
  ReportErrorDialogEffect,
  REPORT_ERROR_META_FACTORY,
} from '@gv/ui/help';
import {
  BillingStateModule,
  CreditStateModule,
  DetailStateModule,
  OrganizationNotificationsEffects,
  CreditNotificationsEffects,
} from '@gv/ui/billing';
import { ErrorsEffects } from '@gv/error';
import { ClientLoggerEffects } from '@gv/client-logger';
import { appVersion } from '@gv/constant';
import { OutageModule } from '@gv/outage';
import { UploadsActions, VideoUploadsStoreModule } from '@gv/upload/state';
import { APP_UPDATE_CHECKER_SERVICE } from '@gv/ui/utils';

import { AngularticsEffects } from '../../store/effect/angulartics.effects';
import { CommonBaseModule } from '../base/common-base.module';
import { ConfigModule } from '../setup/config.module';
import { DebugModeService } from '../../service/helper/debug-mode.service';
import { debugReducer } from '../../store/reducer/debug.reducer';
import { DesktopAppUpdateCheckerService } from '../../service/application/update/desktop-app-update-checker.service';
import { environment } from '../../../environments/environment';
import { getAppReducers } from '../../store/reducer/get-app-reducers';
import { REDUCERS_TOKEN } from '../../entity/token/reducers';
import { RouterEffects } from '../../store/effect/router.effects';
import { UserEffects } from '../../store/effect/user.effects';
import { WebAppUpdateCheckerService } from '../../service/application/update/web-app-update-checker.service';
import { UtilDialogsStoreModule } from '../store/util-dialogs-store-module';
import { APP_CONFIG } from '../../entity/token/app.config';
import type { AppConfig } from '../../entity/type/config/app.config';
import { UserService } from '../../service/application/user/user.service';
import { UploadDialogEffect } from '../../store/effect/dialog/upload-dialog.effects';

// inspect({
//   iframe: false,
// });

@NgModule({
  imports: [
    CommonBaseModule,
    ConfigModule,
    StoreModule.forRoot(REDUCERS_TOKEN, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
      metaReducers: environment.production ? [] : [debugReducer],
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 500,
      logOnly: environment.production, // Restrict extension to log-only mode
      predicate(_, action): boolean {
        // Due to bug https://github.com/ngrx/platform/issues/643 logOnly does not as expected,
        // so we allow logging only in dev mode or when debug mode is enabled
        if (
          typeof window !== 'undefined' &&
          window[DebugModeService.storeDevToolsEnabledGlobalKey] === true
        ) {
          return action.type !== UploadsActions.setProgress.type;
        }
        return false;
      },
    }),
    // TODO: move to individual feature modules
    EffectsModule.forRoot([
      ...(environment.test
        ? []
        : [
            AngularticsEffects,
            SnackBarEffects,
            ErrorsEffects,
            UserEffects,
            UploadDialogEffect,
          ]),
      ClientLoggerEffects,
      RouterEffects,
      ...(environment.test
        ? []
        : [
            OrganizationNotificationsEffects,
            CreditNotificationsEffects,
            ReportErrorDialogEffect,
          ]),
    ]),
    DetailStateModule,
    CreditStateModule,
    BillingStateModule,
    VideoUploadsStoreModule,
    UtilDialogsStoreModule,
    OutageModule,
  ],
  providers: [
    {
      provide: REDUCERS_TOKEN,
      useFactory: getAppReducers,
    },
    {
      provide: REPORT_ERROR_META_FACTORY,
      deps: [APP_CONFIG, APP_INSTANCE_ID, UserService],
      useFactory: (
        appConfig: AppConfig,
        instanceId: string,
        userService: UserService,
      ) => {
        return () => ({
          version: appVersion,
          instanceId,
          userUuid: userService.user?.uuid,
          email: userService.user?.email,
        });
      },
    },
    {
      provide: APP_UPDATE_CHECKER_SERVICE,
      useClass: ELECTRON
        ? DesktopAppUpdateCheckerService
        : WebAppUpdateCheckerService,
    },
  ],
})
export class StateModule {}
