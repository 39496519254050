import { Directive, ElementRef, inject, Input, Renderer2 } from '@angular/core';
import type {
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';

import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
} from 'rxjs';
import { untilNgDestroyed } from '@gv/utils';

import { ScrollContext } from '../class/scroll-context';

@Directive({
  selector: '[gvScroll]',
  standalone: true,
})
export class ScrollDirective implements AfterViewInit, OnChanges, OnDestroy {
  private el = inject<ElementRef<HTMLElement>>(ElementRef<HTMLElement>);
  private parent = inject(ScrollDirective, { skipSelf: true, optional: true });
  private renderer = inject(Renderer2);
  private scrollContext = inject(ScrollContext, { optional: true });

  private disableCount = new BehaviorSubject<number>(0);

  private gvScrollEnabledSubject = new BehaviorSubject<boolean>(true);

  private gvScrollEnabled$ = this.gvScrollEnabledSubject.asObservable();

  @Input() set gvScrollEnabled(gvScrollEnabled: boolean) {
    if (this.gvScrollEnabled !== gvScrollEnabled) {
      this.gvScrollEnabledSubject.next(gvScrollEnabled);
    }
  }

  get gvScrollEnabled(): boolean {
    return this.gvScrollEnabledSubject.getValue();
  }

  @Input() gvScrollDisableParents = false;

  private enable$ = combineLatest([
    this.gvScrollEnabled$,
    this.disableCount,
  ]).pipe(
    map(([enabled, disableCount]) => enabled && disableCount === 0),
    distinctUntilChanged(),
    untilNgDestroyed(),
  );

  constructor() {
    if (this.scrollContext) {
      this.scrollContext.elementRef = this.el;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gvScrollDisableParents) {
      if (this.gvScrollDisableParents) {
        this.increaseDisableLock(false);
      } else if (!changes.gvScrollDisableParents.firstChange) {
        this.decreaseDisableLock(false);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.gvScrollDisableParents) {
      this.decreaseDisableLock();
    }
  }

  ngAfterViewInit(): void {
    this.enable$.subscribe((enabled) => this.updateElementClass(enabled));
  }

  private updateElementClass(enabled: boolean): void {
    if (!this.el.nativeElement) {
      return;
    }

    if (enabled) {
      this.renderer.addClass(this.el.nativeElement, '__gv-scrollbar');
    } else {
      this.renderer.removeClass(this.el.nativeElement, '__gv-scrollbar');
    }
  }

  increaseDisableLock(self = true): void {
    this.parent?.increaseDisableLock();
    if (self) {
      this.disableCount.next(this.disableCount.getValue() + 1);
    }
  }

  decreaseDisableLock(self = true): void {
    this.parent?.decreaseDisableLock();
    if (self) {
      this.disableCount.next(Math.max(0, this.disableCount.getValue() - 1));
    }
  }
}
