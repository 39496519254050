import type { OnChanges, SimpleChanges } from '@angular/core';
import { Directive, Input } from '@angular/core';
import type {
  AbstractControl,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { NG_VALIDATORS } from '@angular/forms';

import { isUndefinedOrNull } from '@gv/utils';

@Directive({
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EmptySpaceValidatorDirective,
      multi: true,
    },
  ],
  selector:
    '[required][formControlName],[required][formControl],[required][ngModel]',
  standalone: true,
})
export class EmptySpaceValidatorDirective implements OnChanges, Validator {
  @Input() required: string | boolean = false;

  private validatorChange?: () => void;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.required) {
      this.validatorChange?.();
    }
  }

  registerOnValidatorChange(fn: () => void): void {
    this.validatorChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.isRequiredActive()) {
      return null;
    } else if (
      !isUndefinedOrNull(control.value) &&
      typeof control.value === 'string' &&
      control.value.trim() === ''
    ) {
      return {
        required: true,
      };
    }
    return null;
  }

  private isRequiredActive(): boolean {
    return this.required === '' || !!this.required;
  }
}
