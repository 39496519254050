import type {
  BillingAccountDTO,
  NextPaymentDTO,
  PaymentInfoModel,
  SubscriptionDTO,
  PlatformModulePlansResponseModel,
  OrganizationDTO,
  AppThemeDTO,
  AppThemesDTO,
  CreditHistoryDTO,
  InvoiceHistoryDTO,
  ProFormaHistoryDTO,
  PriceOfferHistoryDTO,
} from '@gv/api';
import type { Store, SubStateObjectModel } from '@gv/state';
import { initialSubState, StoreInjectionToken } from '@gv/state';
import type { ZonedDate, ZonedInterval } from '@gv/utils';

export const billingFeatureKey = 'billing2';

export interface BillingFeatureState {
  [billingFeatureKey]: BillingState;
}

export enum BillingFilterType {
  Invoices = 'INVOICES',
  ProFormaInvoices = 'PRO_FORMA_INVOICES',
  PriceOffers = 'PRICE_OFFERS',
  CreditTransactions = 'CREDITS',
}

export interface PaginationInfo {
  readonly range?: ZonedInterval;
  readonly noFilterEnd?: ZonedDate;
}

export const BILLING_FEATURE_STATE = new StoreInjectionToken<
  Store<BillingFeatureState>
>('billingFeatureState');

export interface BillingState {
  readonly paymentInfo: SubStateObjectModel<
    PaymentInfoModel & { dtSent: Date }
  >;

  readonly platformPlansInfo: SubStateObjectModel<
    PlatformModulePlansResponseModel & { dtSent: Date }
  >;
  readonly subscription: SubStateObjectModel<
    SubscriptionDTO & { dtSent: Date }
  >;

  readonly nextPayment: SubStateObjectModel<NextPaymentDTO & { dtSent: Date }>;
  readonly billingInfo: SubStateObjectModel<
    BillingAccountDTO & { dtSent: Date }
  >;

  readonly invoicesHistory: SubStateObjectModel<
    InvoiceHistoryDTO & {
      dtSent: Date;
    }
  > &
    PaginationInfo;
  readonly proFormaInvoicesHistory: SubStateObjectModel<
    ProFormaHistoryDTO & {
      dtSent: Date;
    }
  > &
    PaginationInfo;
  readonly priceOffersHistory: SubStateObjectModel<
    PriceOfferHistoryDTO & {
      dtSent: Date;
    }
  > &
    PaginationInfo;

  readonly creditHistory: SubStateObjectModel<
    CreditHistoryDTO & { dtSent: Date }
  > &
    PaginationInfo;

  readonly organization: SubStateObjectModel<
    OrganizationDTO & { dtSent: Date }
  >;

  readonly appThemes: SubStateObjectModel<AppThemesDTO>;
  readonly temporaryTheme: AppThemeDTO;

  readonly applyingPromoCode: boolean;
  readonly promoCodeError: boolean;
}
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const initialPaymentInfo: SubStateObjectModel<
  PaymentInfoModel & { dtSent: Date }
> = initialSubState;
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const initialPlatformPlansInfoSubState: SubStateObjectModel<
  PlatformModulePlansResponseModel & { dtSent: Date }
> = initialSubState;
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const initialSubscriptionSubState: SubStateObjectModel<
  SubscriptionDTO & { dtSent: Date }
> = initialSubState;
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const initialNextPaymentSubState: SubStateObjectModel<
  NextPaymentDTO & { dtSent: Date }
> = initialSubState;
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const initialBillingInfoSubState: SubStateObjectModel<
  BillingAccountDTO & { dtSent: Date }
> = initialSubState;
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const initialInvoicesHistory: BillingState['invoicesHistory'] =
  initialSubState;

export const initialProFormaInvoicesHistory: BillingState['proFormaInvoicesHistory'] =
  initialSubState;

export const initialPriceOffersHistory: BillingState['priceOffersHistory'] =
  initialSubState;

export const initialCreditHistory: BillingState['creditHistory'] =
  initialSubState;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const initialOrganizationSubState: SubStateObjectModel<
  OrganizationDTO & { dtSent: Date }
> = initialSubState;
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const initialAppThemesSubState: SubStateObjectModel<AppThemesDTO> =
  initialSubState;

export const initialBillingState: BillingState = {
  paymentInfo: initialPaymentInfo,
  platformPlansInfo: initialPlatformPlansInfoSubState,
  subscription: initialSubscriptionSubState,
  nextPayment: initialNextPaymentSubState,
  billingInfo: initialBillingInfoSubState,
  organization: initialOrganizationSubState,
  appThemes: initialAppThemesSubState,
  invoicesHistory: initialInvoicesHistory,
  proFormaInvoicesHistory: initialProFormaInvoicesHistory,
  priceOffersHistory: initialPriceOffersHistory,
  creditHistory: initialCreditHistory,
  temporaryTheme: undefined,
  applyingPromoCode: false,
  promoCodeError: false,
};
