import type { ComponentType } from '@angular/cdk/portal';
import type { NgZone, TemplateRef } from '@angular/core';
import type {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';

import type { Dialog } from '@gv/utils';

import type { TypedDialogBaseActions } from './create-dialog-base-actions';

export function openDialog<
  T,
  R,
  P,
  ActionsOpenProps,
  O = T extends Dialog<infer OpenData, unknown> ? OpenData : never,
>(
  dialog: MatDialog,
  _actions: TypedDialogBaseActions<ActionsOpenProps, R, P>,
  componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
  config: MatDialogConfig<O>,
  ngZone: NgZone,
): MatDialogRef<T, R> {
  const dialogRef = ngZone.run(() =>
    dialog.open(componentOrTemplateRef, config),
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return dialogRef;
}
