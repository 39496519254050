export function kebabize(_str: string): string {
  let str = _str
    .replace(/DTO/g, 'Dto')
    .replace(
      /[A-Z]([A-Z]*)[A-Z]/g,
      (r) =>
        r.charAt(0) +
        r.slice(1, r.length - 1).toLowerCase() +
        r.charAt(r.length - 1),
    );
  str = str.slice(0, str.length - 1) + str.charAt(str.length - 1).toLowerCase();

  return str
    .split('')
    .map((letter, idx) => {
      return letter.toUpperCase() === letter
        ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
        : letter;
    })
    .join('');
}

export function toLowerCase<T extends string>(text: T): Lowercase<T> {
  return text?.toLowerCase() as Lowercase<T>;
}

export function toUpperCase<T extends string>(text: T): Uppercase<T> {
  return text?.toUpperCase() as Uppercase<T>;
}

export function capitalize<T extends string>(text: T): Capitalize<T> {
  return (text.charAt(0).toUpperCase() +
    text.slice(1, text.length)) as Capitalize<T>;
}
