import type { DestroyRef } from '@angular/core';

import { untilNgDestroyed } from '@gv/utils';
import type { Subscription } from 'rxjs';
import { startWith, scan, shareReplay, Subject } from 'rxjs';

const init = Symbol('init');

export class SimpleState<
  T,
  R extends Record<string, (state: T, data: any) => T>,
  A extends keyof R,
  E extends {
    [Action in A]?: (
      state: T,
      val: Parameters<R[Action]>[1],
      oldState?: T,
    ) => void;
  },
> {
  private state = new Subject<{ type: A; data: unknown }>();

  state$ = this.state.pipe(
    startWith(init),
    scan((state, action) => {
      if (action === init) {
        return state;
      }

      const newState = this.reducers[action.type](state, action.data);
      this.effects?.[action.type]?.(newState, action.data, state);
      return newState;
    }, this.initialState),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  constructor(
    private initialState: T,
    private reducers: R,
    private effects?: E,
  ) {}

  init(destroyRef: DestroyRef): Subscription {
    return this.state$.pipe(untilNgDestroyed(destroyRef)).subscribe();
  }

  dispatch<Action extends A, D extends Parameters<R[Action]>[1]>(action: {
    type: Action;
    data: D;
  }): void {
    this.state.next(action);
  }
}
