import { inject, Injectable } from '@angular/core';
import type { OnDestroy, ElementRef, DestroyRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { generateUuid, BrowserUtils, untilNgDestroyed } from '@gv/utils';
import { WindowRefService } from '@gv/ui/core';
import type { ObservedValueOf } from 'rxjs';
import { filter, firstValueFrom, NEVER, Subject } from 'rxjs';

import type { TruncatedTextOptions } from './utils';
import { getTruncatedText } from './utils';

function mapLetterSpacing(value: string): number {
  const match = value.match(
    /^(0?[-.]?\d+)(r?e[m|x]|v[h|w|min|max]+|p[x|t|c]|[c|m]m|%|s|in|ch)$/,
  );
  const res = match
    ? { value: parseFloat(match[1]), unit: match[2] }
    : { value: 0, unit: undefined };
  switch (res.unit) {
    case 'px':
      return res.value;
    case 'rem':
      return res.value * 14;
    // TODO: do we need to support something else?
  }
  return 0;
}
@Injectable({
  providedIn: 'root',
})
export class TruncateService implements OnDestroy {
  private windowRef = inject(WindowRefService);
  private document = inject(DOCUMENT);

  private _canvas: HTMLCanvasElement | undefined = undefined;

  private get context(): CanvasRenderingContext2D {
    if (!this._canvas) {
      this._canvas = this.document.createElement('canvas');
    }
    return this._canvas.getContext('2d', {
      willReadFrequently: true,
    }) as CanvasRenderingContext2D;
  }

  // options cannot be spread to multiple lines, as it's wrongly compiled to: { , } as `type` is removed during compilation
  // prettier-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  private worker = BrowserUtils.isSafari || 'puppeteerController' in this.windowRef.nativeWindow ? undefined : new Worker(
    new URL('./truncated.worker.ts', import.meta.url), { type: 'module' },
  );

  private onResize = () => {
    if (this.globalNotification) {
      return;
    }

    this.globalNotification = true;
    this.schedule();
  };

  private handleResizeNotification = (entries: ResizeObserverEntry[]) => {
    if (this.globalNotification) {
      return;
    }

    for (const entry of entries) {
      const id = entry.target.getAttribute('truncate-id');
      if (id) {
        this.localNotifications.add(id);
      }
    }
    this.schedule();
  };

  private workerMessages = new Subject<
    {
      uuid: string;
    } & (
      | {
          data: ReturnType<typeof getTruncatedText>;
        }
      | { error: string }
    )
  >();
  private resizeObserver = new ResizeObserver(this.handleResizeNotification);
  private localNotifications = new Set<string>();
  private globalNotification = false;

  private callbacks: Record<string, Parameters<typeof this.register>[0]> = {};

  private scheduleId: ReturnType<typeof requestAnimationFrame> | undefined =
    undefined;

  private handleBatchedNotifications = () => {
    this.scheduleId = undefined;
    if (this.globalNotification) {
      for (const entry of Object.values(this.callbacks)) {
        entry.onResize();
      }
    } else {
      for (const uuid of this.localNotifications.values()) {
        this.callbacks[uuid]?.onResize();
      }
    }
    this.localNotifications.clear();
    this.globalNotification = false;
  };

  constructor() {
    this.windowRef.nativeWindow?.addEventListener('resize', this.onResize);
    if (this.worker) {
      this.worker.onmessage = (event) =>
        this.workerMessages.next(
          event.data as ObservedValueOf<typeof this.workerMessages>,
        );
    }
  }

  register(
    component: OnDestroy & {
      uuid: string;
      onResize: () => void;
      getText: () => string;
      textContainer: ElementRef<HTMLElement> | undefined;
      suffix: ElementRef<HTMLElement> | undefined;
      destroyRef: DestroyRef;
    },
  ): void {
    const uuid = component.uuid;

    let el = component.textContainer?.nativeElement;
    let el2 = component.suffix?.nativeElement;
    NEVER.pipe(untilNgDestroyed(component.destroyRef)).subscribe({
      complete: () => {
        if (el) {
          this.resizeObserver.unobserve(el);
          el = undefined;
        }
        if (el2) {
          this.resizeObserver.unobserve(el2);
          el2 = undefined;
        }
        delete this.callbacks[uuid];
      },
    });

    if (el) {
      this.resizeObserver.observe(el);
    }
    if (el2) {
      this.resizeObserver.observe(el2);
    }

    this.callbacks[uuid] = component;
  }

  ngOnDestroy(): void {
    this.windowRef.nativeWindow?.removeEventListener('resize', this.onResize);
    this.resizeObserver.disconnect();
    if (this.scheduleId !== undefined) {
      cancelAnimationFrame(this.scheduleId);
    }
    this.worker?.terminate();
  }

  async getTruncatedText(
    text: string | undefined,
    options: {
      textContainer: HTMLElement;
      suffixContainer?: HTMLElement;
      preferredEndSize: number;
    },
  ): Promise<readonly [string | undefined, string | undefined]> {
    const { textContainer, suffixContainer, preferredEndSize } = options;
    if (!textContainer || !text || !textContainer.clientWidth) {
      return [undefined, undefined];
    }

    const uuid = generateUuid();

    const style = this.windowRef.nativeWindow.getComputedStyle(
      textContainer,
      null,
    );
    const fontFamily = style.getPropertyValue('font-family');
    const fontSize = style.getPropertyValue('font-size');
    const fontWeight = style.getPropertyValue('font-weight');
    const transform = style.getPropertyValue('text-transform');
    const letterSpacing = mapLetterSpacing(
      style.getPropertyValue('letter-spacing'),
    );
    if (transform === 'uppercase') {
      text = text?.toUpperCase();
    } else if (transform === 'lowercase') {
      text = text?.toLowerCase();
    }
    const font = `${fontWeight} ${fontSize} ${fontFamily}`;
    let suffixSize = 0;
    if (suffixContainer?.children) {
      suffixSize = suffixContainer.getBoundingClientRect().width;
    }
    const maxWidth = textContainer.clientWidth - suffixSize;
    const message: {
      uuid: string;
      text: string;
      options: Omit<TruncatedTextOptions, 'context'>;
    } = {
      uuid,
      text,
      options: {
        font,
        letterSpacing,
        preferredEndSize,
        maxWidth,
      },
    };

    const resultPromise = this.worker
      ? firstValueFrom(this.workerMessages.pipe(filter((f) => f.uuid === uuid)))
      : Promise.resolve({ data: ['', ''] as readonly [string, string] });
    this.worker?.postMessage(message);
    const res = await resultPromise;

    if ('error' in res || !this.worker) {
      const fallbackRes = getTruncatedText(text, {
        context: this.context,
        font: font,
        maxWidth: maxWidth,
        preferredEndSize: preferredEndSize,
        letterSpacing,
      });
      return fallbackRes;
    }
    return res.data;
  }

  private schedule(): void {
    if (this.scheduleId !== undefined) {
      return;
    }

    this.scheduleId = requestAnimationFrame(this.handleBatchedNotifications);
  }
}
