import { Injectable, inject } from '@angular/core';

import { appVersion } from '@gv/constant';

import { HotjarService } from '../../application/tracking/hotjar.service';
import { UserService } from '../../application/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class HotjarTagService {
  private userService = inject(UserService);
  private hotjar = inject(HotjarService);

  tagAppVersion(): void {
    this.hotjar.hotjarCommand('tagRecording', [appVersion]);
  }

  tagUser(): void {
    const userUuid: string = this.userService.user?.uuid;

    if (userUuid) {
      this.hotjar.hotjarCommand('tagRecording', [userUuid]);
    }
  }
}
