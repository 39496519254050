import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Directive, HostListener, ElementRef, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { trackEvent } from '@gv/analytics';

function getImportance(el: Element): number {
  if (
    el.classList.contains('btn-green') ||
    el.classList.contains('green') ||
    el.classList.contains('submit')
  ) {
    return 0;
  }
  return 1;
}

@Directive({
  standalone: true,
  selector: '[submitOnEnter]',
})
export class SubmitOnEnterDirective {
  private dialog = inject(MatDialog);
  private overlay = inject(OverlayContainer);
  private elementRef = inject<ElementRef<HTMLElement>>(ElementRef<HTMLElement>);

  @HostListener('window:keydown.enter', ['$event'])
  onKeydownHandler(): void {
    if (!this.elementRef?.nativeElement) {
      return;
    }

    const openedDialogs = this.dialog?.openDialogs;
    const openedCdkOverlays = Array.from(
      this.overlay?.getContainerElement()?.children,
    ).filter(
      (child) =>
        child.clientWidth > 0 &&
        child.clientHeight > 0 &&
        !child.hasChildNodes(),
    );
    const openedForms = document.getElementsByTagName('FORM');

    const buttons: HTMLElement[] = Array.from(
      this.elementRef.nativeElement?.querySelectorAll(
        'button,[type=submit],app-button',
      ) || [],
    );

    if (buttons.reduce((acc, r) => acc + (1 - getImportance(r)), 0) > 1) {
      return;
    }

    const button = buttons.sort((a, b) => {
      return getImportance(a) - getImportance(b);
    })[0];

    if (
      (button && getImportance(button) > 0) ||
      !button ||
      coerceBooleanProperty(button.getAttribute('disabled'))
    ) {
      return;
    }

    if (
      openedDialogs?.length > 0 ||
      openedCdkOverlays?.length > 0 ||
      openedForms?.length > 1
    ) {
      if (openedForms?.length > 1) {
        let activeElParent = document.activeElement?.parentElement;
        while (
          !!activeElParent &&
          activeElParent !== this.elementRef.nativeElement
        ) {
          activeElParent = activeElParent.parentElement;
        }

        if (activeElParent === this.elementRef.nativeElement) {
          trackEvent('keyboardUsed', {
            key: 'enter',
            location: 'form',
          });
          button.click();
        }
      }

      return;
    }

    trackEvent('keyboardUsed', {
      key: 'enter',
      location: 'form',
    });
    button.click();
  }
}
