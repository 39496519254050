import { createAction, props } from '@ngrx/store';
import type {
  FiltersModel,
  ShareExportModel,
  SortBy,
  SortDirection,
} from '@gv/api';
import { createFlowBaseActions } from '@gv/state';
import type { DataSourceApi } from '@gv/context';

import type { ExportsModel } from '../../entity/model/export/exports-model';

const basename = '[Exports]';
const fetchBasename = `${basename} fetch`;
const filtersBasename = `${basename} multi`;

export const ExportActions = {
  init: createAction(
    `${basename} init`,
    props<{ uuid: string; api: () => DataSourceApi }>(),
  ),

  fetch: {
    init: createAction(
      `${fetchBasename} init`,
      props<{
        readonly skipWhenLoaded?: boolean;
        readonly loadMore?: boolean;
      }>(),
    ),
    started: createAction(`${fetchBasename} started`),
    cancelled: createAction(`${fetchBasename} cancelled`),
    succeeded: createAction(
      `${fetchBasename} succeeded`,
      props<{
        readonly data: ExportsModel;
        readonly sortBy: SortBy;
        readonly sortDirection: SortDirection;
      }>(),
    ),
    failed: createAction(`${fetchBasename} failed`),
  },

  multiExport: {
    addDataSources: createAction(
      `${filtersBasename} add`,
      props<{
        readonly uuids: string[];
      }>(),
    ),
    fetchFilters: createAction(
      `${filtersBasename} fetch`,
      props<{
        readonly skipWhenLoaded?: boolean;
        readonly dataSource?: string;
        readonly dataSources?: string[];
      }>(),
    ),
    fetchFailed: createAction(`${filtersBasename} fetch failed`),
    pushFilters: createAction(
      `${filtersBasename} push`,
      props<{
        readonly uuid: string;
        readonly filters: FiltersModel;
      }>(),
    ),
  },

  getDataSourceFiltersFlow: createFlowBaseActions(
    basename,
    'get data source filters',
    {
      initProps: props<{
        data: {
          uuid: string;
        };
      }>(),
    },
  ),

  shareFlow: createFlowBaseActions(basename, 'share export', {
    initProps: props<{
      data: {
        dataSourceUuid: string;
        exportUuid: string;
        data: ShareExportModel;
      };
    }>(),
  }),

  downloadFileFlow: createFlowBaseActions(basename, 'download file', {
    initProps: props<{
      data: {
        fileUrl: string;
      };
    }>(),
  }),

  deleteFlow: createFlowBaseActions(basename, 'delete export', {
    initProps: props<{
      data: {
        dataSourceUuid: string;
        exportUuid: string;
      };
    }>(),
  }),

  delete: createAction(
    `${basename} delete`,
    props<{
      uuid: string;
    }>(),
  ),

  markAsDirty: createAction(`${basename} mark dirty`),

  refresh: createAction(`${basename} refresh`),

  reset: createAction(`${basename} reset`),

  highlight: createAction(
    `${basename} highlight`,
    props<{ highlight: string; dtSent: Date }>(),
  ),

  updateSort: {
    init: createAction(
      `${basename} update sort init`,
      props<{ sortBy: SortBy; sortDirection: SortDirection }>(),
    ),
    completed: createAction(
      `${basename} update sort completed`,
      props<{ sortBy: SortBy; sortDirection: SortDirection }>(),
    ),
  },
};
