import { Injectable, NgZone, inject } from '@angular/core';
import { Router } from '@angular/router';

import type { ApiResponse, Retryable } from '@gv/api';
import { CHECK_OUTAGE } from '@gv/api';
import {
  ObjectUtils,
  RequestRetryManagerService,
  toSentryError,
} from '@gv/utils';
import { captureException, withScope } from '@sentry/angular';
import type { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { APP_CONFIG } from '../../../../entity/token/app.config';
import { ApiService } from '../../../api/api.service';
import { MaintenanceDetectorService } from '../../../application/state/maintenance-detector.service';
import type { RequestConfig } from '../http';
import type { RequestHandler, RequestInterceptor } from '../interceptor';

@Injectable({
  providedIn: 'root',
})
export class ServiceUnavailableInterceptor implements RequestInterceptor {
  private appConfig = inject(APP_CONFIG);
  private apiService = inject(ApiService);
  private maintenanceDetectorService = inject(MaintenanceDetectorService);
  private checkOutagePage$ = inject(CHECK_OUTAGE);
  private requestsRetryManager = inject(RequestRetryManagerService);
  private router = inject(Router);
  private ngZone = inject(NgZone);

  intercept(
    request: RequestConfig<any>,
    next: RequestHandler,
  ): Observable<ApiResponse<any>> {
    if (!this.apiService.isApiRequest(request)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (this.maintenanceDetectorService.isMaintenanceError(error)) {
          this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
              const showMaintenanceTime =
                new Date().getTime() -
                this.appConfig.requestsRetry.timeToShowMaintenancePage;

              if (
                !ObjectUtils.isTypeBasedOnProperty<Retryable, 'rid'>(
                  error,
                  'rid',
                ) ||
                !this.requestsRetryManager.isRetrying(error.rid) ||
                error.createdAt.getTime() <= showMaintenanceTime
              ) {
                withScope((scope) => {
                  scope.setLevel('info');
                  scope.setTag('subMessage', 'Redirect To Maintenance Page');
                  scope.setFingerprint([
                    'MaintenancePage',
                    String(this.apiService.isApiRequest(request)),
                  ]);
                  captureException(toSentryError('MaintenancePage', error));
                });
                this.checkOutagePage$.next();
                this.redirectToMaintenancePage();
              }
            });
          });
        }

        return throwError(() => error);
      }),
    );
  }

  private redirectToMaintenancePage(): Promise<boolean> {
    return this.ngZone.run(() => {
      return this.router.navigate(['/maintenance-page'], {
        skipLocationChange: true,
      });
    });
  }
}
