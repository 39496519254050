import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgStore } from '@gv/state';
import { DetailStateModule } from '@gv/ui/billing';

import { reducer, uploadsFeatureKey } from './reducers/uploads.reducer';
import { VaultUploadsEffects } from './effects/vault-uploads.effects';
import { SharedUploadsEffects } from './effects/shared-uploads.effects';
import { UPLOADS_FEATURE_STATE } from './uploads-feature.state';

@NgModule({
  imports: [
    MatDialogModule,
    MatIconModule,
    DetailStateModule,
    StoreModule.forFeature(uploadsFeatureKey, reducer),
    EffectsModule.forFeature([VaultUploadsEffects, SharedUploadsEffects]),
    HttpClientJsonpModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: UPLOADS_FEATURE_STATE,
      useExisting: NgStore,
    },
  ],
})
export class VideoUploadsStoreModule {}
