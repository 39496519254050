import { inject, Injectable } from '@angular/core';

import { NotificationsService } from '@gv/notification';
import { USER_CONTEXT } from '@gv/user';

import {
  creditHistory,
  invoicesHistory,
  priceOffersHistory,
  proFormaInvoicesHistory,
} from '../actions';
import { updateBalance } from './actions';

@Injectable()
export class CreditNotificationsEffects {
  private notificationsService = inject(NotificationsService);
  private userContext = inject(USER_CONTEXT);
  balanceChanged$ = this.notificationsService.createEffect({
    obs: this.userContext.userUuid$,
    data: (uuid) => [{ userUuid: uuid }],
    type: 'CREDIT_CHANGE',
    action: (n) => [
      updateBalance({ data: n.data.credits }),
      invoicesHistory.refresh({}),
      proFormaInvoicesHistory.refresh({}),
      priceOffersHistory.refresh({}),
      creditHistory.refresh({}),
    ],
  });
}
