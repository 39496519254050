import type { OnDestroy } from '@angular/core';
import { Component, signal, DestroyRef, inject } from '@angular/core';

import type { SubmittableDialog } from '@gv/utils';
import { untilNgDestroyed } from '@gv/utils';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';

import { DialogComponent } from './dialog.component';

@Component({
  template: ``,
  standalone: true,
  selector: 'gv-submittable-dialog',
})
export class SubmittableDialogComponent<OpenData, SubmitData, CloseData>
  extends DialogComponent<OpenData, CloseData>
  implements SubmittableDialog<OpenData, SubmitData, CloseData>, OnDestroy
{
  protected destroyRef = inject(DestroyRef);
  protected submitSubject = new Subject<SubmitData>();

  readonly onSubmit$: Observable<SubmitData> = this.submitSubject
    .asObservable()
    .pipe(untilNgDestroyed());

  protected readonly submitInProgressS = signal(false);

  /**
   * @deprecated use signal submitInProgressS() instead
   */
  protected get submitInProgress(): boolean {
    return this.submitInProgressS();
  }

  setUpdateInProgress(inProgress: boolean): void {
    this.submitInProgressS.set(inProgress);
  }

  ngOnDestroy(): void {
    //
  }
}
