/**
 * This is a partially generated file. Some edits may be overwritten!
 * @apiRef {@model DeviceStreamStatus}
 */
export enum DeviceStreamStatus {
  Initializing = 'INITIALIZING',
  Online = 'ONLINE',
  Offline = 'OFFLINE',
  NotSuitable = 'NOT_SUITABLE',
  Running = 'RUNNING',
  Stopped = 'STOPPED',
  Provisioning = 'PROVISIONING',
  ValidationFailed = 'VALIDATION_FAILED',
  WaitingForValidation = 'WAITING_FOR_VALIDATION',
  WaitingForDiscovery = 'WAITING_FOR_DISCOVERY',
  DiscoveryFailed = 'DISCOVERY_FAILED',
  WaitingForConfiguration = 'WAITING_FOR_CONFIGURATION',
  PtzAway = 'PTZ_AWAY',
}
