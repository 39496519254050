import type {
  ProjectFilter,
  ProjectMapDTO,
  MapSettings,
  LayoutView,
} from '@gv/api';
import { ProjectPageType, SortBy, SortDirection, MapStyle } from '@gv/api';
import type { SubStateArrayModel } from '@gv/state';
import { initialArraySubState } from '@gv/state';

import type { ProjectStateModel } from '../../../../entity/model/projects/project-state-model';

export enum InternalProjectPageType {
  All = 'ALL',
  My = 'MY',
  Archived = 'ARCHIVED',
}

export const pageTypeMapping: Record<InternalProjectPageType, ProjectPageType> =
  {
    [InternalProjectPageType.All]: ProjectPageType.All,
    [InternalProjectPageType.My]: ProjectPageType.My,
    [InternalProjectPageType.Archived]: ProjectPageType.All,
  };

export type ProjectFilterStateModel = Omit<ProjectFilter, 'page'> & {
  page: InternalProjectPageType;
  entitiesNames: string[];
};

export type ProjectsState = Omit<
  SubStateArrayModel<ProjectStateModel>,
  'dirty'
> & {
  readonly neededItems: number;
  readonly updatingLayout: boolean;

  readonly demo: {
    state: 'loaded' | 'loading' | 'importing' | 'none';
    imported: boolean;
  };
  pageView: LayoutView | undefined;

  context:
    | {
        limit: number | undefined;
        offset: number | undefined;
        count: number | undefined;
        countDirty: boolean;
      }
    | undefined;

  filter: ProjectFilterStateModel;
  countsLoadedAtLeastOnce: boolean;
  loadingCounts: boolean;
  dirty: 'filthy' | 'smudged' | false;

  readonly detailData: Record<
    string,
    { loaded: boolean; loading: boolean; data: ProjectStateModel }
  >;

  readonly newProjects: readonly ProjectStateModel[];
  readonly allowedTypes: ProjectFilter['types'];
  readonly allowedPageTypes: InternalProjectPageType[];

  readonly mapData: readonly ProjectMapDTO[];
  readonly apiMapSettings: MapSettings & { preferredZoom: number };
  readonly localMapSettings: MapSettings & { preferredZoom: number };

  freezed: (Pick<
    ProjectsState,
    | 'ids'
    | 'data'
    | 'filter'
    | 'loaded'
    | 'context'
    | 'dirty'
    | 'mapData'
    | 'newProjects'
  > & { uuid: string })[];
};

export const initialProjectsState: ProjectsState = {
  ...initialArraySubState,
  dirty: false,
  neededItems: 0,
  updatingLayout: false,
  demo: {
    state: 'none',
    imported: false,
  },
  pageView: undefined,

  context: undefined,

  countsLoadedAtLeastOnce: false,
  loadingCounts: false,
  detailData: {},
  newProjects: [],

  filter: {
    page: InternalProjectPageType.My,
    sortBy: SortBy.DtModified,
    sortDirection: SortDirection.Desc,
    assignees: [],
    types: [],
    dtModifiedEnd: undefined,
    dtModifiedStart: undefined,
    nameSearch: undefined,
    statuses: [],
    uuids: [],
    entitiesNames: [],
  },

  mapData: [],

  apiMapSettings: {
    mapStyle: MapStyle.GVMode,
    mapZoom: 14,
    preferredZoom: 14,
  },
  localMapSettings: {
    mapStyle: MapStyle.GVMode,
    mapZoom: 14,
    preferredZoom: 14,
  },

  freezed: [],

  allowedTypes: [],
  allowedPageTypes: [],
};
