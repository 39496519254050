import type { ZodErrorMap } from 'zod';
import { ZodIssueCode, ZodParsedType } from 'zod';

export const typesMapping = (type: ZodParsedType): string | undefined => {
  switch (type) {
    case 'string':
      return $localize`:@@zod-errors.string:string`;
    case 'number':
    case 'integer':
    case 'float':
      return $localize`:@@zod-errors.number:number`;
  }
  return undefined;
};

export const nullTypes = [
  ZodParsedType.undefined,
  ZodParsedType.null,
  ZodParsedType.nan,
] as string[];

const errorMap: ZodErrorMap = (issue, _ctx) => {
  let message: string;
  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (nullTypes.includes(issue.received)) {
        message = $localize`:@@zod-errors.required:Input required`;
      } else {
        const type = typesMapping(issue.expected);
        message = type
          ? $localize`:@@zod-errors.invalid-type-precise:Value is not ${type}`
          : $localize`:@@zod-errors.invalid-input:Invalid input`;
      }
      break;
    case ZodIssueCode.invalid_literal:
    case ZodIssueCode.unrecognized_keys:
    case ZodIssueCode.invalid_union:
    case ZodIssueCode.invalid_union_discriminator:
    case ZodIssueCode.invalid_enum_value:
    case ZodIssueCode.invalid_arguments:
    case ZodIssueCode.invalid_return_type:
    case ZodIssueCode.custom:
    case ZodIssueCode.invalid_intersection_types:
      message = $localize`:@@zod-errors.invalid-input:Invalid input`;
      break;
    case ZodIssueCode.invalid_date:
      message = $localize`:@@zod-errors.invalid-date:Invalid date`;
      break;
    case ZodIssueCode.invalid_string:
      if (typeof issue.validation === 'object') {
        if ('includes' in issue.validation) {
          message = $localize`:@@zod-errors.must-contain:This field must contain ${issue.validation.includes}`;
        } else if ('startsWith' in issue.validation) {
          message = $localize`:@@zod-errors.must-start:This field must start with ${issue.validation.startsWith}`;
        } else if ('endsWith' in issue.validation) {
          message = $localize`:@@zod-errors.must-end:This field must end with ${issue.validation.endsWith}`;
        } else {
          // should not happen
        }
      } else if (issue.validation !== 'regex') {
        message =
          issue.message || $localize`:@@zod-errors.invalid-input:Invalid input`;
      } else {
        message = $localize`:@@zod-errors.invalid-input:Invalid input`;
      }
      break;
    case ZodIssueCode.too_small:
      if (issue.type === 'array') {
        message = issue.exact
          ? $localize`:@@zod-errors.array-exact-small:Exactly ${issue.minimum} item(s) must be selected`
          : issue.inclusive
            ? $localize`:@@zod-errors.array-atleast-small:At least ${issue.minimum} item(s) must be selected`
            : $localize`:@@zod-errors.array-more-small:More than ${issue.minimum} item(s) must be selected`;
      } else if (issue.type === 'string') {
        message = issue.exact
          ? $localize`:@@zod-errors.string-exact-small:This field must be exactly ${issue.minimum} character(s) long`
          : issue.inclusive
            ? $localize`:@@zod-errors.string-atleast-small:This field must be at least ${issue.minimum} character(s) long`
            : $localize`:@@zod-errors.string-more-small:This field must be more than ${issue.minimum} characters(s) long`;
      } else if (issue.type === 'number') {
        message = issue.exact
          ? $localize`:@@zod-errors.number-exact-small:Value must be exactly equal to ${issue.minimum}`
          : issue.inclusive
            ? $localize`:@@zod-errors.number-atleast-small:Value must be greater than or equal to ${issue.minimum}`
            : $localize`:@@zod-errors.number-more-small:Value must be greater than ${issue.minimum}`;
      } else if (issue.type === 'date') {
        message = issue.exact
          ? $localize`:@@zod-errors.date-exact-small:Date must be exactly equal to ${new Date(
              Number(issue.minimum),
            )}`
          : issue.inclusive
            ? $localize`:@@zod-errors.date-atleast-small:Date must be greater than or equal to ${new Date(
                Number(issue.minimum),
              )}`
            : $localize`:@@zod-errors.date-more-small:Date must be greater than ${new Date(
                Number(issue.minimum),
              )}`;
      } else {
        message = $localize`:@@zod-errors.invalid-input:Invalid input`;
      }
      break;
    case ZodIssueCode.too_big:
      if (issue.type === 'array') {
        message = issue.exact
          ? $localize`:@@zod-errors.array-exact-big:Exactly ${issue.maximum} item(s) must be selected`
          : issue.inclusive
            ? $localize`:@@zod-errors.array-atmost-big:At most ${issue.maximum} item(s) must be selected`
            : $localize`:@@zod-errors.array-more-big:Less than ${issue.maximum} item(s) must be selected`;
      } else if (issue.type === 'string') {
        message = issue.exact
          ? $localize`:@@zod-errors.string-exact-big:This field must be exactly ${issue.maximum} character(s) long`
          : issue.inclusive
            ? $localize`:@@zod-errors.string-atmost-big:This field must contain at most ${issue.maximum} character(s)`
            : $localize`:@@zod-errors.string-more-big:This field must contain under ${issue.maximum} characters(s)`;
      } else if (issue.type === 'number') {
        message = issue.exact
          ? $localize`:@@zod-errors.number-exact-big:Value must be exactly equal to ${issue.maximum}`
          : issue.inclusive
            ? $localize`:@@zod-errors.number-atmost-big:Value must be less than or equal to ${issue.maximum}`
            : $localize`:@@zod-errors.number-more-big:Value must be less than ${issue.maximum}`;
      } else if (issue.type === 'date') {
        message = issue.exact
          ? $localize`:@@zod-errors.date-exact-big:Date must be exactly equal to ${new Date(
              Number(issue.maximum),
            )}`
          : issue.inclusive
            ? $localize`:@@zod-errors.date-atleast-big:Date must be smaller than or equal to ${new Date(
                Number(issue.maximum),
              )}`
            : $localize`:@@zod-errors.date-more-big:Date must be smaller than ${new Date(
                Number(issue.maximum),
              )}`;
      } else {
        message = $localize`:@@zod-errors.invalid-input:Invalid input`;
      }
      break;
    case ZodIssueCode.not_multiple_of:
      message = $localize`:@@zod-errors.multiple-of:Number must be a multiple of ${issue.multipleOf}`;
      break;
    case ZodIssueCode.not_finite:
      message = $localize`:@@zod-errors.finite:Number must be finite`;
      break;
    default:
      message = _ctx.defaultError;
  }
  return { message };
};

export default errorMap;
