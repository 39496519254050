import { inject, Injectable } from '@angular/core';

import { API } from '@gv/api';
import { createSubStateRefreshEffect, StoreInject } from '@gv/state';
import { USER_CONTEXT } from '@gv/user';
import { delayedConcatMap } from '@gv/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concat, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

import * as DetailActions from './actions';
import { fromDetail } from './selectors';
import { DETAIL_FEATURE_STATE } from './state';

@Injectable()
export class DetailEffects {
  private actions$ = inject<Actions>(Actions);
  private api = inject(API);
  private store = inject(StoreInject(DETAIL_FEATURE_STATE));
  private userContext = inject(USER_CONTEXT);
  fetchDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailActions.detail.fetch.init),
      debounceTime(0),
      delayedConcatMap(() => [
        this.userContext.user$,
        this.store.select(fromDetail.isDetailLoaded),
        this.store.select(fromDetail.isDetailDirty),
        this.store.select(fromDetail.isDetailLoading),
      ]),
      filter(
        ([action, , loaded, dirty, loading]) =>
          !action.skipWhenLoaded || ((!loaded || dirty) && !loading),
      ),
      switchMap(([, user]) => {
        return concat(
          of(DetailActions.detail.fetch.started()),
          this.api.getUserDetailCard(user?.uuid).pipe(
            mergeMap((response) => {
              return of(
                DetailActions.detail.fetch.completed({
                  dtSent: response.dtSent,
                  data: response.data!,
                  sortable: undefined,
                }),
              );
            }),
            catchError((error: unknown) => {
              return of(
                DetailActions.detail.fetch.error({
                  error,
                }),
              );
            }),
            takeUntil(
              this.actions$.pipe(ofType(DetailActions.detail.fetch.cancel)),
            ),
          ),
        );
      }),
    ),
  );

  refreshUserDetail$ = createSubStateRefreshEffect(
    DetailActions.detail,
    fromDetail,
    {
      actions$: this.actions$,
      store: this.store,
    },
    'detail',
  );
}
