import { DateTime as ApiDateTime } from '@gv/api';
import { DateTime } from 'luxon';
import { mapValues, pipe, values } from 'remeda';

export const dateTimeEnumMapping: Record<
  ApiDateTime,
  {
    date: string;
    dateTime: string;
    dateTimeShort: string;
    dateTimeMultiline: string;
    time: string;
    daily: string;
    weekly: string;
    monthly: string;
  }
> = {
  [ApiDateTime.Standard]: {
    date: 'MM/dd/yyyy',
    dateTime: 'MM/dd/yyyy - HH:mm:ss',
    dateTimeShort: 'MM/dd/yyyy - HH:mm',
    dateTimeMultiline: `MM/dd/yyyy'\n'HH:mm:ss`,
    time: 'HH:mm:ss',
    daily: 'T',
    weekly: 'EEEE T',
    monthly: "'each month' T",
  },
  [ApiDateTime.Dot]: {
    date: 'dd. MM. yyyy',
    dateTime: 'dd. MM. yyyy HH:mm:ss',
    dateTimeShort: 'dd. MM. yyyy HH:mm',
    dateTimeMultiline: `dd. MM. yyyy'\n'HH:mm:ss`,
    time: 'HH:mm:ss',
    daily: 'T',
    weekly: 'EEEE T',
    monthly: "'each month' T",
  },
  [ApiDateTime.Dash]: {
    date: 'yyyy-MM-dd',
    dateTime: 'yyyy-MM-dd HH:mm:ss',
    dateTimeShort: 'yyyy-MM-dd HH:mm',
    dateTimeMultiline: `yyyy-MM-dd'\n'HH:mm:ss`,
    time: 'HH:mm:ss',
    daily: 'T',
    weekly: 'EEEE T',
    monthly: "'each month' T",
  },
};

export const createDemoTime = () =>
  DateTime.fromObject({
    hour: 14,
    minute: 20,
    second: 48,
    day: 24,
    month: 12,
  }).toJSDate();

export const dateTimeSelectorEntries = pipe(
  dateTimeEnumMapping,
  mapValues((v, key) => ({ ...v, key })),
  values(),
);

export type FormatType =
  keyof (typeof dateTimeEnumMapping)[keyof typeof dateTimeEnumMapping];
