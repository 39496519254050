import { Injectable, signal } from '@angular/core';

import { generateUuid } from '@gv/utils';
import { asImmutable, type Mutable } from '@gv/state';

import type { ToastModel } from '../model/toast-model';
import { ToastType } from '../model/toast-model';

export type Without<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

@Injectable({ providedIn: 'root' })
export class ToasterService {
  toastS = signal<ToastModel | undefined>(undefined);
  toastsS = signal<ToastModel[]>([]);

  action = this.toastS()?.action;

  show(toast: Without<ToastModel, 'uuid'>): string | undefined {
    if (!toast) {
      return undefined;
    }

    const uuid = generateUuid();
    const toasts = this.toastsS();
    const newToast = {
      ...toast,
      uuid,
      closable: toast.delay ? toast.closable : true,
    };

    this.toastS.set(newToast);

    if (newToast && !toasts.map((t) => t.uuid)?.includes(uuid)) {
      this.toastsS.set([{ ...newToast, uuid }, ...toasts]);
      if (newToast.delay) {
        setTimeout(() => {
          this.close(uuid);
        }, newToast.delay);
      }
    }

    return uuid;
  }

  close(uuid: string): void {
    if (this.toastS()?.uuid === uuid) {
      this.toastS.set(undefined);
    }
    this.toastsS.set(this.toastsS().filter((toast) => toast.uuid !== uuid));
  }

  closeAll(): void {
    this.toastsS.set([]);
  }
}

export class ToastBuilder {
  private constructor(private model: Mutable<Without<ToastModel, 'uuid'>>) {
    //
  }

  static error(content: string): ToastBuilder {
    return new ToastBuilder({
      type: ToastType.error,
      icon: 'error',
      closable: true,
      delay: 5000,
      content,
    });
  }

  static info(content: string): ToastBuilder {
    return new ToastBuilder({
      type: ToastType.info,
      closable: true,
      delay: 5000,
      content,
    });
  }

  icon(icon: string): ToastBuilder {
    this.model.icon = icon;
    return this;
  }

  delay(delay: number): ToastBuilder {
    this.model.delay = delay;
    return this;
  }

  withoutTimeout(): ToastBuilder {
    this.model.delay = undefined;
    return this;
  }

  closesable(closeable: boolean): ToastBuilder {
    this.model.closable = closeable;
    return this;
  }

  title(title: string): ToastBuilder {
    this.model.title = title;
    return this;
  }

  action(action: () => void, button: string): ToastBuilder {
    this.model.action = action;
    this.model.actionButton = button;
    return this;
  }

  build(): Without<ToastModel, 'uuid'> {
    return asImmutable(this.model) as Without<ToastModel, 'uuid'>;
  }
}
