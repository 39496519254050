import type { Store } from '@gv/state';
import { StoreInjectionToken } from '@gv/state';

import type { uploadsFeatureKey } from './reducers/uploads.reducer';
import type { UploadsState } from './uploads.state';

export interface UploadsFeatureState {
  [uploadsFeatureKey]: UploadsState;
}

export const UPLOADS_FEATURE_STATE = new StoreInjectionToken<
  Store<UploadsFeatureState>
>('uploadsFeatureState');
