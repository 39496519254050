import type {
  BillingAccountDTO,
  NextPaymentDTO,
  PaymentInfoModel,
  SubscriptionDTO,
  PlatformModulePlansResponseModel,
  OrganizationDTO,
  CreateOrganization,
  CreateOrganizationUser,
  EditOrganization,
  EditOrganizationUser,
  CreateAppTheme,
  AppThemeDTO,
  OrganizationLogoPresignDTO,
  AppThemesDTO,
  BillingAddress,
  BillingAddressModel,
  ApplyPromocode,
  PaymentMethodModel,
  CreditHistoryDTO,
  InviteUserUsingReferral,
  UserOnlineStatus,
  InvoiceHistoryDTO,
  ProFormaHistoryDTO,
  PriceOfferHistoryDTO,
} from '@gv/api';
import type { Sortable } from '@gv/state';
import {
  createDialogBaseActions,
  createFlowBaseActions,
  createSubStateActions,
} from '@gv/state';
import { createAction, props } from '@ngrx/store';
import type { ZonedDate, ZonedInterval } from '@gv/utils';

import type { PaymentDialogData } from '../component/dialog/payment-dialog/payment-dialog.component';

const basename = '[Billing]';
const fetchAppThemesBasename = `${basename} fetch app themes`;

export const paymentInfo = createSubStateActions<
  PaymentInfoModel,
  PaymentInfoModel & { dtSent: Date }
>(`${basename} payment info`);

export const platformPlansInfo = createSubStateActions<
  PlatformModulePlansResponseModel,
  PlatformModulePlansResponseModel & { dtSent: Date }
>(`${basename} platform plans`);

export const subscription = {
  ...createSubStateActions<SubscriptionDTO, SubscriptionDTO & { dtSent: Date }>(
    `${basename} subscription`,
  ),

  cancelSubscriptionFlow: createFlowBaseActions(
    basename,
    'cancel subscription',
  ),
};

export const billingInfo = {
  ...createSubStateActions<
    BillingAccountDTO,
    BillingAccountDTO & { dtSent: Date }
  >(`${basename} billing info`),

  updateBillingAddress: createFlowBaseActions(
    basename,
    'update billing address',
    {
      initProps: props<{ data: BillingAddress }>(),
      completedProps: props<{ data: { model: BillingAddressModel } }>(),
    },
  ),

  updatePaymentMethod: createAction(
    `${basename} update payment method`,
    props<{ data: PaymentMethodModel }>(),
  ),

  deletePaymentMethodFlow: createFlowBaseActions(
    basename,
    'delete payment method',
  ),
};

export const nextPayment = createSubStateActions<
  NextPaymentDTO,
  NextPaymentDTO & { dtSent: Date }
>(`${basename} next payment`);

const _invoicesHistory = createSubStateActions<
  InvoiceHistoryDTO,
  InvoiceHistoryDTO & {
    dtSent: Date;
  }
>(`${basename} invoices history`);
export const invoicesHistory = {
  _: _invoicesHistory,
  ..._invoicesHistory,
  loadMore: createAction(`${basename} invoices history load more`),
  fetch: {
    ..._invoicesHistory.fetch,
    init: createAction(
      `${basename} invoices history fetch init`,
      props<{
        skipWhenLoaded: boolean;
        debounce?: boolean;
        data?: { offset: number; limit: number };
      }>(),
    ),
    completed: createAction(
      `${basename} invoices history fetch completed`,
      props<{
        context:
          | {
              offset: number;
              limit: number;
            }
          | undefined;
        data: InvoiceHistoryDTO;
        dtSent: Date;
        sortable: Sortable | undefined;
      }>(),
    ),
  },
};

const _proFormaInvoicesHistory = createSubStateActions<
  ProFormaHistoryDTO,
  ProFormaHistoryDTO & {
    dtSent: Date;
  }
>(`${basename} pro forma invoices history`);

export const proFormaInvoicesHistory = {
  _: _proFormaInvoicesHistory,
  ..._proFormaInvoicesHistory,
  loadMore: createAction(`${basename} pro forma invoices history load more`),
  fetch: {
    ..._proFormaInvoicesHistory.fetch,
    init: createAction(
      `${basename} pro forma invoices history fetch init`,
      props<{
        skipWhenLoaded: boolean;
        debounce?: boolean;
        data?: { offset: number; limit: number };
      }>(),
    ),
    completed: createAction(
      `${basename} pro forma invoices history fetch completed`,
      props<{
        context:
          | {
              offset: number;
              limit: number;
            }
          | undefined;
        data: ProFormaHistoryDTO;
        dtSent: Date;
        sortable: Sortable | undefined;
      }>(),
    ),
  },
};
const _priceOffersHistory = createSubStateActions<
  PriceOfferHistoryDTO,
  PriceOfferHistoryDTO & {
    dtSent: Date;
  }
>(`${basename} price offers history`);

export const priceOffersHistory = {
  _: _priceOffersHistory,
  ..._priceOffersHistory,
  loadMore: createAction(`${basename} price offers history load more`),
  fetch: {
    ..._priceOffersHistory.fetch,
    init: createAction(
      `${basename} price offers history fetch init`,
      props<{
        skipWhenLoaded: boolean;
        debounce?: boolean;
        data?: { offset: number; limit: number };
      }>(),
    ),
    completed: createAction(
      `${basename} price offers history fetch completed`,
      props<{
        context:
          | {
              offset: number;
              limit: number;
            }
          | undefined;
        data: PriceOfferHistoryDTO;
        dtSent: Date;
        sortable: Sortable | undefined;
      }>(),
    ),
  },
};

const _creditHistory = createSubStateActions<
  CreditHistoryDTO,
  CreditHistoryDTO & { dtSent: Date }
>(`${basename} credit history`);
export const creditHistory = {
  _: _creditHistory,
  ..._creditHistory,
  loadMore: createAction(`${basename} credit history load more`),
  fetch: {
    ..._creditHistory.fetch,
    init: createAction(
      `${basename} credit history fetch init`,
      props<{
        skipWhenLoaded: boolean;
        debounce?: boolean;
        data?: { offset: number; limit: number };
      }>(),
    ),
    completed: createAction(
      `${basename} credit history fetch completed`,
      props<{
        context:
          | {
              offset: number;
              limit: number;
            }
          | undefined;
        data: CreditHistoryDTO;
        dtSent: Date;
        sortable: Sortable | undefined;
      }>(),
    ),
  },
};

export const organization = {
  ...createSubStateActions<OrganizationDTO, OrganizationDTO & { dtSent: Date }>(
    `${basename} organization`,
  ),

  setMemberStatus: createAction(
    `${basename} set member status`,
    props<{ data: { userUuid: string; status: UserOnlineStatus } }>(),
  ),

  createOrganizationFlow: createFlowBaseActions(
    basename,
    'create organization',
    {
      initProps: props<{
        data: CreateOrganization;
      }>(),
    },
  ),

  editOrganizationFlow: createFlowBaseActions(basename, 'edit organization', {
    initProps: props<{
      data: {
        organization: EditOrganization;
        squareLogo?: File;
        mainLogo?: File;
      };
    }>(),
  }),

  organizationReferralInvitationFlow: createFlowBaseActions(
    basename,
    'create referral invitation',
    {
      initProps: props<{
        data: InviteUserUsingReferral;
      }>(),
    },
  ),

  createOrganizationUserInvitationFlow: createFlowBaseActions(
    basename,
    'create organization user',
    {
      initProps: props<{
        data: CreateOrganizationUser;
      }>(),
    },
  ),

  acceptInvitation: createFlowBaseActions(
    basename,
    'accept organization invitation',
    {
      initProps: props<{
        data: { request: string; accept: boolean };
      }>(),
    },
  ),

  editOrganizationUserFlow: createFlowBaseActions(
    basename,
    'edit organization user',
    {
      initProps: props<{
        data: { uuid: string; data: EditOrganizationUser };
      }>(),
    },
  ),

  deleteOrganizationUser: createFlowBaseActions(
    basename,
    'delete organization user',
    {
      initProps: props<{
        data: { uuid: string };
      }>(),
    },
  ),

  createThemeFlow: createFlowBaseActions(basename, 'create app theme', {
    initProps: props<{
      data: CreateAppTheme & { slugSet: boolean };
    }>(),
    completedProps: props<{ data: AppThemeDTO }>(),
  }),

  createTemporaryTheme: createAction(
    `${basename} create temporary theme`,
    props<{ data: AppThemeDTO }>(),
  ),

  resetTemporaryTheme: createAction(`${basename} reset temporary theme`),

  uploadOrganizationLogoFlow: createFlowBaseActions(
    basename,
    'upload organization logo',
    {
      initProps: props<{
        data: { squareLogo: File; mainLogo: File };
      }>(),
      completedProps: props<{ data: OrganizationLogoPresignDTO }>(),
    },
  ),
};

export const fetchAppThemes = {
  init: createAction(
    `${fetchAppThemesBasename} init`,
    props<{ skipWhenLoaded?: boolean }>(),
  ),
  succeeded: createAction(
    `${fetchAppThemesBasename} succeeded`,
    props<{ appThemes: AppThemesDTO }>(),
  ),
  failed: createAction(`${fetchAppThemesBasename} failed`),
  completed: createAction(`${fetchAppThemesBasename} completed`),
};

export const downloadInvoice = createAction(
  `${basename} download invoice`,
  props<{ invoiceUuid: string }>(),
);

export const paymentDialog = createDialogBaseActions(basename, 'Payment', {
  openProps: props<{
    data: PaymentDialogData;
  }>(),
});
export const paymentDialogLink = createDialogBaseActions(
  basename,
  'PaymentLink',
  {
    allowSerialization: true,
    openProps: props<{
      data: {
        request: string;
      };
    }>(),
  },
);
export const creditDialog = createDialogBaseActions(basename, 'Credit');
export const vaultDialog = createDialogBaseActions(basename, 'Vault', {
  openProps: props<{
    data: { additional: number };
  }>(),
});
export const seatsDialog = createDialogBaseActions(basename, 'Seats', {
  openProps: props<{
    data: { additional: number };
  }>(),
});
export const platformPlanDialog = createDialogBaseActions(
  basename,
  'PlatformPlan',
);
export const cancelPlatformPlanDialog = createDialogBaseActions(
  basename,
  'CancelPlatformPlan',
  {
    openProps: props<{
      data: { nextBillingDate: string; hasOrganization: boolean };
    }>(),
  },
);
export const deletePaymentMethodDialog = createDialogBaseActions(
  basename,
  'DeletePaymentMethod',
);

export const promoCode = {
  apply: createAction(
    `${basename} apply promo code`,
    props<{
      promo: ApplyPromocode;
    }>(),
  ),

  submitting: createAction(
    `${basename} submitting promo code`,
    props<{
      value: boolean;
    }>(),
  ),

  error: createAction(
    `${basename} error when applying promo code`,
    props<{
      value: boolean;
    }>(),
  ),
};

export const setFilterRange = createAction(
  `${basename} set filter range`,
  props<{ range: ZonedInterval | undefined }>(),
);

export const setNoFilterEnd = createAction(
  `${basename} set no filter end`,
  props<{ date: ZonedDate | undefined }>(),
);
