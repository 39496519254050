import {
  inject,
  ElementRef,
  Input,
  Directive,
  InjectionToken,
  effect,
  untracked,
  NgModule,
  signal,
} from '@angular/core';
import type {
  OnChanges,
  OnDestroy,
  Provider,
  Signal,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[gvId]',
  standalone: true,
})
export class TestIdDirective implements OnChanges, OnDestroy {
  private element = inject<ElementRef<HTMLElement>>(ElementRef<HTMLElement>);
  private group = inject(TestGroupDirective, {
    optional: true,
    skipSelf: true,
  });
  private testId = inject(TEST_ID, { optional: true });
  private parent: TestIdDirective | null | undefined = this.group
    ? inject(TestIdDirective, { skipSelf: true, optional: true })
    : undefined;

  private _ = this.testId && effect(() => this.updateValue());

  private dependencies: (() => void)[] = [];

  @Input()
  gvId!: string;

  @Input()
  _gvId: string | undefined;

  get id(): string {
    if (!this.parent) {
      return this.gvId ?? this._gvId ?? (this.testId && untracked(this.testId));
    }
    return `${this.parent.id}-${this.gvId ?? this._gvId ?? (this.testId && untracked(this.testId))}`;
  }

  private updateValue = () => {
    if (this.element.nativeElement.getAttribute('test-id') !== this.id) {
      this.element.nativeElement.setAttribute('test-id', this.id);
      this.runDependencies();
    }
  };

  constructor() {
    this.parent?.registerDependency(this.updateValue);
  }

  ngOnDestroy(): void {
    this.parent?.unregisterDependency(this.updateValue);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gvId || changes._gvId) {
      this.updateValue();
    }
  }

  setId(value: string): void {
    this.gvId = value;
    this.updateValue();
  }

  registerDependency(cb: () => void) {
    this.dependencies.push(cb);
  }

  unregisterDependency(cb: () => void) {
    const index = this.dependencies.indexOf(cb);
    if (index >= 0) {
      this.dependencies.splice(index, 1);
    }
  }

  private runDependencies(): void {
    for (let i = 0; i < this.dependencies.length; i += 1) {
      this.dependencies[i]();
    }
  }
}

@Directive({
  selector: '[gvGroupId]',
  standalone: true,
})
export class TestGroupDirective {}

export function normalizeTestId(value: string): string {
  return value.replace(/-/g, '_').replace('app_', '').replace('gv_', '');
}

export const TEST_ID = new InjectionToken<Signal<string>>('testId');

@Directive({
  standalone: true,
  hostDirectives: [TestIdDirective],
})
export abstract class TestBaseButtonDirective {
  protected element = inject<ElementRef<HTMLElement>>(ElementRef<HTMLElement>);
  protected testId = inject(TestIdDirective);

  constructor() {
    this.updateValue();
  }

  abstract updateValue(): void;
}

@Directive({
  selector: 'button:not([matMenuTriggerFor]):not([gvId])',
  standalone: true,
})
export class TestButtonDirective extends TestBaseButtonDirective {
  updateValue(): void {
    const hasSubmitClass =
      this.element.nativeElement.classList.contains('btn-green') ||
      this.element.nativeElement.classList.contains('green');
    if (hasSubmitClass) {
      this.testId.setId('btn-submit');
      return;
    }

    const hasWarnClass =
      this.element.nativeElement.classList.contains('btn-warn');
    if (hasWarnClass) {
      this.testId.setId('btn-decline');
      return;
    }

    this.testId.setId('btn');
  }
}

@Directive({
  selector: 'button[matMenuTriggerFor]',
  standalone: true,
})
export class TestMenuButtonDirective extends TestBaseButtonDirective {
  updateValue(): void {
    this.testId.setId('btn-menu');
  }
}

export const withTestId = (id: string): Provider => ({
  provide: TEST_ID,
  useValue: signal(id),
});

@NgModule({
  imports: [
    TestIdDirective,
    TestGroupDirective,
    TestMenuButtonDirective,
    TestButtonDirective,
  ],
  exports: [
    TestIdDirective,
    TestGroupDirective,
    TestMenuButtonDirective,
    TestButtonDirective,
  ],
})
export class TestIdModule {}
