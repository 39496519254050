import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { LinkToHelpdeskModule } from '@gv/ui/help';

import { MobileDeviceDetectedComponent } from '../../../component/page/mobile-device-detected/mobile-device-detected.component';
import { UnsupportedBrowserOffsetDirective } from '../../../entity/directive/layout/unsupported-browser-offset.directive';
import { UnsupportedBrowserSnackBarComponent } from '../../../component/dialog/unsupported-browser/unsupported-browser-snack-bar.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    LinkToHelpdeskModule,
    MobileDeviceDetectedComponent,
    UnsupportedBrowserOffsetDirective,
    UnsupportedBrowserSnackBarComponent,
  ],
  exports: [
    MobileDeviceDetectedComponent,
    UnsupportedBrowserOffsetDirective,
    UnsupportedBrowserSnackBarComponent,
  ],
})
export class BrowserUtilsModule {}
