import { NgModule } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CookieService } from 'ngx-cookie-service';
import { ElectronCookieService } from '@gv/desktop/main';
import { COOKIE_SERVICE } from '@gv/ui/utils';

import { WebApplicationCookieService } from '../../../service/application/web-application-cookie-service';

@NgModule({
  providers: [
    ...(ELECTRON ? [] : [CookieService]),
    {
      provide: COOKIE_SERVICE,
      useClass: ELECTRON ? ElectronCookieService : WebApplicationCookieService,
    },
  ],
})
export class CookieModule {}
