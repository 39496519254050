export function hasSomeParentClass(
  element: HTMLElement,
  classname: string,
): boolean {
  if (!element) {
    return false;
  }

  if (element.className?.split?.(' ').indexOf(classname) >= 0) {
    return true;
  }

  return element.parentElement
    ? hasSomeParentClass(element.parentElement, classname)
    : false;
}

export function hasSomeAnchorParent(element: HTMLElement): boolean {
  if (!element) {
    return false;
  }

  if (element instanceof HTMLAnchorElement) {
    return true;
  }

  return element.parentElement
    ? hasSomeAnchorParent(element.parentElement)
    : false;
}

export function hasSomeParentWithTag(
  element: HTMLElement,
  tag: string,
): boolean {
  if (!element) {
    return false;
  }

  if (element.tagName === tag.toUpperCase()) {
    return true;
  }

  return element.parentElement
    ? hasSomeParentWithTag(element.parentElement, tag)
    : false;
}
