import type { MatDialogRef } from '@angular/material/dialog';

import type { Action } from '@ngrx/store';
import type { Observable } from 'rxjs';
import { isObservable, merge, of } from 'rxjs';
import { switchMap, take, takeUntil, mergeMap, finalize } from 'rxjs/operators';
import type { Dialog } from '@gv/utils';

import type { TypedDialogBaseActions } from './create-dialog-base-actions';
import { ofActions } from '../of-actions';
import { dialogSynchronizer$ } from './dialog-synchronizer';

type ObservableConfigObj<T, R> = {
  dialogRef: Observable<MatDialogRef<T, R>>;
  cleanup: boolean;
  action: ReturnType<TypedDialogBaseActions<any, R, any>['open']>;
};

type DialogActionsEmmiterConfigObj<T, R> = {
  dialogRef: MatDialogRef<T, R> | Observable<MatDialogRef<T, R>>;
  cleanup?: boolean;
  action: ReturnType<TypedDialogBaseActions<any, R, any>['open']>;
};

export type DialogRefOrConfigObj<
  T,
  R = T extends Dialog<unknown, infer CloseData> ? CloseData : void,
> = DialogActionsEmmiterConfigObj<T, R>;

export function dialogActionsEmitter<
  T,
  P,
  R = T extends Dialog<unknown, infer CloseData> ? CloseData : void,
  O = T extends Dialog<infer OpenData, unknown> ? OpenData : void,
>(
  actions: TypedDialogBaseActions<O, R, P>,
  dRefOrConfig: DialogRefOrConfigObj<T, R>,
  ...otherObservables: Observable<Action | never>[]
): Observable<Action> {
  const configObj = dRefOrConfig as DialogActionsEmmiterConfigObj<T, R>;
  const config: ObservableConfigObj<T, R> = {
    ...configObj,
    cleanup: configObj.cleanup || false,
    dialogRef: isObservable(configObj.dialogRef)
      ? configObj.dialogRef
      : of(configObj.dialogRef),
  };

  const closed = config.dialogRef.pipe(
    switchMap((dialogRef) => dialogRef.afterClosed()),
  );

  return merge(
    config.dialogRef.pipe(
      switchMap((dialogRef): Observable<Action> => {
        return merge(
          dialogRef.afterOpened().pipe(
            take(1),
            mergeMap(
              (): Observable<Action> =>
                ofActions(actions.opened()).pipe(
                  finalize(() => {
                    if (!actions._allowSerialization) {
                      return;
                    }
                    dialogSynchronizer$.next({
                      action: 'add',
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                      data: (config.action as any).data || undefined,
                      type: actions._type,
                      ref: dialogRef,
                    });
                  }),
                ),
            ),
          ),
          dialogRef.afterClosed().pipe(
            take(1),
            mergeMap(
              (props): Observable<Action> =>
                ofActions(actions.closed({ data: props })).pipe(
                  finalize(() => {
                    dialogSynchronizer$.next({
                      action: 'remove',
                      type: actions._type,
                    });
                  }),
                ),
            ),
            finalize(() => {
              if (
                typeof document !== 'undefined' &&
                document.activeElement &&
                document.activeElement instanceof HTMLElement
              ) {
                document.activeElement.blur();
              }
            }),
          ),
        );
      }),
    ),
    ...(config.cleanup
      ? otherObservables.map((a) => a.pipe(takeUntil(closed)))
      : otherObservables),
  );
}
