import { toSentryError } from '@gv/utils';
import type { MemoizedSelector } from '@ngrx/store';
import { createSelector } from '@ngrx/store';
import { captureException } from '@sentry/angular';

import type {
  SubStateArrayModel,
  SubStateObjectModel,
} from './sub-state-model';

export type Prefix<T> = T extends SubStateArrayModel<any> ? 'are' : 'is';

export type Suffix<T> = T extends SubStateArrayModel<any> ? 's' : '';

export type SubStateBaseSelectors<
  State,
  T extends { uuid: string },
  L extends SubStateObjectModel<unknown> | SubStateArrayModel<T>,
  Name extends string,
> = {
  [K in
    | 'Loaded'
    | 'Loading'
    | 'Dirty' as `${Prefix<L>}${Capitalize<Name>}${Suffix<L>}${K}`]: MemoizedSelector<
    State,
    boolean
  >;
} & {
  getErrorMessage: MemoizedSelector<State, string | undefined>;
  getErrorCode: MemoizedSelector<State, number | undefined>;
  getError: MemoizedSelector<State, L['error']>;
  watching: MemoizedSelector<State, number>;
  getHiglighted: MemoizedSelector<State, L['highlighted']>;
  shouldRefresh: (data: {
    dtSent: Date | undefined;
  }) => MemoizedSelector<State, 'refresh' | 'dirty' | 'none'>;
  shouldLoad: MemoizedSelector<State, boolean>;
};

type GetObjectStateType<L> = L extends SubStateObjectModel<infer I> ? I : never;
type GetArrayStateType<L> = L extends SubStateArrayModel<infer I> ? I : never;

export type SubStateObjectSelectors<
  State,
  L extends SubStateObjectModel<unknown>,
  Name extends string,
> = SubStateBaseSelectors<State, any, L, Name> & {
  [K in '' as `get${Capitalize<Name>}`]: MemoizedSelector<
    State,
    GetObjectStateType<L>
  >;
};

export type SubStateArraySelectors<
  State,
  T extends { uuid: string },
  L extends SubStateArrayModel<T>,
  Name extends string,
> = SubStateBaseSelectors<State, T, L, Name> & {
  [K in '' as `get${Capitalize<Name>}s`]: MemoizedSelector<
    State,
    readonly GetArrayStateType<L>[]
  >;
} & {
  [K in '' as `get${Capitalize<Name>}`]: (props: {
    uuid: string;
  }) => MemoizedSelector<State, GetArrayStateType<L>>;
} & {
  [K in '' as `is${Capitalize<Name>}Higlighted`]: (props: {
    uuid: string;
  }) => MemoizedSelector<State, boolean | undefined>;
} & {
  [K in '' as `hasAny${Capitalize<Name>}s`]: (props: {
    uuid: string;
  }) => MemoizedSelector<State, boolean | undefined>;
} & {
  [K in '' as `getHydrateDataFor${K}${Capitalize<Name>}`]: (props: {
    uuid: string;
  }) => MemoizedSelector<
    State,
    {
      dirty: boolean;
      freshness: Date;
      data: GetArrayStateType<L>;
    }
  >;
};

function capitalize(text: string): string {
  if (!text || typeof text !== 'string') {
    return text;
  }

  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function createSubStateSelectors<
  State,
  L extends SubStateObjectModel<unknown>,
  Name extends string,
>(
  subStateSelector: MemoizedSelector<State, L>,
  name: Name,
): SubStateObjectSelectors<State, L, Name>;
export function createSubStateSelectors<
  State,
  T extends { readonly uuid: string },
  L extends SubStateArrayModel<T>,
  LInfered extends L,
  Name extends string,
>(
  subStateSelector: MemoizedSelector<State, L>,
  name: Name,
  isArray: true,
): SubStateArraySelectors<State, T, LInfered, Name>;
export function createSubStateSelectors<
  State,
  T extends { uuid: string },
  L extends SubStateObjectModel<unknown> | SubStateArrayModel<T>,
  Name extends string,
>(
  subStateSelector: MemoizedSelector<State, L>,
  name: Name,
  isArray?: boolean,
): any {
  const getErrorMessage = createSelector(
    subStateSelector,
    (state) => state.error?.message,
  );
  const getErrorCode = createSelector(
    subStateSelector,
    (state) => state.error?.code,
  );
  const getError = createSelector(subStateSelector, (state) => state.error);
  const watching = createSelector(subStateSelector, (state) => state.watching);
  const shouldRefresh = (props: { dtSent: Date | undefined }) =>
    createSelector(subStateSelector, (state) => {
      if (
        !state.loaded ||
        (props.dtSent &&
          state.freshness &&
          state.freshness.getTime() >= props.dtSent.getTime())
      ) {
        return 'none';
      }

      if (state.watching === 0) {
        return 'dirty';
      }

      return 'refresh';
    });

  const shouldLoad = createSelector(subStateSelector, (state) => {
    return state.watching && !state.error && !state.loaded && !state.loading;
  });

  if (isArray) {
    let idsErrorReported = false;
    const ids = createSelector(
      subStateSelector as unknown as MemoizedSelector<
        State,
        SubStateArrayModel<T>
      >,
      (state) => {
        if (!state) {
          if (!idsErrorReported) {
            captureException(
              toSentryError(
                'SubStateSelector::ids',
                new Error(`${capitalize(name)}StateUndefined`),
              ),
            );
            idsErrorReported = true;
          }
          return [];
        }
        return state.ids;
      },
    );

    let dataErrorReported = false;
    const data = createSelector(
      subStateSelector as unknown as MemoizedSelector<
        State,
        SubStateArrayModel<T>
      >,
      (state) => {
        if (!state) {
          if (!dataErrorReported) {
            captureException(
              toSentryError(
                'SubStateSelector::data',
                new Error(`${capitalize(name)}StateUndefined`),
              ),
            );
            dataErrorReported = true;
          }
          return {};
        }
        return state.data;
      },
    );
    const loaded = createSelector(subStateSelector, (state) => state.loaded);

    const selectors = {
      [`are${capitalize(name)}sDirty`]: createSelector(
        subStateSelector,
        (state) => state.dirty,
      ),
      [`are${capitalize(name)}sLoaded`]: loaded,
      [`are${capitalize(name)}sLoading`]: createSelector(
        subStateSelector,
        (state) => state.loading,
      ),
      [`get${capitalize(name)}s`]: createSelector(ids, data, (ids, data) =>
        ids.map((uuid) => data[uuid]),
      ),
      [`getHydrateDataFor${capitalize(name)}`]: (props: { uuid: string }) =>
        createSelector(
          subStateSelector as unknown as MemoizedSelector<
            State,
            SubStateArrayModel<T>
          >,
          (state: SubStateArrayModel<T>) =>
            state
              ? {
                  dirty: state.dirty,
                  freshness: state.freshness,
                  data: state.data[props.uuid],
                }
              : undefined,
        ),
      [`get${capitalize(name)}`]: (props: { uuid: string }) =>
        createSelector(
          subStateSelector as unknown as MemoizedSelector<
            State,
            SubStateArrayModel<T>
          >,
          (state: SubStateArrayModel<T>) => state.data[props.uuid],
        ),
      [`is${capitalize(name)}Higlighted`]: (props: { uuid: string }) =>
        createSelector(
          subStateSelector,
          (state) => state.highlighted.uuid === props.uuid,
        ),
      getHiglighted: createSelector(subStateSelector, (state) => {
        return state.highlighted;
      }),

      [`hasAny${capitalize(name)}s`]: createSelector(
        ids,
        loaded,
        (ids, loaded) => (loaded ? ids.length > 0 : undefined),
      ),
      getErrorMessage,
      getErrorCode,
      getError,
      shouldRefresh,
      shouldLoad,
      watching,
    } as SubStateArraySelectors<
      State,
      GetArrayStateType<L>,
      SubStateArrayModel<GetArrayStateType<L>>,
      Name
    >;
    return selectors;
  }

  const selectors: SubStateObjectSelectors<
    State,
    SubStateObjectModel<GetObjectStateType<L>>,
    Name
  > = {
    [`is${capitalize(name)}Dirty`]: createSelector(
      subStateSelector,
      (state) => state.dirty,
    ),
    [`is${capitalize(name)}Loaded`]: createSelector(
      subStateSelector,
      (state) => state.loaded,
    ),
    [`is${capitalize(name)}Loading`]: createSelector(
      subStateSelector,
      (state) => state.loading,
    ),
    [`get${capitalize(name)}`]: createSelector(
      subStateSelector,
      (state) => state.data,
    ),

    [`is${capitalize(name)}Higlighted`]: (props: { uuid: string }) =>
      createSelector(
        subStateSelector,
        (state) => state.highlighted.uuid === props.uuid,
      ),
    getHiglighted: createSelector(
      subStateSelector,
      (state) => state.highlighted,
    ),
    getErrorMessage,
    getErrorCode,
    getError,
    shouldRefresh,
    shouldLoad,
    watching,
  } as SubStateObjectSelectors<
    State,
    SubStateObjectModel<GetObjectStateType<L>>,
    Name
  >;
  return selectors;
}
