import type { AuthenticationTokenDataModel } from '@gv/api';
import { jwtDecode } from 'jwt-decode';
import LRU from 'lru-cache';

import { logger } from '../logger';

const cache = new LRU<
  string,
  { data?: AuthenticationTokenDataModel; error?: any }
>({ max: 5 });

export function _decode(token: string): AuthenticationTokenDataModel {
  if (!cache.has(token)) {
    try {
      const res = jwtDecode<AuthenticationTokenDataModel>(token);
      cache.set(token, { data: res });
      return res;
    } catch (e) {
      cache.set(token, { error: e });
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data, error } = cache.get(token)!;
  if (error) {
    throw error;
  }
  return data!;
}

export function isTokenExpiredForMoreThan(
  token: string,
  threshold: number,
  timeOffset: number,
  now: Date = new Date(),
): boolean {
  if (!token || token === '') {
    return true;
  }

  try {
    const time: number = _decode(token)?.exp;

    if (time === null || time === undefined) {
      return false;
    }

    let date = new Date(0);
    date.setUTCSeconds(time);
    date = new Date(date.getTime() - timeOffset);

    return !(date.getTime() > now.getTime() - threshold);
  } catch (error) {
    return false;
  }
}

export function isTokenValid(token: string, timeOffset: number): boolean {
  if (!token) {
    return false;
  }

  try {
    const tokenData: AuthenticationTokenDataModel = _decode(token);

    if (!tokenData) {
      return false;
    }

    return !isTokenExpiredForMoreThan(token, 0, timeOffset);
  } catch (error) {
    logger.error({ error }, 'Failed to decode token');
    return false;
  }
}

export function isToken(token: string): boolean {
  if (!token) {
    return false;
  }

  try {
    const tokenData: AuthenticationTokenDataModel = _decode(token);

    if (!tokenData) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
}

export function decodeToken(
  token: string,
): AuthenticationTokenDataModel | undefined {
  if (!token) {
    return undefined;
  }

  try {
    return _decode(token);
  } catch (error) {
    logger.error({ error }, 'Failed to decode token');
  }
  return undefined;
}

export function getTokenUserUuid(token: string): string | undefined {
  if (token) {
    try {
      const authenticationTokenData: AuthenticationTokenDataModel =
        _decode(token);

      return authenticationTokenData?.user ?? undefined;
    } catch (error) {
      logger.error({ error }, 'Failed to decode token');
    }
  }
  return undefined;
}
