import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TrackingDetectorService {
  public isTrackingEnabled(): boolean {
    if (!environment.production) {
      return false;
    }

    const dnt =
      navigator.doNotTrack ||
      (navigator as any).msDoNotTrack ||
      (window as any).doNotTrack;
    if (
      dnt === undefined ||
      dnt === null ||
      dnt.toString() === '0' ||
      dnt.toLowerCase() === 'no' ||
      dnt.toLowerCase() === 'unspecified'
    ) {
      return true;
    }
    return false;
  }
}
