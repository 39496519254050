import type {
  AppThemeMode,
  CreateOrganization,
  OrganizationUserDTO,
} from '@gv/api';
import { createDialogBaseActions } from '@gv/state';
import { props } from '@ngrx/store';

import type { AcceptOrganizationInviteData } from '../../dialog/accept-organizetion-invite';

const basename = '[Organization Dialog]';

export const OrganizationDialogActions = {
  createThemeDialog: createDialogBaseActions(basename, 'CreateTheme', {
    openProps: props<{
      data: {
        color: string;
        slugSet: boolean;
        orgName: string;
        mode: AppThemeMode;
      };
    }>(),
  }),

  inviteViaReferral: createDialogBaseActions(basename, 'InviteViaReferral'),

  inviteOrganizationMember: createDialogBaseActions(
    basename,
    'InviteOrganizationMember',
    {
      openProps: props<{
        data: { hasFreeSeat: boolean };
      }>(),
    },
  ),

  editOrganizationMember: createDialogBaseActions(
    basename,
    'EditOrganizationMember',
    {
      openProps: props<{
        data: { hasFreeSeat: boolean; user: OrganizationUserDTO };
      }>(),
    },
  ),

  removeOrganizationMember: createDialogBaseActions(
    basename,
    'RemoveOrganizationMember',
    {
      openProps: props<{
        data: { user: OrganizationUserDTO };
      }>(),
    },
  ),

  createOrganization: createDialogBaseActions(basename, 'NewOrganization', {
    openProps: props<{
      data: CreateOrganization;
    }>(),
  }),

  acceptOrganizationDialog: createDialogBaseActions(
    basename,
    'OrganizationInvitation',
    {
      allowSerialization: true,
      openProps: props<{
        data: AcceptOrganizationInviteData;
      }>(),
    },
  ),
};
