import type { NgZone } from '@angular/core';

import type { Subscription, SchedulerLike, SchedulerAction } from 'rxjs';

class LeaveZoneScheduler implements SchedulerLike {
  constructor(
    private zone: NgZone,
    private scheduler: SchedulerLike,
  ) {}

  schedule<T>(
    work: (this: SchedulerAction<T>, state?: T) => void,
    delay?: number,
    state?: T,
  ): Subscription {
    return this.zone.runOutsideAngular(() =>
      this.scheduler.schedule<T>(work, delay, state),
    );
  }

  now(): number {
    return this.scheduler.now();
  }
}

export function leaveZone(
  zone: NgZone,
  scheduler: SchedulerLike,
): SchedulerLike {
  return new LeaveZoneScheduler(zone, scheduler);
}
