import type { HttpHeaders } from '@angular/common/http';
import { HttpResponse } from '@angular/common/http';

import type { FilePartUploadModel } from '../upload/file-part-upload-model';

export class MultipartFileUploadHttpResponse extends HttpResponse<void> {
  filePartUploads: FilePartUploadModel[];

  constructor(init?: {
    body?: void;
    filePartUploads: FilePartUploadModel[];
    headers?: HttpHeaders;
    status?: number;
    statusText?: string;
    url?: string;
  }) {
    super(init);

    this.filePartUploads = init.filePartUploads;
  }
}
