import { createAction, props } from '@ngrx/store';
import type {
  CreateProjectCollaborationModel,
  CreateProjectModel,
  DemoProjectValidationModel,
  EditProjectCollaborationModel,
  EditProjectModel,
  ProjectApiModel,
  ApiResponse,
  MapSettings,
  EditProjectOwner,
  LayoutView,
  Api,
  InferApiResponse,
} from '@gv/api';
import { createFlowBaseActions } from '@gv/state';

import type { ProjectStateModel } from '../../../../entity/model/projects/project-state-model';
import type {
  InternalProjectPageType,
  ProjectFilterStateModel,
} from './projects-state.types';

const basename = '[Projects]';

export const reset = createAction(`${basename} reset`);

export const updateNeededItems = createAction(
  `${basename} update needed items`,
  props<{ items: number }>(),
);

export const deleteProjectFlow = createFlowBaseActions(basename, 'delete', {
  initProps: props<{
    data: {
      projectUuid: string;
    };
  }>(),
});

export const getProjectFlow = createFlowBaseActions(basename, 'get', {
  initProps: props<{
    data: {
      projectUuid: string;
    };
  }>(),
});

export const shareProjectFlow = createFlowBaseActions(basename, 'share', {
  initProps: props<{
    data: {
      projectUuid: string;
      data: CreateProjectCollaborationModel;
    };
  }>(),
});

export const initTransferProjectFlow = createFlowBaseActions(
  basename,
  'init transfer',
  {
    initProps: props<{
      data: {
        projectUuid: string;
        data: EditProjectOwner;
      };
    }>(),
  },
);

export const loadMore = {
  init: createAction(`${basename} load more init`),
  completed: createAction(
    `${basename} load more completed`,
    props<{
      data: InferApiResponse<ReturnType<Api['getProjects']>> & {
        dtSent: Date;
      };
    }>(),
  ),
};

export const fetchCounts = {
  init: createAction(
    `${basename} fetch counts init`,
    props<{ all?: boolean }>(),
  ),
  completed: createAction(
    `${basename} fetch counts completed`,
    props<{
      currentPageType: InternalProjectPageType;
      data: Partial<Record<InternalProjectPageType, number>>;
    }>(),
  ),
  cancel: createAction(`${basename} fetch counts cancelled`),
};

export const fetchProjectDetail = {
  init: createAction(
    `${basename} fetch project detail`,
    props<{ uuid: string }>(),
  ),
  completed: createAction(
    `${basename} fetch project detail completed`,
    props<{
      data: ProjectStateModel;
    }>(),
  ),
};

export const acceptProjectFlow = createFlowBaseActions(
  basename,
  'accept project transfer',
  {
    initProps: props<{
      data: { project: string; request: string; accept: boolean };
    }>(),
  },
);

export const removeCollaborationFlow = createFlowBaseActions(
  basename,
  'remove collaboration',
  {
    initProps: props<{
      data: {
        projectUuid: string;
        userUuid: string;
      };
    }>(),
  },
);

export const editCollaborationFlow = createFlowBaseActions(
  basename,
  'edit collaboration',
  {
    initProps: props<{
      data: {
        projectUuid: string;
        userUuid: string;
        data: EditProjectCollaborationModel;
      };
    }>(),
  },
);

export const editProjectFlow = createFlowBaseActions(basename, 'edit', {
  initProps: props<{
    data: {
      projectUuid: string;
      data: EditProjectModel;
      successMessage?: string;
    };
  }>(),
  completedProps: props<{
    data: ApiResponse<ProjectApiModel>;
  }>(),
});

export const createProjectFlow = createFlowBaseActions(basename, 'create', {
  initProps: props<{
    data: CreateProjectModel & { open: boolean };
  }>(),
  completedProps: props<{
    data: ApiResponse<ProjectApiModel>;
  }>(),
});

export const importDemoProjectFlow = createFlowBaseActions(
  basename,
  'import demo',
  {
    completedProps: props<{
      data: ApiResponse<ProjectApiModel>;
    }>(),
  },
);

export const validateDemoProject = createAction(
  `${basename} validate demo`,
  props<{ skipWhenLoaded: boolean }>(),
);

export const validateDemoProjectFlow = createFlowBaseActions(
  basename,
  'validate demo',
  {
    completedProps: props<{
      data: ApiResponse<DemoProjectValidationModel>;
    }>(),
  },
);

export const setMapSettings = {
  init: createAction(
    `${basename} set map options init`,
    props<{
      settings: MapSettings & { preferredZoom: number };
      skipUpdate: boolean;
    }>(),
  ),

  completed: createAction(
    `${basename} set map options completed`,
    props<{
      settings: MapSettings & { preferredZoom: number };
    }>(),
  ),
};

export const setPageLayout = {
  init: createAction(
    `${basename} set page layout init`,
    props<{ layout: LayoutView }>(),
  ),
  completed: createAction(
    `${basename} set page layout completed`,
    props<{ layout: LayoutView }>(),
  ),
};

export const setPageType = createAction(
  `${basename} set page type`,
  props<{ page: InternalProjectPageType }>(),
);

export const setFilter = createAction(
  `${basename} set page filter`,
  props<{ filter: Partial<ProjectFilterStateModel> }>(),
);

export const setAllowedTypes = createAction(
  `${basename} set allowed types`,
  props<{
    types: ProjectFilterStateModel['types'];
    default: ProjectFilterStateModel['types'][number];
  }>(),
);

export const setAllowedPageTypes = createAction(
  `${basename} set allowed page types`,
  props<{
    types: InternalProjectPageType[];
  }>(),
);
