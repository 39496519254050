import type { OnDestroy } from '@angular/core';
import { inject, Injectable, NgZone } from '@angular/core';

import type { ClientLog } from '@gv/api';
import { API, ApiErrorResponse } from '@gv/api';
import { appVersion } from '@gv/constant';
import { ElectronAppInfoService } from '@gv/desktop/core';
import { createComponentLogger } from '@gv/logger';
import { APP_INSTANCE_ID, IndexedDBService } from '@gv/ui/core';
import { ConnectionStatusCheckerService } from '@gv/ui/utils';
import { USER_CONTEXT } from '@gv/user';
import {
  generateUuid,
  leaveZone,
  RequestRetryManagerService,
  RetryMode,
  simpleSwitchMap,
  untilNgDestroyed,
} from '@gv/utils';
import {
  catchError,
  concatMap,
  defer,
  filter,
  firstValueFrom,
  from,
  map,
  merge,
  observeOn,
  of,
  queueScheduler,
  startWith,
  Subject,
  timer,
} from 'rxjs';

import { logger as _logger } from '../logger';
import type { ClientLogDataModel } from './client-logger.actions';

export interface ClientLogItemModel {
  readonly id: number;

  readonly sendClientLogData: ClientLog;
}

@Injectable({
  providedIn: 'root',
})
export class ClientLoggerService implements OnDestroy {
  private appInstanceId = inject(APP_INSTANCE_ID);
  private api = inject(API);
  private connectionStatusChecker = inject(ConnectionStatusCheckerService);
  private ngZone = inject(NgZone);
  private electronAppInfoService = inject(ElectronAppInfoService, {
    optional: true,
  });
  private requestsRetryManager = inject(RequestRetryManagerService);
  private indexedDbService = inject(IndexedDBService);
  private userContext = inject(USER_CONTEXT);
  private static readonly sendDelay: number = 10_000;

  private logger = createComponentLogger(_logger, 'ClientLoggerService');

  private id = 1;

  private disableLog = false;

  private get sessionId(): string {
    return this.electronAppInfoService?.appInfo?.sessionId;
  }

  private get desktopVersion(): string {
    return this.electronAppInfoService?.appInfo?.version;
  }

  private enabled$ = defer(() =>
    this.connectionStatusChecker.statusChanged$.pipe(
      startWith(this.connectionStatusChecker.connected),
    ),
  );
  private flushSubject = new Subject<string>();

  private sendingLoop$ = this.enabled$.pipe(
    observeOn(leaveZone(this.ngZone, queueScheduler)),
    simpleSwitchMap(() =>
      merge(
        this.flushSubject,
        timer(ClientLoggerService.sendDelay, ClientLoggerService.sendDelay),
      ).pipe(
        concatMap((id) =>
          from(this.send()).pipe(
            map(() => id),
            catchError((error: unknown) => {
              this.logger.error({ error }, 'Failed to send client log');
              return of(id);
            }),
          ),
        ),
      ),
    ),
    untilNgDestroyed(),
  );

  ngOnDestroy(): void {
    //
  }

  disable(): void {
    this.disableLog = true;
  }

  enable(): void {
    this.disableLog = false;
  }

  enableAutoSending(): void {
    this.sendingLoop$.subscribe();
  }

  async flush(): Promise<void> {
    const uuid = generateUuid();
    const completed = firstValueFrom(
      this.sendingLoop$.pipe(filter((f) => f === uuid)),
    );

    this.flushSubject.next(uuid);

    await completed;
  }

  sendClientLog(
    clientLogData: ClientLogDataModel,
    keepOffline = true,
  ): Promise<void> {
    if (
      (!keepOffline && this.connectionStatusChecker.connected === false) ||
      this.disableLog
    ) {
      return Promise.resolve();
    }

    return this.addToStorage({
      ...clientLogData,
      sessionId: this.sessionId,
      appInstanceId: this.appInstanceId,
      desktopVersion: this.desktopVersion,
      version: appVersion,
    });
  }

  private async addToStorage(sendClientLogData: ClientLog): Promise<void> {
    const clientLogItem: ClientLogItemModel = {
      sendClientLogData,
      id: this.id++,
    };

    try {
      const connection = await this.indexedDbService.getConnection();
      await connection.put('clientLog', clientLogItem, generateUuid());
    } catch (e) {
      this.logger.error(
        { item: sendClientLogData },
        'Failed to store client log item locally.',
      );
      return this._send(clientLogItem);
    }
  }

  private async send(): Promise<void> {
    const connection = await this.indexedDbService.getConnection();

    const transaction = connection.transaction('clientLog', 'readonly');
    let cursor = await transaction.store.openCursor();
    const data: { key: string; value: ClientLogItemModel }[] = [];
    while (cursor) {
      data.push({ key: cursor.key, value: cursor.value as ClientLogItemModel });
      cursor = await cursor.continue();
    }
    await transaction.done;

    if (!data.length) {
      return;
    }

    try {
      await this._send(
        ...data.map((m) => m.value).filter((v) => v && v.sendClientLogData),
      );

      const connection = await this.indexedDbService.getConnection();
      const transaction = connection.transaction('clientLog', 'readwrite');

      const promises = [];
      for (const { key } of data) {
        promises.push(transaction.store.delete(key));
      }

      promises.push(transaction.done);
      await Promise.all(promises);
    } catch (error) {
      this.logger.error({ error }, 'Failed to send client log');
    }
  }

  private async _send(...clientLogItems: ClientLogItemModel[]): Promise<void> {
    if (clientLogItems.length === 0) {
      return;
    }

    const logs: ClientLog[] = clientLogItems
      .sort((a, b) => {
        const dateA = new Date(a.sendClientLogData.datetime).getTime();
        const dateB = new Date(b.sendClientLogData.datetime).getTime();
        return dateA === dateB && a.id && b.id ? a.id - b.id : dateA - dateB;
      })
      .map((item) => item.sendClientLogData);

    const chunks = logs.reduce((acc, log, i) => {
      const chunk = Math.floor(i / 10);
      acc[chunk] = [].concat(acc[chunk] || [], log);
      return acc;
    }, [] as ClientLog[][]);

    for (let i = 0; i < chunks.length; i++) {
      const chunk = chunks[i];

      await firstValueFrom(
        this.api
          .sendClientLog(
            { logs: chunk },
            {
              headers: {
                'x-instance': this.appInstanceId,
                'x-version': appVersion,
                ...(!this.sessionId ? {} : { 'x-session_id': this.sessionId }),
              },
            },
          )
          .pipe(
            this.requestsRetryManager.createRetryOnError(
              (error) =>
                error instanceof ApiErrorResponse && error.status >= 500,
              1_000,
              10_000,
              3,
              RetryMode.NumberOfRetries,
              this.userContext.userUuid$,
            ),
          ),
        { defaultValue: undefined },
      );
    }
  }
}
