import type { OnDestroy } from '@angular/core';
import { Injectable, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { untilNgDestroyed } from '@gv/utils';
import type { Observable } from 'rxjs';
import { firstValueFrom } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PersistBillingUrlService implements OnDestroy {
  private router = inject(Router);
  private readonly billingSectionUrl = '/user-settings';
  private readonly appBaseUrl = '/app/';

  private savedUrl$: Observable<string> = this.router.events.pipe(
    filter<NavigationEnd>((event) => {
      return event instanceof NavigationEnd;
    }),
    map((event) => event.url),
    startWith(this.router.url),
    filter((url) => this.isStorable(url)),
    distinctUntilChanged(),
    startWith(undefined),
    shareReplay({ refCount: true, bufferSize: 1 }),
    untilNgDestroyed(),
  );

  ngOnDestroy(): void {
    //
  }

  getSavedUrl(): Promise<string> {
    return firstValueFrom(this.savedUrl$);
  }

  enable(): void {
    this.savedUrl$.subscribe();
  }

  private isStorable(url: string): boolean {
    return (
      url &&
      url.trim().length > 0 &&
      !url.includes(this.billingSectionUrl) &&
      url.includes(this.appBaseUrl)
    );
  }
}
