import { APP_INITIALIZER, inject, NgModule, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';

import { Actions } from '@ngrx/effects';
import { from, of } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  pairwise,
  take,
  timeout as timeoutOp,
} from 'rxjs/operators';
import { ClientLogLevel, AuthenticationTokenType } from '@gv/api';
import { NgStore, StoreInject } from '@gv/state';
import { DateTime } from 'luxon';
import { captureException } from '@sentry/core';
import { filterTruthy } from '@gv/utils';
import { ElectronAppInfoService, ElectronRefService } from '@gv/desktop/core';
import { ConnectionStatusCheckerService } from '@gv/ui/utils';
import { ClientLoggerService } from '@gv/client-logger';
import { IndexedDBService, LocalStorageService } from '@gv/ui/core';
import { NotificationListenerService } from '@gv/notification';
import { decodeToken, getTokenUserUuid } from '@gv/user';
import { CrossTabChannelService } from '@gv/crosstab';
import { GlobalUploadStateService } from '@gv/upload/ui';
import {
  S3FileUploadService,
  UPLOADS_FEATURE_STATE,
  UploadsActions,
} from '@gv/upload/state';

import { AppErrorHandlerService } from '../../service/application/app-error-handler.service';
import { APP_CONFIG } from '../../entity/token/app.config';
import { DebugModeService } from '../../service/helper/debug-mode.service';
import { HotjarTagService } from '../../service/application/tracking/hotjar-tag.service';
import { RouterHelperService } from '../../service/application/routing/router-helper.service';
import { StorageInitializerService } from '../../service/application/storage-initializer.service';
import { TokenStoreService } from '../../service/application/user/token-store.service';
import { UserInitializerService } from '../../service/application/user/user-initializer.service';
import { UserLoggerService } from '../../service/application/user/user-logger.service';
import { UserService } from '../../service/application/user/user.service';
import { VideoUploadHeartbeatsService } from '../../service/http/video-upload-heartbeats.service';
import { logger } from '../../../logger';

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const storageInitializerService = inject(StorageInitializerService);
        const electronAppInfoService = inject(ElectronAppInfoService, {
          optional: true,
        });
        const localStorageService = inject(LocalStorageService);
        const errorHandler = inject(AppErrorHandlerService);
        const debugModeService = inject(DebugModeService);
        const connectionStatusChecker = inject(ConnectionStatusCheckerService);
        const clientLogger = inject(ClientLoggerService);
        const notificationListenerService = inject(NotificationListenerService);
        return () =>
          from([
            // serialize execution
            () => storageInitializerService.init(),
            () => electronAppInfoService?.initialize(),
            () => errorHandler.enable(),
            () => localStorageService.enable(),
            () => debugModeService.enable(),
            () => connectionStatusChecker.enable(),
            () => clientLogger.enableAutoSending(),
            () => notificationListenerService.enable(),
          ]).pipe(concatMap((fn) => from(Promise.resolve(fn()))));
      },
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const clientLogger = inject(ClientLoggerService);
        const tokenStore = inject(TokenStoreService);
        return () => {
          tokenStore.token$.subscribe((token) => {
            try {
              if (token?.data) {
                const tokenData = decodeToken(token.data);
                if (
                  tokenData &&
                  tokenData.type === AuthenticationTokenType.Admin
                ) {
                  clientLogger.disable();
                } else {
                  clientLogger.enable();
                }
              } else {
                clientLogger.enable();
              }
            } catch (e) {
              logger.error({ token }, 'Invalid token');
              captureException(e);
            }
          });
        };
      },
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const clientLogger = inject(ClientLoggerService);
        return () => {
          clientLogger
            .sendClientLog({
              datetime: DateTime.now().toISO(),
              level: ClientLogLevel.Info,
              message: `App initialized`,
            })
            .catch((error) => {
              logger.error({ error }, 'Failed to send client log');
            });
        };
      },
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const userService = inject(UserService);
        const clientLogger = inject(ClientLoggerService);
        return () => {
          userService.tokenUserUuid$.subscribe((userUuid) => {
            const userData = JSON.stringify({
              user: userService.user,
            });

            clientLogger
              .sendClientLog({
                datetime: DateTime.now().toISO(),
                level: ClientLogLevel.Info,
                message: `User changed (user:${userUuid}) (data:${userData}).`,
              })
              .catch((error) => {
                logger.error({ error }, 'Failed to send client log');
              });
          });
        };
      },
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const userService = inject(UserService);
        const clientLogger = inject(ClientLoggerService);
        return () => {
          userService.registerBeforeLogoutHook(() => {
            return clientLogger.flush();
          });
        };
      },
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const tokenService = inject(TokenStoreService);
        const userInitializerService = inject(UserInitializerService);
        const userService = inject(UserService);
        const router = inject(Router);
        const ngZone = inject(NgZone);

        return async () => {
          tokenService.externalTokenUpdate$.subscribe({
            next: (token) => {
              if (token) {
                if (userService.user?.uuid !== getTokenUserUuid(token?.data)) {
                  userInitializerService.initializeUser(token?.data);
                }
              } else {
                ngZone.run(() => router.navigate(['/logout']));
              }
            },
            error: logger.error,
          });

          await tokenService.init();
          return await userInitializerService.initializeUser();
        };
      },
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const videoUploadHeartbeatsService = inject(
          VideoUploadHeartbeatsService,
        );
        return () => videoUploadHeartbeatsService.enable();
      },
      multi: true,
    },
    // {
    //   provide: APP_INITIALIZER,
    //   deps: [PushNotificationListenerService],
    //   useFactory: (
    //     pushNotificationListener: PushNotificationListenerService,
    //   ) => {
    //     return () => {
    //       if (!environment.production) {
    //         return;
    //       }
    //       pushNotificationListener.enable();
    //     };
    //   },
    //   multi: true,
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const appConfig = inject(APP_CONFIG);
        const userService = inject(UserService);
        const tokenStore = inject(TokenStoreService);
        const notificationListener = inject(NotificationListenerService);
        const s3FileUploadService = inject(S3FileUploadService);
        const hotjarTagService = inject(HotjarTagService);
        const store = inject(StoreInject(UPLOADS_FEATURE_STATE));
        const db = inject(IndexedDBService);
        return () => {
          userService.userUuid$
            .pipe(
              filterTruthy(),
              pairwise(),
              filter(([lastUserUuid, userUuid]) => lastUserUuid !== userUuid),
            )
            .subscribe(() => {
              s3FileUploadService.removeUnfinishedUploads();
              store.dispatch(UploadsActions.removeAllUploads());
              db.getConnection().then((connection) =>
                connection.clear('angulartics'),
              );
            });

          let timeout: ReturnType<typeof setTimeout>;

          userService.isLoggedIn$.subscribe((loggedIn) => {
            clearTimeout(timeout);

            hotjarTagService.tagUser();

            timeout = setTimeout(() => {
              if (loggedIn) {
                tokenStore.enableRefreshing();

                notificationListener.connected$
                  .pipe(
                    filter((connected) => connected),
                    timeoutOp(10000),
                    catchError(() => {
                      return of('waiting-for-connection-timed-out');
                    }),
                    take(1),
                  )
                  .subscribe(() => {
                    store.dispatch(UploadsActions.removePending());
                  });

                store.dispatch(UploadsActions.cancelKilledUploads());

                s3FileUploadService.enable();
              } else {
                tokenStore.disableRefreshing();
                s3FileUploadService.disable();
              }
            }, appConfig.authentication.tokenChangeHandlerTimeout);
          });
        };
      },
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const userLoggerService = inject(UserLoggerService);
        const routerHelper = inject(RouterHelperService);
        const crossTabChannel = inject(CrossTabChannelService);
        const globalUploadStateService = inject(GlobalUploadStateService);
        return () =>
          from([
            () => userLoggerService.enable(),
            () => routerHelper.enable(),
            () => crossTabChannel.enable(),
            () => globalUploadStateService.enable(),
          ]).pipe(concatMap((fn) => from(Promise.resolve(fn()))));
      },
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const debugModeService = inject(DebugModeService);
        const actions$ = inject(Actions);
        const store = inject(NgStore);
        return () => {
          debugModeService.registerStatic('Actions', actions$);
          debugModeService.registerStatic('Store', store);
        };
      },
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const electronRef = inject(ElectronRefService, { optional: true });
        const router = inject(Router);
        if (!electronRef) {
          return () => {
            //
          };
        }

        return () => {
          electronRef.onDeepLinkOpened$.subscribe((link) => {
            if (!link) {
              return;
            }

            try {
              const url = new URL(link);
              if (url.pathname.endsWith('.html')) {
                return;
              }
              const token = url.searchParams.get('token');
              if (!token) {
                return;
              }
              router.navigate(['/'], {
                queryParams: {
                  token: token,
                },
                queryParamsHandling: 'merge',
              });
            } catch (e) {
              // ignore
            }
          });
        };
      },
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory:
        (registry = inject(MatIconRegistry)) =>
        () => {
          registry.setDefaultFontSetClass('material-symbols-rounded');
          registry.registerFontClassAlias(
            'fill',
            'material-symbols-fill material-symbols-rounded mat-ligature-font',
          );
        },
      multi: true,
    },
  ],
})
export class AppInitializerModule {}
