import { DOCUMENT } from '@angular/common';
import type { OnInit } from '@angular/core';
import {
  Component,
  DestroyRef,
  HostListener,
  NgZone,
  Renderer2,
  inject,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AuthenticationTokenType, ClientLogLevel } from '@gv/api';
import { appVersion } from '@gv/constant';
import { ElectronAppInfoService, ElectronRefService } from '@gv/desktop/core';
import { getSync, StoreInject, UrlHighlighter } from '@gv/state';
import { APP_INSTANCE_ID, WindowRefService } from '@gv/ui/core';
import type { BootInput } from '@gv/ui/help';
import { Intercom } from '@gv/ui/help';
import { decodeToken } from '@gv/user';
import { untilNgDestroyed, IconResolverService } from '@gv/utils';
import { DialogUrlSerializer } from '@gv/ui/dialog';
import { Angulartics2, Angulartics2GoogleTagManager } from 'angulartics2';
import { isEqual } from 'lodash-es';
import { DateTime } from 'luxon';
import UAParser from 'ua-parser-js';
import type { Observable } from 'rxjs';
import { combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { AppInstanceService, CrossTabChannelService } from '@gv/crosstab';
import {
  UPLOADS_FEATURE_STATE,
  fromUploadsState,
  VideoUploadHandlerService,
} from '@gv/upload/state';
import { COOKIE_SERVICE } from '@gv/ui/utils';

import { APP_CONFIG } from '../../entity/token/app.config';
import { ApiService } from '../../service/api/api.service';
import { TrackingDetectorService } from '../../service/application/privacy/tracking-detector.service';
import { ReloadService } from '../../service/application/reload.service';
import { ScrollbarService } from '../../service/application/scrollbar.service';
import { PersistBillingUrlService } from '../../service/application/state/persist-billing-url.service';
import { ThemeService } from '../../service/application/theme.service';
import { TitleUpdaterService } from '../../service/application/title-updater.service';
import { HotjarTagService } from '../../service/application/tracking/hotjar-tag.service';
import { TokenStoreService } from '../../service/application/user/token-store.service';
import { UserService } from '../../service/application/user/user.service';
import { BrowserTypeService } from '../../service/helper/browser-type.service';
import { PromiseUtils } from '../../service/util/promise-utils';
import { fromCookieLawState } from '../../store/selector/cookie-law.selector';
import { APP_STATE } from '../../store/state/app-state';
import { COOKIE_LAW_FEATURE_STATE } from '../../store/state/feature-state/cookie-law-feature.state';

// eslint-disable-next-line @angular-eslint/prefer-standalone-component
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private uaParser = new UAParser();
  private destroyRef = inject(DestroyRef);
  appConfig = inject(APP_CONFIG);
  private appInstanceId = inject(APP_INSTANCE_ID);
  private router = inject(Router);
  private trackingDetector = inject(TrackingDetectorService);
  private userService = inject(UserService);
  private titleUpdater = inject(TitleUpdaterService);
  private apiService = inject(ApiService);
  private windowRef = inject(WindowRefService);
  private cookieService = inject(COOKIE_SERVICE);
  private angulartics2 = inject(Angulartics2);
  private angulartics2GoogleTagManager = inject(Angulartics2GoogleTagManager);
  private renderer = inject(Renderer2);
  private hotjarTagService = inject(HotjarTagService);
  private intercom = inject(Intercom);
  private scrollbar = inject(ScrollbarService);
  private themeService = inject(ThemeService);
  private document = inject(DOCUMENT);
  private ngZone = inject(NgZone);
  private store = inject(
    StoreInject(APP_STATE, UPLOADS_FEATURE_STATE, COOKIE_LAW_FEATURE_STATE),
  );
  browserType = inject(BrowserTypeService);
  private videoUploadHandlerService = inject(VideoUploadHandlerService);
  private appInstanceService = inject(AppInstanceService);
  private crossTabChannel = inject(CrossTabChannelService);
  private persistBillingUrlService = inject(PersistBillingUrlService);
  private reloader = inject(ReloadService);
  private tokenStore = inject(TokenStoreService);
  private iconResolverService = inject(IconResolverService);
  private electronRef = inject(ElectronRefService, { optional: true });
  private electronAppInfoService = inject(ElectronAppInfoService, {
    optional: true,
  });
  private dialogSerializer = inject(DialogUrlSerializer);
  private highlighter = inject(UrlHighlighter);
  private _showMobileDetected = false;

  get showMobileDetected(): boolean {
    return this._showMobileDetected;
  }

  set showMobileDetected(show: boolean) {
    if (this._showMobileDetected !== show) {
      this._showMobileDetected = show;
    }
  }

  cookiesNeedsToBeConfirmed$ = this.electronAppInfoService
    ? of(false)
    : combineLatest([
        this.store.select(fromCookieLawState.getWasConfirmed),
        this.userService.isLoggedIn$,
      ]).pipe(
        map(([wasConfirmed, isLoggedIn]) => !wasConfirmed && !isLoggedIn),
        distinctUntilChanged(),
      );

  readonly dark$ = this.themeService.themeOverride$.pipe(
    switchMap((override) => (override ? of(false) : this.windowRef.theme$)),
  );

  private readonly tokenType$: Observable<AuthenticationTokenType> =
    this.tokenStore.token$.pipe(
      map((token) => decodeToken(token?.data)?.type),
      distinctUntilChanged(),
      untilNgDestroyed(),
    );

  ngOnInit(): void {
    setTimeout(() => {
      this.checkMobile();
    });

    this.persistBillingUrlService.enable();

    this.angulartics2.settings.developerMode =
      !this.trackingDetector.isTrackingEnabled();
    this.angulartics2GoogleTagManager.startTracking();

    this.hotjarTagService.tagAppVersion();

    const loaderElement = this.document.getElementById('loader');
    if (loaderElement) {
      this.renderer.addClass(loaderElement, 'hidden');
    }

    this.userService.userUuid$
      .pipe(untilNgDestroyed(this.destroyRef))
      .subscribe(() => {
        this.tagAngularticsUser();
      });

    combineLatest([
      this.tokenType$,
      this.userService.user$.pipe(distinctUntilChanged()),
    ])
      .pipe(
        map(([tokenType, user]) => {
          if (!user) {
            return undefined;
          }

          return {
            dtRegistered: user.dtRegistered,
            email: user.email,
            name: user.name,
            phone: user.phone,
            tokenType,
            uuid: user.uuid,
          };
        }),
        distinctUntilChanged((a, b) => isEqual(a, b)),
        untilNgDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.ngZone.runOutsideAngular(() => {
          this.initTracking();
        });
      });

    this.router.events
      .pipe(
        filter((evt) => evt instanceof NavigationEnd),
        untilNgDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        // scroll to top on route change
        document.body.scrollTop = 0;
      });

    this.titleUpdater.enable(); // TODO: enable rather in app initializer?

    this.scrollbar.scrollbarVisible$
      .pipe(distinctUntilChanged(), untilNgDestroyed(this.destroyRef))
      .subscribe(() => {
        this.updateHtmlScrollbar();
      });

    // this needs to be called before AppInstanceService::onAppInit
    this.crossTabChannel.purge();

    this.appInstanceService.onAppInit();

    this.reloader.enable();

    this.removeAllInvalidServiceWorkers();
  }

  private async removeAllInvalidServiceWorkers(): Promise<void> {
    try {
      const registeredServiceWorkers =
        await navigator.serviceWorker.getRegistrations();
      const oldWorkers = registeredServiceWorkers.filter((worker) => {
        return (
          worker.scope.endsWith('.gvl.io/') ||
          worker.scope.endsWith('.goodvisionlive.com/')
        );
      });

      await PromiseUtils.softAll(oldWorkers.map((w) => w.unregister()));
    } catch (_) {
      // ignore
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeWindowUnload(event: BeforeUnloadEvent): string {
    if (
      !this.electronRef &&
      getSync(this.store, fromUploadsState.vault.getAnyUploadNeedsUserAttention)
    ) {
      const message = $localize`:@@app.cancel-upload:Are you sure you want to cancel uploading?`;
      event.returnValue = message;
      return message;
    }

    this.sendUnloadBeacon();
    return undefined;
  }

  @HostListener('window:unload')
  windowUnload(): void {
    this.videoUploadHandlerService.persistUploads();

    if (
      getSync(this.store, fromUploadsState.vault.getAnyUploadNeedsUserAttention)
    ) {
      this.sendUnloadBeacon();
    }

    this.appInstanceService.onAppUnload();

    this.crossTabChannel.broadcastDisconnect();
  }

  @HostListener('window:click', ['$event'])
  click(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    // this is workaround for weird automatic scroll that happens on chrome 84 when hovering over mat-icon inside focused link (happens mostly on platform plans page)
    if (
      target &&
      target.nodeName?.toLowerCase() === 'mat-icon' &&
      target.parentElement?.nodeName?.toLowerCase() === 'a' &&
      // eslint-disable-next-line deprecation/deprecation
      (target.parentElement as HTMLLinkElement).target === '_blank' &&
      this.document.activeElement &&
      (this.document.activeElement as HTMLElement).blur
    ) {
      (this.document.activeElement as HTMLElement).blur();
    }
  }

  private tagAngularticsUser(): void {
    this.angulartics2.setUsername.next(this.userService.user?.uuid);
  }

  private updateHtmlScrollbar(): void {
    const htmlElement = this.document.getElementsByTagName('html').item(0);
    if (!htmlElement) {
      return;
    }

    if (!this.scrollbar.isScrollbarVisible) {
      this.renderer.addClass(htmlElement, 'hide-scrollbar');
      this.renderer.removeClass(htmlElement, 'show-scrollbar');
    } else {
      this.renderer.addClass(htmlElement, 'show-scrollbar');
      this.renderer.removeClass(htmlElement, 'hide-scrollbar');
    }
  }

  private initTracking(): void {
    const userUuid = this.userService.user?.uuid;

    this.intercom.shutdown();

    // To hide intercom chat when the impersonated login occurs (-__-)
    switch (this.tokenStore.decodedToken?.type) {
      case AuthenticationTokenType.Admin:
      case AuthenticationTokenType.Support:
      case AuthenticationTokenType.SuperAdmin:
        return;
    }

    const bootInput: BootInput = {};

    if (userUuid) {
      const user = this.userService.user;
      bootInput.created_at = user
        ? new Date(user.dtRegistered).getTime()
        : undefined;
      bootInput.email = user?.email;
      bootInput.user_hash = user?.identity;
      bootInput.name = user?.name;
      bootInput.phone = user?.phone;
      bootInput.user_id = userUuid;
      bootInput.using_desktop_app = !!this.electronRef;
      bootInput.app_version = appVersion;
    }

    this.intercom.boot(bootInput);
  }

  private checkMobile(): void {
    if (this.uaParser.getDevice()?.type === UAParser.DEVICE.MOBILE) {
      this.setShowMobileDetected();

      this.userService.loggedIn$
        .pipe(untilNgDestroyed(this.destroyRef))
        .subscribe(() => {
          this.setShowMobileDetected();
        });
    }
  }

  private setShowMobileDetected(): void {
    if (this.userService.user) {
      this.showMobileDetected = true;
    } else {
      this.showMobileDetected = false;
    }
  }

  private sendUnloadBeacon(): boolean {
    if (
      !this.windowRef.nativeWindow ||
      !this.windowRef.nativeWindow.navigator ||
      !this.windowRef.nativeWindow.navigator.sendBeacon
    ) {
      return false;
    }

    const userUuid = this.userService.user?.uuid;

    const ongoingUploads =
      getSync(this.store, fromUploadsState.vault.getOngoingUploadsInfo) || [];

    const logData = JSON.stringify({
      length: ongoingUploads.length,
      url:
        this.windowRef.nativeWindow && this.windowRef.nativeWindow.location
          ? this.windowRef.nativeWindow.location.href
          : null,
      videoUploads: ongoingUploads,
    });

    const blob = new Blob(
      [
        JSON.stringify({
          sessionId:
            this.electronAppInfoService?.appInfo?.sessionId ?? undefined,
          desktopVersion:
            this.electronAppInfoService?.appInfo?.version ?? undefined,
          appInstanceId: this.appInstanceId,
          datetime: DateTime.now().toISO(),
          level: ClientLogLevel.Info,
          message: `Page unloaded (user:${userUuid}) (data:${logData})`,
          version: appVersion,
          csrf: this.cookieService.getSync('XSRF-TOKEN'),
        }),
      ],
      {
        type: 'text/plain;charset=UTF-8',
      },
    );

    try {
      return this.windowRef.nativeWindow.navigator.sendBeacon(
        this.apiService.baseUrl + '/app/beacon',
        blob,
      );
    } catch (e) {
      // ignore errors
      return false;
    }
  }
}
