import { createFeatureSelector, createSubStateSelectors } from '@gv/state';
import { createSelector } from '@ngrx/store';

import type { CreditFeatureState } from './state';
import { creditFeatureKey } from './state';

const getState = createFeatureSelector<CreditFeatureState>(creditFeatureKey);

const getBalanceState = createSelector(getState, (state) => state.balance);

export const fromBalance = createSubStateSelectors(getBalanceState, 'balance');

export const getCredits = createSelector(
  fromBalance.getBalance,
  (data) => data?.balance,
);
