import { InjectionToken } from '@angular/core';

import type { Subject } from 'rxjs';

import type { Api } from './api-def';
import type { LambdaApi } from './api-def-lambda';
import type { HttpService } from './type/http-service';

export const API_URL = new InjectionToken<string>('apiUrl');

export const API = new InjectionToken<Api>('api');
export const API_LAMBDA = new InjectionToken<LambdaApi>('lambdaApi');
export const CHECK_OUTAGE = new InjectionToken<Subject<void>>(
  'checkOutagePage',
);

export const HTTP_SERVICE = new InjectionToken<HttpService>('httpService');
