import { Injectable } from '@angular/core';

import type { Retryable } from '@gv/api';
import type { MonoTypeOperatorFunction } from 'rxjs';
import { BehaviorSubject, filter, map, Observable, pairwise } from 'rxjs';

import type { RetryMode } from '../operator';
import { retryOnError } from '../operator';
import { ObjectUtils } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class RequestRetryManagerService {
  private requestsInRetry = new Set<string>();

  private retryingSubject = new BehaviorSubject<boolean>(false);

  retrying$ = this.retryingSubject.asObservable();

  set retrying(retrying: boolean) {
    if (this.retrying !== retrying) {
      this.retryingSubject.next(retrying);
    }
  }

  get retrying(): boolean {
    return this.retryingSubject.getValue();
  }

  isRetrying(requestId: string): boolean {
    return this.requestsInRetry.has(requestId);
  }

  reportRetry(requestId: string): void {
    if (this.requestsInRetry.add(requestId)) {
      this.updateRetrying();
    }
  }

  reportFinished(requestId: string): void {
    if (this.requestsInRetry.delete(requestId)) {
      this.updateRetrying();
    }
  }

  reportFailed(requestId: string): void {
    if (this.requestsInRetry.delete(requestId)) {
      this.updateRetrying();
    }
  }

  /**
   * Predicate are expected to be exclusive across one request, otherwise requestsInRetry may be unstable
   */
  createRetryOnError<T>(
    predicate: (error: any) => boolean,
    waitTime: number,
    maxWaitTime: number,
    limit: number,
    retryMode: RetryMode.NumberOfRetries,
    userUuid$: Observable<string>,
  ): MonoTypeOperatorFunction<T> {
    let wasReported = false;

    return (source: Observable<T>): Observable<T> => {
      return new Observable<T>((observer) => {
        let loggedIn = true;
        const subUser = userUuid$
          .pipe(map((m) => !!m))
          .subscribe((_loggedIn) => (loggedIn = _loggedIn));

        const sub = source
          .pipe(
            retryOnError({
              predicate: (err: unknown) =>
                loggedIn &&
                ObjectUtils.isTypeBasedOnProperty<Retryable, 'rid'>(
                  err,
                  'rid',
                ) &&
                predicate(err), // ensure we do not schedule new retry when user is logged out already
              waitTime: waitTime,
              maxWaitTime: maxWaitTime,
              limit,
              retryMode,
              onRetry: (err) => {
                if (
                  ObjectUtils.isTypeBasedOnProperty<Retryable, 'rid'>(
                    err,
                    'rid',
                  )
                ) {
                  this.reportRetry(err.rid);
                  wasReported = true;
                }
              },
              untilObservable: userUuid$.pipe(
                pairwise(),
                filter(([logged, loggedNow]) => !!logged && !loggedNow),
              ), // cancel scheduled request when user logouts
            }),
          )
          .subscribe({
            next(x): void {
              observer.next(x);
            },
            error: (err): void => {
              if (
                ObjectUtils.isTypeBasedOnProperty<Retryable, 'rid'>(
                  err,
                  'rid',
                ) &&
                wasReported
              ) {
                this.reportFailed(err.rid);
              }
              observer.error(err);
            },
            complete(): void {
              observer.complete();
            },
          });

        return () => {
          sub?.unsubscribe();
          subUser?.unsubscribe();
        };
      });
    };
  }

  private updateRetrying(): void {
    this.retrying = this.requestsInRetry.size > 0;
  }
}
