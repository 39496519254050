import type { MonoTypeOperatorFunction } from 'rxjs';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function delayImmediate<T>(): MonoTypeOperatorFunction<T> {
  return mergeMap((val) => {
    return new Observable<T>((obs) => {
      const timer = setTimeout(() => {
        obs.next(val);
        obs.complete();
      }, 0);

      return () => {
        clearTimeout(timer);
      };
    });
  });
}
