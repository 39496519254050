import type { HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import type { ApiStatusModel, LambdaApi } from '@gv/api';
import { createApi, generateBaseUrl, lambdaApiDefinition } from '@gv/api';
import { WindowRefService } from '@gv/ui/core';
import type { RawAxiosRequestConfig } from 'axios';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { DebugModeService } from '../helper/debug-mode.service';
import type { RequestConfig } from '../http/axios/http';
import { HttpServiceHandler } from '../http/axios/http';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private httpServiceHandler = inject(HttpServiceHandler);
  private httpClient = inject(HttpService);
  private debugService = inject(DebugModeService);
  private windowRef = inject(WindowRefService);
  baseUrl = environment.api.useStaticBaseUrl
    ? environment.api.staticBaseUrl
    : generateBaseUrl(this.windowRef.nativeWindow.location);

  lambdaBaseUrl = environment.api.useStaticBaseUrl
    ? environment.api.lambdaStaticBaseUrl
    : generateBaseUrl(this.windowRef.nativeWindow.location, false) + '/lapi';

  api = createApi(this.httpClient, environment.test);
  apiLambda = createApi<LambdaApi>(
    this.httpClient,
    environment.test,
    lambdaApiDefinition,
    this.lambdaBaseUrl,
  );

  constructor() {
    this.debugService.registerStatic('Api', this.api);
  }

  withDirect<T>(cb: () => T): T {
    return this.httpServiceHandler.withDirect(cb);
  }

  withConfig<T>(config: RawAxiosRequestConfig, cb: () => T): T {
    return this.httpServiceHandler.withConfig(config, cb);
  }

  isApiRequest(request: HttpRequest<any> | RequestConfig<any>): boolean {
    return (
      request.url.indexOf(this.baseUrl) === 0 || !request.url.startsWith(`http`)
    );
  }

  getApiStatus(): Observable<ApiStatusModel> {
    return this.httpClient
      .get<ApiStatusModel>(this.baseUrl, undefined)
      .pipe(map(HttpService.mapData));
  }
}
