import { Injectable, inject } from '@angular/core';
import type { ActivatedRouteSnapshot } from '@angular/router';

import { EMPTY_STATE, StoreInject } from '@gv/state';
import { Actions, ofType } from '@ngrx/effects';
import type { Observable } from 'rxjs';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalUploadStateService } from '@gv/upload/ui';
import { VaultDialogActions } from '@gv/upload/state';

@Injectable({
  providedIn: 'root',
})
export class PreventActivationDuringUploadGuard {
  private store = inject(StoreInject(EMPTY_STATE));
  private actions$ = inject<Actions>(Actions);
  private globalUploadStateService = inject(GlobalUploadStateService);
  static readonly forceActivationQueryParameterName: string = '_fa';

  canActivate(
    route: ActivatedRouteSnapshot,
  ): boolean | Observable<boolean> | Promise<boolean> {
    this.globalUploadStateService.forceUpdate();

    if (
      !this.isActivationForced(route) &&
      this.globalUploadStateService.anyUploadNeedsUserAttention
    ) {
      const promise: Promise<boolean> = firstValueFrom(
        this.actions$.pipe(
          ofType(VaultDialogActions.stopAllUploadsCrossTabDialog.closed),
          map((action) => action.data),
        ),
      );

      this.store.dispatch(
        VaultDialogActions.stopAllUploadsCrossTabDialog.open({ data: {} }),
      );

      return promise;
    }
    return true;
  }

  private isActivationForced(route: ActivatedRouteSnapshot): boolean {
    return !!route.queryParams[
      PreventActivationDuringUploadGuard.forceActivationQueryParameterName
    ];
  }
}
