import { Injectable, inject } from '@angular/core';

import { COOKIE_SERVICE } from '@gv/ui/utils';

import { APP_CONFIG } from '../../../entity/token/app.config';

@Injectable({
  providedIn: 'root',
})
export class CookieLawService {
  private appConfig = inject(APP_CONFIG);
  private cookieService = inject(COOKIE_SERVICE);

  wasConfirmed(): Promise<boolean> {
    return this.cookieService.check(this.appConfig.cookies.lawDisclaimer);
  }

  confirm(): void {
    this.cookieService.set(
      this.appConfig.cookies.lawDisclaimer,
      '1',
      undefined,
      '/',
    );
  }
}
