import { inject, Injectable } from '@angular/core';

import { API, ApiErrorResponse } from '@gv/api';
import { ErrorsActions } from '@gv/error';
import { createFlowEffect, ofActions, StoreInject } from '@gv/state';
import { WindowRefService } from '@gv/ui/core';
import { UserActions, USER_CONTEXT } from '@gv/user';
import { copyTokenToDomain, delayedConcatMap, generateUuid } from '@gv/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import type { Action } from '@ngrx/store';
import axios from 'axios';
import type { Observable } from 'rxjs';
import { from, concat, EMPTY, of, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  filter,
  finalize,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';

import { BillingActions, fromBillingState } from '..';
import * as DetailActions from '../detail/actions';
import { BILLING_FEATURE_STATE } from '../state';

@Injectable()
export class OrganizationEffects {
  private actions$ = inject<Actions>(Actions);
  private api = inject(API);
  private userContext = inject(USER_CONTEXT);
  private windowRef = inject(WindowRefService);
  private store = inject(StoreInject(BILLING_FEATURE_STATE));

  fetchOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.organization.fetch.init),
      debounceTime(0),
      delayedConcatMap(() => [
        this.store.select(
          fromBillingState.fromOrganization.isOrganizationLoaded,
        ),
        this.store.select(
          fromBillingState.fromOrganization.isOrganizationDirty,
        ),
        this.store.select(
          fromBillingState.fromOrganization.isOrganizationLoading,
        ),
      ]),
      filter(
        ([action, loaded, dirty, loading]) =>
          !action.skipWhenLoaded || ((!loaded || dirty) && !loading),
      ),
      switchMap(() => {
        return concat(
          of(BillingActions.organization.fetch.started()),
          this.api.getOrganization().pipe(
            mergeMap((response) => {
              return of(
                BillingActions.organization.fetch.completed({
                  dtSent: response.dtSent,
                  data: response.data!,
                  sortable: undefined,
                }),
              );
            }),
            catchError((error: unknown) => {
              return of(
                BillingActions.organization.fetch.error({
                  error,
                }),
              );
            }),
            takeUntil(
              this.actions$.pipe(
                ofType(BillingActions.organization.fetch.cancel),
              ),
            ),
          ),
        );
      }),
    ),
  );

  createOrganizationFlow$ = createFlowEffect(({ action }) => {
    return this.api.createOrganization(action.data).pipe(
      mergeMap((response): Observable<Action> => {
        return of(
          // UserActions.refresh.init({ debounce: false }),
          BillingActions.organization.fetch.completed({
            data: response.data!,
            dtSent: response.dtSent,
            sortable: undefined,
          }),
          BillingActions.subscription.refresh({}),
        );
      }),
      catchError((error: unknown) =>
        concat(
          of(
            ErrorsActions.generic({
              action,
              error,
              logToSentry: true,
              log: true,
              snackbarMessage: {
                message:
                  (error instanceof ApiErrorResponse &&
                    error?.apiMessage?.message) ||
                  $localize`:@@effects.failed-to-create:Failed to create organization`,
                config: { duration: 3000 },
              },
            }),
          ),
          throwError(() => error),
        ),
      ),
    );
  }, BillingActions.organization.createOrganizationFlow);

  editOrganizationFlow$ = createFlowEffect(({ action }) => {
    return this.api.editOrganization(action.data?.organization).pipe(
      delayedConcatMap(() => [
        this.store.select(fromBillingState.temporaryTheme),
      ]),
      mergeMap(([response, theme]): Observable<Action> => {
        return concat(
          ofActions(
            BillingActions.organization.fetch.completed({
              data: response.data!,
              dtSent: response.dtSent,
              sortable: undefined,
            }),
            UserActions.refresh.init({ debounce: false }),
          ),
          theme ? of(BillingActions.organization.resetTemporaryTheme()) : EMPTY,
          !!response.data?.subdomain &&
            (!!action.data?.squareLogo || !!action.data?.mainLogo)
            ? of(
                BillingActions.organization.uploadOrganizationLogoFlow.init({
                  data: {
                    squareLogo: action.data?.squareLogo,
                    mainLogo: action.data?.mainLogo,
                  },
                }),
              )
            : EMPTY,
        );
      }),
      catchError((error: unknown) =>
        concat(
          of(
            ErrorsActions.generic({
              action,
              error,
              logToSentry: true,
              log: true,
              snackbarMessage: {
                message:
                  (error instanceof ApiErrorResponse &&
                    error?.apiMessage?.message) ||
                  $localize`:@@effects.failed-to-update:Failed to update organization`,
                config: { duration: 3000 },
              },
            }),
          ),
          throwError(() => error),
        ),
      ),
    );
  }, BillingActions.organization.editOrganizationFlow);

  organizationReferralInvitationFlow$ = createFlowEffect(({ action }) => {
    return this.api.inviteUserUsingReferral(action.data).pipe(
      mergeMap((): Observable<Action> => EMPTY),
      catchError((error: unknown) =>
        concat(
          of(
            ErrorsActions.generic({
              action,
              error,
              logToSentry: true,
              log: true,
              snackbarMessage: {
                message:
                  (error instanceof ApiErrorResponse &&
                    error?.apiMessage?.message) ||
                  $localize`:@@effects.failed-to-send-invitation:Failed to send invitation`,
                config: { duration: 3000 },
              },
            }),
          ),
          throwError(() => error),
        ),
      ),
    );
  }, BillingActions.organization.organizationReferralInvitationFlow);

  createOrganizationUserInvitationFlow$ = createFlowEffect(({ action }) => {
    return this.api.createOrganizationUserInvitation(action.data).pipe(
      mergeMap((response): Observable<Action> => {
        return of(
          BillingActions.organization.refresh({ dtSent: response.dtSent }),
        );
      }),
      catchError((error: unknown) =>
        concat(
          of(
            ErrorsActions.generic({
              action,
              error,
              logToSentry: true,
              log: true,
              snackbarMessage: {
                message:
                  (error instanceof ApiErrorResponse &&
                    error?.apiMessage?.message) ||
                  $localize`:@@effects.failed-to-invite:Failed to invite new member to organization`,
                config: { duration: 3000 },
              },
            }),
          ),
          throwError(() => error),
        ),
      ),
    );
  }, BillingActions.organization.createOrganizationUserInvitationFlow);

  editOrganizationUserFlow$ = createFlowEffect(({ action }) => {
    return this.api
      .editOrganizationUser(action.data.uuid, action.data.data)
      .pipe(
        mergeMap((response): Observable<Action> => {
          return of(
            BillingActions.organization.refresh({ dtSent: response.dtSent }),
            DetailActions.detail.refresh({}),
            UserActions.refresh.init({ debounce: false }),
            BillingActions.subscription.refresh({}),
          );
        }),
        catchError((error: unknown) =>
          concat(
            of(
              ErrorsActions.generic({
                action,
                error,
                logToSentry: true,
                log: true,
                snackbarMessage: {
                  message:
                    (error instanceof ApiErrorResponse &&
                      error?.apiMessage?.message) ||
                    $localize`:@@effects.failed-to-update-member:Failed to update organization member`,
                  config: { duration: 3000 },
                },
              }),
            ),
            throwError(() => error),
          ),
        ),
      );
  }, BillingActions.organization.editOrganizationUserFlow);

  acceptInvitation$ = createFlowEffect(({ action }) => {
    return this.api
      .organizationInvitationAction(
        {
          accept: action.data.accept,
          request: action.data.request,
        },
        { headers: { 'bypass-redirect': '1' } },
      )
      .pipe(
        delayedConcatMap(() => [this.userContext.token$]),
        concatMap(([response, token]) => {
          if (response.data!.url) {
            return from(
              copyTokenToDomain(
                this.windowRef.nativeWindow,
                response.data!.url,
                token,
                () => {
                  this.store.dispatch(UserActions.requestLogout());
                  return Promise.resolve();
                },
              ),
            ).pipe(map(() => response));
          }
          return of(response);
        }),
        mergeMap((response): Observable<Action> => {
          return of(
            BillingActions.organization.refresh({ dtSent: response.dtSent }),
            DetailActions.detail.refresh({}),
            UserActions.refresh.init({ debounce: false }),
          );
        }),
        catchError((error: unknown) =>
          concat(
            of(
              ErrorsActions.generic({
                action,
                error,
                logToSentry: true,
                log: true,
                snackbarMessage: {
                  message:
                    (error instanceof ApiErrorResponse &&
                      error?.apiMessage?.message) ||
                    $localize`:@@effects.failed-to-update-member:Failed to update organization member`,
                  config: { duration: 3000 },
                },
              }),
            ),
            throwError(() => error),
          ),
        ),
      );
  }, BillingActions.organization.acceptInvitation);

  deleteOrganizationUserFlow$ = createFlowEffect(({ action }) => {
    return this.api.deleteOrganizationUser(action.data.uuid).pipe(
      mergeMap((response): Observable<Action> => {
        return of(
          BillingActions.organization.refresh({ dtSent: response.dtSent }),
        );
      }),
      catchError((error: unknown) =>
        concat(
          of(
            ErrorsActions.generic({
              action,
              error,
              logToSentry: true,
              log: true,
              snackbarMessage: {
                message:
                  (error instanceof ApiErrorResponse &&
                    error?.apiMessage?.message) ||
                  $localize`:@@effects.failed-to-delete-member:Failed to delete organization member`,
                config: { duration: 3000 },
              },
            }),
          ),
          throwError(() => error),
        ),
      ),
    );
  }, BillingActions.organization.deleteOrganizationUser);

  createAppThemeFlow$ = createFlowEffect(({ action }) => {
    return of(
      action.data.slugSet
        ? BillingActions.fetchAppThemes.init({ skipWhenLoaded: false })
        : BillingActions.organization.createTemporaryTheme({
            data: {
              uuid: generateUuid(),
              name: action.data.name,
              color: action.data.color,
              mode: action.data.mode,
            },
          }),
    );
  }, BillingActions.organization.createThemeFlow);

  uploadOrganizationLogoFlow$ = createFlowEffect(({ action }) => {
    return this.api.presignOrganizationLogos().pipe(
      delayedConcatMap(() => [
        this.store.select(fromBillingState.fromOrganization.getOrganization),
      ]),
      mergeMap(([response, organization]) => {
        const client = new axios.Axios({});
        if (response.data?.mainLogo && action.data.mainLogo) {
          void client.put(response.data.mainLogo, action.data.mainLogo, {
            headers: {
              'x-amz-meta-uuid': organization.uuid,
              'Content-Type': 'image/png',
            },
          });
        }

        if (response.data?.squareLogo && action.data.squareLogo) {
          void client.put(response.data.squareLogo, action.data?.squareLogo, {
            headers: {
              'x-amz-meta-uuid': organization.uuid,
              'Content-Type': 'image/png',
            },
          });
        }

        return concat(
          of(BillingActions.organization.refresh({ dtSent: response.dtSent })),
          of(response.data),
        );
      }),
      catchError((error: unknown) =>
        concat(
          of(
            ErrorsActions.generic({
              action,
              error,
              logToSentry: true,
              log: true,
              snackbarMessage: {
                message:
                  (error instanceof ApiErrorResponse &&
                    error?.apiMessage?.message) ||
                  $localize`:@@effects.failed-to-upload-logo:Failed to upload organization logo`,
                config: { duration: 3000 },
              },
            }),
          ),
          throwError(() => error),
        ),
      ),
    );
  }, BillingActions.organization.uploadOrganizationLogoFlow);

  refreshOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.organization.refresh),
      map(() => {
        return BillingActions.organization.fetch.init({
          skipWhenLoaded: false,
        });
      }),
    ),
  );

  fetchAppThemes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.fetchAppThemes.init),
      switchMap((action) =>
        action.skipWhenLoaded
          ? this.store
              .select(fromBillingState.fromAppThemes.isAppThemesLoaded)
              .pipe(
                take(1),
                mergeMap((loaded) => (loaded ? EMPTY : of(action))),
              )
          : of(action),
      ),
      switchMap((action) => {
        return this.api.getAppThemes().pipe(
          map((response) => {
            return BillingActions.fetchAppThemes.succeeded({
              appThemes: response.data!,
            });
          }),
          catchError((error: unknown) =>
            of(
              BillingActions.fetchAppThemes.failed(),

              ErrorsActions.generic({
                action,
                error,
                logToSentry: true,
                log: true,
                snackbarMessage: {
                  message:
                    (error instanceof ApiErrorResponse &&
                      error?.apiMessage?.message) ||
                    $localize`:@@effects.failed-to-fetch-themes:Failed to fetch app themes`,
                  config: { duration: 3000 },
                },
              }),
            ),
          ),
          finalize(() => {
            this.store.dispatch(BillingActions.fetchAppThemes.completed());
          }),
        );
      }),
    ),
  );
}
