import { inject, Injectable, InjectionToken } from '@angular/core';

import { API_LAMBDA } from '@gv/api';
import { ElectronRefService, ElectronAppInfoService } from '@gv/desktop/core';
import { logStore } from '@gv/logger';
import {
  closeDialogOnFlowSuccess,
  createFlowEffect,
  createLazyDialogEffect,
  EMPTY_STATE,
  getSync,
  StoreInject,
} from '@gv/state';
import { SnackBarActions } from '@gv/ui/toaster';
import { Actions, createEffect } from '@ngrx/effects';
import type { Action } from '@ngrx/store';
import mousetrap from 'mousetrap';
import axios from 'axios';
import {
  Observable,
  combineLatest,
  concat,
  concatMap,
  exhaustMap,
  finalize,
  forkJoin,
  from,
  ignoreElements,
  map,
  merge,
  of,
  shareReplay,
  switchMap,
  take,
  Subject,
  tap,
} from 'rxjs';
import { gzip } from 'fflate';
import { delayedConcatMap } from '@gv/utils';
import { USER_CONTEXT } from '@gv/user';

import { reportErrorDialog, reportErrorFlow } from './actions';
import { ScrollStrategy } from './scroll-strategy';

const client = new axios.Axios({});

export function safeJsonStringify(object: any): string {
  let cache: any[] | undefined = [];
  const res = JSON.stringify(object, (key, value) => {
    if (key === 'electronRef') {
      return;
    }
    if (typeof value === 'object' && value !== null) {
      if (cache!.includes(value)) {
        return;
      }
      cache!.push(value);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return value;
  });
  cache = undefined;
  return res;
}

export function errorReportLog(
  url: string,
  electronRef: ElectronRefService,
  reportProgress = true,
): Observable<{ loaded: number; total: number }> {
  const body: Observable<Uint8Array | Buffer> = from(
    electronRef.api.app.getLogFile(),
  ).pipe(
    switchMap(
      (logFile) =>
        new Observable<Uint8Array>((sub) => {
          const cancel = gzip(
            new TextEncoder().encode(logFile),
            {},
            (err, res) => {
              if (err) {
                sub.error(err);
                return;
              }

              sub.next(res);
              sub.complete();
            },
          );

          return () => {
            cancel();
          };
        }),
    ),
  );
  return body.pipe(
    switchMap(
      (data): Observable<{ loaded: number; total: number }> =>
        new Observable<{ loaded: number; total: number }>((sub) => {
          return from(
            axios.put(url, data, {
              onUploadProgress(progress) {
                if (!reportProgress) {
                  return;
                }
                sub.next({
                  loaded: progress.loaded,
                  total: progress.total!,
                });
              },
              headers:
                data instanceof Uint8Array
                  ? {
                      'content-encoding': 'gzip',
                      'content-type': 'application/json',
                    }
                  : undefined,
            }),
          ).subscribe({
            next: () => {
              sub.complete();
            },
            error: sub.error.bind(sub),
            complete: sub.complete.bind(sub),
          });
        }),
    ),
  );
}

export function errorReportUpload(
  url: string,
  _data: string | Buffer,
  reportProgress = true,
): Observable<{ loaded: number; total: number }> {
  const body: Observable<Uint8Array | Buffer> =
    _data instanceof Buffer
      ? of(_data)
      : new Observable<Uint8Array>((sub) => {
          const cancel = gzip(
            new TextEncoder().encode(_data),
            {},
            (err, res) => {
              if (err) {
                sub.error(err);
                return;
              }

              sub.next(res);
              sub.complete();
            },
          );

          return () => {
            cancel();
          };
        });
  return body.pipe(
    switchMap(
      (data): Observable<{ loaded: number; total: number }> =>
        new Observable<{ loaded: number; total: number }>((sub) => {
          return from(
            axios.put(url, data, {
              onUploadProgress(progress) {
                if (!reportProgress) {
                  return;
                }
                sub.next({
                  loaded: progress.loaded,
                  total: progress.total!,
                });
              },
              headers:
                data instanceof Uint8Array
                  ? {
                      'content-encoding': 'gzip',
                      'content-type': 'application/json',
                    }
                  : undefined,
            }),
          ).subscribe({
            next: () => {
              sub.complete();
            },
            error: sub.error.bind(sub),
            complete: sub.complete.bind(sub),
          });
        }),
    ),
  );
}

@Injectable()
export class ReportErrorDialogEffect {
  private actions$ = inject<Actions>(Actions);
  private api = inject(API_LAMBDA);
  private userContext = inject(USER_CONTEXT);
  private store = inject(StoreInject(EMPTY_STATE));
  private metaFactory = inject(REPORT_ERROR_META_FACTORY);
  private electronAppInfoService = inject(ElectronAppInfoService, {
    optional: true,
  });
  private electronRef = inject(ElectronRefService, {
    optional: true,
  });
  reportErrorDialog$ = createLazyDialogEffect(
    ({ actions$, openDialog, dialogActionsEmitter }) =>
      actions$.pipe(
        exhaustMap((action) => {
          return forkJoin([
            of(getSync(this.store, (state) => state)),
            of(Array.from(logStore())),
          ]).pipe(
            delayedConcatMap(() => [this.userContext.decodedToken$]),
            exhaustMap(([[state, logs], token]): Observable<Action> => {
              const apiObj = this.api.reportError().pipe(shareReplay(1));

              const role = token?.role;
              const uploadProgress = new Subject<number>();
              const dialogRef$ = openDialog(action, {
                data: {
                  admin: ['GORDON', 'ADMIN'].includes(role),
                  uuid$: apiObj.pipe(map((m) => m.data!.uuid)),
                  uploadProgress$: uploadProgress,
                },
                autoFocus: false,
                disableClose: true,
                width: '1000px',
                panelClass: ['full-height-dialog'],
                hasBackdrop: false,
                scrollStrategy: new ScrollStrategy(),
              });

              const meta = {
                ...this.metaFactory(),
                location: window.location.href,
                sessionId: this.electronAppInfoService?.appInfo?.sessionId,
                desktopVersion: this.electronAppInfoService?.appInfo?.version,
              };

              const onSubmit$ = merge(
                apiObj.pipe(
                  concatMap((response) => {
                    return combineLatest([
                      errorReportUpload(
                        response.data!.urls[0],
                        JSON.stringify(meta),
                      ),
                      errorReportUpload(
                        response.data!.urls[2],
                        safeJsonStringify(state),
                      ),
                      errorReportUpload(
                        response.data!.urls[4],
                        JSON.stringify(logs),
                      ),
                      ...(this.electronRef
                        ? [
                            errorReportLog(
                              response.data!.urls[5],
                              this.electronRef,
                            ),
                          ]
                        : []),
                    ]).pipe(
                      map((args) => {
                        return (
                          args.reduce((acc, t) => acc + t.loaded, 0) /
                          args.reduce((acc, t) => acc + t.total, 0)
                        );
                      }),
                      tap((progress: number) => uploadProgress.next(progress)),
                      ignoreElements(),
                    );
                  }),
                ),
                dialogRef$.pipe(
                  switchMap(
                    (dialogRef): Observable<Action> =>
                      dialogRef.componentInstance.onSubmit$.pipe(
                        concatMap((data) => {
                          dialogRef.componentInstance.setUpdateInProgress(true);
                          return combineLatest([of(data), apiObj]).pipe(
                            take(1),
                          );
                        }),
                        exhaustMap(([data, response]): Observable<Action> => {
                          const flowAction = reportErrorFlow.init({
                            data: { uuid: response.data!.uuid },
                          });

                          const flowFinished$ = reportErrorFlow
                            .onFinished$(flowAction, this.actions$)
                            .pipe(
                              closeDialogOnFlowSuccess(dialogRef),
                              ignoreElements(),
                            );

                          return merge(
                            flowFinished$,
                            concat(
                              from(
                                client.put(
                                  response.data!.urls[1],
                                  JSON.stringify(data),
                                ),
                              ).pipe(ignoreElements()),
                              of(flowAction),
                            ),
                          ).pipe(
                            finalize(() => {
                              dialogRef.componentInstance.setUpdateInProgress(
                                false,
                              );
                            }),
                          );
                        }),
                      ),
                  ),
                ),
              );

              return dialogActionsEmitter(
                { action, dialogRef: dialogRef$ },
                onSubmit$,
              );
            }),
          );
        }),
      ),
    reportErrorDialog,
    {
      module: {
        component: () =>
          import('./report-error.component').then(
            (m) => m.ReportErrorDialogComponent,
          ),
      },
    },
  );

  reportErrorFlow$ = createFlowEffect(({ action }): Observable<Action> => {
    return this.api.reportErrorComplete(action.data.uuid).pipe(
      map(() =>
        SnackBarActions.show({
          message: $localize`:@@dialog.:The report has been successfully sent`,
          config: {
            duration: 7000,
          },
        }),
      ),
    );
  }, reportErrorFlow);

  openReportErro$ = createEffect(
    () =>
      new Observable<Action>((sub) => {
        const keys = 'shift+alt+e';
        const action = () => {
          sub.next(reportErrorDialog.open());
        };
        mousetrap.bind(keys, action);

        return () => {
          mousetrap.unbind(keys);
        };
      }),
  );
}

export const REPORT_ERROR_META_FACTORY = new InjectionToken<
  () => Record<string, unknown>
>('reportErrorMetaFactory');
