import type { Selector } from '@ngrx/store';
import { take } from 'rxjs/operators';

import type { Store } from './store';

// subscribing to store is synchronous
// https://github.com/ngrx/platform/issues/227#issuecomment-431682349

export function getSync<State, Result>(
  store: Store<State>,
  selector: Selector<State, Result>,
): Result {
  let value!: Result;

  store
    .select(selector)
    .pipe(take(1))
    .subscribe((val) => {
      value = val;
    });

  return value;
}
