import type { ObservableInput, OperatorFunction } from 'rxjs';
import { defer, EMPTY, Observable, of } from 'rxjs';
import { concatMap, concatWith, ignoreElements, tap } from 'rxjs/operators';

/**
 * @see https://github.com/ReactiveX/rxjs/issues/1777#issuecomment-247465493
 * based on ^^ implementation
 */
export function exhaustMapWithTrailing<T, R>(
  project: (value: T, index: number) => ObservableInput<R>,
): OperatorFunction<T, R> {
  return (source): Observable<R> => {
    return new Observable<R>((ob) => {
      let count = 0;

      return source
        .pipe(
          tap(() => (count += 1)),
          concatMap((e, i) =>
            defer(() => (count === 1 ? project(e, i) : EMPTY)).pipe(
              concatWith(
                of(0).pipe(
                  tap(() => (count -= 1)),
                  ignoreElements(),
                ),
              ),
            ),
          ),
        )
        .subscribe(ob);
    });
  };
}
