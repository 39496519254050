import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  booleanAttribute,
} from '@angular/core';

import {
  DialogHelpDirective,
  DialogWarningDirective,
} from '../../dialog-actions.component';

@Component({
  template: '',
  standalone: true,
  selector: 'gv-base-submit-actions',
})
export class BaseSubmitActions {
  @Input({ transform: booleanAttribute })
  submitInProgress = false;

  @Input({ transform: booleanAttribute })
  disabled = false;

  @Input()
  color: 'warn' | 'primary' | 'neutral' = 'primary';

  @Input()
  disabledTooltip: string | undefined;

  @Input()
  dialogClose = false;

  @Input()
  disableClose = false;

  @Output()
  btnClick = new EventEmitter<void>();

  @Output()
  close = new EventEmitter<void>();

  @ContentChild(DialogHelpDirective, { static: false, descendants: true })
  protected help: DialogHelpDirective | undefined;

  @ContentChild(DialogWarningDirective, { static: false, descendants: true })
  protected warning: DialogWarningDirective | undefined;

  @Input()
  helpTpl: DialogHelpDirective | undefined;

  @Input()
  warningTpl: DialogWarningDirective | undefined;

  @Input()
  btnClasses: string[];
}
