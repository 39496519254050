export type ImmutableObject<T> = {
  readonly [P in keyof T]: Immutable<T[P]>;
};
export type ImmutableArray<T> = ReadonlyArray<Immutable<T>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Immutable<T> = T extends any[]
  ? ImmutableArray<T[number]>
  : // eslint-disable-next-line @typescript-eslint/ban-types
    T extends object
    ? ImmutableObject<T>
    : T;

export function asImmutable<T>(val: T): Immutable<T> {
  return val as Immutable<T>;
}
