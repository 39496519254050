import { Injectable } from '@angular/core';

import { BrowserUtils } from '../util/browser-utils';

@Injectable({
  providedIn: 'root',
})
export class BrowserTypeService {
  get isElectron(): boolean {
    return BrowserUtils.isElectron;
  }

  get isSupported(): boolean {
    return this.isElectron || BrowserUtils.isSupported;
  }
}
