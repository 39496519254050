import type { ActionCreator, Creator } from '@ngrx/store';

export function isActionOfType<
  AC extends ActionCreator<string, Creator>,
  T1 extends AC,
  V = ReturnType<Extract<T1, AC>>,
>(action: any, t1: T1): action is V {
  if (!action || typeof action === 'string') {
    return false;
  }
  if (typeof t1 === 'string') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return t1 === action.type;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return action.type === t1.type;
}
