import { Injectable, Injector, inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

import { appFullVersion } from '@gv/constant';
import { ElectronRefService } from '@gv/desktop/core';
import {
  delayedConcatMap,
  shallowDistinctUntilChanged,
  shallowDistinctUntilKeysChanged,
  LocalizationUtils,
} from '@gv/utils';
import { createEffect } from '@ngrx/effects';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CookieService } from 'ngx-cookie-service';
import type { Observable } from 'rxjs';
import {
  combineLatest,
  concat,
  EMPTY,
  from,
  interval,
  merge,
  of,
  race,
  timer,
} from 'rxjs';
import {
  concatMap,
  delay,
  delayWhen,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  startWith,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import compareSemVer from 'semver/functions/compare';
import semverDiff from 'semver/functions/diff';
import isValidSemVer from 'semver/functions/valid';
import { APP_UPDATE_CHECKER_SERVICE } from '@gv/ui/utils';

import { UserService } from '../../service/application/user/user.service';
import { LANG_REDIRECT_IN_PROGRESS } from '../../service/helper/localization.service';
import { APP_CONFIG } from './../../entity/token/app.config';
import { DialogActions } from './../action/dialog.actions';

@Injectable()
export class UtilEffects {
  private appConfig = inject(APP_CONFIG);
  private userService = inject(UserService);
  private updateService = inject(APP_UPDATE_CHECKER_SERVICE);
  private cookieService = inject(CookieService);
  private injector = inject(Injector);
  private electronRef = inject(ElectronRefService, { optional: true });
  private redirectInProgress = inject(LANG_REDIRECT_IN_PROGRESS);
  static readonly checkInterval = 5 * 60 * 1000;

  readonly lastUpdate: {
    version: string | undefined;
    dt: Date;
  } = {
    version: undefined,
    dt: undefined,
  };

  private version$ = of<string | undefined>(
    appFullVersion === 'GV_FULL_VERSION' ? undefined : appFullVersion,
  );

  private apiUpdateRequired$ = this.userService.user$.pipe(
    map((user) => (user ? user.currentClientVersion : undefined)),
    delayedConcatMap(() => [this.version$]),
    shallowDistinctUntilChanged(),
    filter(
      ([version, appVersion]) =>
        !!version &&
        !!isValidSemVer(version, true) &&
        !!appVersion &&
        !!isValidSemVer(appVersion, true),
    ),
    filter(([version, appVersion]) => {
      return compareSemVer(version, appVersion.split('-')[0]) >= 1;
    }),
    map(([version]) => version),
  );

  private updateTimeInterval$: Observable<void> = concat(
    race(
      of(true).pipe(delay(this.appConfig.appUpdate.checkDelay)),
      this.apiUpdateRequired$.pipe(map(() => false)),
    ).pipe(
      take(1),
      filter((v) => v),
    ),
    this.apiUpdateRequired$.pipe(
      startWith(''),
      switchMap(() => interval(this.appConfig.appUpdate.checkInterval)),
    ),
  ).pipe(map(() => undefined));

  updateRequired$ = createEffect(() =>
    merge(this.updateTimeInterval$, this.apiUpdateRequired$).pipe(
      concatMap((version) => {
        const now = new Date();
        const wasCheckedRecently =
          version &&
          this.lastUpdate.version === version &&
          this.lastUpdate.dt &&
          this.lastUpdate.dt.getTime() + 60 * 60 * 4 * 1000 > now.getTime();

        if (wasCheckedRecently) {
          return EMPTY;
        }

        if (version) {
          this.lastUpdate.version = version;
          this.lastUpdate.dt = now;
        }

        return of(
          DialogActions.updateAppDialog.open({
            data: { triggeredByApi: !!version },
          }),
        );
      }),
      switchMap((action) =>
        timer(0, UtilEffects.checkInterval).pipe(
          switchMap(() =>
            combineLatest([
              from(
                this.updateService.checkForUpdates(action.data.triggeredByApi),
              ),
              toObservable(this.updateService.updateAvailableS, {
                injector: this.injector,
              }),
            ]).pipe(
              delayedConcatMap(() => [this.version$]),
              filter(([[, updateAvailable], appVersion]) => {
                const data = this.updateService.dataS();
                if (!updateAvailable || (data && data.version === appVersion)) {
                  return false;
                }

                if (
                  !data ||
                  data.forceUpdate ||
                  !isValidSemVer(data.version, true) ||
                  !isValidSemVer(appVersion, true)
                ) {
                  return true;
                }

                if (
                  ['major', 'minor', 'premajor', 'preminor'].includes(
                    semverDiff(data.version, appVersion),
                  )
                ) {
                  return true;
                }

                return false;
              }),
            ),
          ),
          take(1),
          withLatestFrom(of(action)),
        ),
      ),
      map(([, action]) => action),
    ),
  );

  languageMismatchFound$ = createEffect(() =>
    this.userService.user$.pipe(
      shallowDistinctUntilKeysChanged(['uuid', 'language']),
      mergeMap((user) => {
        return user?.language
          ? of(user.language)
          : this.electronRef
            ? from(
                this.electronRef.api.app
                  .getLocale()
                  .then((lang) => lang.split('-')[0]),
              )
            : of(this.cookieService.get(this.appConfig.cookies.language));
      }),
      delayWhen(() => this.redirectInProgress.pipe(filter((f) => !f))),
      concatMap((cookieLanguage) => {
        const appLanguage$ = this.electronRef
          ? from(
              this.electronRef.api.app
                .getLocale()
                .then((lang) => lang.split('-')[0]),
            )
          : of(LocalizationUtils.getCurrentUrlLanguage());

        return appLanguage$.pipe(
          map((appLanguage) => [cookieLanguage, appLanguage] as const),
        );
      }),
      distinctUntilChanged(),
      concatMap(([cookieLanguage, urlLanguagePrefix]) => {
        const cookieUrlPrefix = this.appConfig.languages.find(
          (language) => language.shortcutApi === cookieLanguage,
        )?.pathPrefix;

        const isUrlPrefixCorrect =
          urlLanguagePrefix &&
          this.appConfig.languages.find(
            (language) => language.pathPrefix === urlLanguagePrefix,
          );

        if (
          isUrlPrefixCorrect &&
          cookieUrlPrefix &&
          cookieUrlPrefix !== urlLanguagePrefix &&
          this.userService.user &&
          !document.location.href.includes('sign-up/verify-account')
        ) {
          return of(
            DialogActions.languageMismatchDialog.open({
              data: {
                urlPrefix: urlLanguagePrefix,
                cookiePrefix: cookieUrlPrefix,
              },
            }),
          );
        }
        return EMPTY;
      }),
    ),
  );
}
