import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { StoreInject } from '@gv/state';
import {
  detail,
  DETAIL_FEATURE_STATE,
  DetailStateModule,
  getTotalSpace,
  getUsedSpace,
} from '@gv/ui/billing';
import { FileSizeUnit, toBytes } from '@gv/utils';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  BasicDialogButtonComponent,
  SimpleDialogModule,
  SubmittableDialogComponent,
} from '@gv/ui/dialog';
import { FormatBytesModule } from '@gv/ui/utils';

import type { VaultSizeExceededDialogDataModel } from '../../../../entity/model/dialog/vault-size-exceeded-dialog-data-model';

@Component({
  standalone: true,
  imports: [
    SimpleDialogModule,
    FormatBytesModule,
    DetailStateModule,
    BasicDialogButtonComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-vault-size-exceeded-dialog',
  template: `
    <gv-simple-dialog [text]="title">
      <ng-container *gvDialogContent>
        @if (!data.uploadStopped) {
          <p i18n="@@vault-size-exceeded-dialog.exceed-message">
            You are uploading
            <strong>{{ data.uploadSize | formatBytes }}</strong>
            of videos to Vault but you have only
            <strong>{{ data.freeSpace | formatBytes }}</strong>
            free space remaining in your Vault.
          </p>
          <p i18n="@@vault-size-exceeded-dialog.free-up">
            Free up your space or
            <strong
              class="link text-color-primary font-bold"
              (click)="upgradePlan()"
            >
              buy additional storage</strong
            >. If you do not buy additional storage and exceed the capacity, you
            will still have access to your uploaded videos, but you won't be
            able to upload any other video to Vault.
          </p>
        }

        @if (data.uploadStopped) {
          <p i18n="@@vault-size-exceeded-dialog.exceed-message2">
            <strong>Files cannot be uploaded.</strong> Total upload size is
            <strong>{{ data.uploadSize | formatBytes }}</strong>
            but you have only
            <strong>{{ data.freeSpace | formatBytes }}</strong>
            free space remaining in your Vault.
          </p>
          <p i18n="@@vault-size-exceeded-dialog.free-up2">
            Free up your space or
            <strong
              class="text-color-primary link font-bold"
              (click)="upgradePlan()"
            >
              buy additional storage</strong
            >.
          </p>
        }
      </ng-container>

      <ng-container *gvDialogActions>
        @if (!data.uploadStopped) {
          <gv-basic-dialog-button
            i18n="@@vault-size-exceeded-dialog.upgrade-later"
            >Buy storage later</gv-basic-dialog-button
          >
        }

        @if (data.uploadStopped) {
          <gv-basic-dialog-button i18n="@@button.cancel">
            Cancel
          </gv-basic-dialog-button>
        }

        <gv-basic-dialog-button
          i18n="@@vault-size-exceeded-dialog.upgrade-now"
          color="primary"
          [disabled]="submitInProgressS()"
          (close)="upgradePlan()"
        >
          Buy storage
        </gv-basic-dialog-button>
      </ng-container>
    </gv-simple-dialog>
  `,
})
export class VaultSizeExceededDialogComponent
  extends SubmittableDialogComponent<
    VaultSizeExceededDialogDataModel,
    void,
    void
  >
  implements OnInit, OnDestroy
{
  private store = inject(StoreInject(DETAIL_FEATURE_STATE));
  readonly totalSpace$: Observable<number> = this.store.select(getTotalSpace);

  readonly title = !this.data.uploadStopped
    ? $localize`:@@vault-size-exceeded-dialog.title:Vault capacity warning`
    : $localize`:@@vault-size-exceeded-dialog.title-stopped:Vault capacity exceeded`;

  readonly usedSpaceInBytes$: Observable<number> = this.store
    .select(getUsedSpace)
    .pipe(map((v) => toBytes(v, FileSizeUnit.GigaByte)));

  ngOnInit(): void {
    this.store.dispatch(detail.fetch.init({ skipWhenLoaded: true }));
    this.store.dispatch(detail.watching.acquire());
  }

  override ngOnDestroy(): void {
    this.store.dispatch(detail.watching.release());
  }

  upgradePlan(): void {
    if (this.submitInProgressS()) {
      return;
    }

    this.submitSubject.next();
  }
}
