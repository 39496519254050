import type { IntercomConfig } from '@gv/ui/help';
import type { WindowRefService } from '@gv/ui/core';
import { Environment } from '@gv/ui/core';

import { intercomAppId } from '../constant/3rd-party-service/intercom-app-id';

export function intercomConfigFactory(
  windowRef: WindowRefService,
): IntercomConfig {
  return {
    appId:
      !!windowRef &&
      !!windowRef.nativeWindow &&
      (windowRef.nativeWindow._environment === Environment.Production ||
        windowRef.nativeWindow._environment === Environment.ElectronProduction)
        ? intercomAppId.production
        : intercomAppId.test,
    updateOnRouterChange: false,
  };
}
