import type { OnDestroy } from '@angular/core';
import { Injectable, inject } from '@angular/core';
import type { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';

import type { ApiResponse } from '@gv/api';
import { ApiErrorResponse } from '@gv/api';
import { WindowRefService } from '@gv/ui/core';
import {
  copyTokenToDomain,
  createThrowIfRedirect,
  reloadingLocks,
} from '@gv/utils';
import type { Observable, Subscription } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { InfoDialogComponent } from '@gv/ui/dialog';
import { ElectronRefService } from '@gv/desktop/core';

import { TokenStoreService } from '../../../application/user/token-store.service';
import { UserService } from '../../../application/user/user.service';
import type { RequestConfig } from '../http';
import type { RequestHandler, RequestInterceptor } from '../interceptor';

interface RedirectDataModel {
  location: string;
}

@Injectable({
  providedIn: 'root',
})
export class DomainRedirectInterceptor
  implements RequestInterceptor, OnDestroy
{
  private dialog = inject(MatDialog);
  private electronRef = inject(ElectronRefService, { optional: true });
  private windowRef = inject(WindowRefService);
  private userService = inject(UserService);
  private tokenStore = inject(TokenStoreService);
  private dialogTimeout: ReturnType<typeof setTimeout>;

  private dialogRef: MatDialogRef<InfoDialogComponent>;

  private dialogClosedSubscription: Subscription;

  ngOnDestroy(): void {
    this.destroyDialog();
  }

  intercept(
    request: RequestConfig<any>,
    next: RequestHandler,
  ): Observable<ApiResponse<any>> {
    if (request.bypassRedirect) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      tap(createThrowIfRedirect(!this.electronRef)),
      catchError((error) => {
        if (error instanceof ApiErrorResponse) {
          if (error.status === 302) {
            const errorData: RedirectDataModel = error.data;
            if (errorData && errorData.location) {
              this.showRedirectMessage(errorData.location);
            }
          }
        }
        return throwError(() => error);
      }),
    );
  }

  private showRedirectMessage(location: string): Promise<void> {
    if (this.dialogRef || reloadingLocks.value) {
      // redirect is already in progress
      // doing nothing
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      this.destroyDialogClosedSubscription();

      this.dialogRef = this.dialog.open(InfoDialogComponent, {
        autoFocus: false,
        data: {
          title: 'You will be redirected',
          text: `
              Your organization is not allowed to run on this domain. <br>
              You will be redirected in a few seconds.
            `,
        },
        width: '690px',
      });

      this.dialogClosedSubscription = this.dialogRef
        .afterClosed()
        .subscribe(() => {
          this.destroyDialogClosedSubscription();

          clearTimeout(this.dialogTimeout);

          void copyTokenToDomain(
            this.windowRef.nativeWindow,
            location,
            this.tokenStore.token,
            () => this.userService.logOut(),
          ).then(resolve);
        });

      this.dialogTimeout = setTimeout(() => {
        if (this.dialogRef) {
          this.dialogRef.close();
        }
      }, 5000);
    });
  }

  private destroyDialogClosedSubscription(): void {
    if (this.dialogClosedSubscription) {
      this.dialogClosedSubscription.unsubscribe();
      this.dialogClosedSubscription = undefined;
    }
  }

  private destroyDialog(): void {
    clearTimeout(this.dialogTimeout);
    this.dialogTimeout = undefined;

    this.destroyDialogClosedSubscription();

    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = undefined;
    }
  }
}
