import type { HttpResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import type { ApiResponse } from '@gv/api';

export function getHeader<T>(
  response: HttpResponse<T> | ApiResponse<T>,
  header: string,
): string | undefined {
  const normalizedHeader = header.toLowerCase();

  if (response['headers'] instanceof HttpHeaders) {
    return response['headers'].get(normalizedHeader) || undefined;
  }

  return response['headers']?.[normalizedHeader];
}

export function getContentTypeFromResponse<T>(
  response: ApiResponse<T>,
): string | undefined {
  return getHeader(response, 'content-type');
}

/**
 * @returns Either parsed filename from `Content-Disposition` header
 *          or `undefined` if cannot be parsed.
 */
export function getFilenameFromResponse<T>(
  response: ApiResponse<T>,
): string | undefined {
  return parseFilenameFromHeader(getHeader(response, 'content-disposition'));
}

export function parseFilenameFromHeader(
  contentDispositionHeader: string | undefined,
): string | undefined {
  if (!contentDispositionHeader) {
    return undefined;
  }

  const headerValue = contentDispositionHeader.split(';')[1];
  if (headerValue === undefined) {
    return undefined;
  }

  const filename = headerValue.trim().split('=')[1];
  if (filename === undefined) {
    return undefined;
  }
  return filename.replace(/"/g, '');
}
