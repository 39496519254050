import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import type { Store } from '@gv/state';
import { NgStore } from '@gv/state';

import { CookieLawSnackBarComponent } from '../../../component/dialog/cookie-law/cookie-law-snack-bar/cookie-law-snack-bar.component';
import * as CookieLawActions from '../../../store/action/cookie-law.actions';
import { CookieLawEffects } from '../../../store/effect/cookie-law.effects';
import {
  cookieLawFeatureKey,
  reducer,
} from '../../../store/reducer/cookie-law.reducer';
import type { CookieLawState } from '../../../store/state/cookie-law.state';
import { COOKIE_LAW_FEATURE_STATE } from '../../../store/state/feature-state/cookie-law-feature.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(cookieLawFeatureKey, reducer),
    EffectsModule.forFeature([CookieLawEffects]),
    CookieLawSnackBarComponent,
  ],
  exports: [CookieLawSnackBarComponent],
  providers: [
    {
      provide: COOKIE_LAW_FEATURE_STATE,
      useExisting: NgStore,
    },
    {
      provide: APP_INITIALIZER,
      deps: [COOKIE_LAW_FEATURE_STATE],
      useFactory: (store: Store<CookieLawState>) => {
        return () => {
          store.dispatch(CookieLawActions.initialize());
        };
      },
      multi: true,
    },
  ],
})
export class CookieLawModule {}
