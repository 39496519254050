import { Injectable } from '@angular/core';

import type { GVVIDataSourceDetailDTO, VaultFileModel } from '@gv/api';
import type { Observable } from 'rxjs';
import { Subject, filter } from 'rxjs';
import type { U } from 'ts-toolbelt';

// using ug prefix fo actions that are triggered fom states to components
export type SyncifyPayload = { dtSent: Date } & (
  | {
      type: 'location' | 'projects' | 'ug-locations';
    }
  | {
      type: 'project';
    }
  | {
      type: 'breadcrumb';
    }
  | ({
      type: 'vault';
      prefix: string;
      tree: boolean;
      blockingUpdateNeeded: boolean;
    } & (
      | {
          files: VaultFileModel[];
          action: 'new';
        }
      | {
          files: string[];
          action: 'delete' | 'update';
        }
    ))
  | {
      type: 'gvvi-datasource';
      data: GVVIDataSourceDetailDTO;
      directory: string;
    }
  | {
      type: 'gvvi-datasources';
      directory: string;
    }
);

@Injectable({ providedIn: 'root' })
export class Syncify {
  invalidate$ = new Subject<SyncifyPayload>();

  ofType<K extends SyncifyPayload['type']>(
    key: K,
  ): Observable<U.Select<SyncifyPayload, { type: K }>> {
    return this.invalidate$.pipe(
      filter(
        (action): action is U.Select<SyncifyPayload, { type: K }> =>
          action.type === key,
      ),
    );
  }

  invalidate(data: SyncifyPayload) {
    this.invalidate$.next(data);
  }
}
