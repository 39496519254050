import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { trackEvent } from '@gv/analytics';
import { ApiErrorResponse } from '@gv/api';
import { EMPTY_STATE, StoreInject } from '@gv/state';
import { SnackBarActions } from '@gv/ui/toaster';
import { extractLogLevel, toSentryError } from '@gv/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { captureException, withScope } from '@sentry/angular';
import { tap } from 'rxjs/operators';

import { logger } from '../logger';
import { ErrorsActions } from './errors.actions';

@Injectable()
export class ErrorsEffects {
  private actions$ = inject<Actions>(Actions);
  private router = inject(Router);
  private store = inject(StoreInject(EMPTY_STATE));
  genericErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorsActions.generic),
        tap((action) => {
          if (action.log) {
            logger.error({ action }, 'Caught error');
          }

          if (action.logToSentry && action.error) {
            this.captureSentryError(action);
          }

          if (!action.snackbarMessage) {
            return;
          }

          if (
            (action.error instanceof HttpErrorResponse ||
              action.error instanceof ApiErrorResponse) &&
            action.error.status === 401
          ) {
            if (!this.isOnLoginOrLogoutPage()) {
              this.store.dispatch(SnackBarActions.show(action.snackbarMessage));
            }
          } else {
            this.store.dispatch(SnackBarActions.show(action.snackbarMessage));
          }
          trackEvent('error', { errorSource: 'modal' });
        }),
      ),
    {
      dispatch: false,
    },
  );

  private captureSentryError(
    action: ReturnType<typeof ErrorsActions.generic>,
  ): void {
    withScope((scope) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { sentryOptions, error } = action;

      scope.setLevel(extractLogLevel(error));

      if (sentryOptions) {
        if (sentryOptions.tags) {
          scope.setTags(sentryOptions.tags);
        }

        if (sentryOptions.extras) {
          scope.setExtras(sentryOptions.extras);
        }

        if (sentryOptions.level) {
          scope.setLevel(sentryOptions.level);
        }
      }

      captureException(
        toSentryError(action.action ? action.action.type : undefined, error),
      );
    });
  }

  private isOnLoginOrLogoutPage(): boolean {
    return (
      this.router.isActive('/login', {
        paths: 'subset',
        queryParams: 'subset',
        fragment: 'ignored',
        matrixParams: 'ignored',
      }) ||
      this.router.isActive('/logout', {
        paths: 'subset',
        queryParams: 'subset',
        fragment: 'ignored',
        matrixParams: 'ignored',
      })
    );
  }
}
