import type { OnDestroy } from '@angular/core';
import { Injectable, DestroyRef, inject } from '@angular/core';

import { replaceArgs } from '@gv/api';
import { ofActions, SimpleState } from '@gv/state';
import { isArray, simpleSwitchMap } from '@gv/utils';
import { createEffect } from '@ngrx/effects';
import type { Action } from '@ngrx/store';
import { produce } from 'immer';
import { isEqual } from 'lodash-es';
import type { ObservedValueOf } from 'rxjs';
import {
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  mergeMap,
  Observable,
  Subject,
  switchMap,
  take,
} from 'rxjs';
import type { U } from 'ts-toolbelt';
import { isFunction } from 'remeda';

import type { SubscriptionTypeMapping } from '../entity/subscription-mapping';
import { subscriptionMapping } from '../entity/subscription-mapping';
import type { Topics } from '../entity/topics';
import { topics } from '../entity/topics';
import type { ApiNotification } from '../entity/type/api-notification';
import type { BinaryPayload } from '../entity';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService implements OnDestroy {
  private destroyRef = inject(DestroyRef);
  private topicState = new SimpleState(
    { topics: <Record<string, number>>{} },
    {
      add: (state, topic: string) => ({
        ...state,
        topics: { ...state.topics, [topic]: (state.topics[topic] || 0) + 1 },
      }),
      remove: (state, topic: string) =>
        !(topic in state.topics)
          ? state
          : produce(state, (draft) => {
              draft.topics[topic] -= 1;
              if (draft.topics[topic] <= 0) {
                delete draft.topics[topic];
              }
            }),
    },
  );

  subscriptionTopics$ = this.topicState.state$.pipe(
    map((state) => Object.keys(state.topics).sort()),
    distinctUntilChanged((a, b) => isEqual(a, b)),
  );

  private notificationsSubject = new Subject<{
    notification: ApiNotification | BinaryPayload;
    topic: string;
  }>();

  notifications$ = this.notificationsSubject.asObservable();

  constructor() {
    this.topicState.init(this.destroyRef);
  }

  ngOnDestroy(): void {
    //
  }

  postNotification(notification: {
    notification: ApiNotification | BinaryPayload;
    topic: string;
  }): void {
    this.notificationsSubject.next(notification);
  }

  ofType<T extends ApiNotification['notificationType']>(
    key: T,
    ...params: Parameters<Topics[SubscriptionTypeMapping[T]]['parameter']>
  ): Observable<
    U.Select<ApiNotification, { notificationType: T }, 'contains->'>
  > {
    const topicObj = topics[
      subscriptionMapping[key]
    ] as Topics[SubscriptionTypeMapping[T]];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param = topicObj.parameter(params[0] as any);
    const topic = replaceArgs(topicObj.name, param);

    const notifications$ = this.notifications$.pipe(
      filter((f) => f.topic === topic),
      map((m) => m.notification),
      filter(
        (
          f,
        ): f is U.Select<
          ApiNotification,
          { notificationType: T },
          'contains->'
        > => 'notificationType' in f && key === f.notificationType,
      ),
    );
    return new Observable<
      U.Select<ApiNotification, { notificationType: T }, 'contains->'>
    >((obs) => {
      this.topicState.dispatch({ type: 'add', data: topic });

      const sub = notifications$.subscribe(obs);

      return () => {
        this.topicState.dispatch({ type: 'remove', data: topic });
        sub?.unsubscribe();
      };
    });
  }

  createEffect<
    T,
    N extends ApiNotification['notificationType'],
    C extends Observable<any>,
  >(options: {
    obs: Observable<T>;
    type: N | ((opts: T) => N);
    data: (
      data: T,
    ) => Parameters<Topics[SubscriptionTypeMapping[N]]['parameter']>;
    action: (
      notification: U.Select<
        ApiNotification,
        { notificationType: N },
        'contains->'
      >,
      context: ObservedValueOf<C>,
    ) =>
      | Action
      | false
      | null
      | undefined
      | (Action | false | null | undefined)[];
    contextProvider?: C;
  }): Observable<Action> {
    const { obs, type, data: dataProvider, action, contextProvider } = options;

    const getActions = (
      notification: U.Select<
        ApiNotification,
        { notificationType: N },
        'contains->'
      >,
      context: ObservedValueOf<C>,
    ) => {
      const actions = action(notification, context);

      return ofActions(...(isArray(actions) ? actions : [actions]));
    };

    return createEffect(() =>
      obs.pipe(
        distinctUntilChanged(),
        simpleSwitchMap(
          (data) =>
            this.ofType(
              isFunction(type) ? type(data) : type,
              ...dataProvider(data),
            ),
          EMPTY,
        ),
        mergeMap((notification) => {
          if (contextProvider) {
            return contextProvider.pipe(
              take(1),
              switchMap((context: ObservedValueOf<C>) =>
                getActions(notification, context),
              ),
            );
          }
          const actions = action(notification, undefined as ObservedValueOf<C>);

          return ofActions(...(isArray(actions) ? actions : [actions]));
        }),
      ),
    );
  }
}
