@if (link) {
  <a
    gvId="link"
    draggable="false"
    [routerLink]="link"
    [relativeTo]="relativeTo()"
    class="btn-container flex items-center px-4 py-2 font-semibold no-underline"
    [matTooltipDisabled]="!disabledByPermissionsS()"
    [matTooltip]="permissionTooltip === true ? reason : permissionTooltip || ''"
    [matTooltipPosition]="tooltipPosition"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </a>
} @else {
  <div
    gvId="content"
    class="btn-container flex items-center px-4 py-2"
    [matTooltipDisabled]="!disabledByPermissionsS()"
    [matTooltip]="permissionTooltip === true ? reason : permissionTooltip || ''"
    [matTooltipPosition]="tooltipPosition"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </div>
}

<ng-template #content>
  @if (!submitting && (icon || svgIcon) && !alignEnd) {
    <mat-icon [inline]="iconInline" [svgIcon]="svgIcon" gvId="icon">{{
      icon
    }}</mat-icon>
  }

  @if (submitting) {
    <mat-progress-spinner
      gvId="loader"
      [value]="progress$ && (progress$ | async)"
      [mode]="progress$ ? 'determinate' : 'indeterminate'"
      [diameter]="20"
      class="inline-block align-middle"
    ></mat-progress-spinner>
  }

  @if (submitting) {
    <span class="content-wrapper ml-2 truncate" gvId="text">
      <ng-container [ngTemplateOutlet]="submittingTemplate"></ng-container>
    </span>
  }

  @if (!submitting) {
    <span class="content-wrapper ml-2 truncate" gvId="text">
      <ng-content></ng-content>
    </span>
  }

  @if (!submitting && (icon || svgIcon) && alignEnd) {
    <mat-icon [inline]="iconInline" [svgIcon]="svgIcon" gvId="icon">{{
      icon
    }}</mat-icon>
  }
</ng-template>
