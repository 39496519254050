import { expandContextIfNeeded, _logStore } from './log-writer';

export const logStore = function* () {
  for (const item of _logStore) {
    const context = expandContextIfNeeded(item.payload.context);
    if (item.payload.context === context) {
      yield item;
    } else {
      yield {
        ...item,
        payload: {
          ...item.payload,
          context,
        },
      };
    }
  }
};
