import { on } from '@ngrx/store';
import type { Draft } from 'immer';
import { current, produce } from 'immer';
import { asMutable, createFeatureReducer } from '@gv/state';
import { ObjectUtils, generateUuid, removeFromArray } from '@gv/utils';
import { UserActions } from '@gv/user';
import { isEqual } from 'lodash-es';
import { LayoutView } from '@gv/api';
import { SchemesActions } from '@gv/ui/classification-scheme';

import { ProjectActions } from '../../../../store/action/project.actions';
import {
  importDemoProjectFlow,
  loadMore,
  reset,
  setPageType,
  setPageLayout,
  validateDemoProjectFlow,
  setFilter,
  fetchCounts,
  fetchProjectDetail,
  setMapSettings,
  updateNeededItems,
  setAllowedTypes,
  setAllowedPageTypes,
} from './projects.actions';
import type { ProjectsState } from './projects-state.types';
import {
  InternalProjectPageType,
  initialProjectsState,
} from './projects-state.types';

export const projectsFeatureKey = 'projects';

function updatePageType(
  draft: Draft<ProjectsState>,
  page: InternalProjectPageType,
): void {
  const state = current(draft);
  if (draft.filter.page === page) {
    return;
  }

  const oldDataIndex = state.freezed.findIndex((f) => f.filter.page === page);

  draft.freezed.push({
    uuid: generateUuid(),
    context: state.context,
    data: asMutable(state.data),
    filter: asMutable(state.filter),
    ids: state.ids,
    loaded: state.loaded,
    dirty: state.dirty,
    mapData: asMutable(state.mapData),
    newProjects: state.newProjects,
  });

  let resetData = false;
  if (oldDataIndex === -1) {
    resetData = true;
  } else {
    const oldData = draft.freezed.splice(oldDataIndex, 1)[0];
    if (
      isEqual(
        ObjectUtils.omit(state.freezed[oldDataIndex].filter, 'page'),
        ObjectUtils.omit(state.filter, 'page'),
      )
    ) {
      for (const key of ObjectUtils.keys(oldData)) {
        (draft as any)[key] = oldData[key];
      }
    } else {
      // filter changed, so we need to load data anyway
      resetData = true;
    }
  }

  if (resetData) {
    draft.ids = initialProjectsState['ids'];
    draft.data = asMutable(initialProjectsState['data']);
    draft.loaded = initialProjectsState['loaded'];
    draft.dirty = initialProjectsState['dirty'];
    if (draft.context) {
      draft.context.countDirty = true;
    }
    draft.mapData = asMutable(initialProjectsState['mapData']);
  }
  draft.filter.page = page;
}

export const projectsReducerFns = createFeatureReducer<ProjectsState>(
  on(setMapSettings.init, (state, action): ProjectsState => {
    if (isEqual(state.localMapSettings, action.settings)) {
      return state;
    }

    return {
      ...state,
      localMapSettings: action.settings,
    };
  }),

  on(setAllowedTypes, (state, action): ProjectsState => {
    return produce(state, (draft) => {
      draft.allowedTypes = asMutable(action.types);
      const type = draft.allowedTypes.includes(action.default)
        ? action.default
        : draft.allowedTypes[0];

      if (draft.filter.types[0] === type) {
        return;
      }

      draft.filter.types = type ? [type] : [];

      draft.dirty = 'filthy' as const;
      for (const d of draft.freezed) {
        d.ids = initialProjectsState['ids'];
        d.data = asMutable(initialProjectsState['data']);
        d.loaded = initialProjectsState['loaded'];
        d.dirty = initialProjectsState['dirty'];
        if (d.context) {
          d.context.countDirty = true;
        }
        d.mapData = asMutable(initialProjectsState['mapData']);
        d.filter = asMutable({
          ...draft.filter,
          page: d.filter.page,
        });
      }
    });
  }),

  on(setMapSettings.completed, (state, action): ProjectsState => {
    if (isEqual(state.apiMapSettings, action.settings)) {
      return state;
    }

    return {
      ...state,
      apiMapSettings: action.settings,
    };
  }),

  on(
    setPageLayout.init,
    (state, action): ProjectsState =>
      state.pageView === action.layout
        ? state
        : produce(state, (draft) => {
            draft.dirty = 'filthy';
            draft.updatingLayout = true;
            draft.pageView = action.layout;
            if (draft.pageView === LayoutView.Map) {
              for (const d of [...draft.freezed, draft]) {
                d.dirty = 'filthy';
              }
            }
          }),
  ),

  on(
    setPageLayout.completed,
    (state): ProjectsState =>
      produce(state, (draft) => {
        draft.updatingLayout = false;
      }),
  ),

  on(
    updateNeededItems,
    (state, action): ProjectsState =>
      state.neededItems === action.items
        ? state
        : { ...state, neededItems: action.items },
  ),

  on(fetchCounts.init, (state) =>
    produce(state, (draft) => {
      draft.loadingCounts = true;
    }),
  ),

  on(fetchCounts.completed, (state, action) =>
    produce(state, (draft) => {
      const initialLoad = !draft.countsLoadedAtLeastOnce;
      draft.countsLoadedAtLeastOnce = true;
      draft.loadingCounts = false;
      for (const page of ObjectUtils.keys(action.data)) {
        if (page === draft.filter.page) {
          draft.context ??= {
            count: undefined,
            limit: undefined,
            offset: undefined,
            countDirty: false,
          };
          draft.context.count = action.data[page];
          continue;
        }
        const item = draft.freezed.find((f) => f.filter.page === page);
        if (item) {
          item.context ??= {
            count: undefined,
            limit: undefined,
            offset: undefined,
            countDirty: false,
          };
          item.context.count = action.data[page];
        } else {
          draft.freezed.push({
            context: {
              count: action.data[page],
              limit: undefined,
              offset: undefined,
              countDirty: false,
            },
            mapData: [],
            data: {},
            dirty: false,
            loaded: false,
            filter: {
              ...draft.filter,
              page,
            },
            ids: [],
            uuid: generateUuid(),
            newProjects: [],
          });
        }
      }
      if (initialLoad || draft.filter?.uuids?.length) {
        const type = draft.allowedPageTypes.find((f) => action.data[f]);
        if (
          type !== draft.filter.page &&
          type &&
          action.data[draft.filter.page] === 0
        ) {
          updatePageType(draft, type);
        }
      }
    }),
  ),

  on(
    setPageType,
    (state, action): ProjectsState =>
      produce(state, (draft) => {
        updatePageType(draft, action.page);
      }),
  ),

  on(
    setFilter,
    (state, action): ProjectsState =>
      isEqual(state.filter, action.filter)
        ? state
        : produce(state, (draft) => {
            const newFilter = {
              ...draft.filter,
              ...action.filter,
            };
            draft.dirty = 'filthy';
            draft.filter = asMutable({
              ...newFilter,
              page: draft.filter.page,
            });
            if (draft.context) {
              draft.context.countDirty = true;
            }
            for (const d of draft.freezed) {
              d.ids = initialProjectsState['ids'];
              d.data = asMutable(initialProjectsState['data']);
              d.loaded = initialProjectsState['loaded'];
              d.dirty = initialProjectsState['dirty'];
              if (d.context) {
                d.context.countDirty = true;
              }
              d.mapData = asMutable(initialProjectsState['mapData']);
              d.filter = asMutable({
                ...newFilter,
                page: d.filter.page,
              });
              d.newProjects = asMutable(initialProjectsState['newProjects']);
            }
          }),
  ),

  on(
    setAllowedPageTypes,
    (state, action): ProjectsState =>
      isEqual(state.allowedPageTypes, action.types)
        ? state
        : produce(state, (draft) => {
            draft.allowedPageTypes = action.types;
            if (draft.allowedPageTypes.includes(draft.filter.page)) {
              updatePageType(draft, draft.allowedPageTypes[0]);
            }
          }),
  ),

  on(
    importDemoProjectFlow.started,
    validateDemoProjectFlow.started,
    (state): ProjectsState => {
      return { ...state, demo: { ...state.demo, state: 'loading' as const } };
    },
  ),

  on(
    importDemoProjectFlow.cancel,
    validateDemoProjectFlow.cancel,
    (state): ProjectsState => {
      return { ...state, demo: { ...state.demo, state: 'none' as const } };
    },
  ),

  on(
    importDemoProjectFlow.error,
    validateDemoProjectFlow.error,
    (state): ProjectsState => {
      return { ...state, demo: { ...state.demo, state: 'none' as const } };
    },
  ),

  on(importDemoProjectFlow.completed, (state): ProjectsState => {
    return {
      ...state,
      demo: { ...state.demo, imported: true, state: 'loaded' as const },
    };
  }),

  on(validateDemoProjectFlow.completed, (state, action) => {
    return {
      ...state,
      demo: {
        ...state.demo,
        imported: action?.data?.data?.demoShared ?? false,
        state: 'loaded' as const,
      },
    };
  }),

  on(loadMore.completed, (state, action) => {
    return produce(state, (draft) => {
      for (const project of action.data.projects) {
        const newProjectIndex = draft.newProjects.findIndex(
          (f) => f.uuid === project.uuid,
        );

        if (newProjectIndex >= 0) {
          draft.newProjects.splice(newProjectIndex, 1);
        }

        if (!draft.ids.includes(project.uuid)) {
          draft.ids.push(project.uuid);
        }
        draft.data[project.uuid] = asMutable({
          ...project,
          dtSent: action.data.dtSent,
        });

        if (project.uuid in draft.detailData) {
          draft.detailData[project.uuid] = {
            ...draft.detailData[project.uuid],
            data: draft.data[project.uuid],
          };
        }
      }
      draft.context = {
        count: action.data.count,
        limit: action.data.limit,
        offset: action.data.offset,
        countDirty: false,
      };
    });
  }),

  on(fetchProjectDetail.completed, (state, action) =>
    produce(state, (draft) => {
      draft.detailData[action.data.uuid] = {
        data: asMutable(action.data),
        loaded: true,
        loading: false,
      };
    }),
  ),

  on(fetchProjectDetail.init, (state, action) =>
    produce(state, (draft) => {
      draft.detailData[action.uuid] ??= {
        data: undefined,
        loaded: false,
        loading: false,
      };
      draft.detailData[action.uuid].loading = true;
    }),
  ),

  on(ProjectActions.add, (state, action) => {
    if (!state.loaded) {
      return state;
    }

    const alreadyPresent = action.project.uuid in state.data;
    const alreadyPresentInNewProjects = state.newProjects
      .map((p) => p.uuid)
      .includes(action.project.uuid);

    if (
      alreadyPresent &&
      state.freshness &&
      (state.freshness.getTime() >= action.dtSent.getTime() ||
        state.data[action.project.uuid].dtSent.getTime() >=
          action.dtSent.getTime())
    ) {
      return state;
    }

    return produce(state, (draft) => {
      const mappedProject = {
        ...asMutable(action.project),
        dtSent: action.dtSent,
      };
      if (alreadyPresent) {
        draft.freezed.forEach((f) => {
          f.dirty = 'filthy';
        });
        draft.data[action.project.uuid] = mappedProject;
      } else if (alreadyPresentInNewProjects) {
        draft.freezed.forEach((f) => {
          f.dirty = 'filthy';
        });
        draft.newProjects = asMutable(
          state.newProjects.map((p) =>
            p.uuid === action.project.uuid ? mappedProject : p,
          ),
        );
      } else {
        if (draft.filter?.page === InternalProjectPageType.Archived) {
          updatePageType(draft, InternalProjectPageType.My);
        }

        // mark all other pages for full refresh
        draft.freezed.forEach((f) => {
          f.dirty = 'filthy';
        });

        // do not reload new projects on subsequent refresh
        draft.dirty = 'smudged';
        draft.newProjects.push(mappedProject);
      }
    });
  }),

  on(ProjectActions.remove, (state, action) => {
    return produce(state, (draft) => {
      draft.freezed.forEach((f) => {
        f.dirty = 'filthy';
      });
      if (action.uuid in draft.data) {
        delete draft.data[action.uuid];
        removeFromArray(draft.ids, action.uuid);
      }
      if (action.uuid in draft.detailData) {
        delete draft.detailData[action.uuid];
      }
      const index = draft.newProjects.findIndex((f) => f.uuid === action.uuid);
      if (index >= 0) {
        draft.newProjects.splice(index, 1);
      }
    });
  }),

  on(ProjectActions.update, (state, action) => {
    if (!state.loaded) {
      return state;
    }

    return produce(state, (draft) => {
      draft.freezed.forEach((f) => {
        f.dirty = 'filthy';
      });
      const alreadyPresent = action.project.uuid in state.data;

      if (
        alreadyPresent &&
        state.freshness &&
        action.dtSent &&
        (state.freshness.getTime() >= action.dtSent.getTime() ||
          state.data[action.project.uuid].dtSent.getTime() >=
            action.dtSent.getTime())
      ) {
        return;
      }

      draft.dirty = 'filthy';

      if (alreadyPresent) {
        draft.data[action.project.uuid] = {
          ...draft.data[action.project.uuid],
          ...asMutable(action.project),
          dtSent: action.dtSent ?? draft.data[action.project.uuid].dtSent,
        };
      }

      if (action.uuid in draft.detailData) {
        draft.detailData[action.uuid] = {
          ...draft.detailData[action.project.uuid],
          ...asMutable(action.project),
        };
      }

      if (!alreadyPresent) {
        const index = draft.newProjects.findIndex(
          (f) => f.uuid === action.uuid,
        );
        if (index >= 0) {
          draft.newProjects[index] = {
            ...draft.newProjects[index],
            ...asMutable(action.project),
          };
        } else {
          draft.newProjects.push({
            ...asMutable(action.project),
            defaultStorage: action.project.defaultStorage ?? undefined,
            dtSent: action.dtSent ?? new Date(),
          });
        }
      }
    });
  }),

  on(
    SchemesActions.editScheme,
    SchemesActions.editCustomSchemeFlow.completed,
    (state, action) => {
      return produce(state, (draft) => {
        state.ids.forEach((id) => {
          if (state.data[id].classScheme.uuid === action.data.uuid) {
            draft.data[id] = {
              ...draft.data[id],
              classScheme: asMutable(action.data),
            };
          }
        });
      });
    },
  ),

  on(reset, UserActions.logout, () => initialProjectsState),
);
