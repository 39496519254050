export enum ObjectClass {
  Animal = 'ANIMAL',
  Car = 'CAR',
  Van = 'VAN',
  Bus = 'BUS',
  Motorcycle = 'MOTORCYCLE',
  Bicycle = 'BICYCLE',
  Person = 'PERSON',
  Truck = 'TRUCK',
  OGV1 = 'OGV1',
  OGV2 = 'OGV2',
}
