import { inject, Injectable } from '@angular/core';

import { API } from '@gv/api';
import { createSubStateRefreshEffect, StoreInject } from '@gv/state';
import { delayedConcatMap } from '@gv/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concat, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

import * as CreditActions from './actions';
import { fromBalance } from './selectors';
import { CREDIT_FEATURE_STATE } from './state';

@Injectable()
export class CreditEffects {
  private actions$ = inject<Actions>(Actions);
  private api = inject(API);
  private store = inject(StoreInject(CREDIT_FEATURE_STATE));
  fetchBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreditActions.credit.fetch.init),
      debounceTime(0),
      delayedConcatMap(() => [
        this.store.select(fromBalance.isBalanceLoaded),
        this.store.select(fromBalance.isBalanceDirty),
        this.store.select(fromBalance.isBalanceLoading),
      ]),
      filter(
        ([action, loaded, dirty, loading]) =>
          !action.skipWhenLoaded || ((!loaded || dirty) && !loading),
      ),
      switchMap(() => {
        return concat(
          of(CreditActions.credit.fetch.started()),
          this.api.getCreditBalance().pipe(
            mergeMap((response) => {
              return of(
                CreditActions.credit.fetch.completed({
                  dtSent: response.dtSent,
                  data: response.data!,
                  sortable: undefined,
                }),
              );
            }),
            catchError((error: unknown) => {
              return of(
                CreditActions.credit.fetch.error({
                  error,
                }),
              );
            }),
            takeUntil(
              this.actions$.pipe(ofType(CreditActions.credit.fetch.cancel)),
            ),
          ),
        );
      }),
    ),
  );

  refreshBalance$ = createSubStateRefreshEffect(
    CreditActions.credit,
    fromBalance,
    { actions$: this.actions$, store: this.store },
    'balance',
  );
}
