import { isFunction } from 'lodash-es';
import type { TokenRole } from '@gv/api';
import { Feature } from '@gv/api';
import { ObjectUtils } from '@gv/utils';

import { Permission } from '../entity/enum/permission.enum';
import { PermissionScope } from '../entity/enum/permission-scope.enum';

export type Permissions = Record<Permission, PermissionScope>;

export type PermissionsInverseMapping = Record<Permission, Feature>;

export type PermissionReducerFunction = (
  permissions: Permissions,
  feature: Feature,
  features: readonly Feature[],
) => Permissions;

export type PermissionInitializerFunction = (
  permissions: Permissions,
  features: readonly Feature[],
  tokenRole: TokenRole,
) => Permissions;

export type PermissionReducerObject = Partial<
  Record<Feature, [Permission, PermissionScope]>
>;

export type PermissionReducer =
  | PermissionReducerFunction
  | PermissionReducerObject;

export type PermissionsReason = Partial<Record<Permission, string>>;

export const permissionsReason: PermissionsReason = {
  [Permission.OrganizationProjectManagement]: $localize`:@@permission-definition.reason-org:You have insufficient organization permissions for this action`,
  [Permission.Organization]: $localize`:@@permission-definition.reason-org:You have insufficient organization permissions for this action`,
  [Permission.Project]: $localize`:@@permission-definition.reason-project:You have insufficient project permissions for this action`,
};

export const permissionsInitializers: readonly PermissionInitializerFunction[] =
  [
    (permissions, features) => {
      if (!features) {
        return permissions;
      }

      if (features.includes(Feature.ModuleDesktop)) {
        return {
          ...permissions,
          [Permission.Desktop]: PermissionScope.Empty,
        };
      }

      return {
        ...permissions,
        [Permission.Desktop]: PermissionScope.All,
      };
    },
    (permissions, features) => {
      if (!features) {
        return permissions;
      }

      if (features.includes(Feature.PlatformPlan)) {
        return {
          ...permissions,
          [Permission.Platform]: PermissionScope.Empty,
        };
      }

      return {
        ...permissions,
        [Permission.Platform]: PermissionScope.All,
      };
    },
    (permissions, _, token) => {
      if (!token) {
        return permissions;
      }

      if (token === 'SUPER_ADMIN') {
        return {
          ...permissions,
          [Permission.SuperAdmin]: PermissionScope.All,
        };
      } else if (token === 'SUPPORT') {
        return {
          ...permissions,
          [Permission.Support]: PermissionScope.All,
        };
      } else if (token === 'ADMIN' || token === 'GORDON') {
        return {
          ...permissions,
          [Permission.Admin]: PermissionScope.All,
        };
      }

      return permissions;
    },
  ];

export const permissionReducers: readonly PermissionReducer[] = [
  {
    [Feature.ProcessingStream]: [Permission.Stream, PermissionScope.Open],
    [Feature.ProcessingDrone]: [
      Permission.Drone,
      PermissionScope.Empty | PermissionScope.Show,
    ],
    // [Feature.OGV]: [Permission.OGV, PermissionScope.Empty | PermissionScope.Show],
    [Feature.ProcessingAlpr]: [
      Permission.ALPR,
      PermissionScope.Empty | PermissionScope.Show,
    ],
    [Feature.ProcessingTimelapse]: [
      Permission.Timelapse,
      PermissionScope.Empty,
    ],
    [Feature.ProcessingOgv]: [Permission.NotSupported, PermissionScope.Empty],
    [Feature.ProcessingSpeed]: [Permission.NotSupported, PermissionScope.Empty],

    [Feature.ExportTmc]: [
      Permission.TMCExport,
      PermissionScope.Open | PermissionScope.Show,
    ],
    [Feature.TMCEdited]: [Permission.TMCEdited, PermissionScope.Empty],
    [Feature.ExportTMCMulti]: [Permission.TMCMulti, PermissionScope.Open],
    [Feature.ExportVehicleList]: [
      Permission.VehicleListExport,
      PermissionScope.Open | PermissionScope.Show,
    ],
    [Feature.ExportSaturationFlow]: [
      Permission.SatFlowExport,
      PermissionScope.Open | PermissionScope.Show,
    ],
    [Feature.ExportODMatrix]: [
      Permission.ODMatrix,
      PermissionScope.Open | PermissionScope.Show,
    ],
    [Feature.ExportVissim]: [
      Permission.Vissim,
      PermissionScope.Open | PermissionScope.Show,
    ],
    [Feature.ExportANPRVehicleList]: [
      Permission.AnprExport,
      PermissionScope.Open | PermissionScope.Show,
    ],
    [Feature.ExportPeakHour]: [
      Permission.PeakHourExport,
      PermissionScope.Open | PermissionScope.Show,
    ],

    [Feature.WidgetPeakHourSingle]: [
      Permission.PeakPeriodMaxSingle,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.WidgetPeakHourPie]: [
      Permission.PeakPeriodMaxPie,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.WidgetAverageVolumeSingle]: [
      Permission.AverageVolumeSingle,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.WidgetAverageVolumePie]: [
      Permission.AverageVolumePie,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.WidgetObjectDistributionLine]: [
      Permission.ObjectDistributionLine,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.WidgetObjectDistributionBar]: [
      Permission.ObjectDistributionBar,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.WidgetObjectDistributionHeatmap]: [
      Permission.ObjectDistributionHeatmap,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.WidgetTotalVolumeSingle]: [
      Permission.TotalVolumeSingle,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.WidgetTotalVolumePie]: [
      Permission.TotalVolumePie,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.WidgetTmcDiagramFlowGraph]: [
      Permission.TmcFlowDiagram,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.WidgetMeanTravelTimeSingle]: [
      Permission.MeanTravelTimeSingle,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.WidgetMeanTravelTimeBar]: [
      Permission.MeanTravelTimeBar,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.WidgetMeanTimeGapSingle]: [
      Permission.MeanTimeGapSingle,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.WidgetMeanTimeGapBar]: [
      Permission.MeanTimeGapBar,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.WidgetVehicleListTable]: [
      Permission.VehicleListTable,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.WidgetTrajectoriesScene]: [
      Permission.TrajectoryScene,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.WidgetMotionHeatmapScene]: [
      Permission.MotionHeatmapScene,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.WidgetAccelerationHeatmapScene]: [
      Permission.AccelerationHeatmapScene,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.WidgetHoldupHeatmapScene]: [
      Permission.HoldupHeatmapScene,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.WidgetPeakPeriodsTable]: [
      Permission.PeakPeriods,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.WidgetMeanOccupancyTimeInZoneSingle]: [
      Permission.MeanOccupancyTimeInZoneSingle,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.WidgetMeanOccupancyTimeInZonePie]: [
      Permission.MeanOccupancyTimeInZonePie,
      PermissionScope.Delete | PermissionScope.Show,
    ],

    [Feature.VideoUpload]: [Permission.NotSupported, PermissionScope.Empty],
    [Feature.ModuleVault]: [Permission.Vault, PermissionScope.Show],
    [Feature.ModuleVaultVideo]: [Permission.NotSupported, PermissionScope.Show],
    [Feature.ModuleVaultVideoPlayback]: [
      Permission.VaultPlayback,
      PermissionScope.Open,
    ],
    [Feature.ModuleSync]: [
      Permission.Sync,
      PermissionScope.Empty | PermissionScope.Show,
    ],
    [Feature.ModuleConnect]: [
      Permission.Connect,
      PermissionScope.Empty | PermissionScope.Show,
    ],
    [Feature.ModuleEdge]: [
      Permission.Edge,
      PermissionScope.Empty | PermissionScope.Show,
    ],
    [Feature.ModuleDesktop]: [
      Permission.Desktop,
      PermissionScope.Empty | PermissionScope.Show,
    ],
    [Feature.PlatformPlan]: [
      Permission.Platform,
      PermissionScope.Empty | PermissionScope.Show,
    ],

    [Feature.FilterODGeneration]: [
      Permission.ODFilterGeneration,
      PermissionScope.Empty | PermissionScope.Show,
    ],
    [Feature.FilterStay]: [
      Permission.StayEvent,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.FilterVehicleStopped]: [
      Permission.StoppedEvent,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.FilterMovement]: [
      Permission.Movement,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.FilterScenario]: [
      Permission.Scenario,
      PermissionScope.Delete | PermissionScope.Show,
    ],
    [Feature.FilterDistances]: [Permission.NotSupported, PermissionScope.Empty],
    [Feature.DataEditing]: [
      Permission.DataSource,
      PermissionScope.Empty | PermissionScope.Show,
    ],
    [Feature.MetricLayer]: [
      Permission.Metric,
      PermissionScope.Empty | PermissionScope.Show,
    ],
    [Feature.CameraSharing]: [
      Permission.DataSourceSharing,
      PermissionScope.Empty | PermissionScope.Show,
    ],
    [Feature.PaymentCredits]: [
      Permission.PaymentCredits,
      PermissionScope.Empty | PermissionScope.Show,
    ],
    [Feature.PaymentAll]: [
      Permission.PaymentAll,
      PermissionScope.Empty | PermissionScope.Show,
    ],
    [Feature.PaymentBankTransfer]: [
      Permission.PaymentBankTransfer,
      PermissionScope.Empty | PermissionScope.Show,
    ],
    [Feature.PaymentInvoice]: [
      Permission.PaymentInvoice,
      PermissionScope.Empty | PermissionScope.Show,
    ],
    [Feature.OrganizationBilling]: [
      Permission.OrganizationBilling,
      PermissionScope.Empty | PermissionScope.Show,
    ],
    [Feature.OrganizationProjectManagement]: [
      Permission.OrganizationProjectManagement,
      PermissionScope.Empty | PermissionScope.Show,
    ],
    [Feature.OrganizationSettings]: [
      Permission.OrganizationSettings,
      PermissionScope.Empty | PermissionScope.Show,
    ],
    [Feature.ServiceGDrive]: [
      Permission.ServiceGDrive,
      PermissionScope.Empty | PermissionScope.Show,
    ],
    [Feature.ServiceDropbox]: [
      Permission.ServiceDropbox,
      PermissionScope.Empty | PermissionScope.Show,
    ],
    [Feature.ServiceOneDrive]: [
      Permission.ServiceOneDrive,
      PermissionScope.Empty | PermissionScope.Show,
    ],
    [Feature.FilterUTurn]: [Permission.FilterUTurn, PermissionScope.Empty],
    [Feature.FilterDirectionalCross]: [
      Permission.FilterDirectionalCross,
      PermissionScope.Empty,
    ],
    [Feature.EventImages]: [Permission.EventImages, PermissionScope.Empty],
    [Feature.EventIntrusionInstant]: [
      Permission.EventIntrusionInstant,
      PermissionScope.Empty,
    ],
    [Feature.EventIntrusionPeriod]: [
      Permission.EventIntrusionPeriod,
      PermissionScope.Empty,
    ],
    [Feature.EventAverageSpeedInstant]: [
      Permission.EventAverageSpeedInstant,
      PermissionScope.Empty,
    ],
    [Feature.EventAverageSpeedPeriod]: [
      Permission.EventAverageSpeedPeriod,
      PermissionScope.Empty,
    ],
    [Feature.EventAverageOccupancyPeriod]: [
      Permission.EventAverageOccupancyPeriod,
      PermissionScope.Empty,
    ],
    [Feature.EventAverageHeadwayPeriod]: [
      Permission.EventAverageHeadwayPeriod,
      PermissionScope.Empty,
    ],
    [Feature.EventViolationInstant]: [
      Permission.EventViolationInstant,
      PermissionScope.Empty,
    ],
    [Feature.GraphicalObjectCardinalDirection]: [
      Permission.GraphicalObjectCardinalDirection,
      PermissionScope.Empty,
    ],
    [Feature.OrganizationReferrals]: [
      Permission.OrganizationReferrals,
      PermissionScope.Empty,
    ],
    [Feature.DashboardGlobal]: [
      Permission.DashboardGlobal,
      PermissionScope.Empty,
    ],
    [Feature.DashboardLocal]: [
      Permission.DashboardLocal,
      PermissionScope.Empty,
    ],
    [Feature.DashboardVisibilityPrivate]: [
      Permission.DashboardVisibilityPrivate,
      PermissionScope.Empty,
    ],
    [Feature.DashboardVisibilityPublic]: [
      Permission.DashboardVisibilityPublic,
      PermissionScope.Empty,
    ],
    [Feature.DashboardLive]: [Permission.DashboardLive, PermissionScope.Empty],
    [Feature.ProcessingNoFunds]: [Permission.NoFunds, PermissionScope.Empty],
    [Feature.FilePruzkumnikLite]: [
      Permission.NotSupported,
      PermissionScope.Empty,
    ],
    [Feature.StreamAnpr]: [Permission.StreamAnpr, PermissionScope.Empty],
    [Feature.StreamTraffic]: [Permission.StreamTraffic, PermissionScope.Empty],
    [Feature.ScenarioImages]: [
      Permission.ScenarioImages,
      PermissionScope.Empty,
    ],
    [Feature.ScenarioIllegalStop]: [
      Permission.ScenarioIllegalStop,
      PermissionScope.Empty,
    ],
    [Feature.ScenarioLicensePlateDetection]: [
      Permission.ScenarioLicensePlateDetection,
      PermissionScope.Empty,
    ],
    [Feature.ScenarioDirectionalCrossDetection]: [
      Permission.ScenarioDirectionalCrossDetection,
      PermissionScope.Empty,
    ],
    [Feature.ScenarioMultiLineCrossDetection]: [
      Permission.ScenarioMultiLineCrossDetection,
      PermissionScope.Empty,
    ],
    [Feature.ScenarioAvgSpeedMeasurement]: [
      Permission.ScenarioAvgSpeedMeasurement,
      PermissionScope.Empty,
    ],
    [Feature.ScenarioSpeedLimitViolation]: [
      Permission.ScenarioSpeedLimitViolation,
      PermissionScope.Empty,
    ],
    [Feature.ScenarioWrongWayDriving]: [
      Permission.ScenarioWrongWayDriving,
      PermissionScope.Empty,
    ],
    [Feature.ScenarioNodeRed]: [
      Permission.ScenarioNodeRed,
      PermissionScope.Empty,
    ],
    [Feature.ScenarioAvgTimeGapMeasurement]: [
      Permission.ScenarioAvgTimeGapMeasurement,
      PermissionScope.Empty,
    ],
    [Feature.ScenarioLpIllegalParking]: [
      Permission.ScenarioLpIllegalParking,
      PermissionScope.Empty,
    ],
    [Feature.ScenarioAID]: [Permission.ScenarioAID, PermissionScope.Empty],
    [Feature.ScenarioIllegalParking]: [
      Permission.ScenarioIllegalParking,
      PermissionScope.Empty,
    ],
    [Feature.ScenarioC0CrossroadZoneDetection]: [
      Permission.ScenarioC0CrossroadZoneDetection,
      PermissionScope.Empty,
    ],
    [Feature.StreamC0]: [Permission.StreamC0, PermissionScope.Empty],
    [Feature.StreamTrafficANPR]: [
      Permission.StreamTrafficANPR,
      PermissionScope.Empty,
    ],
    [Feature.ExportLive]: [Permission.ExportLive, PermissionScope.Empty],
    [Feature.ScenarioAitlcm]: [
      Permission.ScenarioAitlcm,
      PermissionScope.Empty,
    ],
    [Feature.StreamLivePreview]: [
      Permission.StreamLivePreview,
      PermissionScope.Empty,
    ],
    [Feature.DeprecatedGVLTDescription]: [
      Permission.DeprecatedGVLTDescription,
      PermissionScope.Empty,
    ],
    [Feature.CustomDateTime]: [
      Permission.CustomDateTime,
      PermissionScope.Empty,
    ],
    [Feature.GraphicalObjectLock]: [
      Permission.GraphicalObjectLock,
      PermissionScope.Empty,
    ],
  } satisfies Record<Feature, [Permission, PermissionScope]>,
];

export const defaultPermissions: Permissions = {
  [Permission.Stream]: PermissionScope.All,
  [Permission.Drone]: PermissionScope.All,
  // [Permission.OGV]: PermissionScope.All,
  [Permission.ALPR]: PermissionScope.All,
  [Permission.Timelapse]: PermissionScope.All,
  [Permission.NoFunds]: PermissionScope.All,
  [Permission.StreamAnpr]: PermissionScope.All,
  [Permission.StreamTraffic]: PermissionScope.All,

  [Permission.TMCExport]: PermissionScope.All,
  [Permission.TMCMulti]: PermissionScope.All,
  [Permission.VehicleListExport]: PermissionScope.All,
  [Permission.SatFlowExport]: PermissionScope.All,
  [Permission.ODMatrix]: PermissionScope.All,
  [Permission.Vissim]: PermissionScope.All,
  [Permission.AnprExport]: PermissionScope.All,
  [Permission.PeakHourExport]: PermissionScope.All,
  [Permission.ExportLive]: PermissionScope.All,

  [Permission.PeakPeriodMaxSingle]: PermissionScope.All,
  [Permission.PeakPeriodMaxPie]: PermissionScope.All,
  [Permission.AverageVolumeSingle]: PermissionScope.All,
  [Permission.AverageVolumePie]: PermissionScope.All,
  [Permission.ObjectDistributionLine]: PermissionScope.All,
  [Permission.ObjectDistributionBar]: PermissionScope.All,
  [Permission.ObjectDistributionHeatmap]: PermissionScope.All,
  [Permission.TotalVolumeSingle]: PermissionScope.All,
  [Permission.TotalVolumePie]: PermissionScope.All,
  [Permission.TmcFlowDiagram]: PermissionScope.All,
  [Permission.MeanTravelTimeSingle]: PermissionScope.All,
  [Permission.MeanTravelTimeBar]: PermissionScope.All,
  [Permission.MeanTimeGapSingle]: PermissionScope.All,
  [Permission.MeanTimeGapBar]: PermissionScope.All,
  [Permission.VehicleListTable]: PermissionScope.All,
  [Permission.TrajectoryScene]: PermissionScope.All,
  [Permission.MotionHeatmapScene]: PermissionScope.All,
  [Permission.AccelerationHeatmapScene]: PermissionScope.All,
  [Permission.HoldupHeatmapScene]: PermissionScope.All,
  [Permission.PeakPeriods]: PermissionScope.All,
  [Permission.MeanOccupancyTimeInZoneSingle]: PermissionScope.All,
  [Permission.MeanOccupancyTimeInZonePie]: PermissionScope.All,
  [Permission.Vault]: PermissionScope.All,
  [Permission.VaultPlayback]: PermissionScope.All,
  [Permission.Sync]: PermissionScope.All,
  [Permission.Connect]: PermissionScope.All,
  [Permission.Edge]: PermissionScope.All,
  [Permission.Desktop]: PermissionScope.Empty, // we need to have it disabled even when user is not loaded
  [Permission.Platform]: PermissionScope.Empty, // we need to have it disabled even when user is not loaded
  [Permission.ODFilterGeneration]: PermissionScope.All,
  [Permission.StayEvent]: PermissionScope.All,
  [Permission.StoppedEvent]: PermissionScope.All,
  [Permission.Movement]: PermissionScope.All,
  [Permission.Scenario]: PermissionScope.All,
  [Permission.Metric]: PermissionScope.All,
  [Permission.DataSourceSharing]: PermissionScope.All,
  [Permission.Project]: PermissionScope.All,
  [Permission.Location]: PermissionScope.All,
  [Permission.Export]: PermissionScope.All,
  [Permission.DataSource]: PermissionScope.All,
  [Permission.Dashboard]: PermissionScope.All,
  [Permission.Widget]: PermissionScope.All,
  [Permission.LiveEvent]: PermissionScope.All,
  [Permission.Processing]: PermissionScope.All,
  [Permission.PaymentCredits]: PermissionScope.All,
  [Permission.PaymentAll]: PermissionScope.All,
  [Permission.PaymentBankTransfer]: PermissionScope.All,
  [Permission.PaymentInvoice]: PermissionScope.All,
  [Permission.Organization]: PermissionScope.All,
  [Permission.OrganizationBilling]: PermissionScope.All,
  [Permission.OrganizationSettings]: PermissionScope.All,
  [Permission.OrganizationProjectManagement]: PermissionScope.All,
  [Permission.OrganizationReferrals]: PermissionScope.All,
  [Permission.ServiceGDrive]: PermissionScope.All,
  [Permission.ServiceDropbox]: PermissionScope.All,
  [Permission.ServiceOneDrive]: PermissionScope.All,
  [Permission.TMCEdited]: PermissionScope.All,
  [Permission.FilterDirectionalCross]: PermissionScope.All,
  [Permission.FilterUTurn]: PermissionScope.All,
  [Permission.EventImages]: PermissionScope.All,
  [Permission.GraphicalObjectCardinalDirection]: PermissionScope.All,
  [Permission.GraphicalObjectLock]: PermissionScope.All,
  [Permission.EventIntrusionInstant]: PermissionScope.All,
  [Permission.EventIntrusionPeriod]: PermissionScope.All,
  [Permission.EventAverageSpeedInstant]: PermissionScope.All,
  [Permission.EventAverageSpeedPeriod]: PermissionScope.All,
  [Permission.EventAverageOccupancyPeriod]: PermissionScope.All,
  [Permission.EventAverageHeadwayPeriod]: PermissionScope.All,
  [Permission.EventViolationInstant]: PermissionScope.All,
  [Permission.Admin]: PermissionScope.Empty,
  [Permission.SuperAdmin]: PermissionScope.Empty,
  [Permission.Support]: PermissionScope.Empty,

  [Permission.ScenarioImages]: PermissionScope.All,

  [Permission.DashboardGlobal]: PermissionScope.All,
  [Permission.DashboardLocal]: PermissionScope.All,
  [Permission.DashboardVisibilityPrivate]: PermissionScope.All,
  [Permission.DashboardVisibilityPublic]: PermissionScope.All,
  [Permission.DashboardLive]: PermissionScope.All,
  [Permission.ScenarioIllegalStop]: PermissionScope.All,
  [Permission.ScenarioLicensePlateDetection]: PermissionScope.All,
  [Permission.ScenarioDirectionalCrossDetection]: PermissionScope.All,
  [Permission.ScenarioMultiLineCrossDetection]: PermissionScope.All,
  [Permission.ScenarioAvgSpeedMeasurement]: PermissionScope.All,
  [Permission.ScenarioSpeedLimitViolation]: PermissionScope.All,
  [Permission.ScenarioWrongWayDriving]: PermissionScope.All,
  [Permission.ScenarioAvgTimeGapMeasurement]: PermissionScope.All,
  [Permission.ScenarioLpIllegalParking]: PermissionScope.All,
  [Permission.ScenarioIllegalParking]: PermissionScope.All,
  [Permission.ScenarioAID]: PermissionScope.All,
  [Permission.ScenarioC0CrossroadZoneDetection]: PermissionScope.All,
  [Permission.ScenarioAitlcm]: PermissionScope.All,
  [Permission.ScenarioNodeRed]: PermissionScope.All,
  [Permission.StreamC0]: PermissionScope.All,
  [Permission.StreamTrafficANPR]: PermissionScope.All,
  [Permission.StreamLivePreview]: PermissionScope.All,
  [Permission.DeprecatedGVLTDescription]: PermissionScope.All,

  [Permission.CustomDateTime]: PermissionScope.All,
  [Permission.NotSupported]: PermissionScope.Empty,
};

export const permissionsInverseMapping: PermissionsInverseMapping = {
  [Permission.Stream]: Feature.ProcessingStream,
  [Permission.Drone]: Feature.ProcessingDrone,
  [Permission.ALPR]: Feature.ProcessingAlpr,
  [Permission.Timelapse]: Feature.ProcessingTimelapse,
  [Permission.NoFunds]: Feature.ProcessingNoFunds,
  [Permission.StreamAnpr]: Feature.StreamAnpr,
  [Permission.StreamTraffic]: Feature.StreamTraffic,

  [Permission.TMCExport]: Feature.ExportTmc,
  [Permission.TMCMulti]: Feature.ExportTMCMulti,
  [Permission.VehicleListExport]: Feature.ExportVehicleList,
  [Permission.SatFlowExport]: Feature.ExportSaturationFlow,
  [Permission.ODMatrix]: Feature.ExportODMatrix,
  [Permission.Vissim]: Feature.ExportVissim,
  [Permission.AnprExport]: Feature.ExportANPRVehicleList,
  [Permission.PeakHourExport]: Feature.ExportPeakHour,
  [Permission.ExportLive]: Feature.ExportLive,

  [Permission.PeakPeriodMaxSingle]: Feature.WidgetPeakHourSingle,
  [Permission.PeakPeriodMaxPie]: Feature.WidgetPeakHourPie,
  [Permission.AverageVolumeSingle]: Feature.WidgetAverageVolumeSingle,
  [Permission.AverageVolumePie]: Feature.WidgetAverageVolumePie,
  [Permission.ObjectDistributionLine]: Feature.WidgetObjectDistributionLine,
  [Permission.ObjectDistributionBar]: Feature.WidgetObjectDistributionBar,
  [Permission.ObjectDistributionHeatmap]:
    Feature.WidgetObjectDistributionHeatmap,
  [Permission.TotalVolumeSingle]: Feature.WidgetTotalVolumeSingle,
  [Permission.TotalVolumePie]: Feature.WidgetTotalVolumePie,
  [Permission.TmcFlowDiagram]: Feature.WidgetTmcDiagramFlowGraph,
  [Permission.MeanTravelTimeSingle]: Feature.WidgetMeanTravelTimeSingle,
  [Permission.MeanTravelTimeBar]: Feature.WidgetMeanTravelTimeBar,
  [Permission.MeanTimeGapSingle]: Feature.WidgetMeanTimeGapSingle,
  [Permission.MeanTimeGapBar]: Feature.WidgetMeanTimeGapBar,
  [Permission.VehicleListTable]: Feature.WidgetVehicleListTable,
  [Permission.TrajectoryScene]: Feature.WidgetTrajectoriesScene,
  [Permission.MotionHeatmapScene]: Feature.WidgetMotionHeatmapScene,
  [Permission.AccelerationHeatmapScene]: Feature.WidgetAccelerationHeatmapScene,
  [Permission.HoldupHeatmapScene]: Feature.WidgetHoldupHeatmapScene,
  [Permission.PeakPeriods]: Feature.WidgetPeakPeriodsTable,
  [Permission.MeanOccupancyTimeInZoneSingle]:
    Feature.WidgetMeanOccupancyTimeInZoneSingle,
  [Permission.MeanOccupancyTimeInZonePie]:
    Feature.WidgetMeanOccupancyTimeInZonePie,

  [Permission.Vault]: Feature.ModuleVault,
  [Permission.VaultPlayback]: Feature.ModuleVaultVideoPlayback,
  [Permission.Sync]: Feature.ModuleSync,
  [Permission.Connect]: Feature.ModuleConnect,
  [Permission.Edge]: Feature.ModuleEdge,
  [Permission.Desktop]: Feature.ModuleDesktop,
  [Permission.Platform]: Feature.PlatformPlan,
  [Permission.ODFilterGeneration]: Feature.FilterODGeneration,
  [Permission.StayEvent]: Feature.FilterStay,
  [Permission.StoppedEvent]: Feature.FilterVehicleStopped,
  [Permission.Movement]: Feature.FilterMovement,
  [Permission.Scenario]: Feature.FilterScenario,
  [Permission.Metric]: Feature.MetricLayer,
  [Permission.DataSourceSharing]: Feature.CameraSharing,
  [Permission.Project]: undefined,
  [Permission.Location]: undefined,
  [Permission.Dashboard]: undefined,
  [Permission.Export]: undefined,
  [Permission.DataSource]: undefined,
  [Permission.Widget]: undefined,
  [Permission.LiveEvent]: undefined,
  [Permission.Processing]: undefined,
  [Permission.Admin]: undefined,
  [Permission.SuperAdmin]: undefined,
  [Permission.Support]: undefined,

  [Permission.PaymentCredits]: Feature.PaymentCredits,
  [Permission.PaymentAll]: Feature.PaymentAll,
  [Permission.PaymentBankTransfer]: Feature.PaymentBankTransfer,
  [Permission.PaymentInvoice]: Feature.PaymentInvoice,
  [Permission.Organization]: undefined,
  [Permission.OrganizationBilling]: Feature.OrganizationBilling,
  [Permission.OrganizationSettings]: Feature.OrganizationSettings,
  [Permission.OrganizationProjectManagement]:
    Feature.OrganizationProjectManagement,
  [Permission.OrganizationReferrals]: Feature.OrganizationReferrals,
  [Permission.ServiceGDrive]: Feature.ServiceGDrive,
  [Permission.ServiceDropbox]: Feature.ServiceDropbox,
  [Permission.ServiceOneDrive]: Feature.ServiceOneDrive,
  [Permission.TMCEdited]: Feature.TMCEdited,
  [Permission.EventImages]: Feature.EventImages,
  [Permission.FilterUTurn]: Feature.FilterUTurn,
  [Permission.FilterDirectionalCross]: Feature.FilterDirectionalCross,
  [Permission.GraphicalObjectCardinalDirection]:
    Feature.GraphicalObjectCardinalDirection,
  [Permission.GraphicalObjectLock]: Feature.GraphicalObjectLock,
  [Permission.EventIntrusionInstant]: Feature.EventIntrusionInstant,
  [Permission.EventIntrusionPeriod]: Feature.EventIntrusionPeriod,
  [Permission.EventAverageSpeedInstant]: Feature.EventAverageSpeedInstant,
  [Permission.EventAverageSpeedPeriod]: Feature.EventAverageSpeedPeriod,
  [Permission.EventAverageOccupancyPeriod]: Feature.EventAverageOccupancyPeriod,
  [Permission.EventAverageHeadwayPeriod]: Feature.EventAverageHeadwayPeriod,
  [Permission.EventViolationInstant]: Feature.EventViolationInstant,

  [Permission.DashboardGlobal]: Feature.DashboardGlobal,
  [Permission.DashboardLive]: Feature.DashboardLive,
  [Permission.DashboardLocal]: Feature.DashboardLocal,
  [Permission.DashboardVisibilityPrivate]: Feature.DashboardVisibilityPrivate,
  [Permission.DashboardVisibilityPublic]: Feature.DashboardVisibilityPublic,

  [Permission.NotSupported]: undefined,
  [Permission.StreamLivePreview]: Feature.StreamLivePreview,
  [Permission.ScenarioIllegalStop]: Feature.ScenarioIllegalStop,
  [Permission.ScenarioLicensePlateDetection]:
    Feature.ScenarioLicensePlateDetection,
  [Permission.ScenarioDirectionalCrossDetection]:
    Feature.ScenarioDirectionalCrossDetection,
  [Permission.ScenarioMultiLineCrossDetection]:
    Feature.ScenarioMultiLineCrossDetection,
  [Permission.ScenarioAvgSpeedMeasurement]: Feature.ScenarioAvgSpeedMeasurement,
  [Permission.ScenarioSpeedLimitViolation]: Feature.ScenarioSpeedLimitViolation,
  [Permission.ScenarioWrongWayDriving]: Feature.ScenarioWrongWayDriving,
  [Permission.ScenarioAvgTimeGapMeasurement]:
    Feature.ScenarioAvgTimeGapMeasurement,
  [Permission.ScenarioLpIllegalParking]: Feature.ScenarioLpIllegalParking,
  [Permission.ScenarioIllegalParking]: Feature.ScenarioIllegalParking,
  [Permission.ScenarioNodeRed]: Feature.ScenarioNodeRed,
  [Permission.ScenarioAID]: Feature.ScenarioAID,
  [Permission.ScenarioAitlcm]: Feature.ScenarioAitlcm,
  [Permission.StreamC0]: Feature.StreamC0,
  [Permission.StreamTrafficANPR]: Feature.StreamTrafficANPR,
  [Permission.ScenarioC0CrossroadZoneDetection]:
    Feature.ScenarioC0CrossroadZoneDetection,

  [Permission.ScenarioImages]: Feature.ScenarioImages,
  [Permission.DeprecatedGVLTDescription]: Feature.DeprecatedGVLTDescription,
  [Permission.CustomDateTime]: Feature.CustomDateTime,
};

export function reduce(
  feature: Feature,
  disabledFeatures: readonly Feature[],
  permissions: Permissions,
): Permissions {
  return permissionReducers.reduce((p, reducer): Permissions => {
    if (isFunction(reducer)) {
      return reducer(p, feature, disabledFeatures);
    }

    if (
      !ObjectUtils.hasProperty<PermissionReducerObject, typeof feature>(
        reducer,
        feature,
      )
    ) {
      return p;
    }

    const [permission, permissionScope] = reducer[feature]!;

    return {
      ...p,
      [permission]: permissionScope,
    };
  }, permissions);
}
