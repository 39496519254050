export enum Environment {
  Dev = 'dev',
  DevProd = 'devProd',
  ElectronDev = 'electronDev',
  ElectronDevRun = 'electronDevRun',
  ElectronProduction = 'electronProduction',
  ElectronStaging = 'electronStaging',
  Production = 'production',
  Staging = 'staging',
}
