import { inject, Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IntervalService {
  private ngZone = inject(NgZone);
  private timers: Map<string, ReturnType<typeof setTimeout>> = new Map<
    string,
    ReturnType<typeof setTimeout>
  >();

  /**
   * @param id unique ID
   * @param duration in ms
   * @param callback function to call after each cycle
   * @param outsideNgZone run outside `NgZone`?
   */
  setInterval(
    id: string,
    duration: number,
    callback: () => void,
    outsideNgZone = false,
  ): void {
    const interval = this.createInterval(duration, callback, outsideNgZone);
    this.timers.set(id, interval);
  }

  clearInterval(id: string): void {
    const timer = this.timers.get(id);
    if (timer !== undefined) {
      clearInterval(timer);
    }
    this.timers.delete(id);
  }

  private createInterval(
    duration: number,
    callback: () => void,
    outsideNgZone: boolean,
  ): ReturnType<typeof setTimeout> {
    if (outsideNgZone) {
      return this.ngZone.runOutsideAngular(() => {
        return setInterval(callback, duration);
      });
    }
    return setInterval(callback, duration);
  }
}
