import type { OnDestroy, Signal } from '@angular/core';
import {
  inject,
  Injectable,
  NgZone,
  DestroyRef,
  InjectionToken,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

import type { NotificationInfoDTO } from '@gv/api';
import { API, ClientLogLevel } from '@gv/api';
import { sendLog } from '@gv/client-logger';
import { createComponentLogger } from '@gv/logger';
import { EMPTY_STATE, StoreInject } from '@gv/state';
import { APP_INSTANCE_ID } from '@gv/ui/core';
import { ConnectionStatusCheckerService } from '@gv/ui/utils';
import { isTokenValid, USER_CONTEXT } from '@gv/user';
import {
  delayedConcatMap,
  enterZone,
  extractLogLevel,
  ObjectUtils,
  resettableShareReplay,
  retryWithNext,
  RETRY_WHEN_WITH_NEXT_RETRY,
  simpleSwitchMap,
  toSentryError,
  untilNgDestroyed,
} from '@gv/utils';
import { captureException, withScope } from '@sentry/angular';
import { difference } from 'lodash-es';
import type { MqttClient } from 'mqtt';
import type { MonoTypeOperatorFunction, Subscription } from 'rxjs';
import {
  combineLatest,
  concat,
  defer,
  EMPTY,
  from,
  Observable,
  of,
  queueScheduler,
  Subject,
  throwError,
} from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  defaultIfEmpty,
  delay,
  distinctUntilChanged,
  filter,
  map,
  observeOn,
  pairwise,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs/operators';

import { logger } from '../../logger';
import type { DeviceConfigModel } from '../entity/device/device-config-model';
import type { DeviceEvent } from '../entity/device/device-event';
import type { DeviceInitializationEventModel } from '../entity/device/device-initialization-event-model';
import type { MessageDeviceEventModel } from '../entity/device/message-device-event-model';
import type { ResetDeviceEventModel } from '../entity/device/reset-device-event-model';
import { InvalidCredentialsError } from '../entity/error/invalid-credentials-error';
import { TooManySuccessiveErrorsError } from '../entity/error/too-many-successive-errors-error';
import { createDevice } from './create-device';
import { NotificationsService } from './notifications.service';

export interface NotificationSettings {
  uuid?: Signal<string | undefined>;
  token?: Signal<string | undefined>;
  authorizer?: string;
  authorizerLink?: string;
  skipApiInfo?: boolean;
  autoStart?: boolean;
}

export const NOTIFICATION_SETTINGS = new InjectionToken<NotificationSettings>(
  'notificationSettings',
);

@Injectable({
  providedIn: 'root',
})
export class NotificationListenerService implements OnDestroy {
  private destroyRef = inject(DestroyRef);
  private appInstanceId = inject(APP_INSTANCE_ID);
  private notificationSettings =
    inject(NOTIFICATION_SETTINGS, { optional: true }) ??
    ({} satisfies NotificationSettings);
  private api = inject(API);
  private connectionStatusChecker = inject(ConnectionStatusCheckerService);
  private ngZone = inject(NgZone);
  private userContext = inject(USER_CONTEXT);
  private notificationsService = inject(NotificationsService);
  private store = inject(StoreInject(EMPTY_STATE));
  static readonly networkErrorReconnectInterval: number = 2500;
  static readonly maxFailedReconnectCount: number = 10;
  private static readonly awsRegion: string = 'eu-west-1';

  private logger = createComponentLogger(logger, 'NotificationListenerService');

  private connectionStatusSubscription: Subscription | undefined;
  private deviceEventsSubscription: Subscription | undefined;

  private connectSubject = new Subject<boolean>();

  private uuid$ = this.notificationSettings.uuid
    ? toObservable(this.notificationSettings.uuid)
    : this.userContext.userUuid$;

  private token$ = this.notificationSettings.token
    ? toObservable(this.notificationSettings.token)
    : this.userContext.token$;

  private reconnect$ = this.connectSubject.pipe(
    switchMap((connect) =>
      !connect ? of(false) : this.uuid$.pipe(map((m) => !!m)),
    ),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private deviceObj$: Observable<DeviceInitializationEventModel | undefined> =
    resettableShareReplay(
      this.reconnect$,
      (connect): Observable<DeviceInitializationEventModel | undefined> =>
        !connect
          ? of(undefined)
          : (this.uuid$.pipe(
              simpleSwitchMap(() =>
                this.notificationSettings.skipApiInfo
                  ? of({
                      data: {
                        authorizer: this.notificationSettings.authorizer!,
                        host: this.notificationSettings.authorizerLink!,
                        subscriptionTopic: '',
                        uploadTopic: '',
                        vaultUploadTopic: '',
                      } satisfies NotificationInfoDTO,
                    })
                  : this.api.notificationInfo(),
              ),
              switchMap(({ data }) => {
                return combineLatest([
                  of(data as NotificationInfoDTO),
                  this.token$.pipe(distinctUntilChanged()),
                ]);
              }),
              delayedConcatMap(() => [
                this.uuid$,
                this.userContext.timeOffset$,
              ]),
              switchMap(
                ([[notificationInfo, token], userUuid, timeOffset]): Observable<
                  DeviceInitializationEventModel | undefined
                > => {
                  const clientId = `GV_FE_${userUuid}_${this.appInstanceId}`;
                  const {
                    authorizer,
                    host,
                    subscriptionTopic,
                    uploadTopic,
                    vaultUploadTopic,
                  } = notificationInfo;

                  let index = 0;
                  return !token || !isTokenValid(token, timeOffset)
                    ? of(undefined)
                    : defer(() =>
                        createDevice(
                          {
                            authorizer,
                            clientId: `${clientId}_${index++}`,
                            host,
                            region: NotificationListenerService.awsRegion,
                            subscriptionTopic,
                            uploadTopic,
                            vaultUploadTopic,
                          },
                          token,
                        ),
                      );
                },
              ),
              catchError(
                (
                  error: unknown,
                ): Observable<DeviceInitializationEventModel | undefined> => {
                  this.logger.error({ error }, 'Failed to create device');
                  return throwError(() => error);
                },
              ),

              this.cancelAfterManyErrors(
                NotificationListenerService.maxFailedReconnectCount,
              ),

              this.retryOnDeviceError<
                DeviceInitializationEventModel | undefined
              >(),

              catchError(
                (
                  error,
                ): Observable<DeviceInitializationEventModel | undefined> => {
                  if (error instanceof InvalidCredentialsError) {
                    withScope((scope) => {
                      scope.setLevel('info');
                      scope.setTag('subMessage', 'Credentials expired');
                      captureException(toSentryError('Device::error', error));
                    });
                    return of(undefined);
                  }

                  withScope((scope) => {
                    scope.setLevel(extractLogLevel(error));
                    scope.setTag(
                      'subMessage',
                      error instanceof TooManySuccessiveErrorsError
                        ? 'Notification listener device error occurred - too many reconnect attempts.'
                        : 'Notification listener device error occurred',
                    );
                    captureException(toSentryError('Device::error', error));
                  });

                  return of(undefined);
                },
              ),
              defaultIfEmpty<
                DeviceInitializationEventModel | undefined,
                DeviceInitializationEventModel | undefined
              >(undefined),
              startWith<DeviceInitializationEventModel | undefined>(undefined),

              untilNgDestroyed<DeviceInitializationEventModel | undefined>(
                this.destroyRef,
              ),
            ) as Observable<DeviceInitializationEventModel | undefined>),
    );

  private deviceEvents$ = this.deviceObj$.pipe(
    switchMap((deviceObj) =>
      deviceObj && this.isConnectDeviceEventModel(deviceObj)
        ? deviceObj.events$
        : of({ type: 'reset', connected: false } as ResetDeviceEventModel),
    ),
    untilNgDestroyed(),
  );

  private notificationReceived$ = this.deviceEvents$.pipe(
    filter<DeviceEvent, MessageDeviceEventModel>(
      (v: DeviceEvent): v is MessageDeviceEventModel =>
        !!v && 'type' in v && v.type === 'message' && !!v.data,
    ),
    // TODO: notifications allow notifications based on seat
    // delayedConcatMap(() => [
    //   this.electronRef ? this.electronRef.isAllowed$ : of(true),
    // ]),
    // filter(
    //   ([notification, allowed]) =>
    //     allowed ||
    //     !this.appConfig.permission.disabledDesktopNotifications.includes(
    //       notification.notificationType,
    //     ),
    // ),
    // map(([notification]) => notification),
    observeOn(enterZone(this.ngZone, queueScheduler)),
    untilNgDestroyed(),
  );

  connected$: Observable<boolean> = resettableShareReplay(
    this.deviceObj$,
    this.deviceEvents$.pipe(
      map((v) => v && v.connected),
      startWith(false),
    ),
  ).pipe(
    map((v) => !!v),
    distinctUntilChanged(),
  );

  device$: Observable<{
    device: MqttClient;
    deviceConfig: DeviceConfigModel;
  }> = this.deviceObj$.pipe(
    switchMap((deviceObj) =>
      deviceObj && this.isConnectDeviceEventModel(deviceObj)
        ? of({ device: deviceObj.device, deviceConfig: deviceObj.deviceConfig })
        : EMPTY,
    ),
    untilNgDestroyed(),
    shareReplay(1),
  );

  private topics$ = this.device$.pipe(
    distinctUntilChanged(),
    simpleSwitchMap((device) =>
      this.notificationsService.subscriptionTopics$.pipe(
        debounceTime(0),
        startWith([]),
        pairwise(),
        concatMap(([prevTopics, newTopics]) => {
          const removed = difference(prevTopics, newTopics);
          const added = difference(newTopics, prevTopics);

          const promises: Promise<void>[] = [];
          logger.debug({ added, removed }, 'Subcriptions changes');

          for (const r of removed) {
            promises.push(
              new Promise<void>((resolve) =>
                device.device.unsubscribe(r, undefined, () => resolve()),
              ),
            );
          }

          for (const a of added) {
            promises.push(
              new Promise<void>((resolve) =>
                device.device.subscribe(a, () => resolve()),
              ),
            );
          }

          return promises.length === 0 ? EMPTY : from(Promise.all(promises));
        }),
      ),
    ),
    untilNgDestroyed(),
  );

  constructor() {
    this.notificationReceived$.subscribe((notification) => {
      this.logger.debug({ notification }, 'Notification received');
      return this.notificationsService.postNotification({
        topic: notification.topic,
        notification: notification.data,
      });
    });
    this.topics$.subscribe();

    if (this.notificationSettings.autoStart) {
      this.enable();
    }
  }

  enable(): void {
    if (this.connectionStatusSubscription) {
      this.connectionStatusSubscription.unsubscribe();
    }

    this.deviceEventsSubscription = this.deviceEvents$.subscribe((event) => {
      if (!('type' in event)) {
        return;
      }
      if (event.type === 'message') {
        this.store.dispatch(
          sendLog({
            datetime: new Date().toISOString(),
            level: ClientLogLevel.Info,
            message: `Notification listener device event (type:${
              event.type
            }) (data: ${JSON.stringify(event.data)}).`,
          }),
        );
        return;
      }

      if (event.type === 'error') {
        this.store.dispatch(
          sendLog({
            datetime: new Date().toISOString(),
            level: ClientLogLevel.Info,
            message: `Notification listener device event (type:${
              event.type
            } error:${JSON.stringify(event.error)}).`,
          }),
        );
        return;
      }

      this.store.dispatch(
        sendLog({
          datetime: new Date().toISOString(),
          level: ClientLogLevel.Info,
          message: `Notification listener device event (type:${event.type}).`,
        }),
      );
    });

    this.connectionStatusSubscription =
      this.connectionStatusChecker.statusChanged$
        .pipe(
          distinctUntilChanged(),
          debounceTime(1000),
          startWith(this.connectionStatusChecker.connected),
          untilNgDestroyed(this.destroyRef),
        )
        .subscribe((connected) => {
          this.connectSubject.next(connected);
        });
  }

  disable(): void {
    this.connectSubject.next(false);

    if (this.deviceEventsSubscription) {
      this.deviceEventsSubscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    //
  }

  private isConnectDeviceEventModel(
    val: any,
  ): val is DeviceInitializationEventModel {
    return (
      !!val &&
      ObjectUtils.hasProperty(val, 'device') &&
      ObjectUtils.hasProperty(val, 'events$')
    );
  }

  private cancelAfterManyErrors<T>(limit = 10): MonoTypeOperatorFunction<T> {
    let numberOfSuccessiveErrors = 0;

    return (source: Observable<T>): Observable<T> => {
      return new Observable((subscriber) => {
        return source.subscribe({
          next: (value): void => {
            this.logger.debug(
              '[NotificationListener] cancelAfterManyErrors:reset',
            );
            numberOfSuccessiveErrors = 0;
            subscriber.next(value);
          },
          error: (error): void => {
            if (error instanceof InvalidCredentialsError) {
              subscriber.error(error);
              return;
            }
            this.logger.debug(
              { numberOfSuccessiveErrors },
              '[NotificationListener] cancelAfterManyErrors:error detected',
            );
            numberOfSuccessiveErrors += 1;

            if (numberOfSuccessiveErrors > limit) {
              subscriber.error(
                new TooManySuccessiveErrorsError(
                  'Too many errors occurred',
                  error,
                ),
              );
            } else {
              subscriber.error(error);
            }
          },
          complete(): void {
            subscriber.complete();
          },
        });
      });
    };
  }

  private retryOnDeviceError<T>(): MonoTypeOperatorFunction<T> {
    return retryWithNext<T>((error: unknown, _retryCount: number) => {
      if (
        error instanceof InvalidCredentialsError ||
        error instanceof TooManySuccessiveErrorsError
      ) {
        return throwError(() => error);
      }

      withScope((scope) => {
        scope.setLevel('info');
        scope.setTag(
          'subMessage',
          'Notification listener device error occurred - gonna try to reconnect.',
        );
        captureException(toSentryError('Device::error', error));
      });

      return concat(
        of(undefined),
        of(RETRY_WHEN_WITH_NEXT_RETRY).pipe(
          delay(NotificationListenerService.networkErrorReconnectInterval),
        ),
      ) as Observable<T | typeof RETRY_WHEN_WITH_NEXT_RETRY>;
    });
  }
}
