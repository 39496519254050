import type { OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  inject,
  NgZone,
} from '@angular/core';

import { leaveZone, untilNgDestroyed } from '@gv/utils';
import {
  Observable,
  animationFrameScheduler,
  asyncScheduler,
  combineLatest,
  debounceTime,
  defer,
  fromEvent,
  map,
  observeOn,
  startWith,
  throttleTime,
} from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[gvScrollFadeReversed]',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  template: `<ng-content></ng-content>`,
  styles: [
    `
      :host {
        mask-image: linear-gradient(
          to bottom,
          #ffffff88 0,
          white var(--bottom-mask-size, 0px),
          white calc(100% - var(--top-mask-size, 0px)),
          #ffffff88 100%
        );

        &.fade-top {
          --top-mask-size: 48px;
        }

        &.fade-bottom {
          --bottom-mask-size: 48px;
        }
      }
    `,
  ],
})
export class ScrollFadeReversedComponent implements OnDestroy, OnInit {
  // host bindings are part of parent view; so we need parent view cdr
  private cdr = inject(ChangeDetectorRef, { skipSelf: true });
  private ngZone = inject(NgZone);
  private el = inject<ElementRef<HTMLElement>>(ElementRef);

  private _fadeTop = false;

  @HostBinding('class.fade-top')
  get fadeTop(): boolean {
    return this._fadeTop;
  }

  set fadeTop(value: boolean) {
    if (this._fadeTop === value) {
      return;
    }
    this._fadeTop = value;
    this.cdr.detectChanges();
  }

  private _fadeBottom = false;

  @HostBinding('class.fade-bottom')
  get fadeBottom(): boolean {
    return this._fadeBottom;
  }

  set fadeBottom(value: boolean) {
    if (this._fadeBottom === value) {
      return;
    }
    this._fadeBottom = value;
    this.cdr.detectChanges();
  }

  private scroll$ = defer(() =>
    fromEvent(this.el.nativeElement, 'scroll', { passive: true }),
  ).pipe(
    observeOn(leaveZone(this.ngZone, asyncScheduler)),
    throttleTime(1000 / 60, animationFrameScheduler, {
      trailing: true,
      leading: true,
    }),
    untilNgDestroyed(),
  );

  private onResize$ = new Observable((obs) => {
    const resizeObserver = new ResizeObserver(() => {
      obs.next();
    });

    resizeObserver.observe(this.el.nativeElement);

    return () => resizeObserver.disconnect();
  }).pipe(
    map(() => this.el.nativeElement.getBoundingClientRect().height),
    untilNgDestroyed(),
  );

  ngOnInit(): void {
    combineLatest([this.onResize$, this.scroll$.pipe(startWith(undefined))])
      .pipe(debounceTime(100))
      .subscribe(() => {
        const el = this.el.nativeElement;
        if (!el) {
          return;
        }

        const isScrollable = el.scrollHeight > el.clientHeight;
        if (!isScrollable) {
          this.fadeBottom = false;
          this.fadeTop = false;
          return;
        }

        this.fadeBottom = el.scrollHeight > el.clientHeight + el.scrollTop;
        this.fadeTop = el.scrollTop !== 0;
      });
  }

  ngOnDestroy(): void {
    //
  }
}
