import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import type { ModuleWithProviders, Renderer2 } from '@angular/core';
import {
  inject,
  Injectable,
  NgModule,
  PLATFORM_ID,
  RendererFactory2,
  ViewEncapsulation,
} from '@angular/core';

@Injectable()
export class IntercomConfig {
  appId: string | undefined;
  updateOnRouterChange?: boolean;
  /**
   * Customize left or right position of messenger
   */
  alignment?: 'left' | 'right';
  /**
   * Customize horizontal padding
   */
  horizontal_padding?: number;

  /**
   * Customize vertical padding
   */
  vertical_padding?: number;

  /**
   * arbitrarily localize your intercom messenger
   */
  language_override?: string;
}

export interface BootInput {
  custom_launcher_selector?: string;
  user_id?: string;
  email?: string;
  /**
   * If no app_id is passed, the value on config will be used
   */
  app_id?: string;
  [propName: string]: any;
}

/**
 * A provider with every Intercom.JS method
 */
@Injectable()
export class Intercom {
  private config = inject(IntercomConfig);
  platformId = inject(PLATFORM_ID);
  private rendererFactory = inject(RendererFactory2);
  private document = inject(DOCUMENT, { optional: true });
  private id: string | undefined;

  private renderer2!: Renderer2;

  constructor() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.renderer2 = this.rendererFactory.createRenderer(this.document, {
      id: '-1',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {},
    });
  }

  public boot(intercomData: BootInput): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const app_id = intercomData.app_id
      ? intercomData.app_id
      : this.config.appId;
    // Run load and attach to window
    this.loadIntercom(this.config, () => {
      // then boot the intercom js
      const data = {
        ...intercomData,
        app_id,
      };

      return this._callIntercom<void>('boot', data);
    });
  }

  public shutdown(): void {
    return this._callIntercom('shutdown');
  }

  public update(data?: any): void {
    return this._callIntercom('update', data);
  }

  public hide(): void {
    return this._callIntercom('hide');
  }

  public show(message?: string): void {
    if (message) {
      return this.showNewMessage(message);
    }
    return this._callIntercom('show');
  }

  public showMessages(): void {
    return this._callIntercom('showMessages');
  }

  public showNewMessage(message?: string): void {
    return this._callIntercom('showNewMessage', message);
  }

  public trackEvent(eventName: string, metadata?: any): void {
    return this._callIntercom('trackEvent', eventName, metadata);
  }

  public getVisitorId(): string {
    return this._callIntercom<string>('getVisitorId')!;
  }

  get visitorId(): string {
    return this._callIntercom<string>('getVisitorId')!;
  }

  public onShow(handler: () => void): void {
    return this._callIntercom('onShow', handler);
  }

  public onHide(handler: () => void): void {
    return this._callIntercom('onHide', handler);
  }

  public onUnreadCountChange(handler: (unreadCount?: number) => void): void {
    return this._callIntercom('onUnreadCountChange', handler);
  }

  public startTour(tourId: number): void {
    return this._callIntercom('startTour', tourId);
  }

  private _callIntercom<T>(fn: string, ...args: any[]): T | undefined {
    if (!isPlatformBrowser(this.platformId)) {
      return undefined;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if ((<any>window).Intercom) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      return (<any>window).Intercom(fn, ...args);
    }
    return undefined;
  }

  injectIntercomScript(
    conf: IntercomConfig,
    afterInjectCallback: (ev: Event) => any,
  ): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    (<any>window).intercomSettings = conf;
    const s = this.document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = `https://widget.intercom.io/widget/${this.id!}`;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if ((<any>s).attachEvent) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      (<any>s).attachEvent('onload', afterInjectCallback);
    } else {
      s.addEventListener('load', afterInjectCallback, false);
    }

    if (this.renderer2 && this.renderer2.appendChild) {
      this.renderer2.appendChild(this.document.head, s);
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    (<any>window).Intercom('update', conf);
  }

  loadIntercom(
    config: IntercomConfig,
    afterLoadCallback: (ev?: Event) => any,
  ): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    /* eslint-disable @typescript-eslint/no-unsafe-call */
    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    /* eslint-disable @typescript-eslint/no-unsafe-assignment */
    this.id = config.appId;
    const w = <any>window;
    const ic = w.Intercom;

    // Set window config for Intercom
    w.intercomSettings = config;

    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', config);
      afterLoadCallback();
    } else {
      const i: any = function () {
        // eslint-disable-next-line prefer-rest-params
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args: any) {
        i.q.push(args);
      };
      w.Intercom = i;
      this.injectIntercomScript(config, afterLoadCallback);
    }
  }
}

@NgModule({
  providers: [Intercom, IntercomConfig],
})
export class IntercomModule {
  static forRoot(config: IntercomConfig): ModuleWithProviders<IntercomModule> {
    return {
      ngModule: IntercomModule,
      providers: [Intercom, { provide: IntercomConfig, useValue: config }],
    };
  }
}
