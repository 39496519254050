export class LocalizationUtils {
  static getCurrentUrlLanguage(): string {
    const parts = document.baseURI?.split('/').filter((part) => !!part);
    return parts[parts.length - 1];
  }

  static getServiceWorkerContext(): string {
    const base = `${document.location.protocol}//${document.location.host}`;

    const index = document.baseURI.indexOf(base);

    if (index !== -1) {
      return document.baseURI.slice(base.length);
    }

    const parts = document.baseURI?.split('/').filter((part) => !!part);
    return `/${parts[parts.length - 1]}/`;
  }
}
