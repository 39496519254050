import type { OnDestroy } from '@angular/core';
import { inject, Injectable, NgZone } from '@angular/core';

import { API_URL } from '@gv/api';
import {
  BASE_PERMISSION_SERVICE,
  Permission,
  PermissionScope,
} from '@gv/permission';
import type { FieldPath, PathValue } from '@gv/utils';
import { enterZone } from '@gv/utils';
import type { MonoTypeOperatorFunction } from 'rxjs';
import { asyncScheduler, Observable, Subject } from 'rxjs';
import { filter, observeOn, take } from 'rxjs/operators';

import type { MainProcessApiModel } from './entity/electron-api-model';
import type { FrontendApiModel } from './entity/frontend-api-model';

export type Imports = {
  SubjectClass: typeof Subject;
  ObservableClass: typeof Observable;
  // eslint-disable-next-line deprecation/deprecation
  filter: typeof filter;
  take: typeof take;
  observeOnZone?: <T>() => MonoTypeOperatorFunction<T>;
};

type FunctionType<T> = T extends (...args: any) => any ? T : never;

interface ElectronWindow extends Window {
  readonly electronApi: MainProcessApiModel;
  readonly electronApiRef: {
    register<T extends object, K extends FieldPath<T>>(
      channel: K,
      cb: FunctionType<PathValue<T, K>>,
    ): void;
  };
  readonly initElectronApi: (imports: Imports) => void;

  readonly appConfig: {
    readonly production: boolean;
    readonly autoUpdate: boolean;
    readonly enableLoader: boolean;
  };
}

@Injectable()
export class ElectronRefService implements OnDestroy {
  private permissionService = inject(BASE_PERMISSION_SERVICE);
  private ngZone = inject(NgZone);
  private _apiUrl = inject(API_URL);
  readonly isAllowed$ = this.permissionService.isAllowed$(
    Permission.Desktop,
    PermissionScope.Use,
  );

  get apiUrl(): string {
    return this._apiUrl;
  }

  get api(): MainProcessApiModel {
    return this.window.electronApi;
  }

  get deeplinkingProtocol(): string {
    return window.location.hostname.includes('desktop.dev')
      ? 'gv-dev:'
      : window.location.hostname.includes('desktop.staging')
        ? 'gv-staging:'
        : 'gv:';
  }

  private onDeepLinkOpenedSubject = new Subject<string>();

  onDeepLinkOpened$ = this.onDeepLinkOpenedSubject.asObservable();

  constructor() {
    this.window.initElectronApi({
      ObservableClass: Observable,
      SubjectClass: Subject,
      // eslint-disable-next-line deprecation/deprecation
      filter: filter,
      take: take,
      observeOnZone: <T>(): MonoTypeOperatorFunction<T> =>
        observeOn<T>(enterZone(this.ngZone, asyncScheduler)),
    });
    this.register('deeplink.onActive', (link) => {
      this.ngZone.run(() => {
        this.onDeepLinkOpenedSubject.next(link);
      });
      return Promise.resolve();
    });
  }

  register<E extends FrontendApiModel, K extends FieldPath<E>>(
    channel: K,
    cb: Parameters<
      typeof ElectronRefService.prototype.window.electronApiRef.register<E, K>
    >[1],
  ): void {
    this.window.electronApiRef.register<E, K>(channel, cb);
  }

  ngOnDestroy(): void {
    //
  }

  private get window(): ElectronWindow {
    return window as unknown as ElectronWindow;
  }

  get desktopAppConfig(): ElectronWindow['appConfig'] | null {
    return (window as unknown as ElectronWindow).appConfig;
  }
}
