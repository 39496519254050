import type { OnDestroy } from '@angular/core';
import { Injectable, Injector, inject } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { toObservable } from '@angular/core/rxjs-interop';

import { appFullVersion } from '@gv/constant';
import { SessionStorageService, WindowRefService } from '@gv/ui/core';
import { isUndefinedOrNull, toSentryError, untilNgDestroyed } from '@gv/utils';
import { captureException, withScope } from '@sentry/browser';
import type { Observable } from 'rxjs';
import { concat, EMPTY, from, of, Subject } from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  filter,
  ignoreElements,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { BaseUpdateCheckerService } from '@gv/ui/utils';

import { logger } from '../../../../logger';
@Injectable()
export class WebAppUpdateCheckerService
  extends BaseUpdateCheckerService
  implements OnDestroy
{
  private windowRef = inject(WindowRefService);
  private swUpdate = inject(SwUpdate);
  private injector = inject(Injector);
  private sessionStorageService = inject(SessionStorageService);
  private static readonly lastVersionKey =
    'WebAppUpdateCheckerService.lastVersion';

  private updateTriggerSubject = new Subject<boolean>();

  private updateInProgress$: Observable<readonly [boolean, boolean]> =
    this.updateTriggerSubject.pipe(
      concatMap((triggeredByApi) =>
        concat(
          of([true, false] as const),
          from(this._checkForUpdates()).pipe(
            ignoreElements(),
            catchError(() => EMPTY),
          ),
          of([false, triggeredByApi] as const),
        ),
      ),
      untilNgDestroyed(),
    );

  private downloadSubject = new Subject<void>();

  private downloadingProgress$ = this.downloadSubject.pipe(
    switchMap(() =>
      concat(
        of(true),
        of(undefined).pipe(
          delay(90000),
          ignoreElements(),
          takeUntil(
            toObservable(this.stateS, { injector: this.injector }).pipe(
              filter((state) => state.ready),
            ),
          ),
        ),
        of(false),
      ),
    ),
    untilNgDestroyed(),
  );

  private versionUpdates$ =
    this.swUpdate.versionUpdates.pipe(untilNgDestroyed());

  constructor() {
    super();
    this.initializeEventHandlers();
  }

  ngOnDestroy(): void {
    //
  }

  private initializeEventHandlers(): void {
    this.updateInProgress$.subscribe(([checking, triggeredByApi]) => {
      this.stateS.update((state) => ({
        ...state,
        checking,
        available: (!checking && triggeredByApi) || state.available, // there is no way to check if update is available
      }));
    });

    this.downloadingProgress$.subscribe((downloading) => {
      this.stateS.update((state) => ({
        ...state,
        downloading: isUndefinedOrNull(downloading)
          ? state.downloading
          : downloading,
        ready: downloading === false || state.ready,
      }));
    });

    this.versionUpdates$.subscribe((event) => {
      if (event.type === 'VERSION_READY') {
        this.stateS.update((state) => ({
          ...state,
          ready: true,
          available: true,
          data: event.latestVersion.appData as {
            version: string;
            forceUpdate: boolean;
          },
        }));
      }
    });

    if (
      this.sessionStorageService.getItem(
        WebAppUpdateCheckerService.lastVersionKey,
      ) === appFullVersion
    ) {
      withScope((scope) => {
        scope.setLevel('warning');
        captureException(
          toSentryError(
            'WebAppUpdateCheckerService::init',
            new Error('InvalidUpdate'),
          ),
        );
      });
    }
  }

  private reload(): Promise<void> {
    if (this.windowRef.nativeWindow) {
      this.sessionStorageService.setItem(
        WebAppUpdateCheckerService.lastVersionKey,
        appFullVersion,
      );

      this.windowRef.nativeWindow.location.reload();
    }

    return Promise.resolve();
  }

  activateUpdate(): Promise<void> {
    if (!this.swUpdate.isEnabled) {
      return this.reload();
    }

    return this.swUpdate
      .activateUpdate()
      .catch((error) => {
        logger.error({ error }, 'Failed to activated the app update.');
      })
      .then(() => {
        return this.reload();
      });
  }

  downloadUpdate(): Promise<void> {
    if (!this.swUpdate.isEnabled) {
      this.activateUpdate();
      return Promise.resolve();
    }

    this.downloadSubject.next();

    return Promise.resolve();
  }

  checkForUpdates(triggeredByApi: boolean): Promise<boolean> {
    this.updateTriggerSubject.next(triggeredByApi);

    return Promise.resolve(true);
  }

  private async _checkForUpdates(): Promise<boolean> {
    try {
      if (this.swUpdate.isEnabled) {
        await this.swUpdate.checkForUpdate();
      }
    } catch (error) {
      logger.error({ error }, 'Failed to check for app update');
    }

    return true;
  }
}
