<app-base-sign-page>
  @if (isRequestVerifiedLoaded) {
    <div class="flex w-full min-w-0 flex-col items-center space-y-6">
      <div class="flex">
        @if (SlugHashUtils.hasSlug() && !noOrgLogo.getValue()) {
          <img
            class="mr-2 h-6 w-6"
            [src]="SlugHashUtils.getSquareLogoUrl()"
            (error)="noOrgLogo.next(true)"
          />
        } @else {
          <img class="mr-2 h-6 w-6" src="./assets/images/logo.png" />
        }
        <div
          class="text-xl font-medium uppercase text-text"
          i18n="@@restore-password.setup-password"
        >
          Set up a new password
        </div>
      </div>
      <!-- User email -->
      @if (email) {
        <div class="w-full truncate text-center text-text">
          {{ email }}
        </div>
      }
      <!-- Password requirements -->
      <div class="text-xs text-text" [ngClass]="{ hidden: !isRequestVerified }">
        <div class="flex items-center">
          <mat-icon
            class="mr-4"
            [ngClass]="{
              'text-color-primary': passwordValidator.containsUpperCase(
                formGroup.controls['password'].value
              )
            }"
          >
            navigate_next
          </mat-icon>
          <ng-container
            i18n="@@restore-password.password-requirement-uppercase"
          >
            Must contain 1 upper case letter (A-Z)
          </ng-container>
        </div>
        <div class="flex items-center">
          <mat-icon
            class="mr-4"
            [ngClass]="{
              'text-color-primary': passwordValidator.containsLowerCase(
                formGroup.controls['password'].value
              )
            }"
          >
            navigate_next
          </mat-icon>
          <ng-container
            i18n="@@restore-password.password-requirement-lowercase"
          >
            Must contain 1 lower case letter (a-z)
          </ng-container>
        </div>
        <div class="flex items-center">
          <mat-icon
            class="mr-4"
            [ngClass]="{
              'text-color-primary': passwordValidator.containsNumber(
                formGroup.controls['password'].value
              )
            }"
          >
            navigate_next
          </mat-icon>
          <ng-container i18n="@@restore-password.password-requirement-numbers">
            Must contain 1 number (0-9)
          </ng-container>
        </div>
        <div class="flex items-center">
          <mat-icon
            class="mr-4"
            [ngClass]="{
              'text-color-primary': passwordValidator.isLongerThanSixChars(
                formGroup.controls['password'].value
              )
            }"
          >
            navigate_next
          </mat-icon>
          <ng-container i18n="@@restore-password.password-requirement-length">
            Minimum length is 12
          </ng-container>
        </div>
      </div>
      @if (!isRequestVerified) {
        <div
          class="text-center text-sm text-red-500"
          i18n="@@restore-password.verification-failed"
        >
          Failed to verify forgotten password request. Please try to open the
          link from the forgotten password email again. In case of further
          problems, contact us via live chat or by email
          support&#64;goodvisionlive.com.
        </div>
      }
      <form
        [formGroup]="formGroup"
        class="flex w-full flex-col pt-6"
        [ngClass]="{ hidden: !isRequestVerified }"
        method="POST"
      >
        <mat-form-field class="w-full">
          <mat-label i18n="@@restore-password.password">Password</mat-label>
          <input
            matInput
            type="password"
            formControlName="password"
            reverse="true"
            i18n-placeholder="@@restore-password.password"
            placeholder="Password"
            autocomplete="new-password"
            required
          />
          @if (formGroup.controls.password?.errors?.zodError) {
            <gv-validation-error>{{
              formGroup.controls.password!.errors!.zodError
            }}</gv-validation-error>
          }
        </mat-form-field>
        <mat-form-field class="mt-2 w-full">
          <mat-label i18n="@@restore-password.password-repeat"
            >Password again</mat-label
          >
          <input
            matInput
            formControlName="passwordRepeated"
            type="password"
            autocomplete="new-password"
            i18n-placeholder="@@restore-password.password-repeat"
            placeholder="Password again"
            required
          />
          @if (formGroup.controls.passwordRepeated?.errors?.zodError) {
            <gv-validation-error>{{
              formGroup.controls.passwordRepeated!.errors!.zodError
            }}</gv-validation-error>
          }
        </mat-form-field>
        <app-button
          type="submit"
          class="green mt-10"
          [disabled]="formGroup.invalid || submitting.value"
          (btnClick)="savePassword()"
          [submitting]="submitting.value"
        >
          <div class="py-1" i18n="@@restore-password.login">Save and login</div>
          <ng-template appSubmittingText>
            <div class="py-1" i18n="@@button.logging-in"> Logging in... </div>
          </ng-template>
        </app-button>
      </form>
    </div>
  }
</app-base-sign-page>
