const regex = /[xy]/g;

const template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

function mapper(c: string): string {
  const r = (Math.random() * 16) | 0;
  const v = c === 'x' ? r : (r & 0x3) | 0x8;
  return v.toString(16);
}

export function generateUuid(): string {
  return template.replace(regex, mapper);
}
