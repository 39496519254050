import { Injectable, inject } from '@angular/core';

import type { ApiResponse } from '@gv/api';
import type { RawAxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import type { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { JWT_INTERCEPTOR_WHITELIST } from '../../../../entity/token/jwt-interceptor-whitelist';
import type { Retryable } from '../../../../entity/type/http/retryable';
import { TokenStoreService } from '../../../application/user/token-store.service';
import type { RequestConfig } from '../http';
import type { RequestHandler, RequestInterceptor } from '../interceptor';

@Injectable({
  providedIn: 'root',
})
export class JwtInterceptor implements RequestInterceptor {
  private whitelist = inject(JWT_INTERCEPTOR_WHITELIST);
  private tokenStore = inject(TokenStoreService);

  intercept(
    request: RequestConfig<any>,
    next: RequestHandler,
  ): Observable<ApiResponse<any>> {
    if (
      !this.mustRequestUseToken(request) ||
      request.headers?.['authorization']
    ) {
      return next.handle(request);
    }

    return this.tokenStore.tokenAfterRefresh$.pipe(
      take(1),
      switchMap((token) =>
        next.handle(JwtInterceptor.requestWithToken(request, token)),
      ),
    );
  }

  private mustRequestUseToken(request: RequestConfig<any>): boolean {
    if ((request as unknown as Retryable).bypassJwt) {
      return false;
    }

    const parsedUrl = request.url.split('?')[0];

    return (
      this.whitelist.findIndex((whitelistItem) => {
        return typeof whitelistItem === 'string'
          ? whitelistItem === parsedUrl
          : whitelistItem.test(parsedUrl);
      }) === -1
    );
  }

  static requestWithToken(
    request: RequestConfig<any>,
    token: string,
  ): RequestConfig<any>;
  static requestWithToken(
    request: Partial<RawAxiosRequestConfig<any>>,
    token: string,
  ): Partial<RequestConfig<any>>;
  static requestWithToken(
    request: RequestConfig<any>,
    token: string,
  ): RequestConfig<any> {
    const headers = request.headers;
    if (
      headers &&
      'authorization' in headers &&
      headers.authorization === `Bearer ${token}`
    ) {
      return request;
    }
    return {
      ...request,
      headers: {
        ...(request.headers || {}),
        authorization: `Bearer ${token}`,
      } as RawAxiosRequestHeaders,
    };
  }
}
