/**
 * This is a partially generated file. Some edits may be overwritten!
 * @apiRef {@model SortBy}
 */
export enum SortBy {
  DtCreated = 'DT_CREATED',
  Name = 'NAME',
  DtModified = 'DT_MODIFIED',
  State = 'STATE',
  Size = 'SIZE',
  DtUploaded = 'DT_UPLOADED',
}
