import { InjectionToken, Injectable, inject } from '@angular/core';
import type { OnDestroy, Signal } from '@angular/core';

import type { I18nResourcesModel, SupportedLanguage } from '@gv/api';
import type { Observable, ObservedValueOf, Subject } from 'rxjs';

import { untilNgDestroyed } from '../operator';

export const LOCALE = new InjectionToken<Subject<SupportedLanguage>>('locale');
export const USER_LOCALE = new InjectionToken<Signal<SupportedLanguage>>(
  'userLocale',
);
export const TRANSLATION_LOADER = new InjectionToken<TranslationLoaderService>(
  'translationLoader',
);

export interface TranslationLoaderService {
  register(locale: Observable<SupportedLanguage>): Promise<void>;
  getData(
    locale: SupportedLanguage,
  ): I18nResourcesModel & Record<string, string>;
}

@Injectable()
export class TranslationService implements OnDestroy {
  private locale = inject(LOCALE);
  private loader = inject(TRANSLATION_LOADER);

  private loaded: Promise<void>;
  private _locale: ObservedValueOf<typeof this.locale> | undefined;

  constructor() {
    this.loaded = this.loader.register(this.locale);
    this.locale.pipe(untilNgDestroyed()).subscribe((l) => (this._locale = l));
  }

  get(key: string): string {
    if (!this._locale) {
      throw new Error('Translations not loaded');
    }
    return this.loader.getData(this._locale)[key];
  }

  ready(): Promise<void> {
    return this.loaded;
  }

  ngOnDestroy(): void {
    //
  }
}

export function _t(
  key: string,
  opts?: {
    data?: Record<string, string>;
  },
  s = inject(TranslationService),
): string {
  const msg = s.get(key);
  if (opts?.data) {
    // TODO:
  }
  return msg || key;
}
