import { Injectable } from '@angular/core';

import { isObject } from '@gv/utils';
import type {
  Event,
  EventHint,
  EventProcessor,
  Hub,
  Integration,
} from '@sentry/types';

/**
 * We usually change error name to provide some kind of context.
 * However, sentry default handling ingores error name for DomExceptions
 * and it just extracts it from the error message.
 * Therefore we just set use name manually for all sentry events.
 */
@Injectable({
  providedIn: 'root',
})
export class SentryTypeIntegrationService implements Integration {
  name = 'TypeIntegration';

  static id = 'TypeIntegration';

  setupOnce(
    addGlobalEventProcessor: (callback: EventProcessor) => void,
    getCurrentHub: () => Hub,
  ): void {
    addGlobalEventProcessor((event: Event, hint?: EventHint) => {
      const self = getCurrentHub().getIntegration(SentryTypeIntegrationService);
      if (self) {
        if (
          hint &&
          hint.originalException &&
          isObject(hint.originalException) &&
          hint.originalException.name
        ) {
          if (
            event.exception &&
            event.exception.values &&
            event.exception.values.length > 0 &&
            event.exception.values[0].type !== hint.originalException.name
          ) {
            event.exception.values[0].type = `${
              hint.originalException.name as string
            }`;
          }
        }
      }

      return event;
    });
  }
}
