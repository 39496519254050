import type { MatDialogRef } from '@angular/material/dialog';

import { Subject } from 'rxjs';

export const dialogSynchronizer$ = new Subject<{
  action: 'add' | 'remove';
  type: string;
  data?: Record<string, any>;
  ref?: MatDialogRef<unknown, unknown>;
}>();
