import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';

import { UtilEffects } from './../../store/effect/util.effects';
import { environment } from '../../../environments/environment';
import { UtilDialogEffects } from '../../store/effect/dialog/util-dialog.effects';
import { UpdateDialogComponent } from '../../component/dialog/update/update-dialog/update-dialog.component';

@NgModule({
  imports: [
    UpdateDialogComponent,
    ...(environment.test
      ? []
      : [EffectsModule.forFeature([UtilDialogEffects, UtilEffects])]),
  ],
  declarations: [],
})
export class UtilDialogsStoreModule {}
