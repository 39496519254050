export enum ToastType {
  info,
  warning,
  error,
}

export const toastTypeMapping = {
  [ToastType.info]: 'text-active',
  [ToastType.warning]: 'text-orange',
  [ToastType.error]: 'text-red-500',
};

export interface ToastModel {
  readonly uuid: string;
  readonly type: ToastType;
  readonly icon?: string;
  readonly svgIcon?: string;
  readonly title?: string;
  readonly content?: string;
  readonly delay?: number;
  readonly closable?: boolean;
  readonly action?: () => void;
  readonly actionButton?: string;
}
