import { inject, Injectable } from '@angular/core';
import type { FormGroup } from '@angular/forms';

import type {
  CameraNetworkInterface,
  DeviceNetworks,
  DeviceStreamDTO,
  DeviceStreamState,
  EditDevice,
} from '@gv/api';
import {
  API,
  DeviceStreamAction,
  DeviceStatus,
  DeviceStreamStatus,
} from '@gv/api';
import { NotificationsService } from '@gv/notification';
import type { BasePermissionService } from '@gv/permission';
import {
  BASE_PERMISSION_SERVICE,
  Permission,
  PermissionScope,
} from '@gv/permission';
import type { FieldsOfType, FormMappingType } from '@gv/state';
import {
  UrlHighlighter,
  StoreInject,
  asMutable,
  FormBaseStore,
} from '@gv/state';
import type { Validation } from '@gv/ui/form';
import { FormUtils } from '@gv/ui/form';
import { USER_CONTEXT } from '@gv/user';
import type { MapToType } from '@gv/utils';
import { delayedConcatMap, simpleSwitchMap, untilNgDestroyed } from '@gv/utils';
import { tapResponse } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import type { Draft } from 'immer';
import { produce } from 'immer';
import { isEqual } from 'lodash-es';
import type { Observable } from 'rxjs';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  filter,
  forkJoin,
  interval,
  of,
  throwError,
  timeout,
  TimeoutError,
} from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import { fromDeviceState } from '..';
import { logger } from '../../../logger';
import {
  deleteEdgeStreamDialog,
  editEdgeStreamDialog,
  newEdgeStreamDialog,
  updateEdgeStreamStateDialog,
  validateStreamFlow,
  verifyEdgeStreamDialog,
} from '../actions';
import type { DeviceStateModel, DeviceStreamStateModel } from '../state';
import { DEVICES_FEATURE_STATE } from '../state';

export type Validable = {
  readonly valid: boolean;
};

export type Editable = {
  readonly disabled: boolean;
  readonly disabledReason?: string;
  readonly touched: boolean;
};

export type Value<T = string> = {
  readonly value: T | null | undefined;
};

export interface EditDeviceState {
  readonly device: DeviceStateModel | undefined;
  readonly highlightedStream: string | undefined;

  readonly name: Editable & Value;
  readonly liveEvents: Editable & Value<boolean>;
  readonly offlineData: Editable & Value<boolean>;

  readonly streams: {
    readonly loaded: boolean;
    readonly loading: boolean;
    readonly data: readonly DeviceStreamStateModel[];
  };

  readonly discovered: {
    readonly loaded: boolean;
    readonly loading: boolean;
    readonly data: DeviceNetworks | undefined;
  };
}

export interface EditDeviceFormModel {
  name: string | null;
  liveEvents: boolean | null;
  offlineData: boolean | null;
}

export type EditDeviceFormFieldEditable = MapToType<
  EditDeviceFormModel,
  boolean
>;

const initialEditableStrValue: Editable & Value<unknown> = {
  touched: false,
  disabled: true,
  value: null,
};

type EditableFields = FieldsOfType<EditDeviceState, Editable>;

const editableFields: readonly EditableFields[] = [
  'name',
  'liveEvents',
  'offlineData',
] as const;

const editableFieldsTextMapping: Record<EditableFields, string> = {
  name: $localize`:@@edit-device.device-name:Live Traffic Unit name`,
  liveEvents: $localize`:@@edit-device.live-events:Live Events`,
  offlineData: $localize`:@@edit-device.offline-data:Offline Data`,
};

const _setDevice = (
  _state: EditDeviceState,
  device: DeviceStateModel,
  permissionService: BasePermissionService,
): EditDeviceState => {
  const isAble = permissionService.isAble(
    Permission.Edge,
    PermissionScope.Edit,
  );

  return {
    device,
    highlightedStream: _state.highlightedStream,

    name: {
      disabled: !isAble[0],
      touched: false,
      value: device.name || null,
      disabledReason: !isAble[0] ? isAble[1].reason : undefined,
    },

    offlineData: {
      disabled: !isAble[0],
      touched: false,
      value: false, // TODO:
      disabledReason: !isAble[0] ? isAble[1].reason : undefined,
    },

    liveEvents: {
      disabled: !isAble[0],
      touched: false,
      value: false, // TODO:
      disabledReason: !isAble[0] ? isAble[1].reason : undefined,
    },

    streams: {
      loaded: false,
      loading: false,
      data: [],
    },

    discovered: {
      loaded: false,
      loading: false,
      data: undefined,
    },
  };
};

const _setName = (
  state: EditDeviceState,
  value: string | null,
): EditDeviceState => {
  const name = value || '';

  return state.name.value === name
    ? state
    : {
        ...state,
        name: {
          ...state.name,
          value: name,
          touched: !(name === state.device?.name),
        },
      };
};

const _setLiveEvents = (
  state: EditDeviceState,
  value: boolean | null,
): EditDeviceState => {
  const v = value || false;

  return state.liveEvents.value === v
    ? state
    : {
        ...state,
        liveEvents: {
          ...state.liveEvents,
          value: v,
          touched: true, // TODO:
        },
      };
};

const _setOfflineData = (
  state: EditDeviceState,
  value: boolean | null,
): EditDeviceState => {
  const v = value || false;

  return state.offlineData.value === v
    ? state
    : {
        ...state,
        offlineData: {
          ...state.offlineData,
          value: v,
          touched: true, // TODO:
        },
      };
};

export const STREAM_HIGHLIGHT_KEY = 'stream';

@Injectable()
export class EditDeviceStore extends FormBaseStore<
  EditDeviceFormModel,
  EditDeviceState
> {
  private notificationsService = inject(NotificationsService);
  private highlighter = inject(UrlHighlighter);
  private actions$ = inject<Actions>(Actions);
  private permissionService = inject(BASE_PERMISSION_SERVICE);
  private store = inject(StoreInject(DEVICES_FEATURE_STATE));
  private api = inject(API);
  private userContext = inject(USER_CONTEXT);
  _stateToForm: (state: EditDeviceState) => EditDeviceFormModel = (state) => ({
    name: state.name.value || null,
    liveEvents: state.liveEvents.value || null,
    offlineData: state.offlineData.value || null,
  });

  _formMapping: FormMappingType<EditDeviceFormModel, EditDeviceState> = {
    name: _setName,
    liveEvents: _setLiveEvents,
    offlineData: _setOfflineData,
  };

  private enableDeviceSynchronizationSubject = new BehaviorSubject<boolean>(
    false,
  );

  private globalStateDevice$ = this.enableDeviceSynchronizationSubject.pipe(
    simpleSwitchMap(
      () =>
        this.device$.pipe(
          map((d) => d?.uuid),
          distinctUntilChanged(),
          simpleSwitchMap(
            (uuid) =>
              this.store.select(
                fromDeviceState.baseSelectors.getDevice({
                  uuid,
                }),
              ),
            EMPTY,
          ),
          distinctUntilChanged(),
        ),
      EMPTY,
    ),
    filter((device) => !!device),
    untilNgDestroyed(),
  );

  private onStreamNotification$ = this.userContext.userUuid$.pipe(
    distinctUntilChanged(),
    simpleSwitchMap(
      (userUuid) =>
        this.notificationsService.ofType('DEVICE_STREAM_NOTIFICATION', {
          userUuid,
        }),
      EMPTY,
    ),
    delayedConcatMap(() => [this.state$]),
    filter(([notification, state]) =>
      state.streams.data.some((d) => d.uuid === notification.data.stream),
    ),
    tap(() => this.loadStreams()),
    untilNgDestroyed(),
  );

  private onStreamVerificationNotification$ = this.userContext.userUuid$.pipe(
    distinctUntilChanged(),
    simpleSwitchMap(
      (userUuid) =>
        this.notificationsService.ofType(
          'DEVICE_STREAM_VERIFICATION_NOTIFICATION',
          {
            userUuid,
          },
        ),
      EMPTY,
    ),
    delayedConcatMap(() => [this.state$]),
    filter(([notification, state]) =>
      state.streams.data.some((d) => d.uuid === notification.data.stream),
    ),
    tap(() => this.loadStreams()),
    untilNgDestroyed(),
  );

  private onDeviceVerificationTriggered$ = this.actions$.pipe(
    ofType(validateStreamFlow.completed),
    delayedConcatMap(() => [this.state$]),
    filter(([response, state]) =>
      state.streams.data.some((d) => d.uuid === response.data.device?.uuid),
    ),
    tap(([response]) => {
      this.setState((state) =>
        produce(state, (draft) => {
          const index = state.streams.data.findIndex(
            (d) => d.uuid === response.data.device.uuid,
          );
          if (index >= 0) {
            draft.streams.data[index] = {
              ...(response.data.device as Draft<DeviceStreamStateModel>),
              waitingForNotification: false,
            };
          }
        }),
      );
      this.loadStreams();
    }),
    untilNgDestroyed(),
  );

  private onNotifications$ = this.userContext.userUuid$.pipe(
    distinctUntilChanged(),
    simpleSwitchMap(
      (userUuid) =>
        this.notificationsService.ofType('CAMERA_DISCOVERY_NOTIFICATION', {
          userUuid,
        }),
      EMPTY,
    ),
    filter((f) => f.data.device === this.get().device?.uuid),
    concatMap(() => this.api.getDeviceDetail(this.get().device!.uuid)),
    untilNgDestroyed(),
    shareReplay(1),
  );

  constructor() {
    super({
      device: undefined,
      highlightedStream: undefined,
      name: initialEditableStrValue as Editable & Value,
      liveEvents: initialEditableStrValue as Editable & Value<boolean>,
      offlineData: initialEditableStrValue as Editable & Value<boolean>,
      streams: {
        data: [],
        loaded: false,
        loading: false,
      },
      discovered: {
        data: undefined,
        loaded: false,
        loading: false,
      },
    });

    this.updateDevice(this.globalStateDevice$);
    this.onDeviceVerificationTriggered$.subscribe();
    this.onStreamNotification$.subscribe();
    this.onStreamVerificationNotification$.subscribe();
    this.onNotifications$.subscribe(() => this.loadStreams());
    this.highlighter
      .onHighlight<{ uuid: string }>(STREAM_HIGHLIGHT_KEY)
      .pipe(untilNgDestroyed(this.destroyRef))
      .subscribe((f) => {
        this.patchState({
          highlightedStream: f.uuid,
        });
        this.highlighter.hide(STREAM_HIGHLIGHT_KEY);
      });
  }

  readonly clearHighlight = this.updater((state) =>
    state.highlightedStream
      ? { ...state, highlightedStream: undefined }
      : state,
  );

  readonly setDevice = this.updater((state, device: DeviceStateModel) =>
    _setDevice(state, device, this.permissionService),
  );

  readonly updateDevice = this.updater((state, device: DeviceStateModel) => {
    const newState = _setDevice(state, device, this.permissionService);

    const s = produce(state, (draft) => {
      draft.device = asMutable(device);

      for (const editableField of editableFields) {
        if (draft[editableField].touched) {
          draft[editableField].disabled = newState[editableField].disabled;
          draft[editableField].disabledReason =
            newState[editableField].disabledReason;
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          draft[editableField] = newState[editableField] as any;
        }
      }
    });

    return s;
  });

  readonly touchedFieldTexts$ = this.select((state) =>
    editableFields
      .filter((v) => state[v].touched)
      .map((field) => editableFieldsTextMapping[field]),
  );

  readonly device$ = this.fieldSelector$('device');
  readonly highlightedStream$ = this.fieldSelector$('highlightedStream');
  readonly canStartStream$ = this.select(this.device$, (device) =>
    device ? device.runningStreams < device.maxRunningStreams : false,
  );
  readonly discovered$ = this.fieldSelector$('discovered');
  readonly streams$ = this.select((state) => state.streams.data);
  readonly cameras$ = this.select((state) => state.discovered.data?.cameras);
  readonly addingEnabled$ = this.select(
    this.device$,
    (device) =>
      !!device &&
      this.permissionService.isAllowed(Permission.Edge, PermissionScope.Edit) &&
      device.availableStreams > device.usedStreams &&
      device.status === DeviceStatus.Online,
  );

  readonly addingEnabledTooltip$ = this.select(this.device$, (device) => {
    const isAble = this.permissionService.isAble(
      Permission.Edge,
      PermissionScope.Edit,
    );

    if (!isAble[0]) {
      return isAble[1].reason;
    }

    if (device && device.status !== DeviceStatus.Online) {
      return $localize`:@@edit-device.not-online:Live Traffic Unit must be online to be able to create a new video stream`;
    }

    return device && device.availableStreams <= device.usedStreams
      ? $localize`:@@edit-device.all-used:All video streams are used`
      : undefined;
  });

  readonly discovering$ = combineLatest([
    this.discovered$,
    interval(5000).pipe(startWith(0)),
  ]).pipe(
    map(([discovered]) => {
      if (!discovered || !discovered.data?.dtDiscoveryStart) {
        return false;
      }

      const { dtDiscoveryEnd, dtDiscoveryStart } = discovered.data;

      if (
        new Date(dtDiscoveryStart).getTime() + 45_000 <
        new Date().getTime()
      ) {
        return false;
      }

      return (
        !dtDiscoveryEnd ||
        new Date(dtDiscoveryEnd).getTime() <
          new Date(dtDiscoveryStart).getTime()
      );
    }),
    distinctUntilChanged(),
  );

  readonly refreshEnabled$ = this.select(
    this.discovering$,
    this.device$,
    (m, device) => !m && device && device.availableStreams > device.usedStreams,
  );

  readonly refreshEnabledTooltip$ = this.select(
    this.discovering$,
    this.device$,
    (discovering, device) => {
      return discovering
        ? $localize`:@@edit-device.discovering:Discovering`
        : device && device.availableStreams <= device.usedStreams
          ? $localize`:@@edit-device.all-used:All video streams are used`
          : undefined;
    },
  );

  readonly validation$: Observable<Validation> = this.select((state) => {
    if (
      state.name.touched &&
      (!state.name.value || state.name.value.trim().length === 0)
    ) {
      return [
        false,
        {
          reason: $localize`:@@edit-device.name-required:Live traffic Unit name is required`,
        },
      ];
    }

    return [true] as const;
  });

  readonly asFormEnabledInfo$: Observable<EditDeviceFormFieldEditable> =
    this.select((state) => ({
      name: !state.name.disabled,
      liveEvents: !state.liveEvents.disabled,
      offlineData: !state.offlineData.disabled,
    }));

  readonly asSubmitData$: Observable<EditDevice> = this.select((state) => ({
    ...(state.name.touched ? { name: state.name.value! } : {}),
  }));
  // #endregion

  override registerFormGroup(formGroup: FormGroup): void {
    super.registerFormGroup(formGroup);

    this.asFormEnabledInfo$
      .pipe(
        distinctUntilChanged((a, b) => isEqual(a, b)),
        untilNgDestroyed(this.destroyRef),
      )
      .subscribe((enabledInfo) => {
        FormUtils.enableFormControls(formGroup, enabledInfo);
      });
  }

  enableDeviceSynchronization(): void {
    this.enableDeviceSynchronizationSubject.next(true);
  }

  // #region effects
  readonly addNewStream = this.effect((trigger$: Observable<void>) => {
    return trigger$.pipe(
      switchMap(() =>
        forkJoin([
          this.actions$.pipe(
            ofType(newEdgeStreamDialog.closed),
            take(1),
            map((action) => action.data),
          ),
          this.state$.pipe(
            take(1),
            tap(({ device, streams }) =>
              this.store.dispatch(
                newEdgeStreamDialog.open({
                  data: { device: device!, streams: streams.data },
                }),
              ),
            ),
          ),
        ]),
      ),
      filter(([data]) => !!data),
      tapResponse(
        ([data, _]) => {
          this.setState((state) =>
            produce(state, (draft) => {
              draft.streams.data.push({
                ...asMutable(data),
                waitingForNotification: false,
              });
            }),
          );
        },
        (error) => {
          logger.error({ error }, 'Failed to create video stream');
        },
      ),
    );
  });

  loadStreams = this.effect((trigger$: Observable<void>) =>
    trigger$.pipe(
      delayedConcatMap(() => [this.state$]),
      switchMap(([, state]) => {
        if (!state.device) {
          return EMPTY;
        }
        this.patchState({
          streams: { ...state.streams, loading: true },
          discovered: { ...state.discovered, loading: true },
        });

        return this.api.getDeviceDetail(state.device.uuid).pipe(
          tapResponse(
            (response) => {
              this.patchState({
                discovered: {
                  data: response.data!.networks,
                  loaded: true,
                  loading: false,
                },
                streams: {
                  data: response.data!.streams.map((m) => ({
                    ...m,
                    waitingForNotification: false,
                  })),
                  loaded: true,
                  loading: false,
                },
              });
            },
            (error) => {
              logger.error({ error }, 'Failed to get Live Traffic Unit detail');

              this.patchState({
                discovered: {
                  data: undefined,
                  loaded: true,
                  loading: false,
                },
                streams: {
                  data: [],
                  loaded: true,
                  loading: false,
                },
              });
            },
          ),
        );
      }),
    ),
  );

  editStream = this.effect((trigger$: Observable<DeviceStreamDTO>) => {
    return trigger$.pipe(
      delayedConcatMap(() => [this.state$]),
      switchMap(([stream, state]) =>
        forkJoin([
          this.actions$.pipe(
            ofType(editEdgeStreamDialog.closed),
            take(1),
            map((action) => action.data),
          ),
          of(undefined).pipe(
            tap(() => {
              this.store.dispatch(
                editEdgeStreamDialog.open({
                  data: {
                    deviceUuid: state.device!.uuid,
                    stream,
                    deviceStatus: state.device!.status,
                    streams: state.streams.data.filter(
                      (s) => s.uuid !== stream.uuid,
                    ),
                  },
                }),
              );
            }),
          ),
        ]),
      ),
      filter(([data]) => !!data),
      tapResponse(
        ([data, _]) => {
          this.setState((state) =>
            produce(state, (draft) => {
              const index = draft.streams.data.findIndex(
                (f) => f.uuid === data.uuid,
              );
              draft.streams.data[index] = {
                ...data,
                waitingForNotification: true,
              } as Draft<DeviceStreamStateModel>;
            }),
          );
        },
        (error) => {
          logger.error({ error }, 'Failed to edit video stream');
        },
      ),
    );
  });

  deleteStream = this.effect((trigger$: Observable<DeviceStreamDTO>) => {
    return trigger$.pipe(
      delayedConcatMap(() => [this.state$]),
      switchMap(([stream, state]) =>
        forkJoin([
          of(stream),
          this.actions$.pipe(
            ofType(deleteEdgeStreamDialog.closed),
            take(1),
            map((action) => action.data),
          ),
          of(undefined).pipe(
            tap(() => {
              this.store.dispatch(
                deleteEdgeStreamDialog.open({
                  data: {
                    deviceUuid: state.device!.uuid,
                    stream,
                  },
                }),
              );
            }),
          ),
        ]),
      ),
      filter(([_stream, removed]) => !!removed),
      tapResponse(
        ([stream]) => {
          this.setState((state) =>
            produce(state, (draft) => {
              const index = draft.streams.data.findIndex(
                (f) => f.uuid === stream.uuid,
              );
              if (index !== -1) {
                draft.streams.data.splice(index, 1);
              }
            }),
          );
        },
        (error) => {
          logger.error({ error }, 'Failed to delete video stream');
        },
      ),
    );
  });

  revalidateStream = this.effect(
    (trigger$: Observable<{ stream: DeviceStreamDTO; url: string }>) => {
      return trigger$.pipe(
        delayedConcatMap(() => [this.state$]),
        switchMap(([{ stream, url }, state]) => {
          if (
            stream.status === DeviceStreamStatus.ValidationFailed ||
            stream.status === DeviceStreamStatus.NotSuitable
          ) {
            this.store.dispatch(
              validateStreamFlow.init({
                data: {
                  data: { action: DeviceStreamAction.ReVerify, url },
                  deviceUuid: state.device!.uuid,
                  streamUuid: stream.uuid,
                },
              }),
            );
            return EMPTY;
          }

          return forkJoin([
            of(stream),
            of(undefined).pipe(
              tap(() => {
                this.store.dispatch(
                  verifyEdgeStreamDialog.open({
                    data: {
                      device: state.device!,
                      stream,
                    },
                  }),
                );
              }),
            ),
          ]);
        }),
      );
    },
  );

  updateStreamState = this.effect(
    (
      trigger$: Observable<{
        stream: DeviceStreamDTO;
        state: DeviceStreamState;
      }>,
    ) => {
      return trigger$.pipe(
        delayedConcatMap(() => [this.state$]),
        switchMap(([action, state]) =>
          forkJoin([
            this.actions$.pipe(
              ofType(updateEdgeStreamStateDialog.closed),
              take(1),
              map((action) => action.data),
            ),
            of(undefined).pipe(
              tap(() => {
                this.store.dispatch(
                  updateEdgeStreamStateDialog.open({
                    data: {
                      dataSourceContext: undefined,
                      state: action.state,
                      deviceUuid: state.device!.uuid,
                      stream: action.stream,
                    },
                  }),
                );
              }),
            ),
          ]),
        ),
        filter(([data]) => !!data),
        tapResponse(
          ([data]) => {
            this.setState((state) =>
              produce(state, (draft) => {
                const index = draft.streams.data.findIndex(
                  (f) => f.uuid === data.uuid,
                );
                if (index !== -1) {
                  draft.streams.data[index] = {
                    ...data,
                    waitingForNotification: true,
                  } as Draft<DeviceStreamStateModel>;
                }
              }),
            );
          },
          (error) => {
            logger.error({ error }, 'Failed to update video stream state');
          },
        ),
      );
    },
  );

  discoverDevices = this.effect((trigger$: Observable<void>) =>
    trigger$.pipe(
      delayedConcatMap(() => [this.state$]),
      switchMap(([, state]) => {
        if (!state.device) {
          return EMPTY;
        }

        return this.api.discoverDeviceCameras(state.device.uuid).pipe(
          concatMap(() =>
            this.api.getDeviceDetail(state.device!.uuid).pipe(
              switchMap((response) => {
                this.patchState({
                  discovered: {
                    data: response.data!.networks,
                    loaded: true,
                    loading: false,
                  },
                });
                return this.onNotifications$.pipe(
                  filter((f) => {
                    const dtStart = f.data?.networks?.dtDiscoveryStart;
                    const responseStart =
                      response.data?.networks?.dtDiscoveryStart;

                    return (
                      !!dtStart &&
                      !!responseStart &&
                      new Date(dtStart).getTime() >=
                        new Date(responseStart).getTime()
                    );
                  }),
                  timeout(30000),
                  catchError((e) =>
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                    e instanceof TimeoutError
                      ? this.api.getDeviceDetail(state.device!.uuid)
                      : throwError(() => e as unknown),
                  ),
                  tapResponse(
                    (response) => {
                      if (response === undefined) {
                        this.patchState({
                          discovered: {
                            data: undefined,
                            loaded: true,
                            loading: false,
                          },
                        });
                        return;
                      }
                      this.patchState({
                        discovered: {
                          data: response.data!.networks,
                          loaded: true,
                          loading: false,
                        },
                      });
                    },
                    (error) => {
                      logger.error(
                        { error },
                        'Failed to discover Live Traffic Units',
                      );

                      this.patchState({
                        streams: {
                          data: [],
                          loaded: true,
                          loading: false,
                        },
                      });
                    },
                  ),
                );
              }),
            ),
          ),
        );
      }),
    ),
  );

  readonly pairStream = this.effect(
    (trigger$: Observable<CameraNetworkInterface>) => {
      return trigger$.pipe(
        switchMap((data) =>
          forkJoin([
            this.actions$.pipe(
              ofType(newEdgeStreamDialog.closed),
              take(1),
              map((action) => action.data),
            ),
            this.state$.pipe(
              take(1),
              tap(({ device, streams }) =>
                this.store.dispatch(
                  newEdgeStreamDialog.open({
                    data: {
                      device: device!,
                      camera: data,
                      streams: streams.data,
                    },
                  }),
                ),
              ),
            ),
          ]),
        ),
        filter(([data]) => !!data),
        tapResponse(
          ([data, _]) => {
            this.setState((state) =>
              produce(state, (draft) => {
                draft.streams.data.push({
                  ...asMutable(data),
                  waitingForNotification: false,
                });
              }),
            );
          },
          (error) => {
            logger.error({ error }, 'Failed to pair video stream');
          },
        ),
      );
    },
  );
}
