import {
  ChangeDetectorRef,
  Component,
  inject,
  reflectComponentType,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import type { InferDialogRefType, Dialog } from '@gv/utils';
import {
  TestGroupDirective,
  TestIdDirective,
  normalizeTestId,
} from '@gv/debug';

@Component({
  template: '',
  hostDirectives: [TestIdDirective, TestGroupDirective],
  standalone: true,
  selector: 'gv-dialog',
})
export class DialogComponent<OpenData, CloseData>
  implements Dialog<OpenData, CloseData>
{
  preventClose = false;
  readonly _dialogCloseParam!: CloseData;
  readonly data = inject<OpenData>(MAT_DIALOG_DATA);
  protected changeDetector = inject(ChangeDetectorRef);
  protected dialogRef =
    inject<InferDialogRefType<Dialog<OpenData, CloseData>>>(MatDialogRef);

  close(data: CloseData): void {
    this.dialogRef.close(data);
  }

  constructor() {
    inject(TestIdDirective, { self: true }).setId(
      normalizeTestId(
        reflectComponentType(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          Object.getPrototypeOf(this).constructor,
        ).selector,
      ),
    );
  }
}
