import { Injectable, inject } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, from, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { CookieLawService } from '../../service/application/privacy/cookie-law.service';
import * as CookieLawActions from '../action/cookie-law.actions';

@Injectable()
export class CookieLawEffects {
  private actions$ = inject<Actions>(Actions);
  private cookieLawService = inject(CookieLawService);
  initialize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CookieLawActions.initialize),
      switchMap(() => from(this.cookieLawService.wasConfirmed())),
      switchMap((wasConfirmed) =>
        wasConfirmed ? of(CookieLawActions.confirm()) : EMPTY,
      ),
    ),
  );

  confirm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CookieLawActions.confirm),
        tap(() => {
          this.cookieLawService.confirm();
        }),
      ),
    {
      dispatch: false,
    },
  );
}
