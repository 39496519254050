import type { Signal } from '@angular/core';

import { createAction, props } from '@ngrx/store';
import type {
  CreateDataSourceModel,
  DataSourceDetailApiModel,
  GraphicalObjectModel,
  FilterModel,
  CreateGraphicalObjectDataModel,
  UpdateGraphicalObjectDataModel,
  FiltersModel,
  FilterValidationModel,
  GraphicalObjectValidationModel,
  CreateExport,
  TimelineWithFilesDTO,
  CreateFilter,
  CreateODMatrix,
  EditFilter,
  EditDataSource,
  UpdateDataSourceScreenshot,
  DataSourceValidationDTO,
  CopyDescription,
  DataMetricDTO,
  CreateDataMetric,
  EditDataMetric,
  EditDataMetricOrder,
  DataAvailabilityDTO,
  EditDescription,
  EditFilterOrder,
  FilterTypeName,
  LocationApiModel,
  GVVIDataSourceDetailDTO,
  GVVIDataSourceValidationDTO,
  ClassificationSchemeModel,
  GeoAnnotationPoint,
} from '@gv/api';
import {
  createDialogBaseActions,
  createFlowBaseActions,
  createSubStateActions,
} from '@gv/state';
import type { DataSourceApi } from '@gv/context';

import type {
  DataAvailabilityStateModel,
  DataSourceDetailModel,
  DataSourceDetailStateModel,
  FilterStateModel,
  MetricStateModel,
} from '../types';

const basename = '[DataSource]';
const basenameDataSource = '[DataSource] data source';
const basenameTimeline = '[DataSource] timeline';
const basenameFilters = '[DataSource] filter';
const basenameGraphicalObjects = '[DataSource] graphical objects';
const basenameMetrics = '[DataSource] metrics';

export const add = createAction(
  `${basename} add`,
  props<{
    dataSource: DataSourceDetailModel;
  }>(),
);

export const remove = createAction(
  `${basename} remove`,
  props<{
    uuid: string;
    dtSent: Date;
  }>(),
);

export const setDeleted = createAction(
  `${basename} set deleted`,
  props<{
    uuid: string;
    value: boolean;
  }>(),
);

export const update = createAction(
  `${basename} update`,
  props<{
    uuid: string;
    dataSource: Partial<DataSourceDetailModel>;
    isPartial: boolean;
    newLocationUuid?: string;
    waitingForNotification?: boolean;
    updateAfterCopy?: boolean;
  }>(),
);

export const deleteDataSourceFlow = createFlowBaseActions(basename, 'delete', {
  initProps: props<{
    data: {
      dataSourceUuid: string;
      api: () => DataSourceApi;
    };
  }>(),
});

export const createDataSourceFlow = createFlowBaseActions(basename, 'create', {
  initProps: props<{
    data: {
      dataSource: CreateDataSourceModel;
      directoryPrefix: string | undefined;
      api: () => DataSourceApi;
    };
  }>(),
  completedProps: props<{
    data: DataSourceDetailModel | GVVIDataSourceDetailDTO;
  }>(),
});

export const editDataSourceFlow = createFlowBaseActions(basename, 'edit', {
  initProps: props<{
    data: {
      dataSource: EditDataSource;
      dataSourceUuid: string;
      classScheme: ClassificationSchemeModel;
      api: () => DataSourceApi;
    };
  }>(),
});

export const editDescriptionFlow = createFlowBaseActions(
  basename,
  'edit description',
  {
    initProps: props<{
      data: {
        description: EditDescription;
        dataSourceUuid: string;
        timezone: string;
      };
    }>(),
  },
);

export const refreshFlow = createFlowBaseActions(basename, 'refresh', {
  initProps: props<{
    data: {
      dataSourceUuid: string;
    };
  }>(),
  completedProps: props<{ data: DataSourceDetailModel }>(),
});

export const loadDataSourceVideoTimelineFlow = createFlowBaseActions(
  basename,
  'load video timeline',
  {
    initProps: props<{
      data: {
        dataSourceUuid: string;
        options: {
          readonly presign?: boolean;
        };
      };
    }>(),
    completedProps: props<{ data: TimelineWithFilesDTO }>(),
  },
);

export const setScreenshotDimension = createAction(
  `${basenameFilters} setScreenshotDimension`,
  props<{
    data: {
      width: number;
      height: number;
    };
  }>(),
);

export const setScreenshotFlow = createFlowBaseActions(
  basename,
  'setScreenshot',
  {
    initProps: props<{
      data: {
        screenshot: UpdateDataSourceScreenshot;
        dataSourceUuid: string;
      };
    }>(),
  },
);

export const calibrateDataSourceFlow = createFlowBaseActions(
  basename,
  'calibration',
  {
    initProps: props<{
      data: {
        uuid: string;
        points: GeoAnnotationPoint[];
      };
    }>(),
  },
);

export const updateDataSourceCalibrationFlow = createFlowBaseActions(
  basename,
  'update calibration',
  {
    initProps: props<{
      data: {
        uuid: string;
        points: GeoAnnotationPoint[];
      };
    }>(),
  },
);

export const deleteDataSourceCalibrationFlow = createFlowBaseActions(
  basename,
  'delete calibration',
  {
    initProps: props<{
      data: {
        uuid: string;
      };
    }>(),
  },
);

export const validateFlow = createFlowBaseActions(basename, 'validate', {
  initProps: props<{
    data: { uuid: string; api: () => DataSourceApi };
  }>(),
  completedProps: props<{
    data: DataSourceValidationDTO | GVVIDataSourceValidationDTO;
  }>(),
});

export const generateReportFlow = createFlowBaseActions(basename, 'report', {
  initProps: props<{
    data: {
      dataSourceUuid: string;
      report: CreateExport;
    };
  }>(),
});

export const init = createAction(
  `${basename} init`,
  props<{ uuid: string; api: () => DataSourceApi }>(),
);

export const dataSource = createSubStateActions<
  DataSourceDetailApiModel | GVVIDataSourceDetailDTO,
  DataSourceDetailStateModel
>(basenameDataSource);

export const filters = createSubStateActions<
  readonly FilterModel[],
  readonly FilterModel[]
>(basenameFilters);

export const timeline = createSubStateActions<
  DataAvailabilityDTO,
  DataAvailabilityStateModel
>(basenameTimeline);

export const deleteFilterFlow = createFlowBaseActions(
  basenameFilters,
  'delete',
  {
    initProps: props<{
      data: {
        dataSourceUuid: string;
        filterUuid: string;
      };
    }>(),
  },
);

export const validateFilterFlow = createFlowBaseActions(
  basenameFilters,
  'validate',
  {
    initProps: props<{
      data: {
        dataSourceUuid: string;
        filterUuid: string;
      };
    }>(),
    completedProps: props<{ data: FilterValidationModel }>(),
  },
);

export const createFilterFlow = createFlowBaseActions(
  basenameFilters,
  'create',
  {
    initProps: props<{
      data: {
        dataSourceUuid: string;
        createFilterData: CreateFilter;
        createWidget: boolean;
      };
    }>(),
    completedProps: props<{
      data: { filter: FilterModel };
    }>(),
  },
);

export const createODFiltersFlow = createFlowBaseActions(
  basenameFilters,
  'create od',
  {
    initProps: props<{
      data: {
        dataSourceUuid: string;
        createFiltersData: CreateFilter;
      };
    }>(),
    completedProps: props<{
      data: { filters: FiltersModel };
    }>(),
  },
);

export const updateFilterFlow = createFlowBaseActions(
  basenameFilters,
  'update',
  {
    initProps: props<{
      data: {
        dataSourceUuid: string;
        filterUuid: string;
        updateFilterDataModel: EditFilter;
      };
    }>(),
    completedProps: props<{
      data: { filter: FilterModel };
    }>(),
  },
);

export const editFilterPositionFlow = createFlowBaseActions(
  basenameFilters,
  'edit order',
  {
    initProps: props<{
      data: {
        dataSourceUuid: string;
        data: EditFilterOrder;
        group: FilterTypeName;
      };
    }>(),
    completedProps: props<{
      data: readonly FilterStateModel[];
    }>(),
  },
);

export const editFilterPositions = createAction(
  `${basenameFilters} update order`,
  props<{
    data: { uuids: string[]; group: FilterTypeName };
  }>(),
);

export const addFilter = createAction(
  `${basenameFilters} add`,
  props<{
    dataSourceUuid: string;
    dtSent: Date;
    filter: FilterModel;
  }>(),
);

export const removeFilter = createAction(
  `${basenameFilters} remove`,
  props<{
    filterUuid: string;
  }>(),
);

export const updateFilter = createAction(
  `${basenameFilters} update`,
  props<{
    dataSourceUuid: string;
    filterUuid: string;
    dtSent: Date;
    filter: FilterModel;
  }>(),
);

export const graphicalObjects = createSubStateActions<
  readonly GraphicalObjectModel[],
  readonly GraphicalObjectModel[]
>(basenameGraphicalObjects);

export const deleteGraphicalObjectFlow = createFlowBaseActions(
  basenameGraphicalObjects,
  'delete',
  {
    initProps: props<{
      data: {
        dataSourceUuid: string;
        graphicalObjectUuid: string;
      };
    }>(),
  },
  true,
);

export const deleteAllGraphicalObjectsFlow = createFlowBaseActions(
  basenameGraphicalObjects,
  'delete all',
  {
    initProps: props<{
      data: {
        dataSourceUuid: string;
      };
    }>(),
  },
  true,
);

export const createGraphicalObjectFlow = createFlowBaseActions(
  basenameGraphicalObjects,
  'create',
  {
    initProps: props<{
      data: {
        dataSourceUuid: string;
        createGraphicalObjectData: CreateGraphicalObjectDataModel;
        createFilter: boolean;
      };
    }>(),
    completedProps: props<{
      data: { graphicalObject: GraphicalObjectModel };
    }>(),
  },
);

export const updateGraphicalObjectFlow = createFlowBaseActions(
  basenameGraphicalObjects,
  'update',
  {
    initProps: props<{
      data: {
        dataSourceUuid: string;
        graphicalObjectUuid: string;
        updateGraphicalObjectDataModel: UpdateGraphicalObjectDataModel;
      };
    }>(),
    completedProps: props<{
      data: { graphicalObject: GraphicalObjectModel };
    }>(),
  },
);

export const addGraphicalObject = createAction(
  `${basenameGraphicalObjects} add`,
  props<{
    dataSourceUuid: string;
    dtSent: Date;
    graphicalObject: GraphicalObjectModel;
  }>(),
);

export const removeGraphicalObject = createAction(
  `${basenameGraphicalObjects} remove`,
  props<{
    graphicalObjectUuid: string;
  }>(),
);

export const updateGraphicalObject = createAction(
  `${basenameGraphicalObjects} update`,
  props<{
    dataSourceUuid: string;
    graphicalObjectUuid: string;
    dtSent: Date;
    graphicalObject: GraphicalObjectModel;
  }>(),
);

export const validateGraphicalObjectFlow = createFlowBaseActions(
  basenameGraphicalObjects,
  'validate',
  {
    initProps: props<{
      data: {
        dataSourceUuid: string;
        graphicalObjectUuid: string;
      };
    }>(),
    completedProps: props<{ data: GraphicalObjectValidationModel }>(),
  },
);

export const generateODMovementsFlow = createFlowBaseActions(
  basenameFilters,
  'generate od',
  {
    initProps: props<{
      data: {
        dataSourceUuid: string;
        odData: CreateODMatrix;
      };
    }>(),
    completedProps: props<{
      data: { filters: readonly FilterModel[] };
    }>(),
  },
);

export const resetSceneEditsFlow = createFlowBaseActions(
  basenameFilters,
  'reset scene edits',
  {
    initProps: props<{
      data: {
        dataSourceUuid: string;
      };
    }>(),
  },
);

export const toggleSelectedFilter = createAction(
  `${basename} toggle filter`,
  props<{ uuid: string }>(),
);

export const copySceneDescription = createFlowBaseActions(
  basenameDataSource,
  'copy scene description',
  {
    initProps: props<{
      data: {
        dataSourceUuid: string;
        data: CopyDescription;
      };
    }>(),
  },
);

export const metrics = createSubStateActions<
  readonly DataMetricDTO[],
  readonly MetricStateModel[]
>(basenameMetrics);

export const getMetricFlow = createFlowBaseActions(
  basenameMetrics,
  'get metric',
  {
    initProps: props<{
      data: {
        dataSourceUuid: string;
        uuid: string;
      };
    }>(),
  },
);

export const createMetricFlow = createFlowBaseActions(
  basenameMetrics,
  'create',
  {
    initProps: props<{
      data: {
        dataSourceUuid: string;
        data: CreateDataMetric;
      };
    }>(),
    completedProps: props<{
      data: readonly MetricStateModel[];
    }>(),
  },
);

export const editMetricFlow = createFlowBaseActions(basenameMetrics, 'edit', {
  initProps: props<{
    data: {
      dataSourceUuid: string;
      metricUuid: string;
      data: EditDataMetric;
    };
  }>(),
  completedProps: props<{
    data: readonly MetricStateModel[];
  }>(),
});

export const deleteMetricFlow = createFlowBaseActions(
  basenameMetrics,
  'delete',
  {
    initProps: props<{
      data: {
        dataSourceUuid: string;
        metricUuid: string;
      };
    }>(),
  },
);

export const refreshSingleMetricFlow = createFlowBaseActions(
  basenameMetrics,
  'refresh single',
  {
    initProps: props<{
      data: {
        dataSourceUuid: string;
        metricUuid: string;
      };
    }>(),
  },
);

export const refreshMetricsFlow = createFlowBaseActions(
  basenameMetrics,
  'refresh all',
  {
    initProps: props<{
      data: {
        dataSourceUuid: string;
      };
    }>(),
    completedProps: props<{
      data: readonly MetricStateModel[];
    }>(),
  },
);

export const editMetricPositionFlow = createFlowBaseActions(
  basenameMetrics,
  'edit order',
  {
    initProps: props<{
      data: {
        dataSourceUuid: string;
        data: EditDataMetricOrder;
      };
    }>(),
    completedProps: props<{
      data: readonly MetricStateModel[];
    }>(),
  },
);

export const getMetric = createAction(
  `${basenameMetrics} get`,
  props<{
    data: MetricStateModel;
  }>(),
);

export const updateMetricState = createAction(
  `${basenameMetrics} update state`,
  props<{
    data: { metricUuid: string; dtSent: Date; status: number };
  }>(),
);

export const removeMetric = createAction(
  `${basenameMetrics} remove`,
  props<{
    metricUuid: string;
  }>(),
);

export const editMetricPositions = createAction(
  `${basenameMetrics} update order`,
  props<{
    data: {
      positions: string[];
    };
  }>(),
);

export type DESCRIBE_SOURCE = 'describe-page' | 'live-dashboards';

export const copySceneDescriptionDialog = createDialogBaseActions(
  basename,
  'CopySceneDescription',
  {
    openProps: props<{
      data: {
        uuid: string;
        source: DESCRIBE_SOURCE;
      };
    }>(),
  },
);

export const setCardinalAngleDialog = createDialogBaseActions(
  basename,
  'CardinalAngleDialogComponent',
  {
    openProps: props<{
      data: {
        uuid: string;
        cardinalAngle: number;
        source: DESCRIBE_SOURCE;
        classScheme: ClassificationSchemeModel;
      };
    }>(),
  },
);

export const newDataSourceDialog = createDialogBaseActions(
  basename,
  'NewDataSource',
  {
    openProps: props<{
      data: {
        dataSources: readonly {
          name: string;
          uuid: string;
        }[];
        directoryPrefix: string;
      };
    }>(),
  },
);

// TODO: move to location package
export const newLocationDialog = createDialogBaseActions(
  basename,
  'NewLocationDialog',
  {
    openProps: props<{
      data: {
        //
      };
    }>(),
    closedProps: props<{ data: LocationApiModel }>(),
  },
);

export const editDataSourceDialog = createDialogBaseActions(
  basename,
  'EditDataSource',
  {
    openProps: props<{
      data: {
        dataSource: () => Signal<
          DataSourceDetailStateModel | GVVIDataSourceDetailDTO
        >;
      };
    }>(),
  },
);

export const deleteDataSourceDialog = createDialogBaseActions(
  basename,
  'DeleteDataSource',
  {
    openProps: props<{
      data: DataSourceDetailStateModel | GVVIDataSourceDetailDTO;
    }>(),
  },
);
