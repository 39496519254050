import { inject, Injectable } from '@angular/core';

import { createComponentLogger } from '@gv/logger';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { logger as _logger } from '../logger';
import { sendLog } from './client-logger.actions';
import { ClientLoggerService } from './client-logger.service';

@Injectable()
export class ClientLoggerEffects {
  private actions$ = inject<Actions>(Actions);
  private clientLogger = inject(ClientLoggerService);
  private logger = createComponentLogger(_logger, 'ClientLoggerEffects');

  send$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sendLog),
        mergeMap((action) => {
          return from(
            this.clientLogger.sendClientLog({
              datetime: action.datetime,
              level: action.level,
              message: action.message,
            }),
          ).pipe(
            catchError((error: unknown) => {
              if (error) {
                this.logger.error({ error }, 'Failed to send client log');
              }
              return EMPTY;
            }),
          );
        }),
      ),
    {
      dispatch: false,
    },
  );
}
