import type { OnDestroy } from '@angular/core';
import { Injectable, inject } from '@angular/core';

import {
  APP_INSTANCE_ID,
  IntervalService,
  LocalStorageService,
  SessionStorageService,
} from '@gv/ui/core';
import { SentryError, toSentryError, untilNgDestroyed } from '@gv/utils';
import { captureException, withScope } from '@sentry/angular';
import { isEqual } from 'lodash-es';
import type { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
} from 'rxjs/operators';

import { logger } from '../logger';
import type { AppInstanceUnloadedModel } from './entity/instance/app-instance-unloaded-model';
import { CorrectUnloadValue } from './entity/instance/correct-unload-value.enum';
import { UnexpectedAppUnloadError } from './entity/instance/unexpected-app-unload-error';

@Injectable({
  providedIn: 'root',
})
export class AppInstanceService implements OnDestroy {
  private appInstanceId = inject(APP_INSTANCE_ID);
  private localStorageService = inject(LocalStorageService);
  private sessionStorageService = inject(SessionStorageService);
  private intervalService = inject(IntervalService);
  static readonly className: string = 'AppInstanceService';

  static readonly timeBeforeCrashIntervalKey: string = 'appTimeBeforeCrash';

  static readonly timeBeforeCrashIntervalDuration: number = 1000;

  static readonly correctUnloadKey: string = '__gv_correct_unload';

  static readonly timeBeforeCrashKey: string = '__gv_time_before_crash';

  static readonly instancesUnloadedKey: string = 'gv_unloadedInstances';

  static readonly storageChangedDebounceTime: number = 1000; // in ms

  pendingUnloadedInstances$: Observable<AppInstanceUnloadedModel[]> =
    this.localStorageService.storageChanged$.pipe(
      startWith({}),
      debounceTime(AppInstanceService.storageChangedDebounceTime),
      map(() => this.getPendingUnloadedInstances()),
      distinctUntilChanged((a, b) => isEqual(a, b)),
      untilNgDestroyed(),
      shareReplay(1),
    );

  ngOnDestroy(): void {
    //
  }

  onAppInit(): void {
    try {
      this.checkUnexpectedUnload();

      this.setCorrectUnloadValue(CorrectUnloadValue.Pending);

      this.startTimeBeforeCrashUpdateLoop();

      const unloadedInstances = this.localStorageService.getItem<
        AppInstanceUnloadedModel[]
      >(AppInstanceService.instancesUnloadedKey);

      if (unloadedInstances && Array.isArray(unloadedInstances)) {
        unloadedInstances.forEach((unloadedInstance) => {
          withScope((scope) => {
            scope.setLevel('info');
            scope.setTag('instanceId', unloadedInstance.instanceId);
            scope.setFingerprint(['appInstanceUnloaded']);
            scope.setExtra('date', unloadedInstance.date);
            scope.clearBreadcrumbs();

            captureException(
              new SentryError(
                'AppInstanceUnloaded',
                `Unloaded ${unloadedInstance.instanceId}`,
                undefined,
              ),
            );
          });
        });

        this.localStorageService.removeItem(
          AppInstanceService.instancesUnloadedKey,
        );
      }
    } catch (e) {
      logger.error({ error: e }, 'Failed to check unloaded instances');
    }
  }

  onAppUnload(): void {
    let unloadedData: AppInstanceUnloadedModel[] = [
      {
        date: new Date(),
        instanceId: this.appInstanceId,
      },
    ];

    this.intervalService.clearInterval(
      AppInstanceService.timeBeforeCrashIntervalKey,
    );

    try {
      this.setCorrectUnloadValue(CorrectUnloadValue.True);

      const unloadedInstances = this.localStorageService.getItem<
        AppInstanceUnloadedModel[]
      >(AppInstanceService.instancesUnloadedKey);

      if (unloadedInstances && Array.isArray(unloadedInstances)) {
        unloadedData = [...unloadedInstances, ...unloadedData];
      }

      this.localStorageService.setItem(
        AppInstanceService.instancesUnloadedKey,
        unloadedData,
      );
    } catch (e) {
      logger.error({ error: e }, 'Failed to unload instance');
    }
  }

  getPendingUnloadedInstances(): AppInstanceUnloadedModel[] {
    const unloadedInstances = this.localStorageService.getItem<
      AppInstanceUnloadedModel[]
    >(AppInstanceService.instancesUnloadedKey);

    return unloadedInstances && Array.isArray(unloadedInstances)
      ? unloadedInstances
      : undefined;
  }

  private checkUnexpectedUnload(): void {
    const correctUnloadValue: string = this.sessionStorageService.getItem(
      AppInstanceService.correctUnloadKey,
    );

    if (correctUnloadValue && correctUnloadValue !== CorrectUnloadValue.True) {
      const timeBeforeCrash = this.sessionStorageService.getItem(
        AppInstanceService.timeBeforeCrashKey,
      );

      const error = new UnexpectedAppUnloadError(
        'Unexpected app unload detected.',
      );

      withScope((scope) => {
        scope.setLevel('info');
        scope.setExtra('timeBeforeCrash', timeBeforeCrash || null);

        captureException(
          toSentryError(
            `${AppInstanceService.className}::${this.checkUnexpectedUnload.name}()`,
            error,
          ),
        );
      });
    }
  }

  private startTimeBeforeCrashUpdateLoop(): void {
    this.intervalService.setInterval(
      AppInstanceService.timeBeforeCrashIntervalKey,
      AppInstanceService.timeBeforeCrashIntervalDuration,
      () => {
        this.sessionStorageService.setItem(
          AppInstanceService.timeBeforeCrashKey,
          new Date().toISOString(),
        );
      },
      true,
    );
  }

  private setCorrectUnloadValue(value: CorrectUnloadValue): void {
    this.sessionStorageService.setItem(
      AppInstanceService.correctUnloadKey,
      value,
    );
  }
}
