const fileSizeDivider = 1000;

export const enum FileSizeUnit {
  Byte = 'B',
  KiloByte = 'KB',
  MegaByte = 'MB',
  GigaByte = 'GB',
  TeraByte = 'TB',
  PetaByte = 'PB',
  ExaByte = 'EB',
  Zettabyte = 'ZB',
  YottaByte = 'YB',
}

export const fileSizeUnits: readonly FileSizeUnit[] = [
  FileSizeUnit.Byte,
  FileSizeUnit.KiloByte,
  FileSizeUnit.MegaByte,
  FileSizeUnit.GigaByte,
  FileSizeUnit.TeraByte,
  FileSizeUnit.PetaByte,
  FileSizeUnit.ExaByte,
  FileSizeUnit.Zettabyte,
  FileSizeUnit.YottaByte,
];

export interface HumanReadableFileSizeModel {
  readonly value: number;

  readonly unit: FileSizeUnit;
}

export function humanReadableFileSize(
  bytes: number,
): HumanReadableFileSizeModel {
  if (Math.abs(bytes) < fileSizeDivider) {
    return {
      value: bytes,
      unit: FileSizeUnit.Byte,
    };
  }

  let u = 0; // skip byte unit

  let value: number = bytes;

  do {
    value /= fileSizeDivider;
    ++u;
  } while (Math.abs(value) >= fileSizeDivider && u < fileSizeUnits.length - 1);

  return {
    value,
    unit: fileSizeUnits[u],
  };
}

export function toBytes(size: number, unit: FileSizeUnit): number | undefined {
  if (unit === FileSizeUnit.Byte || !unit) {
    return size;
  }

  let u: number = fileSizeUnits.indexOf(unit);

  if (u < 0) {
    return undefined;
  }

  let value: number = size;

  while (u > 0) {
    value *= fileSizeDivider;
    u -= 1;
  }

  return value;
}
