import { InjectionToken } from '@angular/core';

import type { RequestInterceptor } from '../../service/http/axios/interceptor';

export const REQUEST_INTERCEPTORS: InjectionToken<RequestInterceptor[]> =
  new InjectionToken('requestInterceptors');

export const REQUEST_DIRECT_INTERCEPTORS: InjectionToken<RequestInterceptor[]> =
  new InjectionToken('requestDirectInterceptors');

export const REQUEST_LOGOUT_INTERCEPTORS: InjectionToken<RequestInterceptor[]> =
  new InjectionToken('requestLogoutInterceptors');
