import type {
  InjectOptions,
  Provider,
  ProviderToken,
  Signal,
} from '@angular/core';
import {
  DestroyRef,
  InjectionToken,
  Injector,
  inject,
  runInInjectionContext,
} from '@angular/core';

import type {
  ClassSchemeDetailDTO,
  DashboardApiModel,
  DataSourceDetailApiModel,
  FilterModel,
  GVVIDataSourceDetailDTO,
  LocationApiModel,
  ProjectApiModel,
  GraphicalObjectModel,
} from '@gv/api';

export function injectContext<T>(token: ProviderToken<ContextProvider<T>>): T;
export function injectContext<T>(
  token: ProviderToken<ContextProvider<T>>,
  options: InjectOptions & {
    optional?: false;
  },
): T;
export function injectContext<T>(
  token: ProviderToken<ContextProvider<T>>,
  options: InjectOptions,
): T | null;
export function injectContext<T>(
  token: ProviderToken<ContextProvider<T>>,
  options?: InjectOptions,
): T | null {
  return inject(token, options ?? {})?.() ?? null;
}

type InferContextType<T> = T extends ContextProvider<infer J> ? J : never;

export class ContextProviderBuilder<T extends ContextProvider<unknown>> {
  private _onInit: (() => void)[] = [];
  private _onDestroy: (() => void)[] = [];
  constructor(
    private token: ProviderToken<T>,
    private context: () => InferContextType<T>,
  ) {}

  init(fn: (typeof this._onInit)[number]) {
    this._onInit.push(fn);
    return this;
  }

  destroy(fn: (typeof this._onDestroy)[number]) {
    this._onDestroy.push(fn);
    return this;
  }

  build(): Provider {
    return {
      provide: this.token,
      useFactory: () => {
        const c = this.context();
        const injector = inject(Injector);
        return () => {
          // this will be executed in injection context of destination
          this._onInit.forEach((f) => f());
          const destroyRef = inject(DestroyRef);
          this._onDestroy.forEach((f) =>
            destroyRef.onDestroy(() => runInInjectionContext(injector, f)),
          );

          return c;
        };
      },
    };
  }
}

export type ContextProvider<T> = () => T;

export interface ProjectContext {
  readonly uuidS: Signal<string>;
  readonly projectS: Signal<ProjectApiModel>;
}

export interface ClassificationContext {
  readonly schemeS: Signal<ClassSchemeDetailDTO>;
}

export interface DataSourceContext {
  readonly dataSourceS: Signal<
    DataSourceDetailApiModel | GVVIDataSourceDetailDTO
  >;
  readonly dataSourceUuidS: Signal<string>;
  readonly fullDataSourceS: Signal<
    (DataSourceDetailApiModel | GVVIDataSourceDetailDTO) & {
      dtSent: Date;
    }
  >;
}

export interface LocationContext {
  readonly locationS: Signal<LocationApiModel>;
  readonly uuidS: Signal<string>;
}

export interface DashboardContext {
  readonly dashboardS: Signal<DashboardApiModel>;
}

export const PROJECT_CONTEXT = new InjectionToken<
  ContextProvider<ProjectContext>
>('projectContext');

export const CLASSIFICATION_CONTEXT = new InjectionToken<
  ContextProvider<ClassificationContext>
>('classificationContext');

export const FILTERS_CONTEXT = new InjectionToken<
  ContextProvider<{
    filtersS: Signal<readonly FilterModel[]>;
  }>
>('filterContext');

export const GRAPHICAL_OBJECTS_CONTEXT = new InjectionToken<
  ContextProvider<{
    graphicalObjectsS: Signal<
      readonly (GraphicalObjectModel & { dtSent: Date; updated?: boolean })[]
    >;
  }>
>('graphicalObjectContext');

export const DATA_SOURCE_CONTEXT = new InjectionToken<
  ContextProvider<DataSourceContext>
>('dataSourceContext');

export const LOCATION_CONTEXT = new InjectionToken<
  ContextProvider<LocationContext>
>('locationContext');

export const DASHBOARD_CONTEXT = new InjectionToken<
  ContextProvider<DashboardContext>
>('dashboardContext');

export interface PublicDashboardContext {
  readonly uuidS: Signal<string>;
}

export const PUBLIC_DASHBOAD_CONTEXT = new InjectionToken<
  ContextProvider<PublicDashboardContext>
>('publicDashboardContext');
