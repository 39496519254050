import type { Observable } from 'rxjs';
import { EMPTY, throwError } from 'rxjs';
import { isFunction } from 'lodash-es';

import type { Api, ApiDefinition } from './api-def';
import { apiDefinition as _apiDefinition } from './api-def';
import type { HttpService } from './type/http-service';

const paramRegex = /\{\}/g;

export function replaceArgs(url: string, args: unknown[]): string {
  const urlParts = url.split(paramRegex);

  if (urlParts.length === 1) {
    return urlParts[0];
  }

  let urlWithParams = '';

  for (let i = 0; i < urlParts.length - 1; i += 1) {
    urlWithParams += urlParts[i];
    urlWithParams += `${args[i] as string}`;
  }

  urlWithParams += urlParts[urlParts.length - 1];

  return urlWithParams;
}

function callApi<
  T extends object = Api,
  R extends ApiDefinition = ApiDefinition,
>(
  baseURL: undefined | string,
  apiById: Record<keyof T, R>,
  httpClient: HttpService,
  id: string,
  ..._args: unknown[]
): Observable<unknown> {
  const allArgs = _args.slice();
  const definition = apiById[id as keyof T] as R;

  const options: {
    headers?: Record<string, string>;
    params?: Record<string, string>;
    responseType?: 'json' | 'blob';
    baseURL?: string;
  } = {
    baseURL,
  };

  const paramCount = definition.p ?? 0;
  const params = allArgs.slice(0, paramCount);

  const body: unknown = definition.b ? allArgs[paramCount] : undefined;

  const optionsPos = paramCount + (definition.b ? 1 : 0);

  Object.assign(
    options,
    definition.bin ? { responseType: 'blob' } : {},
    allArgs.length > optionsPos ? allArgs[optionsPos] : undefined,
  );

  const path = `${replaceArgs(definition.e, params)}`;

  switch (definition.m) {
    case 'delete':
      return httpClient.delete(path, definition.d, options);
    case 'get':
      return httpClient.get(path, definition.d, options);
    case 'head':
      return httpClient.head(path, definition.d, options);
    case 'put':
      return httpClient.put(path, body || {}, definition.d, options);
    case 'post':
      return httpClient.post(path, body || {}, definition.d, options);
  }

  return throwError(() => new Error('Invalid api definition ' + id));
}

export function createApi<
  T extends object = Api,
  R extends ApiDefinition = ApiDefinition,
>(
  httpClient: HttpService,
  testMode = false,
  apiDefinition = _apiDefinition,
  baseUrl = undefined,
): T {
  const api = {};

  const apiById: Record<keyof T, R> = apiDefinition.reduce(
    (acc, v) => {
      acc[v.id as keyof T] = v as R;
      return acc;
    },
    {} as Record<keyof T, R>,
  );

  const properties: Record<string, PropertyDescriptor> = {};

  const basePropertyDefinition: PropertyDescriptor = {
    enumerable: true,
    ...(testMode
      ? {
          configurable: true,
          writable: true,
        }
      : { configurable: false, writable: false }),
  };

  for (let i = 0; i < apiDefinition.length; i += 1) {
    const def = apiDefinition[i];
    const name = def.id;
    properties[def.id] = {
      ...basePropertyDefinition,
      value: {
        [name]: function (...args: unknown[]) {
          return callApi<T, R>(baseUrl, apiById, httpClient, name, ...args);
        },
      }[name], // needed to define method with correct name
    };
  }

  Object.defineProperties(api, properties);
  return api as T;
}

function callApiMock<
  T extends object = Api,
  R extends ApiDefinition = ApiDefinition,
>(
  data: {
    [K in keyof T]?: any;
  },
  _apiById: Record<keyof T, R>,
  id: keyof T,
  ...args: unknown[]
): Observable<unknown> {
  const val = data[id];
  return isFunction(val)
    ? (val(...args) as Observable<unknown>)
    : (val as Observable<unknown>) ?? EMPTY;
}

export function createMockApi<
  T extends Api,
  R extends ApiDefinition,
  D extends {
    [K in keyof T]?: any;
  },
>(data: D, apiDefinition = _apiDefinition): T {
  const api = {};

  const apiById: Record<keyof T, R> = apiDefinition.reduce(
    (acc, v) => {
      acc[v.id as keyof T] = v as R;
      return acc;
    },
    {} as Record<keyof T, R>,
  );

  const properties: Record<string, PropertyDescriptor> = {};

  const basePropertyDefinition: PropertyDescriptor = {
    enumerable: true,
    configurable: true,
    writable: true,
  };

  for (let i = 0; i < apiDefinition.length; i += 1) {
    const def = apiDefinition[i];
    const name = def.id;
    properties[def.id] = {
      ...basePropertyDefinition,
      value: {
        [name]: function (...args: unknown[]) {
          return callApiMock<T, R>(data, apiById, name as keyof T, ...args);
        },
      }[name], // needed to define method with correct name
    };
  }

  Object.defineProperties(api, properties);
  return api as T;
}
