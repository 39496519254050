import type { MonoTypeOperatorFunction } from 'rxjs';
import { Observable } from 'rxjs';

export function softTimeout<T>(
  timeout: number,
  onTimeout: () => void,
): MonoTypeOperatorFunction<T> {
  return (source) => {
    return new Observable<T>((sub) => {
      let timer: ReturnType<typeof setTimeout>;

      function stopTimer(): void {
        clearTimeout(timer);
      }

      function onNotified(): void {
        stopTimer();

        timer = setTimeout(onTimeout, timeout);
      }

      const subscription = source.subscribe({
        next(val: T): void {
          onNotified();
          sub.next(val);
        },
        complete(): void {
          stopTimer();
          sub.complete();
        },
        error(error: unknown): void {
          stopTimer();
          sub.error(error);
        },
      });

      sub.add(subscription);

      return () => {
        stopTimer();
      };
    });
  };
}
