import { CommonModule, AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  NgModule,
  inject,
} from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { trackEvent } from '@gv/analytics';
import { logo$ } from '@gv/ui/core';
import { SubmitOnEnterDirective } from '@gv/ui/form';

import { APP_CONFIG } from '../../../entity/token/app.config';
import { ConfigModule } from '../../../module/setup/config.module';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-base-sign-page',
  template: `
    <img
      class="gv-logo optimize-contrast absolute left-8 top-4 m-4"
      [src]="logo$ | async"
    />
    <div
      submitOnEnter
      class="white-box z-10 mx-4 mt-12 flex min-w-0 flex-col items-center space-y-6 rounded-md bg-white px-14 py-12 shadow-xl"
    >
      <ng-content></ng-content>
    </div>

    <div
      class="absolute bottom-0 flex h-8 w-full items-center justify-center bg-black/15 text-sm text-[#ababab]"
    >
      <ng-container i18n="@@base-sign-page.learn-more"
        >Want to learn more about GoodVision?&nbsp;
      </ng-container>
      <a
        class="link text-color-primary font-semibold"
        [href]="appConfig.productLink"
        target="_blank"
        i18n="@@base-sign-page.website"
        (click)="trackEvent()"
      >
        Visit our website
      </a>
    </div>
  `,
  host: {
    class:
      'flex flex-col pb-12 justify-center items-center min-h-full relative',
  },
  styles: [
    `
      :host {
        background: url('../../../../css-assets/images/default-background.png')
          no-repeat center center fixed;
        background-size: cover;

        &.large {
          .white-box {
            width: 550px !important;
          }
        }
      }

      .gv-logo {
        @media (max-width: 1350px) {
          margin-top: 1rem;
          margin-bottom: -1rem;
          position: initial !important;
        }
      }

      .white-box,
      .gv-logo {
        width: 390px;
      }
    `,
  ],
  standalone: true,
  imports: [SubmitOnEnterDirective, AsyncPipe],
})
export class BaseSignPageComponent {
  appConfig = inject(APP_CONFIG);
  @Input()
  @HostBinding('class.large')
  large: boolean;

  readonly logo$ = logo$;

  trackEvent(): void {
    trackEvent('articleOpen', {
      source: 'sign-up',
      articleType: 'visit-website',
    });
  }
}

@NgModule({
  imports: [
    CommonModule,
    ConfigModule,
    SubmitOnEnterDirective,
    MatDialogModule,
    BaseSignPageComponent,
  ],
  exports: [BaseSignPageComponent],
})
export class BaseSignPageModule {}
