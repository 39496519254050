import type { OnDestroy, OnInit } from '@angular/core';
import { Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { NgClass } from '@angular/common';

import type { LoginUser, VerifyRequestWEmail } from '@gv/api';
import { RequestType } from '@gv/api';
import { StoreInject } from '@gv/state';
import { SlugHashUtils } from '@gv/ui/core';
import { BehaviorField } from '@gv/utils';
import type { Subscription } from 'rxjs';
import { BehaviorSubject, combineLatest, of, ReplaySubject } from 'rxjs';
import { catchError, filter, map, take, timeout } from 'rxjs/operators';
import {
  FormBuilder,
  gvZ,
  ValidationErrorComponent,
  EmptySpaceValidatorDirective,
  EqualityValidatorDirective,
} from '@gv/ui/form';
import { ButtonSubmittingTextDirective, ButtonComponent } from '@gv/ui/utils';
import { SnackBarService } from '@gv/ui/toaster';

import type { ChangePasswordRequestDataModel } from '../../../entity/model/user-area/change-password-request-data-model';
import { APP_CONFIG } from '../../../entity/token/app.config';
import { ApiService } from '../../../service/api/api.service';
import { UserService as UserApiService } from '../../../service/api/user.service';
import { ScrollbarService } from '../../../service/application/scrollbar.service';
import { ThemeService } from '../../../service/application/theme.service';
import { UserService } from '../../../service/application/user/user.service';
import { BrowserTypeService } from '../../../service/helper/browser-type.service';
import { PasswordValidatorService } from '../../../service/helper/validator/password-validator.service';
import { RouterActions } from '../../../store/action/router.actions';
import { APP_STATE } from '../../../store/state/app-state';
import { BaseSignPageComponent } from '../base-sign-page/base-sign-page.component';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  standalone: true,
  imports: [
    BaseSignPageComponent,
    NgClass,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    EmptySpaceValidatorDirective,
    EqualityValidatorDirective,
    ValidationErrorComponent,
    ButtonComponent,
    ButtonSubmittingTextDirective,
  ],
})
export class RestorePasswordComponent implements OnDestroy, OnInit {
  private appConfig = inject(APP_CONFIG);
  private fb = inject(FormBuilder);
  private userService = inject(UserService);
  private userApiService = inject(UserApiService);
  private apiService = inject(ApiService);
  private scrollbar = inject(ScrollbarService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  passwordValidator = inject(PasswordValidatorService);
  private store = inject(StoreInject(APP_STATE));
  private browserType = inject(BrowserTypeService);
  private themeService = inject(ThemeService);
  private onDestroy$ = new ReplaySubject<void>();

  protected submitting = new BehaviorField(false);

  requestUuid: string;

  email: string;

  isRequestVerified = false;
  isRequestVerifiedLoaded = false;

  passwordValidationPattern: RegExp =
    this.appConfig.validation.userPassword.pattern;

  formGroup = this.fb.group(
    gvZ
      .object({
        password: gvZ.string().regex(this.passwordValidationPattern),
        passwordRepeated: gvZ.string().regex(this.passwordValidationPattern),
      })
      .superRefine((data, ctx) => {
        const firstValue = data.password;
        const secondValue = data.passwordRepeated;

        if (firstValue && secondValue !== firstValue) {
          ctx.addIssue({
            code: 'custom',
            message: $localize`:@@restore-password.validation-error-password-mismatch:Password does not match the password above`,
            path: ['passwordRepeated'],
          });
        }
      }),
    {
      password: '',
      passwordRepeated: '',
    },
  );

  private snackBarService = inject(SnackBarService);

  private verifyRequestSubscription: Subscription | undefined;
  private changePasswordSubscription: Subscription | undefined;
  private authenticateSubscription: Subscription | undefined;
  private getUserSubscription: Subscription | undefined;

  noOrgLogo = new BehaviorSubject<boolean>(false);

  SlugHashUtils = SlugHashUtils;

  ngOnDestroy(): void {
    if (this.verifyRequestSubscription) {
      this.verifyRequestSubscription.unsubscribe();
      this.verifyRequestSubscription = undefined;
    }

    if (this.changePasswordSubscription) {
      this.changePasswordSubscription.unsubscribe();
      this.changePasswordSubscription = undefined;
    }

    if (this.authenticateSubscription) {
      this.authenticateSubscription.unsubscribe();
      this.authenticateSubscription = undefined;
    }

    if (this.getUserSubscription) {
      this.getUserSubscription.unsubscribe();
      this.getUserSubscription = undefined;
    }

    this.snackBarService.dismissAll();

    this.scrollbar.isScrollbarVisible = true;

    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit(): void {
    this.themeService.forceTheme(this.onDestroy$);

    combineLatest([
      this.activatedRoute.params.pipe(map((params) => params['request'])),
      this.activatedRoute.queryParams.pipe(
        map((queryParams) =>
          typeof queryParams['email'] === 'string'
            ? decodeURI(queryParams['email'])
            : queryParams['email'],
        ),
      ),
    ])
      .pipe(
        filter(([requestUuid, email]) => !!requestUuid && !!email),
        take(1),
        timeout(5000),
        catchError(() => of([undefined, undefined] as const)),
      )
      .subscribe(([requestUuid, email]) => {
        this.requestUuid = requestUuid;
        this.email = email;
        this.verifyRequest();
      });

    this.scrollbar.isScrollbarVisible = false;
  }

  async savePassword(): Promise<boolean> {
    if (this.submitting.value) {
      return Promise.resolve(false);
    }

    if (
      !this.formGroup.controls['password'].value ||
      this.formGroup.controls['password'].value.length === 0
    ) {
      return Promise.resolve(false);
    } else if (
      this.formGroup.controls['password'].value !==
      this.formGroup.controls['passwordRepeated'].value
    ) {
      return Promise.resolve(false);
    }

    await this.verifyRequest();

    if (this.isRequestVerified) {
      return this.changePassword();
    }

    return await Promise.resolve(false);
  }

  private async verifyRequest(): Promise<void> {
    try {
      await this.checkRequest();
      this.isRequestVerified = true;
    } catch (e) {
      this.isRequestVerified = false;
    }

    this.isRequestVerifiedLoaded = true;
  }

  private checkRequest(): Promise<void> {
    if (!this.requestUuid || this.requestUuid.trim() === '') {
      return Promise.reject(undefined);
    } else if (!this.email || this.email.trim() === '') {
      return Promise.reject(undefined);
    }

    const requestVerificationData: VerifyRequestWEmail = {
      email: this.email,
      request: {
        type: RequestType.UserPasswordChange,
        uuid: this.requestUuid,
      },
    };

    if (this.verifyRequestSubscription) {
      this.verifyRequestSubscription.unsubscribe();
    }

    return new Promise<void>((resolve, reject) => {
      this.verifyRequestSubscription = this.apiService.api
        .verifyRequest(requestVerificationData)
        .subscribe({
          next: () => {
            resolve();
          },
          error: (_: any) => {
            reject();
          },
        });
    });
  }

  private changePassword(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.changePasswordSubscription) {
        this.changePasswordSubscription.unsubscribe();
      }

      if (this.authenticateSubscription) {
        this.authenticateSubscription.unsubscribe();
      }

      if (this.getUserSubscription) {
        this.getUserSubscription.unsubscribe();
      }

      const changePasswordRequestData: ChangePasswordRequestDataModel = {
        email: this.email,
        request: {
          uuid: this.requestUuid,
          type: RequestType.UserPasswordChange,
          password: this.formGroup.controls['password'].value,
        },
      };

      this.changePasswordSubscription = this.userApiService
        .changePassword(changePasswordRequestData)
        .subscribe({
          next: () => {
            this.autoLogin(
              changePasswordRequestData.email,
              changePasswordRequestData.request.password,
              false,
              resolve,
            );
          },
          error: (_response: any) => {
            this.isRequestVerified = false;
            resolve(false);
          },
        });
    });
  }

  private autoLogin(
    email: string,
    password: string,
    rememberMe: boolean,
    resolve: (value?: boolean | PromiseLike<boolean>) => void,
  ): void {
    const credentials: LoginUser = {
      email,
      password,
      rememberMe,
    };

    if (this.authenticateSubscription) {
      this.authenticateSubscription.unsubscribe();
    }

    if (this.getUserSubscription) {
      this.getUserSubscription.unsubscribe();
    }

    this.snackBarService.dismissAll();

    this.submitting.value = true;
    this.userApiService
      .loginUser({
        ...credentials,
        rememberMe: this.browserType.isElectron || credentials.rememberMe,
      })
      .subscribe({
        next: ([token, user]) => {
          this.userService.logIn(token, user.dtSent, user);
          this.userService.setLanguageCookie(user?.language);
          this.store.dispatch(RouterActions.navigateToProjects({}));
          this.submitting.value = false;
        },
        error: (_response: any) => {
          // failed to autologin, navigate to manual login
          resolve(this.router.navigateByUrl('/login'));
          this.submitting.value = false;
        },
      });
  }
}
