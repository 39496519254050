import type { DestroyRef } from '@angular/core';

import type { Observable } from 'rxjs';
import { firstValueFrom } from 'rxjs';

import { untilNgDestroyed } from '../operator/until-destroyed';

export class ObservableUtils {
  static toPromise<T>(
    observable: Observable<T>,
    destroyRef?: DestroyRef,
  ): Promise<T> {
    let obs = observable;
    if (destroyRef) {
      obs = observable.pipe(untilNgDestroyed(destroyRef));
    }

    return firstValueFrom(obs);
  }
}
