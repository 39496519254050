/**
 * This is a partially generated file. Some edits may be overwritten!
 * @apiRef {@model VaultFileState}
 */
export enum VaultFileState {
  Uploading = 'UPLOADING',
  Verifying = 'VERIFYING',
  Error = 'ERROR',
  Ready = 'READY',
  Processing = 'PROCESSING',
  Processed = 'PROCESSED',
}
