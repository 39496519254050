import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { ScrollDirective } from '@gv/ui/utils';

@Component({
  selector: 'gv-dialog-content',
  standalone: true,
  imports: [MatDialogModule, ScrollDirective],
  hostDirectives: [
    {
      directive: ScrollDirective,
    },
  ],
  host: {
    class: 'min-h-0 flex-auto',
  },
  template: `
    <mat-dialog-content [class.will-change-transform]="willChange">
      <ng-content></ng-content>
    </mat-dialog-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        max-height: 65vh;
      }

      mat-dialog-content {
        max-height: unset;
      }

      :host-context(.full-height-dialog) {
        max-height: 100% !important;
      }

      :host-context(.fixed-height-dialog) {
        :host {
          max-height: unset;
        }
        mat-dialog-content {
          height: 100%;
        }
      }

      :host.disable-scroll {
        max-height: 100% !important;
        display: flex;
        flex-direction: column;

        mat-dialog-content {
          max-height: 100%;
          flex: 1 1 auto;
        }
      }
    `,
  ],
})
export class DialogContentComponent {
  @HostBinding('class.disable-scroll')
  @Input()
  disableScroll = false;

  @Input()
  willChange = false;
}
