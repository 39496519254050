import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type { Draft } from 'immer';
import { produce } from 'immer';
import { isEqual } from 'lodash-es';
import { asMutable, isActionOfType, createSubStateReducerFns } from '@gv/state';
import { UserActions } from '@gv/user';
import { LayoutView } from '@gv/api';
import type { DataSourceDetailStateModel } from '@gv/datasource';
import { DataSourceActions } from '@gv/datasource';
import { SchemesActions } from '@gv/ui/classification-scheme';
import { getValueOrDie } from '@gv/utils';

import { LocationActions } from '../action/location.actions';
import type { LocationState } from '../state/location.state';
import {
  initialDataSourcesSubState,
  initialLocationState,
  initialLocationSubState,
} from '../state/location.state';

export const locationFeatureKey = 'location';

const locationReducer = createReducer(
  initialLocationState,

  on(LocationActions.init, (state, action) =>
    produce(state, (draft) => {
      if (state.uuid === action.uuid) {
        return;
      }

      draft.uuid = action.uuid;
      draft.projectUuid = action.projectUuid;
      draft.projectType = action.projectType;

      draft.location = {
        ...asMutable(initialLocationSubState),
        watching: draft.location.watching,
        highlighted: draft.location.highlighted,
      };

      draft.dataSources = {
        ...asMutable(initialDataSourcesSubState),
        watching: draft.dataSources.watching,
        highlighted: draft.dataSources.highlighted,
      };

      draft.locationPageSettings = initialLocationState.locationPageSettings;
    }),
  ),

  ...createSubStateReducerFns(
    LocationActions.location,
    (state: Draft<LocationState>) => state.location,
    (state, location, dtSent) => ({
      projectUuid: state.projectUuid,
      ...location,
      dtSent: dtSent,
    }),
    $localize`:@@location.failed-to-load-location:Failed to load location`,
  ),

  on(
    LocationActions.dataSources.fetch.completed,
    (state, action): LocationState => {
      if (!action.sortable) {
        return state;
      }

      return produce(state, (draft) => {
        draft.locationPageSettings.pageView =
          action.sortable.layoutView || LayoutView.Map;
        draft.locationPageSettings.sortDirection =
          action.sortable.sortDirection;
        draft.locationPageSettings.sortingBy = action.sortable.sortBy;
        draft.locationPageSettings.mapSettings = {
          ...action.sortable.mapSettings,
          preferredZoom: action.sortable.mapSettings.mapZoom,
        };
      });
    },
  ),

  ...createSubStateReducerFns(
    LocationActions.dataSources,
    (state: Draft<LocationState>) => state.dataSources,
    (state, dataSources, dtSent) =>
      dataSources.map(
        (p): DataSourceDetailStateModel => ({
          ...p,
          dtSent,
          deleted: false,
          waitingForNotification: false,
        }),
      ),
    $localize`:@@location.failed-to-load-data-sources:Failed to load data sources`,
    true,
  ),

  on(DataSourceActions.add, (state, { dataSource }): LocationState => {
    // not add GVVI data sources
    if (!state.uuid || !('deviceStream' in dataSource)) {
      return state;
    }

    const { dataSources } = state;

    if (!dataSources.loaded) {
      return state;
    }

    if (dataSources.freshness.getTime() >= dataSource.dtSent.getTime()) {
      return state;
    }

    return produce(state, (draft) => {
      draft.dataSources.data[dataSource.uuid] = {
        ...asMutable(dataSource),
        deleted: false,
        waitingForNotification: false,
      };
      if (!dataSources.data[dataSource.uuid]) {
        draft.dataSources.ids.push(dataSource.uuid);
      }
    });
  }),

  on(DataSourceActions.remove, (state, { uuid }): LocationState => {
    if (
      !state.uuid ||
      !state.dataSources.loaded ||
      !(uuid in state.dataSources.data)
    ) {
      return state;
    }

    return produce(state, (draft) => {
      const index = draft.dataSources.ids.indexOf(uuid);
      if (index >= 0) {
        draft.dataSources.ids.splice(index, 1);
        delete draft.dataSources[uuid];
      }
    });
  }),

  on(
    DataSourceActions.update,
    (
      state,
      {
        uuid,
        newLocationUuid,
        dataSource,
        isPartial,
        waitingForNotification,
        updateAfterCopy,
      },
    ): LocationState => {
      if (
        !state.uuid ||
        !state.dataSources.loaded ||
        !(uuid in state.dataSources.data)
      ) {
        return state;
      }

      const moveToDifferentLocation =
        newLocationUuid && newLocationUuid !== state.uuid;

      return produce(state, (draft) => {
        if (moveToDifferentLocation) {
          const index = draft.dataSources.ids.indexOf(uuid);
          if (index !== -1) {
            draft.dataSources.ids.splice(index, 1);
          }
          if (uuid in draft.dataSources.data) {
            delete draft.dataSources.data[uuid];
          }
        } else {
          draft.dataSources.data[uuid] = {
            uuid,
            ...asMutable(state.dataSources.data[uuid]),
            ...asMutable(dataSource),
            selectedFilter: updateAfterCopy
              ? null
              : draft.dataSources.data[uuid].selectedFilter,

            waitingForNotification: waitingForNotification || false,
            deviceStream: getValueOrDie(dataSource, 'deviceStream')
              ? getValueOrDie(dataSource, 'deviceStream')
              : isPartial
                ? getValueOrDie(state.dataSources.data[uuid], 'deviceStream')
                : undefined,
          };
        }
      });
    },
  ),

  on(
    LocationActions.update,
    LocationActions.add,
    (state, action): LocationState => {
      const uuid = isActionOfType(action, LocationActions.add)
        ? action.location.uuid
        : action.uuid;
      if (!state.uuid || state.uuid !== uuid || !state.location.loaded) {
        return state;
      }

      const location = action.location;

      if (
        location.dtSent &&
        (state.location.freshness.getTime() >= location.dtSent.getTime() ||
          state.location.data.dtSent.getTime() >= location.dtSent.getTime())
      ) {
        return state;
      }

      const newLocation = {
        ...state.location.data,
        ...location,
        dtSent: location.dtSent ?? state.location.data.dtSent,
      };

      return {
        ...state,
        location: {
          ...state.location,
          freshness: location.dtSent ?? state.location.freshness,
          data: newLocation,
        },
      };
    },
  ),

  on(LocationActions.remove, (state, { uuid }): LocationState => {
    if (!state.uuid || state.uuid !== uuid || !state.location.loaded) {
      return state;
    }

    const newState = {
      ...initialLocationState,
      uuid,
    };

    return produce(newState, (draft) => {
      draft.location.watching = state.location.watching;
      draft.dataSources.watching = state.dataSources.watching;
    });
  }),

  on(UserActions.logout, () => initialLocationState),

  on(
    LocationActions.setLocationPageSettings,
    (state, action): LocationState => {
      if (isEqual(state.locationPageSettings, action.settings)) {
        return state;
      }

      return {
        ...state,
        locationPageSettings: action.settings,
      };
    },
  ),

  on(
    LocationActions.setLocationMapPreferences,
    (state, action): LocationState => {
      if (isEqual(state?.locationPageSettings?.mapSettings, action.settings)) {
        return state;
      }

      return {
        ...state,
        locationPageSettings: {
          ...state.locationPageSettings,
          mapSettings: action.settings,
        },
      };
    },
  ),

  // TODO: projects
  // on(ProjectsActions.pageSettingsUpdated.init, (state, action): LocationState => {
  //   if (isEqual(state?.locationPageSettings, action.settings)) {
  //     return state;
  //   }

  //   return {
  //     ...state,
  //     locationPageSettings: {
  //       ...state.locationPageSettings,
  //       // TODO: projects
  //       // pageView: action.settings.pageView || LayoutView.Map,
  //       sortingBy: action.settings.sortingBy,
  //       sortDirection: action.settings.sortDirection,
  //     },
  //   };
  // }),

  on(
    SchemesActions.editScheme,
    SchemesActions.editCustomSchemeFlow.completed,
    (state, action): LocationState => {
      return produce(state, (draft): void => {
        const dataSourcesForUpdate = state.dataSources.ids.filter(
          (uuid) =>
            state.dataSources.data[uuid]?.classScheme?.uuid ===
            action.data.uuid,
        );

        if (dataSourcesForUpdate?.length === 0) {
          return;
        }

        dataSourcesForUpdate.forEach((uuid) => {
          draft.dataSources.data[uuid].classScheme = asMutable(action.data);
        });
      });
    },
  ),
);

export function reducer(
  state: LocationState | undefined,
  action: Action,
): LocationState {
  return locationReducer(state, action);
}
