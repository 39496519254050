import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';

import { init } from './mixpanel';
import { MixPanelEffects } from './mixpanel.effects';

@NgModule({
  imports: [EffectsModule.forFeature([MixPanelEffects])],
})
export class MixPanelModule {
  constructor() {
    init();
  }
}
