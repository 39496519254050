import type { ClientLogLevel } from '@gv/api';
import { createAction, props } from '@ngrx/store';

export interface ClientLogDataModel {
  datetime: string;

  level: ClientLogLevel;

  message: string;
}

const basename = '[Client Logger]';

export const sendLog = createAction(
  `${basename} send`,
  props<ClientLogDataModel>(),
);
