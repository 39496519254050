/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse } from '@angular/common/http';

import { ApiErrorResponse } from '@gv/api';
import type { SeverityLevel } from '@sentry/angular';

import { HttpError, SentryError, UnauthorizedError } from '../error';

export function extractLogLevel(
  error: unknown,
  defaultLogLevel: SeverityLevel = 'error',
): SeverityLevel {
  return ((error instanceof HttpErrorResponse ||
    error instanceof ApiErrorResponse) &&
    [0, 302, 401, 404].includes(error.status)) ||
    error instanceof UnauthorizedError
    ? 'info'
    : defaultLogLevel;
}

export function toSentryError(
  context: string | undefined,
  error: unknown,
  message = '',
): Error | undefined {
  if (!error || error instanceof SentryError || error instanceof HttpError) {
    return (error as Error) || undefined;
  }

  if (error instanceof Error) {
    if (error.name) {
      // need to create a copy and do not modify it inplace
      // @see https://issues.goodvisionlive.com/youtrack/issue/GVVI-3209
      const newError = cloneError(error);
      newError.name = `${newError.name}[${context || ''}]`;
      return newError;
    }
    return error;
  }

  if (error instanceof HttpErrorResponse || error instanceof ApiErrorResponse) {
    return new HttpError(context || '', error);
  }

  return new SentryError(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    Object.prototype.hasOwnProperty.call(error, 'name') && (error as any).name
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-explicit-any
        `${(error as any).name}[${context || ''}]`
      : context || '',
    message,
    error,
  );
}

function cloneError(error: Error): Error {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const prototype = Object.getPrototypeOf(error);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return Object.create(prototype, {
    name: { value: error.name, writable: true },
    message: { value: error.message, writable: true },
    ...Object.getOwnPropertyNames(error)
      .filter((n) => !['name', 'message'].includes(n))
      .reduce((acc, property: string) => {
        return {
          ...acc,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          [property]: { value: (error as any)[property], writable: true },
        };
      }, {}),
  });
}
