import { Injectable, inject } from '@angular/core';

import type { ApiResponse } from '@gv/api';
import { ApiErrorResponse } from '@gv/api';
import { toSentryError } from '@gv/utils';
import { captureException, withScope } from '@sentry/angular';
import type { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiService } from '../../../api/api.service';
import type { RequestConfig } from '../http';
import type { RequestHandler, RequestInterceptor } from '../interceptor';

@Injectable({
  providedIn: 'root',
})
export class InternalServerErrorInterceptor implements RequestInterceptor {
  private apiService = inject(ApiService);
  private static readonly replaceUuidRegex =
    // eslint-disable-next-line no-useless-escape
    /\b[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/g;

  intercept(
    request: RequestConfig<any>,
    next: RequestHandler,
  ): Observable<ApiResponse<any>> {
    if (!this.apiService.isApiRequest(request)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof ApiErrorResponse && error.status === 500) {
          withScope((scope) => {
            scope.setLevel('info');
            scope.setFingerprint([
              'InternalServerError',
              request.method,
              request.url.replace(
                InternalServerErrorInterceptor.replaceUuidRegex,
                '',
              ),
            ]);
            captureException(toSentryError('InternalServerError', error));
          });
        }

        return throwError(() => error);
      }),
    );
  }
}
