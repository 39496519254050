import type { AfterViewInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
  inject,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AsyncPipe } from '@angular/common';

import { LinkToHelpdeskComponent } from '@gv/ui/help';
import { TestIdModule } from '@gv/debug';

import { UnsupportedBrowserSnackBarService } from '../../../service/component/dialog/unsupported-browser-snack-bar.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-unsupported-browser-snack-bar',
  templateUrl: './unsupported-browser-snack-bar.component.html',
  host: {
    class: 'fixed top-0 left-0 w-full text-sm bg-overlay-snackbar',
  },
  standalone: true,
  imports: [
    LinkToHelpdeskComponent,
    MatButtonModule,
    MatIconModule,
    AsyncPipe,
    TestIdModule,
  ],
})
export class UnsupportedBrowserSnackBarComponent implements AfterViewInit {
  service = inject(UnsupportedBrowserSnackBarService);
  private _showMobileDetected = false;
  get showMobileDetected(): boolean {
    return this._showMobileDetected;
  }
  @Input()
  set showMobileDetected(value: boolean) {
    this._showMobileDetected = value;
    if (value) {
      this.service.hide();
    }
  }

  @ViewChild('message')
  elementView: ElementRef<HTMLDivElement>;

  ngAfterViewInit(): void {
    this.updateHeight();
  }

  hide(): void {
    this.service.hide();
  }

  @HostListener('window:resize')
  updateHeight(): void {
    if (this.elementView && this.elementView.nativeElement) {
      const height = (this.elementView.nativeElement as HTMLElement)
        .offsetHeight;
      this.service.height = height;
    }
  }
}
