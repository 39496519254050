import { createAction, props } from '@ngrx/store';
import type {
  LocationApiModel,
  ProjectApiModel,
  MapSettings,
  ProjectDetailDTO,
  LiveProjectDataSourceDTO,
} from '@gv/api';
import { createSubStateActions } from '@gv/state';
import type { PageSettings } from '@gv/page-settings';

import type { LocationStateModel } from '../../entity/model/location/location-state-model';
import type { ProjectStateModel } from '../../entity/model/projects/project-state-model';

const basename = '[Project]';
const projectBasename = '[Project] project';
const locationBasename = '[Project] locations';
const projectDataSourcesBasename = '[Project] data sources';

export const ProjectActions = {
  init: createAction(`${basename} init[init]`, props<{ uuid: string }>()),

  add: createAction(
    `${basename} add`,
    props<{ project: ProjectApiModel; dtSent: Date }>(),
  ),

  remove: createAction(`${basename} remove`, props<{ uuid: string }>()),

  update: createAction(
    `${basename} update`,
    props<{
      uuid: string;
      project: ProjectDetailDTO;
      dtSent?: Date;
    }>(),
  ),

  project: createSubStateActions<ProjectApiModel, ProjectStateModel>(
    projectBasename,
  ),

  locations: createSubStateActions<
    readonly LocationApiModel[],
    readonly LocationStateModel[]
  >(locationBasename),

  projectDataSources: createSubStateActions<
    readonly LiveProjectDataSourceDTO[],
    readonly LiveProjectDataSourceDTO[]
  >(projectDataSourcesBasename),

  reset: createAction(`${basename} reset`),

  setProjectPageSettings: createAction(
    `${locationBasename} set page options`,
    props<{ settings: PageSettings; skipUpdate: boolean }>(),
  ),

  setProjectMapPreferences: createAction(
    `${locationBasename} set map settings`,
    props<{ settings: MapSettings & { preferredZoom: number } }>(),
  ),
};
