import type { DeviceDTO, DeviceStreamDTO } from '@gv/api';
import type { Store, SubStateArrayModel } from '@gv/state';
import { initialArraySubState, StoreInjectionToken } from '@gv/state';

export const devicesFeatureKey = 'devices';

export type DeviceStateModel = DeviceDTO & { readonly dtSent: Date };

export type DeviceStreamStateModel = DeviceStreamDTO & {
  waitingForNotification: boolean;
};

export type DevicesState = SubStateArrayModel<DeviceStateModel>;

export const initialDevicesState: DevicesState = {
  ...initialArraySubState,
};

export interface DevicesFeatureState {
  [devicesFeatureKey]: DevicesState;
}

export const DEVICES_FEATURE_STATE = new StoreInjectionToken<
  Store<DevicesFeatureState>
>('devicesFeatureState');
