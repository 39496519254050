import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  inject,
  ElementRef,
  HostBinding,
  Input,
  Component,
  ChangeDetectorRef,
} from '@angular/core';
import type { AfterViewInit, OnDestroy } from '@angular/core';
import { MatInputModule } from '@angular/material/input';

import { Observable, startWith } from 'rxjs';
import { untilNgDestroyed } from '@gv/utils';
import { ImageTextItem } from '@gv/constant';

@Component({
  standalone: true,
  imports: [CommonModule, MatInputModule, DragDropModule],
  host: {
    class: 'block absolute',
  },
  selector: 'gv-image-text-item',
  template: `
    <div
      [ngClass]="[data.hAlign, data.vAlign, data.fontWeight, data.color]"
      class="absolute inset-0 flex h-full w-full flex-col leading-none"
    >
      {{ data.text }}
    </div>
    <ng-content></ng-content>
  `,
})
export class ImageTextItemComponent implements AfterViewInit, OnDestroy {
  el = inject<ElementRef<HTMLElement>>(ElementRef);
  private changeDetector = inject(ChangeDetectorRef);

  private onResize$ = new Observable<void>((obs) => {
    const resizeObserver = new ResizeObserver(() => {
      obs.next();
    });

    resizeObserver.observe(this.el.nativeElement);

    return () => resizeObserver.disconnect();
  }).pipe(untilNgDestroyed());

  @Input()
  active = false;

  @Input()
  data!: ImageTextItem;

  @Input()
  border = false;

  @HostBinding('style.border')
  get _border(): any {
    if (!this.border) {
      return undefined;
    }
    return !this.active
      ? 'solid 1px rgba(var(--main-color))'
      : 'solid 1px rgba(var(--warning))';
  }

  @HostBinding('style.transform')
  get tr(): any {
    return undefined;
  }

  @HostBinding('style.top.%')
  get top(): any {
    return this.data.top * 100;
  }

  @HostBinding('style.left.%')
  get left(): any {
    return this.data.left * 100;
  }

  @HostBinding('style.font-size.px')
  get fontSize(): any {
    return Math.floor(this.data.fontSize * this.parentHeight);
  }

  @HostBinding('style.width.%')
  get width(): any {
    return this.data.width * 100;
  }

  @HostBinding('style.height.%')
  get height(): any {
    return this.data.height * 100;
  }

  parentHeight = 0;

  ngAfterViewInit(): void {
    this.onResize$.pipe(startWith(undefined)).subscribe(() => {
      this.parentHeight =
        this.el.nativeElement.offsetParent?.getBoundingClientRect().height ?? 0;
      this.changeDetector.markForCheck();
    });
  }

  ngOnDestroy(): void {
    //
  }
}
