import type { Renderer2 } from '@angular/core';
import { Injectable, NgZone, RendererFactory2, inject } from '@angular/core';

import type { ResolutionModel } from '@gv/upload/types';
import {
  InvalidVideoDurationError,
  InvalidVideoResolutionError,
  UnsupportedHtmlVideoTypeError,
  UploadFile,
} from '@gv/upload/types';

@Injectable({
  providedIn: 'root',
})
export class VideoMetadataReaderService {
  private rendererFactory = inject(RendererFactory2);
  private ngZone = inject(NgZone);
  private renderer: Renderer2;

  constructor() {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  getVideoMetadata(videoFile: File | UploadFile): Promise<{
    duration: number | InvalidVideoDurationError;
    resolution: ResolutionModel | Error;
  }> {
    const gcVideoElement = (videoElement: HTMLVideoElement) => {
      const fileObjectUrl = videoElement.src;

      // GC
      videoElement.removeAttribute('src');
      videoElement.load();

      if (fileObjectUrl.startsWith('file:///')) {
        return;
      }

      URL.revokeObjectURL(fileObjectUrl);
    };

    return this.loadVideo(videoFile).then((videoElement) => {
      const videoDuration =
        videoElement.duration !== undefined &&
        videoElement.duration !== null &&
        videoElement.duration !== Infinity
          ? videoElement.duration * 1000
          : undefined;

      const videoHeight = videoElement.videoHeight;
      const videoWidth = videoElement.videoWidth;

      gcVideoElement(videoElement);

      const resolution =
        videoHeight > 0 && videoWidth > 0
          ? {
              height: videoHeight,
              width: videoWidth,
            }
          : new InvalidVideoResolutionError(
              `Video file '${videoFile.name}' of type '${videoFile.type}' seems invalid.`,
            );

      const res = {
        duration:
          videoDuration > 0
            ? videoDuration
            : new InvalidVideoDurationError(
                `Video file '${videoFile.name}' of type '${videoFile.type}' seems invalid.`,
              ),
        resolution,
      };
      return res;
    });
  }

  private createVideoElement(): HTMLVideoElement {
    const videoElement = this.renderer.createElement(
      'video',
    ) as HTMLVideoElement;
    videoElement.preload = 'metadata';
    return videoElement;
  }

  private loadVideo(file: File | UploadFile): Promise<HTMLVideoElement> {
    return this.ngZone.runOutsideAngular(() => {
      return new Promise<HTMLVideoElement>((resolve, reject) => {
        const videoElement = this.createVideoElement();

        const canPlayResult = videoElement.canPlayType(file.type);

        if (canPlayResult === 'probably' || canPlayResult === 'maybe') {
          const fileObjectUrl =
            file instanceof UploadFile
              ? `file://${encodeURI(file.path)}`
              : URL.createObjectURL(file);

          const eventHandlers: (() => void)[] = [];

          eventHandlers.push(
            this.renderer.listen(videoElement, 'loadedmetadata', () => {
              this.destroyEventHandlers(eventHandlers);

              resolve(videoElement);
            }),
          );

          eventHandlers.push(
            this.renderer.listen(videoElement, 'error', () => {
              this.destroyEventHandlers(eventHandlers);

              reject(
                new UnsupportedHtmlVideoTypeError(
                  'Video type not supported by browser.',
                ),
              );
            }),
          );

          videoElement.src = fileObjectUrl;
        } else {
          reject(
            new UnsupportedHtmlVideoTypeError(
              'Video type not supported by browser.',
            ),
          );
        }
      });
    });
  }

  private destroyEventHandlers(eventHandlers: (() => void)[]): void {
    eventHandlers.forEach((unlistenEventHandler) => {
      unlistenEventHandler();
    });
  }
}
