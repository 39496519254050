import { props } from '@ngrx/store';
import type {
  FilterModel,
  CreateProjectCollaborationModel,
  DataMetricDTO,
  DashboardTimeSettingsType,
  LiveWidgetDTO,
  TrafficScenarioDTO,
  AuthProvider,
  GVVIDataSourceDetailDTO,
} from '@gv/api';
import { createDialogBaseActions } from '@gv/state';
import type {
  FileMetadataModel,
  VaultFileUploadItemModel,
} from '@gv/upload/types';
import type { UserModel } from '@gv/user';
import type { DataSourceDetailStateModel } from '@gv/datasource';
import type { DashboardStateModel } from '@gv/ui/dashboard';
import type {
  EditLiveWidgetDialogData,
  NewTrafficScenarioDialogData,
  TrafficScenarioDetailDialogData,
} from '@gv/ui/live-dashboard';
import type { ReportStateModel } from '@gv/ui/live-reports';

import type { ExportStateModel } from '../../entity/model/export/export-state-model';
import type { ProjectStateModel } from '../../entity/model/projects/project-state-model';
import type { LocationStateModel } from '../../entity/model/location/location-state-model';
import type { ShareProjectDialogData } from '../../component/dialog/projects/share-project/share-project.component';
import type { WidgetStateModel } from '../../entity/model/widget/widget-state-model';
import type { AcceptOwnershipData } from '../../component/dialog/projects/accept-ownership/accept-ownership-dialog.component';

const basename = '[Dialog]';

export const DialogActions = {
  deleteVideoDialog: createDialogBaseActions(basename, 'DeleteVideo', {
    openProps: props<{
      data: {
        videoUuid: string;
        dataSource: DataSourceDetailStateModel | GVVIDataSourceDetailDTO;
      };
    }>(),
    closedProps: props<{
      data: boolean;
    }>(),
  }),

  editVideoDialog: createDialogBaseActions(basename, 'EditVideo', {
    openProps: props<{
      data: {
        videoUuid: string;
        projectUuid: string;
        dataSource: GVVIDataSourceDetailDTO;
      };
    }>(),
    closedProps: props<{
      data: boolean;
    }>(),
  }),

  deleteLocationDialog: createDialogBaseActions(basename, 'DeleteLocation', {
    openProps: props<{ data: LocationStateModel }>(),
  }),

  deleteDashboardDialog: createDialogBaseActions(basename, 'DeleteDashboard', {
    openProps: props<{ data: DashboardStateModel }>(),
  }),

  deleteExportDialog: createDialogBaseActions(basename, 'DeleteExport', {
    openProps: props<{
      data: ExportStateModel;
    }>(),
  }),

  shareExportDialog: createDialogBaseActions(basename, 'ShareExport', {
    openProps: props<{
      data: ExportStateModel;
    }>(),
  }),

  uploadDialog: createDialogBaseActions(basename, 'Upload', {
    openProps: props<{
      data: {
        locationUuid: string;
        targetDataSource: DataSourceDetailStateModel;
        videoUploadItems: readonly VaultFileUploadItemModel[];
        metadata?: readonly FileMetadataModel[];
      };
    }>(),
  }),

  updateAppDialog: createDialogBaseActions(basename, 'UpdateApp', {
    openProps: props<{ data: { triggeredByApi: boolean } }>(),
    closedProps: props<{ data: boolean }>(),
  }),

  languageMismatchDialog: createDialogBaseActions(
    basename,
    'LanguageMismatch',
    {
      openProps: props<{ data: { urlPrefix: string; cookiePrefix: string } }>(),
      closedProps: props<{ data: boolean }>(),
    },
  ),

  deleteProjectDialog: createDialogBaseActions(basename, 'DeleteProject', {
    openProps: props<{
      data: ProjectStateModel;
    }>(),
  }),

  deleteSharedProjectDialog: createDialogBaseActions(
    basename,
    'DeleteSharedProject',
    {
      openProps: props<{
        data: { project: ProjectStateModel; user: UserModel };
      }>(),
    },
  ),

  editProjectDialog: createDialogBaseActions(basename, 'EditProject', {
    openProps: props<{
      data: ProjectStateModel;
    }>(),
  }),

  importingProjectDialog: createDialogBaseActions(
    basename,
    'ImportingProject',
    {
      openProps: props<{
        data: { projectUuid: string };
      }>(),
    },
  ),

  shareProjectDialog: createDialogBaseActions(basename, 'ShareProject', {
    openProps: props<{
      data: ShareProjectDialogData;
    }>(),
    closedProps: props<{ data: CreateProjectCollaborationModel }>(),
  }),

  initTransferProjectDialog: createDialogBaseActions(
    basename,
    'TransferOwnership',
    {
      openProps: props<{
        data: { uuid: string };
      }>(),
    },
  ),

  acceptProjectDialog: createDialogBaseActions(basename, 'AcceptOwnership', {
    openProps: props<{
      data: AcceptOwnershipData;
    }>(),
  }),

  newProjectDialog: createDialogBaseActions(basename, 'NewProject'),

  deleteFilterDialog: createDialogBaseActions(basename, 'DeleteFilter', {
    openProps: props<{
      data: FilterModel;
    }>(),
  }),

  deleteMetricDialog: createDialogBaseActions(basename, 'DeleteMetric', {
    openProps: props<{
      data: DataMetricDTO;
    }>(),
  }),

  newDashboardDialog: createDialogBaseActions(basename, 'NewDashboard', {
    openProps: props<{
      data: {
        assignWidget: string;
        dashboardTimeSettings: DashboardTimeSettingsType;
      };
    }>(),
  }),

  fullscreenEvent: createDialogBaseActions(basename, 'FullscreenEvent', {
    openProps: props<{
      data: { widget: LiveWidgetDTO; data: { uuid: string; row: number } };
    }>(),
  }),

  testWebhook: createDialogBaseActions(basename, 'TestLiveEventWebhook', {
    openProps: props<{
      data: WidgetStateModel;
    }>(),
  }),

  showEventDescription: createDialogBaseActions(
    basename,
    'ShowLiveEventDescription',
    {
      openProps: props<{
        data: WidgetStateModel;
      }>(),
    },
  ),

  resetLiveEvent: createDialogBaseActions(basename, 'ResetLiveEvent', {
    openProps: props<{
      data: WidgetStateModel;
    }>(),
  }),

  enableDisableEvent: createDialogBaseActions(basename, 'EnableDisableEvent', {
    openProps: props<{
      data: WidgetStateModel;
    }>(),
  }),

  newTrafficScenarioDialog: createDialogBaseActions(
    basename,
    'NewTrafficScenario',
    {
      openProps: props<{
        data: Omit<NewTrafficScenarioDialogData, 'scenario'> & {
          scenarioUuid?: string;
        };
      }>(),
    },
  ),

  trafficScenarioDetailDialog: createDialogBaseActions(
    basename,
    'TrafficScenarioDetail',
    {
      openProps: props<{
        data: Omit<TrafficScenarioDetailDialogData, 'scenario'> & {
          scenarioUuid: string;
        };
      }>(),
    },
  ),

  deleteTrafficScenarioDialog: createDialogBaseActions(
    basename,
    'DeleteTrafficScenario',
    {
      openProps: props<{
        data: {
          scenarioUuid: string;
        };
      }>(),
    },
  ),

  editLiveWidgetDialog: createDialogBaseActions(basename, 'EditLiveWidget', {
    openProps: props<{ data: EditLiveWidgetDialogData }>(),
    closedProps: props<{ data: LiveWidgetDTO }>(),
  }),

  newLiveWidgetDialog: createDialogBaseActions(basename, 'NewLiveWidget', {
    openProps: props<{
      data: {
        scenario?: TrafficScenarioDTO;
        source: 'scenario-definition' | 'page-add-button';
      };
    }>(),
  }),

  deleteLiveWidgetDialog: createDialogBaseActions(
    basename,
    'DeleteLiveWidget',
    { openProps: props<{ data: { uuid: string; name: string } }>() },
  ),

  deleteLoginProviderDialog: createDialogBaseActions(
    basename,
    'DeleteAuthProviderDialog',
    { openProps: props<{ data: { uuid: string; type: AuthProvider } }>() },
  ),

  editPasswordDialog: createDialogBaseActions(
    basename,
    'EditPasswordComponent',
    { openProps: props<{ data: { uuid: string; email: string } }>() },
  ),

  recurrentReport: createDialogBaseActions(
    basename,
    'RecurrentReportsComponent',
    {
      openProps: props<{
        data: { type: 'download' | 'detail' | 'share'; uuid: string };
      }>(),
    },
  ),

  deleteReport: createDialogBaseActions(basename, 'DeleteReportComponent', {
    openProps: props<{ data: ReportStateModel }>(),
    closedProps: props<{ data: { uuid: string } }>(),
  }),

  reportDetail: createDialogBaseActions(
    basename,
    'TrafficReportDetailComponent',
    {
      openProps: props<{
        data: { uuid: string };
      }>(),
    },
  ),

  newReportDialog: createDialogBaseActions(basename, 'NewReportDialog', {
    openProps: props(),
  }),
};
