import type { ActionCreator } from '@ngrx/store';
import { createAction, props } from '@ngrx/store';
import type { TypedAction } from '@ngrx/store/src/models';
import type {
  ApiErrorResponse,
  MapSettings,
  LayoutView,
  SortBy,
  SortDirection,
} from '@gv/api';

export interface Sortable {
  readonly sortBy: SortBy;
  readonly sortDirection: SortDirection;
  readonly layoutView: LayoutView | undefined;
  readonly mapSettings: MapSettings;
}

export type IncludeField<Name extends string, Type> = [Type] extends [undefined]
  ? { [K in Name]?: Type }
  : { [K in Name]: Type };

export type BaseSubStateActions<
  ApiModel,
  StateModel,
  FetchType = undefined,
  CompletedType = undefined,
> = {
  refresh: ActionCreator<
    string,
    (props: {
      dtSent?: Date;
      debounce?: boolean;
    }) => TypedAction<string> & { dtSent?: Date; debounce?: boolean }
  >;

  markAsDirty: ActionCreator<string, () => TypedAction<string>>;

  fetch: {
    init: ActionCreator<
      string,
      (
        props: {
          skipWhenLoaded: boolean;
          debounce?: boolean;
        } & IncludeField<'custom', FetchType>,
      ) => {
        skipWhenLoaded: boolean;
        debounce?: boolean;
      } & IncludeField<'custom', FetchType> &
        TypedAction<string>
    >;
    started: ActionCreator<string, () => TypedAction<string>>;
    cancel: ActionCreator<string, () => TypedAction<string>>;
    completed: ActionCreator<
      string,
      (
        props: {
          data: ApiModel;
          sortable: Sortable | undefined;
          dtSent: Date;
        } & IncludeField<'custom', CompletedType>,
      ) => {
        data: ApiModel;
        dtSent: Date;
        sortable: Sortable | undefined;
      } & IncludeField<'custom', CompletedType> &
        TypedAction<string>
    >;
    error: ActionCreator<
      string,
      (props: {
        error: ApiErrorResponse<any> | any;
      }) => { error: ApiErrorResponse<any> | any } & TypedAction<string>
    >;
  };

  watching: {
    acquire: ActionCreator<string, () => TypedAction<string>>;
    release: ActionCreator<string, () => TypedAction<string>>;
  };

  hydrate: ActionCreator<
    string,
    (props: {
      data: StateModel;
      dtSent: Date;
      sortable: Sortable | undefined;
    }) => {
      data: StateModel;
      dtSent: Date;
      sortable: Sortable | undefined;
    } & TypedAction<string>
  >;

  highlight: ActionCreator<
    string,
    (props: { highlight: string; dtSent: Date }) => {
      highlight: string;
      dtSent: Date;
    } & TypedAction<string>
  >;
};

export function createSubStateActions<
  ApiModel,
  StateModel,
  FetchType = undefined,
  CompletedType = undefined,
>(
  basename: string,
): BaseSubStateActions<ApiModel, StateModel, FetchType, CompletedType> {
  return {
    refresh: createAction(`${basename} refresh`, props<{ dtSent?: Date }>()),

    markAsDirty: createAction(`${basename} mark as dirty`),

    fetch: {
      // we ignore custom fields, as props() are use for proper typings only, so we can recast it safely
      init: createAction(
        `${basename} fetch init`,
        props<{ skipWhenLoaded: boolean }>(),
      ) as unknown as BaseSubStateActions<
        ApiModel,
        StateModel,
        FetchType,
        CompletedType
      >['fetch']['init'],
      started: createAction(`${basename} fetch started`),
      cancel: createAction(`${basename} fetch cancel`),
      // we ignore custom fields, as props() are use for proper typings only, so we can recast it safely
      completed: createAction(
        `${basename} fetch completed`,
        props<{
          data: ApiModel;
          dtSent: Date;
          sortable: Sortable | undefined;
        }>(),
      ) as unknown as BaseSubStateActions<
        ApiModel,
        StateModel,
        FetchType,
        CompletedType
      >['fetch']['completed'],
      error: createAction(
        `${basename} fetch error`,
        props<{ error: ApiErrorResponse<any> | any }>(),
      ),
    },

    watching: {
      acquire: createAction(`${basename} acquire`),
      release: createAction(`${basename} release`),
    },

    hydrate: createAction(
      `${basename} hydrate`,
      props<{
        data: StateModel;
        dtSent: Date;
        sortable: Sortable | undefined;
      }>(),
    ),

    highlight: createAction(
      `${basename} highlight`,
      props<{
        highlight: string;
        dtSent: Date;
      }>(),
    ),
  };
}
