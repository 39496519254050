export const uploadConfig = {
  maxFilesForMultiUpload: 5000,
  maxInProgressUploads: 5000,
  maxChunksAtOnce: 4,
  maxWidth: 7680,
  maxHeight: 7680,
  minWidth: 320,
  minHeight: 240,
  minDuration: 60 * 1000, // in ms
  maxDuration: 9408 * 60 * 60 * 1000, // in ms
  minFileSize: 1,
  maxFileSize: 150 * 1024 * 1024 * 1024,
  minFrameCount: 500,
  multipartUploadThrottleTimePerChunk: 500, // in ms
  // in bytes, must be at least 5 MB and not more than 5 GB.
  // @see https://docs.aws.amazon.com/AmazonS3/latest/dev/qfacts.html
  preferredChunkSize: 1024 * 1024 * 5,
  retry: {
    limit: 80,
    waitTime: 8000,
  },
  apiRetry: {
    maxWaitTime: 10 * 1000, // in ms
    waitTime: 1000, // in ms
    limit: 3,
  },
  speedEstimator: {
    bufferSampleTime: 1000, // in ms
    averageNLastSpeedEstimates: 3,
    maxAge: 30 * 1000, // in ms
    minEstimationInterval: 8 * 1000, // in ms
    remainingSizeThrottleTime: 1000, // in ms
  },
  uploadRestart: {
    offlineDelay: 20000, // in ms
    networkStuckDetectionThreshold: 40000, // in ms
  },
  uploadManager: {
    recentlyUploadedDelay: 4000, // in ms
  },
  vault: {
    cleanupDelay: 8000, // in ms
    userRetryDelay: 4000, // in ms,
  },
  supportedFileTypes: ['video/*'],
  persistance: {
    softUpdateDebounceInterval: 30 * 1000, // in ms
  },
} as const;
