const dataSourceDialogs = () =>
  import('../feature/data-source/data-source-dialogs.module');

const videoDialogs = () =>
  import('../feature/data-source/video/video-dialogs.module');

const exportDialogs = () => import('../feature/exports/exports-pane.module');

const projectDialogs = () =>
  import('../feature/workspace/projects/project-dialogs.module');

const locationDialogs = () =>
  import('../feature/workspace/location/location-dialog.module');

const dashboardDialogs = () =>
  import('../feature/workspace/dashboard/dashboard-dialog.module');

const dataSourceTutorialDialogs = () =>
  import('../feature/data-source/tutorial/data-source-tutorial.module');

const locationsTutorialDialogs = () =>
  import('../feature/data-source/tutorial/locations-tutorial.module');

const projectsTutorialDialogs = () =>
  import('../feature/data-source/tutorial/projects-tutorial.module');

const dataSourceItem = () =>
  import('../feature/data-source/data-sources.module');

const locationItem = () =>
  import('../feature/workspace/location/locations-page.module');

// const projectItem = () =>
//   import('../feature/workspace/projects/projects.module');

const widgetDialog = () => import('../feature/widget/widget-dialogs.module');

const userDialog = () => import('../feature/user/user-dialogs.module');

export const lazyComponents = {
  exportDialogs,
  exportDialogsModule: () => exportDialogs().then((m) => m.ExportsPaneModule),

  videoDialogs,
  videoDialogsModule: () => videoDialogs().then((m) => m.VideoDialogsModule),

  dataSourceDialogs,
  dataSourceDialogsModule: () =>
    dataSourceDialogs().then((m) => m.DataSourceDialogsModule),

  projectDialogs,
  projectDialogsModule: () =>
    projectDialogs().then((m) => m.ProjectDialogsModule),

  locationDialogs,
  locationDialogsModule: () =>
    locationDialogs().then((m) => m.LocationDialogModule),

  dashboardDialogs,
  dashboardDialogsModule: () =>
    dashboardDialogs().then((m) => m.DashboardDialogModule),

  dataSourceTutorialDialogs,
  dataSourceTutorialDialogsModule: () =>
    dataSourceTutorialDialogs().then((m) => m.DataSourceTutorialModule),

  locationsTutorialDialogs,
  locationsTutorialDialogsModule: () =>
    locationsTutorialDialogs().then((m) => m.LocationsTutorialModule),

  projectsTutorialDialogs,
  projectsTutorialDialogsModule: () =>
    projectsTutorialDialogs().then((m) => m.ProjectsTutorialModule),

  dataSourceItem,
  dataSourceItemModule: () => dataSourceItem().then((m) => m.DataSourcesModule),

  locationItem,
  locationItemModule: () => locationItem().then((m) => m.LocationsModule),

  // projectItem,
  // projectItemModule: () => projectItem().then((m) => m.ProjectsModule),

  widgetDialog,
  widgetDialogModule: () => widgetDialog().then((m) => m.WidgetDialogsModule),

  userDialog,
  userDialogModule: () => userDialog().then((m) => m.UserDialogsModule),
};

export function lazyComponentFactory(): { [key: string]: string } {
  const result = {};

  for (const w of Object.keys(lazyComponents)) {
    result[w] = lazyComponents[w];
  }

  return result;
}
