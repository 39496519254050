import { InjectionToken } from '@angular/core';

import type { BasePermissionService } from '../service/permission-service.interface';

export const BASE_PERMISSION_SERVICE: InjectionToken<BasePermissionService> =
  new InjectionToken<BasePermissionService>('basePermissionService');

export const PERMISSION_SERVICE: InjectionToken<BasePermissionService> =
  new InjectionToken<BasePermissionService>('permissionService');

export const STATE_PROJECT_PERMISSION_SERVICE: InjectionToken<BasePermissionService> =
  new InjectionToken<BasePermissionService>('stateProjectPermissionService');
