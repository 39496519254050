import type { Injector, Type, NgModuleRef } from '@angular/core';
import { createNgModule } from '@angular/core';
import type { ComponentType } from '@angular/cdk/portal';

import type { Observable } from 'rxjs';
import { defer, from, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { forkJoinConcurrent } from '../rxjs';

export function loadModule<T>(
  m: () => Promise<T>,
  ctx: { injector?: Injector },
): Observable<NgModuleRef<T>> {
  return defer(() => from(m())).pipe(
    map((t: unknown) => {
      return createNgModule(t as Type<T>, ctx.injector);
    }),
  );
}

export function loadComponent<M, T>(
  m: (() => Promise<M>) | undefined,
  componentOrTemplateRef: () => Promise<ComponentType<T>>,
  injector: Injector,
): Observable<{
  component: ComponentType<T>;
  module: NgModuleRef<M> | undefined;
}> {
  return forkJoinConcurrent(
    [
      m ? from(loadModule(m, { injector })) : of(undefined),
      from(componentOrTemplateRef()),
    ] as const,
    1,
  ).pipe(
    map(
      ([module, component]: readonly [
        NgModuleRef<M> | undefined,
        ComponentType<T>,
      ]) => {
        return { component, module };
      },
    ),
  );
}
