import type { OnDestroy } from '@angular/core';
import { inject, DestroyRef, Injectable } from '@angular/core';

import type { SupportedLanguage, I18nResourcesModel } from '@gv/api';
import { API } from '@gv/api';
import { SimpleState } from '@gv/state';
import type { TranslationLoaderService as ITranslationLoaderService } from '@gv/utils';
import { retryOnNetworkError, untilNgDestroyed } from '@gv/utils';
import { produce } from 'immer';
import type { Observable, ObservedValueOf } from 'rxjs';
import {
  mergeMap,
  Subject,
  map,
  firstValueFrom,
  combineLatest,
  filter,
  tap,
} from 'rxjs';

@Injectable()
export class TranslationLoaderService
  implements ITranslationLoaderService, OnDestroy
{
  private api = inject(API);
  private destroyRef = inject(DestroyRef);

  private state = new SimpleState(
    { data: {} } as {
      data: Record<
        SupportedLanguage,
        Partial<{
          data: Record<string, string> | undefined;
          loading: boolean;
        }>
      >;
    },
    {
      load: (state, lang: SupportedLanguage) =>
        produce(state, (draft) => {
          if (!draft.data[lang]) {
            draft.data[lang] = {
              data: undefined,
              loading: true,
            };
          }
          draft.data[lang].loading = true;
        }),
      loaded: (
        state,
        data: { lang: SupportedLanguage; data: Record<string, string> },
      ) =>
        produce(state, (draft) => {
          draft.data[data.lang].loading = true;
          draft.data[data.lang].data = data.data;
        }),
    },
    {
      load: (_state, val, oldState) => {
        if (oldState?.data[val]?.loading) {
          return;
        }

        this.loadTrigger.next(val);
      },
    },
  );

  private loadTrigger = new Subject<SupportedLanguage>();

  private load$ = this.loadTrigger.pipe(
    mergeMap((locale: SupportedLanguage) =>
      this.api.getTranslationKeys({ headers: { 'x-language': locale } }).pipe(
        retryOnNetworkError(500, 10000),
        tap((response) =>
          this.state.dispatch({
            type: 'loaded',
            data: {
              lang: locale,
              data: response.data!.i18nResources as Record<string, string>,
            },
          }),
        ),
      ),
    ),
    untilNgDestroyed(),
  );

  private stateSnapshot: ObservedValueOf<typeof this.state.state$>;

  constructor() {
    this.state.init(this.destroyRef);
    this.state.state$.subscribe((s) => (this.stateSnapshot = s));
    this.load$.subscribe();
  }

  register(locale: Observable<SupportedLanguage>): Promise<void> {
    locale.pipe(untilNgDestroyed(this.destroyRef)).subscribe((l) => {
      if (l) {
        this.state.dispatch({ type: 'load', data: l });
      }
    });

    return firstValueFrom(
      combineLatest([locale, this.state.state$]).pipe(
        filter(([l, s]) => Boolean(s.data[l]?.data)),
        map(() => undefined),
      ),
    );
  }

  getData(
    locale: SupportedLanguage,
  ): I18nResourcesModel & Record<string, string> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.stateSnapshot.data[locale].data as any;
  }

  ngOnDestroy(): void {
    //
  }
}
