import { Injectable, inject } from '@angular/core';

import type { Observable } from 'rxjs';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from '../../application/user/user.service';
import { BrowserTypeService } from '../../helper/browser-type.service';

@Injectable({
  providedIn: 'root',
})
export class UnsupportedBrowserSnackBarService {
  private browserType = inject(BrowserTypeService);
  private userService = inject(UserService);
  private visibleSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(!this.browserType.isSupported);

  readonly visible$: Observable<boolean> = combineLatest([
    this.visibleSubject,
    this.userService.isLoggedIn$,
  ]).pipe(map(([visible, loggedIn]) => visible && loggedIn));

  private heightSubject: BehaviorSubject<number> = new BehaviorSubject<number>(
    0,
  );

  set height(height: number) {
    if (this.height !== height) {
      this.heightSubject.next(height);
    }
  }

  readonly height$: Observable<number> = combineLatest([
    this.heightSubject,
    this.visible$,
  ]).pipe(map(([height, visible]) => (visible ? height : undefined)));

  hide(): void {
    if (this.visibleSubject.getValue()) {
      this.visibleSubject.next(false);
    }
  }
}
