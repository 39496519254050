import { createSubStateReducerFns } from '@gv/state';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type { Draft } from 'immer';

import { credit } from './actions';
import type { CreditState } from './state';
import { initialCreditState } from './state';
import * as CreditActions from './actions';

const reducer = createReducer(
  initialCreditState,

  ...createSubStateReducerFns(
    credit,
    (state: Draft<CreditState>) => state.balance,
    (_, data, dtSent) => {
      return { ...data, dtSent };
    },
    $localize`:@@reducers.failed-to-load-credit-balance:Failed to load credit balance`,
  ),

  on(
    CreditActions.updateBalance,
    (state, action): CreditState => ({
      ...state,
      balance: {
        ...state.balance,
        data: {
          ...state.balance.data,
          balance: action.data,
        },
      },
    }),
  ),
);

export function creditReducer(
  state: CreditState | undefined,
  action: Action,
): CreditState {
  return reducer(state, action);
}
