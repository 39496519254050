import type { OnDestroy, OnInit } from '@angular/core';
import { DestroyRef, Injectable, inject } from '@angular/core';

import { StoreInject } from '@gv/state';
import { ConnectionStatusCheckerService } from '@gv/ui/utils';
import type { UploadedBytesReportModel } from '@gv/upload/types';
import {
  VideoUploadSpeedEstimatorStatus,
  uploadConfig,
} from '@gv/upload/types';
import { isUndefinedOrNull, untilNgDestroyed } from '@gv/utils';
import { select } from '@ngrx/store';
import type { Subscription } from 'rxjs';
import { combineLatest, of, Subject } from 'rxjs';
import {
  bufferCount,
  bufferTime,
  distinctUntilChanged,
  filter,
  map,
  scan,
  shareReplay,
  skipWhile,
  startWith,
  switchMap,
  throttleTime,
} from 'rxjs/operators';

import { uploadsFeatureKey } from '../reducers/uploads.reducer';
import { fromUploadsState } from '../selectors';
import { UPLOADS_FEATURE_STATE } from '../uploads-feature.state';

@Injectable({
  providedIn: 'root',
})
export class VideoUploadSpeedEstimatorService implements OnDestroy, OnInit {
  private destroyRef = inject(DestroyRef);
  private store = inject(StoreInject(UPLOADS_FEATURE_STATE));
  private connectionStatusChecker = inject(ConnectionStatusCheckerService);
  private speedEstimationSubject = new Subject<UploadedBytesReportModel>();

  private speedEstimatorConfig = uploadConfig.speedEstimator;

  private $isUploading = this.store.pipe(
    skipWhile((state) => {
      return !state || !state[uploadsFeatureKey];
    }),
    select(fromUploadsState.vault.isAnyUploading),
    distinctUntilChanged(),
  );

  private speedReports$ = combineLatest([
    this.$isUploading,
    this.connectionStatusChecker.connectionStatus$,
  ]).pipe(
    switchMap(([isUploading, isOnline]) => {
      if (!isUploading || !isOnline) {
        return of(undefined).pipe(shareReplay(1));
      }

      return this.speedEstimationSubject.pipe(
        bufferTime(this.speedEstimatorConfig.bufferSampleTime),
        filter((v) => v.length > 0),
        scan<UploadedBytesReportModel[], UploadedBytesReportModel[]>(
          (acc, values) => {
            const newValues = [...acc, ...(values || [])];

            const currentTime = new Date().getTime();

            // remove reports older than maxAge
            const index = newValues.findIndex(
              (v) => v.endTime > currentTime - this.speedEstimatorConfig.maxAge,
            );
            if (index >= 1) {
              newValues.splice(0, index);
            }

            return newValues;
          },
          [],
        ),
        untilNgDestroyed(this.destroyRef),
        shareReplay(this.speedEstimatorConfig.averageNLastSpeedEstimates),
      );
    }),
  );

  private speed$ = this.speedReports$.pipe(
    map((values) => {
      if (values && values.length > 2) {
        const minTime = values[0].startTime;
        const maxTime = values[values.length - 1].endTime;

        if (
          maxTime - minTime >
          this.speedEstimatorConfig.minEstimationInterval
        ) {
          const loaded = values.reduce((acc, v) => acc + v.uploaded, 0);
          return loaded / ((maxTime - minTime) / 1000);
        }
      }
      return undefined;
    }),
    bufferCount(this.speedEstimatorConfig.averageNLastSpeedEstimates, 1),
    map(
      (values) =>
        values &&
        values.length > 1 &&
        !values.some((v) => isUndefinedOrNull(v)) &&
        values.reduce((acc, v) => acc + v, 0) / values.length,
    ),
    untilNgDestroyed(),
    shareReplay(1),
  );

  private $remainingSize = this.store.pipe(
    skipWhile((state) => {
      return !state || !state[uploadsFeatureKey];
    }),
    throttleTime(this.speedEstimatorConfig.remainingSizeThrottleTime),
    select(fromUploadsState.vault.getRemainingSize),
  );

  /**
   * Emits estimated remaining time in seconds.
   */
  remainingTime$ = combineLatest([this.speed$, this.$remainingSize]).pipe(
    filter(([speed, size]) => !!size && !!speed),
    map(([speed, size]) => size / speed),
  );

  status$ = combineLatest([
    this.$isUploading,
    this.connectionStatusChecker.connectionStatus$,
  ]).pipe(
    switchMap(([isUploading, isOnline]) => {
      if (!isUploading) {
        return of(VideoUploadSpeedEstimatorStatus.PAUSED);
      }

      if (!isOnline) {
        return of(VideoUploadSpeedEstimatorStatus.OFFLINE);
      }

      return this.speed$.pipe(
        startWith(undefined),
        map((speed) => {
          if (!speed) {
            return VideoUploadSpeedEstimatorStatus.ESTIMATING;
          }
          return VideoUploadSpeedEstimatorStatus.READY;
        }),
      );
    }),
    distinctUntilChanged(),
    untilNgDestroyed(),
    shareReplay(1),
  );

  private speedReportSubscription: Subscription | undefined;

  ngOnInit(): void {
    this.speedReportSubscription = this.speedReports$.subscribe();
  }

  reportUploadedBytes(uploaded: UploadedBytesReportModel): void {
    this.speedEstimationSubject.next(uploaded);
  }

  ngOnDestroy(): void {
    this.unsubscribeSpeedReportSubscription();
  }

  private unsubscribeSpeedReportSubscription(): void {
    if (this.speedReportSubscription) {
      this.speedReportSubscription.unsubscribe();
      this.speedReportSubscription = undefined;
    }
  }
}
