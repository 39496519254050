import type { Observable } from 'rxjs';
import type { ApiResponse } from '@gv/api';
import type { RawAxiosRequestConfig } from 'axios';

import type { RequestConfig } from './http';

export interface RequestInterceptor {
  /**
   * Identifies and handles a given HTTP request.
   * @param req The outgoing request object to handle.
   * @param next The next interceptor in the chain, or the backend
   * if no interceptors remain in the chain.
   * @returns An observable of the event stream.
   */
  intercept(
    req: RawAxiosRequestConfig<any>,
    next: RequestHandler,
  ): Observable<ApiResponse<any>>;
}

export abstract class RequestHandler {
  abstract handle(req: RequestConfig<any>): Observable<ApiResponse<any>>;
}

export class InterceptorRequestHandler extends RequestHandler {
  constructor(
    private next: RequestHandler,
    private interceptor: RequestInterceptor,
  ) {
    super();
  }

  handle(req: RequestConfig<any>): Observable<ApiResponse<any>> {
    return this.interceptor.intercept(req, this.next);
  }
}
