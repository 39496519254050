import { inject } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import type { Action } from '@ngrx/store';
import type { Observable } from 'rxjs';

import type { OptionsType } from './create-flow-action-observable';
import { createFlowActionObservable } from './create-flow-action-observable';
import type {
  DataProps,
  FlowInitActionType,
  TypedFlowBaseActions,
} from './create-flow-base-actions';

type FlowEffectFn<A, CompletedData> = (v: {
  action: A;
}) => Observable<Action | CompletedData>;

export function createFlowEffect<
  InitProps,
  CompletedData,
  PropagateInitProps extends boolean,
  IPInfered extends InitProps,
  CPInfered extends CompletedData,
>(
  fn: FlowEffectFn<
    ReturnType<FlowInitActionType<DataProps<IPInfered>>>,
    CPInfered
  >,
  actions: TypedFlowBaseActions<InitProps, CompletedData, PropagateInitProps>,
  options?: Partial<OptionsType>,
): Observable<Action> {
  const actionsObs =
    options?.actions$ || (inject(Actions) as Observable<Action>);
  const actions$: Observable<
    ReturnType<FlowInitActionType<DataProps<IPInfered>>>
  > = actionsObs.pipe(ofType(actions.init));

  return createEffect(() =>
    actions$.pipe(
      createFlowActionObservable(fn, actions, {
        ...(options || {}),
        actions$: actionsObs,
      }),
    ),
  );
}
