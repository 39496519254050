import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { TestIdModule } from '@gv/debug';

@Component({
  selector: 'gv-dialog-header',
  standalone: true,
  imports: [
    DragDropModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    TestIdModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      #drag
      class="mt-6 w-full cursor-move"
      cdkDrag
      [cdkDragDisabled]="disableDrag"
      cdkDragRootElement=".cdk-overlay-pane"
      cdkDragBoundary=".cdk-overlay-container"
      cdkDragHandle
      gvGroupId
      gvId="dialog-header"
    >
      <div mat-dialog-title class="flex items-center justify-between space-x-3">
        <div
          class="header-content min-w-0 overflow-hidden truncate font-bold uppercase"
        >
          <ng-content></ng-content>
        </div>
        <div class="flex flex-none items-center">
          <ng-content select="[buttons]"></ng-content>
          @if (!hideButton) {
            <button
              type="button"
              mat-icon-button
              disableRipple
              tabindex="-1"
              [disabled]="disableButton"
              (click)="closeDialog($event)"
              ><mat-icon>close</mat-icon></button
            >
          }
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      :host ::ng-deep {
        .mat-mdc-dialog-title {
          margin-bottom: 0;
          padding-bottom: 0;

          /* div {
            @apply overflow-hidden truncate text-ellipsis;
          } */
        }
        .mdc-dialog__title::before {
          display: none !important;
        }
      }
    `,
  ],
})
export class DialogHeaderComponent {
  private dialogRef = inject(MatDialogRef);

  @Input()
  hideButton = false;

  @Input()
  disableDrag = false;

  @Input()
  disableClose = false;

  @Input()
  disableButton = false;

  @Output()
  close = new EventEmitter();

  closeDialog(event: Event): void {
    if (this.disableClose) {
      event.stopPropagation();
      event.preventDefault();
    } else {
      this.dialogRef.close();
    }

    this.close.emit();
  }
}
