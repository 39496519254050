export enum Permission {
  Stream,
  Drone,
  // OGV,
  ALPR,
  Timelapse,
  NoFunds,

  StreamAnpr,
  StreamTraffic,

  TMCEdited,
  TMCExport,
  TMCMulti,
  VehicleListExport,
  SatFlowExport,
  ODMatrix,
  Vissim,
  AnprExport,
  PeakHourExport,
  EventImages,
  ScenarioImages,
  ExportLive,

  PeakPeriodMaxSingle,
  PeakPeriodMaxPie,
  AverageVolumeSingle,
  AverageVolumePie,
  ObjectDistributionLine,
  ObjectDistributionBar,
  ObjectDistributionHeatmap,
  TotalVolumeSingle,
  TotalVolumePie,
  TmcFlowDiagram,
  MeanTravelTimeSingle,
  MeanTravelTimeBar,
  MeanTimeGapSingle,
  MeanTimeGapBar,
  VehicleListTable,
  TrajectoryScene,
  MotionHeatmapScene,
  AccelerationHeatmapScene,
  HoldupHeatmapScene,
  PeakPeriods,
  MeanOccupancyTimeInZoneSingle,
  MeanOccupancyTimeInZonePie,
  EventIntrusionInstant,
  EventIntrusionPeriod,
  EventAverageSpeedInstant,
  EventAverageSpeedPeriod,
  EventAverageOccupancyPeriod,
  EventAverageHeadwayPeriod,
  EventViolationInstant,

  DashboardGlobal,
  DashboardLive,
  DashboardLocal,
  DashboardVisibilityPrivate,
  DashboardVisibilityPublic,

  Vault,
  VaultPlayback,
  Sync,
  Connect,
  Edge,
  Desktop,
  Platform,

  ODFilterGeneration,
  StayEvent,
  StoppedEvent,
  Movement,
  Scenario,
  Metric,
  DataSourceSharing,
  FilterDirectionalCross,
  FilterUTurn,
  GraphicalObjectCardinalDirection,
  GraphicalObjectLock,

  Project,
  Location,
  Dashboard,
  Widget,
  LiveEvent,
  Export,
  DataSource,
  Processing,

  PaymentCredits,
  PaymentAll,
  PaymentBankTransfer,
  PaymentInvoice,

  Organization,
  OrganizationSettings,
  OrganizationBilling,
  OrganizationProjectManagement,
  OrganizationReferrals,

  ServiceGDrive,
  ServiceDropbox,
  ServiceOneDrive,

  Admin,
  SuperAdmin,
  Support,

  StreamLivePreview,
  ScenarioIllegalStop,
  ScenarioLicensePlateDetection,
  ScenarioDirectionalCrossDetection,
  ScenarioMultiLineCrossDetection,
  ScenarioAvgSpeedMeasurement,
  ScenarioSpeedLimitViolation,
  ScenarioWrongWayDriving,
  ScenarioAvgTimeGapMeasurement,
  ScenarioLpIllegalParking,
  ScenarioIllegalParking,
  StreamC0,
  StreamTrafficANPR,
  ScenarioC0CrossroadZoneDetection,
  ScenarioAID,
  ScenarioAitlcm,
  ScenarioNodeRed,

  CustomDateTime,

  NotSupported,

  DeprecatedGVLTDescription,
}
