import type { OnDestroy } from '@angular/core';
import { Injectable, inject } from '@angular/core';

import { appFullVersion } from '@gv/constant';
import { APP_INSTANCE_ID } from '@gv/ui/core';
import { BroadcastChannel } from 'broadcast-channel';
import { produce } from 'immer';
import type { Observable, Subscription } from 'rxjs';
import { BehaviorSubject, EMPTY, of, Subject } from 'rxjs';
import { mergeMap, pairwise } from 'rxjs/operators';
import { LocalizationUtils } from '@gv/utils';

import { logger } from '../logger';
import type { CrossTabConnectedMessageModel } from './entity/cross-tab-connected-message-model';
import type { CrossTabDisconnectedMessageModel } from './entity/cross-tab-disconnected-message-model';
import type { CrossTabMessageModel } from './entity/cross-tab-message-model';
import { CrossTabMessageType } from './entity/cross-tab-messsage-type.enum';
import { AppInstanceService } from './app-instance.service';

@Injectable({
  providedIn: 'root',
})
export class CrossTabChannelService implements OnDestroy {
  private appInstanceId = inject(APP_INSTANCE_ID);
  private appInstanceService = inject(AppInstanceService);
  private static readonly broadcastChannelName: string =
    '__gv_cross-tab_channel_channel';

  readonly tabLanguage = LocalizationUtils.getCurrentUrlLanguage();

  readonly tabAppVersion =
    appFullVersion === 'GV_FULL_VERSION' ? undefined : appFullVersion;

  private readonly activeInstancesSubject = new BehaviorSubject<
    { instanceId: string; lang: string; appVersion: string }[]
  >([
    {
      instanceId: this.appInstanceId,
      lang: this.tabLanguage,
      appVersion: this.tabAppVersion,
    },
  ]);

  get activeInstances(): {
    instanceId: string;
    lang: string;
    appVersion: string;
  }[] {
    return this.activeInstancesSubject.getValue();
  }

  activeInstances$ = this.activeInstancesSubject.asObservable();

  instanceDisconnected$: Observable<{
    instanceId: string;
    lang: string;
    appVersion: string;
  }> = this.activeInstances$.pipe(
    pairwise(),
    mergeMap(([oldInstances, newInstances]) => {
      const disconnectedInstances = (oldInstances || []).filter(
        (appInstanceId) => !newInstances.includes(appInstanceId),
      );

      if (disconnectedInstances.length === 0) {
        return EMPTY;
      }

      return of(...disconnectedInstances);
    }),
  );

  instanceConnected$: Observable<{
    instanceId: string;
    lang: string;
    appVersion: string;
  }> = this.activeInstances$.pipe(
    pairwise(),
    mergeMap(([oldInstances, newInstances]) => {
      const connectedInstances = (newInstances || []).filter(
        (appInstanceId) => !oldInstances.includes(appInstanceId),
      );

      if (connectedInstances.length === 0) {
        return EMPTY;
      }

      return of(...connectedInstances);
    }),
  );

  private broadcastChannel: BroadcastChannel<CrossTabMessageModel<any, string>>;

  private messageReceivedSubject = new Subject<
    CrossTabMessageModel<any, string>
  >();

  messageReceived$ = this.messageReceivedSubject.asObservable();

  private broadcastChannelMessageHandler = (
    message: CrossTabMessageModel<any, string>,
  ) => {
    if (message.instanceId === this.appInstanceId) {
      return;
    }

    if (this.isDisconnectMessage(message)) {
      return this.deleteAppInstance(message.instanceId);
    }

    if (this.isConnectedMessage(message)) {
      if (!message.isReply) {
        this.replyConnected(message);
      }

      return this.addAppInstance(message.instanceId);
    }

    if (message.recipient && this.appInstanceId !== message.recipient) {
      // this message is not for us; Ignore it
      return;
    }

    logger.debug({ message }, 'CrossTabChannel::messageReceived');
    this.messageReceivedSubject.next(message);
  };

  private pendingUnloadedInstancesSubscription: Subscription | undefined;

  ngOnDestroy(): void {
    void this.destroyBroadcastChannel();
  }

  purge(): void {
    // check for recently unloaded instances and remove them
    // This should do nothing as we should be notified by storage event (But just to be sure...)
    const unloadedInstances =
      this.appInstanceService.getPendingUnloadedInstances();

    if (unloadedInstances) {
      unloadedInstances.forEach((unloadedInstance) => {
        this.deleteAppInstance(unloadedInstance.instanceId);
        this.broadcastDisconnect(unloadedInstance.instanceId);
      });
    }
  }

  async enable(): Promise<void> {
    await this.destroyBroadcastChannel();

    this.createBroadcastChannel();

    this.createPendingUnloadedInstancesSubscription();

    this.purge();

    this.broadcastConnected();
  }

  private createBroadcastChannel(): void {
    this.broadcastChannel = new BroadcastChannel<
      CrossTabMessageModel<any, string>
    >(CrossTabChannelService.broadcastChannelName, {
      webWorkerSupport: false,
    });

    this.broadcastChannel.onmessage = this.broadcastChannelMessageHandler;
  }

  private createPendingUnloadedInstancesSubscription(): void {
    this.destroyPendingUnloadedInstancesSubscription();

    this.pendingUnloadedInstancesSubscription =
      this.appInstanceService.pendingUnloadedInstances$.subscribe(
        (pendingUnloadedInstances) => {
          if (!pendingUnloadedInstances) {
            return;
          }

          for (const unloadedInstance of pendingUnloadedInstances) {
            this.deleteAppInstance(unloadedInstance.instanceId);
          }
        },
      );
  }

  broadcastDisconnect(appInstanceId?: string): void {
    const message: CrossTabDisconnectedMessageModel = {
      type: CrossTabMessageType.Disconnected,
      instanceId: appInstanceId || this.appInstanceId,
    };

    this.sendMessage(message);
  }

  sendMessage(message: CrossTabMessageModel<any, string>): void {
    if (!this.broadcastChannel) {
      return;
    }

    void this.broadcastChannel.postMessage(message);
  }

  private broadcastConnected(): void {
    const message: CrossTabConnectedMessageModel = {
      type: CrossTabMessageType.Connected,
      instanceId: this.appInstanceId,
      data: {
        lang: this.tabLanguage,
        version: this.tabAppVersion,
      },
    };

    this.sendMessage(message);
  }

  private replyConnected(m: CrossTabConnectedMessageModel): void {
    const message: CrossTabConnectedMessageModel = {
      type: CrossTabMessageType.Connected,
      instanceId: this.appInstanceId,
      recipient: m.instanceId,
      isReply: true,
      data: {
        lang: this.tabLanguage,
        version: this.tabAppVersion,
      },
    };

    this.sendMessage(message);
  }

  private deleteAppInstance(appInstanceId: string): void {
    const activeInstances = this.activeInstancesSubject.getValue();

    const instance = activeInstances.find(
      (i) => i.instanceId === appInstanceId,
    );
    const index = activeInstances.indexOf(instance);
    if (index < 0) {
      return;
    }

    const newActiveInstances = produce(activeInstances, (draft) => {
      draft.splice(index, 1);
    });

    this.activeInstancesSubject.next(newActiveInstances);
  }

  private addAppInstance(appInstanceId: string): void {
    const activeInstances = this.activeInstancesSubject.getValue();

    if (
      activeInstances.find((instance) => instance.instanceId === appInstanceId)
    ) {
      return;
    }

    this.activeInstancesSubject.next([
      ...activeInstances,
      {
        instanceId: appInstanceId,
        lang: this.tabLanguage,
        appVersion: this.tabAppVersion,
      },
    ]);
  }

  private isDisconnectMessage(
    message: CrossTabMessageModel<any, string>,
  ): message is CrossTabDisconnectedMessageModel {
    return message && message.type === CrossTabMessageType.Disconnected;
  }

  private isConnectedMessage(
    message: CrossTabMessageModel<any, string>,
  ): message is CrossTabConnectedMessageModel {
    return message && message.type === CrossTabMessageType.Connected;
  }

  private destroyPendingUnloadedInstancesSubscription(): void {
    if (this.pendingUnloadedInstancesSubscription) {
      this.pendingUnloadedInstancesSubscription.unsubscribe();
      this.pendingUnloadedInstancesSubscription = undefined;
    }
  }

  private async destroyBroadcastChannel(): Promise<void> {
    if (!this.broadcastChannel) {
      return;
    }

    try {
      await this.broadcastChannel.close();
    } catch (e) {
      logger.error({ error: e }, 'Failed to destroy broadcast channel');
    }

    this.broadcastChannel = undefined;
  }
}
