import { createSelector } from '@ngrx/store';
import type {
  UploadGroupModel,
  VaultUploadGroupModel,
  VaultStateFileModel,
  VaultUploadInfoModel,
} from '@gv/upload/types';
import { UploadType, uploadConfig } from '@gv/upload/types';

import * as fromState from './uploads-from-state.selector';
import type { UploadsFeatureState } from '../uploads-feature.state';
import type { UploadProgress } from '../uploads.state';

const getFile = fromState.getFile;

const getNextGroup = createSelector(fromState.getState, (state) =>
  state.uploadGroupsIds.length
    ? state.uploadGroups[state.uploadGroupsIds[0]]
    : undefined,
);

const getGroups = createSelector(
  fromState.getState,
  (state): readonly UploadGroupModel[] =>
    state.uploadGroupsIds.map((id) => state.uploadGroups[id]),
);

const getProjects = createSelector(getGroups, (state) =>
  state
    .reduce(
      (acc, group) => {
        if (acc.includes(group.projectUuid)) {
          return acc;
        }
        acc.push(group.projectUuid);
        return acc;
      },
      <string[]>[],
    )
    .sort(),
);

const getUploadGroupsForPrefix = (props: { prefix: string }) =>
  createSelector(
    getGroups,
    (groups): readonly UploadGroupModel[] =>
      groups &&
      groups.filter((g) => g.serverData?.directory?.prefix === props.prefix),
  );

const getCanceledGroups = createSelector(
  fromState.getState,
  (state): readonly UploadGroupModel[] =>
    state.canceledGroupsIds.map((id) => state.uploadGroups[id]),
);

const getUploadedGroups = createSelector(
  fromState.getState,
  (state): readonly UploadGroupModel[] =>
    state.finishedGroupsIds.map((id) => state.uploadGroups[id]),
);

const getFailedGroups = createSelector(
  fromState.getState,
  (state): readonly UploadGroupModel[] =>
    state.failedGroupsIds.map((id) => state.uploadGroups[id]),
);

const getFailedGroupsCount = createSelector(
  fromState.getState,
  (state): number => state.failedGroupsIds.length,
);

const isAnyUploading = createSelector(
  fromState.getState,
  (state): boolean => state.uploadGroupsIds.length > 0,
);

const getRemainingUploadCount = createSelector(getGroups, (groups): number =>
  !groups ? 0 : groups.reduce((acc, g) => acc + g.remainingFiles.length, 0),
);

const getFailedUploadCount = createSelector(
  getFailedGroups,
  (groups): number =>
    !groups ? 0 : groups.reduce((acc, g) => acc + g.failed.length, 0),
);

const getRemainingSize = createSelector(
  getGroups,
  fromState._getProgress,
  (
    groups: readonly VaultUploadGroupModel[],
    progress: { [uuid: string]: UploadProgress },
  ): number => {
    return !groups
      ? 0
      : groups.reduce(
          (size, group) =>
            size +
            group.remainingSize -
            group.uploading.reduce(
              (uploaded, uuid) =>
                uploaded +
                (group.files[uuid].data.size *
                  (progress[uuid]?.progress || 0)) /
                  100,
              0,
            ),
          0,
        );
  },
);

const getOngoingUploadsInfo = createSelector(
  getGroups,
  fromState._getProgress,
  (
    groups: readonly VaultUploadGroupModel[],
    progress: { [uuid: string]: UploadProgress },
  ): readonly VaultUploadInfoModel[] =>
    groups?.reduce(
      (acc, group) =>
        acc.concat(
          group.remainingFiles.map((fileUuid): VaultUploadInfoModel => {
            const file = group.files[fileUuid];

            return {
              filename: file.data.file.name,
              progress: progress[fileUuid]?.progress || 0,
              type: UploadType.VAULT,
              uploadId: file.serverData ? file.serverData.uploadId : undefined,
              uuid: file.serverData ? file.serverData.fileUuid : undefined,
              localUploadGroupUuid: group.uuid,
              localUuid: file.uuid,
            };
          }),
        ),
      <VaultUploadInfoModel[]>[],
    ),
);

const getGroupFiles = (props: { uploadGroupUuid: string }) =>
  createSelector(
    fromState.getState,
    (state): readonly VaultStateFileModel[] => {
      const group = state.uploadGroups[props.uploadGroupUuid];

      if (!group) {
        return undefined;
      }

      return group.filesIds.map(
        (fileId) => state.uploadGroups[props.uploadGroupUuid].files[fileId],
      );
    },
  );

const getGroup = (props: { uploadGroupUuid: string }) =>
  createSelector(
    fromState.getState,
    (state): UploadGroupModel => state.uploadGroups[props.uploadGroupUuid],
  );

const getGroupFromUploadId = (props: { uploadId: string }) =>
  createSelector(
    getGroups,
    (groups): UploadGroupModel =>
      groups?.find(
        (g) => g.serverData && g.serverData.uploadId === props.uploadId,
      ),
  );

const getFileFromServerFileUuid = (props: {
  fileUuid: string;
  uploadId: string;
}) =>
  createSelector<UploadsFeatureState, [UploadGroupModel], VaultStateFileModel>(
    getGroupFromUploadId(props),
    (group: VaultUploadGroupModel): VaultStateFileModel => {
      if (!group) {
        return undefined;
      }

      const fileUuid = group.filesIds.find(
        (uuid) => group.files[uuid]?.serverData?.fileUuid === props.fileUuid,
      );

      return fileUuid ? group.files[fileUuid] : undefined;
    },
  );

const getFileFromFileUuid = (props: { fileUuid: string }) =>
  createSelector(
    getGroups,
    (groups: VaultUploadGroupModel[]): VaultStateFileModel => {
      for (const group of groups || []) {
        const file = Object.values(group.files).find(
          (f) => f.serverData?.fileUuid === props.fileUuid,
        );
        if (file) {
          return file;
        }
      }
      return undefined;
    },
  );

const getGroupInProgressUploaded = (
  group: VaultUploadGroupModel,
  progress: { [uuid: string]: UploadProgress },
): number | undefined => {
  if (!group || group.totalSize === 0) {
    return 0;
  }

  return [...group.uploading, ...group.encoding].reduce((uploaded, uuid) => {
    const file = group.files[uuid];
    const size = file.encoded?.file.size || file.data.size;
    if (!progress[uuid]) {
      return uploaded;
    }

    return (
      uploaded +
      size *
        fromState.ratioMapping[progress[uuid].type](
          (progress[uuid]?.progress || 0) / 100,
          file,
        )
    );
  }, 0);
};

const mapProgressForUploadGroup = (
  group: VaultUploadGroupModel,
  progress: { [uuid: string]: UploadProgress },
): number | undefined => {
  if (!group || group.totalSize === 0) {
    return undefined;
  }

  const currentlyUploaded = getGroupInProgressUploaded(group, progress);

  return Math.round(
    (1 - (group.remainingSize - currentlyUploaded) / group.totalSize) * 100,
  );
};

const getGroupProgress = (props: { uploadGroupUuid: string }) =>
  createSelector(
    getGroup(props),
    fromState._getProgress,
    mapProgressForUploadGroup,
  );

const isGroupDirectory = (props: { uploadGroupUuid: string }) =>
  createSelector(
    getGroup(props),
    (group): boolean =>
      !!group && group.type === UploadType.VAULT && group.isDirectory,
  );

const getGroupIfEverythingIsProcessed = createSelector(
  getNextGroup,
  (uploadGroup): UploadGroupModel =>
    uploadGroup?.remainingFiles.length === 0 ? uploadGroup : undefined,
);

const getFileForUploadTrigger = createSelector(
  getNextGroup,
  (group): VaultStateFileModel => {
    if (!group || group.fullMetadata.length === 0) {
      // do not trigger upload when there is already some uploading
      return undefined;
    }

    const remainingParts = group.uploading.reduce(
      (acc, r) => acc + (group.files[r].remainingParts ?? 0),
      0,
    );

    if (remainingParts >= uploadConfig.maxChunksAtOnce) {
      return undefined;
    }

    return group.files[group.fullMetadata[0]];
  },
);

const getFileForFullMetadataLoadTrigger = createSelector(
  getNextGroup,
  (group): VaultStateFileModel => {
    if (!group || group.metadata.length === 0) {
      // do not trigger upload when there is already some uploading
      return undefined;
    }

    return group.files[group.metadata[0]];
  },
);

const shouldWeForceFullMetadataLoadTrigger = createSelector(
  getNextGroup,
  (group): boolean => {
    return (
      !!group && group.fullMetadata.length === 0 && group.uploading.length === 0
    );
  },
);

const shouldWeForceMetadataLoadTrigger = createSelector(
  getNextGroup,
  (group): boolean => {
    return (
      !!group &&
      group.fullMetadata.length === 0 &&
      group.uploading.length === 0 &&
      group.metadata.length === 0
    );
  },
);

const getFileForEncodeTrigger = createSelector(
  getNextGroup,
  (group): VaultStateFileModel => {
    if (!group || group.initialized.length === 0 || group.encoding.length > 0) {
      return undefined;
    }

    return group.files[group.initialized[0]];
  },
);

const getFileForMetadataLoadTrigger = createSelector(
  getNextGroup,
  (group): VaultStateFileModel => {
    if (!group || group.encoded.length === 0) {
      return undefined;
    }

    return group.files[group.encoded[0]];
  },
);

const getGroupsToPersist = createSelector(
  getGroups,
  getFailedGroups,
  (remainingGroups, failedGroups): readonly UploadGroupModel[] => {
    return [...(remainingGroups || []), ...(failedGroups || [])];
  },
);

const getAnyUploadNeedsUserAttention = createSelector(
  isAnyUploading,
  getFailedGroupsCount,
  (isAnyUploadInProgress: boolean, failedGroupsCount: number) =>
    isAnyUploadInProgress || failedGroupsCount > 0,
);

const triggers = {
  getGroupIfEverythingIsProcessed: getGroupIfEverythingIsProcessed,
  getFileForUploadTrigger,
  getFileForMetadataLoadTrigger,
  getFileForEncodeTrigger,
  getFileForFullMetadataLoadTrigger,
  shouldWeForceFullMetadataLoadTrigger,
  shouldWeForceMetadataLoadTrigger,
};

export {
  getCanceledGroups,
  getFailedGroups,
  getFailedGroupsCount,
  getFailedUploadCount,
  getFile,
  getFileFromServerFileUuid,
  getGroup,
  getGroupFiles,
  getGroupProgress,
  getGroups,
  getGroupsToPersist,
  getOngoingUploadsInfo,
  getRemainingSize,
  getRemainingUploadCount,
  getUploadedGroups,
  isAnyUploading,
  isGroupDirectory,
  triggers,
  getUploadGroupsForPrefix,
  getAnyUploadNeedsUserAttention,
  getProjects,
  getFileFromFileUuid,
};
