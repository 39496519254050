import type { CreditBalanceDTO } from '@gv/api';
import { createSubStateActions } from '@gv/state';
import { createAction, props } from '@ngrx/store';

const basename = '[Credit]';

export const credit = createSubStateActions<
  CreditBalanceDTO,
  CreditBalanceDTO & { dtSent: Date }
>(`${basename} balance`);

export const updateBalance = createAction(
  `${basename} update balance`,
  props<{ data: number }>(),
);
