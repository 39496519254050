import { inject, Component, computed } from '@angular/core';
import { NgClass } from '@angular/common';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { toSignal } from '@angular/core/rxjs-interop';

import { hostBinding } from 'ngxtension/host-binding';
import { TestIdModule } from '@gv/debug';
import { distinctUntilChanged, map, merge } from 'rxjs';

import { ToasterService } from '../../service/toaster.service';
import { ToasterComponent } from './toaster.component';
import { ScrollFadeReversedComponent } from '../../directive/scroll-fade-reversed.directive';

@Component({
  selector: 'gv-toaster-container',
  template: `
    <div
      class="__gv-scrollbar flex h-full max-h-[300px] flex-col-reverse items-center"
      gvScrollFadeReversed
    >
      @for (toast of toastsS(); track toast.uuid) {
        <gv-toaster
          [@flyFromBottom]
          [gvId]="toast.uuid"
          class="flex-none overflow-hidden"
          [ngClass]="{
            '[&>*]:border [&>*]:border-black/15 [&>*]:shadow-md':
              someDialogOpenedS()
          }"
          [toast]="toast"
          [index]="$index"
          (close)="service.close(toast.uuid)"
        />
      }
    </div>
  `,
  host: {
    class: `fixed max-h-[300px] min-h-[1px] min-w-[1px] bottom-0 right-[calc(50%_-_250px)] z-[100000]`,
  },
  animations: [
    trigger('flyFromBottom', [
      transition('void => *', [
        style({ transform: 'translateY(100%)', height: 0, opacity: 0 }),
        animate(
          '225ms cubic-bezier(0.4,0.0,0.2,1)',
          style({ transform: 'translateY(0)', height: '*', opacity: 1 }),
        ),
      ]),
      transition('* => void', [
        animate(
          '225ms cubic-bezier(0.4,0.0,0.2,1)',
          style({ transform: 'translateY(-100%)', height: 0, opacity: 0 }),
        ),
      ]),
    ]),
  ],
  styles: [
    `
      ::-webkit-scrollbar {
        width: 0px !important;
      }

      :host {
        .__gv-scrollbar {
          scrollbar-width: none !important;
        }
      }
    `,
  ],
  standalone: true,
  imports: [
    ToasterComponent,
    ScrollFadeReversedComponent,
    TestIdModule,
    NgClass,
  ],
})
export class ToasterContainerComponent {
  protected service = inject(ToasterService);
  protected dialog = inject(MatDialog);
  protected toastsS = this.service.toastsS;

  someDialogOpenedS = toSignal(
    merge(
      this.dialog.afterAllClosed.pipe(map(() => false)),
      this.dialog.afterOpened.pipe(map(() => true)),
    ).pipe(distinctUntilChanged()),
  );

  _ = hostBinding(
    'class.backdrop-blur-[100px]',
    computed(() => !this.someDialogOpenedS()),
  );
}
