import type { OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  NgZone,
  inject,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { logo$ } from '@gv/ui/core';
import { untilNgDestroyed } from '@gv/utils';
import { DateTime } from 'luxon';
import { of, timer } from 'rxjs';
import { delayWhen, takeWhile } from 'rxjs/operators';

import { RouterHelperService } from '../../../service/application/routing/router-helper.service';
import { ScrollbarService } from '../../../service/application/scrollbar.service';
import { MaintenanceStateDetectionService } from '../../../service/application/state/maintenance-state-detection.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styles: [
    `
      .bgimage {
        background: url('../../../../css-assets/images/default-background.png');
      }
    `,
  ],
  standalone: true,
  imports: [MatIconModule, AsyncPipe],
})
export class MaintenancePageComponent implements OnDestroy, OnInit {
  private destroyRef = inject(DestroyRef);
  private maintenanceStateDetectionService = inject(
    MaintenanceStateDetectionService,
  );
  private scrollbar = inject(ScrollbarService);
  private routerHelper = inject(RouterHelperService);
  private dialog = inject(MatDialog);
  private router = inject(Router);
  private ngZone = inject(NgZone);
  private retryCalled = false;

  readonly logo$ = logo$;

  ngOnDestroy(): void {
    this.scrollbar.isScrollbarVisible = true;

    // just to be sure that any meanwhile opened dialogs are closed
    this.closeAllDialogs();

    this.maintenanceStateDetectionService.disableAutoDetection();
  }

  ngOnInit(): void {
    this.scrollbar.isScrollbarVisible = false;

    this.closeAllDialogs();

    this.maintenanceStateDetectionService.maintenanceStateDetected$
      .pipe(
        delayWhen(() => {
          // need to prevent flickering effect caused by false positives:
          // when maintenance state not detected but retry attempt still fails
          // => retry should happen only only after certain minimum delay

          if (!this.maintenanceStateDetectionService.lastRetry) {
            return of(0);
          }

          const now = DateTime.now();
          if (
            now < this.maintenanceStateDetectionService.lastRetry ||
            now === this.maintenanceStateDetectionService.lastRetry
          ) {
            return of(0);
          }

          const diff = now
            .diff(
              this.maintenanceStateDetectionService.lastRetry,
              'milliseconds',
            )
            .toMillis();

          if (diff < MaintenanceStateDetectionService.intervalDuration) {
            return timer(
              MaintenanceStateDetectionService.intervalDuration - diff,
            );
          }
          return of(0);
        }),
        takeWhile(() => !this.retryCalled),
        untilNgDestroyed(this.destroyRef),
      )
      .subscribe((maintenanceStateDetected) => {
        if (
          maintenanceStateDetected !== undefined &&
          !maintenanceStateDetected
        ) {
          this.ngZone.run(() => {
            return this.retry();
          });
        }
      });

    this.maintenanceStateDetectionService.enableAutoDetection(
      !this.routerHelper.previousUrl,
    );
  }

  retry(): Promise<boolean> {
    if (!this.retryCalled) {
      this.retryCalled = true;
    }

    this.maintenanceStateDetectionService.lastRetry = DateTime.now();

    const previousUrl = this.routerHelper.previousUrl;

    if (!previousUrl || previousUrl.indexOf('/maintenance-page') === 0) {
      return this.router.navigate(['/app'], {
        replaceUrl: true,
      });
    }

    return this.router.navigateByUrl(previousUrl, {
      replaceUrl: true,
    });
  }

  private closeAllDialogs(): void {
    this.dialog.openDialogs.forEach((dialog) => {
      dialog.close();
    });
  }
}
