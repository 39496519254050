import type { MonoTypeOperatorFunction } from 'rxjs';
import { Observable } from 'rxjs';
import { filter, retry, tap } from 'rxjs/operators';

export const RETRY_WHEN_WITH_NEXT_RETRY = Symbol('retry');

// TODO: check if this works as expected
export function retryWithNext<T>(
  notifier: (
    errors: unknown,
    retryCount: number,
  ) => Observable<T | typeof RETRY_WHEN_WITH_NEXT_RETRY>,
): MonoTypeOperatorFunction<T> {
  return (source: Observable<T>) =>
    new Observable<T>((obs) => {
      return source
        .pipe(
          retry({
            delay(error, retryCount) {
              return notifier(error, retryCount).pipe(
                tap((v) => {
                  if (v !== RETRY_WHEN_WITH_NEXT_RETRY) {
                    obs.next(v);
                  }
                }),
                filter((v) => v === RETRY_WHEN_WITH_NEXT_RETRY),
              );
            },
          }),
        )
        .subscribe({
          next(v) {
            obs.next(v);
          },
          error(error) {
            obs.error(error);
          },
          complete() {
            obs.complete();
          },
        });
    });
}
