import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { ScrollDirective, ButtonModule } from '@gv/ui/utils';
import {
  DialogActionsComponent,
  DialogBaseComponent,
  DialogContentComponent,
  DialogHeaderComponent,
} from '@gv/ui/dialog';

import { LogoutEverywhereDialogComponent } from '../../../component/dialog/auth/logout-everywhere-dialog/logout-everywhere-dialog.component';

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    ScrollDirective,
    DialogActionsComponent,
    DialogBaseComponent,
    DialogContentComponent,
    DialogHeaderComponent,
    LogoutEverywhereDialogComponent,
  ],
})
export class LogoutEverywhereDialogModule {}
