/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  Observable,
  ObservableInput,
  ObservedValueOf,
  OperatorFunction,
} from 'rxjs';
import { of } from 'rxjs';
import { concatMap, withLatestFrom } from 'rxjs/operators';

export function delayedConcatMap<T, O2 extends ObservableInput<any>>(
  fn: (val: T) => [O2],
): OperatorFunction<T, [T, ObservedValueOf<O2>]>;
export function delayedConcatMap<
  T,
  O2 extends ObservableInput<any>,
  O3 extends ObservableInput<any>,
>(
  fn: (val: T) => [O2, O3],
): OperatorFunction<T, [T, ObservedValueOf<O2>, ObservedValueOf<O3>]>;
export function delayedConcatMap<
  T,
  O2 extends ObservableInput<any>,
  O3 extends ObservableInput<any>,
  O4 extends ObservableInput<any>,
>(
  fn: (val: T) => [O2, O3, O4],
): OperatorFunction<
  T,
  [T, ObservedValueOf<O2>, ObservedValueOf<O3>, ObservedValueOf<O4>]
>;
export function delayedConcatMap<
  T,
  O2 extends ObservableInput<any>,
  O3 extends ObservableInput<any>,
  O4 extends ObservableInput<any>,
  O5 extends ObservableInput<any>,
>(
  fn: (val: T) => [O2, O3, O4, O5],
): OperatorFunction<
  T,
  [
    T,
    ObservedValueOf<O2>,
    ObservedValueOf<O3>,
    ObservedValueOf<O4>,
    ObservedValueOf<O5>,
  ]
>;
export function delayedConcatMap<
  T,
  O2 extends ObservableInput<any>,
  O3 extends ObservableInput<any>,
  O4 extends ObservableInput<any>,
  O5 extends ObservableInput<any>,
  O6 extends ObservableInput<any>,
>(
  fn: (val: T) => [O2, O3, O4, O5, O6],
): OperatorFunction<
  T,
  [
    T,
    ObservedValueOf<O2>,
    ObservedValueOf<O3>,
    ObservedValueOf<O4>,
    ObservedValueOf<O5>,
    ObservedValueOf<O6>,
  ]
>;
export function delayedConcatMap<
  T,
  O2 extends ObservableInput<any>,
  O3 extends ObservableInput<any>,
  O4 extends ObservableInput<any>,
  O5 extends ObservableInput<any>,
  O6 extends ObservableInput<any>,
  O7 extends ObservableInput<any>,
>(
  fn: (val: T) => [O2, O3, O4, O5, O6, O7],
): OperatorFunction<
  T,
  [
    T,
    ObservedValueOf<O2>,
    ObservedValueOf<O3>,
    ObservedValueOf<O4>,
    ObservedValueOf<O5>,
    ObservedValueOf<O6>,
    ObservedValueOf<O7>,
  ]
>;
export function delayedConcatMap<
  T,
  O2 extends ObservableInput<any>,
  O3 extends ObservableInput<any>,
  O4 extends ObservableInput<any>,
  O5 extends ObservableInput<any>,
  O6 extends ObservableInput<any>,
  O7 extends ObservableInput<any>,
  O8 extends ObservableInput<any>,
>(
  fn: (val: T) => [O2, O3, O4, O5, O6, O7, O8],
): OperatorFunction<
  T,
  [
    T,
    ObservedValueOf<O2>,
    ObservedValueOf<O3>,
    ObservedValueOf<O4>,
    ObservedValueOf<O5>,
    ObservedValueOf<O6>,
    ObservedValueOf<O7>,
    ObservedValueOf<O8>,
  ]
>;
export function delayedConcatMap<
  T,
  O2 extends ObservableInput<any>,
  O3 extends ObservableInput<any>,
  O4 extends ObservableInput<any>,
  O5 extends ObservableInput<any>,
  O6 extends ObservableInput<any>,
  O7 extends ObservableInput<any>,
  O8 extends ObservableInput<any>,
  O9 extends ObservableInput<any>,
>(
  fn: (val: T) => [O2, O3, O4, O5, O6, O7, O8, O9],
): OperatorFunction<
  T,
  [
    T,
    ObservedValueOf<O2>,
    ObservedValueOf<O3>,
    ObservedValueOf<O4>,
    ObservedValueOf<O5>,
    ObservedValueOf<O6>,
    ObservedValueOf<O7>,
    ObservedValueOf<O8>,
    ObservedValueOf<O9>,
  ]
>;
export function delayedConcatMap<T, R extends Observable<any>>(
  fn: (val: T) => Observable<any>[],
): OperatorFunction<T, R> {
  return concatMap(
    (val: T): Observable<R> =>
      of(val).pipe(withLatestFrom(...fn(val))) as unknown as Observable<R>,
  );
}
