import type { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import type { ApiErrorResponse } from '@gv/api';

export class HttpError extends Error {
  error: unknown;
  ok = false;
  headers: HttpHeaders = new HttpHeaders();
  status: number | undefined;
  statusText: string | undefined;
  url: string | undefined;
  type: HttpEventType.ResponseHeader | HttpEventType.Response = 2;
  override name: string;

  constructor(
    context: string,
    response: HttpErrorResponse | ApiErrorResponse<unknown>,
  ) {
    super(response.message);

    if (response) {
      const properties = Object.getOwnPropertyNames(response);
      for (const property of properties) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        (this as any)[property] =
          response[
            property as keyof (HttpErrorResponse | ApiErrorResponse<unknown>)
          ];
      }
    }

    if (
      this.error &&
      typeof this.error === 'object' &&
      Object.prototype.hasOwnProperty.call(this.error, 'stack')
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      this.stack = (this.error as any).stack;
    }

    this.name = `HttpError[${context || ''}]`;

    Object.setPrototypeOf(this, HttpError.prototype);
  }
}
