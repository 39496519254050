import type { MatSnackBarConfig } from '@angular/material/snack-bar';

import { createAction, props } from '@ngrx/store';
export interface SnackbarMessage {
  action?: string;
  config?: MatSnackBarConfig;
  message: string;
}

const basename = '[Snackar]';

export const SnackBarActions = {
  show: createAction(`${basename} snackbar`, props<SnackbarMessage>()),

  dismiss: createAction(`${basename} dismiss`),
};
