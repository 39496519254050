import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { OverlayContainer } from '@angular/cdk/overlay';
import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  HostListener,
  inject,
  Input,
  TemplateRef,
} from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';

import { trackEvent } from '@gv/analytics';
import { hasSomeParentWithTag } from '@gv/utils';
import { TestIdModule } from '@gv/debug';

function getImportance(el: Element): number {
  if (
    el.classList.contains('btn-warn') ||
    el.classList.contains('btn-green') ||
    el.classList.contains('warn') ||
    el.classList.contains('green') ||
    el.classList.contains('submit')
  ) {
    return 0;
  }
  return 1;
}

@Directive({ selector: '[gvDialogWarning]', standalone: true })
export class DialogWarningDirective {
  templateRef = inject(TemplateRef);
}
@Directive({ selector: '[gvDialogHelp]', standalone: true })
export class DialogHelpDirective {
  templateRef = inject(TemplateRef);
}

@Component({
  selector: 'gv-dialog-actions',
  standalone: true,
  imports: [MatDialogModule, NgTemplateOutlet, TestIdModule],
  template: `
    <mat-dialog-actions
      gvGroupId
      gvId="dialog-actions"
      class="mx-[24px] mb-6 grid grid-cols-[1fr_max-content] grid-rows-[1fr-max-content] px-0 pb-0 pt-3"
    >
      @if (warning || warningTpl) {
        <div class="col-span-2 space-y-4 pb-3">
          <ng-container
            *ngTemplateOutlet="(warning || warningTpl).templateRef"
          />
        </div>
      }
      @if (help || helpTpl) {
        <div class="row-start-2">
          <ng-container *ngTemplateOutlet="(help || helpTpl).templateRef" />
        </div>
      }
      <div class="col-start-2 row-start-2 flex space-x-3">
        <ng-content></ng-content>
      </div>
    </mat-dialog-actions>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogActionsComponent {
  private dialog = inject(MatDialog);
  private overlay = inject(OverlayContainer);
  private ref = inject<ElementRef<HTMLElement>>(ElementRef<HTMLElement>);

  @ContentChild(DialogHelpDirective, { static: false, descendants: true })
  protected help: DialogHelpDirective | undefined;

  @ContentChild(DialogWarningDirective, { static: false, descendants: true })
  protected warning: DialogWarningDirective | undefined;

  @Input()
  helpTpl: DialogHelpDirective | undefined;

  @Input()
  warningTpl: DialogWarningDirective | undefined;

  @Input()
  disableSubmitByKey = false;

  @HostListener('window:keydown.enter', ['$event'])
  onClick(event: KeyboardEvent): void {
    if (this.disableSubmitByKey) {
      return;
    }

    if ('isContentEditable' in event.target) {
      if (
        (event.target as HTMLElement).isContentEditable ||
        hasSomeParentWithTag(event.target as HTMLElement, 'gv-comment')
      ) {
        return;
      }
    }

    const openedContainers = Array.from(
      this.overlay?.getContainerElement()?.children,
    )
      ?.filter((child) => child.clientWidth > 0 && child.clientHeight > 0)
      ?.map((child) => child.children)
      ?.filter((children) => children?.length === 0);

    if (openedContainers.length > this.dialog.openDialogs.length) {
      return;
    }

    if (this.dialog.openDialogs.length >= 2) {
      const lastDialogId =
        this.dialog.openDialogs[this.dialog.openDialogs.length - 1].id;
      let el = this.ref.nativeElement;
      while ((el = el.parentElement) && el.id !== lastDialogId) {
        // do nothing
      }
      if (!el) {
        return;
      }
    }

    event.preventDefault();
    event.stopPropagation();

    const buttons: HTMLElement[] = Array.from(
      this.ref.nativeElement?.querySelectorAll(
        'button,[type=submit],.btn,app-button',
      ) || [],
    );

    if (
      buttons.some((f) => {
        const disabled = f.getAttribute('disabled');
        return coerceBooleanProperty(disabled);
      }) ||
      buttons.reduce((acc, r) => acc + (1 - getImportance(r)), 0) > 1
    ) {
      return;
    }

    trackEvent('keyboardUsed', {
      key: 'enter',
      location: 'dialog',
    });
    const button = buttons.sort((a, b) => {
      return getImportance(a) - getImportance(b);
    })[0];
    button?.click();
  }
}
