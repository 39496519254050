import { createSubStateReducerFns } from '@gv/state';
import { UserActions } from '@gv/user';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type { Draft } from 'immer';

import { detail } from './actions';
import type { DetailState } from './state';
import { initialDetailState } from './state';

const reducer = createReducer(
  initialDetailState,

  ...createSubStateReducerFns(
    detail,
    (state: Draft<DetailState>) => state.detail,
    (_, data, dtSent) => {
      return { ...data, dtSent };
    },
    $localize`:@@reducers.failed-to-load-user-detail:Failed to load user detail`,
  ),

  on(UserActions.logout, () => initialDetailState),
);

export function detailReducer(
  state: DetailState | undefined,
  action: Action,
): DetailState {
  return reducer(state, action);
}
