import type { Type } from '@angular/core';

import {
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  createFeatureSelector,
  createReducer,
  createSelector,
} from '@ngrx/store';
import type { MemoizedSelector, Selector, ReducerTypes } from '@ngrx/store';
import type {
  Action,
  ActionCreator,
  ActionReducer,
  Primitive,
} from '@ngrx/store/src/models';
import type { Draft } from 'immer';
import { ObjectUtils } from '@gv/utils';
import type { FunctionalEffect } from '@ngrx/effects';

import { createSubStateActions } from './create-sub-state-action';
import type {
  IncludeField,
  BaseSubStateActions,
} from './create-sub-state-action';
import { createSubStateReducerFns } from './create-sub-state-reducers';
import {
  initialArraySubState,
  type SubStateArrayModel,
} from './sub-state-model';
import type { SubStateArraySelectors } from './create-sub-state-selectors';
import { createSubStateSelectors } from './create-sub-state-selectors';
import type { Store } from '../store';
import { StoreInjectionToken } from '../inject';

type SelectorsDictionary = Record<
  string,
  | Selector<Record<string, any>, unknown>
  | ((...args: any[]) => Selector<Record<string, any>, unknown>)
>;

type FeatureSelector<FeatureName extends string, FeatureState> = {
  [K in FeatureName as `select${Capitalize<K>}State`]: MemoizedSelector<
    Record<string, any>,
    FeatureState,
    (featureState: FeatureState) => FeatureState
  >;
};

type NestedSelectors<FeatureState> = FeatureState extends
  | Primitive
  | unknown[]
  | Date
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : {
      [K in keyof FeatureState &
        string as `select${Capitalize<K>}`]: MemoizedSelector<
        Record<string, any>,
        FeatureState[K],
        (featureState: FeatureState) => FeatureState[K]
      >;
    };

export type NotSubStateState<T extends SubStateArrayModel<any>> = Omit<
  T,
  keyof SubStateArrayModel<any>
>;

export type BaseFeatureSelectors<
  FeatureName extends string,
  StateModel extends { readonly uuid: string },
  FeatureState extends SubStateArrayModel<StateModel>,
  OtherStateFields extends NotSubStateState<FeatureState>,
> = FeatureSelector<Plural<FeatureName>, FeatureState> &
  NestedSelectors<OtherStateFields> &
  SubStateArraySelectors<FeatureState, StateModel, FeatureState, FeatureName>;

export type ArrayFeature<
  FeatureName extends string,
  ExtraSelectors extends SelectorsDictionary,
  ApiModel,
  StateModel extends { readonly uuid: string },
  FeatureState extends SubStateArrayModel<StateModel>,
  OtherStateFields extends NotSubStateState<FeatureState>,
  ExtraActions extends Record<string, any>,
  FetchCustomType,
  CompletedCustomType,
  ExtraEffects,
> = Omit<
  BaseFeatureSelectors<FeatureName, StateModel, FeatureState, OtherStateFields>,
  keyof ExtraSelectors
> &
  ExtraSelectors & {
    actions: Omit<
      BaseSubStateActions<
        readonly ApiModel[],
        readonly StateModel[],
        FetchCustomType,
        CompletedCustomType
      >,
      keyof ExtraActions
    > &
      ExtraActions;
    reducer: ActionReducer<FeatureState, Action>;
    name: Plural<FeatureName>;
    token: StoreInjectionToken<Store<FeatureState>>;
  } & IncludeField<'effects', ExtraEffects>;

type Plural<FeatureName extends string> = `${FeatureName}s`;

export interface ActionPropType<T> {
  __t: T;
}

export function actionProps<T>(): ActionPropType<T> {
  return {} as ActionPropType<T>;
}

export type InferExtraEffects<T> = [T] extends [undefined] ? undefined : T;

export type InferActionProp<T> = [T] extends [undefined]
  ? undefined
  : [T] extends [ActionPropType<infer J>]
    ? J
    : undefined;

export function createArrayFeature<
  FeatureName extends string,
  ExtraSelectors extends SelectorsDictionary,
  FeatureApiModel,
  ExtraActions extends Record<string, any>,
  StateEntityModel extends { readonly uuid: string },
  FeatureState extends SubStateArrayModel<StateEntityModel>,
  OtherStateFields extends NotSubStateState<FeatureState>,
  ExtraEffects extends
    | Array<Type<unknown> | Record<string, FunctionalEffect>>
    | undefined = undefined,
  FetchCustomProps extends ActionPropType<unknown> | undefined = undefined,
  CompletedCustomType extends ActionPropType<unknown> | undefined = undefined,
  FetchCustomTypeInfered = InferActionProp<FetchCustomProps>,
  CompletedCustomTypeInfered = InferActionProp<CompletedCustomType>,
  ExtraEffectsInfered = InferExtraEffects<ExtraEffects>,
>(
  initialState: FeatureState,
  featureConfig: {
    name: FeatureName;
    fetchProps?: FetchCustomProps;
    completedProps?: CompletedCustomType;
    extraReducers: ReducerTypes<FeatureState, readonly ActionCreator[]>[];
    extraReducersFactory?: (
      actions: Omit<
        BaseSubStateActions<
          readonly FeatureApiModel[],
          readonly StateEntityModel[],
          FetchCustomTypeInfered,
          CompletedCustomTypeInfered
        >,
        keyof ExtraActions
      > &
        ExtraActions,
    ) => ReducerTypes<FeatureState, readonly ActionCreator[]>[];
    extraSelectors: (
      selectors: BaseFeatureSelectors<
        FeatureName,
        StateEntityModel,
        FeatureState,
        OtherStateFields
      >,
    ) => ExtraSelectors;
    extraActions: ExtraActions;
    extraEffects?: (
      actions: Omit<
        BaseSubStateActions<
          readonly FeatureApiModel[],
          readonly StateEntityModel[],
          FetchCustomTypeInfered,
          CompletedCustomTypeInfered
        >,
        keyof ExtraActions
      > &
        ExtraActions,
      selectors: Omit<
        BaseFeatureSelectors<
          FeatureName,
          StateEntityModel,
          FeatureState,
          OtherStateFields
        >,
        keyof ExtraSelectors
      > &
        ExtraSelectors,
    ) => ExtraEffects;
    modelMapper: (
      state: FeatureState,
      apiModel: readonly FeatureApiModel[],
      dtSent: Date,
    ) => readonly StateEntityModel[];
    defaultErrorMessage: string;
  },
): ArrayFeature<
  FeatureName,
  ExtraSelectors,
  FeatureApiModel,
  StateEntityModel,
  FeatureState,
  OtherStateFields,
  ExtraActions,
  FetchCustomTypeInfered,
  CompletedCustomTypeInfered,
  ExtraEffectsInfered
> {
  const actions = createSubStateActions<
    readonly FeatureApiModel[],
    readonly StateEntityModel[],
    FetchCustomTypeInfered,
    CompletedCustomTypeInfered
  >(featureConfig.name);

  const reducerFns = createSubStateReducerFns(
    actions,
    (state: Draft<FeatureState>) => state,
    featureConfig.modelMapper,
    featureConfig.defaultErrorMessage,
    true,
  );

  const allActions = { ...actions, ...featureConfig.extraActions };

  const reducer = createReducer(
    initialState,
    ...reducerFns,
    ...featureConfig.extraReducers,
    ...(featureConfig.extraReducersFactory?.(allActions) ?? []),
  );

  const featureSelector = createFeatureSelector<FeatureState>(
    `${featureConfig.name}s`,
  );
  const nestedSelectors = createNestedSelectors<OtherStateFields>(
    featureSelector,
    initialState,
  );

  const baseSelectors = {
    [`select${capitalize(featureConfig.name)}sState`]: featureSelector,
  } as FeatureSelector<Plural<FeatureName>, FeatureState>;

  const subStateSelectors = createSubStateSelectors(
    featureSelector,
    featureConfig.name,
    true,
  );

  const selectors = {
    ...baseSelectors,
    ...nestedSelectors,
    ...subStateSelectors,
  } as BaseFeatureSelectors<
    FeatureName,
    StateEntityModel,
    FeatureState,
    OtherStateFields
  >;

  const extraSelectors = featureConfig.extraSelectors(selectors);
  const effects = featureConfig.extraEffects?.(allActions, {
    ...selectors,
    ...extraSelectors,
  });

  return {
    name: `${featureConfig.name}s`,
    token: new StoreInjectionToken(`${featureConfig.name}sStore`),
    ...((effects ? { effects } : {}) as IncludeField<
      'effects',
      ExtraEffectsInfered
    >),
    actions: allActions,
    reducer,
    ...selectors,
    ...extraSelectors,
  } as unknown as ArrayFeature<
    FeatureName,
    ExtraSelectors,
    FeatureApiModel,
    StateEntityModel,
    FeatureState,
    OtherStateFields,
    ExtraActions,
    FetchCustomTypeInfered,
    CompletedCustomTypeInfered,
    ExtraEffectsInfered
  >;
}

function capitalize(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1, value.length);
}

function createNestedSelectors<OtherStateFields>(
  featureSelector: MemoizedSelector<Record<string, any>, any>,
  initialState: any,
): NestedSelectors<OtherStateFields> {
  const nestedKeys = getStateField(initialState);

  return nestedKeys.reduce(
    (nestedSelectors, nestedKey) => ({
      ...nestedSelectors,
      [`select${capitalize(nestedKey)}`]: createSelector(
        featureSelector,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
        (parentState) => parentState?.[nestedKey],
      ),
    }),
    {} as NestedSelectors<OtherStateFields>,
  );
}

function getStateField<OtherStateFields, FeatureState extends OtherStateFields>(
  initialState: FeatureState,
): string[] {
  return ObjectUtils.keys(initialState as NonNullable<unknown>).filter(
    (f) => !(f in initialArraySubState),
  );
}
