import type { MatDialogRef } from '@angular/material/dialog';

import type { TypedAction } from '@ngrx/store/src/models';
import { isFunction } from 'lodash-es';
import type { MonoTypeOperatorFunction } from 'rxjs';
import { tap } from 'rxjs/operators';

export function closeDialogOnFlowSuccess<T extends TypedAction<string>, R>(
  dialogRef: MatDialogRef<unknown, R>,
  data?: R | ((a: T) => R),
): MonoTypeOperatorFunction<T> {
  return tap((a: T) => {
    // if not error; we test by regex only, should work as we use it for flows only
    if (!/error\[.*\]/.test(a.type)) {
      dialogRef.close(isFunction(data) ? data(a) : data);
    }
  });
}
