import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { initLogger } from '@gv/logger';

import { AppModule } from './app/module/core/app.module';
import { environment } from './environments/environment';
import { logger } from './logger';

if (environment.production) {
  enableProdMode();
}

const bootstrap = () =>
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((moduleRef) => {
      if (!environment.production) {
        // import('./mocks/browser').then(({ worker }) => {
        //   worker.start({
        //     onUnhandledRequest: 'bypass',
        //     serviceWorker: {
        //       url: '/mock-service-worker.js',
        //       options: {
        //         scope: '/',
        //       },
        //     },
        //   });
        // });

        const appRef = moduleRef.injector.get(ApplicationRef);
        const componentRef = appRef.components[0];

        if (componentRef) {
          enableDebugTools(componentRef);
        }
      }
      return moduleRef;
    });

initLogger(localStorage, true);
bootstrap().catch((error) =>
  logger.error({ error }, 'Failed to bootstrap application'),
);
