import { createFeatureSelector, createSubStateSelectors } from '@gv/state';
import { createSelector } from '@ngrx/store';

import type { DevicesFeatureState } from './state';
import { devicesFeatureKey } from './state';

const getState = createFeatureSelector<DevicesFeatureState>(devicesFeatureKey);

const baseSelectors = createSubStateSelectors(getState, 'device', true);

export { baseSelectors };

export const getNumberOfDevices = createSelector(
  baseSelectors.getDevices,
  (devices) => devices.length,
);

export const getNumberOfStreams = createSelector(
  baseSelectors.getDevices,
  (devices) =>
    devices.reduce((acc, device) => device.availableStreams + acc, 0),
);

export const getNumberOfConnectedStreams = createSelector(
  baseSelectors.getDevices,
  (devices) => devices.reduce((acc, device) => device.usedStreams + acc, 0),
);
