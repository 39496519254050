import { createAction, props } from '@ngrx/store';
import type {
  DataSourceDetailApiModel,
  CreateLocationModel,
  EditLocationModel,
  LocationApiModel,
  LocationValidationModel,
  MapSettings,
  ProjectType,
} from '@gv/api';
import { createFlowBaseActions, createSubStateActions } from '@gv/state';
import type { DataSourceDetailStateModel } from '@gv/datasource';
import type { PageSettings } from '@gv/page-settings';

import type { LocationModel } from '../../entity/model/location/location-model';
import type { LocationStateModel } from '../../entity/model/location/location-state-model';

const basename = '[Location]';
const locationBasename = `${basename} location`;
const dataSourcesBasename = `${basename} data sources`;

export const LocationActions = {
  add: createAction(
    `${basename} add`,
    props<{ projectUuid: string; location: LocationModel }>(),
  ),

  remove: createAction(
    `${basename} remove`,
    props<{ projectUuid: string; uuid: string }>(),
  ),

  update: createAction(
    `${basename} update`,
    props<{
      projectUuid: string;
      uuid: string;
      location: Partial<LocationModel>;
    }>(),
  ),

  validateFlow: createFlowBaseActions(basename, 'validate', {
    initProps: props<{ data: { projectUuid: string; uuid: string } }>(),
    completedProps: props<{ data: LocationValidationModel }>(),
  }),

  deleteFlow: createFlowBaseActions(basename, 'delete', {
    initProps: props<{ data: { projectUuid: string; uuid: string } }>(),
  }),

  createFlow: createFlowBaseActions(basename, 'create', {
    initProps: props<{
      data: { projectUuid: string; data: CreateLocationModel };
    }>(),
    completedProps: props<{ data: LocationModel }>(),
  }),

  editFlow: createFlowBaseActions(basename, 'edit', {
    initProps: props<{
      data: {
        projectUuid: string;
        locationUuid: string;
        data: EditLocationModel;
        ignorePageSettings?: boolean;
      };
    }>(),
    completedProps: props<{ data: LocationModel }>(),
  }),

  init: createAction(
    `${basename} init`,
    props<{ uuid: string; projectUuid: string; projectType: ProjectType }>(),
  ),

  location: createSubStateActions<LocationApiModel, LocationStateModel>(
    locationBasename,
  ),

  dataSources: createSubStateActions<
    readonly DataSourceDetailApiModel[],
    readonly DataSourceDetailStateModel[]
  >(dataSourcesBasename),

  reset: createAction(`${basename} reset`),

  setLocationPageSettings: createAction(
    `${locationBasename} set page options`,
    props<{ settings: PageSettings }>(),
  ),

  setLocationMapPreferences: createAction(
    `${locationBasename} set map settings`,
    props<{ settings: MapSettings & { preferredZoom: number } }>(),
  ),
};
