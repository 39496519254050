import type { FormGroup } from '@angular/forms';
import type { Signal } from '@angular/core';
import { DestroyRef, inject, isSignal, Injector, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

import {
  debounceTime,
  delay,
  distinctUntilChanged,
  switchMap,
  tap,
  map,
  shareReplay,
} from 'rxjs/operators';
import { isArray, isEqualWith } from 'lodash-es';
import { untilNgDestroyed, ObjectUtils, simpleSwitchMap } from '@gv/utils';
import type { Observable } from 'rxjs';
import { EMPTY, of } from 'rxjs';

import { ComponentStore } from './component-store';

type ReduceFN<State, V> = (state: State, value: V) => State;

type ExcludeFN<State, V> = (state: State, value: V) => boolean;

export type FormMappingType<T, State> = {
  [P in keyof T]:
    | ReduceFN<State, T[P]>
    | [ReduceFN<State, T[P]>, ExcludeFN<State, T[P]>];
};

const momentDeepEqual = (x: unknown, y: unknown) =>
  isEqualWith(x, y, (v1, v2) =>
    v1 && v2 && v1 instanceof Date && v2 instanceof Date
      ? v1.getTime() === v2.getTime()
      : undefined,
  );

export abstract class FormBaseStore<
  FormModel,
  // eslint-disable-next-line @typescript-eslint/ban-types
  State extends object,
> extends ComponentStore<State> {
  protected destroyRef = inject(DestroyRef);
  private injector = inject(Injector);
  protected abstract readonly _stateToForm: (state: State) => FormModel;
  protected abstract readonly _formMapping: FormMappingType<FormModel, State>;

  readonly asForm$ = this.select((state) => this._stateToForm(state));

  constructor(defaultState?: State) {
    super(defaultState);
  }

  readonly setFormData = this.updater((state, value: FormModel): State => {
    const updatedFields = Object.keys(this._formMapping).filter((_field) => {
      const field = _field as unknown as keyof FormModel;
      const val = this._formMapping[field];

      return isArray(val) ? !val[1](state, value[field]) : true;
    });

    return updatedFields.reduce((accState, _field) => {
      const field = _field as unknown as keyof FormModel;
      if (!ObjectUtils.hasProperty(value, field)) {
        return accState;
      }

      const val = this._formMapping[field];
      const mapFn: ReduceFN<State, FormModel[keyof FormModel]> = isArray(val)
        ? val[0]
        : val;

      return mapFn(accState, value[field]);
    }, state);
  });

  registerFormGroupStrict(formGroup: FormGroup | Signal<FormGroup>): void {
    const fg = isSignal(formGroup) ? formGroup : signal(formGroup);
    const value$: Observable<FormModel> = this.asForm$.pipe(
      distinctUntilChanged<FormModel>(momentDeepEqual),
      switchMap((value) => of(value).pipe(delay(1))),
      shareReplay({ refCount: true, bufferSize: 1 }),
      untilNgDestroyed(this.destroyRef),
    );

    value$.subscribe();

    toObservable(fg, { injector: this.injector })
      .pipe(
        simpleSwitchMap((f) =>
          value$.pipe(tap((value: FormModel) => f.patchValue(value as any))),
        ),
        untilNgDestroyed(this.destroyRef),
      )
      .subscribe();
    const dataObs = toObservable(fg, { injector: this.injector }).pipe(
      simpleSwitchMap(
        (f) =>
          this.asForm$.pipe(
            distinctUntilChanged<FormModel>(momentDeepEqual),
            switchMap(() => {
              const value$ = f.valueChanges as Observable<FormModel>;
              return value$.pipe(
                debounceTime(0),
                map(() => f.value as FormModel),
              );
            }),
          ),
        EMPTY,
      ),
      untilNgDestroyed(this.destroyRef),
    );
    this.setFormData(dataObs);
  }

  registerFormGroup(formGroup: FormGroup | Signal<FormGroup>): void {
    const fg = isSignal(formGroup) ? formGroup : signal(formGroup);
    toObservable(fg, { injector: this.injector })
      .pipe(
        simpleSwitchMap((f) =>
          this.asForm$.pipe(
            distinctUntilChanged<FormModel>(momentDeepEqual),
            debounceTime(0),
            tap((value: FormModel) => f.patchValue(value)),
          ),
        ),
        untilNgDestroyed(this.destroyRef),
      )
      .subscribe();

    this.setFormData(
      toObservable(fg, { injector: this.injector }).pipe(
        simpleSwitchMap((f) => f.valueChanges, EMPTY),
        debounceTime(0),
        untilNgDestroyed(this.destroyRef),
      ),
    );
  }
}
