/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ofType } from '@ngrx/effects';
import type { Action } from '@ngrx/store';
import type { Observable, OperatorFunction } from 'rxjs';
import { concat, EMPTY, merge, of } from 'rxjs';
import {
  catchError,
  defaultIfEmpty,
  filter,
  map,
  mergeMap,
  share,
  take,
  takeUntil,
} from 'rxjs/operators';
import { softTimeout } from '@gv/utils';

import type {
  DataProps,
  FlowInitActionType,
  FlowProps,
  PropagatedInitProps,
  TypedFlowBaseActions,
  CompleteProps,
} from './create-flow-base-actions';
import { ofActions } from '../of-actions';
import { ofFlowAction } from './of-flow-action';
import { logger } from '../../../logger';

export interface OptionsType {
  actions$: Observable<Action>;
  timeout?: number;
}

type FlowEffectFn<A, CompletedData> = (v: {
  action: A;
}) => Observable<Action | CompletedData>;

function isAction(a: any): a is Action {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return a && typeof a.type === 'string';
}

const EMPTY_OBS = Symbol('__EMPTY_OBS');

export function createFlowActionObservable<
  InitProps,
  CompletedData,
  PropagateInitProps extends boolean,
  IPInfered extends InitProps,
  CDInfered extends CompletedData, // we use extends here as otherwise infered type from FlowEffectFn takes precedence over the type from TypedFlowBaseActions
>(
  fn: FlowEffectFn<
    ReturnType<FlowInitActionType<DataProps<IPInfered>>>,
    CompletedData
  >,
  actions: TypedFlowBaseActions<InitProps, CompletedData, PropagateInitProps>,
  options: OptionsType,
): OperatorFunction<
  ReturnType<FlowInitActionType<DataProps<IPInfered>>>,
  Action
> {
  return mergeMap(
    (
      action: ReturnType<FlowInitActionType<DataProps<IPInfered>>>,
    ): Observable<Action> => {
      const { id, silent, noUpdate, data } = action as any;

      const until$: Observable<Action> = options.actions$.pipe(
        ofFlowAction(action),
        ofType(actions.cancel, actions.completed, actions.error),
        take(1),
      );

      const obs$: Observable<Action | CompletedData> = fn({ action }).pipe(
        takeUntil(until$),
        share(),
      );

      return concat(
        ofActions(
          actions.started(
            (actions.propagateInitProps
              ? {
                  id,
                  silent,
                  noUpdate,
                  initProps: data,
                }
              : {
                  id,
                  silent,
                  noUpdate,
                }) as FlowProps<
              PropagatedInitProps<InitProps, PropagateInitProps>
            >,
          ),
          actions.loader &&
            actions.loader.acquire({ data } as DataProps<InitProps>),
        ),
        merge(
          obs$.pipe(
            catchError(() => EMPTY),
            filter((d): d is Action => isAction(d)),
          ),
          obs$.pipe(
            filter<any>((d) => !isAction(d)),
            defaultIfEmpty<CDInfered, typeof EMPTY_OBS>(EMPTY_OBS),
            map((d): any => {
              const base =
                d !== EMPTY_OBS
                  ? { data: d, id, silent, noUpdate }
                  : { id, silent, noUpdate };

              if (actions.propagateInitProps) {
                (base as any).initProps = data;
              }

              return base as FlowProps<
                PropagatedInitProps<InitProps, PropagateInitProps>
              > &
                CompleteProps<CDInfered>;
            }),
            map((d): Action => actions.completed(d)),
            catchError((e): Observable<Action> => {
              return of(
                actions.error(
                  (actions.propagateInitProps
                    ? {
                        id,
                        silent,
                        noUpdate,
                        initProps: data,
                        data: e,
                      }
                    : {
                        id,
                        silent,
                        noUpdate,
                        data: e,
                      }) as unknown as DataProps<Error> &
                    FlowProps<
                      PropagatedInitProps<IPInfered, PropagateInitProps>
                    >,
                ),
              );
            }),
            softTimeout(options.timeout || 10000, () => {
              logger.warn({ action }, 'Action not completed in time');
            }),
          ),
        ).pipe(takeUntil(until$)),
        ofActions(
          actions.loader &&
            actions.loader.release({ data } as DataProps<InitProps>),
        ),
      );
    },
  );
}
