import { HttpErrorResponse } from '@angular/common/http';
import type { ErrorHandler, OnDestroy } from '@angular/core';
import { Injectable, Injector, inject } from '@angular/core';

import { ApiErrorResponse } from '@gv/api';
import { appFullVersion } from '@gv/constant';
import { ElectronAppInfoService } from '@gv/desktop/core';
import { APP_INSTANCE_ID, Environment, WindowRefService } from '@gv/ui/core';
import { isObject, toSentryError } from '@gv/utils';
import {
  captureException,
  configureScope,
  init,
  setUser,
  withScope,
} from '@sentry/angular';
import {
  Dedupe,
  ExtraErrorData,
  Offline as OfflineIntegration,
  ReportingObserver,
} from '@sentry/integrations';
import type { SeverityLevel } from '@sentry/types';
import type { Subscription } from 'rxjs';

import { logger } from '../../../logger';
import type { ErrorHandlingStrategyModel } from '../../entity/model/error-handling/error-handling-strategy-model';
import { APP_CONFIG } from '../../entity/token/app.config';
import { SentryAggregateIntegrationService } from './sentry/sentry-aggregate-integration.service';
import { SentryTypeIntegrationService } from './sentry/sentry-type-integration.service';
import { TokenStoreService } from './user/token-store.service';
import { UserService } from './user/user.service';

@Injectable({
  providedIn: 'root',
})
export class AppErrorHandlerService implements ErrorHandler, OnDestroy {
  private appConfig = inject(APP_CONFIG);
  private appInstanceId = inject(APP_INSTANCE_ID);
  private injector = inject(Injector);
  private windowRef = inject(WindowRefService);
  private sentryTypeIntegrationService = inject(SentryTypeIntegrationService);
  private tokenStore = inject(TokenStoreService);
  private sentryAggregateIntegration = inject(
    SentryAggregateIntegrationService,
  );
  private electronAppInfoService = inject(ElectronAppInfoService, {
    optional: true,
  });
  /**
   * @todo inject via constructor
   */
  private static readonly errorHandlingStrategies: readonly ErrorHandlingStrategyModel[] =
    [
      {
        message: /Loading chunk [\d]+ failed/,
        reloadPage: true,
        severity: 'info',
      },
      {
        message: /net:/,
        reloadPage: false,
        severity: 'info',
      },
    ];

  private userChangedSubscription: Subscription | undefined;

  constructor() {
    const environment = this.detectSentryEnvironment();

    if (environment !== this.appConfig.sentry.environment.dev) {
      init({
        // It seems that it is not possible to provide constructor like type function with correct typescript typings
        // So we just pass function to provide correct angular aware service transport
        dsn: this.appConfig.sentry.dsn,
        environment,
        release: appFullVersion,
        integrations: [
          this.sentryTypeIntegrationService,
          this.sentryAggregateIntegration,
          new ExtraErrorData({
            depth: 10,
          }),
          new ReportingObserver(),
          new Dedupe(),
          new OfflineIntegration(),
        ],
        normalizeDepth: 5,
        attachStacktrace: true,
        ignoreErrors: ['ResizeObserver loop limit exceeded'],
        beforeSend: (event) => {
          if (!event.extra) {
            event.extra = {};
          }
          if (isObject(event.extra) && !('token' in event.extra)) {
            event.extra.token = this.tokenStore.decodedToken;
          }
          return event;
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyUserChangedSubscription();
  }

  enable(): void {
    this.userChangedSubscription = this.injector
      .get(UserService)
      .tokenUserUuid$.subscribe(() => {
        this.updateUserInfo();
      });
  }

  handleError(error: any): void {
    logger.error({ error }, 'Uncaught error');

    const mappedError = this.mapError(error);

    if (!mappedError) {
      return;
    }

    if (typeof mappedError === 'object' && mappedError.originalError) {
      this.processError(mappedError.originalError);
      return;
    }

    this.processError(mappedError);
  }

  private mapError(error: any): any {
    if (
      error instanceof HttpErrorResponse ||
      error instanceof ApiErrorResponse
    ) {
      if (error.status === 302) {
        // TODO: we should not ignore these errors
        return undefined;
      }
    }

    return error;
  }

  private detectSentryEnvironment(): string {
    switch (this.windowRef.nativeWindow._environment) {
      case Environment.ElectronProduction:
      case Environment.Production:
        return this.appConfig.sentry.environment.production;

      case Environment.ElectronStaging:
      case Environment.Staging:
      case Environment.ElectronDev:
        return this.appConfig.sentry.environment.staging;
    }
    return this.appConfig.sentry.environment.dev;
  }

  private updateUserInfo(): void {
    const decodedToken = this.tokenStore.decodedToken;

    let uuid: string;
    if (decodedToken && decodedToken.user) {
      uuid = decodedToken.user;
    }

    configureScope((scope) => {
      scope.setTag('instanceId', this.appInstanceId);
      scope.setTag(
        'desktopVersion',
        this.electronAppInfoService &&
          this.electronAppInfoService.appInfo &&
          this.electronAppInfoService.appInfo.version,
      );
      scope.setTag(
        'sessionId',
        this.electronAppInfoService &&
          this.electronAppInfoService.appInfo &&
          this.electronAppInfoService.appInfo.sessionId,
      );
      if (uuid) {
        scope.setUser({
          id: uuid,
        });
      } else {
        setUser(null);
      }
    });
  }

  private processError(error: any): void {
    let errorHandlingStrategy: ErrorHandlingStrategyModel | undefined;

    if (error && error.message) {
      errorHandlingStrategy =
        AppErrorHandlerService.errorHandlingStrategies.find((ehs) =>
          ehs.message.test(error.message),
        );
    }

    let level: SeverityLevel = 'error';

    if (
      (error instanceof HttpErrorResponse ||
        error instanceof ApiErrorResponse) &&
      (error.status === 401 || error.status === 0)
    ) {
      level = 'info';
    } else if (errorHandlingStrategy) {
      level = errorHandlingStrategy.severity;
    }

    withScope((scope) => {
      scope.setLevel(level);
      captureException(
        toSentryError(
          Object.prototype.hasOwnProperty.call(error, 'name')
            ? error.name
            : 'UnknownError',
          error,
        ),
      );
    });

    if (errorHandlingStrategy?.reloadPage) {
      this.windowRef.nativeWindow.location.reload();
    }
  }

  private destroyUserChangedSubscription(): void {
    if (this.userChangedSubscription) {
      this.userChangedSubscription.unsubscribe();
      this.userChangedSubscription = undefined;
    }
  }
}
