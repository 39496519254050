import { createAction, props } from '@ngrx/store';
import type {
  VaultUploadDetailModel,
  VaultUploadFileDetailModel,
  CreateVaultUpload,
} from '@gv/api';
import { createFlowBaseActions } from '@gv/state';
import type {
  UploadType,
  UploadGroupModel,
  FileMetadataModel,
  FullFileMetadataModel,
  VaultStateFileModel,
  FileEncodeModel,
  BaseVideoUploadModel,
  InitVaultUploadsPropsModel,
  InitUploadsPropsModel,
} from '@gv/upload/types';

import type { UploadProgress } from '../uploads.state';

const basename = '[Uploads]';

export const UploadsActions = {
  setCompletedChunks: createAction(
    `${basename} set completed chunks`,
    props<{ uploadGroupUuid: string; uuid: string; completedChunks: number }>(),
  ),

  vault: {
    persistanceService: {
      enable: createAction(`${basename} vault persistance service[enable]`),
      disable: createAction(`${basename} vault persistance service[disable]`),
    },

    restore: {
      init: createAction(
        `${basename} vault upload[restore init]`,
        props<{ userUuid: string }>(),
      ),
      completed: createAction(
        `${basename} vault upload[restore completed]`,
        props<{
          data: readonly UploadGroupModel[];
        }>(),
      ),
    },

    // insert data into state
    init: createAction(
      `${basename} vault upload[init]`,
      props<{
        uploadGroupUuid: string;
        projectUuid: string;
        projectName: string;
        data: InitUploadsPropsModel;
      }>(),
    ),

    encode: {
      init: createAction(
        `${basename} vault upload[encode init]`,
        props<{ uploadGroupUuid: string; uuid: string }>(),
      ),
      started: createAction(
        `${basename} vault upload[encode started]`,
        props<{ uploadGroupUuid: string; uuid: string; presetPath: string }>(),
      ),
      optOut: createAction(
        `${basename} vault upload[encode opt out]`,
        props<{ uploadGroupUuid: string; uuid: string }>(),
      ),
      completed: createAction(
        `${basename} vault upload[encode completed]`,
        props<{
          uploadGroupUuid: string;
          uuid: string;
          data: FileEncodeModel;
        }>(),
      ),

      cleanupFile: createAction(
        `${basename} vault upload[encode cleanup]`,
        props<{
          data: FileEncodeModel;
        }>(),
      ),
    },

    // load video metadata (duration, type) and validate, otherwise show dialog/error etc,
    basicMetadata: {
      init: createAction(
        `${basename} vault upload[load basic metadata init]`,
        props<{ uploadGroupUuid: string; uuid: string }>(),
      ),
      completed: createAction(
        `${basename} vault upload[load basic metadata completed]`,
        props<{
          uploadGroupUuid: string;
          uuid: string;
          data: FileMetadataModel;
        }>(),
      ),
    },

    // calculate hash, (in future maybe some deeper analysis of video file by mediainfo/ffmpeg)
    // this can run after the upload was created (unless presigned urls were requested in advance)
    fullMetadata: {
      init: createAction(
        `${basename} vault upload[load full metadata init]`,
        props<{ uploadGroupUuid: string; uuid: string }>(),
      ),
      completed: createAction(
        `${basename} vault upload[load full metadata completed]`,
        props<{
          uploadGroupUuid: string;
          uuid: string;
          data: FullFileMetadataModel;
        }>(),
      ),
    },

    // obtain presigned urls
    presign: {
      init: createAction(
        `${basename} vault upload[presign init]`,
        props<{ uploadGroupUuid: string; uuid: string }>(),
      ),
      completed: createAction(
        `${basename} vault upload[presign completed]`,
        props<{
          uploadGroupUuid: string;
          uuid: string;
          data: VaultUploadFileDetailModel;
          finishUrl: string;
        }>(),
      ),
    },

    create: {
      init: createAction(
        `${basename} vault upload[create vault]`,
        props<{ uploadGroupId: string; uploadProps: CreateVaultUpload }>(),
      ),
      completed: createAction(
        `${basename} vault upload[create vault completed]`,
        props<{ uploadGroupUuid: string; data: VaultUploadDetailModel }>(),
      ),
      error: createAction(
        `${basename} vault upload[create vault error]`,
        props<{ error: Error; uploadGroupUuid: string }>(),
      ),
    },

    uploadFile: {
      init: createAction(
        `${basename} vault upload[upload file init]`,
        props<{ uploadGroupUuid: string; uuid: string }>(),
      ),
      started: createAction(
        `${basename} vault upload[upload file started]`,
        props<{ uploadGroupUuid: string; uuid: string }>(),
      ),
      completed: createAction(
        `${basename} vault upload[upload file completed]`,
        props<{
          uploadGroupUuid: string;
          uuid: string;
        }>(),
      ),
      failed: createAction(
        `${basename} vault upload[upload file failed]`,
        props<{
          uploadGroupUuid: string;
          uuid: string;
          error: any;
          reason: string;
        }>(),
      ),
      cancelLocally: createAction(
        `${basename} vault upload[upload file cancel locally]`,
        props<{ uuids: string[] }>(),
      ),
      cancel: createFlowBaseActions(
        basename,
        'vault upload[upload file cancel]',
        {
          initProps: props<{
            data: {
              uploadGroupUuid: string;
              uuid: string;
              localOnly: boolean;
            };
          }>(),
          completedProps: props<{
            data: {
              uploadGroupUuid: string;
              uuid: string;
              localOnly: boolean;
              file: VaultStateFileModel;
            };
          }>(),
        },
      ),
    },

    finish: createAction(
      `${basename} vault upload[finish group]`,
      props<{
        uploadGroupUuid: string;
      }>(),
    ),

    cleanup: createAction(
      `${basename} vault upload[cleanup group]`,
      props<{
        uploadGroupUuid: string;
      }>(),
    ),

    cancel: createFlowBaseActions(basename, 'vault upload[cancel group]', {
      initProps: props<{
        data: {
          uploadGroupUuid: string;
          localOnly: boolean;
        };
      }>(),
      completedProps: props<{
        data: {
          uploadGroupUuid: string;
          localOnly: boolean;
          uploadGroup: UploadGroupModel;
        };
      }>(),
    }),

    cancelOnServer: createFlowBaseActions(
      basename,
      'vault upload[cancel group on server]',
      {
        initProps: props<{
          data: {
            uploadId: string;
            type: typeof UploadType.VAULT;
          };
        }>(),
        completedProps: props<{ data: { uploadId: string } }>(),
      },
      true,
    ),

    error: createAction(
      `${basename} vault upload[file error]`,
      props<{
        uploadGroupUuid: string;
        uuid: string;
        error: any;
        reason: string;
      }>(),
    ),

    retry: createAction(
      `${basename} vault upload[file retry]`,
      props<{
        uploadGroupUuid?: string;
        uuid?: string;
        retryDelay?: number;
      }>(),
    ),
  },

  uploadToVault: createAction(
    `${basename} upload to vault`,
    props<{
      uploadProps: readonly InitVaultUploadsPropsModel[];
      projectUuid: string;
      projectName: string;
      showSizeWarning: boolean;
      vaultAction: 'upload-file' | 'upload-folder' | 'drag-n-drop';
    }>(),
  ),

  setProgress: createAction(
    `${basename} setProgress`,
    props<{ uuid: string; progress: UploadProgress }>(),
  ),

  cancelKilledUploads: createAction(
    `${basename} cancel uploads killed by server`,
  ),

  cancelUploadsForUploadGroup: createAction(
    `${basename} cancel uploads on server`,
    props<{
      uploadGroupId: string;
      uploads?: BaseVideoUploadModel[];
    }>(),
  ),

  uploadsForUploadGroupCanceled: createAction(
    `${basename} uploads for upload group canceled`,
    props<{
      uploadGroupId: string;
      uploads?: BaseVideoUploadModel[];
    }>(),
  ),

  removeAllUploads: createAction(`${basename} remove all uploads`),

  reset: createAction(`${basename} reset`),

  persist: createAction(`${basename} persist`),

  removePending: createAction(`${basename} removePending`),

  stopUpload: createAction(
    `${basename} stop upload`,
    props<{
      uuid: string;
    }>(),
  ),

  stopAllUploadsFlow: createFlowBaseActions(basename, 'stop all uploads'),

  stopAllUploadsCrossTabFlow: createFlowBaseActions(
    basename,
    'stop all uploads cross tab',
  ),
};
