import { createAction, props } from '@ngrx/store';
import { createFlowBaseActions } from '@gv/state';
import type { AuthProvider, EditUser } from '@gv/api';

import type { UserModel } from './entity';

const basename = '[User]';
const logoutEverywhereBasename = `${basename} logout everywhere`;
const refreshBasename = `${basename} refresh`;

export const UserActions = {
  logout: createAction(`${basename} logout`),
  requestLogout: createAction(`${basename} request logout`),

  logoutEverywhere: {
    init: createAction(`${logoutEverywhereBasename} init`),
    cancel: createAction(`${logoutEverywhereBasename} cancel`),
    start: createAction(`${logoutEverywhereBasename} start`),
    failed: createAction(`${logoutEverywhereBasename} failed`),
    succeeded: createAction(`${logoutEverywhereBasename} suceeded`),
    completed: createAction(`${logoutEverywhereBasename} completed`),
  },

  refresh: {
    init: createAction(
      `${refreshBasename} init`,
      props<{ debounce: boolean; noUpdate?: boolean; token?: string }>(),
    ),
    cancel: createAction(`${refreshBasename} cancel`),
    failed: createAction(`${refreshBasename} failed`, props<{ error: any }>()),
    canceled: createAction(`${refreshBasename} canceled`),
    completed: createAction(
      `${refreshBasename} completed`,
      props<{
        user: UserModel;
        refreshTimeOffset?: boolean;
        noUpdate?: boolean;
      }>(),
    ),
  },

  refreshUserFlow: createFlowBaseActions(basename, 'user', {
    initProps: props<{
      data: { debounce: boolean; noUpdate?: boolean; token?: string };
    }>(),
    completedProps: props<{ data: UserModel }>(),
  }),

  editDetailFlow: createFlowBaseActions(basename, 'edit', {
    initProps: props<{
      data: { uuid: string; user: EditUser };
    }>(),
    completedProps: props<{ data: UserModel }>(),
  }),

  deleteLoginProviderFlow: createFlowBaseActions(
    basename,
    'delete login provider',
    {
      initProps: props<{
        data: {
          uuid: string;
          type: AuthProvider;
        };
      }>(),
    },
  ),

  editPasswordFlow: createFlowBaseActions(basename, 'edit password', {
    initProps: props<{
      data: {
        uuid: string;
        oldPassword: string;
        password: string;
      };
    }>(),
  }),
};
