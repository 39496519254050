import type { OnDestroy } from '@angular/core';
import { DestroyRef, Injectable, inject } from '@angular/core';

import { untilNgDestroyed } from '@gv/utils';
import type { DateTime } from 'luxon';
import type { Observable } from 'rxjs';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IntervalService } from '@gv/ui/core';

import { ApiService } from '../../api/api.service';
import { MaintenanceDetectorService } from './maintenance-detector.service';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceStateDetectionService implements OnDestroy {
  private destroyRef = inject(DestroyRef);
  private maintenanceDetectorService = inject(MaintenanceDetectorService);
  private intervalService = inject(IntervalService);
  private apiService = inject(ApiService);
  static readonly intervalId: string = 'maintenance-state-detection-check';
  static readonly intervalDuration: number = 30 * 1000; // in ms

  private _lastRetry: DateTime;

  get lastRetry(): DateTime {
    return this._lastRetry;
  }

  set lastRetry(lastRetry: DateTime) {
    if (this._lastRetry !== lastRetry) {
      this._lastRetry = lastRetry;
    }
  }

  private set maintenanceStateDetected(maintenanceStateDetected: boolean) {
    if (
      this.maintenanceStateDetectedSubject.value !== maintenanceStateDetected
    ) {
      this.maintenanceStateDetectedSubject.next(maintenanceStateDetected);
    }
  }

  private maintenanceStateDetectedSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(undefined);

  maintenanceStateDetected$: Observable<boolean> =
    this.maintenanceStateDetectedSubject.asObservable();

  private detectMaintenanceStateSubject: Subject<void> = new Subject<void>();

  private detectMaintenanceState$: Observable<void> =
    this.detectMaintenanceStateSubject.asObservable();

  ngOnDestroy(): void {
    this.disableAutoDetection();
  }

  enableAutoDetection(immediateCheck = true): void {
    this.disableAutoDetection();

    this.maintenanceStateDetected = undefined;

    this.intervalService.setInterval(
      MaintenanceStateDetectionService.intervalId,
      MaintenanceStateDetectionService.intervalDuration,
      () => this.detectMaintenanceState(),
      true,
    );

    if (immediateCheck) {
      this.detectMaintenanceState();
    }
  }

  disableAutoDetection(): void {
    this.intervalService.clearInterval(
      MaintenanceStateDetectionService.intervalId,
    );
  }

  detectMaintenanceState(): void {
    this.detectMaintenanceStateSubject.next();

    this.apiService
      .getApiStatus()
      .pipe(
        untilNgDestroyed(this.destroyRef),
        takeUntil(this.detectMaintenanceState$),
      )
      .subscribe({
        next: () => {
          this.maintenanceStateDetected = false;
        },
        error: (error) => {
          this.maintenanceStateDetected =
            this.maintenanceDetectorService.isMaintenanceError(error);
        },
      });
  }
}
