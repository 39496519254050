import { InjectionToken } from '@angular/core';

export interface CookieService {
  check(name: string): Promise<boolean>;

  get(name: string): Promise<string>;

  getAll(): Promise<Record<string, unknown>>;

  set(
    name: string,
    value: string,
    expires?: number | Date,
    path?: string,
    domain?: string,
    secure?: boolean,
  ): Promise<void>;

  delete(name: string, path?: string, domain?: string): Promise<void>;

  deleteAll(path?: string, domain?: string): Promise<void>;

  getSync(name: string): string;
}

export const COOKIE_SERVICE = new InjectionToken<CookieService>(
  'appCookieService',
);
