import { isObject } from 'lodash-es';

export class SentryError extends Error {
  override name: string;
  [key: string]: unknown;

  constructor(
    name: string,
    message: string,
    public error: unknown,
    public data?: { [key: string]: unknown },
  ) {
    super(message);

    this.name = name || 'UnknownError';

    if (error && isObject(error)) {
      const properties = Object.getOwnPropertyNames(error);
      for (const property of properties) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        this[property] = (error as any)[property];
      }
    }

    Object.setPrototypeOf(this, SentryError.prototype);
  }
}
