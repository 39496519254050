import { isEqual } from 'lodash-es';

import { ObjectUtils } from './object-utils';
import { isObject } from './type-utils';

export enum FilterMode {
  ONLY,
  IGNORE,
}

type Comparator<Base> = {
  [K in keyof Base]?: (a1: Base[K], a2: Base[K]) => boolean;
};

export function isShallowEqualIndexedObject<T>(
  a: { [key: string]: T },
  b: { [key: string]: T },
  comp: (a: T, b: T) => boolean = (i1, i2) => i1 === i2,
): boolean {
  if (a === b) {
    return true;
  }

  if (!a || !b) {
    return false;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if (!isEqual(keysA, keysB)) {
    return false;
  }

  return !keysA.some((key) => !comp(a[key], b[key]));
}

export function isShallowEqualArray<T>(
  a: readonly T[],
  b: readonly T[],
  comp: (a: T, b: T) => boolean = (i1, i2) => i1 === i2,
): boolean {
  if (a === b) {
    return true;
  }

  if (!a || !b) {
    return false;
  }

  if (a.length !== b.length) {
    return false;
  }

  return !a.some((item, index) => !comp(item, b[index]));
}

export function isShallowEqualObj<
  // eslint-disable-next-line @typescript-eslint/ban-types
  A extends object,
  K extends keyof A & string,
>(
  a: A,
  b: A,
  fields?: readonly K[],
  fieldsComparators?: Comparator<A>,
  mode: FilterMode = FilterMode.IGNORE,
): boolean {
  if (a === b) {
    return true;
  }

  if (!a || !b) {
    return false;
  }

  let keysA: K[] = Object.keys(a) as K[];
  let keysB: K[] = Object.keys(b) as K[];

  if (fields) {
    const f =
      mode === FilterMode.IGNORE ? (c: boolean) => !c : (c: boolean) => c;
    keysA = keysA.filter((k) => f(fields.includes(k)));
    keysB = keysB.filter((k) => f(fields.includes(k)));
  }

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (const key of keysA) {
    const comparator =
      fieldsComparators && ObjectUtils.hasProperty(fieldsComparators, key)
        ? fieldsComparators[key]
        : undefined;

    if (comparator) {
      if (!comparator(a[key], b[key])) {
        return false;
      }
    }

    if (!Object.prototype.hasOwnProperty.call(b, key) || a[key] !== b[key]) {
      return false;
    }
  }

  return true;
}

export function isShallowEqual<T>(a: T, b: T): boolean {
  if (a === b) {
    return true;
  }

  if (typeof a !== typeof b) {
    return false;
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    return isShallowEqualArray(a, b);
  }

  if (isObject(a) && isObject(b)) {
    return isShallowEqualObj(a, b);
  }

  return false;
}
