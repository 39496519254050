import { asMutable, createSubStateReducerFns } from '@gv/state';
import { UserActions } from '@gv/user';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type { Draft } from 'immer';
import { produce } from 'immer';
import { zonedNow } from '@gv/utils';

import * as BillingActions from './actions';
import type { BillingState } from './state';
import { initialBillingState } from './state';

const reducer = createReducer(
  initialBillingState,

  ...createSubStateReducerFns(
    BillingActions.paymentInfo,
    (state: Draft<BillingState>) => state.paymentInfo,
    (_, item, dtSent) => {
      return { ...item, dtSent };
    },
    $localize`:@@reducers.failed-to-load-payment-info:Failed to load credits info`,
  ),

  ...createSubStateReducerFns(
    BillingActions.platformPlansInfo,
    (state: Draft<BillingState>) => state.platformPlansInfo,
    (_, item, dtSent) => {
      return { ...item, dtSent };
    },
    $localize`:@@reducers.failed-to-load-plans-info:Failed to load platform plans info`,
  ),

  ...createSubStateReducerFns(
    BillingActions.nextPayment,
    (state: Draft<BillingState>) => state.nextPayment,
    (_, item, dtSent) => ({ ...item, dtSent }),
    $localize`:@@reducers.failed-to-load-next-payment:Failed to load next payment`,
  ),

  ...createSubStateReducerFns(
    BillingActions.subscription,
    (state: Draft<BillingState>) => state.subscription,
    (_, item, dtSent) => ({ ...item, dtSent }),
    $localize`:@@reducers.failed-to-load-subscription:Failed to load subscription`,
  ),

  ...createSubStateReducerFns(
    BillingActions.billingInfo,
    (state: Draft<BillingState>) => state.billingInfo,
    (_, item, dtSent) => ({ ...item, dtSent }),
    $localize`:@@reducers.failed-to-load-billing-info:Failed to load billing info`,
  ),

  ...createSubStateReducerFns(
    BillingActions.organization,
    (state: Draft<BillingState>) => state.organization,
    (_, item, dtSent) => ({ ...item, dtSent }),
    $localize`:@@reducers.failed-to-load-organization:Failed to load organization`,
  ),

  ...createSubStateReducerFns(
    BillingActions.invoicesHistory._,
    (state: Draft<BillingState>) => state.invoicesHistory,
    (_, item, dtSent) => ({ ...item, dtSent }),
    $localize`:@@reducers.failed-to-load-invoices-history:Failed to load invoices history`,
  ).filter(
    (f) =>
      !f.types.includes(BillingActions.invoicesHistory._.fetch.completed.type),
  ),

  on(BillingActions.invoicesHistory.fetch.completed, (state, action) => {
    return produce(state, (draft) => {
      const subState = draft.invoicesHistory;

      if (!action.context) {
        const data = { ...action.data, dtSent: action.dtSent };
        subState.data = {
          ...asMutable(data),
        };
      } else {
        subState.data.invoices = subState.data.invoices.concat(
          asMutable(action.data.invoices),
        );
        subState.data.dtSent = action.dtSent;
        subState.data.limit = action.data.limit;
        subState.data.count = action.data.count;
        subState.data.offset = action.data.offset;
      }

      subState.loading = false;
      subState.freshness = action.dtSent;
      subState.dirty = false;
      subState.loaded = true;
      subState.error = undefined;
    });
  }),

  ...createSubStateReducerFns(
    BillingActions.priceOffersHistory._,
    (state: Draft<BillingState>) => state.priceOffersHistory,
    (_, item, dtSent) => ({ ...item, dtSent }),
    $localize`:@@reducers.failed-to-load-price-offers-history:Failed to load price offers history`,
  ).filter(
    (f) =>
      !f.types.includes(
        BillingActions.priceOffersHistory._.fetch.completed.type,
      ),
  ),

  on(BillingActions.priceOffersHistory.fetch.completed, (state, action) => {
    return produce(state, (draft) => {
      const subState = draft.priceOffersHistory;

      if (!action.context) {
        const data = { ...action.data, dtSent: action.dtSent };
        subState.data = {
          ...asMutable(data),
        };
      } else {
        subState.data.priceOffers = subState.data.priceOffers.concat(
          asMutable(action.data.priceOffers),
        );
        subState.data.dtSent = action.dtSent;
        subState.data.limit = action.data.limit;
        subState.data.count = action.data.count;
        subState.data.offset = action.data.offset;
      }

      subState.loading = false;
      subState.freshness = action.dtSent;
      subState.dirty = false;
      subState.loaded = true;
      subState.error = undefined;
    });
  }),

  ...createSubStateReducerFns(
    BillingActions.proFormaInvoicesHistory._,
    (state: Draft<BillingState>) => state.proFormaInvoicesHistory,
    (_, item, dtSent) => ({ ...item, dtSent }),
    $localize`:@@reducers.failed-to-load-pro-forma-invoices-history:Failed to load pro forma invoices history`,
  ).filter(
    (f) =>
      !f.types.includes(
        BillingActions.proFormaInvoicesHistory._.fetch.completed.type,
      ),
  ),

  on(
    BillingActions.proFormaInvoicesHistory.fetch.completed,
    (state, action) => {
      return produce(state, (draft) => {
        const subState = draft.proFormaInvoicesHistory;

        if (!action.context) {
          const data = { ...action.data, dtSent: action.dtSent };
          subState.data = {
            ...asMutable(data),
          };
        } else {
          subState.data.proFormaInvoices =
            subState.data.proFormaInvoices.concat(
              asMutable(action.data.proFormaInvoices),
            );
          subState.data.dtSent = action.dtSent;
          subState.data.limit = action.data.limit;
          subState.data.count = action.data.count;
          subState.data.offset = action.data.offset;
        }

        subState.loading = false;
        subState.freshness = action.dtSent;
        subState.dirty = false;
        subState.loaded = true;
        subState.error = undefined;
      });
    },
  ),

  ...createSubStateReducerFns(
    BillingActions.creditHistory._,
    (state: Draft<BillingState>) => state.creditHistory,
    (_, item, dtSent) => ({ ...item, dtSent }),
    $localize`:@@reducers.failed-to-load-credit-history:Failed to load credit transactions`,
  ).filter(
    (f) =>
      !f.types.includes(BillingActions.creditHistory._.fetch.completed.type),
  ),

  on(BillingActions.creditHistory.fetch.completed, (state, action) => {
    return produce(state, (draft) => {
      const subState = draft.creditHistory;

      if (!action.context) {
        const data = { ...action.data, dtSent: action.dtSent };
        subState.data = {
          ...asMutable(data),
        };
      } else {
        subState.data.history = subState.data.history.concat(
          asMutable(action.data.history),
        );
        subState.data.dtSent = action.dtSent;
        subState.data.limit = action.data.limit;
        subState.data.count = action.data.count;
        subState.data.offset = action.data.offset;
      }

      subState.loading = false;
      subState.freshness = action.dtSent;
      subState.dirty = false;
      subState.loaded = true;
      subState.error = undefined;
    });
  }),

  on(
    BillingActions.billingInfo.updatePaymentMethod,
    (state, action): BillingState => ({
      ...state,
      billingInfo: {
        ...state.billingInfo,
        data: {
          ...state.billingInfo.data,
          paymentMethod: action.data,
        },
      },
    }),
  ),

  on(
    BillingActions.fetchAppThemes.init,
    (state): BillingState => ({
      ...state,
      appThemes: {
        ...state.appThemes,
        loading: true,
      },
    }),
  ),

  on(
    BillingActions.fetchAppThemes.succeeded,
    (state, action): BillingState => ({
      ...state,
      appThemes: {
        ...state.appThemes,
        data: action.appThemes,
      },
    }),
  ),

  on(
    BillingActions.fetchAppThemes.failed,
    (state): BillingState => ({
      ...state,
      appThemes: {
        ...state.appThemes,
        loaded: false,
      },
    }),
  ),

  on(
    BillingActions.fetchAppThemes.completed,
    (state): BillingState => ({
      ...state,
      appThemes: {
        ...state.appThemes,
        loading: false,
      },
    }),
  ),

  on(
    BillingActions.organization.createTemporaryTheme,
    (state, action): BillingState => {
      return {
        ...state,
        temporaryTheme: action.data || state.temporaryTheme || undefined,
      };
    },
  ),

  on(
    BillingActions.organization.resetTemporaryTheme,
    (state): BillingState => ({
      ...state,
      temporaryTheme: undefined,
    }),
  ),

  on(
    BillingActions.setFilterRange,
    (state, action): BillingState =>
      produce(state, (draft) => {
        draft.invoicesHistory.range = action.range;
        draft.proFormaInvoicesHistory.range = action.range;
        draft.priceOffersHistory.range = action.range;
        draft.creditHistory.range = action.range;
      }),
  ),

  on(
    BillingActions.creditHistory.fetch.init,
    BillingActions.proFormaInvoicesHistory.fetch.init,
    BillingActions.priceOffersHistory.fetch.init,
    BillingActions.creditHistory.fetch.init,
    (state, action): BillingState =>
      produce(state, (draft) => {
        if (action.data) {
          return;
        }
        const newDate = zonedNow('default');
        const fields = [
          'invoicesHistory',
          'proFormaInvoicesHistory',
          'priceOffersHistory',
          'creditHistory',
        ] as const;
        for (const field of fields) {
          draft[field].noFilterEnd = newDate;
          if (!draft[field].range) {
            draft[field].dirty = true;
          }
        }
      }),
  ),

  on(BillingActions.organization.setMemberStatus, (state, action) => {
    return produce(state, (draft) => {
      const members = draft.organization.data?.members;
      if (!members) {
        return;
      }

      const member = members.find((f) => f.uuid === action.data.userUuid);
      if (!member) {
        return;
      }

      member.onlineStatus = action.data.status;
    });
  }),

  on(BillingActions.promoCode.submitting, (state, action) =>
    state.applyingPromoCode === action.value
      ? state
      : { ...state, applyingPromoCode: action.value },
  ),

  on(BillingActions.promoCode.error, (state, action) =>
    state.promoCodeError === action.value
      ? state
      : { ...state, promoCodeError: action.value },
  ),

  on(
    BillingActions.billingInfo.updateBillingAddress.completed,
    (state, action): BillingState =>
      !action.data
        ? state
        : {
            ...state,
            billingInfo: {
              ...state.billingInfo,
              data: {
                ...state.billingInfo.data,
                billingAddress: action?.data?.model,
              },
            },
          },
  ),

  on(UserActions.logout, () => initialBillingState),
);

export function billingReducer(
  state: BillingState | undefined,
  action: Action,
): BillingState {
  return reducer(state, action);
}
