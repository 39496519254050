import type { OnDestroy } from '@angular/core';
import { inject, Injectable } from '@angular/core';

import { logger } from '../logger';
import { ElectronRefService } from './electron-ref.service';
import type { ElectronAppInfoModel } from './entity/electron-app-info-model';

@Injectable()
export class ElectronAppInfoService implements OnDestroy {
  private electronRef = inject(ElectronRefService);
  private initialized = false;

  private _appInfo!: ElectronAppInfoModel;

  get appInfo(): ElectronAppInfoModel {
    return this._appInfo;
  }

  ngOnDestroy(): void {
    //
  }

  async initialize(): Promise<void> {
    if (this.initialized) {
      return;
    }

    try {
      this._appInfo = await this.electronRef.api.app.info();
      this.initialized = true;
    } catch (error) {
      logger.error({ error }, 'Failed to load desktop app info');
    }
  }
}
