import { Injectable, NgZone, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { createDialogEffect } from '@gv/state';
import { Actions } from '@ngrx/effects';
import {
  exhaustMap,
  filter,
  ignoreElements,
  mergeMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { APP_UPDATE_CHECKER_SERVICE } from '@gv/ui/utils';

import { LanguageMismatchDialogComponent } from '../../../component/dialog/language-mismatch/language-mismatch-dialog.component';
import { UpdateDialogComponent } from '../../../component/dialog/update/update-dialog/update-dialog.component';
import { DialogActions } from '../../action/dialog.actions';

@Injectable()
export class UtilDialogEffects {
  private actions$ = inject<Actions>(Actions);
  private dialog = inject(MatDialog);
  private updateService = inject(APP_UPDATE_CHECKER_SERVICE);
  private ngZone = inject(NgZone);
  updateDialogFlow$ = createDialogEffect(
    ({ actions$, openDialog, dialogActionsEmitter }) =>
      actions$.pipe(
        exhaustMap((action) => {
          const dialogRef = openDialog(action, {
            autoFocus: false,
            disableClose: true,
            width: '290px',
          });

          const onSubmit$ = dialogRef.componentInstance.onSubmit$.pipe(
            tap(() => void this.updateService.downloadUpdate()),
            ignoreElements(),
            takeUntil(dialogRef.afterClosed()),
          );

          const onClose$ = dialogRef.afterClosed().pipe(
            take(1),
            filter((reload) => reload === true),
            tap(() => void this.updateService.activateUpdate()),
            ignoreElements(),
          );

          return dialogActionsEmitter(
            { action, dialogRef, cleanup: false },
            onSubmit$,
            onClose$,
          );
        }),
      ),
    DialogActions.updateAppDialog,
    UpdateDialogComponent,
    { actions$: this.actions$, dialog: this.dialog, ngZone: this.ngZone },
  );

  languageMismatchDialog$ = createDialogEffect(
    ({ actions$, dialogActionsEmitter, openDialog }) =>
      actions$.pipe(
        mergeMap((action) => {
          const dialogRef = openDialog(action, {
            autoFocus: false,
            disableClose: true,
            width: '800px',
            data: action.data,
          });

          return dialogActionsEmitter({ action, dialogRef });
        }),
      ),
    DialogActions.languageMismatchDialog,
    LanguageMismatchDialogComponent,
  );
}
