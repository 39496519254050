import type { OnDestroy } from '@angular/core';
import { DestroyRef, Injectable, Injector, inject } from '@angular/core';
import type { NavigationExtras, UrlTree } from '@angular/router';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { isUndefinedOrNull, untilNgDestroyed } from '@gv/utils';
import type { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouterHelperService implements OnDestroy {
  private destroyRef = inject(DestroyRef);
  private injector = inject(Injector);
  private static readonly blackListedUrlForRedirectionAfterLogin = [
    '/',
    '/login',
    '/logout',
  ];

  /**
   * Router service shortcut.
   * DI cannot be used because of circular dependency.
   */
  private get router(): Router {
    return this.injector.get(Router);
  }

  /**
   * ActivatedRoute service shortcut.
   * DI cannot be used because of circular dependency.
   */
  private get activatedRoute(): ActivatedRoute {
    return this.injector.get(ActivatedRoute);
  }

  private _previousUrl: string;
  get previousUrl(): string {
    return this._previousUrl;
  }

  private currentUrl: string;

  private navigationEndSubscription: Subscription | undefined;

  ngOnDestroy(): void {
    this.destroyNavigationEndSubscription();
  }

  enable(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        untilNgDestroyed(this.destroyRef),
      )
      .subscribe((event: NavigationEnd) => {
        this._previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      });
  }

  /**
   * Returns current URL for use in login page where user
   * should be redirected to the original URL after successfull login.
   */
  getCurrentUrlForRedirectionAfterLogin(): string {
    const urlTree: UrlTree = this.router.url
      ? this.router.parseUrl(this.router.url)
      : undefined;

    let redirectUrl = this.router.url
      ? this.router.url.split('?')[0]
      : this.router.url;

    if (
      urlTree &&
      !isUndefinedOrNull(urlTree.queryParams['r']) &&
      typeof urlTree.queryParams['r'] === 'string' &&
      urlTree.queryParams['r'].trim() !== ''
    ) {
      redirectUrl = urlTree.queryParams['r'].trim();
    }

    return RouterHelperService.blackListedUrlForRedirectionAfterLogin.find(
      (url) => url === redirectUrl,
    ) === undefined
      ? redirectUrl
      : undefined;
  }

  /**
   * Works like native Router.navigate()
   * but keeps rest of current url segments untouched.
   *
   * @param commands
   * @param extras
   */
  navigateWithOverrides(
    commands: string[],
    extras?: NavigationExtras,
  ): Promise<boolean> {
    const adjustedCommands = this.overrideSegments(commands, extras);
    if (extras) {
      return this.router.navigate(adjustedCommands, extras);
    }
    return this.router.navigate(adjustedCommands);
  }

  private overrideSegments(
    overrideSegments: string[],
    extras?: NavigationExtras,
  ): string[] {
    const allSegments = this.router.parseUrl(this.router.url);

    let skipToIndex = -1;

    if (extras && extras.relativeTo) {
      let route = this.activatedRoute.root;
      while (route) {
        skipToIndex++;

        if (route === extras.relativeTo) {
          break;
        }
        route = route.firstChild;
      }
    }

    const outputSegments = [];

    if (allSegments.root.children['primary']) {
      const segments = allSegments.root.children['primary'].segments
        .filter((_, index) => {
          return index > skipToIndex;
        })
        .map((s) => {
          return s.path;
        });

      segments.forEach((segment, index) => {
        if (overrideSegments[index] !== undefined) {
          outputSegments.push(overrideSegments[index]);
        } else {
          outputSegments.push(segment);
        }
      });
    } else {
      overrideSegments.forEach((segment) => {
        outputSegments.push(segment);
      });
    }

    return outputSegments;
  }

  private destroyNavigationEndSubscription(): void {
    if (this.navigationEndSubscription) {
      this.navigationEndSubscription.unsubscribe();
      this.navigationEndSubscription = undefined;
    }
  }
}
