import { Injectable, NgZone, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ApiErrorResponse } from '@gv/api';
import { ErrorsActions } from '@gv/error';
import { NotificationsService } from '@gv/notification';
import {
  BASE_PERMISSION_SERVICE,
  Permission,
  PermissionScope,
} from '@gv/permission';
import {
  createFlowEffect,
  isActionOfType,
  onFlowFinished,
  StoreInject,
} from '@gv/state';
import { BillingActions, credit, detail, DetailActions } from '@gv/ui/billing';
import { SnackBarActions } from '@gv/ui/toaster';
import { DeviceActions } from '@gv/ui/edge';
import type { UserModel } from '@gv/user';
import { getTokenUserUuid, UserActions } from '@gv/user';
import {
  finalizeWhenEmpty,
  retryOnNetworkError,
  shallowDistinctUntilKeysChanged,
} from '@gv/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { cloneDeep } from 'lodash-es';
import { concat, EMPTY, merge, of, throwError, timer } from 'rxjs';
import {
  catchError,
  debounce,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mergeMap,
  pairwise,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { UploadsActions } from '@gv/upload/state';

import { logger } from '../../../logger';
import { LogoutEverywhereDialogComponent } from '../../component/dialog/auth/logout-everywhere-dialog/logout-everywhere-dialog.component';
import { APP_CONFIG } from '../../entity/token/app.config';
import { ApiService } from '../../service/api/api.service';
import { UserService as UserApiService } from '../../service/api/user.service';
import { PreventActivationDuringUploadGuard } from '../../service/application/routing/guard/uploading/prevent-activation-during-upload.guard';
import { RouterHelperService } from '../../service/application/routing/router-helper.service';
import { TokenStoreService } from '../../service/application/user/token-store.service';
import { UserService } from '../../service/application/user/user.service';
import { APP_STATE } from '../state/app-state';

@Injectable()
export class UserEffects {
  private appConfig = inject(APP_CONFIG);
  private store = inject(StoreInject(APP_STATE));
  private actions$ = inject<Actions>(Actions);
  private userApiService = inject(UserApiService);
  private apiService = inject(ApiService);
  private userService = inject(UserService);
  private tokenStoreService = inject(TokenStoreService);
  private routerHelper = inject(RouterHelperService);
  private notificationsService = inject(NotificationsService);
  private router = inject(Router);
  private dialog = inject(MatDialog);
  private ngZone = inject(NgZone);
  private permissionService = inject(BASE_PERMISSION_SERVICE);
  private static readonly userRefreshDebounceTime: number = 1000;

  refreshInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.refresh.init),
      debounce((action) =>
        action.debounce
          ? timer(UserEffects.userRefreshDebounceTime)
          : of(undefined),
      ),
      switchMap((action) => {
        const uuid = getTokenUserUuid(
          action.token || this.tokenStoreService.token,
        );

        const cancelReceived$ = action.token
          ? EMPTY
          : this.actions$.pipe(ofType(UserActions.refresh.cancel));

        const { maxWaitTime, waitTime } = this.appConfig.api.retry;
        // pass token only if not default one should be used
        return this.apiService.api
          .getOwnUserDetail(
            !action.token
              ? undefined
              : { headers: { authorization: `Bearer ${action.token}` } },
          )
          .pipe(
            retryOnNetworkError(waitTime, maxWaitTime),

            mergeMap((response) => {
              const user: UserModel = {
                ...response.data,
                uuid,
                dtSent: response.dtSent,
              };

              this.userService.setLanguageCookie(user?.language);

              return of(
                UserActions.refresh.completed({
                  user,
                  refreshTimeOffset: true,
                  noUpdate: action.noUpdate,
                }),
              );
            }),
            catchError((error) => {
              logger.error({ error }, 'Failed to refresh user');
              return of(
                UserActions.refresh.failed(error),
                ErrorsActions.generic({
                  action,
                  error,
                  log: true,
                  logToSentry: true,
                }),
              );
            }),
            finalizeWhenEmpty((t) => {
              if (t === 'complete') {
                this.store.dispatch(UserActions.refresh.canceled());
              }
            }),
            takeUntil(cancelReceived$),
          );
      }),
    ),
  );

  refreshCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.refresh.completed),
        tap((action) => {
          if (!action.noUpdate) {
            this.userService.setUser(
              cloneDeep(action.user),
              action.refreshTimeOffset,
            );
          }
        }),
      ),
    { dispatch: false },
  );

  refreshUserFlow$ = createFlowEffect(({ action }) => {
    return merge(
      this.actions$.pipe(
        ofType(
          UserActions.refresh.completed,
          UserActions.refresh.failed,
          UserActions.refresh.canceled,
        ),
        take(1),
        mergeMap((completeAction) =>
          isActionOfType(completeAction, UserActions.refresh.failed)
            ? throwError(() => completeAction.error)
            : isActionOfType(completeAction, UserActions.refresh.completed)
              ? of(completeAction.user)
              : EMPTY,
        ),
      ),
      of(
        UserActions.refresh.init({
          debounce: action.data.debounce,
          token: action.data.token,
          noUpdate: action.data.noUpdate,
        }),
      ),
    );
  }, UserActions.refreshUserFlow);

  logoutEverywhereInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.logoutEverywhere.init),
      switchMap(() => {
        const dialogRef = this.dialog.open<
          LogoutEverywhereDialogComponent,
          void,
          void
        >(LogoutEverywhereDialogComponent, {
          autoFocus: false,
          width: '600px',
        });

        const logoutEverywhereResult$ = this.actions$.pipe(
          ofType(
            UserActions.logoutEverywhere.cancel,
            UserActions.logoutEverywhere.start,
          ),
          take(1),
        );

        return logoutEverywhereResult$.pipe(
          switchMap((logoutEverywhereAction) => {
            // is logout everywhere action cancelled?
            if (
              isActionOfType(
                logoutEverywhereAction,
                UserActions.logoutEverywhere.cancel,
              )
            ) {
              dialogRef.close();
              return EMPTY;
            }

            // logout everywhere confirmed

            const action = UploadsActions.stopAllUploadsCrossTabFlow.init({});

            const allUploadsCrossTabStopped$ = this.actions$.pipe(
              onFlowFinished(action, UploadsActions.stopAllUploadsCrossTabFlow),
            );

            this.store.dispatch(action);

            dialogRef.componentInstance.startSubmitting();
            return allUploadsCrossTabStopped$.pipe(
              switchMap(() => {
                return this.userApiService.logoutUserEverywhere().pipe(
                  take(1),
                  switchMap(() => {
                    dialogRef.close();

                    return of(UserActions.logoutEverywhere.succeeded());
                  }),
                  catchError((error) => {
                    dialogRef.close();

                    return of(
                      UserActions.logoutEverywhere.failed(),
                      ErrorsActions.generic({
                        action: logoutEverywhereAction,
                        error,
                        log: true,
                        logToSentry: true,
                        snackbarMessage: {
                          message: $localize`:@@user.logout-everywhere-failed:Failed to logout everywhere.`,
                          config: {
                            duration:
                              this.appConfig.api.errorMessage.defaultTimeout,
                          },
                        },
                      }),
                    );
                  }),
                );
              }),
              finalize(() => {
                this.store.dispatch(UserActions.logoutEverywhere.completed());
                dialogRef.componentInstance.stopSubmitting();
              }),
            );
          }),
        );
      }),
    ),
  );

  logoutEverywhereSucceeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.logoutEverywhere.succeeded),
        switchMap(() => {
          this.ngZone.run(() => {
            this.router.navigate(['/logout'], {
              queryParams: {
                r: this.routerHelper.getCurrentUrlForRedirectionAfterLogin(),
                [PreventActivationDuringUploadGuard.forceActivationQueryParameterName]: 1,
              },
              queryParamsHandling: 'merge',
              skipLocationChange: true,
            });
          });

          return EMPTY;
        }),
      ),
    {
      dispatch: false,
    },
  );

  refreshVaultInfoWhenUserUuidChanges$ = createEffect(() =>
    this.userService.userUuid$.pipe(
      filter((userUuid) => !!userUuid),
      mergeMap(() =>
        of(
          DetailActions.detail.fetch.init({
            skipWhenLoaded: false,
            debounce: false,
          }),
          credit.refresh({}),
        ),
      ),
    ),
  );

  seatRemoved$ = createEffect(
    () =>
      this.userService.user$.pipe(
        filter((u) => !!u),
        shallowDistinctUntilKeysChanged(['uuid']),
        switchMap(() =>
          this.permissionService
            .isAllowed$(Permission.Platform, PermissionScope.All)
            .pipe(
              distinctUntilChanged(),
              startWith(false),
              pairwise(),
              filter(([prev, cur]) => prev && !cur),
              tap(() => {
                if (this.router.url !== '/app/welcome') {
                  this.router.navigate([this.router.url], { replaceUrl: true });
                }
              }),
            ),
        ),
      ),
    { dispatch: false },
  );

  moduleActiveA$ = this.notificationsService.createEffect({
    obs: this.userService.userUuid$,
    data: (uuid) => [{ userUuid: uuid }],
    type: 'MODULE_ACTIVE',
    action: () => [
      UserActions.refresh.init({ debounce: true }),
      BillingActions.subscription.refresh({}),
      BillingActions.billingInfo.refresh({}),
      BillingActions.proFormaInvoicesHistory.refresh({}),
      BillingActions.invoicesHistory.refresh({}),
      BillingActions.priceOffersHistory.refresh({}),
      BillingActions.paymentInfo.refresh({}),
      BillingActions.nextPayment.refresh({}),
      detail.refresh({}),
    ],
  });

  userUpdated$ = this.notificationsService.createEffect({
    obs: this.userService.userUuid$,
    data: (uuid) => [{ userUuid: uuid }],
    type: 'USER_UPDATED_NOTIFICATION',
    action: () => [
      UserActions.refresh.init({ debounce: true }),
      BillingActions.subscription.refresh({}),
      BillingActions.billingInfo.refresh({}),
      BillingActions.proFormaInvoicesHistory.refresh({}),
      BillingActions.invoicesHistory.refresh({}),
      BillingActions.priceOffersHistory.refresh({}),
      BillingActions.paymentInfo.refresh({}),
      BillingActions.nextPayment.refresh({}),
      detail.refresh({}),
    ],
  });

  onLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.logout),
      map(() => DeviceActions.reset()),
    ),
  );

  editUser$ = createFlowEffect(({ action }) => {
    return this.apiService.api
      .updateUserDetail(action.data.uuid, action.data.user)
      .pipe(
        map((response) => ({
          ...response.data,
          uuid: action.data.uuid,
          dtSent: response.dtSent,
        })),
        catchError((error: unknown) =>
          concat(
            of(
              SnackBarActions.show({
                message:
                  (error instanceof ApiErrorResponse &&
                    error?.apiMessage?.message) ||
                  $localize`:@@user.failed-to-edit-user:Failed to update user settings`,
                config: { duration: 3000 },
              }),
            ),
            throwError(() => error),
          ),
        ),
      );
  }, UserActions.editDetailFlow);

  editCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.editDetailFlow.completed),
        tap((action) => {
          if (!action.noUpdate) {
            this.userService.setUser(cloneDeep(action.data));
          }
        }),
      ),
    { dispatch: false },
  );
}
