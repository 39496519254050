import type { OnDestroy } from '@angular/core';
import {
  Injectable,
  Injector,
  NgZone,
  inject,
  DestroyRef,
} from '@angular/core';

import type { AuthenticationTokenDataModel, TokenRole } from '@gv/api';
import { ApiErrorResponse, AuthenticationTokenType } from '@gv/api';
import { ElectronRefService } from '@gv/desktop/core';
import { APP_INSTANCE_ID, IndexedDBService } from '@gv/ui/core';
import { decodeToken } from '@gv/user';
import {
  delayedConcatMap,
  enterZone,
  leaveZone,
  ObservableUtils,
  retryOnNetworkError,
  shallowDistinctUntilChanged,
  simpleSwitchMap,
  toSentryError,
  untilNgDestroyed,
} from '@gv/utils';
import { captureException, withScope } from '@sentry/angular';
import { jwtDecode } from 'jwt-decode';
import { isEqual, isString } from 'lodash-es';
import type { Observable, ObservedValueOf } from 'rxjs';
import {
  asyncScheduler,
  BehaviorSubject,
  EMPTY,
  from,
  merge,
  of,
  queueScheduler,
  Subject,
  timer,
} from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  defaultIfEmpty,
  delay,
  delayWhen,
  distinctUntilChanged,
  exhaustMap,
  filter,
  finalize,
  map,
  mergeMap,
  observeOn,
  share,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import type { CrossTabExecuteCompletedMessageModel } from '@gv/crosstab';
import {
  CrossTabChannelService,
  CrossTabWorkerMessageType,
} from '@gv/crosstab';

import { logger } from '../../../../logger';
import { APP_CONFIG } from '../../../entity/token/app.config';
import { ApiService } from '../../api/api.service';
import { DebugModeService } from '../../helper/debug-mode.service';
import { BaseStateService } from '../state/base-state.service';
import { WakeupService } from '../wakeup/wakeup-service';
import { CrossTabWorkerService } from '../cross-tab-worker.service';

interface TokenObj {
  readonly data: string;
  readonly dt: number;
}
interface TokenState {
  readonly token: TokenObj;
  readonly refreshing: boolean;
  readonly saving: boolean;
  readonly loaded: boolean;
  readonly timeOffset: number;
  readonly decodedToken: AuthenticationTokenDataModel;
  readonly error:
    | {
        readonly waitingForExternal: boolean;
        readonly date: Date;
      }
    | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class TokenStoreService
  extends BaseStateService<TokenState>
  implements OnDestroy
{
  private destroyRef = inject(DestroyRef);
  private apiService = inject(ApiService);
  private wakeupService = inject(WakeupService);
  private debugService = inject(DebugModeService);
  private ngZone = inject(NgZone);
  private appConfig = inject(APP_CONFIG);
  private crossTabChannel = inject(CrossTabChannelService);
  private appInstanceId = inject(APP_INSTANCE_ID);
  private indexedDbService = inject(IndexedDBService);
  private crossTabWorker = inject(CrossTabWorkerService);
  private injector = inject(Injector);
  static readonly tokenStatus: string = '__token_status';
  static readonly crossTabEvent: string = '__gv_token_updated';

  static readonly className: string = 'TokenStore';
  private static readonly tokenKeyName = '__gv_auth_token' as const;
  private static readonly tokenKeyDtName = '__gv_auth_token_dt' as const;

  public static readonly tokenRefreshCheckIntervalDuration: number = 30_000;

  refreshTokenSubject = new Subject<void>();

  readonly token$: Observable<TokenObj> = this.state$.pipe(
    filter(({ loaded }) => loaded),
    map(({ token }) => token),
    shallowDistinctUntilChanged(),
  );

  readonly timeOffset$: Observable<number> = this.state$.pipe(
    filter(({ loaded }) => loaded),
    map(({ timeOffset }) => timeOffset),
    shallowDistinctUntilChanged(),
  );

  readonly decodedToken$ = this.token$.pipe(
    map((token) => (token.data && decodeToken(token.data)) || undefined),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly tokenRole$: Observable<TokenRole | undefined> = this.token$.pipe(
    map((token) => (token.data && decodeToken(token.data)?.role) || undefined),
    shallowDistinctUntilChanged(),
  );

  readonly synchronizeTokenInStore$ = this.state$.pipe(
    filter(({ loaded }) => loaded),
    take(1),
    switchMap(() =>
      this.token$.pipe(
        concatMap((token) => {
          this.patchState({ saving: true });

          return from(this.storeToken(token)).pipe(
            finalize(() => this.patchState({ saving: false })),
          );
        }),
      ),
    ),
  );

  private waitingForExternalRefresh$ = this.state$.pipe(
    map((state) => !!state.error?.waitingForExternal),
    distinctUntilChanged(),
  );

  readonly tokenInvalidated$ = this.waitingForExternalRefresh$.pipe(
    filter((v) => v),
    switchMap(() =>
      of(undefined).pipe(
        mergeMap(() =>
          this.crossTabWorker
            .executeForAll(TokenStoreService.tokenStatus, undefined)
            .pipe(
              defaultIfEmpty(
                undefined as ObservedValueOf<
                  ReturnType<
                    (typeof CrossTabWorkerService.prototype)['executeForAll']
                  >
                >,
              ),
            ),
        ),
        delayWhen((res) => {
          const dataMessages = (res || []).filter(
            (f): f is CrossTabExecuteCompletedMessageModel<boolean> =>
              f.type === CrossTabWorkerMessageType.ExecuteCompleted,
          );

          if (dataMessages.every((f) => !f.data?.data)) {
            return of(undefined).pipe(delay(0));
          }

          return of(undefined).pipe(delay(8000));
        }),
        tap(() =>
          setTimeout(() =>
            this.setToken({ data: undefined, dt: Date.now() }, undefined),
          ),
        ),
        takeUntil(this.waitingForExternalRefresh$.pipe(filter((s) => !s))),
      ),
    ),
    share(),
    untilNgDestroyed(),
  );

  private refreshingEnabledSubject = new BehaviorSubject<boolean>(false);

  private onWakeUp$ = this.refreshingEnabledSubject.pipe(
    simpleSwitchMap(() => this.wakeupService.wakeup$, EMPTY),
    untilNgDestroyed(),
  );

  private refreshInterval$ = this.refreshingEnabledSubject.pipe(
    distinctUntilChanged(),
    simpleSwitchMap(
      () =>
        timer(
          TokenStoreService.tokenRefreshCheckIntervalDuration,
          TokenStoreService.tokenRefreshCheckIntervalDuration,
          leaveZone(this.ngZone, asyncScheduler),
        ),
      EMPTY,
    ),
  );

  private refresh$ = merge(
    this.refreshTokenSubject,
    this.onWakeUp$,
    this.refreshInterval$,
  ).pipe(
    delayedConcatMap(() => [this.state$]),
    filter(([, state]) => {
      return (
        !state.error?.waitingForExternal &&
        this.shouldRefreshToken(state.token?.data, state.timeOffset)
      );
    }),
    exhaustMap(([, state]) => {
      this.patchState({ refreshing: true });
      return this.apiService
        .withDirect(() =>
          this.apiService.api.refreshToken({
            headers: { authorization: `Bearer ${state.token.data}` },
          }),
        )
        .pipe(
          retryOnNetworkError(100, 500, 3),
          tap((response) => {
            this.patchState({
              loaded: true,
              token: { data: response.data.token, dt: Date.now() },
              refreshing: false,
              timeOffset: this.calculateTimeOffset(response.dtSent),
              decodedToken: decodeToken(response.data.token),
              error: undefined,
            });
          }),
          catchError((error) => {
            this.patchState({
              refreshing: false,
              error: {
                date: new Date(),
                waitingForExternal:
                  error instanceof ApiErrorResponse &&
                  [401, 403].includes(error.status),
              },
            });

            withScope((scope) => {
              scope.setLevel(
                error instanceof ApiErrorResponse &&
                  [0, 401, 403].includes(error.status)
                  ? 'info'
                  : 'error',
              );
              scope.setTag('subMessage', 'Failed to refresh auth token.');
              captureException(toSentryError(`TokenStore:refresh()`, error));
            });

            return EMPTY;
          }),
          finalize(() => {
            if (this.state.refreshing) {
              this.patchState({ refreshing: false });
            }
          }),
        );
    }),
  );

  get refreshingToken(): boolean {
    return this.state.refreshing;
  }

  get token(): string {
    return this.state.loaded ? this.state.token.data : undefined;
  }

  get tokenDt(): number {
    return this.state.loaded ? this.state.token.dt : undefined;
  }

  get decodedToken(): AuthenticationTokenDataModel {
    return this.state.loaded ? this.state.decodedToken : undefined;
  }

  get timeOffset(): number {
    return this.state.timeOffset;
  }

  readonly tokenAfterRefresh$: Observable<string> = this.state$.pipe(
    filter(
      ({ refreshing, saving, error }) =>
        !refreshing && !saving && !error?.waitingForExternal,
    ),
    map(({ token }) => token?.data),
    distinctUntilChanged(),
  );

  readonly externalTokenUpdate$: Observable<TokenObj> =
    this.crossTabChannel.messageReceived$.pipe(
      filter((message) => {
        if (message.type !== TokenStoreService.crossTabEvent) {
          return false;
        }

        let msgData = message.data;

        if (isString(msgData)) {
          msgData = { data: msgData, dt: undefined };
          return this.token !== message.data;
        }

        const { data, dt }: TokenObj = msgData;

        return (
          this.token !== data && (!this.tokenDt || !dt || this.tokenDt <= dt)
        );
      }),
      delayWhen(() =>
        this.state$.pipe(
          filter(({ loaded, refreshing }) => loaded && !refreshing),
        ),
      ),
      observeOn(enterZone(this.ngZone, queueScheduler)),
      map((message) => {
        const tokenObj: TokenObj = message.data;
        this.setToken(tokenObj, undefined);

        return tokenObj;
      }),
      untilNgDestroyed(),
      share(),
    );

  tokenStatusWorker = (): boolean => {
    return this.state.refreshing;
  };

  _electronRefService: ElectronRefService = undefined;

  get electronRefService(): ElectronRefService {
    if (this._electronRefService === undefined) {
      this._electronRefService = this.injector.get(ElectronRefService, null);
    }

    return this._electronRefService;
  }

  constructor() {
    super({
      loaded: false,
      refreshing: false,
      saving: false,
      token: undefined,
      timeOffset: 0,
      decodedToken: undefined,
      error: undefined,
    });

    this.crossTabWorker.register(
      TokenStoreService.tokenStatus,
      this.tokenStatusWorker,
    );
    this.debugService.register('TokenStore', this.destroyRef);
    this.refresh$.subscribe();
    this.synchronizeTokenInStore$.subscribe();
    this.externalTokenUpdate$.subscribe();
    this.tokenInvalidated$.subscribe();
  }

  ngOnDestroy(): void {
    // needed for debugService
  }

  async init(): Promise<void> {
    logger.debug('TokenStore::init init');
    const tokenObj = await this.getToken();
    this.electronRefService?.api.app.setToken?.(tokenObj?.data);

    logger.debug({ tokenObj }, 'TokenStore::init completed');
    this.setToken(tokenObj, undefined);
  }

  forceLoadToken(): Observable<void> {
    return from(
      (async (): Promise<void> => {
        logger.debug('TokenStore::forceLoad init');

        if (this.state.saving) {
          await ObservableUtils.toPromise(
            this.state$.pipe(filter(({ saving }) => !saving)),
          );
        }

        const tokenObj = await this.getToken();

        if (!isEqual(this.state.token, tokenObj)) {
          logger.debug('TokenStore::forceLoad updating');
          this.setToken(tokenObj, undefined);
        } else {
          logger.debug('TokenStore::forceLoad skip; they are same');
        }
      })(),
    );
  }

  enableRefreshing(): void {
    this.refreshingEnabledSubject.next(true);
  }

  disableRefreshing(): void {
    this.refreshingEnabledSubject.next(false);
  }

  setToken(token: TokenObj, dtSent: Date): void {
    const timeOffset = this.calculateTimeOffset(dtSent);
    const tokenDateTime = token?.dt;

    if (this.tokenDt && tokenDateTime && this.tokenDt >= tokenDateTime) {
      logger.debug('TokenStore::setToken skip; new token is too old');
      return;
    }

    if (
      this.state.timeOffset === timeOffset &&
      isEqual(this.state.token, token) &&
      this.state.loaded
    ) {
      logger.debug(
        {
          stateToken: this.state.token,
          token,
        },
        'TokenStore::setToken skip; they are same',
      );
      return;
    }

    logger.debug({ token }, 'TokenStore::setToken update to');
    this.electronRefService?.api.app.setToken?.(token?.data);
    this.patchState({
      loaded: true,
      timeOffset,
      token,
      decodedToken: decodeToken(token?.data),
      error: undefined,
    });
  }

  refreshTimeOffset(dtSent: Date): void {
    const timeOffset = this.calculateTimeOffset(dtSent);

    if (this.state.timeOffset === timeOffset) {
      return;
    }

    this.patchState({ timeOffset });
  }

  reportInvalidToken(token: string): void {
    if (token?.startsWith('Bearer')) {
      token = token.replace('Bearer ', '');
    }

    if (this.token !== token) {
      return;
    }

    this.patchState({
      error: {
        date: new Date(),
        waitingForExternal: true,
      },
    });
  }

  flush(): Promise<void> {
    return ObservableUtils.toPromise(
      this.state$.pipe(
        debounceTime(100),
        filter((state) => state.loaded && !state.refreshing && !state.saving),
        map(() => undefined),
      ),
    );
  }

  async storeToken(tokenObj: TokenObj): Promise<void> {
    logger.debug('TokenStore::storeToken init');
    const db = await this.indexedDbService.getConnection();

    const { data, dt } = tokenObj;

    const tx = db.transaction('user', 'readwrite');
    const storedToken: string = await tx.store.get(
      TokenStoreService.tokenKeyName,
    );

    if (storedToken === data) {
      logger.debug('TokenStore::storeToken skip; Tokens are same');
      return tx.done;
    }

    if (!data) {
      try {
        await Promise.all([
          tx.store.delete(TokenStoreService.tokenKeyName),
          tx.store.put(`${dt}`, TokenStoreService.tokenKeyDtName),
        ]);
        this.crossTabChannel.sendMessage({
          instanceId: this.appInstanceId,
          type: TokenStoreService.crossTabEvent,
          data: tokenObj,
        });
      } catch (e) {
        logger.error({ error: e }, 'Failed to delete token');
      }
      return tx.done;
    }

    try {
      await Promise.all([
        tx.store.put(data, TokenStoreService.tokenKeyName),
        tx.store.put(`${dt}`, TokenStoreService.tokenKeyDtName),
      ]);
      this.crossTabChannel.sendMessage({
        instanceId: this.appInstanceId,
        type: TokenStoreService.crossTabEvent,
        data: tokenObj,
      });
      await tx.done;
    } catch (e) {
      logger.error({ error: e }, 'Failed to save token');
    }
    return tx.done;
  }

  async getToken(): Promise<TokenObj> {
    const db = await this.indexedDbService.getConnection();

    const tx = db.transaction('user', 'readonly');

    const [token, dateTime] = await Promise.all([
      tx.store.get(TokenStoreService.tokenKeyName),
      tx.store.get(TokenStoreService.tokenKeyDtName),
    ]);

    return {
      data: token,
      dt: dateTime ? parseInt(dateTime, 10) : token ? Date.now() : undefined,
    };
  }

  shouldRefreshToken(token: string, timeOffset: number): boolean {
    if (!token) {
      return false;
    }

    try {
      const decodedToken = jwtDecode<AuthenticationTokenDataModel>(token);

      let expirationDate = new Date(0);
      expirationDate.setUTCSeconds(decodedToken.exp);

      expirationDate = new Date(expirationDate.getTime() - timeOffset);

      if (!decodedToken || !decodedToken.type) {
        return false;
      }

      const now = Date.now();
      const diff = expirationDate.getTime() - now;

      switch (decodedToken.type) {
        case AuthenticationTokenType.LongLived:
          return (
            diff <
              this.appConfig.authentication.tokenRefreshInterval.longLived &&
            diff > 0
          );

        case AuthenticationTokenType.ShortLived:
        case AuthenticationTokenType.Oauth:
        case AuthenticationTokenType.SuperAdmin:
        case AuthenticationTokenType.Support:
          return (
            diff <
              this.appConfig.authentication.tokenRefreshInterval.shortLived &&
            diff > 0
          );
      }
      return false;
    } catch (error) {
      logger.error({ error }, 'Failed to decode token');
      return false;
    }
  }

  private calculateTimeOffset(dtSent: Date): number {
    if (!dtSent) {
      return 0;
    }

    const currentTime = new Date().getTime();

    const offset = dtSent.getTime() - currentTime;

    if (Math.abs(offset) > this.appConfig.clocks.skewedClockOffsetThreshold) {
      return offset;
    } else {
      return 0;
    }
  }
}
