import { NgModule } from '@angular/core';

import { ProvideParentFormGroupDirective } from './../directive/form-control/provide-parent-form-group.directive';
import { ProvideParentFormDirective } from './../directive/form-control/provide-parent-form.directive';
import { AttributeDisabledDirective } from '../directive/form-control/attribute-disabled';
import { MarkAsTouchedOnModelChangeDirective } from '../directive/form-control/mark-as-touched-on-model-change.directive';
import { SelectInputContentDirective } from '../directive/form-control/select-input-content.directive';
import { SubmitIfValidDirective } from '../directive/form-control/submit-if-valid.directive';
import { AutofocusDirective } from '../directive/form-control/autofocus';

@NgModule({
  imports: [
    AutofocusDirective,
    MarkAsTouchedOnModelChangeDirective,
    SelectInputContentDirective,
    SubmitIfValidDirective,
    AttributeDisabledDirective,
    ProvideParentFormDirective,
    ProvideParentFormGroupDirective,
  ],
  exports: [
    MarkAsTouchedOnModelChangeDirective,
    SelectInputContentDirective,
    SubmitIfValidDirective,
    AttributeDisabledDirective,
    ProvideParentFormDirective,
    ProvideParentFormGroupDirective,
    AutofocusDirective,
  ],
})
export class InputUtilsModule {}
