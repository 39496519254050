export const enum SubscriptionTopic {
  DashboardEvent,
  Dashboard,
  Project,
  Location,
  DataSource,
  ProjectDataSource,
  User,
}

export interface Topic<T> {
  readonly name: string;
  readonly type: SubscriptionTopic;
  readonly parameter: (item: T) => string[];
}

const _topics = {
  [SubscriptionTopic.DashboardEvent]: {
    name: 'dashboard/{}/event',
    type: SubscriptionTopic.DashboardEvent,
    parameter: (item: { dashboardUuid: string }) => [item.dashboardUuid],
  } as Topic<{ dashboardUuid: string }>,
  [SubscriptionTopic.Dashboard]: {
    name: 'dashboard/{}',
    type: SubscriptionTopic.Dashboard,
    parameter: (item: { dashboardUuid: string }) => [item.dashboardUuid],
  } as Topic<{ dashboardUuid: string }>,
  [SubscriptionTopic.Project]: {
    name: 'project/{}',
    type: SubscriptionTopic.Project,
    parameter: (item: { projectUuid: string }) => [item.projectUuid],
  } as Topic<{ projectUuid: string }>,
  [SubscriptionTopic.DataSource]: {
    name: 'data-source/{}',
    type: SubscriptionTopic.DataSource,
    parameter: (item: { dataSourceUuid: string }) => [item.dataSourceUuid],
  } as Topic<{ dataSourceUuid: string }>,
  [SubscriptionTopic.ProjectDataSource]: {
    name: 'project/{}/data-source/{}',
    type: SubscriptionTopic.ProjectDataSource,
    parameter: (item: { dataSourceUuid: string; projectUuid: string }) => [
      item.projectUuid,
      item.dataSourceUuid,
    ],
  } as Topic<{ dataSourceUuid: string; projectUuid: string }>,
  [SubscriptionTopic.Location]: {
    name: 'location/{}',
    type: SubscriptionTopic.Location,
    parameter: (item: { locationUuid: string }) => [item.locationUuid],
  } as Topic<{ locationUuid: string }>,
  [SubscriptionTopic.User]: {
    name: 'user/{}',
    type: SubscriptionTopic.User,
    parameter: (item: { userUuid: string }) => [item.userUuid],
  } as Topic<{ userUuid: string }>,
} as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const topics = _topics;
export type Topics = typeof _topics;
