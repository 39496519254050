import { createFeatureSelector, createSubStateSelectors } from '@gv/state';
import { createSelector } from '@ngrx/store';

import type { DetailFeatureState } from './state';
import { detailFeatureKey } from './state';

const getState = createFeatureSelector<DetailFeatureState>(detailFeatureKey);

const getDetailState = createSelector(getState, (state) => state.detail);

export const fromDetail = createSubStateSelectors(getDetailState, 'detail');

export const isVaultInfoLoaded = fromDetail.isDetailLoaded;
export const getVaultSubState = createSelector(
  fromDetail.getDetail,
  (state) => state?.vault,
);

export const getPlatformPlanSubState = createSelector(
  fromDetail.getDetail,
  (state) => state?.platformPlan,
);

export const getTotalSpace = createSelector(
  getVaultSubState,
  (state): number => state?.total,
);

export const getUsedSpace = createSelector(
  getVaultSubState,
  (state): number => state?.total - state?.freeSpace,
);

export const getFreeSpace = createSelector(getVaultSubState, (state): number =>
  Math.max(state?.freeSpace, 0),
);

export const isVaultFull = createSelector(
  getVaultSubState,
  (state): boolean => state?.freeSpace < 0,
);
