import { inject, Injectable, Injector, DestroyRef } from '@angular/core';
import { MatDialogState } from '@angular/material/dialog';
import type { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import {
  dialogSynchronizer$,
  EMPTY_STATE,
  SimpleState,
  StoreInject,
} from '@gv/state';
import {
  delayedConcatMap,
  isObject,
  loadModule,
  ObjectUtils,
  untilNgDestroyed,
} from '@gv/utils';
import { produce } from 'immer';
import { difference, isEqual, omit } from 'lodash-es';
import {
  EMPTY,
  filter,
  from,
  map,
  mergeMap,
  pairwise,
  skip,
  startWith,
  tap,
} from 'rxjs';

import { DIALOG_MAPPING } from './token';

type FragmentEntry = { type: string } & Record<string, any>;
type FragmentEntries = Record<string, FragmentEntry[]>;
interface DialogEntry {
  type: string;
  data?: any;
  dialogRef?: MatDialogRef<unknown, unknown>;
}

const isEntryEqual = (a: DialogEntry, b: DialogEntry) =>
  isEqual(
    ObjectUtils.withoutUndefined(omit(a, 'dialogRef')),
    ObjectUtils.withoutUndefined(omit(b, 'dialogRef')),
  );

@Injectable({
  providedIn: 'root',
})
export class DialogUrlSerializer {
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);
  private store = inject(StoreInject(EMPTY_STATE));
  private dialogMapping = inject(DIALOG_MAPPING);
  private injector = inject(Injector);

  private state = new SimpleState(
    {
      openedDialogs: <DialogEntry[]>[],
    },
    {
      add(state, data: DialogEntry) {
        if (state.openedDialogs.find((f) => isEntryEqual(data, f))) {
          return state;
        }

        return {
          ...state,
          openedDialogs: [...state.openedDialogs, data],
        };
      },
      remove(state, data: DialogEntry) {
        return produce(state, (draft) => {
          const index = state.openedDialogs.findIndex(
            (f) => f.type === data.type,
          );

          if (index >= 0) {
            draft.openedDialogs.splice(index, 1);
          }
        });
      },
      cleanup(state, data: string[]) {
        return produce(state, (draft) => {
          for (let i = 0; i < data.length; i += 1) {
            const key = data[i];
            const index = draft.openedDialogs.findIndex((f) => f.type === key);

            if (index >= 0) {
              if (
                draft.openedDialogs[index].dialogRef?.getState() ===
                MatDialogState.OPEN
              ) {
                draft.openedDialogs[index].dialogRef.close();
              }
              draft.openedDialogs.splice(index, 1);
            }
          }
        });
      },
    },
  );

  private activatedRoute = inject(ActivatedRoute);

  private onAction$ = dialogSynchronizer$.pipe(
    tap((action) => {
      if (action.action === 'add') {
        this.state.dispatch({
          type: 'add',
          data: { data: action.data, type: action.type, dialogRef: action.ref },
        });
        return;
      }
      this.state.dispatch({
        type: 'remove',
        data: { data: action.data, type: action.type },
      });
    }),
    untilNgDestroyed(),
  );

  private onStateChanged$ = this.state.state$.pipe(
    skip(1),

    mergeMap((state) => {
      const entries = state.openedDialogs.map(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        (m): FragmentEntry => ({ type: m.type, ...(m.data || {}) }),
      );

      const fragment =
        entries.length === 0 ? undefined : this._serialize({ dialog: entries });

      // TODO: navigation triggers scroll so we should solve it somehow when we enable it for all dialogs
      return (this.activatedRoute.snapshot.fragment || undefined) ===
        (fragment || undefined)
        ? EMPTY
        : from(
            this.router.navigate([], {
              relativeTo: this.activatedRoute,
              fragment,
              queryParamsHandling: 'preserve',
            }),
          );
    }),
    untilNgDestroyed(),
  );

  private routeChanged$ = this.router.events.pipe(
    filter((f) => f instanceof NavigationEnd),
    startWith(undefined),
    map(() => this.activatedRoute.snapshot.fragment),
    pairwise(),
    startWith(''),
    filter(([o, n]) => o !== n),
    delayedConcatMap(() => [this.state.state$]),
    mergeMap(([[, fragment], state]) => {
      const data = this.parse(fragment || '');
      const dialog = data['dialog'];

      const diff = difference(
        state.openedDialogs.map((n) => n.type),
        (dialog || []).map((m) => m.type),
      );
      if (diff.length) {
        this.state.dispatch({
          type: 'cleanup',
          data: diff,
        });
      }

      if (dialog?.length) {
        const type = dialog[0].type;
        const dialogConfig = this.dialogMapping[type];
        const stateEntry: DialogEntry = {
          type: dialog[0].type,
          data:
            Object.keys(dialog[0]).length === 1
              ? undefined
              : omit(dialog[0], 'type'),
        };

        if (
          dialogConfig &&
          !state.openedDialogs.find((f) => isEntryEqual(f, stateEntry))
        ) {
          return loadModule(dialogConfig.deps, {
            injector: this.injector,
          }).pipe(
            tap(() => {
              this.store.dispatch(
                (dialogConfig.actions.prepare || dialogConfig.actions.open)({
                  data: stateEntry.data,
                }),
              );
            }),
          );
        }
      }

      return EMPTY;
    }),
    untilNgDestroyed(),
  );

  constructor() {
    this.state.init(this.destroyRef);
    this.routeChanged$.subscribe();
    this.onAction$.subscribe();
    this.onStateChanged$.subscribe();
  }

  ngOnDestroy(): void {
    //
  }

  getFragment(key: string, data: FragmentEntry): string {
    return this._serialize({ [key]: [data] });
  }

  parse(fragment: string): FragmentEntries {
    const res = <FragmentEntries>{};
    const parts = decodeURIComponent(fragment).split(';');

    for (const part of parts) {
      const partItems = part.split('=');

      if (partItems.length < 2) {
        continue;
      }

      const [key, data] = partItems;
      res[key] = [
        ...(res[key] || []),
        data.split(',').reduce(
          (acc, entry) => {
            const [key, d] = entry.split(':');
            acc[key] = decodeURIComponent(d);
            try {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              acc[key] = JSON.parse(acc[key]);
            } catch (e) {
              // ignore
            }
            return acc;
          },
          <FragmentEntry>{},
        ),
      ];
    }

    return res;
  }

  private _serialize(data: FragmentEntries): string {
    return encodeURIComponent(
      Object.keys(data)
        .reduce(
          (acc, d) => {
            return acc.concat(data[d].map((v) => this._serializeEntry(d, v)));
          },
          <string[]>[],
        )
        .join(';'),
    );
  }

  private _serializeEntry(key: string, data: FragmentEntry): string {
    return `${key}=${Object.keys(data).reduce(
      (acc, k) =>
        `${acc}${acc ? ',' : ''}${k}:${encodeURIComponent(
          isObject(data[k]) ? JSON.stringify(data[k]) : data[k],
        )}`,
      '',
    )}`;
  }
}
