import type { FilterModel, SortBy, SortDirection } from '@gv/api';
import type { SubStateArrayModel } from '@gv/state';
import type { DataSourceApi } from '@gv/context';

import type { ExportStateModel } from '../../entity/model/export/export-state-model';

export interface TMCDataSources {
  uuid: string;
  name: string;
  thumbnail: string;
  locationName: string;
}

export type TMCDataSourcesStateModel = SubStateArrayModel<TMCDataSources>;

export const initialExportsState: ExportsState = {
  api: undefined,

  total: 0,

  uuids: [],

  exports: {},

  loading: false,

  loaded: false,

  dirty: false,

  dataSourceUuid: undefined,

  sortBy: undefined,
  sortDirection: undefined,

  locationDataSources: [],
  dataSourcesFilters: {},

  highlighted: {},
};

export interface ExportsState {
  readonly api: DataSourceApi;

  readonly dataSourceUuid: string;

  readonly total: number;

  readonly uuids: string[];

  readonly exports: { [uuid: string]: ExportStateModel }; // for one data source only, no need for uuid of data source

  readonly loading: boolean;

  readonly loaded: boolean;

  readonly dirty: boolean;

  readonly sortBy: SortBy;
  readonly sortDirection: SortDirection;

  readonly locationDataSources: string[];
  readonly dataSourcesFilters: { [uuid: string]: FilterModel[] };

  readonly highlighted: {
    uuid?: string | undefined;
    date?: Date | undefined;
  };
}
