import { Injectable, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import type { SafeResourceUrl } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconResolverService {
  private matIconRegistry = inject(MatIconRegistry);
  private sanitizer = inject(DomSanitizer);

  constructor() {
    this.matIconRegistry.addSvgIconResolver(
      (name, namespace): SafeResourceUrl => {
        if (namespace === 'gv-url') {
          let url = decodeURIComponent(name);
          url += `${url.indexOf('?') === -1 ? '?' : '&'}ngsw-bypass=1`;

          return this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }

        if (namespace !== 'gv') {
          return null;
        }

        return this.sanitizer.bypassSecurityTrustResourceUrl(
          `assets/svg-icons/${name}.svg`,
        );
      },
    );
  }
}
