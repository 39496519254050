import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiErrorResponse } from '@gv/api';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceDetectorService {
  isMaintenanceError(
    error: any,
  ): error is HttpErrorResponse | ApiErrorResponse<any> {
    // 504 is timeout that can happen during loading statistics in Traffic Analytics
    if (
      (error instanceof HttpErrorResponse ||
        error instanceof ApiErrorResponse) &&
      error.status > 500 &&
      error.status !== 504
    ) {
      return true;
    }
    return false;
  }
}
