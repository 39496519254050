import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import type { Draft } from 'immer';
import { asIndexed } from '@gv/state';
import { removeFromArray } from '@gv/utils';
import { UserActions } from '@gv/user';
import type { FilterModel } from '@gv/api';

import type { ExportsState } from '../state/exports.state';
import { initialExportsState } from '../state/exports.state';
import { ExportActions } from '../action/exports.actions';

export const exportsFeatureKey = 'exports';

const exportsReducer = createReducer<ExportsState>(
  initialExportsState,

  on(ExportActions.init, (_state, action) => ({
    ...initialExportsState,
    dataSourceUuid: action.uuid,
    highlighted: _state.highlighted,
    api: action.api(),
  })),

  on(ExportActions.fetch.started, (state) => {
    return state.loading ? state : { ...state, loading: true };
  }),

  on(ExportActions.fetch.succeeded, (state, action) => {
    const exports = action.data.exports;

    return {
      ...state,
      loaded: true,
      loading: false,
      dirty: false,
      sortBy: action.sortBy,
      sortDirection: action.sortDirection,
      total: action.data.count || 0,
      uuids: exports.map((e) => e.uuid),
      exports: asIndexed(exports, (e) => e.uuid),
    };
  }),

  on(ExportActions.fetch.failed, (state) => {
    return { ...state, loading: false, loaded: false, dirty: false };
  }),

  on(ExportActions.fetch.cancelled, (state) => {
    return !state.loading ? state : { ...state, loading: false, dirty: false };
  }),

  on(ExportActions.delete, (state, action): ExportsState => {
    const exportObj = state.exports[action.uuid];

    if (!exportObj) {
      return state;
    }

    return produce(state, (draft) => {
      delete draft[action.uuid];
      removeFromArray(draft.uuids, action.uuid);
    });
  }),

  on(
    ExportActions.markAsDirty,
    (state): ExportsState => (state.loaded ? { ...state, dirty: true } : state),
  ),

  on(
    ExportActions.highlight,
    (state, action): ExportsState => ({
      ...state,
      highlighted: { uuid: action.highlight, date: action.dtSent },
    }),
  ),

  on(
    ExportActions.reset,
    UserActions.logout,
    (): ExportsState => initialExportsState,
  ),

  on(ExportActions.multiExport.pushFilters, (state, action) => {
    if (!action.uuid) {
      return state;
    }

    return produce(state, (draft) => {
      draft.dataSourcesFilters[action.uuid] = (action.filters?.filters ||
        []) as Draft<readonly FilterModel[]>;
    });
  }),

  on(ExportActions.multiExport.addDataSources, (state, action) => {
    if (action.uuids?.length === 0) {
      return state;
    }

    return produce(state, (draft) => {
      draft.locationDataSources = action.uuids;
    });
  }),
);

export function reducer(
  state: ExportsState | undefined,
  action: Action,
): ExportsState {
  return exportsReducer(state, action);
}
