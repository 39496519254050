import type { OnDestroy } from '@angular/core';
import { Injectable, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { StoreInject } from '@gv/state';
import { select } from '@ngrx/store';
import type { Subscription } from 'rxjs';
import { BehaviorSubject, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import { UPLOADS_FEATURE_STATE, fromUploadsState } from '@gv/upload/state';

import { APP_CONFIG } from '../../entity/token/app.config';
import { APP_STATE } from '../../store/state/app-state';
import { UserService } from './user/user.service';

@Injectable({
  providedIn: 'root',
})
export class TitleUpdaterService implements OnDestroy {
  private appConfig = inject(APP_CONFIG);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private titleService = inject(Title);
  private store = inject(StoreInject(APP_STATE, UPLOADS_FEATURE_STATE));
  private userService = inject(UserService);
  private titleSubject = new BehaviorSubject<string>(
    this.appConfig.defaultTitle,
  );

  private uploadCount$ = this.store.pipe(
    select(fromUploadsState.vault.getRemainingUploadCount),
    distinctUntilChanged(),
  );

  private title$ = this.userService.isLoggedIn$.pipe(
    distinctUntilChanged(),
    switchMap((loggedIn) => {
      if (!loggedIn) {
        return this.titleSubject;
      }

      return this.uploadCount$.pipe(
        switchMap((numberOfUploads) => {
          if (numberOfUploads > 0) {
            return of(
              numberOfUploads === 1
                ? $localize`:@@title-updater.uploading-single:Uploading 1 video`
                : $localize`:@@title-updater.uploading-multiple:Uploading ${numberOfUploads}:number of uploads: videos`,
            );
          }
          return this.titleSubject;
        }),
      );
    }),
    distinctUntilChanged(),
  );

  private navigationEndSubscription: Subscription | undefined;
  private titleSubscription: Subscription | undefined;

  enable(): void {
    this.disable();

    this.navigationEndSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((r) => {
          let route = r;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
      )
      .subscribe((data) => {
        if (data && data['title'] !== undefined) {
          this.titleSubject.next(
            `${data['title'] as string} | ${this.appConfig.defaultTitle}`,
          );
        } else {
          this.titleSubject.next(this.appConfig.defaultTitle);
        }
      });

    this.titleSubscription = this.title$.subscribe((title) => {
      this.titleService.setTitle(title);
    });
  }

  disable(): void {
    if (this.titleSubscription) {
      this.titleSubscription.unsubscribe();
      this.titleSubscription = undefined;
    }

    if (this.navigationEndSubscription) {
      this.navigationEndSubscription.unsubscribe();
      this.navigationEndSubscription = undefined;
    }
  }

  ngOnDestroy(): void {
    this.disable();
  }
}
