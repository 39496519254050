import type { ComponentType } from '@angular/cdk/portal';
import type { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import type { Injector, TemplateRef } from '@angular/core';
import { NgZone, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import type { Action } from '@ngrx/store';
import type { Observable } from 'rxjs';
import { merge, EMPTY } from 'rxjs';
import { concatMap, map, share } from 'rxjs/operators';
import type { Dialog } from '@gv/utils';
import { ModuleLoaderService } from '@gv/ui/core';

import type {
  DialogActionType,
  DialogDataProps,
  TypedDialogBaseActions,
} from './create-dialog-base-actions';
import type { DialogRefOrConfigObj } from './dialog-actions-emitter';
import { dialogActionsEmitter } from './dialog-actions-emitter';
import { openDialog } from './open-dialog';
import { dialogSynchronizer$ } from './dialog-synchronizer';

interface _LazyOptionsType<T, C> {
  actions$?: Observable<Action>;
  module: {
    loader?: {
      openDialog(
        componentOrTemplateRef: () => Promise<
          ComponentType<unknown> | TemplateRef<unknown>
        >,
        config: MatDialogConfig<unknown>,
        m?: () => Promise<unknown>,
        parentInjector?: Injector,
      ): Observable<MatDialogRef<unknown, unknown>>;
    };
    module?: () => Promise<T>;
    component: () => Promise<ComponentType<C>>;
  };
}
type LazyOptionsType<O, P> = [P] extends [void]
  ? object
  : { prepare$: (data: P) => Observable<O> };

type _Options = {
  actions$?: Observable<Action>;
  dialog: MatDialog;
  ngZone: NgZone;
};
type OptionsType<O, P> = [P] extends [void]
  ? _Options
  : _Options & { prepare$: (data: P) => Observable<O> };

type LazyDialogEffectFn<A, O, T, R> = (v: {
  cancel: (action: A) => void;
  actions$: Observable<A>;
  openDialog: (
    action: A,
    config: MatDialogConfig<O>,
    parentInjector?: Injector,
  ) => Observable<MatDialogRef<T, R>>;
  dialogActionsEmitter: (
    dialogRef: DialogRefOrConfigObj<T, R>,
    ...otherObservables: Observable<Action>[]
  ) => Observable<Action>;
}) => Observable<Action>;

type DialogEffectFn<A, O, T, R> = (v: {
  cancel: (action: A) => void;
  actions$: Observable<A>;
  openDialog: (action: A, config: MatDialogConfig<O>) => MatDialogRef<T, R>;
  dialogActionsEmitter: (
    dialogRef: DialogRefOrConfigObj<T, R>,
    ...otherObservables: Observable<Action>[]
  ) => Observable<Action>;
}) => Observable<Action>;

export function createLazyDialogEffect<
  T,
  ActionsOpenProps,
  M,
  P,
  R = T extends Dialog<unknown, infer CloseData> ? CloseData : void,
  O = T extends Dialog<infer OpenData, unknown> ? OpenData : void,
>(
  fn: LazyDialogEffectFn<
    ReturnType<DialogActionType<ActionsOpenProps>>,
    O,
    T,
    R
  >,
  actions: TypedDialogBaseActions<ActionsOpenProps, R, P>,
  options: _LazyOptionsType<M, T> & LazyOptionsType<O, P>,
): Observable<Action> {
  const _actions$ = <Observable<Action>>(options.actions$ || inject(Actions));
  const actions$: Observable<ReturnType<DialogActionType<ActionsOpenProps>>> =
    _actions$.pipe(ofType(actions.open));

  const prepare$: Observable<Action> = !actions.prepare
    ? EMPTY
    : _actions$.pipe(
        ofType(actions.prepare),
        concatMap((p) =>
          (options as { prepare$: (data: P) => Observable<O> }).prepare$(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            (p as any).data,
          ),
        ),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        map((d) => actions.open({ data: d as any })),
      );

  const moduleLoader = options.module.loader || inject(ModuleLoaderService);
  const bindedOpenDialog = (
    a: ReturnType<DialogActionType<ActionsOpenProps>>,
    config: MatDialogConfig<O>,
    parentInjector?: Injector,
  ): Observable<MatDialogRef<T, R>> =>
    (
      moduleLoader.openDialog(
        options.module.component,
        { injector: (a as DialogDataProps<void>).injector?.(), ...config },
        options.module.module,
        parentInjector || (a as DialogDataProps<void>).injector?.(),
      ) as Observable<MatDialogRef<T, R>>
    ).pipe(share<MatDialogRef<T, R>>());

  const bindedDialogActionsEmitter = (
    dialogRef: DialogRefOrConfigObj<T, R>,
    ...otherObservables: Observable<Action>[]
  ): Observable<Action> =>
    dialogActionsEmitter(actions, dialogRef, ...otherObservables);

  return createEffect(() =>
    merge(
      fn({
        actions$,
        cancel: (a: ReturnType<DialogActionType<ActionsOpenProps>>) => {
          dialogSynchronizer$.next({
            action: 'remove',
            type: actions._type,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            data: (a as any).data,
          });
        },
        openDialog: bindedOpenDialog,
        dialogActionsEmitter: bindedDialogActionsEmitter,
      }),
      prepare$,
    ),
  );
}

export function createDialogEffect<
  T,
  ActionsOpenProps,
  P,
  R = T extends Dialog<unknown, infer CloseData> ? CloseData : void,
  O = T extends Dialog<infer OpenData, unknown> ? OpenData : void,
>(
  fn: DialogEffectFn<ReturnType<DialogActionType<ActionsOpenProps>>, O, T, R>,
  actions: TypedDialogBaseActions<ActionsOpenProps, R, P>,
  component: ComponentType<T>,
  options?: OptionsType<O, P>,
): Observable<Action> {
  const actions$: Observable<ReturnType<DialogActionType<ActionsOpenProps>>> =
    (<Observable<Action>>(options?.actions$ || inject(Actions))).pipe(
      ofType(actions.open),
    );

  const dialog = options?.dialog || inject(MatDialog);
  const zone = options?.ngZone || inject(NgZone);
  const bindedOpenDialog = (
    a: ReturnType<DialogActionType<ActionsOpenProps>>,
    config: MatDialogConfig<O>,
  ): MatDialogRef<T, R> =>
    openDialog(
      dialog,
      actions,
      component,
      { injector: (a as DialogDataProps<void>).injector?.(), ...config },
      zone,
    );

  const bindedDialogActionsEmitter = (
    config: DialogRefOrConfigObj<T, R>,
    ...otherObservables: Observable<Action>[]
  ): Observable<Action> =>
    dialogActionsEmitter(actions, config, ...otherObservables);

  return createEffect(() =>
    fn({
      actions$,
      cancel: (a: ReturnType<DialogActionType<ActionsOpenProps>>) => {
        dialogSynchronizer$.next({
          action: 'remove',
          type: actions._type,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          data: (a as any).data,
        });
      },
      openDialog: bindedOpenDialog,
      dialogActionsEmitter: bindedDialogActionsEmitter,
    }),
  );
}
