import { inject, Injectable } from '@angular/core';

import { createEffect } from '@ngrx/effects';
import { isEqual } from 'lodash-es';
import { distinctUntilChanged, pairwise, startWith, tap } from 'rxjs';

import { identify, trackUser, reset } from './mixpanel';
import { MIXPANEL_CONTEXT } from './token';

@Injectable()
export class MixPanelEffects {
  private context = inject(MIXPANEL_CONTEXT);

  tagUser$ = createEffect(
    () =>
      this.context.userInfo$.pipe(
        startWith(undefined),
        pairwise(),
        distinctUntilChanged((a, b) => isEqual(a, b)),
        tap(([oldUser, newUser]) => {
          if (!newUser || (oldUser && oldUser.uuid !== newUser.uuid)) {
            reset();
          }

          if (newUser) {
            trackUser(newUser);
          }

          if (newUser && newUser.uuid !== oldUser?.uuid) {
            identify(newUser.uuid);
          }
        }),
      ),
    {
      dispatch: false,
    },
  );
}
