import type { OnDestroy } from '@angular/core';
import { computed, Injectable, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

import type { Feature, TokenRole } from '@gv/api';
import { USER_CONTEXT } from '@gv/user';
import type { IsAble } from '@gv/utils';
import { isArray, untilNgDestroyed, BinaryEnumUtils } from '@gv/utils';
import { isEqual } from 'lodash-es';
import type { Observable } from 'rxjs';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

import type { BasePermissionService } from './permission-service.interface';
import { PermissionMode } from './permission-service.interface';
import type { Permissions } from '../def/permission-definition';
import {
  defaultPermissions,
  permissionsInitializers,
  permissionsReason,
  reduce,
} from '../def/permission-definition';
import type { Permission } from '../entity/enum/permission.enum';
import type { PermissionScope } from '../entity/enum/permission-scope.enum';

const messages = {
  full: $localize`:@@config.upgrade-plan-msg:Upgrade plan to enable this feature`,
  short: $localize`:@@config.upgrade-plan-msg-short:Upgrade plan`,
};

@Injectable({
  providedIn: 'root',
})
export class PermissionService implements OnDestroy, BasePermissionService {
  private userContext = inject(USER_CONTEXT);
  private overridesSubject = new BehaviorSubject<Partial<Permissions>>({});

  private overrides$ = this.overridesSubject.asObservable();

  set overrides(overrides: Partial<Permissions>) {
    if (this.overrides !== overrides) {
      this.overridesSubject.next(overrides);
    }
  }

  private disabledFeaturesS = computed(
    () => this.userContext.userS()?.disabledFeatures,
  );
  private tokenType$ = this.userContext.decodedToken$.pipe(map((m) => m?.role));

  private permissions: Permissions = defaultPermissions;

  readonly permissions$: Observable<Permissions> = combineLatest([
    toObservable(this.disabledFeaturesS),
    this.overrides$,
    this.tokenType$,
  ]).pipe(
    map(([features, overrides, tokenType]) => ({
      ...this.build(features, tokenType),
      ...overrides,
    })),
    untilNgDestroyed(),
    shareReplay(1),
  );

  private permissionsS = signal<Permissions | undefined>(undefined);

  constructor() {
    this.permissions$.subscribe((permissions) => {
      this.permissions = permissions;
      this.permissionsS.set(permissions);
    });
  }

  ngOnDestroy(): void {
    //
  }

  isAllowed(
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    mode?: PermissionMode,
  ): boolean {
    return this.isSet(this.permissions, permission, scope, mode);
  }

  isAllowedS(
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    mode?: PermissionMode,
  ): boolean {
    if (!this.permissionsS()) {
      return false;
    }
    return this.isSet(this.permissionsS()!, permission, scope, mode);
  }

  isAllowed$(
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    mode?: PermissionMode,
  ): Observable<boolean> {
    return this.permissions$.pipe(
      map((permissions) => {
        return this.isSet(permissions, permission, scope, mode);
      }),
      distinctUntilChanged(),
    );
  }

  isAble$(
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    mode?: PermissionMode,
  ): Observable<IsAble> {
    return this.permissions$.pipe(
      map((permissions): IsAble => {
        const allowed = this.isSet(permissions, permission, scope, mode);

        return allowed
          ? [true]
          : [false, { reason: this.getReason('full', permission, scope) }];
      }),
      distinctUntilChanged((a, b) => isEqual(a, b)),
    );
  }

  isAble(
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    mode?: PermissionMode,
  ): IsAble {
    const allowed = this.isSet(this.permissions, permission, scope, mode);

    return allowed
      ? [true]
      : [false, { reason: this.getReason('full', permission, scope, mode) }];
  }

  isAbleS(
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    mode?: PermissionMode,
  ): IsAble {
    if (!this.permissionsS()) {
      return [false, { reason: '' }];
    }

    const allowed = this.isSet(this.permissionsS()!, permission, scope, mode);

    return allowed
      ? [true]
      : [false, { reason: this.getReason('full', permission, scope, mode) }];
  }

  getReason(
    mode: 'short' | 'full',
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    permissionMode?: PermissionMode,
  ): string {
    if (this.isSet(this.permissions, permission, scope, permissionMode)) {
      return undefined;
    }

    return (
      permissionsReason[isArray(permission) ? permission[0] : permission] ||
      messages[mode]
    );
  }

  isSet(
    permissions: Permissions,
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    mode?: PermissionMode,
  ): boolean {
    if (isArray<Permission>(permission)) {
      return permission[mode === PermissionMode.Or ? 'some' : 'every']((p) =>
        BinaryEnumUtils.isSet(
          permissions[p],
          ...(isArray(scope) ? scope : [scope]),
        ),
      );
    }

    return BinaryEnumUtils.isSet(
      permissions[permission],
      ...(isArray(scope) ? scope : [scope]),
    );
  }

  private build(
    features: readonly Feature[] | undefined,
    tokenRole: TokenRole,
  ): Permissions {
    const initializedPermissions = permissionsInitializers.reduce(
      (p, fn) => fn(p, features, tokenRole),
      defaultPermissions,
    );

    return (features || []).reduce(
      (permissions, feature): Permissions =>
        reduce(feature, features, permissions),
      initializedPermissions,
    );
  }
}
