import { Injectable, inject } from '@angular/core';

import { ElectronRefService } from '@gv/desktop/core';
import {
  createFlowEffect,
  ofActions,
  onFlowFinished,
  StoreInject,
} from '@gv/state';
import type { PersistedUploadModel } from '@gv/upload/types';
import { UploadType } from '@gv/upload/types';
import { delayedConcatMap, toSentryError } from '@gv/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import type { Action } from '@ngrx/store';
import { select } from '@ngrx/store';
import { captureException, withScope } from '@sentry/angular';
import type { Observable } from 'rxjs';
import {
  combineLatest,
  EMPTY,
  forkJoin,
  merge,
  of,
  throwError,
  TimeoutError,
} from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  timeout,
  toArray,
} from 'rxjs/operators';
import { CrossTabWorkerService } from '@gv/crosstab';

import { UPLOADS_FEATURE_STATE } from '../uploads-feature.state';
import { UploadsActions } from '../action/uploads.actions';
import { fromUploadsState } from '../selectors';
import { VideoUploadHandlerService } from './video-upload-handler.service';

@Injectable({
  providedIn: 'root',
})
export class SharedUploadsEffects {
  private actions$ = inject<Actions>(Actions);
  private store = inject(StoreInject(UPLOADS_FEATURE_STATE));
  private crossTabWorker = inject(CrossTabWorkerService);
  private videoUploadHandlerService = inject(VideoUploadHandlerService);
  private electronRef = inject(ElectronRefService, { optional: true });
  private static readonly cancelUploadsWorker: string = 'cancelUploads';

  private static readonly cancelUploadsTimeout: number = 20000; // in ms

  persistUploads$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UploadsActions.persist),
        tap(() => {
          this.videoUploadHandlerService.persistUploads();
        }),
      ),
    {
      dispatch: false,
    },
  );

  removePendingUploads$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadsActions.removePending),
      concatMap(() =>
        of(this.videoUploadHandlerService.retrievePersistedUploads()),
      ),
      switchMap((queuedUploads: readonly PersistedUploadModel[]) => {
        if (!queuedUploads || queuedUploads.length === 0) {
          return EMPTY;
        }

        let actions = [];

        // do not cancel when electron is present (these uploads are persisted)
        if (queuedUploads.length > 0 && !this.electronRef) {
          actions = queuedUploads.map((vu) =>
            UploadsActions.vault.cancelOnServer.init({
              data: {
                uploadId: vu.uploadId,
                type: UploadType.VAULT,
              },
            }),
          );
        }

        return of(...actions);
      }),
    ),
  );

  removeAllUploads$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadsActions.removeAllUploads),
      delayedConcatMap(() => [
        this.store.pipe(select(fromUploadsState.vault.getGroups)),
        this.store.pipe(select(fromUploadsState.vault.getFailedGroups)),
      ]),
      mergeMap(([, groups, failedGroups]) => {
        if (
          (!groups || groups.length === 0) &&
          (!failedGroups || failedGroups.length === 0)
        ) {
          return of(UploadsActions.reset());
        }

        return ofActions(
          ...(!groups || groups.length === 0 || this.electronRef // do not cancel when electron is present; as these uploads are persisted
            ? []
            : groups.map((g) =>
                UploadsActions.vault.cancel.init({
                  data: {
                    uploadGroupUuid: g.uuid,
                    localOnly: false,
                  },
                }),
              )),
          ...(!failedGroups || failedGroups.length === 0 || this.electronRef // do not cancel when electron is present; as these uploads are persisted
            ? []
            : groups.map((g) =>
                UploadsActions.vault.cancel.init({
                  data: {
                    uploadGroupUuid: g.uuid,
                    localOnly: false,
                  },
                }),
              )),
          UploadsActions.reset(),
        );
      }),
    ),
  );

  stopAllUploadsFlow$ = createFlowEffect((): Observable<Action> => {
    return combineLatest([
      this.store.pipe(
        select(fromUploadsState.vault.getGroups),
        map((groups) => groups && groups.map((g) => g.uuid)),
      ),
      this.store.pipe(
        select(fromUploadsState.vault.getFailedGroups),
        map((groups) => groups && groups.map((g) => g.uuid)),
      ),
    ]).pipe(
      take(1),
      mergeMap(([vaultGroupsIds, vaultFailedGroupsIds]): Observable<Action> => {
        const observables: Observable<Action>[] = [];

        if (vaultGroupsIds.length > 0 || vaultFailedGroupsIds.length > 0) {
          const actions = [...vaultGroupsIds, ...vaultFailedGroupsIds].map(
            (uploadGroupUuid) =>
              UploadsActions.vault.cancel.init({
                data: {
                  uploadGroupUuid,
                  localOnly: false,
                },
              }),
          );

          const completedAction$ = this.actions$.pipe(
            ofType(
              UploadsActions.vault.cancel.completed,
              UploadsActions.vault.cancel.error,
              UploadsActions.vault.cancel.cancel,
            ),
            filter((action) => !!actions.find((v) => v.id === action.id)),
            take(vaultGroupsIds.length),
            toArray(),
            mergeMap(() => EMPTY),
          );

          observables.push(completedAction$);
          observables.push(of(...actions));
        }

        return observables.length === 0 ? EMPTY : merge(...observables);
      }),
    );
  }, UploadsActions.stopAllUploadsFlow);

  stopAllUploadsCrossTabFlow$ = createFlowEffect(
    (): Observable<Action> => {
      const crossTabCancel$ = this.crossTabWorker
        .executeForAll(SharedUploadsEffects.cancelUploadsWorker, undefined)
        .pipe(
          catchError((e) => {
            withScope((scope) => {
              scope.setLevel('warning');
              captureException(
                toSentryError('VideoService::stopAllUploadsCrossTab', e),
              );
            });

            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return throwError(() => e);
          }),
          ignoreElements(),
        );

      const stopAllUploadsAction = UploadsActions.stopAllUploadsFlow.init({});

      const allCanceled$ = this.actions$.pipe(
        onFlowFinished(stopAllUploadsAction, UploadsActions.stopAllUploadsFlow),
        take(1),
        ignoreElements(),
      );

      const mergedCancel = merge(
        allCanceled$,
        crossTabCancel$,
        of(stopAllUploadsAction),
      ).pipe(
        timeout(SharedUploadsEffects.cancelUploadsTimeout),
        catchError((e) => {
          withScope((scope) => {
            scope.setLevel('warning');
            captureException(
              toSentryError('VideoService::stopAllUploadsCrossTab', e),
            );
          });

          if (e instanceof TimeoutError) {
            return EMPTY;
          }

          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return throwError(() => e);
        }),
      );

      return mergedCancel;
    },
    UploadsActions.stopAllUploadsCrossTabFlow,
    {
      actions$: this.actions$,
      timeout: 20000,
    },
  );

  cancelUploadsWorker = (): Observable<void> => {
    return this.store.pipe(
      select(fromUploadsState.vault.getAnyUploadNeedsUserAttention),
      take(1),
      concatMap((uploadInProgress) => {
        if (!uploadInProgress) {
          return EMPTY;
        }

        const stopAllUploadsAction = UploadsActions.stopAllUploadsFlow.init({});

        const allCanceled$ = this.actions$.pipe(
          onFlowFinished(
            stopAllUploadsAction,
            UploadsActions.stopAllUploadsFlow,
          ),
          take(1),
          mergeMap(() => EMPTY),
        );

        return forkJoin([
          allCanceled$,
          of(undefined).pipe(
            tap(() => {
              this.store.dispatch(stopAllUploadsAction);
            }),
          ),
        ]).pipe(mergeMap(() => EMPTY));
      }),
    );
  };

  constructor() {
    this.crossTabWorker.register(
      SharedUploadsEffects.cancelUploadsWorker,
      this.cancelUploadsWorker,
    );

    this.videoUploadHandlerService.enable();
  }
}
