import type { HttpErrorResponse } from '@angular/common/http';

import type { Action } from '@ngrx/store';
import { createAction, props } from '@ngrx/store';
import { ApiErrorResponse } from '@gv/api';
import type { SnackbarMessage } from '@gv/ui/toaster';
import type { SeverityLevel } from '@sentry/angular';

export interface SentryOptionsModel {
  tags?: { [tag: string]: any };
  extras?: { [extra: string]: any };
  level?: SeverityLevel;
}

export interface GenericError {
  action: Action;
  error: Error | HttpErrorResponse | ApiErrorResponse<any> | any;
  log?: boolean;
  logToSentry?: boolean;
  sentryOptions?: SentryOptionsModel;
  snackbarMessage?: SnackbarMessage;
}

const basename = '[Error]';

const generic = createAction(`${basename} generic`, props<GenericError>());

export const ErrorsActions = {
  generic,

  defaultWithSnackbar: (action: Action, error: any, defaultMessage: string) =>
    generic({
      action,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      error,
      logToSentry: true,
      log: true,
      snackbarMessage: {
        message:
          error instanceof ApiErrorResponse
            ? error.apiMessage?.message ?? defaultMessage
            : defaultMessage,
        config: {
          duration: 5000,
        },
      },
    }),
};
