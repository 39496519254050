import { computed, inject, signal } from '@angular/core';

import { EMPTY_STATE, StoreInject } from '@gv/state';
import { SnackBarService } from '@gv/ui/toaster';

export interface AppUpdateCheckerService {
  pause(): void;

  resume(): void;
}

export abstract class BaseUpdateCheckerService
  implements AppUpdateCheckerService
{
  protected enabled = false;
  protected snackBarService = inject(SnackBarService);
  protected store = inject(StoreInject(EMPTY_STATE));

  protected stateS = signal<{
    readonly checking: boolean;
    readonly downloading: boolean;
    readonly progress: number | undefined;
    readonly available: boolean;
    readonly paused: boolean;
    readonly ready: boolean;
    readonly data?: { version: string; forceUpdate: boolean };
  }>({
    checking: false,
    downloading: false,
    progress: undefined,
    available: false,
    paused: false,
    ready: false,
    data: undefined,
  });

  readonly updateAvailableS = computed(() => {
    const { checking, available, paused, downloading } = this.stateS();
    return available && !checking && !paused && !downloading;
  });

  readonly downloadingS = computed(() => {
    const { downloading, paused } = this.stateS();
    return downloading && !paused;
  });

  readonly updateReadyS = computed(() => {
    const { downloading, paused, ready } = this.stateS();
    return !downloading && !paused && ready;
  });

  readonly progressS = computed(() => {
    const { progress, ready } = this.stateS();
    return ready ? undefined : progress;
  });
  readonly dataS = computed(() => {
    return this.stateS().data;
  });

  pause(): void {
    this.stateS.update((state) => ({
      ...state,
      paused: true,
    }));
  }

  resume(): void {
    this.stateS.update((state) => ({
      ...state,
      paused: false,
    }));
  }

  abstract activateUpdate(): Promise<void>;

  abstract downloadUpdate(): Promise<void>;

  abstract checkForUpdates(triggeredByApi: boolean): Promise<boolean>;
}
