import { ceil, floor, round } from 'lodash-es';

const roundFunctions = {
  ceil,
  floor,
  round,
};

export class NumberUtils {
  static format(
    value: number,
    decimalPlaces = 2,
    stripTrailingZeros = true,
    op: keyof typeof roundFunctions = 'round',
  ): string {
    if (!value && value !== 0) {
      return '';
    }

    const fn = roundFunctions[op] || round;

    const rounded = fn(value, decimalPlaces);

    if (stripTrailingZeros) {
      return rounded.toString();
    }

    return rounded.toFixed(decimalPlaces);
  }

  static round(value: number, decimalPlaces = 2): number {
    return round(value, decimalPlaces);
  }

  static clamp(value: number, min: number, max: number): number {
    if (value < min) {
      return min;
    }

    if (value > max) {
      return max;
    }

    return value;
  }

  static dividerFloor(n: number, divider: number): number {
    return Math.floor(n / divider) * divider;
  }

  static dividerCeil(n: number, divider: number): number {
    return Math.ceil(n / divider) * divider;
  }

  static isNumeric(x: string | number) {
    return (
      (typeof x === 'number' || typeof x === 'string') && !isNaN(Number(x))
    );
  }
}
