import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgStore } from '@gv/state';

import { billingFeatureKey, BILLING_FEATURE_STATE } from '../store';
import { billingReducer } from '../store/reducers';
import { BillingEffects } from '../store/effects';
import { OrganizationEffects } from '../store/organization/effects';

@NgModule({
  imports: [
    StoreModule.forFeature(billingFeatureKey, billingReducer),
    EffectsModule.forFeature([BillingEffects, OrganizationEffects]),
  ],
  providers: [
    {
      provide: BILLING_FEATURE_STATE,
      useExisting: NgStore,
    },
  ],
})
export class BillingStateModule {}
