import { Injectable, inject } from '@angular/core';

import {
  AnalyticsCategories,
  AnalyticsNavigateActions,
  AngularticsActions,
} from '@gv/analytics';
import { StoreInject } from '@gv/state';
import { WindowRefService } from '@gv/ui/core';

import { APP_STATE } from '../../store/state/app-state';

@Injectable({
  providedIn: 'root',
})
export class ReloadService {
  private store = inject(StoreInject(APP_STATE));
  private windowRef = inject(WindowRefService);

  enable(): void {
    if (this.wasPageReloaded()) {
      this.store.dispatch(
        AngularticsActions.event({
          category: AnalyticsCategories.Navigate,
          action: AnalyticsNavigateActions.Reload,
          options: {
            label: window.location.pathname,
          },
        }),
      );
    }
  }

  private wasPageReloaded(): boolean {
    const window = this.windowRef.nativeWindow;

    if (window) {
      const navigationList = window.performance.getEntriesByType
        ? window.performance.getEntriesByType('navigation')
        : undefined;

      if (navigationList && navigationList.length > 0) {
        const navigationEntry =
          navigationList[0] as PerformanceNavigationTiming;
        const pageLoadType = navigationEntry.type;

        return pageLoadType === 'reload';
        // eslint-disable-next-line deprecation/deprecation
      } else if (window.performance.navigation) {
        return (
          // eslint-disable-next-line deprecation/deprecation
          window.performance.navigation.type ===
          // eslint-disable-next-line deprecation/deprecation
          window.performance.navigation.TYPE_RELOAD
        );
      }
    }

    return false;
  }
}
