import { ErrorHandler, NgModule } from '@angular/core';

import { AppErrorHandlerService } from '../../service/application/app-error-handler.service';

@NgModule({
  providers: [
    {
      provide: ErrorHandler,
      useClass: AppErrorHandlerService,
    },
  ],
})
export class AppErrorHandlingModule {}
