import { Injectable } from '@angular/core';

import { ObservableUtils } from '@gv/utils';
import type { Event, EventProcessor, Hub, Integration } from '@sentry/types';
import { normalizeToSize } from '@sentry/utils';
import { Subject } from 'rxjs';
import { buffer, debounceTime, filter, map, share } from 'rxjs/operators';

import { logger } from '../../../../logger';

@Injectable({
  providedIn: 'root',
})
export class SentryAggregateIntegrationService implements Integration {
  name = 'AggregateIntegration';

  logoutEventsSubject = new Subject<Event>();

  logoutEvents$ = this.logoutEventsSubject.pipe(
    buffer(this.logoutEventsSubject.pipe(debounceTime(4000))),
    filter((v) => v.length > 0),
    map((events) => {
      const event = events[events.length - 1];

      if (!event.extra) {
        event.extra = {};
      }

      event.extra.nested_errors = normalizeToSize(
        events.map(
          (event) =>
            (event.extra?.__serialized__ as any)?.url ?? event.extra?.url,
        ),
      );

      return event;
    }),
    share(),
  );

  static id = 'AggregateIntegration';

  setupOnce(
    addGlobalEventProcessor: (callback: EventProcessor) => void,
    getCurrentHub: () => Hub,
  ): void {
    addGlobalEventProcessor((event: Event) => {
      const self = getCurrentHub().getIntegration(
        SentryAggregateIntegrationService,
      );

      if (self) {
        try {
          if (
            event.extra?.errorCode === 401 ||
            (event.extra?.__serialized__ as any)?.status === 401
          ) {
            const eventPromise = ObservableUtils.toPromise(
              self.logoutEvents$,
            ).then((e) => (event === e ? e : null));

            self.logoutEventsSubject.next(event);

            return eventPromise;
          }
        } catch (e) {
          logger.error({ error: e }, 'Failed to aggregate sentry event');
        }
      }

      return event;
    });
  }
}
