// tslint:disable no-bitwise
export enum PermissionScope {
  Empty = 0,
  All = ~0,

  Generic = 1 << 0, // used when no other action make sense

  Use = 1 << 1,
  Create = 1 << 2,
  Edit = 1 << 3,
  Delete = 1 << 4,
  Share = 1 << 7,
  EditSubset = 1 << 8,
  Show = 1 << 9,

  // dialogs
  Submit = 1 << 5,
  Open = 1 << 6,
}
