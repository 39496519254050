import type {
  ClassSchemeDetailDTO,
  CreateClassificationSchemeModel,
  EditClassScheme,
} from '@gv/api';
import { createDialogBaseActions, createFlowBaseActions } from '@gv/state';
import { props, createAction } from '@ngrx/store';

import type {
  CustomObjectClassInputModel,
  CustomObjectClassModel,
  ObjectClassCoefficientInputModel,
  ObjectClassCoefficientsModel,
} from '../entity';

const basename = '[Scheme]';

// dialog actions
export const showClassificationScheme = createDialogBaseActions(
  basename,
  'ClassificationScheme',
  {
    openProps: props<{
      data: { scheme: ClassSchemeDetailDTO; dialogWidth: string };
    }>(),
  },
);

export const createCustomSchemeDialog = createDialogBaseActions(
  basename,
  'CustomScheme',
  {
    openProps: props<{
      data: ClassSchemeDetailDTO & { reduced?: boolean };
    }>(),
    closedProps: props<{
      readonly data: ClassSchemeDetailDTO;
    }>(),
  },
);

export const editClassificationSchemeDialog = createDialogBaseActions(
  basename,
  'EditClassificationScheme',
  {
    openProps: props<{
      data: {
        scheme: ClassSchemeDetailDTO & { projectUuid?: string };
        defaultScheme: ClassSchemeDetailDTO;
      };
    }>(),
    closedProps: props<{
      readonly data: ClassSchemeDetailDTO;
    }>(),
  },
);

export const deleteClassificationSchemeDialog = createDialogBaseActions(
  basename,
  'DeleteClassificationScheme',
  {
    openProps: props<{
      data: ClassSchemeDetailDTO;
    }>(),
    closedProps: props<{
      data: boolean;
    }>(),
  },
);

export const createObjectClassDialog = createDialogBaseActions(
  basename,
  'CreateObjectClass',
  {
    openProps: props<{
      data: CustomObjectClassInputModel;
    }>(),
    closedProps: props<{
      readonly data: CustomObjectClassModel;
    }>(),
  },
);

export const setCoefficientsDialog = createDialogBaseActions(
  basename,
  'ClassCoefficients',
  {
    openProps: props<{
      data: ObjectClassCoefficientInputModel;
    }>(),
    closedProps: props<{
      readonly data: ObjectClassCoefficientsModel;
    }>(),
  },
);

// flow actions
export const createCustomSchemeFlow = createFlowBaseActions(
  basename,
  'create custom scheme',
  {
    initProps: props<{
      data: CreateClassificationSchemeModel;
    }>(),
    completedProps: props<{
      data: ClassSchemeDetailDTO;
    }>(),
  },
);

export const editCustomSchemeFlow = createFlowBaseActions(
  basename,
  'edit custom scheme',
  {
    initProps: props<{
      data: {
        uuid: string;
        schemeModel: EditClassScheme;
        dataSourceUuid?: string; // todo: changed to dataSourceUuid
      };
    }>(),
    completedProps: props<{
      data: ClassSchemeDetailDTO;
    }>(),
  },
);

export const deleteCustomSchemeFlow = createFlowBaseActions(
  basename,
  'delete custom scheme',
  {
    initProps: props<{
      data: { uuid: string };
    }>(),
    completedProps: props<{
      data: string;
    }>(),
  },
);

export const createObjectClassFlow = createFlowBaseActions(
  basename,
  'create object class',
  {
    initProps: props<{
      data: CustomObjectClassModel;
    }>(),
    completedProps: props<{
      data: CustomObjectClassModel;
    }>(),
  },
);

export const setCoefficientsFlow = createFlowBaseActions(
  basename,
  'set coefficients',
  {
    initProps: props<{
      data: ObjectClassCoefficientsModel;
    }>(),
    completedProps: props<{
      data: ObjectClassCoefficientsModel;
    }>(),
  },
);

// actions
export const addScheme = createAction(
  `${basename} add`,
  props<{ scheme: ClassSchemeDetailDTO }>(),
);

export const editScheme = createAction(
  `${basename} edit`,
  props<{ data: ClassSchemeDetailDTO }>(),
);

export const removeScheme = createAction(
  `${basename} remove`,
  props<{ uuid: string }>(),
);

export const markSchemeAsUsed = createAction(
  `${basename} mark as used`,
  props<{ uuid: string; used: boolean }>(),
);
