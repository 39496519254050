import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type { Draft } from 'immer';
import { produce } from 'immer';
import { isEqual } from 'lodash-es';
import { asIndexed, asMutable } from '@gv/state';
import type {
  VaultStateFileModel,
  LocalFileUploadItemModel,
  UploadGroupModel,
} from '@gv/upload/types';
import {
  VaultStateFileState,
  VaultUploadGroupState,
  UploadType,
} from '@gv/upload/types';
import { removeFromArray } from '@gv/utils';
import { calculateChunkCountFromFileSize } from '@gv/upload/core';

import { UploadStateUtils } from '../upload-state-utils';
import { UploadsActions } from '../action';
import type { UploadsState } from '../uploads.state';
import { initialUploadsState } from '../uploads.state';

export const uploadsFeatureKey = 'uploads';

const initialUploadGroup = {
  state: VaultUploadGroupState.Inserted,
  fullMetadata: [],
  initialized: [],
  metadata: [],
  encoded: [],
  encoding: [],
  uploaded: [],
  uploading: [],
  canceled: [],
  failed: [],
  serverData: undefined,
  type: UploadType.VAULT,
};

function calculateTotalSize(
  files: readonly LocalFileUploadItemModel[],
): number {
  return files.reduce((acc, f) => acc + f.size, 0);
}

function cancelGroupFile(
  uuid: string,
  group: Draft<UploadGroupModel>,
  state: Draft<UploadsState>,
): void {
  const file = group.files[uuid];

  if (UploadStateUtils.isSet(file.state, VaultStateFileState.Canceled)) {
    return;
  }

  file.state = UploadStateUtils.set(file.state, VaultStateFileState.Canceled);

  file.state = UploadStateUtils.unset(
    file.state,
    VaultStateFileState.Uploading,
    VaultStateFileState.Encoding,
    VaultStateFileState.Failed,
  );

  delete file.retryDelay;
  file.remainingParts = file.parts;

  removeFromArray(group.initialized, uuid);
  removeFromArray(group.metadata, uuid);
  removeFromArray(group.fullMetadata, uuid);
  removeFromArray(group.uploading, uuid);
  removeFromArray(group.uploaded, uuid);
  removeFromArray(group.failed, uuid);
  removeFromArray(group.encoded, uuid);
  removeFromArray(group.encoding, uuid);
  removeFromArray(group.remainingFiles, uuid);

  group.canceled.push(uuid);

  const size = file.encoded?.size || file.data.size;

  group.totalSize -= size;
  group.remainingSize -= size;

  delete state.progress[uuid];
}

const uploadsReducer = createReducer(
  initialUploadsState,

  on(UploadsActions.reset, (): UploadsState => initialUploadsState),

  on(UploadsActions.setProgress, (state, { uuid, progress }): UploadsState => {
    if (isEqual(state.progress[uuid], progress)) {
      return state;
    }

    return {
      ...state,
      progress: {
        ...state.progress,
        [uuid]: progress,
      },
    };
  }),

  on(
    UploadsActions.vault.restore.completed,
    (state, { data }): UploadsState => {
      if (!data || data.length === 0) {
        return state;
      }

      return produce(state, (draft) => {
        for (const group of data) {
          if (
            !draft.uploadGroupsIds.includes(group.uuid) &&
            !draft.failedGroupsIds.includes(group.uuid) &&
            !draft.canceledGroupsIds.includes(group.uuid) &&
            !draft.finishedGroupsIds.includes(group.uuid)
          ) {
            draft.uploadGroupsIds.push(group.uuid);
            draft.uploadGroups[group.uuid] = <Draft<typeof group>>group;
          }
        }
      });
    },
  ),

  on(
    UploadsActions.vault.init,
    (
      state,
      { data, uploadGroupUuid, projectName, projectUuid },
    ): UploadsState => {
      const directory = data.prefix ? data.prefix : null;

      const uploadGroup: UploadGroupModel = {
        ...initialUploadGroup,
        uuid: uploadGroupUuid,
        filesIds: data.files.map((f) => f.uuid),
        directory: directory,
        isDirectory: !!data.prefix, // here we do not use data.isDirectory as that value is for server side use (we do not want to expand uploads to the given folder)
        remainingFiles: data.files.map((f) => f.uuid),
        totalSize: calculateTotalSize(data.files),
        remainingSize: calculateTotalSize(data.files),
        projectName,
        projectUuid,
        rootPrefix: data.rootPrefix,
        files: asIndexed(
          data.files.map(
            (f): VaultStateFileModel => ({
              uuid: f.uuid,
              data: f,
              willEncode: undefined,
              directory: data.prefix,
              fullMetadata: undefined,
              metadata: undefined,
              serverData: undefined,
              state: VaultStateFileState.Inserted,
              uploadGroupUuid,
              finishUrl: undefined,
              errorReason: undefined,
              encodingEnded: undefined,
              encodingStarted: undefined,
              uploadEnded: undefined,
              uploadStarted: undefined,
              presetPath: undefined,
              parts: undefined,
              remainingParts: undefined,
            }),
          ),
          (f) => f.uuid,
        ),
        name: directory,
      };

      return {
        ...state,
        uploadGroups: {
          ...state.uploadGroups,
          [uploadGroupUuid]: uploadGroup,
        },
        uploadGroupsIds: [...state.uploadGroupsIds, uploadGroupUuid],
      };
    },
  ),

  on(
    UploadsActions.vault.create.completed,
    (state, { uploadGroupUuid, data }): UploadsState => {
      if (!state.uploadGroups[uploadGroupUuid]) {
        return state;
      }

      return produce(state, (draft) => {
        const group = draft.uploadGroups[uploadGroupUuid];
        group.state = VaultUploadGroupState.Created;

        group.serverData = {
          directory: data.directory,
          uploadId: data.uploadId,
        };

        group.name = data.directory.prefix;

        group.initialized = [...group.filesIds];

        data.files.forEach((f, index) => {
          const file = group.files[group.filesIds[index]];

          file.state = UploadStateUtils.set(
            file.state,
            VaultStateFileState.Created,
          );
          file.parts = calculateChunkCountFromFileSize(file.data.size);
          file.remainingParts = file.parts;

          file.serverData = {
            fileUuid: f.fileUuid,
            filename: f.filename,

            // not ready yet
            hash: undefined,
            presignedUrls: undefined,
            alreadyUploaded: undefined,
            uploadId: undefined,
            existingParts: undefined,
            dtModified: undefined,
          };
        });
      });
    },
  ),

  on(
    UploadsActions.vault.create.error,
    (state, { uploadGroupUuid }): UploadsState => {
      if (!state.uploadGroups[uploadGroupUuid]) {
        return state;
      }

      return produce(state, (draft) => {
        removeFromArray(draft.uploadGroupsIds, uploadGroupUuid);
        delete draft[uploadGroupUuid];
      });
    },
  ),

  on(
    UploadsActions.vault.cancel.completed,
    (state, { data: { uploadGroupUuid } }): UploadsState => {
      if (!state.uploadGroups[uploadGroupUuid]) {
        return state;
      }

      return produce(state, (draft) => {
        const group = draft.uploadGroups[uploadGroupUuid];
        group.state = VaultUploadGroupState.Canceled;

        draft.canceledGroupsIds.push(uploadGroupUuid);
        removeFromArray(draft.uploadGroupsIds, uploadGroupUuid);
        removeFromArray(draft.failedGroupsIds, uploadGroupUuid);

        state.uploadGroups[uploadGroupUuid].remainingFiles.forEach((uuid) => {
          cancelGroupFile(uuid, group, draft);
        });
      });
    },
  ),

  on(
    UploadsActions.vault.encode.completed,
    (state, { uploadGroupUuid, uuid, data }): UploadsState => {
      if (
        !state.uploadGroups[uploadGroupUuid] ||
        !state.uploadGroups[uploadGroupUuid].files[uuid]
      ) {
        return state;
      }

      return produce(state, (draft) => {
        const group = draft.uploadGroups[uploadGroupUuid];

        const file = group.files[uuid];
        file.state = UploadStateUtils.set(
          UploadStateUtils.unset(file.state, VaultStateFileState.Encoding),
          VaultStateFileState.Encoded,
        );
        file.encoded = data;
        delete file.retryDelay;
        file.parts = calculateChunkCountFromFileSize(data.file.size);
        file.remainingParts = file.parts;
        file.encodingEnded = new Date();

        if (data?.file) {
          group.remainingSize -= file.data.size;
          group.totalSize -= file.data.size;
          group.remainingSize += data.file.size;
          group.totalSize += data.size;
        }

        removeFromArray(group.encoding, uuid);
        delete draft.progress[uuid];
        group.encoded.push(uuid);
      });
    },
  ),

  on(
    UploadsActions.vault.encode.started,
    (state, { uploadGroupUuid, uuid, presetPath }): UploadsState => {
      if (
        !state.uploadGroups[uploadGroupUuid] ||
        !state.uploadGroups[uploadGroupUuid].files[uuid]
      ) {
        return state;
      }

      return produce(state, (draft) => {
        const group = draft.uploadGroups[uploadGroupUuid];

        const file = group.files[uuid];

        file.willEncode = true;

        file.state = UploadStateUtils.set(
          file.state,
          VaultStateFileState.Encoding,
        );

        file.encodingStarted = new Date();
        file.presetPath = presetPath;
        delete file.encodingEnded;

        removeFromArray(group.initialized, uuid);

        group.encoding.push(uuid);

        draft.progress[uuid] = { progress: 0, type: 'encode', eta: undefined };
      });
    },
  ),

  on(
    UploadsActions.vault.encode.optOut,
    (state, { uploadGroupUuid, uuid }): UploadsState => {
      if (
        !state.uploadGroups[uploadGroupUuid] ||
        !state.uploadGroups[uploadGroupUuid].files[uuid]
      ) {
        return state;
      }

      return produce(state, (draft) => {
        const group = draft.uploadGroups[uploadGroupUuid];

        const file = group.files[uuid];

        file.willEncode = false;

        file.state = UploadStateUtils.set(
          file.state,
          VaultStateFileState.Encoded,
        );

        file.encoded = undefined;

        delete file.retryDelay;

        removeFromArray(group.initialized, uuid);

        group.encoded.push(uuid);
      });
    },
  ),

  on(
    UploadsActions.vault.basicMetadata.completed,
    (state, { uploadGroupUuid, uuid, data }): UploadsState => {
      if (
        !state.uploadGroups[uploadGroupUuid] ||
        !state.uploadGroups[uploadGroupUuid].files[uuid]
      ) {
        return state;
      }

      return produce(state, (draft) => {
        const group = draft.uploadGroups[uploadGroupUuid];

        const file = group.files[uuid];
        file.state = UploadStateUtils.set(
          file.state,
          VaultStateFileState.BasicMetadataLoaded,
        );
        file.metadata = data;
        delete file.retryDelay;

        removeFromArray(group.encoded, uuid);

        group.metadata.push(uuid);
      });
    },
  ),

  on(
    UploadsActions.vault.fullMetadata.init,
    (state, { uploadGroupUuid, uuid }): UploadsState => {
      if (
        !state.uploadGroups[uploadGroupUuid] ||
        !state.uploadGroups[uploadGroupUuid].files[uuid]
      ) {
        return state;
      }

      return produce(state, (draft) => {
        const group = draft.uploadGroups[uploadGroupUuid];

        const file = group.files[uuid];
        file.state = UploadStateUtils.set(
          file.state,
          VaultStateFileState.FullMetadataLoading,
        );
      });
    },
  ),

  on(
    UploadsActions.vault.fullMetadata.completed,
    (state, { uploadGroupUuid, uuid, data }): UploadsState => {
      if (
        !state.uploadGroups[uploadGroupUuid] ||
        !state.uploadGroups[uploadGroupUuid].files[uuid]
      ) {
        return state;
      }

      return produce(state, (draft) => {
        const group = draft.uploadGroups[uploadGroupUuid];

        const file = group.files[uuid];
        file.state = UploadStateUtils.set(
          UploadStateUtils.unset(
            file.state,
            VaultStateFileState.FullMetadataLoading,
          ),
          VaultStateFileState.FullMetadataLoaded,
        );
        file.fullMetadata = data;
        delete file.retryDelay;

        removeFromArray(group.metadata, uuid);

        group.fullMetadata.push(uuid);
      });
    },
  ),

  on(
    UploadsActions.vault.presign.completed,
    (state, { uploadGroupUuid, uuid, data, finishUrl }): UploadsState => {
      if (
        !state.uploadGroups[uploadGroupUuid] ||
        !state.uploadGroups[uploadGroupUuid].files[uuid]
      ) {
        return state;
      }

      return produce(state, (draft) => {
        const group = draft.uploadGroups[uploadGroupUuid];

        const file = group.files[uuid];

        file.state = UploadStateUtils.set(
          file.state,
          VaultStateFileState.Presigned,
        );
        file.serverData = asMutable(data);
        file.finishUrl = finishUrl;

        const dataFile = file.encoded?.file || file.data.file;
        file.parts = calculateChunkCountFromFileSize(dataFile.size);
        file.remainingParts = file.parts - (data.existingParts?.length ?? 0);
        delete file.retryDelay;

        removeFromArray(group.fullMetadata, uuid);

        group.state = VaultUploadGroupState.Uploading;

        group.uploading.push(uuid);
      });
    },
  ),

  on(
    UploadsActions.vault.uploadFile.started,
    (state, { uploadGroupUuid, uuid }): UploadsState => {
      if (
        !state.uploadGroups[uploadGroupUuid] ||
        !state.uploadGroups[uploadGroupUuid].files[uuid]
      ) {
        return state;
      }

      return produce(state, (draft) => {
        const group = draft.uploadGroups[uploadGroupUuid];

        const file = group.files[uuid];

        file.state = UploadStateUtils.set(
          file.state,
          VaultStateFileState.Uploading,
        );
        delete file.retryDelay;
        file.uploadStarted = new Date();
        delete file.uploadEnded;

        group.state = VaultUploadGroupState.Uploading;

        draft.progress[uuid] = {
          progress: 0,
          type: 'upload',
        };
      });
    },
  ),

  on(
    UploadsActions.vault.uploadFile.completed,
    (state, { uploadGroupUuid, uuid }): UploadsState => {
      if (
        !state.uploadGroups[uploadGroupUuid] ||
        !state.uploadGroups[uploadGroupUuid].files[uuid]
      ) {
        return state;
      }

      return produce(state, (draft) => {
        const group = draft.uploadGroups[uploadGroupUuid];

        const file = group.files[uuid];

        file.state = UploadStateUtils.set(
          file.state,
          VaultStateFileState.Uploaded,
        );

        file.state = UploadStateUtils.unset(
          file.state,
          VaultStateFileState.Uploading,
        );
        delete file.retryDelay;

        file.uploadEnded = new Date();

        group.state = VaultUploadGroupState.Created;

        removeFromArray(group.uploading, uuid);
        removeFromArray(group.remainingFiles, uuid);

        const size = file.encoded?.size || file.data.size;
        group.remainingSize -= size;

        group.uploaded.push(uuid);

        delete draft.progress[uuid];
      });
    },
  ),

  on(
    UploadsActions.vault.uploadFile.failed,
    (state, { uploadGroupUuid, uuid, reason }): UploadsState => {
      if (
        !state.uploadGroups[uploadGroupUuid] ||
        !state.uploadGroups[uploadGroupUuid].files[uuid]
      ) {
        return state;
      }

      return produce(state, (draft) => {
        const group = draft.uploadGroups[uploadGroupUuid];

        const file = group.files[uuid];

        file.state = UploadStateUtils.set(
          file.state,
          VaultStateFileState.Failed,
        );

        file.state = UploadStateUtils.unset(
          file.state,
          VaultStateFileState.Uploading,
        );
        delete file.retryDelay;

        file.errorReason = reason;

        removeFromArray(group.uploading, uuid);
        removeFromArray(group.remainingFiles, uuid);

        group.failed.push(uuid);

        group.state = VaultUploadGroupState.Created;

        const size = file.encoded?.size || file.data.size;
        group.remainingSize -= size;
        group.totalSize -= size;

        delete draft.progress[uuid];
      });
    },
  ),

  on(
    UploadsActions.vault.uploadFile.cancel.completed,
    (state, { data: { uploadGroupUuid, uuid } }): UploadsState => {
      if (
        !state.uploadGroups[uploadGroupUuid] ||
        !state.uploadGroups[uploadGroupUuid].files[uuid]
      ) {
        return state;
      }

      return produce(state, (draft) => {
        const group = draft.uploadGroups[uploadGroupUuid];

        if (
          UploadStateUtils.isSet(
            group.files[uuid].state,
            VaultStateFileState.Uploading,
          )
        ) {
          group.state = VaultUploadGroupState.Created;
        }

        cancelGroupFile(uuid, group, draft);
      });
    },
  ),

  on(
    UploadsActions.vault.finish,
    (state, { uploadGroupUuid }): UploadsState => {
      if (!state.uploadGroups[uploadGroupUuid]) {
        return state;
      }

      return produce(state, (draft) => {
        const group = draft.uploadGroups[uploadGroupUuid];

        group.state = group.failed.length
          ? VaultUploadGroupState.Failed
          : VaultUploadGroupState.Uploaded;

        if (group.failed.length) {
          draft.failedGroupsIds.push(group.uuid);
        } else {
          draft.finishedGroupsIds.push(group.uuid);
        }

        removeFromArray(draft.uploadGroupsIds, group.uuid);
      });
    },
  ),

  on(
    UploadsActions.vault.error,
    (state, { uploadGroupUuid, uuid, reason }): UploadsState => {
      if (
        !state.uploadGroups[uploadGroupUuid] ||
        !state.uploadGroups[uploadGroupUuid].files[uuid]
      ) {
        return state;
      }

      return produce(state, (draft) => {
        const group = draft.uploadGroups[uploadGroupUuid];

        const file = group.files[uuid];

        file.state = UploadStateUtils.set(
          file.state,
          VaultStateFileState.Failed,
        );

        file.state = UploadStateUtils.unset(
          file.state,
          VaultStateFileState.Uploading,
        );
        delete file.retryDelay;

        file.errorReason = reason;

        removeFromArray(group.initialized, uuid);
        removeFromArray(group.metadata, uuid);
        removeFromArray(group.fullMetadata, uuid);
        removeFromArray(group.uploaded, uuid);
        removeFromArray(group.remainingFiles, uuid);
        removeFromArray(group.uploading, uuid);
        removeFromArray(group.encoding, uuid);
        removeFromArray(group.encoded, uuid);

        const size = file.encoded?.size || file.data.size;
        group.remainingSize -= size;
        group.totalSize -= size;

        group.failed.push(uuid);

        delete draft.progress[uuid];
      });
    },
  ),

  on(UploadsActions.vault.retry, (state, action): UploadsState => {
    const { retryDelay } = action;
    // when uploadGroupUuid/uuid is not defined it will retry all in the given context

    if (action.uploadGroupUuid && !state.uploadGroups[action.uploadGroupUuid]) {
      return state;
    }

    const uploadGroupsIds = action.uploadGroupUuid
      ? [action.uploadGroupUuid]
      : state.failedGroupsIds;

    return produce(state, (draft) => {
      for (const uploadGroupUuid of [...uploadGroupsIds].reverse()) {
        const group = draft.uploadGroups[uploadGroupUuid];

        const uuids = action.uuid
          ? [action.uuid]
          : state.uploadGroups[uploadGroupUuid].failed;

        for (const uuid of [...uuids].reverse()) {
          const file = group.files[uuid];

          if (file) {
            file.state = VaultStateFileState.Created;
            file.errorReason = undefined;
            file.retryDelay = retryDelay;

            removeFromArray(group.failed, uuid);

            group.remainingFiles.unshift(uuid);

            if (file.encoded) {
              group.encoded.unshift(uuid);
              file.state = UploadStateUtils.set(
                file.state,
                VaultStateFileState.Encoded,
              );
            } else {
              group.initialized.unshift(uuid);
            }

            const size = file.encoded?.size || file.data.size;
            group.remainingSize += size;
            group.totalSize += size;
          }
        }

        if (draft.failedGroupsIds.includes(uploadGroupUuid)) {
          removeFromArray(draft.failedGroupsIds, uploadGroupUuid);
          draft.uploadGroupsIds.unshift(uploadGroupUuid);
          group.state = VaultUploadGroupState.Created;
        }
      }
    });
  }),

  on(
    UploadsActions.vault.cleanup,
    (state, { uploadGroupUuid }): UploadsState => {
      if (!state.uploadGroups[uploadGroupUuid]) {
        return state;
      }

      return produce(state, (draft) => {
        removeFromArray(draft.canceledGroupsIds, uploadGroupUuid);
        removeFromArray(draft.finishedGroupsIds, uploadGroupUuid);
        delete draft.uploadGroups[uploadGroupUuid];
      });
    },
  ),

  on(
    UploadsActions.setCompletedChunks,
    (state, { uploadGroupUuid, uuid, completedChunks }): UploadsState => {
      const group = state.uploadGroups[uploadGroupUuid];
      if (!group) {
        return state;
      }

      const file = group.files[uuid];

      if (!file || file.remainingParts === file.parts - completedChunks) {
        return state;
      }

      return {
        ...state,
        uploadGroups: {
          ...state.uploadGroups,
          [group.uuid]: {
            ...group,
            files: {
              ...group.files,
              [file.uuid]: {
                ...file,
                remainingParts: file.parts - completedChunks,
              },
            },
          },
        },
      };
    },
  ),
);

export function reducer(
  state: UploadsState | undefined,
  action: Action,
): UploadsState {
  return uploadsReducer(state, action);
}
