import type { Routes } from '@angular/router';
import { Router, RouterModule } from '@angular/router';
import { inject } from '@angular/core';

import { EmptyRoutingComponent } from './../../component/page/empty/empty-routing.component';
import { MaintenancePageComponent } from '../../component/page/maintenance-page/maintenance-page.component';
import { PreventActivationDuringUploadGuard } from '../../service/application/routing/guard/uploading/prevent-activation-during-upload.guard';
import { RestorePasswordComponent } from '../../component/page/restore-password/restore-password.component';
import { queryParamsMergingGuard } from '../../service/application/routing/guard/query-params-merging.guard';
import { UserService } from '../../service/application/user/user.service';
import { TokenLoginGuard } from '../../service/application/routing/guard/token-login-guard';

const appRoutes: Routes = [
  {
    path: '',
    canActivateChild: [queryParamsMergingGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        canActivate: [
          TokenLoginGuard,
          () => {
            const userService = inject(UserService);
            const router = inject(Router);
            return router.createUrlTree([userService.user ? '/app' : '/login']);
          },
        ],
        component: EmptyRoutingComponent,
      },
      {
        path: 'user-settings/billing/plans/cart/:request',
        redirectTo: '/app/user-settings/billing/cart/:request',
      },
      {
        path: 'app',
        loadChildren: () =>
          import('../feature/layout/layout.module').then((m) => m.LayoutModule),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import(
            '../feature/user-management/authentication/forgotten-password/forgotten-password.module'
          ).then((m) => m.ForgottenPasswordModule),
      },
      {
        path: 'login',
        children: [
          {
            path: '',
            pathMatch: 'full',
            data: {
              title: 'Login',
            },
            loadComponent: () =>
              import('../../component/page/login/login.component').then(
                (m) => m.LoginComponent,
              ),
          },
          {
            path: 'pair',
            pathMatch: 'full',
            data: {
              title: 'Login',
            },
            loadComponent: () =>
              import('../../component/page/login/login-confirm.component').then(
                (m) => m.LoginConfirmComponent,
              ),
          },
          {
            path: 'denied',
            pathMatch: 'full',
            data: {
              title: 'Registration denied',
            },
            loadComponent: () =>
              import('../../component/page/login/login-denied.component').then(
                (m) => m.LoginDeniedComponent,
              ),
          },
        ],
      },
      {
        path: 'restore-password/:request',
        component: RestorePasswordComponent,
        pathMatch: 'full',
        data: {
          title: 'Restore password',
        },
      },
      {
        path: 'logout',
        canActivate: [PreventActivationDuringUploadGuard],
        pathMatch: 'full',
        data: {
          title: 'Logout',
        },
        loadComponent: () =>
          import('../../component/page/logout/logout.component').then(
            (m) => m.LogoutComponent,
          ),
      },
      {
        path: 'sign-up',
        loadChildren: () =>
          import('../feature/user-management/sign-up/sign-up.module').then(
            (m) => m.SignUpModule,
          ),
      },
      {
        path: 'platform-plans',
        loadComponent: () =>
          import('../../entity/constant/lazy-billing').then(
            (m) => m.PlatformPlansDetailedComponent,
          ),
        pathMatch: 'full',
        data: {
          title: 'Platform plans',
        },
      },
      {
        path: 'maintenance-page',
        component: MaintenancePageComponent,
        pathMatch: 'full',
        data: {
          title: 'Maintenance page',
        },
      },
      {
        // if user is not logged in, it redirects to login page,
        // otherwise LoginComponent automatically redirects user to default page
        // via RouterHelperService.getDefaultDataSourceRouterLinkSegments(user)
        path: '**',
        redirectTo: 'login',
        pathMatch: 'full',
      },
    ],
  },
];

export const AppRoutingModule = RouterModule.forRoot(appRoutes, {
  onSameUrlNavigation: 'reload',
});
