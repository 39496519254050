import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';

import { ElectronRefService } from '@gv/desktop/core';
import { SlugHashUtils } from '@gv/ui/core';
import { untilNgDestroyed } from '@gv/utils';
import { combineLatest } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { SimpleDialogModule, SubmittableDialogComponent } from '@gv/ui/dialog';
import { TestIdModule } from '@gv/debug';
import { APP_UPDATE_CHECKER_SERVICE } from '@gv/ui/utils';

enum UpdateState {
  Available,
  Downloading,
  Done,
  DoneInitially,
}

@Component({
  standalone: true,
  imports: [
    SimpleDialogModule,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    MatProgressBarModule,
    MatDialogModule,
    TestIdModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './update-dialog.component.html',
  selector: 'app-update-dialog',
})
export class UpdateDialogComponent
  extends SubmittableDialogComponent<void, void, boolean>
  implements OnInit
{
  private updateService = inject(APP_UPDATE_CHECKER_SERVICE);

  private electronRefService = inject(ElectronRefService, { optional: true });
  electron = !!this.electronRefService;
  readonly UpdateState = UpdateState;

  state: UpdateState = UpdateState.Available;

  private updateState$ = combineLatest([
    toObservable(this.updateService.downloadingS),
    toObservable(this.updateService.updateReadyS),
  ]).pipe(untilNgDestroyed());

  private state$ = this.submitSubject.pipe(switchMap(() => this.updateState$));

  readonly progressS = this.updateService.progressS;

  SlugHashUtils = SlugHashUtils;

  ngOnInit(): void {
    const updateState =
      (initial: boolean) =>
      ([downloading, updateReady]) => {
        if (updateReady) {
          this.state = initial ? UpdateState.DoneInitially : UpdateState.Done;
        } else if (downloading) {
          this.state = UpdateState.Downloading;
        } else {
          this.state = UpdateState.Available;
        }

        this.changeDetector.markForCheck();
      };

    // set initial state and then unsubsribe
    this.updateState$.pipe(take(1)).subscribe(updateState(true));

    // update state after the first click
    this.state$.subscribe(updateState(false));
  }

  override ngOnDestroy(): void {
    //
  }

  download(): void {
    this.submitSubject.next();
  }

  reload(): void {
    this.dialogRef.close(true);
  }
}
