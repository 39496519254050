import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { NgTemplateOutlet } from '@angular/common';

import { TruncatedComponent } from '@gv/ui/utils';

import {
  DialogActionsComponent,
  DialogHelpDirective,
  DialogWarningDirective,
} from '../dialog-actions.component';
import { DialogContentComponent } from '../dialog-content.component';
import { DialogHeaderComponent } from '../dialog-header.component';
import {
  BASE_DIALOG_STYLES,
  DialogBaseComponent,
} from '../dialog-base.component';
import {
  BaseVariantDialog,
  DialogActions,
  DialogContent,
} from './base-variant-dialog.component';

@Component({
  standalone: true,
  selector: `gv-simple-dialog`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,
    DialogActionsComponent,
    DialogContentComponent,
    DialogHeaderComponent,
    DialogBaseComponent,
    NgTemplateOutlet,
    DialogWarningDirective,
    DialogHelpDirective,
    TruncatedComponent,
  ],
  viewProviders: [DialogActions],
  template: `
    <gv-dialog-component>
      @if (text) {
        <gv-dialog-header
          [disableClose]="disableClose"
          [disableButton]="disableButton"
          (close)="close.emit()"
        >
          <div
            class="flex w-full min-w-[40px] flex-col justify-center truncate"
          >
            <app-truncated [text]="text" />
            @if (subtitle) {
              <span
                class="w-full min-w-0 truncate text-sm"
                [innerHtml]="subtitle"
              ></span>
            }
          </div>
        </gv-dialog-header>
      }

      @if (dialogContent) {
        <gv-dialog-content>
          <ng-container
            *ngTemplateOutlet="dialogContent.template"
          ></ng-container>
        </gv-dialog-content>
      }

      @if (dialogActions) {
        <gv-dialog-actions>
          @if (help) {
            <ng-container *gvDialogHelp>
              <ng-container *ngTemplateOutlet="help.templateRef" />
            </ng-container>
          }
          @if (warning) {
            <ng-container *gvDialogWarning>
              <ng-container *ngTemplateOutlet="warning.templateRef" />
            </ng-container>
          }
          <ng-container
            *ngTemplateOutlet="dialogActions.template"
          ></ng-container>
        </gv-dialog-actions>
      }
      <ng-content></ng-content>
    </gv-dialog-component>
  `,
  styles: [BASE_DIALOG_STYLES],
})
export class SimpleDialogComponent extends BaseVariantDialog {}

@NgModule({
  imports: [SimpleDialogComponent, DialogActions, DialogContent],
  exports: [SimpleDialogComponent, DialogActions, DialogContent],
})
export class SimpleDialogModule {}
