import type { Action, ActionReducer } from '@ngrx/store';
import { Subject } from 'rxjs';

const storeSubject = new Subject<{
  action: any;
  state: any;
}>();

export const store$ = storeSubject.asObservable();

export function debugReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action: Action) => {
    const newState = reducer(state, action);
    storeSubject.next({
      action,
      state: newState,
    });
    return newState;
  };
}
