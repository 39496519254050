export const errorMapping = {
  20000000: $localize`:@@api-error-20000000:Benefit successfully applied.`,
  20000001: $localize`:@@api-error-20000001:Location successfully deleted.`,
  20000002: $localize`:@@api-error-20000002:Data source successfully removed!`,
  20000003: $localize`:@@api-error-20000003:Data source was successfully deleted.`,
  20000004: $localize`:@@api-error-20000004:Credits transferred!`,
  20000005: $localize`:@@api-error-20000005:DataSource sharing request accepted`,
  20000006: $localize`:@@api-error-20000006:DataSource will be shared soon.`,
  20000007: $localize`:@@api-error-20000007:Filter deleted successfully.`,
  20000008: $localize`:@@api-error-20000008:Graphical Object successfully delete.`,
  20000009: $localize`:@@api-error-20000009:Graphical objects successfully copied.`,
  20000010: $localize`:@@api-error-20000010:Module subscription payment accepted.`,
  20000011: $localize`:@@api-error-20000011:Password change request accepted.`,
  20000012: $localize`:@@api-error-20000012:Password changed successfully.`,
  20000013: $localize`:@@api-error-20000013:Payment accepted.`,
  20000014: $localize`:@@api-error-20000014:Payment method successfully deleted.`,
  20000015: $localize`:@@api-error-20000015:Promocode successfully applied.`,
  20000016: $localize`:@@api-error-20000016:Report request created successfully.`,
  20000017: $localize`:@@api-error-20000017:Request is valid.`,
  20000018: $localize`:@@api-error-20000018:Screenshot updated.`,
  20000019: $localize`:@@api-error-20000019:Stream successfully deleted.`,
  20000020: $localize`:@@api-error-20000020:Timeline and Sample will be recalculated shortly`,
  20000021: $localize`:@@api-error-20000021:Tokens successfully invalidated.`,
  20000022: $localize`:@@api-error-20000022:Trajectory selection is being calculated`,
  20000023: $localize`:@@api-error-20000023:User has been successfully verified!`,
  20000024: $localize`:@@api-error-20000024:User successfully deleted.`,
  20000025: $localize`:@@api-error-20000025:User successfully logged out.`,
  20000026: $localize`:@@api-error-20000026:User verification created.`,
  20000027: $localize`:@@api-error-20000027:Verifying stream.`,
  20000028: $localize`:@@api-error-20000028:Video and all data successfully deleted!`,
  20000029: $localize`:@@api-error-20000029:Video and video group successfully deleted!`,
  20000030: $localize`:@@api-error-20000030:Video successfully deleted!`,
  20000031: $localize`:@@api-error-20000031:Video upload cancelled successfully.`,
  20000032: $localize`:@@api-error-20000032:Widget is being updated.`,
  20000033: $localize`:@@api-error-20000033:Widgets and Sample will be recalculated shortly`,
  20000034: $localize`:@@api-error-20000034:Cart action successful.`,
  20000035: $localize`:@@api-error-20000035:Cart updated successfully.`,
  20000036: $localize`:@@api-error-20000036:Email successfully shared.`,
  20000037: $localize`:@@api-error-20000037:Export successfully deleted.`,
  20000038: $localize`:@@api-error-20000038:File successfully deleted.`,
  20000039: $localize`:@@api-error-20000039:File successfully uploaded.`,
  20000040: $localize`:@@api-error-20000040:File upload successfully canceled.`,
  20000041: $localize`:@@api-error-20000041:Module subscription cancelled successfully.`,
  20000042: $localize`:@@api-error-20000042:Payment accepted.`,
  20000043: $localize`:@@api-error-20000043:Payment request canceled successfully.`,
  20000044: $localize`:@@api-error-20000044:Quote has been sent successfully.`,
  20000045: $localize`:@@api-error-20000045:Upload of remaining files has been successfully canceled.`,
  20000046: $localize`:@@api-error-20000046:Vault directory successfully deleted.`,
  20000047: $localize`:@@api-error-20000047:Trajectory selection has not data.`,
  20000048: $localize`:@@api-error-20000048:No data available.`,
  20000049: $localize`:@@api-error-20000049:Subscribed for web push.`,
  20000050: $localize`:@@api-error-20000050:All push subscriptions were successfully deleted.`,
  20000051: $localize`:@@api-error-20000051:Dashboard successfully deleted.`,
  20000052: $localize`:@@api-error-20000052:Project collaboration updated.`,
  20000053: $localize`:@@api-error-20000053:Project successfully deleted.`,
  20000054: $localize`:@@api-error-20000054:Classification scheme deleted successfully.`,
  20000055: $localize`:@@api-error-20000055:Widget action successful.`,
  20000056: $localize`:@@api-error-20000056:User successfully removed from organization!`,
  20000057: $localize`:@@api-error-20000057:Widget image data is available!`,
  20000058: $localize`:@@api-error-20000058:Live Traffic unit stream deleted successfully!`,
  20000059: $localize`:@@api-error-20000059:Event test sent successfully!`,
  20000060: $localize`:@@api-error-20000060:Scene description deletes successfully!`,
  20000061: $localize`:@@api-error-20000061:Live Traffic unit deleted successfully!`,
  20000062: $localize`:@@api-error-20000062:Description updated successfully!`,
  20000063: $localize`:@@api-error-20000063:Project transfer initiated successfully!`,
  20000064: $localize`:@@api-error-20000064:Project transferred successfully!`,
  20000065: $localize`:@@api-error-20000065:Description copied successfully!`,
  20000067: $localize`:@@api-error-20000067:File deleted successfully!`,
  20000068: $localize`:@@api-error-20000068:Timeline metric is up to date.`,
  20000069: $localize`:@@api-error-20000069:Plotter invoked successfully.`,
  20000070: $localize`:@@api-error-20000070:Job cloned successfully.`,
  20000071: $localize`:@@api-error-20000071:Action performed successful.`,
  20000072: $localize`:@@api-error-20000072:Action successful.`,
  20000073: $localize`:@@api-error-20000073:OAuth service paired successfully.`,
  20000074: $localize`:@@api-error-20000074:Auth providers updated successfully.`,
  20000075: $localize`:@@api-error-20000075:Message read successfully.`,
  20000076: $localize`:@@api-error-20000076:Messages read successfully.`,
  20000077: $localize`:@@api-error-20000077:Notification updated successfully.`,
  20000078: $localize`:@@api-error-20000078:Notification deleted successfully.`,
  20000079: $localize`:@@api-error-20000079:Request cancelled successfully!`,
  20000080: $localize`:@@api-error-20000080:Maintenance action successful!`,
  20000081: $localize`:@@api-error-20000081:Traffic scenario deleted successfully!`,
  20000082: $localize`:@@api-error-20000082:Live widget deleted successfully!`,
  20000083: $localize`:@@api-error-20000083:Video processing restarted successfully!`,
  20000084: $localize`:@@api-error-20000084:Plan cancelled/deleted successfully!`,
  20000085: $localize`:@@api-error-20000085:Plan start updated successfully!`,
  20000086: $localize`:@@api-error-20000086:Invoice updated successfully!`,
  20000087: $localize`:@@api-error-20000087:Live Traffic unit config updated successfully!`,
  20200000: $localize`:@@api-error-20200000:Stream connection is being checked!`,
  20200001: $localize`:@@api-error-20200001:Sample is being recalculated!`,
  20200002: $localize`:@@api-error-20200002:Counts are being processed!`,
  20200003: $localize`:@@api-error-20200003:Resetting data!`,
  20200004: $localize`:@@api-error-20200004:Processing metric data!`,
  20200005: $localize`:@@api-error-20200005:Discovery request sent.`,
  20200006: $localize`:@@api-error-20200006:Widget is redrawing.`,
  20200007: $localize`:@@api-error-20200007:User invited successfully.`,
  20400000: $localize`:@@api-error-20400000:User is not part of any organization.`,
  20400001: $localize`:@@api-error-20400001:Empty object class selection.`,
  20400002: $localize`:@@api-error-20400002:No data found in current range.`,
  20400003: $localize`:@@api-error-20400003:DataSource does not have processed data.`,
  20400004: $localize`:@@api-error-20400004:No payment method set.`,
  20400005: $localize`:@@api-error-20400005:No maintenance is planned at this moment.`,
  20400006: $localize`:@@api-error-20400006:No data for this Scenario`,
  40000000: $localize`:@@api-error-40000000:All data sources are already shared or were deleted by the owner.`,
  40000001: $localize`:@@api-error-40000001:Location not found!`,
  40000002: $localize`:@@api-error-40000002:Location or Data source not found!`,
  40000003: $localize`:@@api-error-40000003:Data source cannot be deleted - video states in the camera do not allow deletion.`,
  40000004: $localize`:@@api-error-40000004:Data source cannot be deleted!`,
  40000005: $localize`:@@api-error-40000005:Data source is disabled.`,
  40000006: $localize`:@@api-error-40000006:Data source not found!`,
  40000007: $localize`:@@api-error-40000007:Cannot afford to recover video.`,
  40000008: $localize`:@@api-error-40000008:Cannot apply promocode.`,
  40000009: $localize`:@@api-error-40000009:Cannot authorize new payment method for user.`,
  40000010: $localize`:@@api-error-40000010:Cannot change data source Stream URL, some streams have already been scheduled.`,
  40000011: $localize`:@@api-error-40000011:Cannot change state of the video group. Some videos are being processed.`,
  40000012: $localize`:@@api-error-40000012:Cannot change video start. It collides with other videos on camera.`,
  40000013: $localize`:@@api-error-40000013:Cannot change widget order`,
  40000014: $localize`:@@api-error-40000014:Cannot create new payment token for user.`,
  40000015: $localize`:@@api-error-40000015:Cannot delete Location.`,
  40000016: $localize`:@@api-error-40000016:Cannot delete Video stream.`,
  40000017: $localize`:@@api-error-40000017:Cannot delete payment method.`,
  40000018: $localize`:@@api-error-40000018:Cannot delete video group.`,
  40000019: $localize`:@@api-error-40000019:Cannot delete video group. Some videos are still processing.`,
  40000020: $localize`:@@api-error-40000020:Cannot delete video!`,
  40000021: $localize`:@@api-error-40000021:Cannot delete video.`,
  40000022: $localize`:@@api-error-40000022:Cannot delete user.`,
  40000023: $localize`:@@api-error-40000023:Cannot extend availability of the video. Insufficient funds.`,
  40000024: $localize`:@@api-error-40000024:Cannot extend availability of this video. Wrong state.`,
  40000025: $localize`:@@api-error-40000025:Cannot extend availability of video-group. Some videos are being processed.`,
  40000026: $localize`:@@api-error-40000026:Cannot process payment.`,
  40000028: $localize`:@@api-error-40000028:Cannot process video upload at the moment.`,
  40000029: $localize`:@@api-error-40000029:Cannot process video upload request!`,
  40000030: $localize`:@@api-error-40000030:Cannot recalculate timeline and sample.`,
  40000031: $localize`:@@api-error-40000031:Cannot recover this video.`,
  40000032: $localize`:@@api-error-40000032:Cannot refresh token. Token has already been invalidated or used for refreshing.`,
  40000033: $localize`:@@api-error-40000033:Cannot refresh token. User is not in a valid state.`,
  40000034: $localize`:@@api-error-40000034:Cannot refresh user token. User is not valid!`,
  40000035: $localize`:@@api-error-40000035:Cannot retrieve video screenshot!`,
  40000036: $localize`:@@api-error-40000036:Cannot schedule stream in this camera.`,
  40000037: $localize`:@@api-error-40000037:Cannot schedule stream, scheduled intervals overlap.`,
  40000038: $localize`:@@api-error-40000038:Cannot schedule video stream. Insufficient funds.`,
  40000039: $localize`:@@api-error-40000039:Cannot share data sources. Owner of the camera is not valid.`,
  40000040: $localize`:@@api-error-40000040:Cannot update payment method.`,
  40000041: $localize`:@@api-error-40000041:Cannot upload video. Insufficient funds.`,
  40000042: $localize`:@@api-error-40000042:Cannot verify stream.`,
  40000043: $localize`:@@api-error-40000043:Cannot verify stream. Data source is missing stream url.`,
  40000044: $localize`:@@api-error-40000044:Cannot verify stream. Video is missing stream url.`,
  40000045: $localize`:@@api-error-40000045:Data source has not data!`,
  40000046: $localize`:@@api-error-40000046:Data source not found!`,
  40000047: $localize`:@@api-error-40000047:DataSource not found!`,
  40000048: $localize`:@@api-error-40000048:DataSource with given uuid not found!`,
  40000049: $localize`:@@api-error-40000049:Email or password is not correct.`,
  40000050: $localize`:@@api-error-40000050:Image not found.`,
  40000051: $localize`:@@api-error-40000051:Invalid data.`,
  40000052: $localize`:@@api-error-40000052:Invoice not found!`,
  40000053: $localize`:@@api-error-40000053:Missing hash.`,
  40000054: $localize`:@@api-error-40000054:No data were found for this widget.`,
  40000055: $localize`:@@api-error-40000055:No screenshot found for camera.`,
  40000056: $localize`:@@api-error-40000056:Payment method cannot be deleted. There is an active subscription which uses this payment method.`,
  40000057: $localize`:@@api-error-40000057:Payment method does not exist.`,
  40000058: $localize`:@@api-error-40000058:Request is not valid!`,
  40000059: $localize`:@@api-error-40000059:Request is not valid.`,
  40000060: $localize`:@@api-error-40000060:Stream schedule overlaps others on camera.`,
  40000061: $localize`:@@api-error-40000061:Too many active camera sharing invitations.`,
  40000062: $localize`:@@api-error-40000062:Trajectory selection is not available`,
  40000063: $localize`:@@api-error-40000063:User cannot be verified!`,
  40000064: $localize`:@@api-error-40000064:User is not allowed to schedule stream videos.`,
  40000065: $localize`:@@api-error-40000065:User is not authorized!`,
  40000066: $localize`:@@api-error-40000066:User is not authorized`,
  40000067: $localize`:@@api-error-40000067:User is not authorized!`,
  40000068: $localize`:@@api-error-40000068:User is not authorized.`,
  40000069: $localize`:@@api-error-40000069:User not found!`,
  40000070: $localize`:@@api-error-40000070:User with given ID not found!`,
  40000071: $localize`:@@api-error-40000071:User with given email already exists!`,
  40000072: $localize`:@@api-error-40000072:Video cannot be deleted!`,
  40000073: $localize`:@@api-error-40000073:Video cannot be updated!`,
  40000074: $localize`:@@api-error-40000074:Video group could not be deleted!`,
  40000075: $localize`:@@api-error-40000075:Video not found!`,
  40000076: $localize`:@@api-error-40000076:Video not found.`,
  40000077: $localize`:@@api-error-40000077:Video of this type cannot be deleted.`,
  40000078: $localize`:@@api-error-40000078:Video of this type cannot be updated.`,
  40000079: $localize`:@@api-error-40000079:Video overlaps other video(s) on camera.`,
  40000080: $localize`:@@api-error-40000080:Video upload id already exists.`,
  40000081: $localize`:@@api-error-40000081:Video uploaded cannot be cancelled.`,
  40000082: $localize`:@@api-error-40000082:Cannot presign file. Missing existing uploaded file for hash`,
  40000084: $localize`:@@api-error-40000084:Access to this resource is denied.`,
  40000086: $localize`:@@api-error-40000086:User is not authorized.`,
  40000087: $localize`:@@api-error-40000087:Cannot buy default module plan.`,
  40000088: $localize`:@@api-error-40000088:Cannot cancel file upload. File is already uploaded.`,
  40000089: $localize`:@@api-error-40000089:Cannot cancel module subscription.`,
  40000090: $localize`:@@api-error-40000090:Cannot cancel module subscription. Waiting for payment processing.`,
  40000091: $localize`:@@api-error-40000091:Cannot create module.`,
  40000092: $localize`:@@api-error-40000092:Cannot create video processing. Insufficient funds.`,
  40000093: $localize`:@@api-error-40000093:Cannot delete file. It is being used for processing.`,
  40000094: $localize`:@@api-error-40000094:Cannot delete folder. Some of the files are being processed right now.`,
  40000095: $localize`:@@api-error-40000095:Cannot find data source.`,
  40000096: $localize`:@@api-error-40000096:Cannot find export.`,
  40000097: $localize`:@@api-error-40000097:Cannot find given directory.`,
  40000098: $localize`:@@api-error-40000098:Cannot find price offer/pro forma/invoice.`,
  40000099: $localize`:@@api-error-40000099:Cannot find the cart.`,
  40000100: $localize`:@@api-error-40000100:Cannot find user.`,
  40000101: $localize`:@@api-error-40000101:Cannot finish file upload. File is already uploaded.`,
  40000102: $localize`:@@api-error-40000102:Cannot generate token.`,
  40000103: $localize`:@@api-error-40000103:Cannot presign files which are already uploaded.`,
  40000104: $localize`:@@api-error-40000104:Cannot process module payment.`,
  40000105: $localize`:@@api-error-40000105:Cannot update already verified module.`,
  40000106: $localize`:@@api-error-40000106:Cannot update file. Invalid state.`,
  40000107: $localize`:@@api-error-40000107:Cannot update module plan. Module plan type is different to current module type.`,
  40000108: $localize`:@@api-error-40000108:Cannot update module settings. Module is not active.`,
  40000109: $localize`:@@api-error-40000109:Cannot update module. It can only be verified using the Request for payment.`,
  40000110: $localize`:@@api-error-40000110:Cannot update payment method!`,
  40000111: $localize`:@@api-error-40000111:Cannot update video state. Video is in invalid state.`,
  40000112: $localize`:@@api-error-40000112:Cannot update video. Video does not have metadata for processing.`,
  40000113: $localize`:@@api-error-40000113:Cannot validate payment.`,
  40000114: $localize`:@@api-error-40000114:Cart is not eligible for bank transfer payment.`,
  40000115: $localize`:@@api-error-40000115:Could not process payment.`,
  40000116: $localize`:@@api-error-40000116:Could not update module payment details.`,
  40000117: $localize`:@@api-error-40000117:Could not update payment details.`,
  40000118: $localize`:@@api-error-40000118:File is not downloadable.`,
  40000119: $localize`:@@api-error-40000119:File with given hash already exist in the folder.`,
  40000120: $localize`:@@api-error-40000120:Image not found.`,
  40000121: $localize`:@@api-error-40000121:Some of the files cannot be used for video creation.`,
  40000122: $localize`:@@api-error-40000122:There are no files to be cancelled.`,
  40000123: $localize`:@@api-error-40000123:User cannot afford the processing type change!`,
  40000124: $localize`:@@api-error-40000124:User has insufficient permissions`,
  40000125: $localize`:@@api-error-40000125:User is not allowed to accept shared cameras.`,
  40000126: $localize`:@@api-error-40000126:User is not authorized!`,
  40000127: $localize`:@@api-error-40000127:User is not authorized.`,
  40000128: $localize`:@@api-error-40000128:User not found.`,
  40000129: $localize`:@@api-error-40000129:Video overlaps other video(s) on camera.`,
  40000130: $localize`:@@api-error-40000130:Video upload cancellation failed.`,
  40000131: $localize`:@@api-error-40000131:Videos are missing metadata for processing.`,
  40000135: $localize`:@@api-error-40000135:User is not permitted to use this data source.`,
  40000143: $localize`:@@api-error-40000143:Language not supported!`,
  40000144: $localize`:@@api-error-40000144:Missing Classification scheme data.`,
  40000145: $localize`:@@api-error-40000145:Missing Classification scheme name.`,
  40000146: $localize`:@@api-error-40000146:Missing Classification scheme language.`,
  40000147: $localize`:@@api-error-40000147:Classification mapping cannot be empty!`,
  40000148: $localize`:@@api-error-40000148:Missing project data.`,
  40000149: $localize`:@@api-error-40000149:Missing project name.`,
  40000150: $localize`:@@api-error-40000150:Project name can have max of 500 characters.`,
  40000151: $localize`:@@api-error-40000151:Missing project language.`,
  40000152: $localize`:@@api-error-40000152:Class scheme id is not valid.`,
  40000153: $localize`:@@api-error-40000153:Project and Classification scheme language do not match.`,
  40000154: $localize`:@@api-error-40000154:Missing project id.`,
  40000155: $localize`:@@api-error-40000155:Project id is not valid.`,
  40000156: $localize`:@@api-error-40000156:User is not permitted to do this action in this project.`,
  40000157: $localize`:@@api-error-40000157:Cannot retrieve Stripe data.`,
  40000158: $localize`:@@api-error-40000158:Cannot update user's default payment method.`,
  40000159: $localize`:@@api-error-40000159:Cannot remove payment method.`,
  40000160: $localize`:@@api-error-40000160:Cannot create Stripe customer.`,
  40000161: $localize`:@@api-error-40000161:Cannot update subscription payment method.`,
  40000162: $localize`:@@api-error-40000162:Widget is being updated.`,
  40000163: $localize`:@@api-error-40000163:Webhook url is not valid.`,
  40000164: $localize`:@@api-error-40000164:Stream url is not valid.`,
  40000165: $localize`:@@api-error-40000165:Unsupported object type for this Project.`,
  40000166: $localize`:@@api-error-40000166:Classification scheme with coefficients is not allowed in Live deployment.`,
  40000167: $localize`:@@api-error-40000167:Classification scheme name is too long.`,
  40000168: $localize`:@@api-error-40000168:Data editing was unsuccessful.`,
  40000169: $localize`:@@api-error-40000169:Live Traffic unit could not be created. Try again later.`,
  40000170: $localize`:@@api-error-40000170:Geometry data is not valid.`,
  40000171: $localize`:@@api-error-40000171:Cannot use this granularity with this scheduled export. Granularity is too small.`,
  40000172: $localize`:@@api-error-40000172:News notifications must be called separatelly!`,
  40000173: $localize`:@@api-error-40000173:Unsupported notification action!`,
  40000174: $localize`:@@api-error-40000174:Vault cannot be changed in this billing cycle!`,
  40000175: $localize`:@@api-error-40000175:Seats cannot be changed in this billing cycle!`,
  40000176: $localize`:@@api-error-40000176:Cannot create scenario on this dashboard.`,
  40000177: $localize`:@@api-error-40000177:Cannot use widgets on this dashboard.`,
  40000178: $localize`:@@api-error-40000178:No Live Traffic unit stream is attached to this data source dashboard.`,
  40000179: $localize`:@@api-error-40000179:Provided webhook URL is not supported.`,
  40000180: $localize`:@@api-error-40000180:No all data sources are in a state for scenario creation.`,
  40000181: $localize`:@@api-error-40000181:Time to is before time from.`,
  40000182: $localize`:@@api-error-40000182:Daily export range spans over to next day.`,
  40000183: $localize`:@@api-error-40000183:Weekly export range spans over to next week.`,
  40000184: $localize`:@@api-error-40000184:Monthly export range spans over to next month.`,
  40100002: $localize`:@@api-error-40100002:User not authorized!`,
  40100003: $localize`:@@api-error-40100003:OAuth user authorization failed!`,
  40200000: $localize`:@@api-error-40200000:The cost is not within the project's budget!`,
  40200001: $localize`:@@api-error-40200001:Service connection is expired!`,
  40200002: $localize`:@@api-error-40200002:Platform plan is not active!`,
  40300000: $localize`:@@api-error-40300000:Cannot create new Organization. User is already part of one.`,
  40300001: $localize`:@@api-error-40300001:Cannot share to user within the organization!`,
  40300002: $localize`:@@api-error-40300002:Cannot edit cart, module seat cannot be purchased separately from the module!`,
  40300003: $localize`:@@api-error-40300003:User does not have any billing address.`,
  40300004: $localize`:@@api-error-40300004:User is already in organization!`,
  40300005: $localize`:@@api-error-40300005:User does not have permissions to be removed from this project!`,
  40300006: $localize`:@@api-error-40300006:Video resolutions do not match in data sources.`,
  40300007: $localize`:@@api-error-40300007:Cannot allow access to the widget data.`,
  40300008: $localize`:@@api-error-40300008:Stream is not valid.`,
  40300009: $localize`:@@api-error-40300009:Stream URL does not match the validation.`,
  40300010: $localize`:@@api-error-40300010:Could not initialize stream on the Live Traffic unit.`,
  40300011: $localize`:@@api-error-40300011:All stream slots are taken for this Live Traffic unit.`,
  40300012: $localize`:@@api-error-40300012:Cannot update stream on the Live Traffic unit!`,
  40300013: $localize`:@@api-error-40300013:Cannot retrieve service data.`,
  40300014: $localize`:@@api-error-40300014:Insufficient credit balance for this action.`,
  40300015: $localize`:@@api-error-40300015:Price in credits does not match expected amount.`,
  40300016: $localize`:@@api-error-40300016:Cannot obtain Live Traffic unit certificates.`,
  40300017: $localize`:@@api-error-40300017:Cannot refresh timeline metric.`,
  40300018: $localize`:@@api-error-40300018:Data source does not have a specific resolution.`,
  40300019: $localize`:@@api-error-40300019:Cannot clone job!`,
  40300020: $localize`:@@api-error-40300020:Cannot update Live Traffic unit settings!`,
  40300021: $localize`:@@api-error-40300021:Organization does not have a subdomain set`,
  40300022: $localize`:@@api-error-40300022:Only Platform plan can be purchased as a subscription.`,
  40300023: $localize`:@@api-error-40300023:Subscription and cart have different currencies. Cannot combine currencies.`,
  40300025: $localize`:@@api-error-40300025:Currency is not supported for this cart`,
  40300026: $localize`:@@api-error-40300026:Checkout method is not supported for this cart.`,
  40300027: $localize`:@@api-error-40300027:Max price reached for this checkout method.`,
  40300028: $localize`:@@api-error-40300028:Payment not supported.`,
  40300029: $localize`:@@api-error-40300029:Not allowed.`,
  40300030: $localize`:@@api-error-40300030:Cannot find service connection.`,
  40300031: $localize`:@@api-error-40300031:Cannot turn on stream processing. Plotter is enabled and only 1 running stream is supported.`,
  40300032: $localize`:@@api-error-40300032:Cannot create organization at this moment.`,
  40300033: $localize`:@@api-error-40300033:Cannot change billing address. User has active subscription and VAT values are different!`,
  40300034: $localize`:@@api-error-40300034:Cannot create Live Traffic data source with coefficient classification scheme.`,
  40300035: $localize`:@@api-error-40300035:TMC Multi is the only supported multi-source export type.`,
  40300036: $localize`:@@api-error-40300036:User's email does not match OAuth service email.`,
  40300037: $localize`:@@api-error-40300037:Cannot refresh token. Token is invalid.`,
  40300038: $localize`:@@api-error-40300038:Request is expired!`,
  40300039: $localize`:@@api-error-40300039:User is not permitted to project!`,
  40300040: $localize`:@@api-error-40300040:Project cannot be shared.`,
  40300041: $localize`:@@api-error-40300041:Video is not fully processed and cannot be moved on the timeline. Wait until the processing is finished.`,
  40300042: $localize`:@@api-error-40300042:Could not perform maintenance action! Try again later!`,
  40300043: $localize`:@@api-error-40300043:Could not invoke maintenance!`,
  40300044: $localize`:@@api-error-40300044:Cannot restart processing. It is in DONE and processed state!`,
  40300045: $localize`:@@api-error-40300045:Provisioning is not possible. Live Traffic unit not found!`,
  40300046: $localize`:@@api-error-40300046:Missing data source selection for traffic scenario!`,
  40300047: $localize`:@@api-error-40300047:This Traffic scenario cannot be edited!`,
  40300048: $localize`:@@api-error-40300048:This Traffic scenario cannot be deleted!`,
  40300049: $localize`:@@api-error-40300049:Report is too large to share over email.`,
  40400001: $localize`:@@api-error-40400001:Widget data not found!`,
  40400002: $localize`:@@api-error-40400002:Export data not found!`,
  40400003: $localize`:@@api-error-40400003:Batch job not found!`,
  40400004: $localize`:@@api-error-40400004:Scenario type not found!`,
  40400005: $localize`:@@api-error-40400005:Catalog file for this version not found!`,
  40400006: $localize`:@@api-error-40400006:Cannot find export data.`,
  40900000: $localize`:@@api-error-40900000:Notification filter has changed. Token is not valid.`,
  50100001: $localize`:@@api-error-50100001:Not implemented`,
};
