import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import * as CookieLawActions from '../action/cookie-law.actions';
import type { CookieLawState } from '../state/cookie-law.state';
import { initialCookieLawState } from '../state/cookie-law.state';

export const cookieLawFeatureKey = 'cookieLaw';

const cookieLawReducer = createReducer(
  initialCookieLawState,

  on(CookieLawActions.confirm, (state): CookieLawState => {
    if (state.wasConfirmed) {
      return state;
    }

    return {
      ...state,
      wasConfirmed: true,
    };
  }),
);

export function reducer(
  state: CookieLawState | undefined,
  action: Action,
): CookieLawState {
  return cookieLawReducer(state, action);
}
