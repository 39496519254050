import { Injectable, inject } from '@angular/core';

import { WindowRefService } from '@gv/ui/core';

@Injectable({
  providedIn: 'root',
})
export class HotjarService {
  private windowRef = inject(WindowRefService);

  hotjarCommand(method: string, arg: any): void {
    if (!this.windowRef.nativeWindow || !this.windowRef.nativeWindow.hj) {
      return;
    }

    this.windowRef.nativeWindow.hj(method, arg);
  }
}
