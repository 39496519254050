import type { OnDestroy } from '@angular/core';
import { Injectable, inject } from '@angular/core';

import type { CrossTabMessageModel } from '@gv/crosstab';
import { CrossTabChannelService } from '@gv/crosstab';
import type { Mutable } from '@gv/state';
import { getSync, StoreInject } from '@gv/state';
import { APP_INSTANCE_ID } from '@gv/ui/core';
import { UPLOADS_FEATURE_STATE, fromUploadsState } from '@gv/upload/state';
import {
  UploadSyncMessageType,
  type UploadsStatsModel,
  type UploadSyncCountChangedMessageModel,
} from '@gv/upload/types';
import {
  delayedConcatMap,
  shallowDistinctUntilChanged,
  untilNgDestroyed,
} from '@gv/utils';
import { select } from '@ngrx/store';
import { produce } from 'immer';
import type { Observable, Subscription } from 'rxjs';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GlobalUploadStateService implements OnDestroy {
  private appInstanceId = inject(APP_INSTANCE_ID);
  private store = inject(StoreInject(UPLOADS_FEATURE_STATE));
  private crossTabChannel = inject(CrossTabChannelService);
  private uploadsCountsSubject = new BehaviorSubject<{
    readonly [instanceId: string]: UploadsStatsModel;
  }>({});

  private localUploadsCount$ = this.store.pipe(
    select(fromUploadsState.vault.getRemainingUploadCount),
    distinctUntilChanged(),
  );

  private localFailedUploadsCount$ = this.store.pipe(
    select(fromUploadsState.vault.getFailedUploadCount),
    distinctUntilChanged(),
  );

  externalUploadsCount$ = this.uploadsCountsSubject.pipe(
    map((uploadsCounts): UploadsStatsModel => {
      return Object.values(uploadsCounts).reduce(
        (acc: Mutable<UploadsStatsModel>, v) => {
          acc.inProgress += v.inProgress;
          acc.failed += v.failed;
          return acc;
        },
        {
          inProgress: 0,
          failed: 0,
        },
      );
    }),
    distinctUntilChanged(),
  );

  globalUploadsCounts$: Observable<UploadsStatsModel> = combineLatest([
    this.localUploadsCount$,
    this.localFailedUploadsCount$,
    this.externalUploadsCount$,
  ]).pipe(
    map(([localCount, localFailedCount, externalCount]) => {
      return {
        inProgress: localCount + externalCount.inProgress,
        failed: localFailedCount + externalCount.failed,
      };
    }),
    shallowDistinctUntilChanged(),
  );

  get anyUploadNeedsUserAttention(): boolean {
    return (
      getSync(this.store, fromUploadsState.vault.getRemainingUploadCount) > 0 ||
      getSync(this.store, fromUploadsState.vault.getFailedUploadCount) > 0 ||
      Object.values(this.uploadsCountsSubject.getValue()).some(
        (v) => v.failed > 0 || v.inProgress > 0,
      )
    );
  }

  private instanceConnected$ = this.crossTabChannel.instanceConnected$.pipe(
    delayedConcatMap(() => [
      this.localUploadsCount$,
      this.localFailedUploadsCount$,
    ]),
    tap(([instance, uploadCount, failedCount]) => {
      this.sendUploadCount(
        {
          inProgress: uploadCount,
          failed: failedCount,
        },
        instance.instanceId,
      );
    }),
    untilNgDestroyed(),
  );

  private instanceDisconnected$ =
    this.crossTabChannel.instanceDisconnected$.pipe(
      tap((instance) => {
        this.deleteAppInstance(instance.instanceId);
      }),
      untilNgDestroyed(),
    );

  private updateCountMessageReceived$: Observable<UploadSyncCountChangedMessageModel> =
    this.crossTabChannel.messageReceived$.pipe(
      filter<UploadSyncCountChangedMessageModel>((message) =>
        this.isCountChangedMessage(message),
      ),
      tap((message) => {
        this.updateCount(message.instanceId, message.data);
      }),
      untilNgDestroyed(),
    );

  private uploadsCountSubscription: Subscription | undefined;

  constructor() {
    this.updateCountMessageReceived$.subscribe();

    this.instanceConnected$.subscribe();

    this.instanceDisconnected$.subscribe();
  }

  ngOnDestroy(): void {
    this.destroyUploadsCountSubscription();
  }

  enable(): void {
    this.createUploadsCountSubscription();
  }

  forceUpdate(): void {
    this.crossTabChannel.purge();
  }

  private createUploadsCountSubscription(): void {
    this.destroyUploadsCountSubscription();

    this.uploadsCountSubscription = combineLatest([
      this.localUploadsCount$,
      this.localFailedUploadsCount$,
    ]).subscribe(([count, failedCount]) => {
      this.sendUploadCount({
        inProgress: count,
        failed: failedCount,
      });
    });
  }

  private sendUploadCount(count: UploadsStatsModel, recipient?: string): void {
    const message: UploadSyncCountChangedMessageModel = {
      type: UploadSyncMessageType.CountChanged,
      data: count,
      recipient,
      instanceId: this.appInstanceId,
    };

    this.crossTabChannel.sendMessage(message);
  }

  private updateCount(appInstanceId: string, count: UploadsStatsModel): void {
    const uploadsCounts = this.uploadsCountsSubject.getValue();

    if (uploadsCounts[appInstanceId] !== count) {
      this.uploadsCountsSubject.next({
        ...uploadsCounts,
        [appInstanceId]: count,
      });
    }
  }

  private deleteAppInstance(appInstanceId: string): void {
    const uploadsCounts = this.uploadsCountsSubject.getValue();

    if (!(appInstanceId in uploadsCounts)) {
      return;
    }

    const updatedUploadsCounts = produce(uploadsCounts, (draft) => {
      delete draft[appInstanceId];
    });

    this.uploadsCountsSubject.next(updatedUploadsCounts);
  }

  private isCountChangedMessage(
    message: CrossTabMessageModel<any, string>,
  ): message is UploadSyncCountChangedMessageModel {
    return message && message.type === UploadSyncMessageType.CountChanged;
  }

  private destroyUploadsCountSubscription(): void {
    if (this.uploadsCountSubscription) {
      this.uploadsCountSubscription.unsubscribe();
      this.uploadsCountSubscription = undefined;
    }
  }
}
