import { oldEdgeBrowsers } from './files/old-edge-browsers';
import { isSafariBrowser } from './files/safari-browser';
import { supportedBrowsers } from './files/supported-browsers';

export class BrowserUtils {
  private static _isElectron: boolean = null;

  private static _isSupported: boolean = null;

  private static _isOldEdge: boolean = null;

  private static _isSafari: boolean = null;

  /**
   * @see https://github.com/electron/electron/issues/2288
   */
  static get isElectron(): boolean {
    if (BrowserUtils._isElectron === null) {
      BrowserUtils._isElectron =
        typeof navigator === 'object' &&
        typeof navigator.userAgent === 'string' &&
        navigator.userAgent.indexOf('Electron') >= 0;
    }
    return BrowserUtils._isElectron;
  }

  static get isSupported(): boolean {
    if (BrowserUtils._isSupported === null) {
      BrowserUtils._isSupported =
        typeof navigator === 'object' &&
        typeof navigator.userAgent === 'string' &&
        (!supportedBrowsers || supportedBrowsers.test(navigator.userAgent));
    }
    return BrowserUtils._isSupported;
  }

  static get isOldEdge(): boolean {
    if (BrowserUtils._isOldEdge === null) {
      BrowserUtils._isOldEdge =
        typeof navigator === 'object' &&
        typeof navigator.userAgent === 'string' &&
        (!oldEdgeBrowsers || oldEdgeBrowsers.test(navigator.userAgent));
    }
    return BrowserUtils._isOldEdge;
  }

  static get isSafari(): boolean {
    if (BrowserUtils._isSafari === null) {
      BrowserUtils._isSafari =
        typeof navigator === 'object' &&
        typeof navigator.userAgent === 'string' &&
        (!isSafariBrowser || isSafariBrowser.test(navigator.userAgent));
    }
    return BrowserUtils._isSafari;
  }
}
