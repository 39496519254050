import { closest } from 'binarysearch';

export interface TruncatedTextOptions {
  maxWidth: number;
  preferredEndSize: number;
  font: string;
  context: OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D;
  letterSpacing: number;
}

function reverse(text: string): string {
  if (!text) {
    return text;
  }
  let o = '';
  for (let i = text.length - 1; i >= 0; o += text[i--]) {
    //
  }
  return o;
}

export function getTruncatedText(
  text: string | undefined,
  options: TruncatedTextOptions,
): readonly [string | undefined, string | undefined] {
  const { maxWidth, font, preferredEndSize, context } = options;

  if (maxWidth === 0 || !text) {
    return [text, undefined];
  }

  let endSize = preferredEndSize;
  let ratioMode = endSize <= 1;
  if (!ratioMode && endSize >= maxWidth) {
    endSize = 0.5;
    ratioMode = true;
  }

  context.font = font;

  // 0.25 to fix rounding issues
  if (getTextWidth(context, text, options.letterSpacing) < maxWidth + 1) {
    return [text, undefined];
  }

  const desiredPreWidth = Math.floor(
    ratioMode ? (1 - endSize) * maxWidth : maxWidth - endSize,
  );
  const { index: splitIndex, width: actualPreWidth } = findTruncationPosition(
    {
      context,
      width: desiredPreWidth,
      letterSpacing: options.letterSpacing,
    },
    text,
  );
  if (splitIndex === -1) {
    return [text, undefined];
  }

  const currentTextPre = text.slice(0, splitIndex);

  const postText = text.slice(splitIndex + 1, text.length);

  const reversedText = reverse(postText);
  const { index: postIndex } = findTruncationPosition(
    {
      context,
      width: Math.floor(ratioMode ? endSize * maxWidth : endSize),
      letterSpacing: options.letterSpacing,
    },
    reversedText,
    '…',
    desiredPreWidth - actualPreWidth,
  );

  if (postIndex === -1) {
    return [text, undefined];
  }

  const currentTextPost = reversedText.slice(0, postIndex);

  return [currentTextPre, reverse(currentTextPost)];
}

function findTruncationPosition(
  {
    context,
    width,
    letterSpacing,
  }: {
    context: OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D;
    width: number;
    letterSpacing: number;
  },
  str: string,
  prepend?: string,
  leftBreadcrumbs?: number, // remaining size
): { index: number; width: number } {
  const array = new Array(str.length)
    .fill(0)
    .map((_, i) => i)
    .reverse();
  const maxWidth = (leftBreadcrumbs || 0) + width;

  const compareForIndex = (value: number) => {
    const newStr = str.slice(0, value);
    return (
      maxWidth -
      getTextWidth(
        context,
        prepend ? `${prepend} ${newStr}` : newStr,
        letterSpacing,
      )
    );
  };

  const comparator = (value: number) => {
    const diff = compareForIndex(value);
    return diff === 0 ? 0 : diff < 0 ? -1 : 1;
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  let index: number = closest(array, 0, comparator);

  let value: number;
  let widthDiff: number;

  do {
    value = array[index];
    widthDiff = compareForIndex(value);
    index += 1;
  } while (widthDiff < 0 && index < array.length);

  return { index: value, width: maxWidth - widthDiff };
}

function getTextWidth(
  context: OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D,
  text: string,
  letterSpacing: number,
) {
  const metrics = context.measureText(text);
  return metrics.width + (text.length - 1) * letterSpacing;
}
