import { inject, Injectable } from '@angular/core';

import { NotificationsService } from '@gv/notification';
import { UserActions, USER_CONTEXT } from '@gv/user';

import * as BillingActions from '../actions';

@Injectable()
export class OrganizationNotificationsEffects {
  private notificationsService = inject(NotificationsService);
  private userContext = inject(USER_CONTEXT);
  organizationUpdated$ = this.notificationsService.createEffect({
    obs: this.userContext.userUuid$,
    data: (uuid) => [{ userUuid: uuid }],
    type: 'USER_ORGANIZATION_NOTIFICATION',
    action: () => [
      BillingActions.organization.fetch.init({
        skipWhenLoaded: false,
      }),
      BillingActions.subscription.fetch.init({ skipWhenLoaded: false }),
      UserActions.refresh.init({ debounce: true }),
    ],
  });

  userStatusUpdated$ = this.notificationsService.createEffect({
    obs: this.userContext.userUuid$,
    data: (uuid) => [{ userUuid: uuid }],
    type: 'USER_ONLINE_STATUS_NOTIFICATION',
    action: (data) => [
      BillingActions.organization.setMemberStatus({
        data: {
          userUuid: data.data.user,
          status: data.data.status,
        },
      }),
    ],
  });

  organizationLogoUpdated$ = this.notificationsService.createEffect({
    obs: this.userContext.userUuid$,
    data: (uuid) => [{ userUuid: uuid }],
    type: 'ORGANIZATION_LOGO_NOTIFICATION',
    action: () => [
      BillingActions.organization.fetch.init({
        skipWhenLoaded: false,
      }),
      BillingActions.subscription.fetch.init({ skipWhenLoaded: false }),
      UserActions.refresh.init({ debounce: true }),
    ],
  });
}
