import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgStore } from '@gv/state';

import type { CreditFeatureState } from '../store/credit/state';
import { creditFeatureKey, CREDIT_FEATURE_STATE } from '../store/credit/state';
import { creditReducer } from '../store/credit/reducers';
import { CreditEffects } from '../store/credit/effects';

export * from '../store/credit/actions';
export * from '../store/credit/selectors';
export type { CreditFeatureState };
export { CREDIT_FEATURE_STATE };

@NgModule({
  imports: [
    StoreModule.forFeature(creditFeatureKey, creditReducer),
    EffectsModule.forFeature([CreditEffects]),
  ],
  providers: [
    {
      provide: CREDIT_FEATURE_STATE,
      useExisting: NgStore,
    },
  ],
})
export class CreditStateModule {}
