export enum VaultStateFileState {
  // tslint:disable no-bitwise
  Empty = 0,
  Inserted = 1 << 0,
  Created = 1 << 1,
  BasicMetadataLoaded = 1 << 2,
  FullMetadataLoaded = 1 << 3,
  Presigned = 1 << 4,
  Uploading = 1 << 5,
  Uploaded = 1 << 6,
  Failed = 1 << 7,
  Canceled = 1 << 8,
  FullMetadataLoading = 1 << 9,
  Encoded = 1 << 10,
  Encoding = 1 << 11,
}
