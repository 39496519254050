import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import type {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Router } from '@angular/router';

import type { TokenRole } from '@gv/api';
import { SnackBarService } from '@gv/ui/toaster';
import { decodeToken, isToken, isTokenValid } from '@gv/user';
import type { Observable } from 'rxjs';
import { from, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, take } from 'rxjs/operators';

import { logger } from '../../../../../logger';
import { TokenStoreService } from '../../user/token-store.service';
import { UserInitializerService } from '../../user/user-initializer.service';
import { UserService } from '../../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class TokenLoginGuard {
  private userService = inject(UserService);
  private tokenStore = inject(TokenStoreService);
  private router = inject(Router);
  private dialog = inject(MatDialog);
  private userInitializerService = inject(UserInitializerService);
  private snackbarService = inject(SnackBarService);
  static readonly className: string = 'TokenLoginGuard';

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.updateTokenIfNeeded(route, state.url);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.updateTokenIfNeeded(childRoute, state.url);
  }

  private updateTokenIfNeeded(
    route: ActivatedRouteSnapshot,
    redirectUrl: string,
  ): Observable<boolean | UrlTree> {
    const token = route.queryParamMap.get('token');

    if (!token || token.length === 0) {
      return of(true);
    }

    if (!isToken(token) || !isTokenValid(token, 0)) {
      logger.debug(`[${TokenLoginGuard.className}] token expired`);
      return from(this.logOut()).pipe(
        map(() => this.router.createUrlTree(['/login'])),
      );
    }

    const tokenData = decodeToken(token);
    if (
      !(<TokenRole[]>['ADMIN_CHECK', 'SUPPORT', 'SUPER_ADMIN']).includes(
        tokenData.role,
      )
    ) {
      logger.debug(
        `[${TokenLoginGuard.className}] wrong token type (${tokenData.type})`,
      );
      return from(this.logOut()).pipe(
        map(() => this.router.createUrlTree(['/login'])),
      );
    }

    const redirectTree = this.router.createUrlTree(
      [redirectUrl.split('?')[0]],
      /* Removed unsupported properties by Angular migration: skipLocationChange. */ {},
    );

    return (
      this.tokenStore.token ? of(undefined) : this.tokenStore.forceLoadToken()
    ).pipe(
      map(() => this.tokenStore.token),
      take(1),
      catchError(() => of(undefined)),
      switchMap((oldToken: string) => {
        if (!token || !isTokenValid(oldToken, 0)) {
          logger.debug(`[${TokenLoginGuard.className}] old token is invalid`);
          return from(this.logOut()).pipe(
            concatMap(() => from(this.logIn(token))),
            map(() => redirectTree),
          );
        }

        const oldTokenData = decodeToken(oldToken);

        if (
          oldTokenData &&
          oldTokenData.user === tokenData.user &&
          oldTokenData.type === tokenData.type &&
          oldTokenData.exp >= tokenData.exp
        ) {
          logger.debug(
            `[${TokenLoginGuard.className}] old token is for same context as new one... skipping`,
          );
          return of(redirectTree);
        }

        return from(this.logOut()).pipe(
          concatMap(() => from(this.logIn(token))),
          map(() => redirectTree),
        );
      }),
    );
  }

  private async logOut(): Promise<void> {
    logger.debug(`[${TokenLoginGuard.className}] logout`);
    await this.userService.logOut();

    this.closeAllDialogs();
  }

  private async logIn(token: string): Promise<void> {
    logger.debug(`[${TokenLoginGuard.className}] login`);
    const user = await this.userInitializerService.initializeUser(token, true);

    if (!user) {
      this.snackbarService.open('Failed to log in as guest', undefined, {
        duration: 3000,
      });
    } else {
      this.userService.logIn(token, user.dtSent, user);
    }
  }

  private closeAllDialogs(): void {
    this.dialog.openDialogs.forEach((dialog) => {
      dialog.close();
    });
  }
}
