import type { MonoTypeOperatorFunction } from 'rxjs';
import { Observable, of } from 'rxjs';
import { concatMap, timeout, catchError } from 'rxjs/operators';

import { SchedulingUtils } from '../utils/scheduling-utils';

export function waitUntilIdle<T>(
  force: Observable<unknown>,
  maxTimeout: number,
): MonoTypeOperatorFunction<T> {
  return concatMap((val) => {
    return new Observable<T>((obs) => {
      let finished = false;

      const run = () => {
        if (!finished) {
          obs.next(val);
          obs.complete();
          finished = true;
        }
      };

      const handler = SchedulingUtils.requestIdleCallback(
        () => {
          run();
        },
        { timeout: 10000 },
      );

      const sub = force.subscribe(() => {
        run();

        SchedulingUtils.cancelIdleCallback(handler);
      });

      return () => {
        finished = true;
        sub.unsubscribe();
        SchedulingUtils.cancelIdleCallback(handler);
      };
    }).pipe(
      timeout(maxTimeout),
      catchError((): Observable<T> => of(val)),
    );
  });
}
