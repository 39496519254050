import { inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { SnackBarService } from './snack-bar.service';
import { SnackBarActions } from './snackbar.actions';

@Injectable()
export class SnackBarEffects {
  private actions$ = inject<Actions>(Actions);
  private snackBarService = inject(SnackBarService);
  show$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SnackBarActions.show),
        tap((action) => {
          this.snackBarService.open(
            action.message,
            action.action,
            action.config,
          );
        }),
      ),
    {
      dispatch: false,
    },
  );
}
