import { createFeatureSelector } from '@gv/state';
import { createSelector } from '@ngrx/store';

import { cookieLawFeatureKey } from '../reducer/cookie-law.reducer';
import type { CookieLawState } from '../state/cookie-law.state';
import type { CookieLawFeatureState } from '../state/feature-state/cookie-law-feature.state';

const getState =
  createFeatureSelector<CookieLawFeatureState>(cookieLawFeatureKey);

const getWasConfirmed = createSelector(
  getState,
  (state: CookieLawState) => state.wasConfirmed,
);

export const fromCookieLawState = {
  getState,
  getWasConfirmed,
};
