import { InjectionToken } from '@angular/core';

import type { TypedDialogBaseActions } from '@gv/state';

interface DialogConfig<T, OpenProps, ClosedProps, PrepareProps> {
  readonly actions: TypedDialogBaseActions<
    OpenProps,
    ClosedProps,
    PrepareProps
  >;
  readonly deps: () => Promise<T>;
}

export const DIALOG_MAPPING = new InjectionToken<
  Record<string, DialogConfig<unknown, unknown, unknown, unknown>>
>('dialogMapping');
