/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { get as lodashGet } from 'lodash-es';

import type { FieldPath, FieldPathValue } from '../entity/type/dot-path';

/* eslint-disable @typescript-eslint/no-explicit-any */
export class ObjectUtils {
  static assign<S extends object, R>(dest: S, source: R): S & R {
    return Object.assign(dest, source);
  }

  static hasProperty<T, K extends keyof T>(obj: T, property: K): boolean {
    return obj && Object.prototype.hasOwnProperty.call(obj, property);
  }

  static isTypeBasedOnProperty<T, K extends keyof T = keyof T>(
    obj: any,
    property: K,
  ): obj is T {
    return !!obj && Object.prototype.hasOwnProperty.call(obj, property);
  }

  static isTypeBasedOnValue<T, K extends keyof T>(
    obj: any,
    property: K,
    value: T[K],
  ): obj is T {
    return (
      !!obj &&
      Object.prototype.hasOwnProperty.call(obj, property) &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      obj[property] === value
    );
  }

  static keys<T extends object, K = keyof T>(
    obj: T,
    map?: (t: any) => any,
  ): readonly K[] {
    const keys = Object.keys(obj) as unknown as readonly K[];
    return map ? keys.map(map) : keys;
  }

  static omit<T extends object, K extends keyof T>(obj: T, key: K): Omit<T, K> {
    const k: Record<string, any> = {};
    const keys = ObjectUtils.keys(obj);
    for (let i = 0; i < keys.length; i++) {
      const _key = keys[i];
      if (_key !== key) {
        k[_key as string] = obj[_key];
      }
    }
    return k as Omit<T, K>;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  static withoutUndefined<T extends object>(
    obj: T | undefined | null,
  ): Partial<T> | undefined | null {
    if (!obj) {
      return obj;
    }

    const newObj: Record<string | number | symbol, unknown> = {};

    const keys = Object.keys(obj) as unknown as (keyof T)[];

    for (const key of keys) {
      if (
        ObjectUtils.hasProperty<T, keyof T>(obj, key) &&
        obj[key] !== undefined
      ) {
        newObj[key] = obj[key];
      }
    }

    return newObj as Partial<T>;
  }
}

export function get<T extends object, K extends FieldPath<T>>(
  obj: T,
  field: K,
): FieldPathValue<T, K> {
  return lodashGet(obj, field) as FieldPathValue<T, K>;
}

type AllKeys<T> = T extends T ? keyof T : never;
export function getValueOrDie<K extends object, T extends AllKeys<K>>(
  obj: K,
  field: T,
): Extract<K, { [K in T]: any }>[T] | undefined {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
  return obj && field in obj ? (obj as any)[field] : undefined;
}
