/* eslint-disable @typescript-eslint/unbound-method */
import { VaultStateFileState } from '@gv/upload/types';
import { BinaryEnumUtils } from '@gv/utils';

export class UploadStateUtils {
  static readonly states: ReadonlyArray<{
    state: VaultStateFileState;
    name: string;
  }> = [
    { state: VaultStateFileState.Inserted, name: 'Inserted' },
    { state: VaultStateFileState.Created, name: 'Created' },
    {
      state: VaultStateFileState.BasicMetadataLoaded,
      name: 'BasicMetadataLoaded',
    },
    {
      state: VaultStateFileState.FullMetadataLoaded,
      name: 'FullMetadataLoaded',
    },
    { state: VaultStateFileState.Presigned, name: 'Presigned' },
    { state: VaultStateFileState.Uploading, name: 'Uploading' },
    { state: VaultStateFileState.Uploaded, name: 'Uploaded' },
    { state: VaultStateFileState.Failed, name: 'Failed' },
    { state: VaultStateFileState.Canceled, name: 'Canceled' },
  ];

  static readonly finalStates: readonly VaultStateFileState[] = [
    VaultStateFileState.Failed,
    VaultStateFileState.Canceled,
    VaultStateFileState.Uploaded,
  ];

  static set = BinaryEnumUtils.set as (
    state: VaultStateFileState,
    ...flags: readonly VaultStateFileState[]
  ) => VaultStateFileState;

  static unset = BinaryEnumUtils.unset as (
    state: VaultStateFileState,
    ...flags: readonly VaultStateFileState[]
  ) => VaultStateFileState;

  static isSet = BinaryEnumUtils.isSet as (
    state: VaultStateFileState,
    ...flags: readonly VaultStateFileState[]
  ) => boolean;

  static allNotSet = BinaryEnumUtils.allNotSet as (
    state: VaultStateFileState,
    ...flags: readonly VaultStateFileState[]
  ) => boolean;

  static someNotSet = BinaryEnumUtils.someNotSet as (
    state: VaultStateFileState,
    ...flags: readonly VaultStateFileState[]
  ) => boolean;

  static format(state: VaultStateFileState): string {
    const names: string[] = [];

    for (const s of UploadStateUtils.states) {
      if (UploadStateUtils.isSet(state, s.state)) {
        names.push(s.name);
      }
    }

    return names.join('|');
  }

  static getFinalState(state: VaultStateFileState): VaultStateFileState {
    return UploadStateUtils.finalStates.find((flag) =>
      UploadStateUtils.isSet(state, flag),
    );
  }
}
