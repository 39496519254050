import type { MonoTypeOperatorFunction } from 'rxjs';
import { Observable } from 'rxjs';

export function finalizeWhenEmpty<T>(
  fn: (type: 'error' | 'complete' | 'unsub') => void,
): MonoTypeOperatorFunction<T> {
  return (source: Observable<T>) => {
    return new Observable<T>((subscriber) => {
      let empty = true;
      let notified = false;

      function notify(type: 'error' | 'complete' | 'unsub'): void {
        if (empty && !notified) {
          fn(type);

          notified = true;
        }
      }

      const sub = source.subscribe({
        next(v: T): void {
          empty = false;

          subscriber.next(v);
        },
        error(e): void {
          notify('error');

          subscriber.error(e);
        },
        complete(): void {
          notify('complete');

          subscriber.complete();
        },
      });

      sub.add(() => {
        notify('unsub');
      });

      return sub;
    });
  };
}
