import type { OnDestroy } from '@angular/core';
import { Injectable, inject } from '@angular/core';

import { getSync, StoreInject } from '@gv/state';
import { LocalStorageService } from '@gv/ui/core';
import type { PersistedUploadModel } from '@gv/upload/types';
import { UploadType } from '@gv/upload/types';
import { untilNgDestroyed } from '@gv/utils';
import { select } from '@ngrx/store';
import type { Subscription } from 'rxjs';
import { EMPTY, Observable } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { APP_UPDATE_CHECKER_SERVICE } from '@gv/ui/utils';
import { WakeLockService, WakeLockType } from '@gv/upload/core';

import { fromUploadsState } from '../selectors/index';
import { UPLOADS_FEATURE_STATE } from '../uploads-feature.state';
import { logger } from '../logger';

@Injectable({
  providedIn: 'root',
})
export class VideoUploadHandlerService implements OnDestroy {
  private appUpdateChecker = inject(APP_UPDATE_CHECKER_SERVICE);
  private wakeLock = inject(WakeLockService);
  private localStorage = inject(LocalStorageService);
  private store = inject(StoreInject(UPLOADS_FEATURE_STATE));
  private static readonly queuedUploadsCacheKey: string = 'gv_queuedUploads';

  private isUploadInProgress$: Observable<boolean> = this.store.pipe(
    select(fromUploadsState.vault.getAnyUploadNeedsUserAttention),
    distinctUntilChanged(),
  );

  private isUploadInProgressSubscription: Subscription | undefined;

  private wakeLockId$ = this.isUploadInProgress$.pipe(
    switchMap((uploading) =>
      !uploading
        ? EMPTY
        : new Observable<never>(() => {
            const lockIdPromise = this.wakeLock.requestWakeLock(
              WakeLockType.APP,
            );
            return () => {
              void lockIdPromise.then((id) => this.wakeLock.release(id));
            };
          }),
    ),
    untilNgDestroyed(),
  );

  enabled = false;

  enable(): void {
    if (this.enabled) {
      return;
    }

    this.enabled = true;

    this.wakeLockId$.subscribe();

    this.isUploadInProgressSubscription = this.isUploadInProgress$.subscribe(
      (uploading) => {
        if (uploading) {
          this.preventAppUpdates();
          return;
        }
        this.enableAppUpdates();
      },
    );
  }

  ngOnDestroy(): void {
    if (this.isUploadInProgressSubscription) {
      this.isUploadInProgressSubscription.unsubscribe();
      this.isUploadInProgressSubscription = undefined;
    }

    this.enableAppUpdates();
  }

  persistUploads(): void {
    const ongoingVaultUploads = [
      ...(getSync(this.store, fromUploadsState.vault.getGroups) || []),
      ...(getSync(this.store, fromUploadsState.vault.getFailedGroups) || []),
    ];

    let queuedUploads: readonly PersistedUploadModel[] = [
      ...ongoingVaultUploads
        .filter((f) => !!f.serverData)
        .map(
          (upload): PersistedUploadModel => ({
            type: UploadType.VAULT,
            uploadId: upload.serverData.uploadId,
            uuid: upload.uuid,
          }),
        ),
    ];

    if (queuedUploads.length === 0) {
      return;
    }

    try {
      const storedUploads = this.localStorage.getItem<PersistedUploadModel[]>(
        VideoUploadHandlerService.queuedUploadsCacheKey,
      );

      if (storedUploads && Array.isArray(storedUploads)) {
        queuedUploads = [...storedUploads, ...queuedUploads];
      }

      this.localStorage.setItem(
        VideoUploadHandlerService.queuedUploadsCacheKey,
        queuedUploads,
      );
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      logger.error({ error: e }, 'Failed to save uploads');
    }
  }

  retrievePersistedUploads(): readonly PersistedUploadModel[] {
    try {
      const data = this.localStorage.getItem<PersistedUploadModel[]>(
        VideoUploadHandlerService.queuedUploadsCacheKey,
      );
      return data;
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      logger.error({ error: e }, 'Failed to retrieve saved uploads');
    } finally {
      this.localStorage.removeItem(
        VideoUploadHandlerService.queuedUploadsCacheKey,
      );
    }

    return undefined;
  }

  private preventAppUpdates(): void {
    this.appUpdateChecker.pause();
  }

  private enableAppUpdates(): void {
    this.appUpdateChecker.resume();
  }
}
