import type { Provider, Signal } from '@angular/core';
import { InjectionToken, computed, inject } from '@angular/core';

import { USER_CONTEXT } from '@gv/user';
import { DateTime } from '@gv/api';
import { PROJECT_CONTEXT, injectContext } from '@gv/context';

import { dateTimeEnumMapping } from './format/dynamic';

export type EnumMappingType<E, T> = E extends keyof T ? T[E] : never;

export const DATETIME_FORMAT = new InjectionToken<
  Signal<EnumMappingType<DateTime, typeof dateTimeEnumMapping>>
>('datetimeFormat');

export function provideDatetimeFormat(): Provider {
  return {
    provide: DATETIME_FORMAT,
    useFactory: () => {
      const userContext = inject(USER_CONTEXT);
      const projectContext = injectContext(PROJECT_CONTEXT, { optional: true });

      return computed(
        () =>
          dateTimeEnumMapping[
            projectContext?.projectS()?.dateTimeFormat ??
              userContext?.userS()?.dateTimeFormat ??
              DateTime.Standard
          ],
      );
    },
  };
}
