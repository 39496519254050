/**
 * This is a partially generated file. Some edits may be overwritten!
 * @apiRef {@model RequestType}
 */
export enum RequestType {
  CameraShare = 'CAMERA_SHARE',
  ProjectOwnerTransfer = 'PROJECT_OWNER_TRANSFER',
  UserPasswordChange = 'USER_PASSWORD_CHANGE',
  UserVerification = 'USER_VERIFICATION',
  UserOrganizationInvitation = 'USER_ORGANIZATION_INVITATION',
}
