import {
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  inject,
} from '@angular/core';
import type { SafeHtml } from '@angular/platform-browser';

import {
  DialogHelpDirective,
  DialogWarningDirective,
} from '../dialog-actions.component';

@Directive({
  standalone: true,
  selector: 'ng-template[gvDialogContent]',
})
export class DialogContent {
  template = inject(TemplateRef<any>);
}

@Directive({
  standalone: true,
  selector: 'ng-template[gvDialogActions]',
})
export class DialogActions {
  template = inject(TemplateRef<any>);
}

@Component({
  template: '',
  standalone: true,
  selector: 'gv-base-variant-dialog',
})
export class BaseVariantDialog {
  @Input()
  text: string | undefined;

  @Input()
  subtitle: SafeHtml | undefined;

  @Input()
  disableClose = false;

  @Input()
  disableButton = false;

  @Output()
  close = new EventEmitter();

  @ContentChild(DialogContent, { static: false })
  dialogContent: DialogContent;

  @ContentChild(DialogActions, { static: false })
  dialogActions: DialogActions;

  @ContentChild(DialogHelpDirective, { static: false, descendants: true })
  protected help: DialogHelpDirective | undefined;

  @ContentChild(DialogWarningDirective, { static: false, descendants: true })
  protected warning: DialogWarningDirective | undefined;
}
