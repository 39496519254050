import { UploadFile } from '@gv/upload/types';
import { isObject, isString } from '@gv/utils';

export class FileUtils {
  // eslint-disable-next-line no-useless-escape
  private static lastFolderNameRegex = /([^\/\\]*)[\\\/][^\/\\]*$/;
  private static isNestedFolderNameRegex =
    // eslint-disable-next-line no-useless-escape
    /^([\\\/]?([^\/\\]*)[\\\/])?[^\/\\]*$/;

  static getUploadOptions(
    rootPrefix: string,
    prefix: string,
    folder: string,
    _mode: 'dropped' | 'dialog' | 'button',
  ): { prefix: string; rootPrefix: string; createNewOnConflict: boolean } {
    const folderPrefix = prefix ?? rootPrefix;
    if (!folder || folder.trim().length === 0) {
      return { prefix: folderPrefix, rootPrefix, createNewOnConflict: false };
    }

    return {
      prefix: `${folderPrefix}/${folder}`,
      rootPrefix,
      createNewOnConflict: false,
    };
  }

  static getPath(file: File | UploadFile | string, removeTop: boolean): string {
    let path: string = isString(file)
      ? file
      : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (isObject(file) && ((file as any).webkitRelativePath as string)) ||
        file.path;

    if (path) {
      if (removeTop) {
        const index = path.indexOf('/');

        if (index > 0) {
          path = path.slice(index + 1);
        }
      }

      return path;
    }

    return isString(file) ? file : file.name;
  }

  static getLastFolderName(file: File | string): string {
    const path: string = isString(file)
      ? file
      : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        isObject(file) && (file as any).webkitRelativePath
        ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          ((file as any).webkitRelativePath as string)
        : undefined;

    if (path) {
      const match = path.match(FileUtils.lastFolderNameRegex);
      if (match && match.length > 1) {
        return match[1];
      }
    }

    return undefined;
  }

  static pathJoin(path: string, name: string): string {
    return path.endsWith('/') || name.startsWith('/')
      ? `${path}${name}`
      : `${path}/${name}`;
  }

  static isNotNested(file: File | UploadFile | string): boolean {
    if (file instanceof UploadFile) {
      return true;
    }

    const path: string = isString(file)
      ? file
      : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (isObject(file) && ((file as any).webkitRelativePath as string)) ||
        file.path;

    if (path) {
      return !!path.match(FileUtils.isNestedFolderNameRegex);
    }

    return true;
  }
}
