import type { OnDestroy } from '@angular/core';
import { Injectable, inject } from '@angular/core';

import { ElectronRefService } from '@gv/desktop/core';

export enum WakeLockType {
  SCREEN = 'SCREEN',
  APP = 'APP',
}

// TODO: make this service optional
@Injectable({
  providedIn: 'root',
})
export class WakeLockService implements OnDestroy {
  private electronRef = inject(ElectronRefService, { optional: true });
  private locks: number[] = [];

  ngOnDestroy(): void {
    void this.releaseAll();
  }

  async requestWakeLock(type: WakeLockType): Promise<number> {
    if (this.electronRef) {
      const lockId = await this.electronRef.api.app.powerSaveBlockerStart(
        type === WakeLockType.APP
          ? 'prevent-app-suspension'
          : 'prevent-display-sleep',
      );

      this.locks.push(lockId);

      return lockId;
    }
    return undefined;
  }

  async release(id: number): Promise<void> {
    const index = this.locks.indexOf(id);
    if (index >= 0) {
      this.locks.splice(index, 1);
    }

    if (this.electronRef) {
      await this.electronRef.api.app.powerSaveBlockerStop(id);
    }
  }

  private releaseAll(): Promise<void> {
    if (!this.electronRef) {
      return undefined;
    }

    const promises = this.locks.map((id) =>
      this.electronRef.api.app.powerSaveBlockerStop(id),
    );

    this.locks.length = 0;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return Promise.all(promises).then(() => undefined);
  }
}
