/* eslint-disable @typescript-eslint/ban-types */
export type MutableObject<T> = {
  -readonly [P in keyof T]: Mutable<T[P]>;
};
export type MutableArray<T> = Mutable<T>[];

export type Mutable<T> = T extends any[]
  ? MutableArray<T[number]>
  : T extends ReadonlyArray<any>
    ? MutableArray<T[number]>
    : T extends Date
      ? T
      : T extends object
        ? MutableObject<T>
        : T;

export type SoftMutableObject<T> = {
  -readonly [P in keyof T]: SoftMutable<T[P]>;
};

export type SoftMutable<T> = T extends any[]
  ? ReadonlyArray<T[number]>
  : T extends ReadonlyArray<any>
    ? ReadonlyArray<T[number]>
    : T extends Date
      ? T
      : T extends object
        ? SoftMutableObject<T>
        : T;

export function asMutable<T>(val: T): Mutable<T> {
  return val as Mutable<T>;
}
