import type { UploadGroupModel } from '@gv/upload/types';

export interface UploadProgress {
  readonly progress: number;
  readonly eta?: number;
  readonly type: 'upload' | 'encode' | 'metadata';
}

export interface UploadsState {
  readonly progress: {
    [uuid: string]: UploadProgress;
  };

  readonly uploadGroupsIds: readonly string[];
  readonly finishedGroupsIds: readonly string[];
  readonly failedGroupsIds: readonly string[];
  readonly canceledGroupsIds: readonly string[];

  readonly uploadGroups: {
    [uuid: string]: UploadGroupModel;
  };
}

export const initialUploadsState: UploadsState = {
  progress: {},

  uploadGroupsIds: [],
  finishedGroupsIds: [],
  failedGroupsIds: [],
  canceledGroupsIds: [],

  uploadGroups: {},
};
