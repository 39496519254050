import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import { LocalizationUtils } from '@gv/utils';

import { environment } from '../../../environments/environment';
import { LocalizationService } from '../../service/helper/localization.service';
import { RouterActions } from '../action/router.actions';

@Injectable()
export class RouterEffects {
  private actions$ = inject<Actions>(Actions);
  private router = inject(Router);
  private localizationService = inject(LocalizationService);
  redirectToProjects$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.navigateToProjects),
        exhaustMap((action) => {
          const currentLang = LocalizationUtils.getCurrentUrlLanguage();
          if (
            !!action.lang &&
            action.lang !== currentLang &&
            environment.production
          ) {
            const newUrl = '/app/project';

            const url = this.localizationService.rerouteToLanguage(
              currentLang,
              action.lang,
              newUrl,
            );

            url.then((url) => {
              if (url && action.queryParams) {
                for (const key of Object.keys(action.queryParams)) {
                  url.searchParams.set(key, action.queryParams[key]);
                }
              }
            });
          } else {
            this.router.navigate(['/app/project'], {
              queryParams: action.queryParams,
            });
          }

          return EMPTY;
        }),
      ),
    { dispatch: false },
  );
}
