import { Injectable, inject } from '@angular/core';

import { isActionOfType, onFlowFinished, StoreInject } from '@gv/state';
import type { UserModel } from '@gv/user';
import { isTokenValid, UserActions } from '@gv/user';
import { Actions } from '@ngrx/effects';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import { APP_STATE } from '../../../store/state/app-state';
import { TokenStoreService } from './token-store.service';

@Injectable({
  providedIn: 'root',
})
export class UserInitializerService {
  private tokenStore = inject(TokenStoreService);
  private actions$ = inject<Actions>(Actions);
  private store = inject(StoreInject(APP_STATE));

  /**
   * Initializes the user using the token from user service (if available & valid).
   *
   * @returns Resolve to true if user was successfully initialized, otherwise to false.
   */
  async initializeUser(
    token: string = this.tokenStore.token,
    noUpdate?: boolean,
  ): Promise<UserModel | undefined> {
    if (!isTokenValid(token, this.tokenStore.timeOffset)) {
      return undefined;
    }

    const flowAction = UserActions.refreshUserFlow.init({
      data: { debounce: false, noUpdate, token },
    });

    const flowFinishedPromise: Promise<UserModel | undefined> = firstValueFrom(
      this.actions$.pipe(
        onFlowFinished(flowAction, UserActions.refreshUserFlow),
        map((action) =>
          isActionOfType(action, UserActions.refreshUserFlow.completed)
            ? action.data
            : undefined,
        ),
      ),
    );

    this.store.dispatch(flowAction);

    return flowFinishedPromise;
  }
}
