import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import { humanReadableFileSize, NumberUtils } from '@gv/utils';

@Pipe({
  name: 'formatBytes',
  standalone: true,
})
export class FormatBytesPipe implements PipeTransform {
  transform(value: number): string {
    const size = humanReadableFileSize(value);

    return `${NumberUtils.format(size.value, 2, true, 'floor')} ${size.unit}`;
  }
}
