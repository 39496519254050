import { Injectable } from '@angular/core';

import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollbarService {
  private scrollbarVisibleSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  readonly scrollbarVisible$: Observable<boolean> =
    this.scrollbarVisibleSubject.asObservable();

  get isScrollbarVisible(): boolean {
    return this.scrollbarVisibleSubject.getValue();
  }

  set isScrollbarVisible(isVisible: boolean) {
    if (isVisible !== this.scrollbarVisibleSubject.getValue()) {
      this.scrollbarVisibleSubject.next(isVisible);
    }
  }
}
