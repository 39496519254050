import { NgModule } from '@angular/core';

import {
  REQUEST_DIRECT_INTERCEPTORS,
  REQUEST_INTERCEPTORS,
  REQUEST_LOGOUT_INTERCEPTORS,
} from '../../entity/token/request-interceptors';
import { DomainRedirectInterceptor } from '../../service/http/axios/interceptor/domain-redirect-interceptor';
import { ElectronCookieInterceptor } from '../../service/http/axios/interceptor/electron-cookie-interceptor';
import { JwtInterceptor } from '../../service/http/axios/interceptor/jwt-interceptor';
import { InternalServerErrorInterceptor } from '../../service/http/axios/interceptor/server-error-interceptor';
import { ServiceUnavailableInterceptor } from '../../service/http/axios/interceptor/service-unavailable-interceptor';
import { UnauthorizedApiRequestInterceptor } from '../../service/http/axios/interceptor/unauthorized-api-request-interceptor';
import { UntilLogoutInterceptor } from '../../service/http/axios/interceptor/until-logout-interceptor';

@NgModule({
  providers: [
    ...(ELECTRON
      ? [
          {
            provide: REQUEST_INTERCEPTORS,
            useClass: ElectronCookieInterceptor,
            multi: true,
          },
        ]
      : []),
    {
      provide: REQUEST_INTERCEPTORS,
      useClass: DomainRedirectInterceptor,
      multi: true,
    },
    {
      provide: REQUEST_DIRECT_INTERCEPTORS,
      useClass: DomainRedirectInterceptor,
      multi: true,
    },
    {
      provide: REQUEST_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: REQUEST_INTERCEPTORS,
      useClass: InternalServerErrorInterceptor,
      multi: true,
    },
    {
      provide: REQUEST_INTERCEPTORS,
      useClass: ServiceUnavailableInterceptor,
      multi: true,
    },
    {
      provide: REQUEST_INTERCEPTORS,
      useClass: UnauthorizedApiRequestInterceptor,
      multi: true,
    },
    {
      provide: REQUEST_LOGOUT_INTERCEPTORS,
      useClass: UntilLogoutInterceptor,
      multi: true,
    },
  ],
})
export class HttpInterceptorModule {}
