import { inject } from '@angular/core';

import type { Store } from '@gv/state';
import { NgStore, StoreInjectionToken } from '@gv/state';

import type { userFeatureKey } from '../reducer/user.reducer';
import type { UserState } from './user.state';

export interface AppState {
  [userFeatureKey]: UserState;
}

export const APP_STATE = new StoreInjectionToken<Store<AppState>>('appState', {
  factory: () => {
    return inject(NgStore) as unknown as Store<AppState>;
  },
});
