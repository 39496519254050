import { Injectable, inject } from '@angular/core';

import type { ApiResponse, Decodable } from '@gv/api';
import { decode } from '@gv/api';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { RawAxiosRequestConfig } from 'axios';

import { logger } from '../../../logger';
import { HttpServiceHandler } from '../http/axios/http';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private httpServiceHandler = inject(HttpServiceHandler);

  withConfig<T>(config: RawAxiosRequestConfig, cb: () => T): T | undefined {
    return this.httpServiceHandler.withConfig(config, cb);
  }

  static mapData = <T>(response: ApiResponse<T>): T => {
    return response?.data;
  };
  private static mapResponse = <T>(response: ApiResponse<T>, _index: number) =>
    response;
  private static mapResponseWithDecode = <T>(
    response: ApiResponse<T>,
    _index: number,
    decodable: Decodable<T> | undefined,
  ): ApiResponse<T> => {
    try {
      decode(response.data, decodable);
    } catch (e) {
      logger.error({ error: e }, 'Failed to decode api object');
    }
    return response;
  };

  get<T>(
    url: string,
    decodable: Decodable<T> | undefined,
    options?: {
      headers?: Record<string, string>;
      params?: Record<string, string>;
      responseType?: 'json' | 'blob';
      baseURL?: string;
    },
  ): Observable<ApiResponse<T>> {
    const responseObs: Observable<ApiResponse<T>> =
      this.httpServiceHandler.request<T>('get', url, {
        params: options?.params,
        headers: options?.headers,
        responseType: options?.responseType || 'json',
        baseURL: options?.baseURL,
      });

    if (options?.responseType === 'blob') {
      return responseObs;
    }

    return responseObs.pipe(
      map(
        decodable
          ? (...args) => HttpService.mapResponseWithDecode(...args, decodable)
          : HttpService.mapResponse,
      ),
    );
  }

  head<T>(
    url: string,
    decodable: Decodable<T> | undefined,
    options?: {
      headers?: Record<string, string>;
      params?: Record<string, string>;
      responseType?: 'json' | 'blob';
      baseURL?: string;
    },
  ): Observable<ApiResponse<T>> {
    const responseObs: Observable<ApiResponse<T>> =
      this.httpServiceHandler.request<T>('head', url, {
        params: options?.params,
        headers: options?.headers,
        responseType: options?.responseType || 'json',
        baseURL: options?.baseURL,
      });

    if (options?.responseType === 'blob') {
      return responseObs;
    }

    return responseObs.pipe(
      map(
        decodable
          ? (...args) => HttpService.mapResponseWithDecode(...args, decodable)
          : HttpService.mapResponse,
      ),
    );
  }

  post<T>(
    url: string,
    body: any | null,
    decodable: Decodable<T> | undefined,
    options?: {
      headers?: Record<string, string>;
      params?: Record<string, string>;
      responseType?: 'json' | 'blob';
      baseURL?: string;
    },
  ): Observable<ApiResponse<T>> {
    return this.httpServiceHandler
      .request<T>('post', url, {
        data: body,
        params: options?.params,
        headers: options?.headers,
        responseType: options?.responseType || 'json',
        baseURL: options?.baseURL,
      })
      .pipe(
        map(
          decodable
            ? (response, index) =>
                HttpService.mapResponseWithDecode(
                  response as any,
                  index,
                  decodable,
                )
            : HttpService.mapResponse,
        ),
      );
  }

  put<T>(
    url: string,
    body: any | null,
    decodable: Decodable<T> | undefined,
    options?: {
      headers?: Record<string, string>;
      params?: Record<string, string>;
      responseType?: 'json' | 'blob';
      baseURL?: string;
    },
  ): Observable<ApiResponse<T>> {
    return this.httpServiceHandler
      .request('put', url, {
        data: body,
        params: options?.params,
        headers: options?.headers,
        responseType: options?.responseType || 'json',
        baseURL: options?.baseURL,
      })
      .pipe(
        map(
          decodable
            ? (response, index) =>
                HttpService.mapResponseWithDecode(
                  response as any,
                  index,
                  decodable,
                )
            : HttpService.mapResponse,
        ),
      );
  }

  delete<T>(
    url: string,
    decodable: Decodable<T> | undefined,
    options?: {
      headers?: Record<string, string>;
      params?: Record<string, string>;
      responseType?: 'json' | 'blob';
      baseURL?: string;
    },
  ): Observable<ApiResponse<T>> {
    return this.httpServiceHandler
      .request('delete', url, {
        params: options?.params,
        headers: options?.headers,
        responseType: options?.responseType || 'json',
        baseURL: options?.baseURL,
      })
      .pipe(
        map(
          decodable
            ? (response, index) =>
                HttpService.mapResponseWithDecode(
                  response as any,
                  index,
                  decodable,
                )
            : HttpService.mapResponse,
        ),
      );
  }
}
