import { Injectable, inject } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CookieService } from 'ngx-cookie-service';
import type { CookieService as CookieServiceInterface } from '@gv/ui/utils';

@Injectable()
export class WebApplicationCookieService implements CookieServiceInterface {
  private cookieService = inject(CookieService);

  check(name: string): Promise<boolean> {
    return Promise.resolve(this.cookieService.check(name));
  }

  get(name: string): Promise<string> {
    return Promise.resolve(this.cookieService.get(name));
  }

  getSync(name: string): string {
    return this.cookieService.get(name);
  }

  getAll(): Promise<{ [name: string]: string }> {
    return Promise.resolve(this.cookieService.getAll());
  }

  set(
    name: string,
    value: string,
    expires?: number | Date,
    path?: string,
    domain?: string,
    secure?: boolean,
  ): Promise<void> {
    this.cookieService.set(name, value, expires, path, domain, secure);
    return Promise.resolve();
  }

  deleteAll(path?: string, domain?: string): Promise<void> {
    this.cookieService.deleteAll(path, domain);
    return Promise.resolve();
  }

  delete(name: string, path?: string, domain?: string): Promise<void> {
    this.cookieService.delete(name, path, domain);
    return Promise.resolve();
  }
}
