import { UploadFile } from '@gv/upload/types';

import type { ElectronFileModel } from '../electron-file-model';
import type { ElectronRefService } from '../../electron-ref.service';
import { logger } from '../../../logger';

export class ElectronFile extends UploadFile {
  private data!: ElectronFileModel;

  get name(): string {
    return this.data?.name;
  }

  get size(): number {
    return this.data?.size;
  }

  get lastModified(): Date {
    return this.data?.lastModified;
  }

  get isDirectory(): boolean {
    return this.data?.isDirectory;
  }

  get isFile(): boolean {
    return this.data?.isFile;
  }

  get type(): string {
    return this.data?.type;
  }

  constructor(
    public path: string,
    private electronRef: ElectronRefService,
  ) {
    super();
  }

  async init(autoClose = true): Promise<void> {
    if (this.data) {
      return;
    }

    const file = await this.electronRef.api.file.open(this.path);
    this.data = file!;

    if (autoClose) {
      await this.close();
    }
  }

  async close(): Promise<void> {
    if (this.data) {
      await this.electronRef.api.file.close(this.data);
    }
  }

  slice(start: number, end: number): Promise<Uint8Array | undefined> {
    return this.electronRef.api.file.slice({ path: this.path, start, end });
  }

  hash(chunkSize: number): Promise<string | undefined> {
    return this.electronRef.api.file.hash({ path: this.path, chunkSize });
  }

  async reopen(): Promise<void> {
    await this.close();
    const file = await this.electronRef.api.file.open(this.path);
    if (file?.size !== this.data?.size) {
      logger.warn('Electron file size changed');
    }
  }
}
