import { createSelector } from '@ngrx/store';
import type { VaultStateFileModel } from '@gv/upload/types';
import { createFeatureSelector } from '@gv/state';

import { uploadsFeatureKey } from '../reducers/uploads.reducer';
import type { UploadsFeatureState } from '../uploads-feature.state';
import type { UploadProgress, UploadsState } from '../uploads.state';

export const ratioMapping: Record<
  UploadProgress['type'],
  (progress: number, file: VaultStateFileModel) => number
> = {
  encode: (progress: number, _file: VaultStateFileModel) => progress,
  upload: (progress: number, _file: VaultStateFileModel) => progress,
  metadata: (progress: number) => progress,
};

const getState = createFeatureSelector<UploadsFeatureState>(uploadsFeatureKey);

const getFile = (props: { uploadGroupUuid: string; uuid: string }) =>
  createSelector(
    getState,
    (state): VaultStateFileModel =>
      state.uploadGroups[props.uploadGroupUuid]?.files[props.uuid],
  );

const _getProgress = createSelector(
  getState,
  (state: UploadsState) => state.progress,
);

const getUploadProgress = (props: { uploadGroupUuid: string; uuid: string }) =>
  createSelector(
    _getProgress,
    getFile(props),
    (
      progress: { [uuid: string]: UploadProgress },
      file: VaultStateFileModel,
    ) => {
      const origProgress = progress[props.uuid];
      if (!origProgress) {
        if (file.encoded) {
          return {
            progress: ratioMapping['encode'](1, file) * 100,
            type: 'encode' as const,
          };
        }

        return origProgress;
      }

      return {
        ...origProgress,
        progress:
          ratioMapping[origProgress.type](
            (origProgress.progress || 0) / 100,
            file,
          ) * 100,
      };
    },
  );

export { getState, getFile, _getProgress, getUploadProgress };
