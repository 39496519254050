import { Injectable, Injector, inject } from '@angular/core';

import type { ApiResponse } from '@gv/api';
import { generateBaseUrl, handle } from '@gv/api';
import { appVersion, errorMapping } from '@gv/constant';
import { ElectronAppInfoService } from '@gv/desktop/core';
import { WindowRefService } from '@gv/ui/core';
import { ConnectionStatusCheckerService } from '@gv/ui/utils';
import { generateUuid, ObjectUtils } from '@gv/utils';
import type {
  AxiosInstance,
  Method,
  RawAxiosRequestConfig,
  RawAxiosRequestHeaders,
} from 'axios';
import axios from 'axios';
import type { Draft } from 'immer';
import { produce } from 'immer';
import type { Observable } from 'rxjs';
import { EMPTY } from 'rxjs';

import { environment } from '../../../../environments/environment';
import {
  REQUEST_DIRECT_INTERCEPTORS,
  REQUEST_INTERCEPTORS,
  REQUEST_LOGOUT_INTERCEPTORS,
} from '../../../entity/token/request-interceptors';
import type { Retryable } from '../../../entity/type/http/retryable';
import { InterceptorRequestHandler, RequestHandler } from './interceptor';

export type RequestConfig<T> = RawAxiosRequestConfig &
  Retryable & { data?: T } & { raw?: boolean };

@Injectable({ providedIn: 'root' })
export class HttpServiceHandler extends RequestHandler {
  private windowRef = inject(WindowRefService);
  private injector = inject(Injector);
  private connectionStatusChecker = inject(ConnectionStatusCheckerService);
  private requestHandler: RequestHandler | null = null;
  private requestHandlerDirect: RequestHandler | null = null;

  private useDirect = false;
  private useRaw = false;
  private useConfig: RawAxiosRequestConfig | undefined;

  private _baseUrl = environment.api.useStaticBaseUrl
    ? environment.api.staticBaseUrl
    : generateBaseUrl(this.windowRef.nativeWindow.location);

  private client = axios.create({
    baseURL: this._baseUrl,
    withXSRFToken: true,
    xsrfHeaderName: 'X-CSRF_TOKEN',
    xsrfCookieName: 'XSRF-TOKEN',
  });

  private _rawClient: AxiosInstance;
  private get rawClient(): AxiosInstance {
    return (
      this._rawClient ??
      (this._rawClient = axios.create({ baseURL: this._baseUrl }))
    );
  }

  private _electronAppInfo: ElectronAppInfoService;
  private get electronAppInfo(): ElectronAppInfoService | null {
    return this._electronAppInfo !== undefined
      ? this._electronAppInfo
      : (this._electronAppInfo = this.injector.get(
          ElectronAppInfoService,
          null,
        ));
  }

  constructor() {
    super();
  }

  withDirect<T>(cb: () => T): T | undefined {
    this.useDirect = true;
    try {
      return cb();
    } catch (e) {
      //
    } finally {
      this.useDirect = false;
    }
    return undefined;
  }

  withConfig<T>(config: RawAxiosRequestConfig, cb: () => T): T | undefined {
    this.useConfig = config;
    try {
      return cb();
    } catch (e) {
      //
    } finally {
      this.useConfig = undefined;
    }
    return undefined;
  }

  withRaw<T>(cb: () => T): T | undefined {
    this.useRaw = true;
    try {
      return cb();
    } catch (e) {
      //
    } finally {
      this.useRaw = false;
    }
    return undefined;
  }

  request<T = any>(
    method?: Method,
    url?: string,
    config?: RawAxiosRequestConfig,
  ): Observable<ApiResponse<T>> {
    if (this.useDirect) {
      if (this.requestHandlerDirect === null) {
        const interceptors = [
          ...this.injector.get(REQUEST_DIRECT_INTERCEPTORS, []),
        ];
        this.requestHandlerDirect = interceptors.reduceRight(
          (next, interceptor): InterceptorRequestHandler =>
            new InterceptorRequestHandler(next, interceptor),
          this as RequestHandler,
        );
      }
      return this.requestHandlerDirect.handle(
        this.createDirectRequest(method, url, config),
      );
    }

    if (this.requestHandler === null) {
      const interceptors = [
        ...this.injector.get(REQUEST_INTERCEPTORS, []),
        ...this.injector.get(REQUEST_LOGOUT_INTERCEPTORS, []),
      ];
      this.requestHandler = interceptors.reduceRight(
        (next, interceptor): InterceptorRequestHandler =>
          new InterceptorRequestHandler(next, interceptor),
        this as RequestHandler,
      );
    }

    const requestUuid = generateUuid();

    const bypassRedirect = !!config.headers?.['bypass-redirect'];
    if (bypassRedirect) {
      config = produce(config, (draft) => {
        delete draft.headers['bypass-redirect'];
      });
    }

    const request: RequestConfig<any> = {
      method,
      url,
      ...(config as Draft<typeof config>),
      headers:
        url.startsWith('http') && !url.includes(this._baseUrl)
          ? ({
              ...ObjectUtils.withoutUndefined(config.headers || {}),
            } as RawAxiosRequestHeaders)
          : ({
              ...ObjectUtils.withoutUndefined(config.headers || {}),
              'x-request_id': requestUuid,
              'x-version': appVersion,
              ...(this.electronAppInfo
                ? {
                    'x-desktop_version':
                      this.electronAppInfo.appInfo?.version ?? '1.0.0',
                  }
                : {}),
              ...(environment.api.xsrfToken
                ? {
                    'X-CSRF_TOKEN': environment.api.xsrfToken,
                  }
                : {}),
            } as RawAxiosRequestHeaders),
      rid: requestUuid,
      bypassRedirect: bypassRedirect,
      bypassJwt:
        config.headers?.['authorization'] &&
        typeof config.headers?.['authorization'] === 'string' &&
        config.headers?.['authorization'].length > 0,
      createdAt: new Date(),
      ...(this.useConfig || {}),
    };

    return this.requestHandler.handle(request);
  }

  private createDirectRequest<T = any>(
    method?: Method,
    url?: string,
    config?: RawAxiosRequestConfig,
    noHeaders?: boolean,
  ): RequestConfig<T> {
    const requestUuid = generateUuid();

    const bypassRedirect = !!config.headers?.['bypass-redirect'];
    if (bypassRedirect) {
      config = produce(config, (draft) => {
        delete draft.headers['bypass-redirect'];
      });
    }

    const request: RequestConfig<any> = {
      method,
      url,
      ...(config as Draft<typeof config>),
      headers:
        url.startsWith('http') && !url.includes(this._baseUrl)
          ? ({
              ...ObjectUtils.withoutUndefined(config.headers || {}),
            } as RawAxiosRequestHeaders)
          : ({
              ...ObjectUtils.withoutUndefined(config.headers || {}),
              ...(noHeaders
                ? {}
                : {
                    'x-request_id': requestUuid,
                    'x-version': appVersion,
                    ...(this.electronAppInfo
                      ? {
                          'x-desktop_version':
                            this.electronAppInfo.appInfo?.version ?? '1.0.0',
                        }
                      : {}),
                  }),
              ...(environment.api.xsrfToken
                ? {
                    'X-CSRF_TOKEN': environment.api.xsrfToken,
                  }
                : {}),
            } as RawAxiosRequestHeaders),
      rid: requestUuid,
      bypassRedirect: bypassRedirect,
      bypassJwt:
        config.headers?.['authorization'] &&
        typeof config.headers?.['authorization'] === 'string' &&
        config.headers?.['authorization'].length > 0,
      createdAt: new Date(),
      raw: this.useRaw,
    };
    return request;
  }

  requestDirect<T = any>(
    method?: Method,
    url?: string,
    config?: RawAxiosRequestConfig,
    noHeaders?: boolean,
  ): Observable<ApiResponse<T>> {
    return this.handle(
      this.createDirectRequest(method, url, config, noHeaders),
    );
  }

  handle<T = any>(config: RequestConfig<any>): Observable<ApiResponse<T>> {
    if (environment.test) {
      return EMPTY;
    }

    const client = config.raw ? this.rawClient : this.client;
    return handle(client, config, errorMapping, this.callbacks);
  }

  private callbacks = {
    done: () => this.connectionStatusChecker.reportOnline(),
    error: (status: number) =>
      status === 0 && this.connectionStatusChecker.reportOffline(),
  };
}
