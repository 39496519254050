import { InjectionToken, inject } from '@angular/core';

import type { TypedAction } from '@ngrx/store/src/models';

import { StoreInjectionToken } from '../util/inject';
import { NgStore } from '../util/store';
import type { Store } from '../util/store';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EmptyState {}

export const EMPTY_STATE = new StoreInjectionToken<Store<EmptyState>>(
  'emptyState',
  {
    factory: () => {
      return inject(NgStore) as unknown as Store<EmptyState>;
    },
  },
);

export const HIGHLIGHT_MAPPING = new InjectionToken<
  Record<
    string,
    | {
        waitFor?: string;
        action?: (
          data: Record<string, any>,
        ) => TypedAction<string> | TypedAction<string>[];
        local?: boolean;
      }
    | { proxy: true; wait?: boolean }
  >
>('highlightMapping');
