import type { DestroyRef } from '@angular/core';

import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

import { untilNgDestroyed } from '../operator/until-destroyed';

export class BehaviorField<T> extends BehaviorSubject<T> {
  readonly $ = this.asObservable();

  constructor(
    defaultValue: T,
    private obsConfig?: { obs: Observable<T>; destroyable: DestroyRef },
  ) {
    super(defaultValue);

    if (this.obsConfig) {
      this.obsConfig.obs
        .pipe(untilNgDestroyed(this.obsConfig.destroyable))
        .subscribe((v) => {
          this.value = v;
        });
    }
  }

  override get value(): T {
    return this.getValue();
  }

  override set value(value: T) {
    if (this.value !== value) {
      this.next(value);
    }
  }
}
