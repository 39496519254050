import type { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import axios from 'axios';
import { DateTime } from 'luxon';
import type { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  timer,
  from,
  isObservable,
  switchMap,
  of,
  combineLatest,
} from 'rxjs';

export const isChristmas$ = timer(0, 60 * 60 * 1000).pipe(
  map(() => DateTime.now().month === 12),
  shareReplay({ bufferSize: 1, refCount: true }),
);

export const isHalloween$ = timer(0, 60 * 60 * 1000).pipe(
  map(() => {
    const time = DateTime.now();
    return (
      (time.month === 10 && time.day > 20) ||
      (time.month === 11 && time.day < 5)
    );
  }),
  shareReplay({ bufferSize: 1, refCount: true }),
);

export const logo$ = combineLatest([isChristmas$, isHalloween$]).pipe(
  map(([isChristmas, isHalloween]) =>
    isChristmas
      ? './assets/images/logo-christmas.png'
      : isHalloween
        ? './assets/images/logo-halloween.png'
        : './assets/images/video-insights-logo-r.svg?v=1',
  ),
  distinctUntilChanged(),
  shareReplay({ bufferSize: 1, refCount: true }),
);

export const toolbarLogo$ = combineLatest([isChristmas$, isHalloween$]).pipe(
  map(([isChristmas, isHalloween]) =>
    isChristmas
      ? './assets/images/header-logos/logo-christmas.svg'
      : isHalloween
        ? './assets/images/header-logos/logo-halloween.svg'
        : './assets/images/header-logos/logo.svg?v=1',
  ),
  distinctUntilChanged(),
  shareReplay({ bufferSize: 1, refCount: true }),
);

export const toolbarTextLogo = './assets/images/header-logos/logo-text.svg?v=1';
export const toolbarTextFullLogo =
  './assets/images/header-logos/logo-text-full.svg?v=1';

const _logoCache = new Map<string | Observable<string>, Observable<string>>();

export const loadLogo = (
  url: string | Observable<string>,
  domSanitizer: DomSanitizer,
): Observable<SafeHtml> => {
  let obs = _logoCache.get(url);
  if (!obs) {
    obs = (isObservable(url) ? url : of(url)).pipe(
      switchMap((url) =>
        from(axios.get(url).then((response) => response.data as string)),
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    _logoCache.set(url, obs);
  }

  return obs.pipe(
    map((data) => domSanitizer.bypassSecurityTrustHtml(data)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
};
