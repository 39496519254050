import type { OnDestroy } from '@angular/core';
import { DestroyRef, Injectable, inject } from '@angular/core';

import { delayImmediate, untilNgDestroyed } from '@gv/utils';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  scan,
  shareReplay,
  startWith,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ThemeService implements OnDestroy {
  private destroyRef = inject(DestroyRef);
  private themeOverrideSubject = new Subject<'add' | 'remove'>();

  readonly themeOverride$ = this.themeOverrideSubject.pipe(
    scan((acc, action) => acc + (action === 'add' ? 1 : -1), 0),
    startWith(0),
    map((overrideLocks) => overrideLocks > 0),
    distinctUntilChanged(),
    delayImmediate(),
    untilNgDestroyed(),
    shareReplay(1),
  );

  private finish = () => this.themeOverrideSubject.next('remove');

  forceTheme(observable: Observable<void>): void {
    this.themeOverrideSubject.next('add');

    observable.pipe(untilNgDestroyed(this.destroyRef)).subscribe({
      error: this.finish,
      complete: this.finish,
    });
  }

  ngOnDestroy(): void {
    //
  }
}
