import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';

import { untilNgDestroyed } from '@gv/utils';
import {
  distinctUntilChanged,
  map,
  Observable,
  shareReplay,
  startWith,
} from 'rxjs';

import type { Environment } from '../entity/enum';

export interface ExtendedWindow extends Window {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hj?: (method: string, arg: any) => void;

  // eslint-disable-next-line @typescript-eslint/ban-types
  dataLayer?: object[];

  _environment: Environment;

  debugModeEnabled?: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  debugObj: { [key: string]: any };

  enableDebugMode?: () => void;
  disableDebugMode?: () => void;
  _cookiePatched: boolean;
  slugHash?: string;

  gvContext: {
    mainColor: string;
    dark: boolean;
  };
}

export function _window(): ExtendedWindow {
  return window as unknown as ExtendedWindow;
}

@Injectable({
  providedIn: 'root',
})
export class WindowRefService implements OnDestroy {
  readonly themeUpdated$ = new Observable((sub) => {
    const fn = () => {
      sub.next();
    };

    this.nativeWindow.addEventListener('theme-updated', fn);

    return () => {
      this.nativeWindow.removeEventListener('theme-updated', fn);
    };
  }).pipe(untilNgDestroyed(), shareReplay(1));

  readonly theme$ = this.themeUpdated$.pipe(
    startWith(undefined),
    map(() => this.nativeWindow?.gvContext?.dark),
    distinctUntilChanged(),
  );

  get nativeWindow(): ExtendedWindow {
    return _window();
  }

  ngOnDestroy(): void {
    //
  }
}
