import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { ButtonSubmittingTextDirective } from '../component/button/button-submitting-text.directive';
import { ButtonComponent } from '../component/button/button.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    RouterModule.forChild([]),
    ButtonComponent,
    ButtonSubmittingTextDirective,
  ],
  exports: [ButtonComponent, ButtonSubmittingTextDirective],
})
export class ButtonModule {}
