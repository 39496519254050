import { Injectable, inject } from '@angular/core';

import type { ApiResponse } from '@gv/api';
import { ApiErrorResponse } from '@gv/api';
import { ObjectUtils, RetryMode, retryOnError } from '@gv/utils';
import type { Observable } from 'rxjs';
import { of, throwError } from 'rxjs';
import { catchError, delay, switchMap, take, tap } from 'rxjs/operators';

import { ApiService } from '../../../api/api.service';
import { TokenStoreService } from '../../../application/user/token-store.service';
import type { RequestConfig } from '../http';
import type { RequestHandler, RequestInterceptor } from '../interceptor';
import { JwtInterceptor } from './jwt-interceptor';

type ErrorWithToken = {
  readonly error: any;
  readonly token: string;
};

@Injectable({
  providedIn: 'root',
})
export class UnauthorizedApiRequestInterceptor implements RequestInterceptor {
  private apiService = inject(ApiService);
  private tokenStore = inject(TokenStoreService);
  static readonly className: string = 'UnauthorizedApiRequestInterceptor';

  static readonly retryDelay: number = 1000; // in ms
  static readonly retryLimit: number = 3;

  intercept(
    request: RequestConfig<any>,
    next: RequestHandler,
  ): Observable<ApiResponse<any>> {
    if (!this.apiService.isApiRequest(request) || request.bypassJwt) {
      return next.handle(request);
    }

    const handleRequest$: Observable<ApiResponse<any>> =
      this.tokenStore.tokenAfterRefresh$.pipe(
        take(1),
        switchMap(
          (token): Observable<ApiResponse<any>> =>
            next.handle(JwtInterceptor.requestWithToken(request, token)).pipe(
              catchError((error) =>
                throwError(
                  (): ErrorWithToken => ({
                    error,
                    token,
                  }),
                ),
              ),
            ),
        ),
        retryOnError<ApiResponse<any>>({
          predicate: (data: ErrorWithToken | any) => {
            const isAuthError =
              data?.error instanceof ApiErrorResponse &&
              data.error.status === 401;

            return isAuthError;
          },
          waitTime: UnauthorizedApiRequestInterceptor.retryDelay,
          maxWaitTime: UnauthorizedApiRequestInterceptor.retryDelay,
          limit: UnauthorizedApiRequestInterceptor.retryLimit,
          retryMode: RetryMode.Custom,
          retry: (error: any) =>
            of(undefined).pipe(
              tap(() => this.tokenStore.reportInvalidToken(error.token)),
              delay(UnauthorizedApiRequestInterceptor.retryDelay),
              switchMap(() => this.tokenStore.forceLoadToken()),
              catchError(() => of(undefined)),
            ),
        }),
        catchError((e) =>
          ObjectUtils.isTypeBasedOnProperty<ErrorWithToken>(e, 'token')
            ? throwError(() => e.error)
            : throwError(() => e),
        ),
      );

    return handleRequest$;
  }
}
