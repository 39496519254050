import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';

import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WakeupService implements OnDestroy {
  private worker: Worker =
    // options cannot be spread to multiple lines, as it's wrongly compiled to: { , } as `type` is removed during compilation
    // prettier-ignore
    typeof Worker === 'undefined'
      ? undefined
      : new Worker(new URL('../../util/wakeup/wakeup-detection-worker.worker.ts', import.meta.url), { type: 'module' });

  private wakeupSubject = new Subject<void>();

  wakeup$: Observable<void> = this.wakeupSubject.asObservable();

  constructor() {
    if (this.worker) {
      this.worker.onmessage = () => {
        this.wakeupSubject.next();
      };
    }
  }

  ngOnDestroy(): void {
    if (this.worker) {
      this.worker.terminate();
    }
  }
}
