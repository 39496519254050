import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';

import type { ImageTextItem } from '@gv/constant';
import { AnnotatedImage } from '@gv/ui/utils';
import { isString } from '@gv/utils';
import { TestIdModule } from '@gv/debug';

import { DialogComponent } from '../dialog.component';
import { SimpleDialogModule } from '../variant';

export interface InfoDialogDataModel {
  readonly images?: HelpImageDataModel[];

  readonly text: string;

  readonly title: string;
}

export interface HelpImageDataModel {
  readonly id: string;

  readonly path: string | { link: string; annotations: ImageTextItem[] };

  readonly title: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-af-info-dialog',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    MatDialogModule,
    MatTabsModule,
    AnnotatedImage,
    SimpleDialogModule,
    TestIdModule,
  ],
  template: `
    <gv-simple-dialog [text]="data.title">
      <ng-container *gvDialogContent>
        <div [innerHtml]="data.text"></div>

        @if (data.images) {
          @if (data.images.length > 1) {
            <mat-tab-group [color]="'primary'">
              @for (img of data.images; track img.id) {
                <mat-tab [label]="img.title">
                  <div class="mat-elevation-z4">
                    <ng-container
                      *ngTemplateOutlet="imageTpl; context: { img: img }"
                    />
                  </div>
                </mat-tab>
              }
            </mat-tab-group>
          }
          @if (data.images.length === 1) {
            <div>
              <ng-container
                *ngTemplateOutlet="imageTpl; context: { img: data.images[0] }"
              />
            </div>
          }
        }
      </ng-container>

      <button
        *gvDialogAtions
        type="button"
        class="btn btn-dialog btn-green"
        [mat-dialog-close]="true"
        i18n="@@info-dialog.got-it"
        >Got it</button
      >
    </gv-simple-dialog>

    <ng-template #imageTpl let-img="img">
      @if (isAnnotatedImage(img.path)) {
        <gv-annotated-image [annotations]="img.path.annotations">
          <img [src]="img.path.link" [alt]="img.title" />
        </gv-annotated-image>
      } @else {
        <img [src]="img.path" [alt]="img.title" />
      }
    </ng-template>
  `,
})
export class InfoDialogComponent extends DialogComponent<
  InfoDialogDataModel,
  boolean
> {
  isAnnotatedImage(
    image: HelpImageDataModel['path'],
  ): image is { link: string; annotations: ImageTextItem[] } {
    return !isString(image);
  }
}
