import type { Store } from '@gv/state';
import { StoreInjectionToken } from '@gv/state';

import type { cookieLawFeatureKey } from '../../reducer/cookie-law.reducer';
import type { CookieLawState } from '../cookie-law.state';

export interface CookieLawFeatureState {
  [cookieLawFeatureKey]: CookieLawState;
}

export const COOKIE_LAW_FEATURE_STATE = new StoreInjectionToken<
  Store<CookieLawFeatureState>
>('cookieLawFeatureState');
