import { Injectable, inject } from '@angular/core';

import type { ApiResponse } from '@gv/api';
import type { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ApiService } from '../../../api/api.service';
import { UserService } from '../../../application/user/user.service';
import type { RequestConfig } from '../http';
import type { RequestHandler, RequestInterceptor } from '../interceptor';

@Injectable({
  providedIn: 'root',
})
export class UntilLogoutInterceptor implements RequestInterceptor {
  private apiService = inject(ApiService);
  private userService = inject(UserService);

  intercept(
    request: RequestConfig<any>,
    next: RequestHandler,
  ): Observable<ApiResponse<any>> {
    if (!this.apiService.isApiRequest(request)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(takeUntil(this.userService.loggedOut$));
  }
}
