import { Injectable, inject, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';

import { ElectronRefService } from '@gv/desktop/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CookieService } from 'ngx-cookie-service';
import type { BehaviorSubject } from 'rxjs';
import { firstValueFrom, filter } from 'rxjs';
import { CrossTabChannelService } from '@gv/crosstab';

import { logger } from '../../../logger';
import { APP_CONFIG } from '../../entity/token/app.config';

export const LANG_REDIRECT_IN_PROGRESS = new InjectionToken<
  BehaviorSubject<boolean>
>('langRedirectInProgress');

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  private router = inject(Router);
  private cookieService = inject(CookieService);
  private appConfig = inject(APP_CONFIG);
  private channelService = inject(CrossTabChannelService);
  private redirectInProgress = inject(LANG_REDIRECT_IN_PROGRESS);
  private electronRef = inject(ElectronRefService, { optional: true });

  async rerouteToLanguage(
    originalLang: string,
    newLang: string,
    currentUrl?: string,
    force = false,
  ): Promise<URL | void> {
    if (this.redirectInProgress.getValue() && !force) {
      await firstValueFrom(this.redirectInProgress.pipe(filter((f) => !f)));
    }

    this.redirectInProgress.next(true);
    if (this.electronRef) {
      return this.electronRef.api.app.setLocaleAndRestart(newLang);
    }
    try {
      currentUrl = !currentUrl ? this.router.url : currentUrl;

      const newBase = document.baseURI.replace(
        `${originalLang}/`,
        `${newLang}/`,
      );

      const url = new URL(
        currentUrl.startsWith('/') ? currentUrl.slice(1) : currentUrl,
        newBase,
      );
      url.searchParams.set('ngsw-bypass', 'true');
      url.searchParams.set('cache-bypass', Math.random().toString());

      if (this.newLangCrossTabNotOpen(newLang)) {
        const registeredServiceWorkers =
          await navigator.serviceWorker.getRegistrations();
        const newLangWorker = registeredServiceWorkers.find((worker) => {
          const parts = worker.scope.split('/').filter((part) => !!part);
          return parts[parts.length - 1] === newLang;
        });
        if (newLangWorker) {
          await newLangWorker
            .unregister()
            .catch((error) =>
              logger.error({ error }, 'Failed to unregister service worker'),
            );
        }
      }

      const date = new Date();
      date.setTime(date.getTime() + 10 * 365 * 60 * 60 * 1000);

      this.cookieService.set(
        this.appConfig.cookies.language,
        newLang,
        date,
        '/',
        `${window.location.hostname.split('.').slice(-2).join('.')}`,
      );
      document.location.href = url.href;
      return new Promise((resolve) => resolve(url));
    } catch (e) {
      logger.error({ error: e }, 'Failed to redirect to new language');
      this.redirectInProgress.next(false);
    }
  }

  private newLangCrossTabNotOpen(expectedLang: string): boolean {
    const activeInstances = this.channelService.activeInstances;
    if (activeInstances.length <= 1) {
      return true;
    }

    const instanceWithExpectedWorker = activeInstances.find(
      (instance) => instance.lang === expectedLang,
    );

    if (instanceWithExpectedWorker) {
      return false;
    }

    return true;
  }
}
