const basename = '[Devices]';

import { createDialogBaseActions, createFlowBaseActions } from '@gv/state';
import { props } from '@ngrx/store';

export const reportErrorDialog = createDialogBaseActions(
  basename,
  'ReportError',
);

export const reportErrorFlow = createFlowBaseActions('ReportError', 'send', {
  initProps: props<{
    data: {
      uuid: string;
    };
  }>(),
});
