import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { MaintenancePageComponent } from '../../../component/page/maintenance-page/maintenance-page.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule.forChild([]),
    MaintenancePageComponent,
  ],
  exports: [MaintenancePageComponent],
})
export class MaintenanceModule {}
