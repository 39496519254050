import type { ComponentType } from '@angular/cdk/portal';
import type { NgModuleRef } from '@angular/core';
import {
  ChangeDetectorRef,
  inject,
  Injectable,
  Injector,
  NgZone,
} from '@angular/core';
import type { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';

import type { Dialog } from '@gv/utils';
import { forkJoinConcurrent, loadModule } from '@gv/utils';
import type { Observable } from 'rxjs';
import { of, defer, from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ModuleLoaderService {
  private injector = inject(Injector);
  openDialog<
    M,
    T,
    R,
    O = T extends Dialog<infer OpenData, unknown> ? OpenData : never,
  >(
    componentOrTemplateRef: () => Promise<ComponentType<T>>,
    config: MatDialogConfig<O>,
    m: () => Promise<M>,
    parentInjector?: Injector,
  ): Observable<MatDialogRef<T, R>> {
    return forkJoinConcurrent<
      readonly [
        Observable<NgModuleRef<M> | undefined>,
        Observable<ComponentType<T>>,
      ]
    >(
      [
        !m
          ? of(undefined)
          : loadModule(m, {
              injector:
                parentInjector ||
                config.viewContainerRef?.injector ||
                this.injector,
            }),
        defer(() => from(componentOrTemplateRef())),
      ],
      1,
    ).pipe(
      map(([module, component]) => {
        // optional, we need this when viewContainerRef is used... to trigger detection from container root
        const injector = module?.injector || this.injector;
        const changeDetector = injector.get(ChangeDetectorRef, null);

        const matDialog = injector.get(MatDialog);
        const ngZone = injector.get(NgZone);

        const dialog: MatDialogRef<T, R> = ngZone.run(() =>
          matDialog.open(component, config),
        );

        if (changeDetector) {
          changeDetector.markForCheck();
        }

        return dialog;
      }),
    );
  }
}
