import type { AfterViewInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import type { EditUser } from '@gv/api';
import { SupportedLanguage, ApiErrorResponse } from '@gv/api';
import { StoreInject } from '@gv/state';
import { SnackBarService } from '@gv/ui/toaster';
import type { Observable } from 'rxjs';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  BasicDialogButtonComponent,
  DialogComponent,
  SimpleDialogModule,
} from '@gv/ui/dialog';

import { APP_CONFIG } from '../../../entity/token/app.config';
import { ApiService } from '../../../service/api/api.service';
import { UserService } from '../../../service/application/user/user.service';
import { LocalizationService } from '../../../service/helper/localization.service';
import { APP_STATE } from '../../../store/state/app-state';

@Component({
  standalone: true,
  imports: [SimpleDialogModule, MatDialogModule, BasicDialogButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-language-mismatch-dialog',
  template: `
    <gv-simple-dialog
      text="Your Language Preference"
      i18n-text="@@language-mismatch-dialog.header"
    >
      <ng-container
        *gvDialogContent
        i18n="@@language-mismatch-dialog.logged-note"
      >
        You are accessing the application in {{ urlLanguage?.title }} but your
        language preferences are set to {{ cookieLanguage?.title }}. Would you
        like to proceed with {{ cookieLanguage?.title }} or switch to
        {{ urlLanguage?.title }}?
      </ng-container>

      <ng-container *gvDialogActions>
        <gv-basic-dialog-button (btnClick)="rerouteToCorrectUrl()">
          {{ buttonLanguageMapping[cookieLanguage.shortcutApi] }}
        </gv-basic-dialog-button>
        <gv-basic-dialog-button
          color="primary"
          [dialogClose]="true"
          (btnClick)="changeLanguagePreferences()"
          i18n="@@language-mismatch-dialog.button-change"
        >
          Change language to {{ urlLanguage?.title }}
        </gv-basic-dialog-button>
      </ng-container>
    </gv-simple-dialog>
  `,
})
export class LanguageMismatchDialogComponent
  extends DialogComponent<{ urlPrefix: string; cookiePrefix: string }, boolean>
  implements AfterViewInit
{
  private appConfig = inject(APP_CONFIG);
  private userService = inject(UserService);
  private apiService = inject(ApiService);
  private snackBarService = inject(SnackBarService);
  private store = inject(StoreInject(APP_STATE));
  private localizationService = inject(LocalizationService);
  private snackBarRef: string | undefined;

  protected buttonLanguageMapping: Record<string, string> = {
    [SupportedLanguage.En]: `Continue with English`,
    [SupportedLanguage.Es]: `Continuar con Español`,
    [SupportedLanguage.Pt]: `Continuar com Português`,
  };

  readonly urlLanguage = this.appConfig.languages.find(
    (language) => language.pathPrefix === this.data.urlPrefix,
  );

  readonly cookieLanguage = this.appConfig.languages.find(
    (language) => language.pathPrefix === this.data.cookiePrefix,
  );

  readonly userLoggedIn$: Observable<boolean> = this.userService.user$.pipe(
    map((user) => !!user),
  );

  ngAfterViewInit(): void {
    this.userLoggedIn$.subscribe();
  }

  rerouteToCorrectUrl() {
    return this.localizationService.rerouteToLanguage(
      this.urlLanguage.pathPrefix,
      this.cookieLanguage.pathPrefix,
    );
  }

  async changeLanguagePreferences(): Promise<boolean | URL | void> {
    if (this.snackBarRef) {
      this.snackBarService.dismiss(this.snackBarRef);
      this.snackBarRef = undefined;
    }

    const userLoggedIn = await firstValueFrom(this.userLoggedIn$);
    if (!userLoggedIn) {
      return this.localizationService.rerouteToLanguage(
        this.cookieLanguage.pathPrefix,
        this.urlLanguage.pathPrefix,
      );
    }

    return new Promise<boolean>((resolve) => {
      const userUuid = this.userService.user.uuid;

      const updateUserDataModel: EditUser = {
        language: this.urlLanguage.shortcutApi,
      };

      this.apiService.api
        .updateUserDetail(userUuid, updateUserDataModel)
        .subscribe({
          next: ({ data: user, dtSent }) => {
            this.userService.setUser({ ...user, uuid: userUuid, dtSent }, true);
            this.userService.setLanguageCookie(user?.language);
            this.snackBarRef = this.snackBarService.open(
              $localize`:@@language-mismatch-dialog.language-changed:Language successfully changed`,
              undefined,
              {
                duration:
                  this.appConfig.userSettings.userProfile.successMessage
                    .duration,
              },
            );
            resolve(true);
          },
          error: (response: any) => {
            let message = $localize`:@@language-mismatch-dialog.language-update-failed:Unable to change language. Please try it again.`;
            if (
              response instanceof ApiErrorResponse &&
              response.status === 404
            ) {
              message = $localize`:@@language-mismatch-dialog.account-not-found:Your account was not found. Please contact us via live chat for help.`;
            }
            this.snackBarRef = this.snackBarService.open(message, undefined, {
              duration:
                this.appConfig.userSettings.userProfile.errorMessage.duration,
            });
            resolve(false);
          },
        });
    });
  }
}
