import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgClass, NgStyle } from '@angular/common';
import type { AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  NgZone,
  ViewChild,
  HostListener,
  DestroyRef,
  booleanAttribute,
} from '@angular/core';
import {
  TooltipPosition,
  MatTooltip,
  MatTooltipModule,
} from '@angular/material/tooltip';

import {
  enterZone,
  leaveZone,
  generateUuid,
  untilNgDestroyed,
  shallowDistinctUntilChanged,
} from '@gv/utils';
import {
  asyncScheduler,
  BehaviorSubject,
  merge,
  Subject,
  from,
  animationFrameScheduler,
} from 'rxjs';
import {
  concatMap,
  debounceTime,
  distinctUntilChanged,
  map,
  observeOn,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { TruncateService } from './truncated.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-truncated',
  imports: [MatTooltipModule, NgClass, NgStyle],
  standalone: true,
  template: `
    <span
      #textContainer
      [attr.truncate-id]="uuid"
      class="relative flex w-full min-w-0 flex-1 overflow-hidden whitespace-nowrap"
      [matTooltip]="tooltip || text!"
      [matTooltipDisabled]="
        (truncatedText[1] === undefined && !truncateStyle) ||
        (truncateStyle && (!tooltipEnabled || tooltipDisabled))
      "
      i18nNoComputedIgnore-matTooltipClass
      [matTooltipClass]="tooltipClass || 'break-word'"
      [matTooltipPosition]="tooltipPosition"
      (mouseenter)="mouseOver = true"
      (mouseleave)="mouseOver = false"
      [ngClass]="{ 'justify-end': right }"
    >
      @if (truncateStyle) {
        <span
          #textEl
          class="min-w-0 truncate"
          [ngClass]="{
            'hover:underline': underlineOnHover
          }"
          [ngStyle]="
            truncateStyle === 'start'
              ? { direction: 'rtl' }
              : { direction: 'ltr' }
          "
          >{{ text }}</span
        >
      }

      @if (!truncateStyle) {
        <span
          class="absolute inset-0"
          [ngClass]="{
            'hover:underline': underlineOnHover,
            'text-center': center,
            'text-left': !center
          }"
        >
          @if (truncatedText[1] !== undefined) {
            {{ truncatedText[0] }}…{{ truncatedText[1] }}
          } @else {
            {{ truncatedText[0] }}
          }
          <span #suffix><ng-content></ng-content></span
        ></span>
        <div
          class="pointer-events-none invisible relative select-none"
          [ngClass]="{ 'hover:underline': underlineOnHover }"
          >{{ text }}</div
        >
      }
    </span>
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
      }
    `,
  ],
})
export class TruncatedComponent implements OnDestroy, AfterViewInit {
  uuid = generateUuid();
  destroyRef = inject(DestroyRef);
  private changeDetector = inject(ChangeDetectorRef);
  private truncateService = inject(TruncateService);
  private ngZone = inject(NgZone);

  @Input() tooltipPosition: TooltipPosition = 'above';
  @Input() tooltip: string | undefined;
  @Input() tooltipClass: string | undefined;
  @Input() tooltipDisabled = false;

  @ViewChild('textEl', { static: false })
  view: ElementRef<HTMLElement> | undefined;

  @ViewChild('suffix', { static: false })
  suffix: ElementRef<HTMLElement> | undefined;

  @ViewChild('textContainer')
  textContainer: ElementRef<HTMLElement> | undefined;

  @ViewChild('textContainer', { read: MatTooltip })
  matTooltip: MatTooltip | undefined;

  private _right = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get right(): any {
    return this._right;
  }

  @Input()
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  set right(value: any) {
    this._right = coerceBooleanProperty(value);
  }

  private _center = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get center(): any {
    return this._center;
  }

  @Input()
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  set center(value: any) {
    this._center = coerceBooleanProperty(value);
  }

  @Input()
  truncateStyle: 'start' | 'end' | false = false;

  tooltipEnabled = false;

  mouseOver = false;

  private resizeSubject = new Subject<void>();

  truncatedText: readonly [string | undefined, string | undefined] = [
    undefined,
    undefined,
  ];

  @Input()
  preferredEndSize = 0.3; // ratio if <= 1; otherwise in pixels

  @Input({ transform: booleanAttribute })
  underlineOnHover = false;

  private textSubject = new BehaviorSubject<string | undefined>(undefined);

  private text$ = this.textSubject.asObservable();

  @Input() set text(text: string | undefined) {
    text = text?.trim();
    if (this.text !== text) {
      if (this.truncatedText[0] === undefined) {
        this.truncatedText = [text, undefined];
      }
      this.textSubject.next(text);
    }
  }

  get text(): string | undefined {
    return this.textSubject.getValue();
  }

  ngOnDestroy(): void {
    //
  }

  onResize(): void {
    this.resizeSubject.next();
  }

  getText(): string {
    return this.text || '';
  }

  private endTruncation$ = this.resizeSubject.pipe(
    debounceTime(50, leaveZone(this.ngZone, asyncScheduler)),
    observeOn(animationFrameScheduler),
    map(() => {
      if (!this.view) {
        return false;
      }
      return (
        this.view.nativeElement.getBoundingClientRect().width <
        this.view.nativeElement.scrollWidth - 0.5
      );
    }),
    observeOn(enterZone(this.ngZone, asyncScheduler)),
    distinctUntilChanged(),
    tap((tooltipEnabled) => {
      if (this.tooltipEnabled !== tooltipEnabled) {
        this.tooltipEnabled = tooltipEnabled;
        if (tooltipEnabled && this.mouseOver) {
          this.changeDetector.detectChanges();
          this.matTooltip?.show();
        }

        this.changeDetector.markForCheck();
      }
    }),
    untilNgDestroyed(),
  );

  private middleTruncation$ = merge(
    this.text$.pipe(distinctUntilChanged()),
    this.resizeSubject.pipe(
      withLatestFrom(this.text$),
      map(([, v]) => v),
    ),
  ).pipe(
    debounceTime(50),
    concatMap((text) => {
      return from(
        this.truncateService.getTruncatedText(text, {
          preferredEndSize: this.preferredEndSize,
          textContainer: this.textContainer!.nativeElement,
          suffixContainer: this.suffix?.nativeElement,
        }),
      );
    }),
    shallowDistinctUntilChanged(),
    tap((text) => {
      this.truncatedText = text;
      this.changeDetector.markForCheck();
    }),
    untilNgDestroyed(),
  );

  ngAfterViewInit(): void {
    this.truncateService.register(this);

    this.endTruncation$.subscribe();
    this.middleTruncation$.subscribe();
  }

  @HostListener('copy', ['$event'])
  onSearchTrigger(event: ClipboardEvent) {
    if (event.target instanceof Element) {
      event.preventDefault();
      event.clipboardData?.setData('text/plain', this.text || '');
    }
  }
}
