import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Injectable, NgZone, inject } from '@angular/core';

import {
  AnalyticsCategories,
  AnalyticsVaultActions,
  AngularticsActions,
  trackEvent,
} from '@gv/analytics';
import type {
  CreateFileUpload,
  FinishVideoUpload,
  VaultFileModel,
  VaultUploadFileDetailModel,
  VideoPartTag,
} from '@gv/api';
import {
  API,
  ApiErrorResponse,
  ClientLogLevel,
  FileStatus,
  HTTP_SERVICE,
  VaultFileType,
  FileState,
  VaultFileState,
} from '@gv/api';
import { sendLog } from '@gv/client-logger';
import type {
  FFMpegOutput,
  MediaInfoOutput,
  OptimizeOptionsModel,
} from '@gv/desktop/core';
import { ElectronFile, ElectronRefService } from '@gv/desktop/core';
import { ErrorsActions } from '@gv/error';
import {
  NotificationListenerService,
  NotificationsService,
} from '@gv/notification';
import {
  asIndexed,
  createFlowEffect,
  isActionOfType,
  ofActions,
  onFlowFinished,
  StoreInject,
} from '@gv/state';
import {
  BillingActions,
  DetailActions,
  DETAIL_FEATURE_STATE,
  getFreeSpace,
} from '@gv/ui/billing';
import type { SnackbarMessage } from '@gv/ui/toaster';
import {
  HashWorkerService,
  VideoMetadataReaderService,
  VideoUploadHelperService,
  calculateChunkCountFromFileSize,
  calculateChunkSizeFromFileSize,
  FileUtils,
} from '@gv/upload/core';
import type {
  FilePartUploadModel,
  FullFileMetadataModel,
  InitVaultUploadsPropsModel,
  InvalidFileModel,
  InvalidFolderModel,
  LocalFileUploadItemModel,
  S3FileUploadOptionsModel,
  VaultStateFileModel,
} from '@gv/upload/types';
import {
  FileUploadStarted,
  MultipartFileUploadHttpResponse,
  UnsupportedHtmlVideoTypeError,
  UploadFile,
  UploadType,
  VideoFileValidation,
  uploadConfig,
} from '@gv/upload/types';
import {
  delayedConcatMap,
  enterZone,
  FileSizeUnit,
  forkJoinConcurrent,
  generateUuid,
  isUndefinedOrNull,
  leaveZone,
  RequestRetryManagerService,
  RetryMode,
  retryOnNetworkError,
  shallowDistinctUntilKeysChanged,
  simpleSwitchMap,
  toBytes,
  toSentryError,
  waitUntilIdle,
} from '@gv/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import type { Action, MemoizedSelector } from '@ngrx/store';
import { select } from '@ngrx/store';
import { captureException, withScope } from '@sentry/angular';
import { groupBy, isEqual, round } from 'lodash-es';
import { DateTime } from 'luxon';
import type { Observable } from 'rxjs';
import {
  asyncScheduler,
  combineLatest,
  concat,
  defer,
  EMPTY,
  from,
  merge,
  of,
  throwError,
  TimeoutError,
  timer,
} from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  delay,
  delayWhen,
  distinct,
  distinctUntilChanged,
  filter,
  finalize,
  ignoreElements,
  map,
  mergeAll,
  mergeMap,
  observeOn,
  pairwise,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  timeout,
  withLatestFrom,
} from 'rxjs/operators';
import { USER_CONTEXT } from '@gv/user';
import { Syncify } from '@gv/syncify';

import { UploadsActions } from '../action/uploads.actions';
import { fromUploadsState } from '../selectors';
import { UPLOADS_FEATURE_STATE } from '../uploads-feature.state';
import { S3FileUploadService } from '../s3-file-upload-service';
import { VaultDialogActions } from '../action';
import { ElectronUploadsPersistanceService } from './electron-uploads-persistance.service';
import { logger } from '../logger';

const METADATA_WILL_NOT_LOAD = Symbol('METADATA_WILL_NOT_LOAD');

@Injectable({
  providedIn: 'root',
})
export class VaultUploadsEffects {
  private actions$ = inject<Actions>(Actions);
  private videoUploadHelperService = inject(VideoUploadHelperService);
  private userContext = inject(USER_CONTEXT);
  private syncify = inject(Syncify);
  private store = inject(
    StoreInject(UPLOADS_FEATURE_STATE, DETAIL_FEATURE_STATE),
  );
  private requestsRetryManager = inject(RequestRetryManagerService);
  private videoMetadataReader = inject(VideoMetadataReaderService);
  private hashWorker = inject(HashWorkerService);
  private api = inject(API);
  private httpService = inject(HTTP_SERVICE);
  private notificationsService = inject(NotificationsService);
  private notificationListener = inject(NotificationListenerService);
  private s3FileUploadService = inject(S3FileUploadService);
  private electronUploadsPersistanceService = inject(
    ElectronUploadsPersistanceService,
    { optional: true },
  );
  private electronRef = inject(ElectronRefService, { optional: true });
  private ngZone = inject(NgZone);
  static readonly actionTimeout = 30000;

  encodeTrigger$ = createEffect(
    this.createTrigger(
      fromUploadsState.vault.triggers.getFileForEncodeTrigger,
      (file) =>
        UploadsActions.vault.encode.init({
          uploadGroupUuid: file.uploadGroupUuid,
          uuid: file.uuid,
        }),
    ),
  );
  loadMetadataTrigger$ = createEffect(
    this.createTrigger(
      fromUploadsState.vault.triggers.getFileForMetadataLoadTrigger,
      (file) =>
        UploadsActions.vault.basicMetadata.init({
          uploadGroupUuid: file.uploadGroupUuid,
          uuid: file.uuid,
        }),
    ),
  );
  loadFullMetadataTrigger$ = createEffect(
    this.createTrigger(
      fromUploadsState.vault.triggers.getFileForFullMetadataLoadTrigger,
      (file) =>
        UploadsActions.vault.fullMetadata.init({
          uploadGroupUuid: file.uploadGroupUuid,
          uuid: file.uuid,
        }),
    ),
  );
  uploadTrigger$ = createEffect(
    this.createTrigger(
      fromUploadsState.vault.triggers.getFileForUploadTrigger,
      (file) =>
        UploadsActions.vault.presign.init({
          uploadGroupUuid: file.uploadGroupUuid,
          uuid: file.uuid,
        }),
    ),
  );

  encode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadsActions.vault.encode.init),
      delayedConcatMap((action) => [
        this.store.select(
          fromUploadsState.vault.getFile({
            uploadGroupUuid: action.uploadGroupUuid,
            uuid: action.uuid,
          }),
        ),
      ]),
      concatMap(
        (data): Observable<readonly [typeof data, OptimizeOptionsModel]> =>
          combineLatest([
            of(data),
            this.electronRef
              ? from(this.electronRef.api.app.getVideoOptimizeOptions())
              : of(undefined),
          ]),
      ),
      filter(([[, vaultFile]]) => !!vaultFile),
      delayWhen(([[, vaultFile]]) =>
        vaultFile.retryDelay ? timer(vaultFile.retryDelay) : of(undefined),
      ),
      concatMap(
        ([[action, vaultFile], optimizeOptions]): Observable<Action> => {
          if (!vaultFile) {
            return EMPTY;
          }

          const optimizeEnabled =
            optimizeOptions && optimizeOptions.enabledAndReady;

          if (
            !(vaultFile.data.file instanceof UploadFile) ||
            !optimizeEnabled
          ) {
            return of(
              UploadsActions.vault.encode.optOut({
                uuid: action.uuid,
                uploadGroupUuid: action.uploadGroupUuid,
              }),
            );
          }

          const { uploadGroupUuid, uuid } = action;

          return concat(
            of(
              UploadsActions.vault.encode.started({
                uploadGroupUuid,
                uuid,
                presetPath: optimizeOptions.presetFile,
              }),
            ),
            this.electronRef.api.file
              .handBrake({
                path: vaultFile.data.file.path,
                preset: 'STANDARD',
              })
              .pipe(
                mergeMap((message) => {
                  if (message.type === 'progress') {
                    return of(
                      UploadsActions.setProgress({
                        uuid,
                        progress: {
                          progress: message.progress,
                          type: 'encode',
                        },
                      }),
                    );
                  } else {
                    return defer(() => {
                      const file = new ElectronFile(
                        message.path,
                        this.electronRef,
                      );
                      return from(file.init().then(() => file));
                    }).pipe(
                      map((file) =>
                        UploadsActions.vault.encode.completed({
                          uuid: action.uuid,
                          uploadGroupUuid: action.uploadGroupUuid,
                          data: {
                            file,
                            size: file.size,
                          },
                        }),
                      ),
                    );
                  }
                }),
              ),
          ).pipe(
            catchError((error) => {
              return of(
                ErrorsActions.generic({
                  action,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  error,
                  logToSentry: true,
                  sentryOptions: {
                    tags: {
                      subMessage: `Failed to encode file.`,
                    },
                  },
                }),
                UploadsActions.vault.error({
                  uploadGroupUuid,
                  uuid,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  error,
                  reason:
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    error.code === 4
                      ? $localize`:@@vault-uploads.insufficient-free-disk-space:Insufficient free disk space`
                      : $localize`:@@vault-uploads.failed-to-encode-file:Failed to encode file.`,
                }),
              );
            }),
            takeUntil(this.uploadCancelled(uploadGroupUuid, uuid)),
          );
        },
      ),
    ),
  );

  loadMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadsActions.vault.basicMetadata.init),
      mergeMap((action): Observable<typeof action> => {
        if (this.electronRef) {
          // skip for electron; we will load all from mediainfo
          return this.store
            .select(
              fromUploadsState.vault.getFile({
                uploadGroupUuid: action.uploadGroupUuid,
                uuid: action.uuid,
              }),
            )
            .pipe(
              take(1),
              concatMap((vaultFile) => {
                const file = vaultFile.encoded?.file || vaultFile.data.file;

                return file instanceof UploadFile
                  ? defer(() => from(file.init()))
                  : of(undefined);
              }),
              tap(() => {
                this.store.dispatch(
                  UploadsActions.vault.basicMetadata.completed({
                    uploadGroupUuid: action.uploadGroupUuid,
                    uuid: action.uuid,
                    data: { duration: undefined, resolution: undefined },
                  }),
                );
              }),
              catchError((error) => {
                this.store.dispatch(
                  UploadsActions.vault.error({
                    uploadGroupUuid: action.uploadGroupUuid,
                    uuid: action.uuid,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    error,
                    reason: $localize`:@@vault-uploads.failed-to-obtain-metadata:Failed to obtain file metadata.`,
                  }),
                );
                return of(undefined);
              }),
              ignoreElements(),
              takeUntil(
                this.uploadCancelled(action.uploadGroupUuid, action.uuid),
              ),
            );
        }

        return of(action);
      }),
      waitUntilIdle(
        this.store
          .select(
            fromUploadsState.vault.triggers.shouldWeForceMetadataLoadTrigger,
          )
          .pipe(filter((v) => v)),
        VaultUploadsEffects.actionTimeout,
      ),
      delayedConcatMap((action) => [
        this.store.select(
          fromUploadsState.vault.getFile({
            uploadGroupUuid: action.uploadGroupUuid,
            uuid: action.uuid,
          }),
        ),
      ]),
      filter(([, vaultFile]) => !!vaultFile),
      delayWhen(([, vaultFile]) =>
        vaultFile.retryDelay ? timer(vaultFile.retryDelay) : of(undefined),
      ),
      concatMap(([action, vaultFile]): Observable<Action> => {
        const { uploadGroupUuid, uuid } = action;

        const file = vaultFile.encoded?.file || vaultFile.data.file;

        return (
          file instanceof UploadFile
            ? defer(() => from(file.init()))
            : of(undefined)
        ).pipe(
          switchMap(() =>
            from(this.videoMetadataReader.getVideoMetadata(file)),
          ),
          timeout(VaultUploadsEffects.actionTimeout),
          catchError((error) =>
            error instanceof UnsupportedHtmlVideoTypeError ||
            error instanceof TimeoutError
              ? of({ duration: undefined, resolution: undefined })
              : // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                throwError(() => error),
          ),
          map((data) =>
            UploadsActions.vault.basicMetadata.completed({
              uploadGroupUuid,
              uuid,
              data: {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                duration:
                  data.duration instanceof Error ? undefined : data.duration,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                resolution:
                  data.resolution instanceof Error
                    ? undefined
                    : data.resolution,
              },
            }),
          ),
          catchError((error) => {
            return of(
              ErrorsActions.generic({
                action,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                error,
                logToSentry: true,
                sentryOptions: {
                  tags: {
                    subMessage: 'Failed to obtain file metadata.',
                  },
                },
              }),
              UploadsActions.vault.error({
                uploadGroupUuid,
                uuid,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                error,
                reason: $localize`:@@vault-uploads.failed-to-obtain-metadata:Failed to obtain file metadata.`,
              }),
            );
          }),
          takeUntil(this.uploadCancelled(uploadGroupUuid, uuid)),
        );
      }),
    ),
  );

  loadFullMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadsActions.vault.fullMetadata.init),
      waitUntilIdle(
        this.store
          .select(
            fromUploadsState.vault.triggers
              .shouldWeForceFullMetadataLoadTrigger,
          )
          .pipe(filter((v) => v)),
        VaultUploadsEffects.actionTimeout,
      ),
      delayedConcatMap((action) => [
        this.store.select(
          fromUploadsState.vault.getFile({
            uploadGroupUuid: action.uploadGroupUuid,
            uuid: action.uuid,
          }),
        ),
        this.store.select(
          fromUploadsState.vault.triggers.shouldWeForceFullMetadataLoadTrigger,
        ),
      ]),
      filter(([, vaultFile]) => !!vaultFile),
      delayWhen(([, vaultFile]) =>
        vaultFile.retryDelay ? timer(vaultFile.retryDelay) : of(undefined),
      ),
      concatMap(([action, vaultFile]): Observable<Action> => {
        if (!vaultFile) {
          return EMPTY;
        }

        const { uploadGroupUuid, uuid } = action;

        const file = vaultFile.encoded?.file || vaultFile.data.file;

        const hashCalculationObs = this.hashWorker
          .calculateHash(file, calculateChunkSizeFromFileSize(file.size))
          .pipe(
            timeout(VaultUploadsEffects.actionTimeout * 4),
            catchError((error) => {
              this.store.dispatch(
                ErrorsActions.generic({
                  action,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  error,
                  logToSentry: true,
                  sentryOptions: {
                    tags: {
                      subMessage: 'Failed to obtain file hash',
                    },
                  },
                }),
              );

              this.store.dispatch(
                sendLog({
                  datetime: DateTime.now().toISO(),
                  level: ClientLogLevel.Info,
                  message: `File metadata load failed for hash worker (file: ${
                    vaultFile.serverData.fileUuid
                  }) (error:${JSON.stringify(error)}).`,
                }),
              );

              return of<string>(undefined);
            }),
          );

        const ffmpegWorkerObs = of(METADATA_WILL_NOT_LOAD);
        const mediaInfoWorkerObs = of(METADATA_WILL_NOT_LOAD);

        return forkJoinConcurrent(
          [hashCalculationObs, ffmpegWorkerObs, mediaInfoWorkerObs] as const,
          2,
        ).pipe(
          mergeMap(([hash, ffmpegInfo, mediaInfo]) => {
            const ffmpegInfoLog: string =
              ffmpegInfo === METADATA_WILL_NOT_LOAD
                ? 'not triggered'
                : JSON.stringify(ffmpegInfo);
            const mediaInfoLog =
              mediaInfo === METADATA_WILL_NOT_LOAD
                ? 'not triggered'
                : JSON.stringify(mediaInfo);

            const fullMetadata = this.extractMetadata(ffmpegInfo, mediaInfo);

            let obs: Observable<any> = of(undefined);
            if (
              (ffmpegInfo !== METADATA_WILL_NOT_LOAD ||
                mediaInfo !== METADATA_WILL_NOT_LOAD) &&
              Object.values(fullMetadata || {}).some(
                (f) => !isUndefinedOrNull(f),
              )
            ) {
              const { maxWaitTime, waitTime, limit } = uploadConfig.apiRetry;

              obs = this.api
                .editFile(vaultFile.serverData.fileUuid, {
                  duration: fullMetadata.duration,
                  hash: hash,
                  mediaType: undefined,
                  videoMetadata: fullMetadata,
                })
                .pipe(
                  retryOnNetworkError(waitTime, maxWaitTime),
                  this.requestsRetryManager.createRetryOnError(
                    (error) =>
                      error instanceof ApiErrorResponse && error.status === 500,
                    waitTime,
                    maxWaitTime,
                    limit,
                    RetryMode.NumberOfRetries,
                    this.userContext.userUuid$,
                  ),
                  this.requestsRetryManager.createRetryOnError(
                    (error) =>
                      error instanceof ApiErrorResponse && error.status > 500,
                    waitTime,
                    maxWaitTime,
                    5,
                    RetryMode.NumberOfRetries,
                    this.userContext.userUuid$,
                  ),
                  map(() => undefined),
                  catchError((error) => {
                    if (error instanceof ApiErrorResponse) {
                      this.store.dispatch(
                        ErrorsActions.generic({
                          action,
                          error,
                          logToSentry: true,
                          sentryOptions: {
                            tags: {
                              subMessage: 'File is likely invalid',
                            },
                            extras: {
                              fullMetadata,
                            },
                          },
                        }),
                      );
                      this.store.dispatch(
                        sendLog({
                          datetime: DateTime.now().toISO(),
                          level: ClientLogLevel.Info,
                          message: `File is likely invalid (file: ${
                            vaultFile.serverData.fileUuid
                          }) (metadata: ${JSON.stringify(
                            fullMetadata,
                          )}). (error: ${JSON.stringify(error)})`,
                        }),
                      );
                    }

                    return of(undefined);
                  }),
                );
            }

            return obs.pipe(
              switchMap(() =>
                ofActions(
                  UploadsActions.vault.fullMetadata.completed({
                    uploadGroupUuid,
                    uuid,
                    data: {
                      hash,
                      ...fullMetadata,
                    },
                  }),
                  sendLog({
                    datetime: DateTime.now().toISO(),
                    level: ClientLogLevel.Info,
                    message: `File metadata loaded (file: ${vaultFile.serverData.fileUuid}) (ffmpeg:${ffmpegInfoLog}) (mediaInfo:${mediaInfoLog}).`,
                  }),
                ),
              ),
            );
          }),
          catchError((error) => {
            return of(
              ErrorsActions.generic({
                action,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                error,
                logToSentry: true,
                sentryOptions: {
                  tags: {
                    subMessage: 'Failed to obtain full file metadata.',
                  },
                },
              }),
              UploadsActions.vault.fullMetadata.completed({
                uploadGroupUuid,
                uuid,
                data: {
                  hash: undefined,
                  duration: undefined,
                  fps: undefined,
                  frameCount: undefined,
                  height: undefined,
                  width: undefined,
                },
              }),
            );
          }),
          takeUntil(this.uploadCancelled(uploadGroupUuid, uuid)),
        );
      }),
    ),
  );

  createVaultUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadsActions.vault.create.init),
      mergeMap((action): Observable<Action> => {
        const { maxWaitTime, waitTime, limit } = uploadConfig.apiRetry;

        return this.api.createVaultUpload(action.uploadProps).pipe(
          retryOnNetworkError(waitTime, maxWaitTime),
          this.requestsRetryManager.createRetryOnError(
            (error) =>
              error instanceof ApiErrorResponse && error.status === 500,
            waitTime,
            maxWaitTime,
            limit,
            RetryMode.NumberOfRetries,
            this.userContext.userUuid$,
          ),
          this.requestsRetryManager.createRetryOnError(
            (error) => error instanceof ApiErrorResponse && error.status > 500,
            waitTime,
            maxWaitTime,
            5,
            RetryMode.NumberOfRetries,
            this.userContext.userUuid$,
          ),
          mergeMap(
            (response): Observable<Action> =>
              concat(
                of(
                  UploadsActions.vault.create.completed({
                    uploadGroupUuid: action.uploadGroupId,
                    data: response.data,
                  }),
                  DetailActions.detail.refresh({ debounce: true }),
                  BillingActions.subscription.refresh({ debounce: true }),
                ),
                EMPTY.pipe(
                  finalize(() => {
                    this.syncify.invalidate({
                      type: 'vault',
                      files: response.data.files.map((m, index) =>
                        buildVaultFileModel(m, action.uploadProps.files[index]),
                      ),
                      prefix: response.data.directory.prefix,
                      tree: true,
                      blockingUpdateNeeded: true,
                      dtSent: response.dtSent,
                      action: 'new',
                    });
                    this.syncify.invalidate({
                      type: 'projects',
                      dtSent: response.dtSent,
                    });
                    this.syncify.invalidate({
                      type: 'project',
                      dtSent: response.dtSent,
                    });
                    this.syncify.invalidate({
                      type: 'location',
                      dtSent: response.dtSent,
                    });
                  }),
                ),
              ),
          ),
          catchError((error) => {
            let snackbarMessage: SnackbarMessage;
            if (error instanceof ApiErrorResponse && error.status === 402) {
              snackbarMessage = {
                message: $localize`:@@vault-uploads.vault-plan-not-active:Vault plan is not active or insufficient for the amount of data.`,
                config: {
                  duration: 5000,
                },
              };
            } else {
              snackbarMessage = {
                message: $localize`:@@vault-uploads.failed-to-prepare-upload:Failed to prepare upload to vault.`,
                config: {
                  duration: 5000,
                },
              };
            }

            return of(
              ErrorsActions.generic({
                action,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                error,
                snackbarMessage,
                logToSentry: true,
                sentryOptions: {
                  tags: {
                    subMessage: 'Failed to create vault upload.',
                  },
                },
              }),
              UploadsActions.vault.create.error({
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                error,
                uploadGroupUuid: action.uploadGroupId,
              }),
            );
          }),
        );
      }),
    ),
  );

  initUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadsActions.vault.init),
      mergeMap((action): Observable<Action> => {
        return of(
          UploadsActions.vault.create.init({
            uploadGroupId: action.uploadGroupUuid,
            uploadProps: {
              directory: action.data.prefix ? action.data.prefix : null,
              presignUrls: false,
              isFolder: action.data.createNewOnConflict,
              files: action.data.files.map((f) => ({
                chunkCount: calculateChunkCountFromFileSize(f.file.size),
                filename: f.file.name,
                size: f.file.size,
                type: VaultFileType.Video,
                hash: undefined,
                dtModified: undefined,
                duration: undefined,
              })),
              project: action.projectUuid,
            },
          }),
        );
      }),
    ),
  );

  uploadToVault$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadsActions.uploadToVault),
      delayedConcatMap(() => [this.store.select(getFreeSpace)]),
      mergeMap(
        ([
          {
            uploadProps,
            projectUuid,
            projectName,
            showSizeWarning,
            vaultAction,
          },
          freeSpace,
        ]): Observable<Action | never> => {
          if (!uploadProps || uploadProps.length === 0) {
            return EMPTY;
          }

          const invalidFolderDialogData = uploadProps.reduce(
            (acc, props) =>
              acc.concat(
                props.invalidFolders.filter((f) => f.files.length > 0),
              ),
            <InvalidFolderModel[]>[],
          );

          const uploads: InitVaultUploadsPropsModel[] = [];

          for (const props of uploadProps) {
            const validFiles: LocalFileUploadItemModel[] = [];
            const invalidFiles: InvalidFileModel[] = [];

            for (const f of props.files) {
              const validation =
                this.videoUploadHelperService.getFileValidationForUpload(
                  f.file,
                  f.relativePath,
                );

              if (validation === VideoFileValidation.Valid) {
                validFiles.push(f);
              } else {
                invalidFiles.push({
                  file: f,
                  validation,
                });
              }
            }

            if (invalidFiles.length) {
              const groups = groupBy(invalidFiles, (f) =>
                FileUtils.getPath(f.file.relativePath, true),
              );

              for (const path of Object.keys(groups)) {
                invalidFolderDialogData.push({
                  files: groups[path],
                  name: FileUtils.getLastFolderName(path),
                  path,
                });
              }
            }

            if (validFiles.length) {
              uploads.push({ ...props, files: validFiles });
            }
          }

          const totalSize = uploads.reduce(
            (size, u) =>
              size + u.files.reduce((folderSize, f) => folderSize + f.size, 0),
            0,
          );

          const freeSpaceInBytes = toBytes(freeSpace, FileSizeUnit.GigaByte);

          const videoCount = uploads.reduce(
            (acc, u) => u.files.length + acc,
            0,
          );

          const tooLarge = freeSpaceInBytes < totalSize;

          trackEvent('vaultFileUpload', { vaultAction });

          return concat(
            ofActions(
              invalidFolderDialogData.length !== 0 &&
                VaultDialogActions.filesCouldNotBeUploadedDialog.open({
                  data: { folders: invalidFolderDialogData },
                }),
              tooLarge &&
                (showSizeWarning || uploads.length > 1) &&
                VaultDialogActions.vaultSizeExceededDialog.open({
                  data: {
                    uploadSize: totalSize,
                    freeSpace: freeSpaceInBytes,
                    uploadStopped: uploads.length > 1,
                  },
                }),
            ),
            uploads.length === 0 || (tooLarge && uploads.length > 1)
              ? EMPTY
              : of(
                  ...uploads.map(
                    (data): Action =>
                      UploadsActions.vault.init({
                        data,
                        projectUuid,
                        projectName,
                        uploadGroupUuid: generateUuid(),
                      }),
                  ),
                  AngularticsActions.event({
                    category: AnalyticsCategories.Vault,
                    action: AnalyticsVaultActions.FileUpload,
                    options: {
                      value: videoCount,
                      size: videoCount,
                    },
                  }),
                ),
          );
        },
      ),
    ),
  );

  i = 0;
  presignFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadsActions.vault.presign.init),
      delayedConcatMap((action) => [
        this.store.select(
          fromUploadsState.vault.getGroup({
            uploadGroupUuid: action.uploadGroupUuid,
          }),
        ),
        this.store.select(
          fromUploadsState.vault.getFile({
            uploadGroupUuid: action.uploadGroupUuid,
            uuid: action.uuid,
          }),
        ),
      ]),
      delayWhen(([, , vaultFile]) =>
        vaultFile.retryDelay ? timer(vaultFile.retryDelay) : of(undefined),
      ),
      mergeMap(([action, group, vaultFile]) => {
        if (!vaultFile || !group) {
          return EMPTY;
        }

        const uploadId = group.serverData.uploadId;

        const { maxWaitTime, waitTime, limit } = uploadConfig.apiRetry;

        const file = vaultFile.encoded?.file || vaultFile.data.file;

        if (
          vaultFile.serverData.uploadId &&
          vaultFile.serverData.hash &&
          vaultFile.serverData.filename === file.name &&
          vaultFile.fullMetadata.hash &&
          vaultFile.fullMetadata.hash !== vaultFile.serverData.hash
        ) {
          // we need to verify that the file did not changed in the meantime
          // otherwise we report it as error to the user
          return of(
            ErrorsActions.generic({
              action,
              error: new Error('Hash mismatch'),
              logToSentry: true,
              sentryOptions: {
                extras: {
                  userHash: vaultFile.fullMetadata.hash,
                  serverHash: vaultFile.serverData.hash,
                },
                tags: {
                  subMessage: 'Failed to presign file upload.',
                },
              },
            }),
            UploadsActions.vault.error({
              uploadGroupUuid: vaultFile.uploadGroupUuid,
              uuid: action.uuid,
              error: new Error('Hash mismatch'),
              reason: $localize`:@@vault-uploads.hash-mismatch:File changed during the upload. Please revert the changes or upload it again.`,
            }),
          );
        }

        return this.api
          .presignUploadingFiles(uploadId, {
            files: [
              {
                chunkCount: calculateChunkCountFromFileSize(file.size),
                uuid: vaultFile.serverData.fileUuid,
                duration: vaultFile.metadata.duration,
                hash: vaultFile.fullMetadata.hash,
                dtModified: DateTime.fromJSDate(
                  new Date(file.lastModified),
                ).toISO(),
                uploadId: vaultFile.serverData.uploadId
                  ? vaultFile.serverData.uploadId
                  : undefined,
                optimized: !!vaultFile.encoded,
                size: file.size,
              },
            ],
          })
          .pipe(
            retryOnNetworkError(waitTime, maxWaitTime),
            this.requestsRetryManager.createRetryOnError(
              (error) =>
                error instanceof ApiErrorResponse && error.status > 500,
              waitTime,
              maxWaitTime,
              5,
              RetryMode.NumberOfRetries,
              this.userContext.userUuid$,
            ),
            this.requestsRetryManager.createRetryOnError(
              (error) =>
                error instanceof ApiErrorResponse && error.status === 500,
              waitTime,
              maxWaitTime,
              limit,
              RetryMode.NumberOfRetries,
              this.userContext.userUuid$,
            ),
            mergeMap(({ data }) => {
              const [file] = data.files;
              return ofActions(
                UploadsActions.vault.presign.completed({
                  uploadGroupUuid: action.uploadGroupUuid,
                  uuid: action.uuid,
                  data: file,
                  finishUrl: data.finishUrl,
                }),
                file.alreadyUploaded
                  ? UploadsActions.vault.uploadFile.completed({
                      uploadGroupUuid: action.uploadGroupUuid,
                      uuid: action.uuid,
                    })
                  : UploadsActions.vault.uploadFile.init({
                      uploadGroupUuid: action.uploadGroupUuid,
                      uuid: action.uuid,
                    }),

                file.alreadyUploaded &&
                  ((): false => {
                    this.syncify.invalidate({
                      type: 'vault',
                      dtSent: undefined,
                      prefix: group.serverData.directory.prefix,
                      tree: false,
                      files: [file.fileUuid],
                      blockingUpdateNeeded: false,
                      action: 'delete',
                    });
                    this.syncify.invalidate({
                      type: 'project',
                      dtSent: undefined,
                    });
                    this.store.dispatch(
                      DetailActions.detail.refresh({ debounce: true }),
                    );
                    return false;
                  })(),
              );
            }),
            catchError((error) => {
              return of(
                ErrorsActions.generic({
                  action,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  error,
                  logToSentry: true,
                  sentryOptions: {
                    tags: {
                      subMessage: 'Failed to presign file upload.',
                    },
                  },
                }),
                UploadsActions.vault.error({
                  uploadGroupUuid: vaultFile.uploadGroupUuid,
                  uuid: action.uuid,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  error,
                  reason:
                    error instanceof ApiErrorResponse && error.status === 402
                      ? $localize`:@@vault-uploads.vault-plan-not-active:Vault plan is not active or insufficient for the amount of data.`
                      : $localize`:@@vault-uploads.failed-to-prepare:Failed to prepare upload.`,
                }),
              );
            }),
            takeUntil(
              this.uploadCancelled(vaultFile.uploadGroupUuid, action.uuid),
            ),
          );
      }),
    ),
  );

  uploadVaultFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadsActions.vault.uploadFile.init),
      delayedConcatMap((action) => [
        this.store.select(
          fromUploadsState.vault.getGroup({
            uploadGroupUuid: action.uploadGroupUuid,
          }),
        ),
      ]),
      mergeMap(([action, uploadGroup]) => {
        if (!uploadGroup) {
          return EMPTY;
        }

        const vaultFile = uploadGroup.files[action.uuid];
        const file = vaultFile.encoded?.file || vaultFile.data.file;

        const s3FileUploadUploadOptions: S3FileUploadOptionsModel = {
          file,
          presignedUrls: vaultFile.serverData.presignedUrls,
          uploadId: vaultFile.serverData.uploadId,
          fileUuid: vaultFile.serverData.fileUuid,
          existingParts: vaultFile.serverData.existingParts,
        };

        return this.s3FileUploadService
          .multipartUpload(s3FileUploadUploadOptions)
          .pipe(
            mergeMap((httpEvent) => {
              if (httpEvent instanceof FileUploadStarted) {
                return of(
                  UploadsActions.vault.uploadFile.started({
                    uuid: action.uuid,
                    uploadGroupUuid: action.uploadGroupUuid,
                  }),
                );
              }
              if (httpEvent.type === HttpEventType.UploadProgress) {
                return of(
                  UploadsActions.setProgress({
                    uuid: vaultFile.uuid,
                    progress: {
                      progress: (100 * httpEvent.loaded) / httpEvent.total,
                      type: 'upload',
                    },
                  }),
                );
              }

              if (httpEvent?.type === 'completed-chunks') {
                return of(
                  UploadsActions.setCompletedChunks({
                    uploadGroupUuid: uploadGroup.uuid,
                    uuid: vaultFile.uuid,
                    completedChunks: httpEvent.chunks,
                  }),
                );
              }

              if (!(httpEvent instanceof MultipartFileUploadHttpResponse)) {
                return EMPTY;
              }

              const videoPartTags = this.getPartTags(httpEvent.filePartUploads);

              const finishVideoUploadData: FinishVideoUpload = {
                uploadId: vaultFile.serverData.uploadId,
                videoPartTags,
              };

              const userUuid: string = this.userContext.userS()?.uuid;

              const logDetail = JSON.stringify({
                filename: vaultFile.data.file.name,
                uploadId: vaultFile.serverData.uploadId,
                fileUuid: vaultFile.serverData.fileUuid,
              });

              const { maxWaitTime, waitTime, limit } = uploadConfig.apiRetry;

              return this.httpService
                .withConfig(
                  {
                    baseURL: vaultFile.finishUrl,
                  },
                  () =>
                    this.api.finishFileUpload(
                      vaultFile.serverData.fileUuid,
                      finishVideoUploadData,
                    ),
                )
                .pipe(
                  retryOnNetworkError(waitTime, maxWaitTime),
                  this.requestsRetryManager.createRetryOnError(
                    (error) =>
                      error instanceof ApiErrorResponse && error.status > 500,
                    waitTime,
                    maxWaitTime,
                    5,
                    RetryMode.NumberOfRetries,
                    this.userContext.userUuid$,
                  ),
                  this.requestsRetryManager.createRetryOnError(
                    (error) =>
                      error instanceof ApiErrorResponse && error.status === 500,
                    waitTime,
                    maxWaitTime,
                    limit,
                    RetryMode.NumberOfRetries,
                    this.userContext.userUuid$,
                  ),
                  mergeMap(() =>
                    ofActions(
                      vaultFile.encoded &&
                        UploadsActions.vault.encode.cleanupFile({
                          data: vaultFile.encoded,
                        }),
                      sendLog({
                        datetime: DateTime.now().toISO(),
                        level: ClientLogLevel.Info,
                        message: `File upload finished (user:${userUuid}) (content:${logDetail}).`,
                      }),
                      UploadsActions.vault.uploadFile.completed({
                        uuid: action.uuid,
                        uploadGroupUuid: action.uploadGroupUuid,
                      }),
                    ),
                  ),
                  catchError((error) => {
                    withScope((scope) => {
                      scope.setLevel('error');
                      scope.clearBreadcrumbs();
                      scope.setFingerprint([
                        'finish-video-upload',
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                        error.toString(),
                      ]);
                      scope.setExtras({
                        filename: vaultFile.data.file.name,
                        uploadId: vaultFile.serverData.uploadId,
                        fileUuid: vaultFile.serverData.fileUuid,
                        finishVideoUploadData,
                      });
                      scope.setTag(
                        'subMessage',
                        'Failed to finish video upload',
                      );
                      captureException(
                        toSentryError('VideoService::finishVideoUpload', error),
                      );
                    });
                    return of(
                      UploadsActions.vault.uploadFile.failed({
                        uuid: action.uuid,
                        uploadGroupUuid: action.uploadGroupUuid,
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        error,
                        reason: $localize`:@@vault-uploads.upload-failed:Upload could not be finished.`,
                      }),
                    );
                  }),
                );
            }),
            catchError((error) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              logger.error({ error }, 'Failed to upload part');

              return ofActions(
                ErrorsActions.generic({
                  action,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  error,
                  logToSentry: true,
                  log: true,
                  sentryOptions: {
                    extras: {
                      uploadGroup: uploadGroup.serverData,
                      file: {
                        fileUuid: vaultFile.serverData.fileUuid,
                        hash: vaultFile.serverData.hash,
                      },
                    },
                  },
                }),
                UploadsActions.vault.uploadFile.failed({
                  uuid: action.uuid,
                  uploadGroupUuid: action.uploadGroupUuid,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  error,
                  reason:
                    error === S3FileUploadService.fileReadError
                      ? $localize`:@@vault-uploads.file-not-accessible:File is not accessible`
                      : undefined,
                }),
                error instanceof HttpErrorResponse &&
                  (error.status === 400 || error.status === 403) &&
                  UploadsActions.vault.retry({
                    uploadGroupUuid: action.uploadGroupUuid,
                    uuid: action.uuid,
                    retryDelay: 1000,
                  }),
              );
            }),
            takeUntil(this.uploadCancelled(uploadGroup.uuid, action.uuid)),
          );
      }),
    ),
  );

  cancelFileUpload$ = createFlowEffect(({ action }) => {
    return of(action).pipe(
      delayedConcatMap(() => [
        this.store.select(
          fromUploadsState.vault.getFile({
            uploadGroupUuid: action.data.uploadGroupUuid,
            uuid: action.data.uuid,
          }),
        ),
        this.store.select(
          fromUploadsState.vault.getGroup({
            uploadGroupUuid: action.data.uploadGroupUuid,
          }),
        ),
      ]),
      switchMap(([, file, group]) =>
        of(undefined).pipe(
          mergeMap(() => {
            if (action.data.localOnly) {
              return of(undefined);
            }

            return file && file.serverData
              ? this.api.cancelFileUpload(file.serverData.fileUuid)
              : of(undefined);
          }),
          catchError((error) =>
            // ignore 404 as it was just canceled earlier
            error instanceof ApiErrorResponse && error.status === 404
              ? of(undefined)
              : // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                throwError(() => error),
          ),
          mergeMap(() => {
            return concat(
              of({ ...action.data, file }),
              EMPTY.pipe(
                finalize(() => {
                  if (group.serverData?.directory?.prefix) {
                    this.syncify.invalidate({
                      type: 'vault',
                      dtSent: undefined,
                      prefix: group.serverData.directory.prefix,
                      tree: true,
                      blockingUpdateNeeded: true,
                      action: 'delete',
                      files: [file.serverData.fileUuid],
                    });
                    this.syncify.invalidate({
                      type: 'project',
                      dtSent: undefined,
                    });
                    this.store.dispatch(
                      DetailActions.detail.refresh({ debounce: true }),
                    );
                  }
                }),
              ),
            );
          }),
          catchError((error) =>
            concat(
              of(
                ErrorsActions.generic({
                  action,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  error,
                  logToSentry: true,
                  log: true,
                  snackbarMessage: {
                    message: $localize`:@@vault-uploads.failed-to-cancel-upload:Failed to cancel upload`,
                    config: {
                      duration: 5000,
                    },
                  },
                }),
              ),
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              throwError(() => error),
            ),
          ),
        ),
      ),
    );
  }, UploadsActions.vault.uploadFile.cancel);

  cancelGroupUpload$ = createFlowEffect(({ action }) => {
    return of(action).pipe(
      withLatestFrom(
        this.store.select(
          fromUploadsState.vault.getGroup({
            uploadGroupUuid: action.data.uploadGroupUuid,
          }),
        ),
      ),
      mergeMap(([, group]) => {
        if (!group || !group.serverData || action.data.localOnly) {
          return of({ ...action.data, uploadGroup: group });
        }

        const flowAction = UploadsActions.vault.cancelOnServer.init({
          data: {
            uploadId: group.serverData.uploadId,
            type: UploadType.VAULT,
          },
        });

        const onFlowFinished$ = this.actions$.pipe(
          onFlowFinished(flowAction, UploadsActions.vault.cancelOnServer),
          mergeMap((a) => {
            if (isActionOfType(a, UploadsActions.vault.cancelOnServer.error)) {
              return throwError(() => a.data);
            }

            return EMPTY;
          }),
        );

        return concat(
          merge(of(flowAction), onFlowFinished$),
          of({ ...action.data, uploadGroup: group }),
          EMPTY.pipe(
            finalize(() => {
              this.syncify.invalidate({
                type: 'vault',
                dtSent: undefined,
                prefix: group.serverData.directory.prefix,
                tree: true,
                blockingUpdateNeeded: true,
                action: 'delete',
                files: Object.values(group.files).map(
                  (m) => m.serverData.fileUuid,
                ),
              });
              this.syncify.invalidate({
                type: 'project',
                dtSent: undefined,
              });
              this.store.dispatch(
                DetailActions.detail.refresh({ debounce: true }),
              );
            }),
          ),
        );
      }),
    );
  }, UploadsActions.vault.cancel);

  cancelGroupUploadOnServer$ = createFlowEffect(({ action }) => {
    return of(action.data).pipe(
      mergeMap((data) => {
        return this.api.cancelActiveUpload(data.uploadId);
      }),
      catchError((error) =>
        // ignore 404 as it was just canceled earlier
        error instanceof ApiErrorResponse && error.status === 404
          ? of(undefined)
          : // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            throwError(() => error),
      ),
      map(() => action.data),
      catchError((error) =>
        concat(
          of(
            ErrorsActions.generic({
              action,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              error,
              logToSentry: true,
              log: true,
              snackbarMessage: {
                message: $localize`:@@vault-uploads.failed-to-cancel-upload:Failed to cancel upload`,
                config: {
                  duration: 5000,
                },
              },
            }),
          ),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          throwError(() => error),
        ),
      ),
    );
  }, UploadsActions.vault.cancelOnServer);

  finishGroupTrigger$ = createEffect(
    (): Observable<Action> =>
      this.store.pipe(
        select(fromUploadsState.vault.triggers.getGroupIfEverythingIsProcessed),
        shallowDistinctUntilKeysChanged(['uuid']),
        filter((f) => !!f),
        mergeMap((uploadGroup) =>
          ofActions(
            UploadsActions.vault.finish({
              uploadGroupUuid: uploadGroup.uuid,
            }),
          ),
        ),
      ),
  );

  persistanceServiceTrigger$ = createEffect(() =>
    combineLatest([
      this.userContext.userUuid$,
      this.electronRef
        ? this.electronRef.isAllowed$.pipe(distinctUntilChanged())
        : of(false),
    ]).pipe(
      filter(() => !!this.electronUploadsPersistanceService),
      distinctUntilChanged(),
      startWith([undefined, false] as [string, boolean]), // fill pairwise buffer
      pairwise(),
      switchMap(
        ([
          [prevUuid, allowedOld],
          [newUuid, allowedNew],
        ]): Observable<Action> => {
          if (!newUuid || !allowedNew) {
            return of(undefined).pipe(
              tap(() => this.electronUploadsPersistanceService.disable()),
              ignoreElements(),
            );
          }

          if (prevUuid !== newUuid || allowedOld !== allowedNew) {
            this.electronUploadsPersistanceService.disable();
          }

          return this.notificationListener.connected$
            .pipe(
              filter((connected) => connected),
              timeout(10000),
              catchError(() => {
                return of('waiting-for-connection-timed-out');
              }),
              take(1),
              map(() => newUuid),
            )
            .pipe(
              map((uuid) =>
                UploadsActions.vault.restore.init({ userUuid: uuid }),
              ),
            );
        },
      ),
    ),
  );

  restoreUploads$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadsActions.vault.restore.init),
      filter(() => !!this.electronUploadsPersistanceService),
      concatMap((action) => {
        return this.electronUploadsPersistanceService
          .restoreUploads(action.userUuid)
          .pipe(
            mergeMap((uploads) =>
              of(
                UploadsActions.vault.restore.completed({ data: uploads }),
              ).pipe(
                finalize(() => {
                  this.electronUploadsPersistanceService.enable();
                }),
              ),
            ),
          );
      }),
    ),
  );

  directoryRemoved$ = createEffect(() =>
    this.store.select(fromUploadsState.vault.getProjects).pipe(
      distinctUntilChanged((a, b) => isEqual(a, b)),
      simpleSwitchMap((projectUuids) => {
        if (projectUuids.length === 0) {
          return EMPTY;
        }
        return merge(
          ...projectUuids.map((projectUuid) =>
            this.notificationsService.ofType('DIRECTORY_NOTIFICATION', {
              projectUuid,
            }),
          ),
        );
      }, EMPTY),
      filter((notification) => notification.data.status === FileStatus.Deleted),
      delayedConcatMap((notification) => [
        this.store.select(
          fromUploadsState.vault.getUploadGroupsForPrefix({
            prefix: notification.data.prefix,
          }),
        ),
      ]),
      mergeMap(([, uploads]) => {
        if (!uploads || uploads.length === 0) {
          return EMPTY;
        }

        return of(...uploads).pipe(
          map((group) =>
            UploadsActions.vault.cancel.init({
              data: {
                uploadGroupUuid: group.uuid,
                localOnly: true,
              },
            }),
          ),
        );
      }),
    ),
  );

  fileStateChanged$ = createEffect(() =>
    this.store.select(fromUploadsState.vault.getProjects).pipe(
      distinctUntilChanged((a, b) => isEqual(a, b)),
      simpleSwitchMap((projectUuids) => {
        if (projectUuids.length === 0) {
          return EMPTY;
        }
        return merge(
          ...projectUuids.map((projectUuid) =>
            this.notificationsService.ofType('FILE_STATE_NOTIFICATION', {
              projectUuid,
            }),
          ),
        );
      }, EMPTY),
      mergeMap((notification) => of(...notification.data)),
      mergeMap(
        (data): Observable<Action> =>
          this.store.pipe(
            select(
              fromUploadsState.vault.getFileFromServerFileUuid({
                uploadId: data.uploadId,
                fileUuid: data.uuid,
              }),
            ),
            take(1),
            mergeMap(
              (file): Observable<Action> =>
                ofActions(
                  file && data.status === FileStatus.Deleted
                    ? UploadsActions.vault.uploadFile.cancel.init({
                        data: {
                          uploadGroupUuid: file.uploadGroupUuid,
                          uuid: file.uuid,
                          localOnly: true,
                        },
                      })
                    : undefined,
                ),
            ),
          ),
      ),
    ),
  );

  cancelLocally$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadsActions.vault.uploadFile.cancelLocally),
      mergeMap((action) => of(...action.uuids)),
      mergeMap(
        (uuid): Observable<Action> =>
          this.store.pipe(
            select(
              fromUploadsState.vault.getFileFromFileUuid({
                fileUuid: uuid,
              }),
            ),
            take(1),
            mergeMap(
              (file): Observable<Action> =>
                ofActions(
                  file
                    ? UploadsActions.vault.uploadFile.cancel.init({
                        data: {
                          uploadGroupUuid: file.uploadGroupUuid,
                          uuid: file.uuid,
                          localOnly: true,
                        },
                      })
                    : undefined,
                ),
            ),
          ),
      ),
    ),
  );

  cancelUpload$ = createEffect(() =>
    this.store.select(fromUploadsState.vault.getProjects).pipe(
      distinctUntilChanged((a, b) => isEqual(a, b)),
      simpleSwitchMap((projectUuids) => {
        if (projectUuids.length === 0) {
          return EMPTY;
        }
        return merge(
          ...projectUuids.map((projectUuid) =>
            this.notificationsService.ofType('FILE_UPLOAD_CANCELLED', {
              projectUuid,
            }),
          ),
        );
      }, EMPTY),
      delayedConcatMap(() => [
        this.store.pipe(select(fromUploadsState.vault.getGroups)),
      ]),
      mergeMap(([notification, groups]) => {
        const group = groups?.find((group) => {
          const groupFiles = group?.serverData?.uploadId;
          return groupFiles === notification.data.uploadId;
        });

        if (!group || !group.remainingFiles) {
          return ofActions(undefined);
        }

        return ofActions(
          ...group.remainingFiles.map((uuid) =>
            UploadsActions.vault.uploadFile.cancel.init({
              data: {
                uploadGroupUuid: group.uuid,
                uuid,
                localOnly: true,
              },
            }),
          ),
        );
      }),
    ),
  );

  cleanupCanceledTrigger$ = createEffect(
    (): Observable<Action> =>
      this.store.pipe(
        select(fromUploadsState.vault.getCanceledGroups),
        distinctUntilChanged(),
        mergeMap((groups) =>
          groups && groups.length ? of(...groups.map((g) => g?.uuid)) : EMPTY,
        ),
        distinct(),
        delay(uploadConfig.vault.cleanupDelay),
        map((uuid) => UploadsActions.vault.cleanup({ uploadGroupUuid: uuid })),
      ),
  );

  cleanupUploadedTrigger$ = createEffect(
    (): Observable<Action> =>
      this.store.pipe(
        select(fromUploadsState.vault.getUploadedGroups),
        distinctUntilChanged(),
        mergeMap((groups) =>
          groups && groups.length ? of(...groups.map((g) => g?.uuid)) : EMPTY,
        ),
        distinct(),
        delay(uploadConfig.vault.cleanupDelay),
        map((uuid) => UploadsActions.vault.cleanup({ uploadGroupUuid: uuid })),
      ),
  );

  cleanupEncodeFileCancel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          UploadsActions.vault.cancel.completed,
          UploadsActions.vault.uploadFile.cancel.completed,
        ),
        mergeMap((action) => {
          let files: VaultStateFileModel[] = isActionOfType(
            action,
            UploadsActions.vault.cancel.completed,
          )
            ? Object.values(action.data.uploadGroup.files)
            : [action.data.file];
          files = files.filter((f) => !!f && f.encoded);

          return from(
            files.map((f) =>
              this.electronRef?.api.file.removeFile(f.encoded.file.path),
            ),
          ).pipe(mergeAll());
        }),
        ignoreElements(),
      ),
    { dispatch: false },
  );

  cleanupEncodeFileFinish$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UploadsActions.vault.encode.cleanupFile),
        mergeMap((action) =>
          this.electronRef?.api.file.removeFile(action.data.file.path),
        ),
        ignoreElements(),
      ),
    { dispatch: false },
  );

  sendStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadsActions.vault.uploadFile.completed),
      delayedConcatMap((action) => [
        this.store.select(
          fromUploadsState.vault.getFile({
            uploadGroupUuid: action.uploadGroupUuid,
            uuid: action.uuid,
          }),
        ),
      ]),
      filter(([, f]) => !!f?.encoded),
      map(([, f]) => {
        const stats = {
          presetFile: f.presetPath,
          encodingStarted: f.encodingStarted,
          encodingEnded: f.encodingEnded,
          encodingTime:
            f.encodingEnded?.getTime() - f.encodingStarted?.getTime(),
          uploadStarted: f.uploadStarted,
          uploadEnded: f.uploadEnded,
          uploadTime: f.uploadEnded?.getTime() - f.uploadStarted?.getTime(),
          encodedSize: f.encoded.size,
          originalSize: f.data.size,
        };

        return sendLog({
          datetime: DateTime.now().toISO(),
          level: ClientLogLevel.Info,
          message: `File encoding stats (file: ${
            f.serverData.fileUuid
          }) (stats: ${JSON.stringify(stats, null, 2)}).`,
        });
      }),
    ),
  );

  uploadStucked$ = createEffect(() =>
    this.store.select(fromUploadsState.getState).pipe(
      debounceTime(
        VaultUploadsEffects.actionTimeout * 8,
        leaveZone(this.ngZone, asyncScheduler),
      ),
      filter((state) => state && state.uploadGroupsIds.length > 0),
      observeOn(enterZone(this.ngZone, asyncScheduler)),
      mergeMap((state) => {
        const group = state.uploadGroups[state.uploadGroupsIds[0]];

        if (
          !group ||
          group.uploading.length > 0 ||
          group.encoding.length > 0 ||
          group.remainingFiles.length === 0 ||
          (group.initialized.length === 0 &&
            group.metadata.length === 0 &&
            group.encoded.length === 0 &&
            group.fullMetadata.length === 0)
        ) {
          return EMPTY;
        }

        withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('group', {
            ...group,
            files: {
              ...asIndexed(
                Object.values(group.files).map((f) => ({
                  ...f,
                  serverData: undefined,
                })),
                (f) => f.uuid,
              ),
            },
          });
          captureException(
            toSentryError(
              'UploadsEffects::queueStucked',
              new Error('Queue stucked'),
            ),
          );
        });

        if (group.fullMetadata.length > 0) {
          const file = group.files[group.fullMetadata[0]];

          return of(
            UploadsActions.vault.presign.init({
              uploadGroupUuid: file.uploadGroupUuid,
              uuid: file.uuid,
            }),
          );
        }

        if (group.metadata.length > 0) {
          const file = group.files[group.metadata[0]];

          return of(
            UploadsActions.vault.fullMetadata.init({
              uploadGroupUuid: file.uploadGroupUuid,
              uuid: file.uuid,
            }),
          );
        }

        if (group.encoded.length > 0) {
          const file = group.files[group.encoded[0]];

          return of(
            UploadsActions.vault.basicMetadata.init({
              uploadGroupUuid: file.uploadGroupUuid,
              uuid: file.uuid,
            }),
          );
        }

        if (group.initialized.length > 0) {
          const file = group.files[group.initialized[0]];

          return of(
            UploadsActions.vault.encode.init({
              uploadGroupUuid: file.uploadGroupUuid,
              uuid: file.uuid,
            }),
          );
        }

        return EMPTY;
      }),
    ),
  );

  private extractMetadata(
    ffmpegInfo: FFMpegOutput | typeof METADATA_WILL_NOT_LOAD,
    mediaInfo: MediaInfoOutput | typeof METADATA_WILL_NOT_LOAD,
  ): Omit<FullFileMetadataModel, 'hash'> {
    let duration: number;
    let fps: number;
    let frameCount: number;
    let height: number;
    let width: number;

    try {
      if (mediaInfo && mediaInfo !== METADATA_WILL_NOT_LOAD) {
        const videoTrack = mediaInfo.media.track.find(
          (f) => f['@type'] === 'Video',
        );

        if (videoTrack) {
          width = parseInt(videoTrack.Width, 10);
          height = parseInt(videoTrack.Height, 10);
          duration = Math.round(parseFloat(videoTrack.Duration) * 1000);
          frameCount = parseInt(videoTrack.FrameCount, 10);
          fps = round(frameCount / (duration / 1000), 3);
        }
      }

      if (ffmpegInfo && ffmpegInfo !== METADATA_WILL_NOT_LOAD) {
        duration = Math.round(ffmpegInfo.duration / 1000);
        frameCount = ffmpegInfo.frames;
        fps = round(frameCount / (duration / 1000), 3);
      }
    } catch (e) {
      logger.error({ error: e }, 'Failed to map file metadata');
    }

    return {
      duration,
      fps,
      frameCount,
      height,
      width,
    };
  }

  private getPartTags(
    filePartUploads: readonly FilePartUploadModel[],
  ): readonly VideoPartTag[] {
    return filePartUploads
      .slice()
      .sort(
        (filePartUploadA, filePartUploadB) =>
          filePartUploadA.partNumber - filePartUploadB.partNumber,
      )
      .map<VideoPartTag>((filePartUpload) => {
        return {
          eTag: filePartUpload.eTag,
          partNumber: filePartUpload.partNumber,
        };
      });
  }

  private createTrigger(
    selector: MemoizedSelector<object, VaultStateFileModel>,
    mapper: (file: VaultStateFileModel) => Action,
  ): () => Observable<Action> {
    return (): Observable<Action> =>
      this.store.select(selector).pipe(
        shallowDistinctUntilKeysChanged(['uuid']),
        filter((file) => !!file),
        map(mapper),
      );
  }

  private uploadCancelled(
    uploadGroupUuid: string,
    uuid: string,
  ): Observable<void> {
    return this.actions$.pipe(
      ofType(
        UploadsActions.vault.cancel.completed,
        UploadsActions.vault.uploadFile.cancel.completed,
      ),
      filter(
        (cancelAction) =>
          cancelAction.data.uploadGroupUuid === uploadGroupUuid &&
          (!isActionOfType(
            cancelAction,
            UploadsActions.vault.uploadFile.cancel.completed,
          ) ||
            cancelAction.data.uuid === uuid),
      ),
      map((): void => undefined),
    );
  }
}

export function buildVaultFileModel(
  item: VaultUploadFileDetailModel,
  info: CreateFileUpload,
): VaultFileModel {
  const date = new Date().toISOString();
  return {
    bundledFiles: 0,
    dataSource: undefined,
    dtCreated: date,
    dtModified: date,
    dtUploaded: date,
    filename: item.filename,
    hash: item.hash,
    mediaType: undefined,
    properties: undefined,
    size: info.size,
    state: FileState.Uploading,
    status: FileStatus.Active,
    thumbnail: undefined,
    type: VaultFileType.Video,
    uuid: item.fileUuid,
    vaultState: VaultFileState.Uploading,
    directory: undefined,
    vaulted: false,
  };
}
