import { inject } from '@angular/core';

import type { ProjectApiModel, ProjectFilter, ProjectMapDTO } from '@gv/api';
import {
  createArrayFeature,
  actionProps,
  asMutable,
  StoreInject,
  EMPTY_STATE,
  ofActions,
} from '@gv/state';
import { ProjectManagementStatus, LayoutView, ProjectType } from '@gv/api';
import { createAction, createSelector, on, props } from '@ngrx/store';
import { produce } from 'immer';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  EMPTY,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  mergeMap,
  of,
  tap,
} from 'rxjs';
import {
  BASE_PERMISSION_SERVICE,
  Permission,
  PermissionScope,
} from '@gv/permission';
import { isEqual } from 'lodash-es';
import { USER_CONTEXT } from '@gv/user';
import { delayedConcatMap, removeFromArray, simpleSwitchMap } from '@gv/utils';
import { LocalStorageSynchronizer } from '@gv/ui/core';
import { omit } from 'remeda';

import type { ProjectStateModel } from '../../../../entity/model/projects/project-state-model';
import * as actions from './projects.actions';
import { projectsReducerFns } from './projects.reducer';
import type { ProjectsState } from './projects-state.types';
import {
  InternalProjectPageType,
  initialProjectsState,
  pageTypeMapping,
} from './projects-state.types';

function mergeDirty(a: ProjectsState['dirty'], b: ProjectsState['dirty']) {
  if (a === 'filthy' || b === 'filthy') {
    return 'filthy';
  }
  if (a === 'smudged' || b === 'smudged') {
    return 'smudged';
  }
  return false;
}

export const projectTypes = [
  {
    text: $localize`:@@project-type-filter.insights:Video Insights`,
    value: ProjectType.Standard,
    permission: Permission.Platform,
  },
  {
    text: $localize`:@@project-type-filter.live:Live Traffic`,
    value: ProjectType.Live,
    permission: Permission.Edge,
  },
];

const projectsFeature = createArrayFeature(initialProjectsState, {
  defaultErrorMessage: '',
  name: 'project',
  modelMapper: (
    _,
    projects: readonly ProjectApiModel[],
    dtSent: Date,
  ): readonly ProjectStateModel[] => projects.map((p) => ({ ...p, dtSent })),

  extraReducers: projectsReducerFns,

  completedProps: actionProps<{
    dirty: ProjectsState['dirty'];
    filter: ProjectFilter;
    mapData: readonly ProjectMapDTO[];
    context: {
      count: number;
      limit: number;
      offset: number;
    };
  }>(),

  extraReducersFactory: (actions) => [
    on(actions.markAsDirty, (state, action) => {
      return produce(state, (draft) => {
        draft.dirty = mergeDirty(draft.dirty, action.dirty);
        draft.freezed.forEach((f) => {
          f.dirty = mergeDirty(f.dirty, action.dirty);
        });
      });
    }),
    on(actions.fetch.completed, (state, action) => {
      return produce(state, (draft) => {
        draft.pageView ??= LayoutView.Map;
        if (action.sortable?.layoutView) {
          draft.pageView = action.sortable.layoutView;
        }
        if (action.sortable) {
          draft.localMapSettings = draft.apiMapSettings = {
            ...action.sortable.mapSettings,
            preferredZoom: action.sortable.mapSettings?.mapZoom,
          };
        }
        draft.mapData = asMutable(action.custom.mapData);
        draft.context = {
          ...action.custom.context,
          countDirty: false,
        };

        if (draft.newProjects.length || Object.keys(draft.detailData).length) {
          if (action.custom.dirty === 'smudged') {
            for (const project of draft.newProjects) {
              if (project.uuid in draft.data) {
                delete draft.data[project.uuid];
                removeFromArray(draft.ids, project.uuid);
              }
            }
          } else {
            for (const project of action.data) {
              const newProjectIndex = draft.newProjects.findIndex(
                (f) => f.uuid === project.uuid,
              );

              if (newProjectIndex >= 0) {
                draft.newProjects.splice(newProjectIndex, 1);
              }

              if (project.uuid in draft.detailData) {
                draft.detailData[project.uuid] = {
                  ...draft.detailData[project.uuid],
                  data: draft.data[project.uuid],
                };
              }
            }
          }
        }
      });
    }),
  ],

  extraSelectors: (selectors) => {
    const isFilterActive = (filter: (typeof initialProjectsState)['filter']) =>
      Boolean(
        filter &&
          ((filter.assignees || []).length ||
            filter.dtModifiedEnd ||
            filter.dtModifiedStart ||
            filter.nameSearch ||
            filter.uuids?.length),
      );

    const getProjects = createSelector(
      selectors.selectProjectsState,
      ({ ids, data }) => ids.map((id) => data[id]),
    );
    return {
      areProjectsDirty: createSelector(
        selectors.selectProjectsState,
        ({ dirty }) => dirty,
      ),
      allowedTypes: createSelector(selectors.selectAllowedTypes, (types) =>
        types.map((m) => projectTypes.find((f) => f.value === m)),
      ),
      isDemoImportable: createSelector(
        selectors.selectDemo,
        (subState) =>
          subState.state === 'loaded' && subState.imported === false,
      ),
      selectPageType: createSelector(
        selectors.selectFilter,
        (subState) => subState.page,
      ),
      getProjectType: createSelector(
        selectors.selectFilter,
        (subState) => subState.types?.[0],
      ),
      getProjectsWithNewProjectsIncluded: createSelector(
        selectors.selectNewProjects,
        getProjects,
        selectors.selectFilter,
        (newProjects, projects, filter) => {
          const matchingNewProjects =
            !filter.types || filter.types.length === 0
              ? newProjects
              : newProjects.filter((f) => filter.types.includes(f.type));
          return !matchingNewProjects.length
            ? projects
            : [...matchingNewProjects, ...projects];
        },
      ),
      selectFilterForApiRequest: createSelector(
        selectors.selectFilter,
        (subState): ProjectFilter => ({
          ...omit(subState, ['entitiesNames']),
          page: pageTypeMapping[subState.page],
          statuses:
            subState.page === InternalProjectPageType.Archived
              ? [ProjectManagementStatus.Archived]
              : [ProjectManagementStatus.Active],
        }),
      ),
      isSubmittingLayoutChange: createSelector(
        selectors.selectProjectsState,
        (state) => state.updatingLayout,
      ),
      getProjectsCount: (type: InternalProjectPageType) =>
        createSelector(selectors.selectProjectsState, (state) =>
          state.filter.page === type
            ? state.context?.count
            : state.freezed.find((f) => f.filter.page === type)?.context?.count,
        ),
      getProjectDetail: (uuid: string) =>
        createSelector(selectors.selectDetailData, (state) => {
          const context = state[uuid];
          return context?.loaded ? state[uuid].data : undefined;
        }),
      getIsFilterActive: createSelector(selectors.selectFilter, isFilterActive),
      userHasAnyProject: createSelector(
        selectors.selectProjectsState,
        (state): boolean | null => {
          const {
            context,
            freezed,
            filter,
            countsLoadedAtLeastOnce,
            loadingCounts,
            loading,
          } = state;

          if (isFilterActive(filter)) {
            return true;
          }

          if (!countsLoadedAtLeastOnce) {
            return null;
          }

          if (loading || loadingCounts) {
            return true;
          }

          return Object.values(InternalProjectPageType).reduce(
            (acc, type) =>
              acc ||
              (filter.page === type
                ? (context?.count ?? 0) > 0
                : (freezed.find((f) => f.filter.page === type)?.context
                    ?.count ?? 0) > 0),
            false,
          );
        },
      ),
    };
  },

  extraEffects: (a, s) => [
    {
      setPageType: createEffect(
        (
          actions$ = inject(Actions),
          store = inject(StoreInject(EMPTY_STATE)),
        ) =>
          actions$.pipe(
            ofType(
              a.setFilter,
              a.setPageType,
              a.setAllowedTypes,
              a.setAllowedPageTypes,
              a.setPageLayout.completed,
            ),
            debounceTime(0),
            delayedConcatMap(() => [store.select(s.watching)]),
            mergeMap(([action, watching]) =>
              ofActions(
                !(
                  [
                    a.setPageType.type,
                    a.setPageLayout.completed.type,
                  ] as string[]
                ).includes(action.type as string) && a.fetchCounts.cancel(),
                a.fetch.cancel(),
                watching === 0 && a.markAsDirty({ dirty: 'filthy' }),
                watching > 0 &&
                  !(
                    [
                      a.setPageType.type,
                      a.setPageLayout.completed.type,
                    ] as string[]
                  ).includes(action.type as string) &&
                  a.fetchCounts.init({}),
                watching > 0 && a.fetch.init({ skipWhenLoaded: true }),
              ),
            ),
          ),
        { functional: true },
      ),

      refreshOnChange: createEffect(
        (
          actions$ = inject(Actions),
          store = inject(StoreInject(EMPTY_STATE)),
        ) =>
          actions$.pipe(
            ofType(a.fetchCounts.completed),
            delayedConcatMap(() => [store.select(s.selectFilter)]),
            mergeMap(([action, filter]) => {
              return ofActions(
                filter.page !== action.currentPageType && a.fetch.cancel(),
                filter.page !== action.currentPageType &&
                  a.fetch.init({ skipWhenLoaded: true }),
              );
            }),
          ),
        { functional: true },
      ),

      storeType: createEffect(
        (
          userContext = inject(USER_CONTEXT),
          store = inject(StoreInject(EMPTY_STATE)),
          localStorage = inject(LocalStorageSynchronizer),
        ) =>
          userContext.userUuid$.pipe(distinctUntilChanged()).pipe(
            simpleSwitchMap(() =>
              store.select(s.selectFilter).pipe(
                map((m) => m.types[0]),
                distinctUntilChanged(),
                tap((type) => localStorage.setItem('gv_projectType', type)),
              ),
            ),
          ),
        { functional: true, dispatch: false },
      ),

      setAllowedTypes: createEffect(
        (
          permissionService = inject(BASE_PERMISSION_SERVICE),
          userContext = inject(USER_CONTEXT),
          localStorage = inject(LocalStorageSynchronizer),
          store = inject(StoreInject(EMPTY_STATE)),
        ) =>
          userContext.userUuid$.pipe(distinctUntilChanged()).pipe(
            simpleSwitchMap(
              () =>
                merge(
                  store.select(s.selectFilter).pipe(
                    map((f) => f?.types[0]),
                    distinctUntilChanged(),
                    filter((f) => !!f),
                    delayedConcatMap((f) => [
                      of(localStorage.getItem(`gv_projectSorting_${f}`)),
                    ]),
                    filter(([, f]) => !!f),
                    map(([, m]) => actions.setFilter({ filter: m })),
                  ),
                  permissionService.permissions$.pipe(
                    debounceTime(0),
                    map(() =>
                      projectTypes
                        .filter((f) =>
                          permissionService.isAllowed(
                            f.permission,
                            PermissionScope.Use,
                          ),
                        )
                        .map((m) => m.value),
                    ),
                    distinctUntilChanged((a, b) => isEqual(a, b)),
                    mergeMap((types) =>
                      of(
                        actions.setAllowedTypes({
                          types,
                          default: localStorage.getItem('gv_projectType'),
                        }),
                        // this is not needed, setAllowedTypes will trigger it, but we keep it here for reference
                        // a.fetch.init({ skipWhenLoaded: true }),
                      ),
                    ),
                  ),
                ),
              EMPTY,
            ),
          ),
        { functional: true },
      ),
    },
  ],

  extraActions: {
    ...actions,
    refresh: createAction(
      `Project refresh`,
      props<{
        dtSent?: Date;
        debounce?: boolean;
        dirty: ProjectsState['dirty'];
      }>(),
    ),
    markAsDirty: createAction(
      `Project set dirty`,
      props<{
        dirty: ProjectsState['dirty'];
      }>(),
    ),
  },
});

const {
  actions: ProjectsActions,
  reducer,
  name,
  token: PROJECTS_FEATURE_STATE,
  ...fromProjectsState
} = projectsFeature;

export default projectsFeature;

export {
  ProjectsActions,
  reducer,
  name,
  PROJECTS_FEATURE_STATE,
  fromProjectsState,
};
