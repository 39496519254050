import type { OperatorFunction } from 'rxjs';
import { filter } from 'rxjs';

type Falsy = false | 0 | '' | null | undefined;

export const truthy = <T>(val: T | Falsy): val is T => !!val;
const truthyFilter = filter(truthy);

export const filterTruthy = <T>(): OperatorFunction<T | Falsy, T> =>
  truthyFilter as OperatorFunction<T | Falsy, T>;
