import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';

import {
  InputUtilsModule,
  ValidationModule,
  ValidationErrorComponent,
} from '@gv/ui/form';
import { ButtonModule } from '@gv/ui/utils';

import { BaseSignPageModule } from '../../../../component/page/base-sign-page/base-sign-page.component';
import { RestorePasswordComponent } from '../../../../component/page/restore-password/restore-password.component';

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputUtilsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    RouterModule.forChild([]),
    ValidationModule,
    BaseSignPageModule,
    ValidationErrorComponent,
    RestorePasswordComponent,
  ],
  exports: [RestorePasswordComponent],
})
export class AuthenticationModule {}
