import type { Signal } from '@angular/core';
import { InjectionToken } from '@angular/core';

import type { AuthenticationTokenDataModel } from '@gv/api';
import type { Observable } from 'rxjs';

import type { UserModel } from './entity';

export interface UserContext {
  readonly user$: Observable<UserModel>;
  readonly userUuid$: Observable<string>;
  readonly token$: Observable<string>;
  readonly decodedToken$: Observable<AuthenticationTokenDataModel>;
  readonly timeOffset$: Observable<number>;
  readonly userS: Signal<UserModel>;
  readonly setCookie: (cookie: string, value: any) => void;
  readonly getCookie: (cookie: string) => Promise<string>;
}

export const USER_CONTEXT = new InjectionToken<UserContext>('userContext');
