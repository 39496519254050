/**
 * This is a partially generated file. Some edits may be overwritten!
 * @apiRef {@model ServiceConnectionType}
 */
export enum ServiceConnectionType {
  GoogleDrive = 'GOOGLE_DRIVE',
  Vault = 'VAULT',
  Dropbox = 'DROPBOX',
  GoogleOauth = 'GOOGLE_OAUTH',
  OneDrive = 'ONEDRIVE',
}
