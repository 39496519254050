<div
  class="bgimage m-0 flex h-[100vh] bg-cover bg-center bg-no-repeat p-8 md:px-20 md:py-12"
>
  <div class="max-w-[400px] sm:max-w-full">
    <img
      class="absolute h-auto w-[calc(100vw_-_4rem)] max-w-[600px] cursor-pointer"
      [src]="logo$ | async"
      alt="GoodVision logo"
      i18n-alt="@@maintenance-page.gv-logo"
      (click)="retry()"
    />
  </div>
  <div
    class="flex w-full flex-col items-center justify-center md:flex-row md:justify-between"
  >
    <div class="flex flex-col space-y-6">
      <div
        class="text-3xl !leading-normal sm:text-4xl lg:text-5xl"
        i18n="@@maintenance-page.maintenance"
      >
        We are down for maintenance.
      </div>
      <a class="btn btn-green self-start" (click)="retry()">
        <mat-icon class="mr-3">refresh</mat-icon>
        <ng-container i18n="@@maintenance-page.retry">Retry</ng-container>
      </a>
    </div>
    <img
      class="mt-20 hidden h-auto w-[350px] lg:block 2xl:mt-12 2xl:w-[500px]"
      src="./assets/images/maintenance-logo.png"
      alt="GoodVision maintenence logo"
      i18n-alt="@@maintenance-page.gv-maintenance-logo"
    />
  </div>
</div>
