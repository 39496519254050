const basename = '[Devices]';

import type {
  CameraNetworkInterface,
  CreateDeviceStream,
  CreateDeviceStreamFromDiscovery,
  DeviceDTO,
  DeviceStreamDTO,
  EditDevice,
  EditDeviceStream,
  PerformDeviceStreamAction,
} from '@gv/api';
import {
  createDialogBaseActions,
  createFlowBaseActions,
  createSubStateActions,
} from '@gv/state';
import { createAction, props } from '@ngrx/store';

import type { DeviceDetailDialogData } from '../dialog/device-detail-dialog/device-detail.component';
import type { EditEdgeStreamDialogData } from '../dialog/edit-device-stream-dialog/edit-edge-stream-dialog.component';
import type { UpdateEdgeStreamStateDialogData } from '../dialog/update-edge-stream-state-dialog.component';
import type { DeviceStateModel, DeviceStreamStateModel } from './state';

const base = createSubStateActions<
  readonly DeviceDTO[],
  readonly DeviceStateModel[]
>(basename);

export { base };

export const newEdgeStreamDialog = createDialogBaseActions(
  basename,
  'NewStream',
  {
    openProps: props<{
      data: {
        device: DeviceStateModel;
        camera?: CameraNetworkInterface;
        streams: readonly DeviceStreamStateModel[];
      };
    }>(),
    closedProps: props<{ data: DeviceStreamDTO }>(),
  },
);

export const editEdgeStreamDialog = createDialogBaseActions(
  basename,
  'EditStream',
  {
    openProps: props<{ data: EditEdgeStreamDialogData }>(),
    closedProps: props<{ data: DeviceStreamDTO }>(),
  },
);

export const deleteEdgeStreamDialog = createDialogBaseActions(
  basename,
  'DeleteStream',
  {
    openProps: props<{
      data: {
        readonly stream: DeviceStreamDTO;
        readonly deviceUuid: string;
      };
    }>(),
    closedProps: props<{ data: boolean }>(),
  },
);

export const verifyEdgeStreamDialog = createDialogBaseActions(
  basename,
  'VerifyStream',
  {
    openProps: props<{
      data: { stream: DeviceStreamDTO; device: DeviceStateModel };
    }>(),
    closedProps: props<{ data: boolean }>(),
  },
);

export const updateEdgeStreamStateDialog = createDialogBaseActions(
  basename,
  'EditStreamState',
  {
    openProps: props<{ data: UpdateEdgeStreamStateDialogData }>(),
    closedProps: props<{ data: DeviceStreamDTO }>(),
  },
);

export const edgeDeviceDetailDialog = createDialogBaseActions(
  basename,
  'DeviceDetail',
  {
    openProps: props<{ data: DeviceDetailDialogData }>(),
    prepareProps: props<{ data: { device: string; tab: number } }>(),
  },
);

export const updateDevice = createAction(
  `${basename} update device`,
  props<{ data: DeviceStateModel }>(),
);

export const createEdgeStreamFlow = createFlowBaseActions(
  basename,
  'create stream',
  {
    initProps: props<{
      data: { data: CreateDeviceStream; deviceUuid: string };
    }>(),
    completedProps: props<{ data: { device: DeviceStreamDTO } }>(),
  },
);

export const createEdgeStreamFromDiscoveryFlow = createFlowBaseActions(
  basename,
  'create stream from discovery',
  {
    initProps: props<{
      data: { data: CreateDeviceStreamFromDiscovery; deviceUuid: string };
    }>(),
    completedProps: props<{ data: { device: DeviceStreamDTO } }>(),
  },
);

export const validateStreamFlow = createFlowBaseActions(
  basename,
  'validate stream',
  {
    initProps: props<{
      data: {
        data: PerformDeviceStreamAction;
        deviceUuid: string;
        streamUuid: string;
      };
    }>(),
    completedProps: props<{
      data: { device: DeviceStreamDTO; dtSent: Date };
    }>(),
  },
);

export const editEdgeStreamFlow = createFlowBaseActions(
  basename,
  'update stream',
  {
    initProps: props<{
      data: {
        data: EditDeviceStream;
        streamUuid: string;
        deviceUuid: string;
      };
    }>(),
    completedProps: props<{
      data: { device: DeviceStreamDTO; dtSent: Date };
    }>(),
  },
  true,
);

export const deleteEdgeStreamFlow = createFlowBaseActions(
  basename,
  'delete stream',
  {
    initProps: props<{ data: { streamUuid: string; deviceUuid: string } }>(),
  },
  true,
);

export const updateDeviceFlow = createFlowBaseActions(
  basename,
  'update device',
  {
    initProps: props<{ data: { data: EditDevice; deviceUuid: string } }>(),
  },
);

export const reset = createAction(`${basename} reset`);
