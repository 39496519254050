import type { GeoPositionModel } from '@gv/api';
import { createDialogBaseActions } from '@gv/state';
import { props } from '@ngrx/store';

import type { SetGeoPositionDataModel } from '../../util/geocoder-utils';

const basename = '[Geo Dialog]';

export const GeoDialogActions = {
  setDataSourceGeoPositionDialog: createDialogBaseActions(
    basename,
    'SetDataSourceGeoPosition',
    {
      openProps: props<{
        data: {
          defaultLocation?: GeoPositionModel | SetGeoPositionDataModel;
        };
      }>(),
      closedProps: props<{ readonly data: SetGeoPositionDataModel }>(),
    },
  ),
};
