/**
 * This is a partially generated file. Some edits may be overwritten!
 * @apiRef {@model FileType}
 */
export enum VaultFileType {
  Video = 'VIDEO',
  GDriveVideo = 'GDRIVE_VIDEO',
  DropboxVideo = 'DROPBOX_VIDEO',
  OneDriveVideo = 'ONEDRIVE_VIDEO',
  Asset = 'ASSET',
}
