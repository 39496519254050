import type { Feature, UserProjectPropertiesModel } from '@gv/api';
import {
  ProjectCreditScheme,
  ProjectPermissions,
  ServiceConnectionType,
  ProjectType,
  ProjectVisibility,
} from '@gv/api';

import { Permission } from '../entity/enum/permission.enum';
import { PermissionScope } from '../entity/enum/permission-scope.enum';
import type { Permissions } from './permission-definition';

export interface ProjectPermissionContext {
  readonly permissions: ProjectPermissions | undefined;
  readonly disabledFeatures: readonly Feature[];
  readonly userProjectProperties: UserProjectPropertiesModel | undefined;
  readonly services: readonly ServiceConnectionType[];
  readonly projectType: ProjectType;
  readonly visibility: ProjectVisibility;
}

export type ProjectPermissionReducerFunction = (
  permissions: Permissions,
  feature: Feature,
  features: readonly Feature[],
) => Permissions;

export type ProjectPermissionInitializerFunction = (
  permissions: Permissions,
  context: ProjectPermissionContext,
) => Permissions;

export type ProjectPermissionReducer = ProjectPermissionReducerFunction;

export const projectPermissionsInitializers: readonly ProjectPermissionInitializerFunction[] =
  [
    (permissions, context): Permissions => {
      if (context.projectType === ProjectType.Lite) {
        permissions = {
          ...permissions,
          [Permission.Project]:
            permissions[Permission.Project] & ~PermissionScope.Show,
          [Permission.Edge]:
            permissions[Permission.Edge] & ~PermissionScope.Show,
          [Permission.Widget]:
            permissions[Permission.Widget] & ~PermissionScope.Show,
          [Permission.Vissim]:
            permissions[Permission.Vissim] & ~PermissionScope.Show,
          [Permission.Drone]:
            permissions[Permission.Drone] & ~PermissionScope.Show,
          [Permission.Dashboard]:
            permissions[Permission.Dashboard] & ~PermissionScope.Show,
          [Permission.StoppedEvent]:
            permissions[Permission.StoppedEvent] & ~PermissionScope.Show,
          [Permission.StayEvent]:
            permissions[Permission.StayEvent] & ~PermissionScope.Show,
          [Permission.ODFilterGeneration]:
            permissions[Permission.ODFilterGeneration] & ~PermissionScope.Show,
          [Permission.VehicleListExport]:
            permissions[Permission.VehicleListExport] & ~PermissionScope.Show,
          [Permission.SatFlowExport]:
            permissions[Permission.SatFlowExport] & ~PermissionScope.Show,
          [Permission.AnprExport]:
            permissions[Permission.AnprExport] & ~PermissionScope.Show,
          [Permission.PeakHourExport]:
            permissions[Permission.PeakHourExport] & ~PermissionScope.Show,
        };
      }

      if (context.projectType === ProjectType.Live) {
        permissions = {
          ...permissions,
          [Permission.Project]:
            permissions[Permission.Project] & ~PermissionScope.Show,
        };
      }

      if (context.projectType === ProjectType.Standard) {
        permissions = {
          ...permissions,
          [Permission.DashboardLive]:
            permissions[Permission.DashboardLive] & ~PermissionScope.All,
        };
      }

      if (context.permissions === ProjectPermissions.Owner) {
        return permissions;
      }

      if (context.permissions === ProjectPermissions.ReadOnly) {
        let newPerm: Permissions = { ...permissions };

        for (const permission of Object.keys(newPerm)) {
          newPerm[permission as unknown as keyof Permissions] &=
            PermissionScope.Use | PermissionScope.Show;
        }

        newPerm = {
          ...newPerm,
          [Permission.Project]:
            permissions[Permission.Project] &
            (PermissionScope.Use |
              PermissionScope.Delete |
              PermissionScope.Show),
        };

        if (
          context.userProjectProperties?.creditScheme ===
            ProjectCreditScheme.Owner &&
          context.visibility === ProjectVisibility.Organization
        ) {
          newPerm[Permission.Project] &= ~PermissionScope.Delete;
        }

        return newPerm;
      }

      if (context.permissions === ProjectPermissions.Write) {
        const newPerm = { ...permissions };

        newPerm[Permission.Project] &= ~(
          PermissionScope.Share | PermissionScope.EditSubset
        );

        if (
          context.userProjectProperties?.creditScheme ===
            ProjectCreditScheme.Owner &&
          context.visibility === ProjectVisibility.Organization
        ) {
          newPerm[Permission.Project] &= ~PermissionScope.Delete;
        }

        return newPerm;
      }

      if (
        context.permissions === ProjectPermissions.Support ||
        context.permissions === undefined
      ) {
        const newPerm = { ...permissions };

        newPerm[Permission.Project] &= ~(
          PermissionScope.Delete |
          PermissionScope.Share |
          PermissionScope.EditSubset
        );

        return newPerm;
      }

      return permissions;
    },

    (permissions): Permissions => {
      if (!permissions[Permission.OrganizationProjectManagement]) {
        return {
          ...permissions,
          [Permission.Project]:
            permissions[Permission.Project] &
            ~(
              PermissionScope.Share |
              PermissionScope.Edit |
              PermissionScope.EditSubset |
              PermissionScope.Create
            ),
        };
      }
      return permissions;
    },

    (permissions, context): Permissions => {
      if (
        context.userProjectProperties?.creditScheme === ProjectCreditScheme.None
      ) {
        const newPerm = { ...permissions };

        newPerm[Permission.Processing] = PermissionScope.Empty;

        return newPerm;
      }

      return permissions;
    },

    (permissions, context): Permissions => {
      const newPerm = { ...permissions };
      if (!context.services?.includes(ServiceConnectionType.GoogleDrive)) {
        newPerm[Permission.ServiceGDrive] =
          newPerm[Permission.ServiceGDrive] &
          ~(
            PermissionScope.Delete |
            PermissionScope.Edit |
            PermissionScope.EditSubset |
            PermissionScope.Generic |
            PermissionScope.Open |
            PermissionScope.Share |
            PermissionScope.Submit |
            PermissionScope.Use
          );
      }

      if (!context.services?.includes(ServiceConnectionType.Dropbox)) {
        newPerm[Permission.ServiceDropbox] =
          newPerm[Permission.ServiceDropbox] &
          ~(
            PermissionScope.Delete |
            PermissionScope.Edit |
            PermissionScope.EditSubset |
            PermissionScope.Generic |
            PermissionScope.Open |
            PermissionScope.Share |
            PermissionScope.Submit |
            PermissionScope.Use
          );
      }

      if (!context.services?.includes(ServiceConnectionType.OneDrive)) {
        newPerm[Permission.ServiceOneDrive] =
          newPerm[Permission.ServiceOneDrive] &
          ~(
            PermissionScope.Delete |
            PermissionScope.Edit |
            PermissionScope.EditSubset |
            PermissionScope.Generic |
            PermissionScope.Open |
            PermissionScope.Share |
            PermissionScope.Submit |
            PermissionScope.Use
          );
      }

      if (!context.services?.includes(ServiceConnectionType.Vault)) {
        newPerm[Permission.Vault] = PermissionScope.Empty;
      }

      return newPerm;
    },
  ];

export const projectPermissionReducers: readonly ProjectPermissionReducer[] =
  [];
