import { DestroyRef, Injectable, inject } from '@angular/core';

import { APP_INSTANCE_ID } from '@gv/ui/core';
import { generateUuid, toSentryError, untilNgDestroyed } from '@gv/utils';
import { captureException, withScope } from '@sentry/angular';
import type { Observable } from 'rxjs';
import {
  EMPTY,
  forkJoin,
  from,
  interval,
  isObservable,
  of,
  throwError,
  TimeoutError,
} from 'rxjs';
import {
  catchError,
  filter,
  mergeMap,
  share,
  switchMap,
  take,
  takeUntil,
  tap,
  timeout,
} from 'rxjs/operators';

import { logger } from '../logger';
import { CrossTabChannelService } from './cross-tab-channel.service';
import type { CrossTabMessageModel } from './entity/cross-tab-message-model';
import type { CrossTabExecuteCompletedMessageModel } from './entity/cross-tab-execute-completed-message-model';
import type { CrossTabExecuteErrorMessageModel } from './entity/cross-tab-execute-error-message-model';
import type { CrossTabExecuteKeepAliveMessageModel } from './entity/cross-tab-execute-keep-alive-message-model';
import type { CrossTabExecuteMessageModel } from './entity/cross-tab-execute-message-model';
import type { CrossTabExecuteReceivedMessageModel } from './entity/cross-tab-execute-received-message-model';
import type { CrossTabExecuteStartMessageModel } from './entity/cross-tab-execute-start-message-model';
import { CrossTabWorkerMessageType } from './entity/cross-tab-worker-messsage-type.enum';

@Injectable({
  providedIn: 'root',
})
export class CrossTabWorkerService {
  private destroyRef = inject(DestroyRef);
  private appInstanceId = inject(APP_INSTANCE_ID);
  private crossTabChannel = inject(CrossTabChannelService);
  private static readonly keepAliveInterval: number = 2000; // in ms
  private static readonly executeTimeout: number = 6000; // in ms

  private readonly workers: {
    [type: string]: (
      instanceId: string,
      data: any,
    ) => Promise<any> | Observable<any> | any;
  } = {};

  private keepAliveInterval$ = interval(
    CrossTabWorkerService.keepAliveInterval,
  ).pipe(untilNgDestroyed());

  private messageReceived$ = this.crossTabChannel.messageReceived$.pipe(
    filter((m): m is CrossTabExecuteMessageModel<any, string> =>
      this.isExecuteMessage(m),
    ),
    untilNgDestroyed(),
  );

  private messageExecuteStartReceived$ = this.messageReceived$.pipe(
    filter((m): m is CrossTabExecuteStartMessageModel<any> =>
      this.isExecuteStartMessage(m as any),
    ),
    tap((m: CrossTabExecuteStartMessageModel<any>) => {
      if (this.workers[m.data!.type]) {
        this.startWorkOnWorker(m, this.workers[m.data!.type]);
      }
    }),
  );

  constructor() {
    this.messageExecuteStartReceived$.subscribe();
  }

  register<Data, Result>(
    type: string,
    fn: (
      instanceId: string,
      data: Data,
    ) => Promise<Result> | Observable<Result> | Result,
  ): void {
    // TODO: enable this check when https://issues.goodvisionlive.com/youtrack/issue/GVVI-3245 is resolved
    // if (type in this.workers) {
    //   throw new Error('Worker with this type is already registered');
    // }

    this.workers[type] = fn;
  }

  execute<Data, R extends CrossTabExecuteCompletedMessageModel<any>>(
    recipient: string,
    type: string,
    data: Data,
  ): Observable<R> {
    const workId = generateUuid();

    const message: CrossTabExecuteStartMessageModel<Data> = {
      instanceId: this.appInstanceId,
      type: CrossTabWorkerMessageType.ExecuteStart,
      recipient,
      data: {
        workId,
        type,
        data,
      },
    };

    return of(undefined).pipe(
      tap(() => this.crossTabChannel.sendMessage(message)),
      mergeMap(() => this.messageReceived$),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
      filter((m): m is R => (m as any).data.workId === workId),
      timeout(CrossTabWorkerService.executeTimeout),
      filter<R>(
        (m) =>
          this.isExecuteCompletedMessage<any>(m) ||
          this.isExecuteErrorMessage(m),
      ),
      take(1),
      switchMap((m) => {
        if (this.isExecuteErrorMessage(m)) {
          return throwError(() => m);
        }
        return of(m);
      }),
      catchError((e) => {
        withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtras({
            ...message,
          });
          captureException(toSentryError('CrossTabWorker::execute()', e));
        });

        const errorMessage: CrossTabExecuteErrorMessageModel = {
          data: {
            workId,
            type: message.data!.type,
            data:
              e instanceof TimeoutError
                ? 'Communication Timeout'
                : JSON.stringify(e),
          },
          instanceId: message.recipient,
          type: CrossTabWorkerMessageType.ExecuteError,
          recipient: message.instanceId,
        };
        return throwError(() => errorMessage);
      }),
    );
  }

  executeForAll<Data, R extends CrossTabExecuteCompletedMessageModel<any>>(
    type: string,
    data: Data,
  ): Observable<(R | CrossTabExecuteErrorMessageModel)[]> {
    this.crossTabChannel.purge();

    const instances = this.crossTabChannel.activeInstances.filter(
      (instance) => instance.instanceId !== this.appInstanceId,
    );

    const observables = instances.map((instance) =>
      this.execute<Data, R>(instance.instanceId, type, data).pipe(
        catchError((error: CrossTabExecuteErrorMessageModel) => of(error)),
      ),
    );

    return observables.length === 0 ? EMPTY : forkJoin(observables);
  }

  private startWorkOnWorker<Data, Result>(
    message: CrossTabExecuteStartMessageModel<Data>,
    worker: (
      instanceId: string,
      data: Data,
    ) => Promise<Result> | Observable<Result> | Result,
  ): void {
    this.sendExecuteReceivedMessage(message);

    const resultObj = worker(message.instanceId, message.data!.data!);

    let result$: Observable<Result | undefined>;

    if (typeof resultObj === 'undefined') {
      result$ = of(undefined);
    } else if (isObservable(resultObj)) {
      result$ = resultObj;
    } else if (resultObj instanceof Promise) {
      result$ = from(resultObj);
    } else {
      result$ = of(resultObj);
    }

    result$ = result$.pipe(
      take(1),
      untilNgDestroyed(this.destroyRef),
      takeUntil(
        this.crossTabChannel.instanceDisconnected$.pipe(
          filter((instance) => instance.instanceId === message.instanceId),
          tap(() => {
            withScope((scope) => {
              scope.setLevel('warning');
              scope.setExtras({
                ...message,
              });
              scope.setFingerprint([
                'InstanceDisconnected',
                'CrossTabWorker::startWorkOnWorker()',
              ]);
              captureException(
                toSentryError(
                  'CrossTabWorker::startWorkOnWorker()',
                  new Error('RecipientInstanceDisconnected'),
                ),
              );
            });
          }),
        ),
      ),
      share(),
    );

    this.keepAliveInterval$.pipe(takeUntil(result$)).subscribe(() => {
      this.sendExecuteKeepAliveMessage(message);
    });

    let resultReported = false;
    result$.subscribe({
      next: (result) => {
        resultReported = true;
        this.sendExecuteCompletedMessage(message, result);
      },
      error: (error) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        logger.error({ error }, 'Failed to receive cross tab worker response');

        this.sendExecuteErrorMessage(message, JSON.stringify(error));
      },
      complete: () => {
        if (!resultReported) {
          this.sendExecuteCompletedMessage(message, undefined);
        }
      },
    });
  }

  private sendExecuteReceivedMessage<Data>(
    receivedMessage: CrossTabExecuteStartMessageModel<Data>,
  ): void {
    const message: CrossTabExecuteReceivedMessageModel = {
      instanceId: this.appInstanceId,
      type: CrossTabWorkerMessageType.ExecuteReceived,
      recipient: receivedMessage.instanceId,
      data: {
        workId: receivedMessage.data!.workId,
        type: receivedMessage.data!.type,
      },
    };

    this.crossTabChannel.sendMessage(message);
  }

  private sendExecuteCompletedMessage<Data, Result>(
    receivedMessage: CrossTabExecuteStartMessageModel<Data>,
    data: Result,
  ): void {
    const message: CrossTabExecuteCompletedMessageModel<Result> = {
      instanceId: this.appInstanceId,
      type: CrossTabWorkerMessageType.ExecuteCompleted,
      recipient: receivedMessage.instanceId,
      data: {
        workId: receivedMessage.data!.workId,
        type: receivedMessage.data!.type,
        data,
      },
    };

    this.crossTabChannel.sendMessage(message);
  }

  private sendExecuteErrorMessage<Data>(
    receivedMessage: CrossTabExecuteStartMessageModel<Data>,
    reason: string,
  ): void {
    const message: CrossTabExecuteErrorMessageModel = {
      instanceId: this.appInstanceId,
      type: CrossTabWorkerMessageType.ExecuteError,
      recipient: receivedMessage.instanceId,
      data: {
        workId: receivedMessage.data!.workId,
        type: receivedMessage.data!.type,
        data: reason,
      },
    };

    this.crossTabChannel.sendMessage(message);
  }

  private sendExecuteKeepAliveMessage<Data>(
    receivedMessage: CrossTabExecuteStartMessageModel<Data>,
  ): void {
    const message: CrossTabExecuteKeepAliveMessageModel = {
      instanceId: this.appInstanceId,
      type: CrossTabWorkerMessageType.ExecuteKeepAlive,
      recipient: receivedMessage.instanceId,
      data: {
        workId: receivedMessage.data!.workId,
        type: receivedMessage.data!.type,
      },
    };

    this.crossTabChannel.sendMessage(message);
  }

  private isExecuteMessage(
    message: CrossTabMessageModel<any, string>,
  ): message is CrossTabExecuteMessageModel<any, string> {
    return (
      message &&
      (message.type === CrossTabWorkerMessageType.ExecuteStart ||
        message.type === CrossTabWorkerMessageType.ExecuteCompleted ||
        message.type === CrossTabWorkerMessageType.ExecuteKeepAlive ||
        message.type === CrossTabWorkerMessageType.ExecuteError ||
        message.type === CrossTabWorkerMessageType.ExecuteReceived)
    );
  }

  private isExecuteErrorMessage(
    message: CrossTabMessageModel<any, string>,
  ): message is CrossTabExecuteErrorMessageModel {
    return message && message.type === CrossTabWorkerMessageType.ExecuteError;
  }

  private isExecuteCompletedMessage<T>(
    message: CrossTabMessageModel<any, string>,
  ): message is CrossTabExecuteCompletedMessageModel<T> {
    return (
      message && message.type === CrossTabWorkerMessageType.ExecuteCompleted
    );
  }

  private isExecuteStartMessage<T>(
    message: CrossTabMessageModel<any, string>,
  ): message is CrossTabExecuteStartMessageModel<T> {
    return message && message.type === CrossTabWorkerMessageType.ExecuteStart;
  }
}
