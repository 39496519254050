import type { OnDestroy } from '@angular/core';
import { Injectable, inject } from '@angular/core';

import { ClientLogLevel } from '@gv/api';
import { ClientLoggerService } from '@gv/client-logger';
import { NotificationListenerService } from '@gv/notification';
import { getSync, StoreInject } from '@gv/state';
import type { VaultUploadHeartbeatDataModel } from '@gv/upload/types';
import { VaultUploadGroupState } from '@gv/upload/types';
import { toSentryError } from '@gv/utils';
import { captureException, withScope } from '@sentry/angular';
import { round } from 'lodash-es';
import { DateTime } from 'luxon';
import type { MqttClient } from 'mqtt';
import type { Subscription } from 'rxjs';
import { timer } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { UPLOADS_FEATURE_STATE, fromUploadsState } from '@gv/upload/state';

import { logger } from '../../../logger';
import { APP_CONFIG } from '../../entity/token/app.config';
import { APP_STATE } from '../../store/state/app-state';
import { UserService } from '../application/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class VideoUploadHeartbeatsService implements OnDestroy {
  private appConfig = inject(APP_CONFIG);
  private notificationListener = inject(NotificationListenerService);
  private userService = inject(UserService);
  private clientLogger = inject(ClientLoggerService);
  private store = inject(StoreInject(APP_STATE, UPLOADS_FEATURE_STATE));
  static readonly className: string = 'VideoUploadHeartbeatsService';

  private static readonly videoUploadHeartbeatsIntervalDuration: number =
    30 * 1000;

  private deviceSubscription: Subscription | undefined;

  ngOnDestroy(): void {
    this.stopVideoUploadHeartbeats();
  }

  enable(): void {
    this.stopVideoUploadHeartbeats();
    this.startVideoUploadHeartbeats();
  }

  disable(): void {
    this.stopVideoUploadHeartbeats();
  }

  private startVideoUploadHeartbeats(): void {
    const userUuid: string = this.userService.user?.uuid;

    this.destroyDeviceSubcription();

    this.deviceSubscription = timer(
      0,
      VideoUploadHeartbeatsService.videoUploadHeartbeatsIntervalDuration,
    )
      .pipe(withLatestFrom(this.notificationListener.device$))
      .subscribe(([, deviceObj]) => {
        if (!deviceObj) {
          return;
        }

        this.processVaultHeartbeats(
          deviceObj.device,
          deviceObj.deviceConfig.vaultUploadTopic,
        );
      });

    this.sendClientLog(
      `Upload heartbeats started (user:${userUuid}).`,
      ClientLogLevel.Info,
    );
  }

  private stopVideoUploadHeartbeats(): void {
    const userUuid: string = this.userService.user?.uuid;

    this.destroyDeviceSubcription();

    this.sendClientLog(
      `Upload heartbeats stopped (user:${userUuid}).`,
      ClientLogLevel.Info,
    );
  }

  private processVaultHeartbeats(device: MqttClient, topic: string): void {
    const validStates = [
      VaultUploadGroupState.Created,
      VaultUploadGroupState.Uploading,
      VaultUploadGroupState.Failed,
    ];

    const videoUploads = [
      ...(getSync(this.store, fromUploadsState.vault.getGroups) || []),
      ...(getSync(this.store, fromUploadsState.vault.getFailedGroups) || []),
    ].filter((vu) => validStates.includes(vu.state));

    const progress = getSync(this.store, fromUploadsState._getProgress) || {};

    const userUuid: string = this.userService.user?.uuid;

    const timestamp = Date.now();

    const videoUploadHeartBeatData: VaultUploadHeartbeatDataModel = {
      uploads: videoUploads.map((v) => ({
        uploadId: v.serverData?.uploadId,
        state: v.state,
        remaining: v.remainingFiles.length,
        uploading: v.uploading.map((uuid) => ({
          uuid: v.files[uuid].serverData?.fileUuid || uuid,
          progress: round(progress[uuid]?.progress, 3),
        })),
        failed: v.failed.map((uuid) => ({
          uuid: v.files[uuid].serverData?.fileUuid || uuid,
          state: v.files[uuid].state,
          reason: v.files[uuid].errorReason,
        })),
        canceled: v.canceled.length,
        remainingSize: v.remainingSize,
        totalSize: v.totalSize,
      })),
      timestamp,
      user: userUuid,
      notificationType: 'VAULT_HEARTBEAT',
    };

    const message: string = JSON.stringify(videoUploadHeartBeatData);

    if (videoUploadHeartBeatData.uploads.length === 0) {
      return;
    }

    this.sendClientLog(
      `Processing vault heartbeats (user:${userUuid}) (data:${message}).`,
      ClientLogLevel.Info,
    );

    device.publish(
      topic,
      message,
      {
        qos: 1,
      },
      (error) => {
        if (error) {
          withScope((scope) => {
            scope.setLevel('error');
            scope.setTag(
              'subMessage',
              'Failed to send vault upload heartbeats.',
            );
            scope.setExtra('data', videoUploadHeartBeatData);
            captureException(
              toSentryError(
                `${VideoUploadHeartbeatsService.className}::${this.processVaultHeartbeats.name}()`,
                error,
              ),
            );
          });

          logger.error({ error }, 'Failed to send vault upload heartbeats.');
        }
      },
    );
  }

  private sendClientLog(
    message: string,
    clientLogLevel: ClientLogLevel,
  ): Promise<boolean> {
    return this.clientLogger
      .sendClientLog(
        {
          datetime: DateTime.now().toISO(),
          level: clientLogLevel,
          message,
        },
        false,
      )
      .then(
        () => true,
        () => false,
      );
  }

  private destroyDeviceSubcription(): void {
    if (this.deviceSubscription) {
      this.deviceSubscription.unsubscribe();
    }
  }
}
