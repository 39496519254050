import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';

import { UploadFile } from '@gv/upload/types';
import { BrowserUtils, generateUuid } from '@gv/utils';
import { from, Observable, Subject } from 'rxjs';

import { HashUtils } from './hash/hash-utils';
import type {
  HashWorkerTaskModel,
  HashWorkerTaskResponseModel,
} from './entity';

@Injectable({
  providedIn: 'root',
})
export class HashWorkerService implements OnDestroy {
  private worker: Worker =
    // options cannot be spread to multiple lines, as it's wrongly compiled to: { , } as `type` is removed during compilation
    // prettier-ignore
    typeof Worker === 'undefined' || BrowserUtils.isOldEdge
      ? undefined
      : new Worker(new URL('./hash/hash-worker.worker.ts', import.meta.url), { type: 'module' });

  private messagesSubject = new Subject<HashWorkerTaskResponseModel>();

  private messages$: Observable<HashWorkerTaskResponseModel> =
    this.messagesSubject.asObservable();

  constructor() {
    if (this.worker) {
      this.worker.onmessage = ({ data }) => {
        this.messagesSubject.next(data);
      };
    }
  }

  ngOnDestroy(): void {
    if (this.worker) {
      this.worker.terminate();
    }
  }

  calculateHash(
    file: File | UploadFile,
    chunkSize: number,
  ): Observable<string> {
    if (file instanceof UploadFile) {
      return from(file.hash(chunkSize));
    }

    if (this.worker) {
      const uuid = generateUuid();

      return new Observable((obs) => {
        let completed = false;
        const sub = this.messages$.subscribe({
          next(message) {
            if (message.uuid === uuid) {
              completed = true;
              if (message.error) {
                obs.error(message.error);
              } else {
                obs.next(message.data?.hash);
                obs.complete();
              }
            }
          },
          error(error) {
            completed = true;
            obs.error(error);
          },
          complete() {
            completed = true;
            obs.complete();
          },
        });

        this.postMessage({ uuid, file, chunkSize });

        return () => {
          if (!completed) {
            this.postMessage({ uuid, action: 'ABORT' });
          }
          sub.unsubscribe();
        };
      });
    }

    return HashUtils.calculateHash(file, chunkSize);
  }

  private postMessage(message: HashWorkerTaskModel): void {
    if (this.worker) {
      this.worker.postMessage(message);
    }
  }
}
