import { _window } from '../service';

export class SlugHashUtils {
  static getLogoUrl(url: string): string {
    return url?.replace('{slugHash}', _window().slugHash || 'undefined'); // undefined will be replaced with cloudfront function if needed
  }

  static getSquareLogoUrl(): string {
    const url = '/cdn/organization/{slugHash}/logo-square.png';
    return SlugHashUtils.getLogoUrl(url);
  }

  static getMainLogoUrl(): string {
    const url = '/cdn/organization/{slugHash}/logo-main.png';
    return SlugHashUtils.getLogoUrl(url);
  }

  static hasSlug(): boolean {
    return !!_window().slugHash || document.cookie.indexOf('domain-slug=') >= 0;
  }
}
