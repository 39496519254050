import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AsyncPipe } from '@angular/common';

import { StoreInject } from '@gv/state';
import { UserActions } from '@gv/user';
import { untilNgDestroyed } from '@gv/utils';
import { select } from '@ngrx/store';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  ButtonSubmittingTextDirective,
  ButtonComponent,
  ScrollDirective,
} from '@gv/ui/utils';
import {
  DialogActionsComponent,
  DialogContentComponent,
  DialogHeaderComponent,
  DialogBaseComponent,
} from '@gv/ui/dialog';
import { TestIdModule } from '@gv/debug';

import { APP_STATE } from '../../../../store/state/app-state';
import { fromUserState } from '../../../../store/selector/user-selector';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-logout-everywhere-dialog-component',
  templateUrl: './logout-everywhere-dialog.component.html',
  standalone: true,
  imports: [
    DialogBaseComponent,
    DialogHeaderComponent,
    ScrollDirective,
    DialogContentComponent,
    DialogActionsComponent,
    ButtonComponent,
    ButtonSubmittingTextDirective,
    AsyncPipe,
    TestIdModule,
  ],
})
export class LogoutEverywhereDialogComponent implements OnDestroy, OnInit {
  private dialogRef = inject(
    MatDialogRef<LogoutEverywhereDialogComponent, boolean>,
  );
  private store = inject(StoreInject(APP_STATE));
  readonly loggingOut$: Observable<boolean> = this.store.pipe(
    select(fromUserState.getLoggingOutEverywhere),
    distinctUntilChanged(),
    untilNgDestroyed(),
  );

  private submittingSubject = new BehaviorSubject<boolean>(false);
  get submitting(): boolean {
    return this.submittingSubject.getValue();
  }

  ngOnDestroy(): void {
    //
  }

  ngOnInit(): void {
    this.loggingOut$.subscribe((loggingOutEverywhere) => {
      this.dialogRef.disableClose = loggingOutEverywhere;
    });
  }

  cancel(): void {
    this.store.dispatch(UserActions.logoutEverywhere.cancel());
  }

  confirm(): void {
    this.store.dispatch(UserActions.logoutEverywhere.start());
  }

  startSubmitting(): void {
    this.submittingSubject.next(true);
  }

  stopSubmitting(): void {
    this.submittingSubject.next(false);
  }
}
