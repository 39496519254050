import type { InjectionToken, Signal } from '@angular/core';

import { isArray as _isArray, isString as _isString } from 'lodash-es';

import { ObjectUtils } from './object-utils';

export function isUndefinedOrNull<T>(
  value: T | undefined | null,
): value is undefined | null {
  return value === undefined || value === null;
}

export function isObject(obj: any): obj is Record<string, unknown> {
  return !!obj && typeof obj === 'object';
}

export function isString(value: any): value is string {
  return !!value && _isString(value);
}

export function isArray<T>(obj: T | T[] | readonly T[]): obj is readonly T[] {
  return obj && _isArray(obj);
}

export function isErrorLikeObject(obj: any): obj is { message: string } {
  return isObject(obj) && ObjectUtils.hasProperty<any, string>(obj, 'message');
}

export type InferArrayElement<T> =
  T extends Array<infer J> ? J : T extends ReadonlyArray<infer J> ? J : never;

export type InferSignalType<T> = T extends Signal<infer J> ? J : never;

export type InferInjectionTokenType<T> =
  T extends InjectionToken<infer J> ? J : never;
