import type { OnInit } from '@angular/core';
import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  InjectionToken,
  Input,
  Output,
  DestroyRef,
} from '@angular/core';
import { NgForm } from '@angular/forms';

import { untilNgDestroyed } from '@gv/utils';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';

import { FormUtils } from '../../utils';

interface ButtonInterface {
  btnClick: Subject<Event>;
}

export const BUTTON = new InjectionToken<ButtonInterface>('submitButton');

@Directive({
  selector: '[submitIfValid]',
  standalone: true,
})
export class SubmitIfValidDirective implements OnInit {
  private destroyRef = inject(DestroyRef);
  private appButton = inject(BUTTON, { optional: true });
  private clickSubject = new Subject<void>();

  private onClick$: Observable<void | Event> =
    this.appButton?.btnClick.asObservable() ?? this.clickSubject.asObservable();

  @Output()
  valid = new EventEmitter<void>();

  @Input()
  disabled = false;

  @Input()
  form!: NgForm;

  @HostBinding('class.soft-disabled')
  get disabledClass(): boolean {
    return (this.form && this.form.invalid) || this.disabled;
  }

  ngOnInit(): void {
    this.onClick$.pipe(untilNgDestroyed(this.destroyRef)).subscribe(() => {
      FormUtils.forceRevalidation(this.form);

      if (!this.form || !this.form.invalid) {
        this.valid.emit();
      }
    });
  }

  @HostListener('click', ['$event'])
  click(event: Event): void {
    event.preventDefault();

    if (this.disabled) {
      event.stopPropagation();
      return;
    }

    this.clickSubject.next();
  }
}
