import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';

import {
  AnalyticsCategories,
  AnalyticsHelpActions,
  AngularticsActions,
} from '@gv/analytics';
import { EMPTY_STATE, StoreInject } from '@gv/state';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'gv-link-to-helpdesk',
  templateUrl: './link-to-helpdesk.component.html',
  standalone: true,
  imports: [],
})
export class LinkToHelpdeskComponent {
  private store = inject(StoreInject(EMPTY_STATE));
  @Input()
  class: string;

  @Input()
  link: string;

  @Input()
  target?: string = '_blank';

  @Input()
  linkText: string;

  @Input()
  strongText?: boolean = false;

  // send event to Google Analytics
  sendEventToGA(): void {
    this.store.dispatch(
      AngularticsActions.event({
        category: AnalyticsCategories.Help,
        action: AnalyticsHelpActions.HelpdeskOpen,
        options: {
          label: this.link,
        },
      }),
    );
  }
}
