<div class="h-full w-full">
  <div class="brand-logo-container">
    <img
      class="brand-logo"
      [src]="logo$ | async"
      i18n-alt="@@mobile-device-detected.gv-logo"
      alt="GoodVision logo"
    />
    <div>
      <h1 class="title" i18n="@@mobile-device-detected.user-experience">
        For the best user experience, access the
        <span class="text-color-primary good font-bold">Good</span>
        platform from a
        <span class="text-color-primary font-bold">tablet</span>
        or
        <span class="text-color-primary font-bold">desktop</span>
        device.
      </h1>
      <br />
      <div class="video-insights-dashboard">
        <img
          class="mobile-uda-gif"
          src="./assets/images/mobile-uda.gif"
          i18n-alt="@@mobile-device-detected.gv-scene"
          alt="GoodVision scene"
        />
        <div
          class="video-insight-dashboard-text"
          i18n="@@mobile-device-detected.insight-dashboard"
        >
          <span class="text-color-primary font-bold">Video</span>
          Insights dashboard
        </div>
      </div>
    </div>
  </div>
</div>
