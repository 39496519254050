import { inject, Injectable } from '@angular/core';

import { CHECK_OUTAGE } from '@gv/api';
import { ElectronRefService } from '@gv/desktop/core';
import { getDomainRedirectForUrl } from '@gv/domain';
import { NotificationsService } from '@gv/notification';
import { USER_CONTEXT } from '@gv/user';
import { simpleSwitchMap } from '@gv/utils';
import { createEffect } from '@ngrx/effects';
import { merge, timer, concatMap, from, tap, catchError } from 'rxjs';

@Injectable()
export class OutageEffects {
  private notificationsService = inject(NotificationsService);
  private checkOutagePage$ = inject(CHECK_OUTAGE);
  private userContext = inject(USER_CONTEXT);
  private electronRef = inject(ElectronRefService, { optional: true });

  onMaintenance$ = createEffect(
    () =>
      merge(
        this.checkOutagePage$,
        this.userContext.userUuid$.pipe(
          simpleSwitchMap(
            (uuid) =>
              this.notificationsService.ofType('MAINTENANCE_NOTIFICATION', {
                userUuid: uuid,
              }),
            timer(60_000, 60_000),
          ),
        ),
      ).pipe(
        concatMap(() =>
          from(
            getDomainRedirectForUrl(
              normalizeMaintenanceUrl(document.location.href, this.electronRef),
            ),
          ),
        ),
        tap((domain) => {
          if (domain) {
            const url = new URL(domain);
            if (this.electronRef) {
              url.searchParams.delete('b');
              url.searchParams.set('b', document.location.href);
            }
            document.location = url.href;
          }
        }),
        catchError((_error: any, caught) => {
          return caught;
        }),
      ),
    { dispatch: false },
  );
}

function normalizeMaintenanceUrl(
  url: string,
  electronRef: ElectronRefService | null,
): string {
  if (electronRef) {
    return new URL(electronRef.apiUrl).origin;
  }

  const u = new URL(url);

  if (u.hostname === 'localhost' || u.hostname === '127.0.0.1') {
    u.hostname = 'my.dev.gvl.io';
    u.port = '';
  }

  return u.href;
}
