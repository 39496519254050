import { DecimalPipe } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { Angulartics2Module } from 'angulartics2';
import { IntercomConfig, IntercomModule } from '@gv/ui/help';
import { WindowRefService } from '@gv/ui/core';
import { LocalizationUtils } from '@gv/utils';

import { environment } from '../../../environments/environment';
import { intercomConfigFactory } from '../../entity/factory/intercom-config-factory';

@NgModule({
  imports: [
    // angular
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ...(!environment.test ? [HttpClientModule, HttpClientJsonpModule] : []),
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.2.js', {
      enabled: environment.serviceWorker,
      scope: LocalizationUtils.getServiceWorkerContext(),
      registrationStrategy: 'registerWithDelay:6000',
    }),

    // 3rd parties
    Angulartics2Module.forRoot({
      pageTracking: {
        clearIds: true,
        clearQueryParams: true,
      },
    }),
    IntercomModule.forRoot({ appId: undefined }),
  ],
  providers: [
    DecimalPipe,
    {
      provide: IntercomConfig,
      deps: [WindowRefService],
      useFactory: intercomConfigFactory,
    },
  ],
  exports: [
    // angular
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ...(!environment.test ? [HttpClientModule, HttpClientJsonpModule] : []),
    ReactiveFormsModule,
    ServiceWorkerModule,

    // 3rd parties
    Angulartics2Module,
  ],
})
export class CommonBaseModule {}
