import { Injectable, inject } from '@angular/core';

import { AnalyticsHelpActions, AngularticsActions } from '@gv/analytics';
import { StoreInject } from '@gv/state';
import { DETAIL_FEATURE_STATE, getPlatformPlanSubState } from '@gv/ui/billing';
import { IndexedDBService } from '@gv/ui/core';
import {
  delayedConcatMap,
  isObject,
  isUndefinedOrNull,
  ObjectUtils,
} from '@gv/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import type { Action } from '@ngrx/store';
import { Angulartics2 } from 'angulartics2';
import { from, of } from 'rxjs';
import { concatMap, filter, map, tap } from 'rxjs/operators';

import { logger } from '../../../logger';
import { APP_STATE } from '../state/app-state';

@Injectable()
export class AngularticsEffects {
  private angulartics2 = inject(Angulartics2);
  private actions$ = inject<Actions>(Actions);
  private store = inject(StoreInject(APP_STATE, DETAIL_FEATURE_STATE));
  private indexedDbService = inject(IndexedDBService);
  private static rateLimitInterval = 4 * 60 * 60 * 1000;

  private stringifyReplacer = (_key: string, value: any) => {
    if (!isUndefinedOrNull(value)) {
      return value instanceof Object && !(value instanceof Array)
        ? Object.keys(value)
            .sort()
            .reduce((sorted, key) => {
              sorted[key] = value[key];
              return sorted;
            }, {})
        : value;
    }
  };

  private static uniqueEvents: string[] = [
    AnalyticsHelpActions.InfoIconClick,
    AnalyticsHelpActions.InfoIconHover,
  ];

  event$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AngularticsActions.event),
        delayedConcatMap(() => [this.store.select(getPlatformPlanSubState)]),
        concatMap((actionObj) => {
          const [action] = actionObj;

          if (this.isUniqueAction(action.action)) {
            return from(this.getLastSend(action)).pipe(
              map((lastSend) => [actionObj, lastSend] as const),
            );
          }

          return of([actionObj, undefined] as const);
        }),
        filter(
          ([, lastSend]) =>
            !lastSend ||
            lastSend.getTime() <
              new Date().getTime() - AngularticsEffects.rateLimitInterval,
        ),
        tap(([[action, platformPlan]]) => {
          if (this.isUniqueAction(action.action)) {
            this.updateLastSend(action);
          }

          this.angulartics2.eventTrack.next({
            action: action.action,
            properties: {
              label: this.formatLabel(action.options.label),
              value: action.options.value,
              category: action.category,
              gtmCustom: {
                price: action.options.price,
                platformPlan:
                  action.options.platformPlan ||
                  (platformPlan && platformPlan.name),
                size: action.options.size,
                vaultPlan: action.options.vaultPlan, //vaultSize, TODO: projects vault size
                credits: action.options.credits,
              },
            },
          });
        }),
      ),
    { dispatch: false },
  );

  private isUniqueAction(action: string): boolean {
    return AngularticsEffects.uniqueEvents.includes(action);
  }

  private getLastSend(action: Action): Promise<Date | null> {
    return this.indexedDbService
      .getConnection()
      .then((connection) =>
        connection.get('angulartics', this.stringifyAction(action)),
      )
      .catch((e) => {
        logger.error({ error: e }, 'Failed to get db entry');

        return null;
      });
  }

  private updateLastSend(action: Action): Promise<Date> {
    return this.indexedDbService
      .getConnection()
      .then((connection) =>
        connection.put('angulartics', new Date(), this.stringifyAction(action)),
      )
      .catch((e) => {
        logger.error({ e }, 'Failed to update entry in db');

        return null;
      });
  }

  private stringifyAction(action: Action): string {
    return JSON.stringify(action, this.stringifyReplacer);
  }

  private formatLabel(label: any): string {
    if (!label) {
      return label;
    }

    if (!isObject(label)) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      return '' + label;
    }

    const keys = Object.keys(label)
      .filter((key) => ObjectUtils.hasProperty(label, key))
      .sort();

    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    return keys.map((key) => '' + (label[key] as string)).join(' | ');
  }
}
