import type { EventEmitter } from '@angular/core';
import { Injectable, ChangeDetectorRef, inject } from '@angular/core';
import type {
  FormRecord,
  FormArray,
  FormControlState,
  AbstractControlOptions,
  ValidatorFn,
  AsyncValidatorFn,
  AbstractControl,
  FormControlStatus,
  ValidationErrors,
} from '@angular/forms';
import { FormGroup, FormControl } from '@angular/forms';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { z } from 'zod';
import { ObjectUtils, isString } from '@gv/utils';
import type { Observable } from 'rxjs';
import { defaultIfEmpty, firstValueFrom } from 'rxjs';
import { isEqual } from 'lodash-es';

import type { ZodValidator } from '../validator';
import { createZodValidator, removeFieldFromSchema } from '../validator';
import errorMap from './zod-errors';

type ValidatorConfig =
  | ValidatorFn
  | AsyncValidatorFn
  | ValidatorFn[]
  | AsyncValidatorFn[];
type PermissiveControlConfig<T> = Array<
  T | FormControlState<T> | ValidatorConfig
>;
interface PermissiveAbstractControlOptions
  extends Omit<AbstractControlOptions, 'updateOn'> {
  updateOn?: string;
}
export type ɵElement<T, N extends null> =
  // The `extends` checks are wrapped in arrays in order to prevent TypeScript from applying type unions
  // through the distributive conditional type. This is the officially recommended solution:
  // https://www.typescriptlang.org/docs/handbook/2/conditional-types.html#distributive-conditional-types
  //
  // Identify FormControl container types.
  [T] extends [FormControl<infer U>]
    ? FormControl<U>
    : // Or FormControl containers that are optional in their parent group.
      [T] extends [FormControl<infer U> | undefined]
      ? FormControl<U>
      : // FormGroup containers.
        [T] extends [FormGroup<infer U>]
        ? FormGroup<U>
        : // Optional FormGroup containers.
          [T] extends [FormGroup<infer U> | undefined]
          ? FormGroup<U>
          : // FormRecord containers.
            [T] extends [FormRecord<infer U>]
            ? FormRecord<U>
            : // Optional FormRecord containers.
              [T] extends [FormRecord<infer U> | undefined]
              ? FormRecord<U>
              : // FormArray containers.
                [T] extends [FormArray<infer U>]
                ? FormArray<U>
                : // Optional FormArray containers.
                  [T] extends [FormArray<infer U> | undefined]
                  ? FormArray<U>
                  : // Otherwise unknown AbstractControl containers.
                    [T] extends [AbstractControl<infer U>]
                    ? AbstractControl<U>
                    : // Optional AbstractControl containers.
                      [T] extends [AbstractControl<infer U> | undefined]
                      ? AbstractControl<U>
                      : // FormControlState object container, which produces a nullable control.
                        [T] extends [FormControlState<infer U>]
                        ? FormControl<U | N>
                        : // A ControlConfig tuple, which produces a nullable control.
                          [T] extends [PermissiveControlConfig<infer U>]
                          ? FormControl<
                              | Exclude<
                                  U,
                                  | ValidatorConfig
                                  | PermissiveAbstractControlOptions
                                >
                              | N
                            >
                          : FormControl<T | N>;

export type _FormGroup<T> = FormGroup<{
  [K in keyof T]: ɵElement<T[K], never>;
}>;

export class GVFormGroup extends FormGroup {
  private cdk = inject(ChangeDetectorRef);
  _validator: any;

  override setErrors(
    errors: ValidationErrors | null,
    opts?: { emitEvent?: boolean | undefined } | undefined,
  ): void {
    super.setErrors(errors, opts);
    this.cdk.markForCheck();
    // this.cdk.detectChanges();
  }
  // // we need to override this to run async validator always, also in case of errors
  override updateValueAndValidity(
    opts: {
      onlySelf?: boolean | undefined;
      emitEvent?: boolean | undefined;
    } = {},
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    (this as any)._setInitialStatus();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    (this as any)._updateValue();

    if (this.enabled) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      (this as any)._cancelExistingSubscription();
      // reset all zod errors from child controls
      Object.values(this.controls).forEach((control) => {
        if (control.errors?.zodError) {
          const errors = ObjectUtils.withoutUndefined({
            ...(control.errors || {}),
            zodError: undefined,
          }) as Record<string, any>;
          control.setErrors(Object.keys(errors).length === 0 ? null : errors, {
            emitEvent: false,
          });
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      (this as { errors: ValidationErrors | null }).errors = (this as any)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        ._runValidator();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      (this as { status: FormControlStatus }).status = (this as any)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        ._calculateStatus();

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      (this as any)._runAsyncValidator(opts.emitEvent);
    }

    if (opts.emitEvent !== false) {
      (this.valueChanges as EventEmitter<any>).emit(this.value);
      (this.statusChanges as EventEmitter<FormControlStatus>).emit(this.status);
    }

    if (this.parent && !opts.onlySelf) {
      this.parent.updateValueAndValidity(opts);
    }
  }

  override setValue(
    value: { [key: string]: any },
    options?:
      | { onlySelf?: boolean | undefined; emitEvent?: boolean | undefined }
      | undefined,
  ): void {
    Object.keys(value).forEach((name) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (!isEqual((this.controls as any)[name].value, (value as any)[name])) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        (this.controls as any)[name].setValue((value as any)[name], {
          onlySelf: true,
          emitEvent: options?.emitEvent,
        });
      }
    });
    this.updateValueAndValidity(options);
  }

  override patchValue(
    value: { [key: string]: any },
    options?:
      | { onlySelf?: boolean | undefined; emitEvent?: boolean | undefined }
      | undefined,
  ): void {
    Object.keys(value).forEach((name) => {
      if (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (this.controls as any)[name] &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        !isEqual((this.controls as any)[name].value, (value as any)[name])
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        (this.controls as any)[name].setValue((value as any)[name], {
          onlySelf: true,
          emitEvent: options?.emitEvent,
        });
      }
    });
    this.updateValueAndValidity(options);
  }
}

// not allow white space at the beggining and end of the string (need trim() before)
const whiteSpaceRegex = /^[^\s]+(\s+[^\s]+)*$/;
const disallowHtmlRegex = /^(?:(?!<\/?[^>]+>)[\s\S])*$/;

export const gvZ = {
  ...z,
  nativeString: z.string,

  string: () =>
    z
      .string()
      .max(255)
      .trim()
      .regex(
        whiteSpaceRegex,
        $localize`:@@form-builder.input-required:Input required`,
      )
      .regex(
        disallowHtmlRegex,
        $localize`:@@form-builder.invalid-input:Invalid input`,
      ),
  optionalString: () =>
    z
      .string()
      .max(255)
      .regex(
        disallowHtmlRegex,
        $localize`:@@form-builder.invalid-input:Invalid input`,
      )
      .nullish(),
  boundlessString: () =>
    z
      .string()
      .regex(
        disallowHtmlRegex,
        $localize`:@@form-builder.invalid-input:Invalid input`,
      ),
  email: () =>
    z
      .string()
      .max(255)
      .trim()
      .regex(
        whiteSpaceRegex,
        $localize`:@@form-builder.input-required:Input required`,
      )
      .regex(
        disallowHtmlRegex,
        $localize`:@@form-builder.invalid-input:Invalid input`,
      )
      .email(),
  optionalEmail: () =>
    z
      .string()
      .max(255)
      .regex(
        disallowHtmlRegex,
        $localize`:@@form-builder.invalid-input:Invalid input`,
      )
      .email()
      .or(z.literal('')),

  duplicitNameString: (
    objects: Observable<readonly ({ name: string; uuid?: string } | string)[]>,
    object: string | { msg: string },
    uuidCheck?: string,
    valueMapper?: (val: string) => string,
    originalValue?: string,
  ) =>
    z
      .string()
      .max(255)
      .trim()
      .regex(
        whiteSpaceRegex,
        $localize`:@@form-builder.input-required:Input required`,
      )
      .regex(
        disallowHtmlRegex,
        $localize`:@@form-builder.invalid-input:Invalid input`,
      )
      .refine(
        async (val) => {
          if (originalValue && originalValue === val) {
            return Promise.resolve(null);
          }
          const values = await firstValueFrom(objects.pipe(defaultIfEmpty([])));
          const value = (valueMapper ? valueMapper(val) : val)
            .trim()
            .toLowerCase();
          const result = values?.some((o) => {
            const object = o as { name: string; uuid?: string };
            const objString = o as string;
            return uuidCheck
              ? object?.uuid !== uuidCheck &&
                  (object?.name ?? objString).trim().toLowerCase() === value
              : (object?.name ?? objString).trim().toLowerCase() === value;
          });
          return result ? Promise.resolve(null) : Promise.resolve(true);
        },
        isString(object)
          ? $localize`:@@form-builder.duplicit-name:${object} with the same name already exists`
          : object.msg,
      ),
};

@Injectable({ providedIn: 'root' })
export class FormBuilder {
  constructor() {
    z.setErrorMap(errorMap);
  }

  group<T extends z.ZodType>(
    type: T,
    initial: z.infer<typeof type>,
    options?: Pick<AbstractControlOptions, 'updateOn'>,
  ): _FormGroup<z.infer<T>> {
    const createdControls: { [key: string]: AbstractControl } = {};
    ObjectUtils.keys(initial).forEach((controlName) => {
      createdControls[controlName as string] = new FormControl(
        initial[controlName],
      );
    });

    const validator = createZodValidator(type);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment
    const group = new GVFormGroup(createdControls, {
      ...options,
      asyncValidators: validator,
    }) as any;
    Object.assign(validator, { type, _validator: validator });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return group;
  }

  addControl<T extends z.ZodType>(
    group: FormGroup,
    type: T,
    initial: z.infer<typeof type>,
    options?: Pick<AbstractControlOptions, 'updateOn'>,
  ): _FormGroup<z.infer<T>> {
    const controlName = ObjectUtils.keys(initial)[0];
    const control: AbstractControl = new FormControl(initial[controlName], {
      ...options,
    });
    group.addControl(controlName.toString(), control);
    let newType: z.ZodTypeAny = type;
    if ('_validator' in group) {
      const validator = group._validator as ZodValidator;
      if (group.hasAsyncValidator(validator)) {
        group.removeAsyncValidators(validator);
      }

      newType = z.intersection(validator.type, type);
    }
    const validator = createZodValidator(newType);
    group.addAsyncValidators(validator);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    (group as any)._validator = validator;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return group;
  }

  removeControl<T>(group: _FormGroup<T>, controlName: string): _FormGroup<T> {
    if ('_validator' in group) {
      const validator = group._validator as ZodValidator;
      validator.type = removeFieldFromSchema(validator.type, [controlName]);
    }

    group.removeControl(controlName as any);
    return group;
  }
}
