@if (browserType.isElectron === false) {
  <app-unsupported-browser-snack-bar
    [showMobileDetected]="showMobileDetected"
  ></app-unsupported-browser-snack-bar>
}
<span
  [ngClass]="
    (cookiesNeedsToBeConfirmed$ | async)
      ? 'cookies-bar-shown'
      : 'cookies-bar-not-shown'
  "
>
  @if (browserType.isElectron === false) {
    <app-cookie-law-snack-bar></app-cookie-law-snack-bar>
  }
  @if (!showMobileDetected) {
    <div
      class="h-full"
      [ngClass]="{
        dark: (dark$ | async) === true
      }"
    >
      <router-outlet></router-outlet>
    </div>
  }
</span>
@if (showMobileDetected) {
  <app-mobile-device-detected />
}
<gv-toaster-container />
