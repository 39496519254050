import { inject } from '@angular/core';

import type { MixPanelContext } from '@gv/analytics';
import type { UserCardPlatformPlanDTO } from '@gv/api';
import { AuthenticationTokenType } from '@gv/api';
import { StoreInject } from '@gv/state';
import {
  BillingActions,
  BILLING_FEATURE_STATE,
  detail,
  DETAIL_FEATURE_STATE,
  fromDetail,
  fromOrganization,
} from '@gv/ui/billing';
import type { ObservedValueOf } from 'rxjs';
import {
  NEVER,
  distinctUntilChanged,
  switchMap,
  Observable,
  combineLatest,
  shareReplay,
  map,
  defer,
} from 'rxjs';

import { TokenStoreService } from '../../service/application/user/token-store.service';
import { UserService } from '../../service/application/user/user.service';

export const mixPanelFactory = (): MixPanelContext => {
  const userService = inject(UserService);
  const tokenStore = inject(TokenStoreService);
  const store = inject(
    StoreInject(DETAIL_FEATURE_STATE, BILLING_FEATURE_STATE),
  );

  function mapPlan(
    plan: UserCardPlatformPlanDTO,
  ): ObservedValueOf<MixPanelContext['userInfo$']>['plan'] {
    if (!plan) {
      return undefined;
    }
    if (plan.trial) {
      return 'trial';
    }
    if (/modeller/i.test(plan.name)) {
      return 'modeller';
    }
    if (/surveyor/i.test(plan.name)) {
      return 'surveyor';
    }
    if (/enterprise/i.test(plan.name)) {
      return 'enterprise';
    }
    return undefined;
  }

  const observeOrg = userService.user$.pipe(
    map((user) => user?.hasOrganization ?? false),
    distinctUntilChanged(),
    switchMap((hasOrg) =>
      !hasOrg
        ? NEVER
        : new Observable<never>(() => {
            store.dispatch(BillingActions.organization.watching.acquire());
            store.dispatch(
              BillingActions.organization.fetch.init({
                debounce: true,
                skipWhenLoaded: true,
              }),
            );
            return () => {
              store.dispatch(BillingActions.organization.watching.release());
            };
          }),
    ),
  );

  const observeDetail = userService.userUuid$.pipe(
    distinctUntilChanged(),
    switchMap((userUuid) =>
      !userUuid
        ? NEVER
        : new Observable<never>(() => {
            store.dispatch(detail.watching.acquire());
            store.dispatch(
              detail.fetch.init({ debounce: true, skipWhenLoaded: true }),
            );
            return () => {
              store.dispatch(detail.watching.release());
            };
          }),
    ),
  );

  const userInfo$: MixPanelContext['userInfo$'] = defer(
    () =>
      new Observable<void>((sub) => {
        sub.add(observeDetail.subscribe());
        sub.add(observeOrg.subscribe());
        sub.next();
      }),
  ).pipe(
    switchMap(() =>
      combineLatest([
        userService.user$,
        tokenStore.decodedToken$,
        store.select(fromDetail.getDetail),
        store.select(fromOrganization.getOrganization),
      ]),
    ),
    map(([user, decodedToken, detail, organization]) => {
      if (
        !user ||
        !decodedToken ||
        !detail ||
        (user.hasOrganization && !organization)
      ) {
        return undefined;
      }
      switch (decodedToken.type) {
        case AuthenticationTokenType.Admin:
        case AuthenticationTokenType.SuperAdmin:
        case AuthenticationTokenType.Support:
          return undefined;
      }

      return {
        loginType:
          decodedToken.type === AuthenticationTokenType.Oauth
            ? ('google' as const)
            : ('user' as const),
        name: user.name,
        uuid: decodedToken.user,
        organization: organization?.name,
        orgUuid: organization?.uuid,
        plan: mapPlan(detail.platformPlan),
        vaultSize: detail.vault?.total,
        language: user.language,
      };
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
  return {
    userInfo$,
  };
};
