import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type { Draft } from 'immer';
import { produce } from 'immer';
import { isEqual } from 'lodash-es';
import { asMutable, isActionOfType, createSubStateReducerFns } from '@gv/state';
import { removeFromArray } from '@gv/utils';
import { UserActions } from '@gv/user';
import { LayoutView } from '@gv/api';

import type { LocationStateModel } from '../../entity/model/location/location-state-model';
import { LocationActions } from '../action/location.actions';
import { ProjectActions } from '../action/project.actions';
import type { ProjectState } from '../state/project.state';
import {
  initialLocationsSubState,
  initialProjectState,
  initialProjectSubState,
} from '../state/project.state';

export const projectFeatureKey = 'project';

const projectReducer = createReducer(
  initialProjectState,

  on(ProjectActions.init, (state, action) =>
    produce(state, (draft) => {
      if (state.uuid === action.uuid) {
        return;
      }

      draft.uuid = action.uuid;

      draft.project = {
        ...asMutable(initialProjectSubState),
        watching: draft.project.watching,
        highlighted: draft.project.highlighted,
      };

      draft.locations = {
        ...asMutable(initialLocationsSubState),
        watching: draft.locations.watching,
        highlighted: draft.locations.highlighted,
      };

      draft.locationsPageSettings = initialProjectState.locationsPageSettings;
    }),
  ),

  ...createSubStateReducerFns(
    ProjectActions.project,
    (state: Draft<ProjectState>) => state.project,
    (_, apiModel, dtSent) => ({ ...apiModel, dtSent }),
    $localize`:@@project.failed-to-load-project:Failed to load project`,
  ),

  ...createSubStateReducerFns(
    ProjectActions.locations,
    (state: Draft<ProjectState>) => state.locations,
    (state, locations, dtSent) =>
      locations.map(
        (p): LocationStateModel => ({
          ...p,
          projectUuid: state.uuid,
          dtSent,
        }),
      ),
    $localize`:@@project.failed-to-load-locations:Failed to load locations`,
    true,
  ),

  on(ProjectActions.locations.fetch.completed, (state, action) => {
    return produce(state, (draft) => {
      draft.locationsPageSettings.pageView =
        action.sortable.layoutView || LayoutView.Map;
      draft.locationsPageSettings.sortDirection = action.sortable.sortDirection;
      draft.locationsPageSettings.sortingBy = action.sortable.sortBy;
      draft.locationsPageSettings.mapSettings = {
        ...action.sortable.mapSettings,
        preferredZoom: action.sortable.mapSettings.mapZoom,
      };
    });
  }),

  ...createSubStateReducerFns(
    ProjectActions.projectDataSources,
    (state: Draft<ProjectState>) => state.projectDataSources,
    (state, projectDataSources) => projectDataSources,
    $localize`:@@project.failed-to-load-project-ds:Failed to load project data sources`,
    true,
  ),

  on(ProjectActions.remove, (state, action) => {
    if (state.uuid !== action.uuid) {
      return state;
    }

    return {
      ...initialProjectState,
      uuid: state.uuid,
    };
  }),

  on(ProjectActions.update, ProjectActions.add, (state, action) => {
    const uuid = isActionOfType(action, ProjectActions.update)
      ? action.uuid
      : action.project.uuid;
    if (state.uuid !== uuid || state.project.loaded === false) {
      return state;
    }

    if (
      action.dtSent &&
      state.project.freshness.getTime() >= action.dtSent.getTime()
    ) {
      return state;
    }

    return produce(state, (draft) => {
      draft.project.data = {
        ...draft.project.data,
        ...asMutable(action.project),
        defaultStorage: action.project.defaultStorage ?? undefined,
      };

      if (action.dtSent) {
        draft.project.data.dtSent = action.dtSent;
        draft.project.freshness = action.dtSent;
      }
    });
  }),

  on(LocationActions.add, (state, action) => {
    if (!state.locations.loaded || state.uuid !== action.projectUuid) {
      return state;
    }

    const alreadyPresent = action.location.uuid in state.locations.data;

    if (
      alreadyPresent &&
      (state.locations.freshness.getTime() >=
        action.location.dtSent.getTime() ||
        state.locations.data[action.location.uuid].dtSent.getTime() >=
          action.location.dtSent.getTime())
    ) {
      return state;
    }
    return produce(state, (draft) => {
      draft.locations.data[action.location.uuid] = {
        ...asMutable(action.location),
        projectUuid: state.uuid,
      };

      if (!alreadyPresent) {
        draft.locations.ids.push(action.location.uuid);
      }
    });
  }),

  on(LocationActions.remove, (state, action) => {
    if (!state.locations.loaded || state.uuid !== action.projectUuid) {
      return state;
    }

    if (!(action.uuid in state.locations.data)) {
      return state;
    }

    return produce(state, (draft) => {
      delete draft.locations.data[action.uuid];
      removeFromArray(draft.locations.ids, action.uuid);
    });
  }),

  on(LocationActions.update, (state, action) => {
    if (!state.locations.loaded || state.uuid !== action.projectUuid) {
      return state;
    }

    const alreadyPresent = action.location.uuid in state.locations.data;

    if (!alreadyPresent) {
      return state;
    }

    const dtSent = action.location.dtSent;
    if (
      dtSent &&
      (state.locations.freshness.getTime() >= dtSent.getTime() ||
        state.locations.data[action.location.uuid].dtSent.getTime() >=
          dtSent.getTime())
    ) {
      return state;
    }

    return produce(state, (draft) => {
      draft.locations.data[action.location.uuid] = {
        ...draft.locations.data[action.location.uuid],
        ...asMutable(action.location),
      };

      draft.locations.data[action.location.uuid].dtSent =
        action.location.dtSent;
    });
  }),

  on(ProjectActions.reset, UserActions.logout, () => initialProjectState),

  on(ProjectActions.setProjectPageSettings, (state, action): ProjectState => {
    if (isEqual(state.locationsPageSettings, action.settings)) {
      return state;
    }

    return {
      ...state,
      locationsPageSettings: action.settings,
    };
  }),

  on(ProjectActions.setProjectMapPreferences, (state, action): ProjectState => {
    if (isEqual(state?.locationsPageSettings?.mapSettings, action.settings)) {
      return state;
    }

    return {
      ...state,
      locationsPageSettings: {
        ...state.locationsPageSettings,
        mapSettings: action.settings,
      },
    };
  }),

  // TODO: projects
  //   on(ProjectsActions.pageSettingsUpdated, (state, action): ProjectState => {
  //     if (isEqual(state?.locationsPageSettings, action.settings)) {
  //       return state;
  //     }

  //     return {
  //       ...state,
  //       locationsPageSettings: {
  //         ...state.locationsPageSettings,
  //         // TODO: projects
  //         // pageView: action.settings.pageView || LayoutView.Map,
  //         sortingBy: action.settings.sortingBy,
  //         sortDirection: action.settings.sortDirection,
  //       },
  //     };
  //   }),
);

export function reducer(
  state: ProjectState | undefined,
  action: Action,
): ProjectState {
  return projectReducer(state, action);
}
