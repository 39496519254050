import { inject, Injectable, DestroyRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { toObservable } from '@angular/core/rxjs-interop';

import {
  isArray,
  shallowDistinctUntilChanged,
  untilNgDestroyed,
} from '@gv/utils';
import type { Observable } from 'rxjs';
import { injectQueryParams } from 'ngxtension/inject-query-params';
import {
  concatMap,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  from,
  map,
  mergeMap,
  of,
  shareReplay,
  startWith,
  take,
  tap,
} from 'rxjs';
import { INIT, ReducerManager, UPDATE } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { pipe, filter as _filter, fromKeys, pullObject } from 'remeda';

import { StoreInject } from './inject';
import { EMPTY_STATE, HIGHLIGHT_MAPPING } from '../token/store.token';
import { SimpleState } from '../simple-state';
import { HighlighterProxy } from './highlighter-proxy';

@Injectable({
  providedIn: 'root',
})
export class UrlHighlighter {
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private store = inject(StoreInject(EMPTY_STATE));
  private proxy = inject(HighlighterProxy);
  private actions = inject<Actions>(Actions);
  private reducerManager = inject(ReducerManager);
  private highlightMapping = inject(HIGHLIGHT_MAPPING);
  private queryParams = injectQueryParams();

  private state = new SimpleState(
    {
      items: <{ type: string; data: any }[]>[],
    },
    {
      add: (state, action: { type: string; data: any }) => ({
        ...state,
        items: [...state.items, action],
      }),
      remove: (state, action: { type: string }) => ({
        ...state,
        items: state.items.filter((f) => f.type !== action.type),
      }),
    },
  );

  private reducers$ = this.actions.pipe(
    ofType(UPDATE, INIT),
    startWith(undefined),
    debounceTime(0),
    map(() => Object.keys(this.reducerManager.currentReducers)),
    shallowDistinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private routeChanged$ = toObservable(this.queryParams).pipe(
    mergeMap((map) => {
      if (!('h-type' in map)) {
        return EMPTY;
      }
      const type = map['h-type'] as string;
      const config = this.highlightMapping[type];
      if (!config) {
        return EMPTY;
      }

      const data = pipe(
        Object.keys(map),
        _filter((f) => f.startsWith('h-') && f !== 'h-type'),
        pullObject(
          (v) => v.replace('h-', ''),
          (v) => map[v] as string,
        ),
      );

      if ('proxy' in config) {
        return (
          config.wait ? this.proxy.waitFor(type).pipe(take(1)) : of(undefined)
        ).pipe(
          tap(() => {
            this.proxy.emit(type, data);
          }),
          concatMap(() =>
            from(
              this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParamsHandling: 'merge',
                preserveFragment: true,
                queryParams: pipe(
                  Object.keys(map),
                  _filter((f) => f.startsWith('h-')),
                  fromKeys(() => null),
                ),
              }),
            ),
          ),
        );
      }

      const { waitFor, action, local } = config;

      return this.reducers$.pipe(
        filter((f) => !waitFor || f.includes(waitFor)),
        take(1),
        concatMap(() => {
          if (action) {
            const actions = action(data);
            for (const a of isArray(actions) ? actions : [actions]) {
              this.store.dispatch(a);
            }
          }
          if (local) {
            this.state.dispatch({ type: 'add', data: { type, data } });
          }
          return from(
            this.router.navigate([], {
              relativeTo: this.activatedRoute,
              queryParamsHandling: 'merge',
              preserveFragment: true,
              queryParams: pipe(
                Object.keys(map),
                _filter((f) => f.startsWith('h-')),
                fromKeys(() => null),
              ),
            }),
          );
        }),
      );
    }),
    untilNgDestroyed(),
  );

  constructor() {
    this.state.init(this.destroyRef);
    this.routeChanged$.subscribe();
  }

  ngOnDestroy(): void {
    //
  }

  onHighlight<T>(type: string): Observable<T> {
    return this.state.state$.pipe(
      map(
        (state): T | undefined =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          state.items.find((f) => f.type === type)?.data,
      ),
      filter((f): f is T => !!f),
      distinctUntilChanged(),
    );
  }

  hide(type: string): void {
    this.state.dispatch({
      type: 'remove',
      data: { type },
    });
  }
}
