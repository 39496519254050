import { delayedConcatMap } from '@gv/utils';
import { createEffect, ofType } from '@ngrx/effects';
import type { Action } from '@ngrx/store';
import type { Observable } from 'rxjs';
import { filter, map } from 'rxjs';

import type { Store } from '../store';
import type { BaseSubStateActions } from './create-sub-state-action';
import type {
  SubStateArraySelectors,
  SubStateBaseSelectors,
  SubStateObjectSelectors,
} from './create-sub-state-selectors';
import type {
  SubStateArrayModel,
  SubStateObjectModel,
} from './sub-state-model';

interface OptionsType {
  actions$: Observable<Action>;
  store: Store<unknown>;
}

type ExtractStateModel<T> = T extends readonly (infer J)[]
  ? J extends { readonly uuid: string }
    ? J
    : never
  : never;

export function createSubStateRefreshEffect<
  State,
  ApiModel,
  StateEntityModel,
  T extends string,
>(
  actions: BaseSubStateActions<ApiModel, StateEntityModel>,
  selectors: SubStateObjectSelectors<
    State,
    SubStateObjectModel<StateEntityModel>,
    T
  >,
  options: OptionsType,
  key: T,
): Observable<Action>;
export function createSubStateRefreshEffect<
  State,
  ApiModelList extends readonly unknown[],
  StateEntityModelList extends readonly unknown[],
  T extends string,
  FetchType,
  CompletedType,
>(
  actions: BaseSubStateActions<
    ApiModelList,
    StateEntityModelList,
    FetchType,
    CompletedType
  >,
  selectors: SubStateArraySelectors<
    State,
    ExtractStateModel<StateEntityModelList>,
    SubStateArrayModel<ExtractStateModel<StateEntityModelList>>,
    T
  >,
  options: OptionsType,
  multi: true,
  key: T,
): Observable<Action>;
export function createSubStateRefreshEffect<
  ApiModel,
  StateModel,
  StateEntityModel extends { uuid: string },
  L extends
    | SubStateObjectModel<StateEntityModel>
    | SubStateArrayModel<StateEntityModel>,
>(
  actions: BaseSubStateActions<ApiModel, StateModel>,
  selectors: SubStateBaseSelectors<unknown, StateEntityModel, L, string>,
  options: OptionsType,
  _multi?: boolean,
): Observable<Action> {
  const actions$ = options.actions$.pipe(ofType(actions.refresh));

  return createEffect(
    (): Observable<Action> =>
      actions$.pipe(
        delayedConcatMap((action) => [
          options.store.select(
            selectors.shouldRefresh({
              dtSent: action.dtSent,
            }),
          ),
        ]),
        filter(([, shouldRefresh]) => shouldRefresh !== 'none'),
        map(([action, shouldRefresh]) => {
          return shouldRefresh === 'refresh'
            ? actions.fetch.init({
                skipWhenLoaded: false,
                debounce: action.debounce,
              })
            : actions.markAsDirty();
        }),
      ),
  );
}
