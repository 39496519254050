import type { OnDestroy, OnInit } from '@angular/core';
import { Component, inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { logo$ } from '@gv/ui/core';

import { ScrollbarService } from '../../../service/application/scrollbar.service';

@Component({
  selector: 'app-mobile-device-detected',
  templateUrl: './mobile-device-detected.component.html',
  standalone: true,
  imports: [AsyncPipe],
})
export class MobileDeviceDetectedComponent implements OnDestroy, OnInit {
  private scrollbar = inject(ScrollbarService);

  readonly logo$ = logo$;

  ngOnDestroy(): void {
    this.scrollbar.isScrollbarVisible = true;
  }

  ngOnInit(): void {
    this.scrollbar.isScrollbarVisible = false;
  }
}
