import type {
  FormGroup,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';

import { removeFromArray } from '@gv/utils';
import type { Observable } from 'rxjs';
import { from, map } from 'rxjs';
import z from 'zod';

import { logger } from '../../logger';

export type ZodValidator = ((
  control: AbstractControl,
) => Observable<ValidationErrors | null>) & {
  type: z.ZodTypeAny;
};

export function createZodValidator(_type: z.ZodTypeAny): ZodValidator {
  function zodValidator(
    control: AbstractControl,
  ): Observable<ValidationErrors | null> {
    return from(
      (zodValidator as ZodValidator).type.safeParseAsync(control.value),
    ).pipe(
      map((res) => {
        const keys =
          'controls' in control ? Object.keys(control.controls as any) : [];
        if (res.success === false) {
          logger.debug(res.error.flatten(), 'validation error');
          const flattened = res.error.flatten();
          for (const [path, error] of Object.entries(flattened.fieldErrors)) {
            const childControl = (control as FormGroup).controls[path];
            if (
              'controls' in control &&
              path in (control as FormGroup).controls &&
              !childControl.disabled
            ) {
              removeFromArray(keys, path);
              childControl.setErrors({
                zodError: error?.[0] || error,
              });
            }
          }

          for (const path of keys) {
            if (
              'controls' in control &&
              path in (control as FormGroup).controls
            ) {
              (control as FormGroup).controls[path].setErrors(null);
            }
          }

          if (flattened.formErrors?.length > 0) {
            return { zodError: flattened.formErrors };
          } else {
            // do not return zodError when controls are disabled
            return null;
          }
        }
        return res.success ? null : { zodError: true };
      }),
    );
  }

  Object.assign(zodValidator, {
    type: _type,
  });

  return zodValidator as ZodValidator;
}

export function removeFieldFromSchema(
  schema: z.ZodTypeAny,
  fieldsToOmit: string[],
): z.ZodTypeAny {
  // Handle effects
  if (schema instanceof z.ZodEffects) {
    const innerProcessed = removeFieldFromSchema(
      schema._def.schema,
      fieldsToOmit,
    );
    return new z.ZodEffects({
      effect: schema._def.effect,
      schema: innerProcessed,
      typeName: schema._def.typeName,
    });
  }

  // Correctly omit fields from objects
  if (schema instanceof z.ZodObject) {
    const omitObject: { [key: string]: true } = {};
    fieldsToOmit.forEach((field) => {
      omitObject[field] = true;
    });
    return schema.omit(omitObject);
  }

  // Handle unions and intersections with correct API usage
  if (schema instanceof z.ZodUnion) {
    const newOptions = (schema.options as z.ZodTypeAny[]).map((option) =>
      removeFieldFromSchema(option, fieldsToOmit),
    );
    return z.union(newOptions as any);
  }
  if (schema instanceof z.ZodIntersection) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { left, right } = schema._def;
    const newLeft = removeFieldFromSchema(left, fieldsToOmit);
    const newRight = removeFieldFromSchema(right, fieldsToOmit);
    return z.intersection(newLeft, newRight);
  }

  // Correct handling of arrays
  if (schema instanceof z.ZodArray) {
    return z.array(removeFieldFromSchema(schema.element, fieldsToOmit));
  }

  // Correct handling of optional and nullable schemas
  if (schema instanceof z.ZodOptional) {
    return z.optional(removeFieldFromSchema(schema.unwrap(), fieldsToOmit));
  }
  if (schema instanceof z.ZodNullable) {
    return z.nullable(removeFieldFromSchema(schema.unwrap(), fieldsToOmit));
  }

  // Handle tuples
  if (schema instanceof z.ZodTuple) {
    return z.tuple(
      (schema.items as z.ZodTypeAny[]).map((item) =>
        removeFieldFromSchema(item, fieldsToOmit),
      ) as any,
    );
  }

  // Handle records correctly
  if (schema instanceof z.ZodRecord) {
    return z.record(
      removeFieldFromSchema(schema.valueSchema as z.ZodTypeAny, fieldsToOmit),
    );
  }

  // Return the schema unchanged if it doesn't require or support modifications
  return schema;
}
