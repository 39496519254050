import { inject, Injectable, NgZone } from '@angular/core';
import type { MatSnackBarConfig } from '@angular/material/snack-bar';

import { ToasterService } from '../../service';
import { ToastType } from '../../model';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  private toasterService = inject(ToasterService);
  private ngZone = inject(NgZone);

  open<Data>(
    message: string,
    action?: string,
    config?: MatSnackBarConfig<Data>,
    runInZone = true,
  ): string | undefined {
    if (runInZone) {
      return this.ngZone.run(() => {
        return this.toasterService.show({
          type: ToastType.info,
          content: message,
          closable: true,
          delay: config?.duration,
        });
      });
    }

    return this.toasterService.show({
      type: ToastType.info,
      content: message,
      closable: true,
      delay: config?.duration,
    });
  }

  openSimpleSuccess(subject: string, verb: string, duration = 2000): void {
    this.toasterService.show({
      type: ToastType.info,
      content: $localize`:@@snack-bar.simple-success-msg:${subject}:subject: was successfully ${verb}:verb:`,
      closable: true,
      delay: duration,
    });
  }

  dismiss(uuid: string, runInZone = true): void {
    if (runInZone) {
      return this.ngZone.run(() => {
        this.toasterService.close(uuid);
      });
    }
    this.toasterService.close(uuid);
  }

  dismissAll(): void {
    this.toasterService.closeAll();
  }
}
