import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ButtonModule } from '@gv/ui/utils';
import classNames from 'classnames';
import { TestIdModule } from '@gv/debug';

import { BaseSubmitActions } from './base-submit-actions.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    MatDialogModule,
    MatTooltipModule,
    TestIdModule,
  ],
  selector: 'gv-basic-dialog-button',
  template: `
    @switch (disableClose) {
      @case (true) {
        <button
          class="btn btn-dialog"
          [ngClass]="{
            'btn-warn': color === 'warn',
            'btn-green': color === 'primary'
          }"
          [disabled]="submitInProgress || disabled"
          [matTooltip]="disabledTooltip"
          [matTooltipDisabled]="!disabled"
          type="button"
          (click)="close.emit(); btnClick.emit()"
        >
          <ng-container *ngTemplateOutlet="content" />
        </button>
      }
      @case (false) {
        <button
          class="btn btn-dialog"
          [ngClass]="{
            'btn-warn': color === 'warn',
            'btn-green': color === 'primary'
          }"
          [mat-dialog-close]="dialogClose"
          [disabled]="submitInProgress || disabled"
          [matTooltip]="disabledTooltip"
          [matTooltipDisabled]="!disabled"
          type="button"
          (click)="close.emit(); btnClick.emit()"
        >
          <ng-container *ngTemplateOutlet="content" />
        </button>
      }
    }

    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  `,
})
export class BasicDialogButtonComponent extends BaseSubmitActions {
  override color: 'warn' | 'primary' | 'neutral' = 'neutral';
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    MatDialogModule,
    MatTooltipModule,
    TestIdModule,
  ],
  selector: 'gv-submit-dialog-button',
  template: `
    <app-button
      class="submit no-uppercase h-full"
      [ngClass]="classes"
      [icon]="icon"
      [disabled]="submitInProgress || disabled"
      [matTooltip]="disabledTooltip"
      [matTooltipDisabled]="!disabled"
      [submitting]="submitInProgress"
      (btnClick)="btnClick.emit()"
    >
      <ng-container>{{ text }}</ng-container>

      <ng-template appSubmittingText>{{ submitText }}</ng-template>
    </app-button>
  `,
})
export class SubmitDialogButtonComponent extends BaseSubmitActions {
  @Input({ required: true })
  submitText: string;

  @Input({ required: true })
  text: string;

  @Input()
  filled = true;

  @Input()
  icon: string | undefined;

  get classes(): string {
    return classNames(
      ...(this.btnClasses || []),
      this.filled && 'filled',
      this.color === 'warn' && 'warn',
    );
  }
}

@Component({
  standalone: true,
  imports: [CommonModule, ButtonModule, MatDialogModule, MatTooltipModule],
  selector: 'gv-secondary-submit-dialog-button',
  template: `
    <app-button
      class="dialog-outline no-uppercase h-[calc(100%_-_1px)]"
      [ngClass]="classes"
      [disabled]="submitInProgress || disabled"
      [matTooltip]="disabledTooltip"
      [matTooltipDisabled]="!disabled"
      [submitting]="submitInProgress"
      (btnClick)="btnClick.emit()"
    >
      <ng-container>{{ text }}</ng-container>

      <ng-template appSubmittingText>{{ submitText }}</ng-template>
    </app-button>
  `,
})
export class SecondarySubmitDialogButtonComponent extends BaseSubmitActions {
  @Input({ required: true })
  submitText: string;

  @Input({ required: true })
  text: string;

  get classes(): string {
    return classNames(
      ...(this.btnClasses || []),
      this.color === 'warn' && 'warn',
    );
  }
}
