import { isArray } from 'lodash-es';
import { decode as htmlDecode, encode as htmlEncode } from 'html-entities';

import type { Decodable, DecodableField } from './decodable-fields';

export function decodeText(value: string): string {
  if (!value) {
    return value;
  }

  return htmlDecode(value);
}
export function encodeTextAll(value: string): string {
  if (!value) {
    return value;
  }

  return htmlEncode(value, { level: 'all', mode: 'extensive' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function decodeObjectByField(data: any, field: DecodableField): void {
  if (isArray(data)) {
    for (let index = 0; index < data.length; index += 1) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const element = data[index];

      decodeField(element, field);
    }
  } else {
    decodeField(data, field);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function decodeField(data: any, field: DecodableField): void {
  if (typeof field !== 'string') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (!data[field[0]]) {
      return;
    }

    for (const f of field[1]) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      decodeObjectByField(data[field[0]], f);
    }
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
  const obj = data[field];

  if (!obj) {
    return;
  }

  if (isArray(obj)) {
    for (let index = 0; index < obj.length; index += 1) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const element = obj[index];

      for (let j = 0; j < field[1].length; j += 1) {
        const fieldSchema = field[1][j];

        decodeField(element, fieldSchema);
      }
    }
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    data[field] = decodeText(data[field]);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function decode<T>(data: unknown, schema: Decodable<T>): void {
  for (let i = 0; i < schema.fields.length; i += 1) {
    const s = schema.fields[i];

    decodeField(data, s);
  }
}
