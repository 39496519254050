import type { Signal } from '@angular/core';

import { get, set } from 'lodash-es';
import { ComponentStore as BaseComponentStore } from '@ngrx/component-store';
import type { Observable } from 'rxjs';
import type { FieldPathValue, FieldPath } from '@gv/utils';
import { produce } from 'immer';

function strictEqual(a: unknown, b: unknown): boolean {
  return a === b;
}

interface CreateSetFnOptions<V> {
  partial?: boolean;
  valueTransformer?: undefined | ((a: V) => V);
  cmp?: (a: V, b: V) => boolean;
}

interface FieldSelectorOptions<V> {
  valueTransformer?: (a: V) => V;
}

export class ComponentStore<T extends object> extends BaseComponentStore<T> {
  private _fieldSelectorsCache: Partial<Record<string, Observable<unknown>>> =
    {};

  private _fieldSelectorsSignalsCache: Partial<
    Record<string, Signal<unknown>>
  > = {};

  constructor(defaultState?: T) {
    super(defaultState);
  }

  protected createSetFn<K extends FieldPath<T>>(
    key: K,
    options: CreateSetFnOptions<FieldPathValue<T, K>> & { partial: true },
  ): (state: T, value: Partial<FieldPathValue<T, K>>) => T;
  protected createSetFn<K extends FieldPath<T>>(
    key: K,
    options?: CreateSetFnOptions<FieldPathValue<T, K>>,
  ): (state: T, value: FieldPathValue<T, K>) => T;
  protected createSetFn<K extends FieldPath<T>>(
    key: K,
    options?: CreateSetFnOptions<FieldPathValue<T, K>>,
  ): (state: T, value: FieldPathValue<T, K>) => T {
    const { cmp, valueTransformer, partial } = options || {};

    return (state: T, value: FieldPathValue<T, K>): T => {
      const transformed = valueTransformer ? valueTransformer(value) : value;
      const oldValue = get(state, key) as FieldPathValue<T, K>;
      return (cmp || strictEqual)(oldValue, transformed)
        ? state
        : produce(state, (draft) => {
            set(
              draft,
              key,
              partial
                ? { ...(oldValue as any), ...(transformed as any) }
                : transformed,
            );
          });
    };
  }

  fieldSelector$<K extends FieldPath<T>>(
    key: K,
    options?: FieldSelectorOptions<FieldPathValue<T, K>>,
  ): Observable<FieldPathValue<T, K>> {
    const cacheKey = options
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        [key, Object.values(options).map((m) => m.toString())].join('')
      : key;

    return (this._fieldSelectorsCache[cacheKey] ??= this.select(
      (state): FieldPathValue<T, K> => {
        const value = get(state, key) as FieldPathValue<T, K>;
        return options?.valueTransformer
          ? options.valueTransformer(value)
          : value;
      },
    )) as Observable<FieldPathValue<T, K>>;
  }

  fieldSelectorS<K extends FieldPath<T>>(
    key: K,
    options?: FieldSelectorOptions<FieldPathValue<T, K>>,
  ): Signal<FieldPathValue<T, K>> {
    const cacheKey = options
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        [key, Object.values(options).map((m) => m.toString())].join('')
      : key;

    return (this._fieldSelectorsSignalsCache[cacheKey] ??= this.selectSignal(
      (state): FieldPathValue<T, K> => {
        const value = get(state, key) as FieldPathValue<T, K>;
        return options?.valueTransformer
          ? options.valueTransformer(value)
          : value;
      },
    )) as Signal<FieldPathValue<T, K>>;
  }
}
