import { createComponentLogger } from '@gv/logger';
import mqtt from 'mqtt';
import { Observable, Subject } from 'rxjs';

import { logger as _logger } from '../../logger';
import type { ApiNotification } from '../entity';
import type { CloseDeviceEventModel } from '../entity/device/close-device-event-model';
import type { ConnectDeviceEventModel } from '../entity/device/connect-device-event-model';
import type { DeviceConfigModel } from '../entity/device/device-config-model';
import type { DeviceEvent } from '../entity/device/device-event';
import type { DeviceInitializationEventModel } from '../entity/device/device-initialization-event-model';
import type { ErrorDeviceEventModel } from '../entity/device/error-device-event-model';
import type {
  BinaryPayload,
  MessageDeviceEventModel,
} from '../entity/device/message-device-event-model';
import type { OfflineDeviceEventModel } from '../entity/device/offline-device-event-model';
import type { ReconnectDeviceEventModel } from '../entity/device/reconnect-device-event-model';
import { InvalidCredentialsError } from '../entity/error/invalid-credentials-error';

const logger = createComponentLogger(_logger, 'createDevice');

export function parsePayload<T>(payload: Uint8Array): T | undefined {
  if (!payload) {
    return undefined;
  }

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return JSON.parse(payload.toString());
  } catch (error: unknown) {
    logger.warn({ error, payload }, 'Failed to parse notification payload');
  }

  return undefined;
}

export function prepareWebSocketUrl(
  host: string,
  authorizer: string,
  token: string,
  protocol: string,
  otherParams?: string,
): string {
  const path = '/mqtt';
  const queryParams = `x-amz-customauthorizer-name=${encodeURIComponent(
    authorizer,
  )}&token=${encodeURIComponent(token)}`;

  return `${protocol}://${host}${path}?${queryParams}${otherParams ? '&' + otherParams : ''}`;
}

export function createDevice(
  deviceConfig: DeviceConfigModel,
  token: string,
): Observable<DeviceInitializationEventModel> {
  const { clientId, host } = deviceConfig;
  let device: ReturnType<typeof mqtt.connect>;

  let subject: Subject<DeviceEvent>;

  return new Observable<DeviceInitializationEventModel>((obs) => {
    subject = new Subject<DeviceEvent>();
    let deviceDestroyed = false;
    let connected: boolean;

    device = mqtt.connect(
      prepareWebSocketUrl(
        host,
        deviceConfig.authorizer,
        token,
        deviceConfig.protocol ?? 'wss',
        deviceConfig.otherParams,
      ),
      {
        keepalive: 90,
        connectTimeout: 65 * 1000,
        clientId,
        reschedulePings: true,
        protocolId: 'MQTT',
        protocolVersion: 5,
        clean: false,
        resubscribe: true,
        reconnectPeriod: 2000,
        protocol: deviceConfig.protocol ?? 'wss',
      },
    );

    let timer: ReturnType<typeof setTimeout>;
    const errors: unknown[] = [];

    device.on('packetreceive', () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      (device as any)['_shiftPingInterval']?.();
    });

    device.on('error', (error) => {
      if (deviceDestroyed) {
        return;
      }

      // websocket should be reconnected automatically if credentials are not expired
      // we should receive `close` event in the current event loop, so we emit the error if it is not received
      errors.push(error);

      clearTimeout(timer);

      timer = setTimeout(() => {
        logger.debug(
          { error },
          '[createDevice] close event not triggered after error',
        );
        obs.error(error);
      }, 0);
    });

    device.on('offline', () => {
      if (deviceDestroyed) {
        return;
      }

      subject.next({
        type: 'offline',
        connected: false,
      } as OfflineDeviceEventModel);

      logger.debug('[createDevice] WS offline');
    });

    device.on('close', () => {
      if (!connected && errors.length === 0) {
        obs.error(
          new InvalidCredentialsError(
            'Closed before connack from server',
            new Error('Closed before connack'),
          ),
        );
        return;
      }
      if (deviceDestroyed) {
        return;
      }

      clearTimeout(timer);

      for (const error of errors) {
        subject.next({
          type: 'error',
          connected: false,
          error,
        } as ErrorDeviceEventModel);
      }

      errors.length = 0;

      subject.next({
        type: 'close',
        connected: false,
      } as CloseDeviceEventModel);

      logger.debug('[createDevice] WS close');
    });

    device.on('reconnect', () => {
      if (deviceDestroyed) {
        return;
      }

      subject.next({
        type: 'reconnect',
        connected: false,
      } as ReconnectDeviceEventModel);
      logger.debug('[createDevice] WS reconnect');
    });

    device.on('connect', () => {
      connected = true;
      if (deviceDestroyed) {
        return;
      }

      logger.debug('[createDevice] WS connect');

      if (!device) {
        return;
      }

      obs.next({
        deviceConfig,
        device,
        events$: subject.asObservable(),
      } as DeviceInitializationEventModel);

      subject.next({
        type: 'connect',
        connected: true,
      } as ConnectDeviceEventModel);
    });

    device.on('message', (topic, payload: Uint8Array, packet) => {
      if (deviceDestroyed) {
        return;
      }

      if (!device) {
        return;
      }

      if (topic.endsWith('/image')) {
        subject.next({
          data: {
            data: payload,
            userProperties: packet.properties?.userProperties,
          } satisfies BinaryPayload,
          connected: true,
          topic,
          type: 'message',
        });

        return;
      }

      const data = parsePayload<ApiNotification>(payload);

      subject.next({
        data,
        connected: true,
        type: 'message',
        topic,
      } as MessageDeviceEventModel);
    });

    return () => {
      deviceDestroyed = true;
      device.end();
    };
  });
}
