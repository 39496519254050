/* eslint-disable @typescript-eslint/no-unsafe-return */
import type { ObservableInput, ObservedValueOf, OperatorFunction } from 'rxjs';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export function simpleSwitchMap<T, O extends ObservableInput<any>>(
  fn: (val: Exclude<T, null | undefined | false>) => O,
  defaultObs: ObservableInput<any> = of(undefined),
): OperatorFunction<T, ObservedValueOf<O>> {
  return switchMap((val: T) => {
    if (!val) {
      return defaultObs;
    }

    return fn(val as Exclude<T, null | undefined | false>);
  });
}
