import { SubscriptionTopic } from './topics';
import type { ApiNotification } from './type';

const mapping = {
  EVENT_TRIGGERED: SubscriptionTopic.DashboardEvent,
  NEW_WIDGET_DATA: SubscriptionTopic.DashboardEvent,

  WIDGET_NOTIFICATION: SubscriptionTopic.Dashboard,
  DASHBOARD_UPDATE: SubscriptionTopic.Dashboard,
  EVENT_TELEMETRY: SubscriptionTopic.Dashboard,
  WIDGET_SETTINGS_NOTIFICATION: SubscriptionTopic.Dashboard,
  EXPORT_READY_NOTIFICATION: SubscriptionTopic.Dashboard,

  PROJECT_DIRECTORY_CHANGED_NOTIFICATION: SubscriptionTopic.Project,
  VAULT_DIRECTORY_CHANGED_NOTIFICATION: SubscriptionTopic.Project,
  FILE_STATE_NOTIFICATION: SubscriptionTopic.Project,
  DIRECTORY_NOTIFICATION: SubscriptionTopic.Project,
  VAULT_SIZE_CHANGED: SubscriptionTopic.Project,
  FILE_UPLOAD_CANCELLED: SubscriptionTopic.Project,
  LOCATIONS_NOTIFICATION: SubscriptionTopic.Project,
  LOCATION_NOTIFICATION: SubscriptionTopic.Project,
  PROCESSING_CREATED: SubscriptionTopic.Project,
  LOCATION_STATE_CHANGE_NOTIFICATION: SubscriptionTopic.Project,
  EXPORT_NOTIFICATION: SubscriptionTopic.Project,

  DATA_SOURCES_NOTIFICATION: SubscriptionTopic.Project,
  DATA_SOURCE_NOTIFICATION: SubscriptionTopic.Project,
  GVVI_DATA_SOURCE_NOTIFICATION: SubscriptionTopic.Project,
  GVVI_DATA_SOURCES_NOTIFICATION: SubscriptionTopic.Project,
  DATA_SOURCE_STATE_CHANGE_NOTIFICATION: SubscriptionTopic.Location,

  DATA_EDIT_NOTIFICATION: SubscriptionTopic.DataSource,
  TIMELINE_NOTIFICATION: SubscriptionTopic.DataSource,
  VIDEO_STATE_NOTIFICATION: SubscriptionTopic.DataSource,

  TIMELINE_DATA_NOTIFICATION: SubscriptionTopic.ProjectDataSource,
  TASK_PROCESSING_NOTIFICATION: SubscriptionTopic.ProjectDataSource,
  DESCRIPTION_CHANGED_NOTIFICATION: SubscriptionTopic.ProjectDataSource,
  FILTERS_NOTIFICATION: SubscriptionTopic.ProjectDataSource,
  FILTER_NOTIFICATION: SubscriptionTopic.ProjectDataSource,
  GRAPHICAL_OBJECTS_NOTIFICATION: SubscriptionTopic.ProjectDataSource,
  GRAPHICAL_OBJECT_NOTIFICATION: SubscriptionTopic.ProjectDataSource,
  GVVI_GRAPHICAL_OBJECTS_NOTIFICATION: SubscriptionTopic.ProjectDataSource,
  GVVI_GRAPHICAL_OBJECT_NOTIFICATION: SubscriptionTopic.ProjectDataSource,

  GVVI_FILTER_NOTIFICATION: SubscriptionTopic.ProjectDataSource,
  GVVI_FILTERS_NOTIFICATION: SubscriptionTopic.ProjectDataSource,
  GVVI_DESCRIPTION_CHANGED_NOTIFICATION: SubscriptionTopic.ProjectDataSource,

  CAMERA_DISCOVERY_NOTIFICATION: SubscriptionTopic.User,
  DEVICE_STREAM_VERIFICATION_NOTIFICATION: SubscriptionTopic.User,
  CREDIT_CHANGE: SubscriptionTopic.User,
  DEVICE_NOTIFICATION: SubscriptionTopic.User,
  DEVICE_STREAM_NOTIFICATION: SubscriptionTopic.User,
  MODULE_ACTIVE: SubscriptionTopic.User,
  NOTIFICATION: SubscriptionTopic.User,
  ORGANIZATION_LOGO_NOTIFICATION: SubscriptionTopic.User,
  PAYMENT_SUCCESSFUL_NOTIFICATION: SubscriptionTopic.User,
  PROJECTS_NOTIFICATION: SubscriptionTopic.User,
  PROJECT_NOTIFICATION: SubscriptionTopic.User,
  STYLE_CHANGED: SubscriptionTopic.User,
  SUBSCRIPTION_ACTIVE: SubscriptionTopic.User,
  USER_ORGANIZATION_NOTIFICATION: SubscriptionTopic.User,
  USER_UPDATED_NOTIFICATION: SubscriptionTopic.User,
  VAULT_CHANGED_NOTIFICATION: SubscriptionTopic.User,
  GEO_LOCATION_CHANGED: SubscriptionTopic.User,
  PLATFORM_NOTIFICATION: SubscriptionTopic.User,
  USER_ONLINE_STATUS_NOTIFICATION: SubscriptionTopic.User,
  PLATFORM_CENTER_NOTIFICATION: SubscriptionTopic.User,
  MAINTENANCE_NOTIFICATION: SubscriptionTopic.User,
  MAINTENANCE_SCHEDULED_NOTIFICATION: SubscriptionTopic.User,
} as const;

export const subscriptionMapping: Record<
  ApiNotification['notificationType'],
  SubscriptionTopic
> = mapping;
export type SubscriptionTypeMapping = typeof mapping;
