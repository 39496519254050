export function asIndexed<T, K extends string | number | symbol = string>(
  val: T[] | readonly T[],
  uuidExtractor: (val: T, index: number) => K,
): Record<K, T> {
  const dict: Record<K, T> = {} as Record<K, T>;
  (val || []).forEach((v, index) => {
    dict[uuidExtractor(v, index)] = v;
  });
  return dict;
}

export function asUuidIndexed<T extends { uuid: string }>(
  val: readonly T[],
): Record<string, T> {
  const dict: Record<string, T> = {};
  for (let index = 0; index < val.length; index += 1) {
    const el = val[index];
    dict[el.uuid] = el;
  }
  return dict;
}
