/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Type, Signal, Injector } from '@angular/core';
import { inject, InjectionToken } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { generateUuid } from '@gv/utils';
import type { SelectSignalOptions } from '@ngrx/store/src/models';

import { NgStore, type Store } from './store';

const tokens: Record<string, StoreInjectionToken<any>> = {};

function key(types: readonly InjectionToken<any>[]) {
  return types
    ?.map((t) => t.toString())
    .sort()
    .join('-');
}

export class StoreInjectionToken<
  T extends Store<unknown>,
> extends InjectionToken<T> {
  constructor(
    public type: string,
    options?: {
      providedIn?: Type<any> | 'root' | 'platform' | 'any' | null;
      factory: () => T;
    },
  ) {
    super(type, options);
  }
}

type ExtractState<K> =
  K extends StoreInjectionToken<infer U>
    ? U extends Store<infer S>
      ? S
      : never
    : never;

type SpreadStoreTuple<T extends readonly any[]> = T extends [infer F]
  ? ExtractState<F>
  : T extends [infer F, ...infer R]
    ? ExtractState<F> & SpreadStoreTuple<R>
    : never;

function selectSignal<K>(
  this: NgStore,
  selector: (state: object) => K,
  options?: (SelectSignalOptions<K> & { injector: Injector }) | undefined,
): Signal<K> {
  return toSignal(this.select(selector), {
    requireSync: true,
    injector: options?.injector,
  });
}

export const StoreInject = <T extends StoreInjectionToken<any>[]>(
  ...types: T
): InjectionToken<Store<SpreadStoreTuple<T>>> => {
  // TODO: remove this when this issue is fixed https://github.com/angular/angular/issues/50320
  NgStore.prototype.selectSignal = selectSignal;
  if (types.length === 1) {
    return types[0] as StoreInjectionToken<Store<SpreadStoreTuple<T>>>;
  }

  const k = key(types);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  let injectionToken: StoreInjectionToken<Store<SpreadStoreTuple<T>>> =
    tokens[k];

  if (!injectionToken) {
    injectionToken = new StoreInjectionToken<Store<SpreadStoreTuple<T>>>(
      generateUuid(),
      {
        providedIn: 'any',
        factory(): any {
          const injected = types.map((t) => inject(t));
          return injected[0];
        },
      },
    );
    tokens[k] = injectionToken;
  }

  return injectionToken;
};
