import { isString, omit } from 'lodash-es';
import LRU from 'lru-cache';

import { BaseLogger } from './logger';

const regex = /[xy]/g;

const template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

function mapper(c: string): string {
  const r = (Math.random() * 16) | 0;
  const v = c === 'x' ? r : (r & 0x3) | 0x8;
  return v.toString(16);
}

function generateUuid(): string {
  return template.replace(regex, mapper);
}

export const DETACHED_FIELD = '__xhrLogData';

function approxObjectSize(object: Record<string, any>): number {
  const objectList = [];
  const stack: unknown[] = [object];
  let bytes = 0;

  while (stack.length) {
    const value = stack.pop();

    if (typeof value === 'boolean') {
      bytes += 4;
    } else if (typeof value === 'string') {
      bytes += value.length * 2;
    } else if (typeof value === 'number') {
      bytes += 8;
    } else if (typeof value === 'object' && objectList.indexOf(value) === -1) {
      objectList.push(value);

      for (const i in value) {
        stack.push(i);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        stack.push((value as any)[i] as unknown);
      }
    }
  }
  return bytes;
}

const cache = new LRU<string, Record<string, any>>({
  maxSize: 20_000_000,
  sizeCalculation: approxObjectSize,
});

export function getDetachedData(key: string): Record<string, any> | undefined {
  return cache.get(key);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export interface WrappedFunction extends Function {
  [key: string]: any;
  __gv_original?: WrappedFunction;
}

type XHRSendInput =
  | null
  | Blob
  | BufferSource
  | FormData
  | URLSearchParams
  | string;
interface WrappedXMLHttpRequest extends XMLHttpRequest {
  [key: string]: any;
  __gv_xhr__?: {
    method?: string;
    url?: string;
    status_code?: number;
    body?: XHRSendInput;
    requestHeaders?: Record<string, string>;
    responseHeaders?: Record<string, string>;
    startTimestamp?: number;
    endTimestamp?: number;
    response?: any;
  };
}

export function addProperty(
  obj: { [key: string]: unknown },
  name: string,
  value: unknown,
): void {
  Object.defineProperty(obj, name, {
    value: value,
    writable: true,
    configurable: true,
  });
}

export function markFunctionWrapped(
  wrapped: WrappedFunction,
  original: WrappedFunction,
): void {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const proto = original.prototype || {};
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  wrapped.prototype = original.prototype = proto;
  addProperty(wrapped, '__gv_original', original);
}

function parseHeaders(headers: string): Record<string, string> {
  const arr = headers.trim().split(/[\r\n]+/);

  const headerMap: Record<string, string> = {};
  for (let i = 0; i < arr.length; i++) {
    const line = arr[i];
    const parts = line.split(': ');
    const header = parts.shift()!.toLowerCase();
    const value = parts.join(': ');
    headerMap[header] = value;
  }

  return headerMap;
}

export function patch(
  source: { [key: string]: any },
  name: string,
  replacementFactory: (...args: any[]) => any,
): void {
  if (!(name in source)) {
    return;
  }

  const original = source[name] as () => any;
  const wrapped = replacementFactory(original) as WrappedFunction;

  if (typeof wrapped === 'function') {
    try {
      markFunctionWrapped(wrapped, original);
    } catch (_) {
      //
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  source[name] = wrapped;
}

export function patchXhr(): void {
  if (!('XMLHttpRequest' in globalThis)) {
    return;
  }

  const xhrproto = XMLHttpRequest.prototype;

  patch(
    xhrproto,
    'setRequestHeader',
    function (originalSetRequestHeader: (...args: any[]) => void): () => void {
      return function (this: WrappedXMLHttpRequest, ...args: any[]): void {
        try {
          if (this.__gv_xhr__ && args.length >= 2) {
            if (!this.__gv_xhr__.requestHeaders) {
              this.__gv_xhr__.requestHeaders = {};
            }
            const name = (args[0] as string)?.toLowerCase();
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            this.__gv_xhr__.requestHeaders[name] =
              name === 'authorization' ? '[redacted]' : args[1];
          }
        } catch (e) {
          //
        }

        return originalSetRequestHeader.apply(this, args);
      };
    },
  );

  patch(
    xhrproto,
    'open',
    function (originalOpen: (...args: any[]) => void): () => void {
      return function (this: WrappedXMLHttpRequest, ...args: unknown[]): void {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const xhr = this;
        const url = args[1] as string;
        if (
          !url ||
          !(/gvl.io|goodvisionlive.com/.exec(url) || !url.startsWith('http'))
        ) {
          return originalOpen.apply(xhr, args);
        }

        const xhrInfo: WrappedXMLHttpRequest['__gv_xhr__'] = (xhr.__gv_xhr__ = {
          method: (isString(args[0])
            ? args[0].toUpperCase()
            : args[0]) as string,
          url: args[1] as string,
        });

        const onreadystatechangeHandler = function (): void {
          if (xhr.readyState === 4) {
            try {
              // touching statusCode in some platforms throws
              // an exception
              xhrInfo.status_code = xhr.status;
            } catch (e) {
              /* do nothing */
            }

            xhrInfo.endTimestamp = Date.now();
            xhrInfo.responseHeaders = parseHeaders(xhr.getAllResponseHeaders());
            if (
              xhrInfo.responseHeaders?.['content-type'] === 'application/json'
            ) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              xhrInfo.response = xhr.response;
            }

            const uuid = generateUuid();
            cache.set(uuid, {
              body: xhrInfo.body,
              responseHeaders: xhrInfo.responseHeaders,
              requestHeaders: xhrInfo.requestHeaders,
            });
            BaseLogger.trace(
              {
                ...omit(xhrInfo, ['body', 'responseHeaders', 'requestHeaders']),
                [DETACHED_FIELD]: uuid,
              },
              `XHR ${xhrInfo.method || ''} ${xhrInfo.url || ''}`,
            );
          }
        };

        if (
          'onreadystatechange' in xhr &&
          typeof xhr.onreadystatechange === 'function'
        ) {
          patch(
            xhr,
            'onreadystatechange',
            // eslint-disable-next-line @typescript-eslint/ban-types
            function (original: WrappedFunction): Function {
              return function (...readyStateArgs: unknown[]): void {
                try {
                  onreadystatechangeHandler();
                } catch (e) {
                  //
                }
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return original.apply(xhr, readyStateArgs);
              };
            },
          );
        } else {
          xhr.addEventListener('readystatechange', onreadystatechangeHandler);
        }

        return originalOpen.apply(xhr, args);
      };
    },
  );

  patch(
    xhrproto,
    'send',
    function (originalSend: (...args: any[]) => void): () => void {
      return function (this: WrappedXMLHttpRequest, ...args: any[]): void {
        try {
          if (this.__gv_xhr__) {
            if (args[0] !== undefined) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              this.__gv_xhr__.body = args[0];
            }
            this.__gv_xhr__.startTimestamp = Date.now();
          }
        } catch (e) {
          //
        }

        return originalSend.apply(this, args);
      };
    },
  );
}
