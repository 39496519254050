import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LinkToHelpdeskComponent } from './component/link-to-helpdesk.component';

@NgModule({
  imports: [CommonModule, LinkToHelpdeskComponent],
  exports: [LinkToHelpdeskComponent],
})
export class LinkToHelpdeskModule {}
