import { inject, Injectable, Injector } from '@angular/core';

import type {
  ApiResponse,
  ChangePwRequestWEmail,
  CreateUser,
  LoginUser,
  PairOAuth,
  Token,
  VerifyUser,
} from '@gv/api';
import { ApiErrorResponse } from '@gv/api';
import { ElectronRefService } from '@gv/desktop/core';
import type { UserModel } from '@gv/user';
import { getTokenUserUuid } from '@gv/user';
import { createThrowIfRedirect, RedirectError } from '@gv/utils';
import type { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import type { RedirectDataModel } from '../../entity/model/error-handling/redirect-data-model';
import type { PasswordChangeRequestDataModel } from '../../entity/model/user-area/password-change-request-data-model';
import { TokenStoreService } from '../application/user/token-store.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  static readonly className: string = 'UserApiService';
  private apiService = inject(ApiService);
  private electronRef = inject(ElectronRefService, { optional: true });
  private injector = inject(Injector);

  private _tokenStore: TokenStoreService;
  get tokenStore(): TokenStoreService {
    return (
      this._tokenStore ??
      (this._tokenStore = this.injector.get(TokenStoreService))
    );
  }

  register(userRegistration: CreateUser): Observable<ApiResponse<void>> {
    return this.apiService.withDirect(() =>
      this.apiService.api.createUser(userRegistration),
    );
  }

  verifyUser(verifyUserData: VerifyUser): Observable<ApiResponse<Token>> {
    return this.apiService.withDirect(() =>
      this.apiService.api.verifyUser(verifyUserData),
    );
  }

  createPasswordChangeRequest(
    passwordChangeRequestData: PasswordChangeRequestDataModel,
  ): Observable<ApiResponse<void>> {
    return this.apiService.withDirect(() =>
      this.apiService.api.createNewPasswordRequest(passwordChangeRequestData),
    );
  }

  changePassword(
    changePasswordData: ChangePwRequestWEmail,
  ): Observable<ApiResponse<void>> {
    return this.apiService.withDirect(() =>
      this.apiService.api.changePassword(changePasswordData),
    );
  }

  authenticate(
    credentials: LoginUser,
    options?: { headers?: Record<string, string> },
  ): Observable<ApiResponse<Token>> {
    return this.apiService.withDirect(() =>
      this.apiService.api.authenticateUser(credentials, options),
    );
  }

  logoutUser(): Observable<ApiResponse<void>> {
    return this.apiService.withDirect(() =>
      this.apiService.api.logoutUser({
        headers: { authorization: `Bearer ${this.tokenStore.token}` },
      }),
    );
  }

  logoutUserEverywhere(): Observable<ApiResponse<void>> {
    return this.apiService.withDirect(() =>
      this.apiService.api.invalidateUserTokens({
        headers: { authorization: `Bearer ${this.tokenStore.token}` },
      }),
    );
  }

  loginUser(
    credentials: LoginUser,
    options?: { headers?: Record<string, string> },
  ): Observable<readonly [string, UserModel]> {
    return this.authenticate(credentials, options).pipe(
      mergeMap((response) => {
        const userUuid = getTokenUserUuid(response.data.token);

        return this.apiService
          .withDirect(() =>
            this.apiService.api.getOwnUserDetail({
              headers: {
                ...(options?.headers || {}),
                authorization: `Bearer ${response.data.token}`,
              },
            }),
          )
          .pipe(
            tap(createThrowIfRedirect(!this.electronRef)),
            map(
              ({ data: user, dtSent }) =>
                [
                  response.data.token,
                  { ...user, uuid: userUuid, dtSent },
                ] as const,
            ),
            catchError((e) => {
              if (
                e instanceof ApiErrorResponse &&
                e.bypassRedirect &&
                e.status === 302 &&
                <RedirectDataModel>e.data &&
                (<RedirectDataModel>e.data).location
              ) {
                return throwError(
                  () =>
                    new RedirectError(
                      response.data.token,
                      (<RedirectDataModel>e.data).location,
                    ),
                );
              }

              return throwError(() => e);
            }),
          );
      }),
    );
  }

  pairUser(
    pairData: PairOAuth,
    options?: { headers?: Record<string, string> },
  ): Observable<readonly [string, UserModel]> {
    return this.apiService.api.pairOAuth(pairData, options).pipe(
      mergeMap((response) => {
        const userUuid = getTokenUserUuid(response.data.token);

        return this.apiService
          .withDirect(() =>
            this.apiService.api.getOwnUserDetail({
              headers: {
                ...(options?.headers || {}),
                authorization: `Bearer ${response.data.token}`,
              },
            }),
          )
          .pipe(
            tap(createThrowIfRedirect(!this.electronRef)),
            map(
              ({ data: user, dtSent }) =>
                [
                  response.data.token,
                  { ...user, uuid: userUuid, dtSent },
                ] as const,
            ),
            catchError((e) => {
              if (
                e instanceof ApiErrorResponse &&
                e.bypassRedirect &&
                e.status === 302 &&
                <RedirectDataModel>e.data &&
                (<RedirectDataModel>e.data).location
              ) {
                return throwError(
                  () =>
                    new RedirectError(
                      response.data.token,
                      (<RedirectDataModel>e.data).location,
                    ),
                );
              }

              return throwError(() => e);
            }),
          );
      }),
    );
  }

  loginUserWithToken(
    token: string,
    options?: { headers?: Record<string, string> },
  ): Observable<readonly [string, UserModel]> {
    const userUuid = getTokenUserUuid(token);

    return this.apiService
      .withDirect(() =>
        this.apiService.api.getOwnUserDetail({
          headers: {
            ...(options?.headers || {}),
            authorization: `Bearer ${token}`,
          },
        }),
      )
      .pipe(
        tap(createThrowIfRedirect(!this.electronRef)),
        map(
          ({ data: user, dtSent }) =>
            [token, { ...user, uuid: userUuid, dtSent }] as const,
        ),
        catchError((e) => {
          if (
            e instanceof ApiErrorResponse &&
            e.bypassRedirect &&
            e.status === 302 &&
            <RedirectDataModel>e.data &&
            (<RedirectDataModel>e.data).location
          ) {
            return throwError(
              () =>
                new RedirectError(token, (<RedirectDataModel>e.data).location),
            );
          }

          return throwError(() => e);
        }),
      );
  }
}
