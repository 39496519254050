export class BinaryEnumUtils {
  static set(state: number, ...flags: readonly number[]): number {
    let newState: number = state;

    for (const flag of flags) {
      // tslint:disable-next-line: no-bitwise
      newState |= flag;
    }

    return newState;
  }

  static unset(state: number, ...flags: readonly number[]): number {
    let newState: number = state;

    for (const flag of flags) {
      // tslint:disable-next-line: no-bitwise
      newState &= ~flag;
    }

    return newState;
  }

  static isSet(state: number, ...flags: readonly number[]): boolean {
    // tslint:disable-next-line: no-bitwise
    return flags.some((flag) => flag === (state & flag));
  }

  static allNotSet(state: number, ...flags: readonly number[]): boolean {
    // tslint:disable-next-line: no-bitwise
    return flags.every((flag) => flag !== (state & flag));
  }

  static someNotSet(state: number, ...flags: readonly number[]): boolean {
    // tslint:disable-next-line: no-bitwise
    return flags.some((flag) => flag !== (state & flag));
  }
}
