import type {
  FormGroup,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { NgForm } from '@angular/forms';

import type { Observable } from 'rxjs';
import { defer } from 'rxjs';
import { startWith, ignoreElements, tap, debounceTime } from 'rxjs/operators';
import { isBoolean, omit } from 'lodash-es';

export class FormUtils {
  static forceRevalidation(form: NgForm | FormGroup): void {
    if (!form) {
      return;
    }

    if (form instanceof NgForm) {
      form.control.updateValueAndValidity();
    }

    const controls = Object.keys(form.controls);

    for (const control of controls) {
      form.controls[control].markAllAsTouched();
      form.controls[control].updateValueAndValidity();
    }
  }

  static ensureValidator(
    control: AbstractControl,
    validatorFn: any,
    present: boolean,
  ) {
    if (present) {
      if (!control.hasValidator(validatorFn)) {
        control.addValidators(validatorFn);
      }
    } else {
      if (control.hasValidator(validatorFn)) {
        control.removeValidators(validatorFn);
      }
    }
  }

  static synchronizeFormControls(
    controlFrom: AbstractControl,
    controlTo: AbstractControl,
    merge?: string[],
  ): Observable<never> {
    return defer(() =>
      controlFrom.statusChanges.pipe(startWith(controlFrom.status)),
    ).pipe(
      debounceTime(100),
      tap(() => {
        const newErrors = merge
          ? !controlTo.errors
            ? controlFrom.errors
            : { ...omit(controlTo.errors, merge), ...controlFrom.errors }
          : controlFrom.errors;

        controlTo.setErrors(
          newErrors && Object.keys(newErrors).length > 0 ? newErrors : null,
          { emitEvent: true },
        );
        controlTo.markAsDirty();
        controlTo.markAsTouched();
      }),
      ignoreElements(),
    );
  }

  static enableFormControls(
    formGroup: FormGroup,
    formEnabled: Record<string, boolean | Record<string, boolean>>,
  ): void {
    for (const field of Object.keys(formEnabled)) {
      const value = formEnabled[field];
      if (isBoolean(value)) {
        if (formGroup.controls[field].enabled !== value) {
          formGroup.controls[field][value ? 'enable' : 'disable']();
        }
      } else if (formGroup.controls[field]) {
        FormUtils.enableFormControls(
          formGroup.controls[field] as FormGroup,
          formEnabled[field] as Record<string, boolean>,
        );
      }
    }
  }

  static mergeValidatorResults(
    results: (ValidationErrors | null)[],
  ): ValidationErrors | null {
    let res = null;
    for (let i = 0; i < results.length; i += 1) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const result = results[i];
      if (result) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        res = { ...(res || {}), ...result };
      }
    }
    return res;
  }
}
