@if (service.visible$ | async) {
  <div #message class="mx-6 flex w-full items-center justify-between py-4">
    <div class="pr-4">
      <ng-container i18n="@@unsupported-browser-snack-bar.helpdesk.start">
        You may be using <b>unsupported browser</b>. Please
        <b
          >ensure your browser is up-to-date and on the list of supported
          browsers</b
        >
      </ng-container>
      <gv-link-to-helpdesk
        [class]="'link text-color-primary text-sm font-bold underline'"
        link="http://help.goodvisionlive.com/en/articles/3342463-what-do-i-need-to-run-and-use-goodvision"
        i18n-link="@@unsupported-browser-snack-bar.helpdesk-link"
        linkText="here"
        i18n-linkText="@@unsupported-browser-snack-bar.helpdesk.link=text"
      ></gv-link-to-helpdesk>
      .
    </div>
    <button
      mat-icon-button
      class="-my-[8px] mx-0 text-gray-button"
      (click)="hide()"
      type="button"
    >
      <mat-icon class="mat-icon">close</mat-icon>
    </button>
  </div>
}
