import { Injectable, inject } from '@angular/core';

import { IndexedDBService } from '@gv/ui/core';

@Injectable({
  providedIn: 'root',
})
export class StorageInitializerService {
  private indexedDb = inject(IndexedDBService);

  async init(): Promise<void> {
    await this.indexedDb.getConnection();
  }
}
