import type { Injector } from '@angular/core';
import { DestroyRef, inject } from '@angular/core';

interface IdleDeadline {
  timeRemaining(): DOMHighResTimeStamp;
  readonly didTimeout: boolean;
}

interface IdleRequestOptions {
  timeout?: number | undefined;
}

type IdleCallbackHandle = number;

interface W {
  requestIdleCallback(
    callback: (deadline: IdleDeadline) => void,
    options?: IdleRequestOptions,
  ): IdleCallbackHandle;
  cancelIdleCallback(handle: number): void;
}

export class SchedulingUtils {
  /**
   * @see https://gist.github.com/paullewis/55efe5d6f05434a96c36
   */
  private static _requestIdleCallbackShim(
    cb: (deadline: IdleDeadline) => void,
  ): IdleCallbackHandle {
    return setTimeout(() => {
      const start = Date.now();

      cb({
        didTimeout: false,
        timeRemaining(): number {
          return Math.max(0, 50 - (Date.now() - start));
        },
      });
    }, 1) as unknown as IdleCallbackHandle;
  }

  private static _cancelIdleCallbackShim(handle: IdleCallbackHandle): void {
    clearTimeout(handle);
  }

  static requestIdleCallback(
    cb: (deadline: IdleDeadline) => void,
    opts?: IdleRequestOptions,
  ): IdleCallbackHandle {
    if ((window as unknown as W).requestIdleCallback) {
      return (window as unknown as W).requestIdleCallback(cb, opts);
    }

    return SchedulingUtils._requestIdleCallbackShim(cb);
  }

  static cancelIdleCallback(handle: IdleCallbackHandle): void {
    if ((window as unknown as W).cancelIdleCallback) {
      return (window as unknown as W).cancelIdleCallback(handle);
    }

    return SchedulingUtils._cancelIdleCallbackShim(handle);
  }
}

export function lifecycleAwareTimeout(
  fn: () => void,
  timeout: number,
  injector?: Injector,
): ReturnType<typeof setTimeout> {
  const destroyRef = injector?.get(DestroyRef) ?? inject(DestroyRef);
  // eslint-disable-next-line prefer-const
  let unregisterFn: undefined | ReturnType<typeof destroyRef.onDestroy>;

  let handle: ReturnType<typeof setTimeout> | undefined = setTimeout(() => {
    fn();
    handle = undefined;
    unregisterFn?.();
  }, timeout);

  unregisterFn = destroyRef.onDestroy(() => {
    if (handle !== undefined) {
      clearTimeout(handle);
    }
  });
  return handle;
}
