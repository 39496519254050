import type { ActionReducerMap } from '@ngrx/store';

import type { AppState } from '../state/app-state';
import * as UserReducer from './user.reducer';

export function getAppReducers(): ActionReducerMap<AppState> {
  return {
    [UserReducer.userFeatureKey]: UserReducer.reducer,
  };
}
