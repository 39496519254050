import { createDialogBaseActions } from '@gv/state';
import type { InvalidFolderModel } from '@gv/upload/types';
import { props } from '@ngrx/store';
const basename = '[Vault Dialog]';

export interface FilesCouldNotBeUploadedDialogDataModel {
  readonly folders: readonly InvalidFolderModel[];
}

export interface VaultSizeExceededDialogDataModel {
  uploadSize: number; // in bytes

  freeSpace: number; // in bytes

  uploadStopped: boolean;
}

export const DialogActions = {
  stopVaultUploadDialog: createDialogBaseActions(basename, 'StopVaultUpload', {
    openProps: props<{
      data: {
        uploadGroupUuid: string;
        uuid: string;
      };
    }>(),
  }),

  stopAllUploadsDialog: createDialogBaseActions(basename, 'StopAllUploads', {
    closedProps: props<{ data: boolean }>(),
  }),

  stopAllUploadsCrossTabDialog: createDialogBaseActions(
    basename,
    'StopAllUploadsCrossTab',
    {
      openProps: props<{ data: { desktopQuit?: boolean } }>(),
      closedProps: props<{ data: boolean }>(),
    },
  ),

  filesCouldNotBeUploadedDialog: createDialogBaseActions(
    basename,
    'FilesCouldNotBeUploaded',
    {
      openProps: props<{
        data: FilesCouldNotBeUploadedDialogDataModel;
      }>(),
    },
  ),

  vaultSizeExceededDialog: createDialogBaseActions(
    basename,
    'VaultSizeExceeded',
    {
      openProps: props<{ data: VaultSizeExceededDialogDataModel }>(),
    },
  ),
};
