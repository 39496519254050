import { Observable } from 'rxjs';
import type { AxiosHeaders, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import axios from 'axios';

import type { ApiResponse } from './type/api-response';
import { ApiErrorResponse } from './type/api-response';

export interface Retryable {
  rid: string;
  createdAt: Date;
  bypassJwt: boolean;
  bypassRedirect: boolean;
}

export type RequestConfig<T> = RawAxiosRequestConfig & { data?: T } & {
  raw?: boolean;
} & Retryable;

function isTypeBasedOnProperty<T, K extends keyof T = keyof T>(
  obj: any,
  property: K,
): obj is T {
  return !!obj && Object.prototype.hasOwnProperty.call(obj, property);
}

export function handle<T = any>(
  client: AxiosInstance,
  config: RequestConfig<any>,
  errorMapping: Record<number, string>,
  callbacks?: {
    done: () => void;
    error: (status: number) => void;
  },
): Observable<ApiResponse<T>> {
  return new Observable<ApiResponse<T>>((obs) => {
    const cancelToken = axios.CancelToken.source();
    let destroyed = false;

    client
      .request<T>({ ...config, cancelToken: cancelToken.token })
      .then((r) => {
        if (destroyed) {
          return;
        }

        const dtSent = r.headers['x-response_date']
          ? new Date(r.headers['x-response_date'])
          : new Date();

        callbacks?.done();

        obs.next({
          bypassJwt: config.bypassJwt,
          bypassRedirect: config.bypassRedirect,
          createdAt: config.createdAt,
          data: r.data,
          status: r.status,
          rid: config.rid,
          dtSent,
          headers: r.headers as AxiosHeaders,
          apiMessage:
            r.data &&
            isTypeBasedOnProperty<{
              code: number;
              message: string;
            }>(r.data, 'code') &&
            errorMapping[r.data.code]
              ? {
                  code: r.data.code,
                  message: errorMapping[r.data.code],
                }
              : undefined,
        });
        obs.complete();
      })
      .catch((error) => {
        if (!destroyed && !axios.isCancel(error)) {
          if (axios.isAxiosError(error)) {
            try {
              const dtSent = error.response?.headers?.['x-response_date']
                ? new Date(error.response?.headers['x-response_date'])
                : new Date();

              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              const response = error.response?.data;
              const apiMessage = isTypeBasedOnProperty<{
                code: number;
                message: string;
              }>(response, 'code')
                ? {
                    code: response.code,
                    message: errorMapping[response.code],
                  }
                : undefined;

              const status = error.response?.status ?? 0;
              obs.error(
                new ApiErrorResponse(error.message, {
                  bypassJwt: config.bypassJwt,
                  bypassRedirect: config.bypassRedirect,
                  createdAt: config.createdAt,
                  rid: config.rid,
                  status,
                  dtSent,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  data: error.response?.data,
                  apiMessage,
                  headers: error.response?.headers as Record<string, string>,
                }),
              );

              callbacks?.error(status);
            } catch (e) {
              obs.error(e);
            }
          } else {
            obs.error(error);
          }
        }
      })
      .finally(() => {
        obs.complete();
      });

    return () => {
      destroyed = true;
      cancelToken.cancel();
    };
  });
}
