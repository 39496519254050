import type { OnDestroy } from '@angular/core';
import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import type { IsAble } from '@gv/utils';
import { isArray, untilNgDestroyed, BinaryEnumUtils } from '@gv/utils';
import { isEqual } from 'lodash-es';
import type { Observable } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';

import { PermissionService } from './permission.service';
import { logger } from '../logger';
import type { ProjectPermissionContext, Permissions } from '../def';
import {
  permissionsReason,
  permissionsInitializers,
  projectPermissionsInitializers,
  projectPermissionReducers,
  reduce,
} from '../def';
import { Permission } from '../entity/enum';
import type { PermissionScope } from '../entity/enum';
import type { BasePermissionService } from './permission-service.interface';
import { PermissionMode } from './permission-service.interface';

@Injectable()
export class ProjectPermissionService
  implements OnDestroy, BasePermissionService
{
  private permissionService = inject(PermissionService);
  private contextSubject = new ReplaySubject<ProjectPermissionContext>(1);

  permissions$: Observable<Permissions> = this.contextSubject.pipe(
    distinctUntilChanged((a, b) => isEqual(a, b)),
    switchMap((scope) =>
      this.permissionService.permissions$.pipe(
        map((userPermissions) => {
          const projectPermissions = this.build(scope, userPermissions);
          this.basePermissions = userPermissions;
          this.permissions = projectPermissions;
          return projectPermissions;
        }),
      ),
    ),
    untilNgDestroyed(),
    shareReplay(1),
  );

  private permissions: Permissions = undefined;
  private basePermissions: Permissions = undefined;

  private permissionsS = toSignal(this.permissions$);

  constructor() {
    this.permissions$.subscribe((permissions) => {
      this.permissions = permissions;
    });
  }

  getReason(
    mode: 'short' | 'full',
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    permissionMode?: PermissionMode,
  ): string {
    if (
      !this.isSet(this.permissions, permission, scope, permissionMode) &&
      this.isSet(this.basePermissions, permission, scope, permissionMode)
    ) {
      return permissionsReason[Permission.Project];
    }

    return this.permissionService.getReason(
      mode,
      permission,
      scope,
      permissionMode,
    );
  }

  ngOnDestroy(): void {
    //
  }

  setProjectContext(context: ProjectPermissionContext): void {
    this.contextSubject.next(context);
  }

  isAllowed(
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    mode?: PermissionMode,
  ): boolean {
    return this.isSet(this.permissions, permission, scope, mode);
  }

  isAllowedS(
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    mode?: PermissionMode,
  ): boolean {
    if (!this.permissionsS()) {
      return false;
    }
    return this.isSet(this.permissionsS()!, permission, scope, mode);
  }

  isAllowed$(
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    mode?: PermissionMode,
  ): Observable<boolean> {
    return this.permissions$.pipe(
      map((permissions) => {
        return this.isSet(permissions, permission, scope, mode);
      }),
      distinctUntilChanged(),
    );
  }

  isAble$(
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    mode?: PermissionMode,
  ): Observable<IsAble> {
    return this.permissions$.pipe(
      map((permissions): IsAble => {
        const allowed = this.isSet(permissions, permission, scope, mode);

        return allowed
          ? [true]
          : [
              false,
              { reason: this.getReason('full', permission, scope, mode) },
            ];
      }),
      distinctUntilChanged((a, b) => isEqual(a, b)),
    );
  }

  isAble(
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    mode?: PermissionMode,
  ): IsAble {
    const allowed = this.isSet(this.permissions, permission, scope, mode);

    return allowed
      ? [true]
      : [false, { reason: this.getReason('full', permission, scope, mode) }];
  }

  isAbleS(
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    mode?: PermissionMode,
  ): IsAble {
    if (!this.permissionsS()) {
      return [false, { reason: '' }];
    }

    const allowed = this.isSet(this.permissionsS()!, permission, scope, mode);

    return allowed
      ? [true]
      : [false, { reason: this.getReason('full', permission, scope, mode) }];
  }

  isSet(
    permissions: Permissions,
    permission: Permission | readonly Permission[],
    scope: PermissionScope | readonly PermissionScope[],
    mode?: PermissionMode,
  ): boolean {
    if (!permissions) {
      logger.warn('Project permissions used before initialization');
      return false;
    }

    if (isArray<Permission>(permission)) {
      return permission[mode === PermissionMode.Or ? 'some' : 'every']((p) =>
        BinaryEnumUtils.isSet(
          permissions[p],
          ...(isArray(scope) ? scope : [scope]),
        ),
      );
    }

    return BinaryEnumUtils.isSet(
      permissions[permission],
      ...(isArray(scope) ? scope : [scope]),
    );
  }

  private build(
    scope: ProjectPermissionContext,
    defaultPermissions: Permissions,
  ): Permissions {
    let reducedPermissions = permissionsInitializers.reduce(
      (p, fn) => fn(p, scope.disabledFeatures, undefined),
      defaultPermissions,
    );

    reducedPermissions = (scope.disabledFeatures || []).reduce(
      (permissions, feature): Permissions =>
        reduce(feature, scope.disabledFeatures, permissions),
      reducedPermissions,
    );

    reducedPermissions = projectPermissionsInitializers.reduce(
      (permissions, reducer) => reducer(permissions, scope),
      reducedPermissions,
    );

    reducedPermissions = (scope.disabledFeatures || []).reduce(
      (permissions, feature): Permissions =>
        projectPermissionReducers.reduce(
          (p, reducer) => reducer(p, feature, scope.disabledFeatures),
          permissions,
        ),
      reducedPermissions,
    );

    return reducedPermissions;
  }
}
