import type { UserCardDTO } from '@gv/api';
import type { Store, SubStateObjectModel } from '@gv/state';
import { initialSubState, StoreInjectionToken } from '@gv/state';

export const detailFeatureKey = 'details';

export interface DetailFeatureState {
  [detailFeatureKey]: DetailState;
}

export const DETAIL_FEATURE_STATE = new StoreInjectionToken<
  Store<DetailFeatureState>
>('detailFeatureState');

export interface DetailState {
  readonly detail: SubStateObjectModel<UserCardDTO & { dtSent: Date }>;
}
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const initialDetail: SubStateObjectModel<
  UserCardDTO & { dtSent: Date }
> = initialSubState;

export const initialDetailState: DetailState = {
  detail: initialDetail,
};
