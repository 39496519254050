import type { Action } from '@ngrx/store';
import type { TypedAction } from '@ngrx/store/src/models';
import type { OperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';

import type { FlowProps } from './create-flow-base-actions';

export function ofFlowAction(
  action: TypedAction<string> & FlowProps<void>,
): OperatorFunction<Action, TypedAction<string> & FlowProps<any>> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
  return filter((a: any) => a && a.id === action.id);
}
