export class InvalidCredentialsError extends Error {
  constructor(
    message: string,
    public error: unknown,
  ) {
    super(message);

    Object.setPrototypeOf(this, InvalidCredentialsError.prototype);
  }
}
