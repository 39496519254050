import { DestroyRef, Injectable, NgZone, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

import { WindowRefService } from '@gv/ui/core';
import { filterTruthy, isUndefinedOrNull, untilNgDestroyed } from '@gv/utils';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DebugModeService {
  private destroyRef = inject(DestroyRef);
  private windowRef = inject(WindowRefService);
  private static readonly debugModeEnabledKey = 'debugModeEnabledKey';
  static readonly storeDevToolsEnabledGlobalKey = 'debugModeEnabled';
  private ngZone = inject(NgZone);

  debugEnabledS = signal<boolean | undefined>(undefined);
  private debugEnabled$ = toObservable(this.debugEnabledS).pipe(filterTruthy());

  errorCount$ = new BehaviorSubject<number>(0);

  constructor() {
    this.patchConsole();
  }

  register(key: string, obj: DestroyRef): void {
    this.debugEnabled$.pipe(untilNgDestroyed(obj)).subscribe(() => {
      this.windowRef.nativeWindow.debugObj ??= {};
      this.windowRef.nativeWindow.debugObj[key] = obj;
    });
  }

  registerStatic(key: string, obj: any): void {
    this.debugEnabled$.pipe(untilNgDestroyed(this.destroyRef)).subscribe(() => {
      this.windowRef.nativeWindow.debugObj ??= {};
      this.windowRef.nativeWindow.debugObj[key] = obj;
    });
  }

  enable(): Promise<void> {
    const window = this.windowRef.nativeWindow;
    if (!window) {
      return Promise.resolve();
    }

    window.enableDebugMode = () => {
      localStorage.setItem(
        DebugModeService.debugModeEnabledKey,
        JSON.stringify(true),
      );
      localStorage.setItem('ROARR_FILTER', 'context.logLevel:>10');

      this.debugEnabledS.set(true);
    };

    window.disableDebugMode = () => {
      localStorage.setItem(
        DebugModeService.debugModeEnabledKey,
        JSON.stringify(false),
      );
      localStorage.setItem('ROARR_FILTER', 'context.logLevel:>20');

      this.debugEnabledS.set(false);
    };

    const enabled = localStorage.getItem(DebugModeService.debugModeEnabledKey);
    const debugModeEnabled = isUndefinedOrNull(enabled)
      ? !environment.production
      : !!JSON.parse(enabled);

    window[DebugModeService.storeDevToolsEnabledGlobalKey] = debugModeEnabled;

    this.debugEnabledS.set(debugModeEnabled);

    return Promise.resolve();
  }

  private patchConsole(): void {
    if (typeof console != 'undefined') {
      // eslint-disable-next-line no-console
      if (typeof console.error != 'undefined') {
        // eslint-disable-next-line no-console
        const origMethod = console.error;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        // eslint-disable-next-line no-console
        console.error = function (...args: any[]) {
          origMethod!.apply(console!, args);
          self.increaseErrorCount();
        };
      }
    }
  }

  increaseErrorCount(): void {
    this.ngZone.run(() => {
      this.errorCount$.next(this.errorCount$.getValue() + 1);
    });
  }
}
