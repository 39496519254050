import { InjectionToken } from '@angular/core';

export const ENVIRONMENT = new InjectionToken<{
  api: {
    staticBaseUrl: string;
    lambdaStaticBaseUrl: string;
    useStaticBaseUrl: boolean;
    xsrfToken?: string;
  };
  electron: boolean;
  production: boolean;
  routing: {
    useHashLocationStrategy: boolean;
  };
  serviceWorker: boolean;
  test: boolean;
}>('enviroment');
